<ng-container *ngIf="isVisible" >
    <div class="fixed background-accessibility fadeInDown pt-4 px-12 pb-2" [ngClass]="{'hidden': !desktop}" [ngStyle]="{'padding-top.px': headerHeight, 'margin-left.px': sidebarWidth, 'width.px':(headerWidth - sidebarWidth) }">
        <div class="flex items-center gap-10">
            <div class="flex items-center">

                <input
                    (change)="changeUserWayInput()"
                    [(ngModel)]="isUserWayChecked"
                    id="toogle"
                    style="height: 1.4rem"
                    class="my-2 relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                    type="checkbox"
                />
                <label class="m-2" >{{i18n.header_activate}} UserWay</label>

                <div class="relative" (click)="displayPanel1.toggle()">
                    <span
                        class="iconify cursor-pointer text-customGray-default text-18"
                        data-icon="bi:question-circle"
                    ></span>
                    <div>
                        <app-display-panel
                        id="displayPanel1"
                        #displayPanel1
                        [newStyle]="{'top': '40px', 'left': '50%', 'transform': 'translateX(-50%)', 'box-shadow': '0px 4px 15px rgba(0, 0, 0, 0.15)','border-radius': '15px'}"
                        [custom_radius]="'10px'"
                        [hide_arrow]="true"
                        [panel_style]="{ background: '#FFF', width: '220px' }"
                        [z_index]="{ 'z-index': '100' }"
                        >
                        {{i18n.header_userway_description}}
                        </app-display-panel>
                    </div>
                </div>
            </div>

            <div class="flex items-center">
                <input
                    (change)="changeVLibrasInput()"
                    [(ngModel)]="isVLibrasChecked"
                    id="toogle"
                    style="height: 1.4rem"
                    class="my-2 relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                    type="checkbox"
                />
                <label class="m-2" >{{i18n.header_activate}} VLibras</label>
                <div class="relative" (click)="displayPanel2.toggle()">
                    <span
                        class="iconify cursor-pointer text-customGray-default text-18"
                        data-icon="bi:question-circle"
                    ></span>
                    <div>
                        <app-display-panel
                        #displayPanel2
                        [newStyle]="{'top': '40px', 'left': '50%', 'transform': 'translateX(-50%)', 'box-shadow': '0px 4px 15px rgba(0, 0, 0, 0.15)','border-radius': '15px'}"
                        [custom_radius]="'10px'"
                        [hide_arrow]="true"
                        [panel_style]="{ background: '#FFF', width: '220px' }"
                        [z_index]="{ 'z-index': '100' }"
                        >
                        {{i18n.header_vlibras_description}}
                        </app-display-panel>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- Remove in mobile -->
    <!-- <div [ngClass]="{'hidden': desktop}">
        <div class="mobile_container">
            <div class="flex flex-col">
                <div class="flex items-start">
                    <input
                        (change)="changeUserWayInput()"
                        [(ngModel)]="isUserWayChecked"
                        id="toogle"
                        style="height: 1.4rem"
                        class="my-2 relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                        type="checkbox"
                    />
                    <label class="m-2 text-14 text-customGray-dark" >{{i18n.header_activate}} userWay</label>
                </div>
                <div>
                    <span class="text-12 text-customGray-dark" >
                        {{i18n.header_userway_description}}
                    </span>
                </div>
    
                <div class="flex items-center">
                    <input
                        (change)="changeVLibrasInput()"
                        [(ngModel)]="isVLibrasChecked"
                        id="toogle"
                        style="height: 1.4rem"
                        class="my-2 relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                        type="checkbox"
                    />
                    <label class="m-2 text-14 text-customGray-dark" >{{i18n.header_activate}} V Libras</label>
                </div>
                <div>
                    <span class="text-12 text-customGray-dark" >
                        {{i18n.header_vlibras_description}}
                    </span>
                </div>
                
            </div>
        </div>
    </div> -->
</ng-container>
