import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentSharedService {
  isLoading$ = new BehaviorSubject<boolean>(false);
  gradesConfig$:Subject<any> = new BehaviorSubject<any>({});
  syncWithDrive$ = new BehaviorSubject<boolean>(true);


  constructor() { }

  

  isLoadingTrue() {
    this.isLoading$.next(true);
  }

  isLoadingFalse() {
    this.isLoading$.next(false);
  }
}
