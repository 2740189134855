import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsLtiService {

  LTISettingsItem$:Subject<any> = new BehaviorSubject<any>([]);
  isLTISettingUpdated$:Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient
  ) { }



  getLtiSettings(): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/settings/lti/`);
  }

  getLtiSetting(id:number): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/settings/lti/${id}`);
  }  

  updatetLtiSetting(params: any): Observable<any> {
    return this.http.patch(`${API_Routes.URL}/settings/lti/${params.id}`, params);
  } 

  postLtiSettings(params: any): Observable<any> {
    return this.http.post(`${API_Routes.URL}/settings/lti/`, params)
  }

  delLtiSettings(id: number): Observable<any> {
    return this.http.delete(`${API_Routes.URL}/settings/lti/${id}`)
  }
}
