import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { QuestionBankService } from '../../question-bank.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-checkbox',
  templateUrl: './create-checkbox.component.html',
  styleUrls: ['./create-checkbox.component.scss'],
})
export class CreateCheckboxComponent implements OnInit {
  @ViewChildren('dropdown') dropdowns: any;
  @Input() data: any;
  @Output() change = new EventEmitter();

  i18n: any;

  form: FormGroup;
  receiveEditor;

  alternatives: any[] = [
    { text: '', correct: false },
    { text: '', correct: false },
  ];

  constructor(
    private shared: SharedService,
    public questionBankService: QuestionBankService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    const translationModuleName = 'QuestionBank';
    this.shared.setI18n(this, translationModuleName);

    if (!this.data) return;

    this.alternatives = this.data.options;

    setTimeout(() => {
      this.dropdowns._results.forEach((dropdown, i) => {
        dropdown.title = this.questionBankService.setAlternativeNameFromContent(
          this.alternatives[i].text,
          i,
          this.i18n.question_bank_alternative
        );
      });
    }, 0);

    setTimeout(() => {
      this.receiveEditor._results.forEach((editor, i) => {
        editor._editor.setContent(this.alternatives[i].text);
      });
    }, 1000);

    this.sendData();
  }

    createForm(): void {
        this.form = this.fb.group({
            checkbox: ['']
        });
    }

  sendData() {
    const arrOfOptions = this.alternatives.map(
      (alt: { correct: boolean }, i) => ({
        text: this.alternatives[i].text,
        order: i + 1,
        correct: alt.correct ? 1 : 0,
      })
    );

    const ready =
      arrOfOptions.every((alt) => alt.text.length) &&
      arrOfOptions.some((alt) => alt.correct) &&
      arrOfOptions.length > 1;

    const payload = {
      options: arrOfOptions,
    };

    this.change.emit({
      ready,
      payload,
    });
  }

  add($randomElement) {
    const maxNumberOfAlternatives = 10;
    const $formBody = $randomElement.closest('.form-body');

    if (this.alternatives.length < maxNumberOfAlternatives)
      this.alternatives.push({ active: false });
    this.scroll($formBody);

    this.sendData();
  }

  delete($event, index) {
    $event.stopPropagation();

    this.alternatives.splice(index, 1);

    setTimeout(() => {
      this.dropdowns._results.forEach((dropdown, index) => {
        dropdown.title = this.questionBankService.setAlternativeNameFromContent(
          this.alternatives[index].text,
          index,
          this.i18n.question_bank_alternative
        );
      });
    }, 0);

    this.sendData();
  }

  setCorrectAlt(index: number) {
    this.alternatives.forEach((alt, i) => {
      if (i === index) alt.correct = !alt.correct;
    });

    this.sendData();
  }

  input(event, index, dropdown) {
    const receiveEditor = event.editor;
    const receiveEvent = event.event;

    this.questionBankService.verifyPasteImage(receiveEvent);
    setTimeout(() => {
      const editorContent = receiveEditor._editor.getContent();
      this.alternatives[index].text = editorContent;
      dropdown.title = this.questionBankService.setAlternativeNameFromContent(
        editorContent,
        index,
        this.i18n.question_bank_alternative
      );

      this.sendData();
    }, 0);
  }

  scroll($formBody) {
    const itemMainClassheight = 33;
    const flexGap = 16;

    $formBody.scrollTop += itemMainClassheight + flexGap; //this gives the scroll, is them sum of the .item__main (class) height + the flex gap
  }
}
