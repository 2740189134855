import { SharedService } from 'src/app/shared/shared.service';
import { Criterion } from './../../../section.service';
import {
  Component,
  ElementRef,
  Input,
  Output,
  ViewChildren,
  EventEmitter,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-card-rubric',
  templateUrl: './card-rubric.component.html',
  styleUrls: ['./card-rubric.component.scss'],
})
export class CardRubricComponent implements OnInit {
  @ViewChildren('level_element') levelElementsList: any;
  @Output() sendIDLevel = new EventEmitter();
  @Input() receiveCriterion: Criterion;
  i18n: any = {};

  constructor(private sharedSerivce: SharedService) {}

  ngOnInit(): void {
    this.getTranslations();
  }

  getTranslations() {
    this.i18n = { ...this.sharedSerivce.getTranslationsOf('Disciplines') };
  }

  selectLevel(element: HTMLDivElement, levelID?: number): void {
    this.levelElementsList._results.filter((e: ElementRef) =>
      e.nativeElement.classList.contains('active')
        ? e.nativeElement.classList.remove('active')
        : false
    );

    this.sendIDLevel.emit(levelID);
    element.classList.toggle('active');
  }
}
