import {
    animate, AUTO_STYLE, state, style, transition, trigger
} from '@angular/animations';
import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import {
    FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup
} from '@angular/forms';
import {
    debounceTime, distinctUntilChanged, map
} from 'rxjs';

import { DisciplinesModelsService } from '../../../shared/disciplines-models.service';
import { PaginationComponent } from '../../pagination/pagination.component';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-import-modal',
    templateUrl: './import-modal.component.html',
    styleUrls: ['./import-modal.component.scss'],
    animations: [
        trigger('collapse', [
            state('true => false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, })),
            state('false => true', style({ height: '0px', visibility: 'hidden', opacity: 0, })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`))
        ])
    ]
})
export class ImportModalComponent implements OnInit, OnChanges {
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;

    typedData = new FormControl();

    paginationParams: any = {};
    activePage = 1;
    per_page = 8;
    // page: number = 1;

    query = '';

    title: string;
    loading: boolean;
    subTitle: string;
    selectAll: string;
    available: string;
    buttonSave: string;
    modelForm: UntypedFormGroup;
    contentsFormEvent: any = [];
    collapseSection = true;
    filteredSections: any = [];
    textFound: any = [];
    alteredFilter = false;
    controlAllSelect = false;
    templateSelectionMode = true;
    templateSectionMode = false;
    templateCode: any = [];
    targetDisciplinesToInput: any[] = [];
    targetSectionsToInput: any[] = [];

    dataDisciplines: any = [];
    filtered: any = [];
    targetDisciplineName = '';
    templateDisciplineMode = false;
    templateSectionImportMode = false;

    @Input() type: type = { type: 'template' };

    @Input() isItInTheTemplates: boolean;
    @Input() contentSelectionMode = true;
    @Input() disciplinesRawData : any = [];
    @Input() disciplineCode: any = [];
    @Input() sections: any = [];
    @Input() i18n: any = [];
    @Input() receiveExternalIDContent = '';
    @Input() getNextDiscipline;

    @Output() sendingImportTemplate: EventEmitter<boolean> = new EventEmitter();
    @Output() sendingDisciplineData: EventEmitter<boolean> = new EventEmitter();
    @Output() getSectionsTemplate: EventEmitter<boolean> = new EventEmitter();
    @Output() getSectionsByDiscipline: EventEmitter<string> = new EventEmitter();
    @Output() getDisciplines: EventEmitter<boolean> = new EventEmitter();
    @Output() importTemplateInDiscipline: EventEmitter<boolean> = new EventEmitter();

    isImportModelOpen$ = this.disciplinesModelsService.isImportModelOpen$;
    isLoadingModal$ = this.disciplinesModelsService.isLoadingModal$;

    constructor(
        private disciplinesModelsService: DisciplinesModelsService,
        private formBuilder: UntypedFormBuilder,
    ) { }

    ngOnInit(): void {
        this.createContentsForm();
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
        this.getLoading();
        this.receiveTypedData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filteredSections = changes?.sections?.currentValue;
        const rawData = changes?.disciplinesRawData?.currentValue;
        this.receiveDisciplineRawData(rawData);
    }

    selectAllSections() {
        this.controlAllSelect = !this.controlAllSelect;
    }

    closeModalImport() {
        this.disciplinesModelsService.addModelModalClose();
    }

    toggleSection() {
        this.collapseSection = !this.collapseSection;
    }

    getLoading() {
        this.isLoadingModal$
            .subscribe(
                (loading) => this.loading = loading
            );
    }

    continueImport() {
        if (this.isItInTheTemplates) {
            this.contentSelectionMode = !this.contentSelectionMode;
            this.templateSelectionMode = !this.templateSelectionMode;
            this.templateDisciplineMode = !this.templateDisciplineMode;
            this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
        } else {
            this.templateDisciplineMode = true;
            this.templateSectionImportMode = false;
            this.templateSelectionMode = false;
            this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
            this.gettingSectionsTemplate();
        }
    }

    continueTargetDisciplines() {
        this.templateSelectionMode = false;
        this.templateDisciplineMode = false;
        this.templateSectionImportMode = true;
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
        this.gettingSectionsTemplate();
    }

    buttonName = '';
    continueTargetSections() {
        this.contentSelectionMode = false;
        this.templateSectionMode = true;
        this.templateSectionImportMode = true;
        this.templateSelectionMode = false;
        this.templateDisciplineMode = false;
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
    }

    continueTargetSectionsImport() {
        this.contentSelectionMode = false;
        this.templateSectionMode = false;
        this.templateSectionImportMode = true;
        this.templateSelectionMode = false;
        this.templateDisciplineMode = false;
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
        this.gettingSectionsTemplate();
    }

    countTemplate = 0;
    continueSelectedDisciplines() {
        this.templateSectionMode = true;

        this.buttonName = this.i18n.disciplines_continue;

        if (this.countTemplate === this.targetDisciplinesToInput.length - 1) {
            this.buttonName = this.i18n.disciplines_export;
        }

        if (this.countTemplate <= this.targetDisciplinesToInput.length - 1) {
            this.nextDiscipline();
            return;
        }
        this.sendEnventToImportData();
    }

    nextDiscipline() {
        const disciplineExternalId = this.targetDisciplinesToInput[this.countTemplate].event.target.value;
        this.targetDisciplineName = this.targetDisciplinesToInput[this.countTemplate].title;
        this.continueTargetSections();
        this.getSectionByDiscipline(disciplineExternalId);
        this.countTemplate += 1;
        this.targetSectionsToInput = [];
    }

    getSectionByDiscipline(disciplineExternalId: string) {
        this.getSectionsByDiscipline?.emit(disciplineExternalId);
    }

    gettingSectionsTemplate() {
        this.getSectionsTemplate?.emit();
    }

    gettingDisciplines(page, per_page) {
        const params: any = {
            page,
            per_page,
            query: this.query
        };
        this.getDisciplines?.emit(params);
    }

    receiveDisciplineRawData(disciplineData) {
        this.dataDisciplines = disciplineData?.items;
        this.activePage = disciplineData?.page;
        if (this.dataDisciplines?.length) {
            this.refreshPagination(disciplineData);
        }
    }

    search(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.value) {
            const { value } = target;
            this.alteredFilter = true;

            this.sections.forEach((section, index) => {
                section.contents.filter((content, i) => {
                    if (content.title.toLowerCase().includes(value.toLocaleLowerCase())) {
                        this.filteredSections = [this.sections[index]];
                        this.textFound = i;
                    }
                });
            });
        } else {
            this.filteredSections = this.sections;
            this.alteredFilter = false;
        }
    }

    modalMode(contentSelectionMode, templateSelectionMode) {
        if (this.isItInTheTemplates) {
            if (contentSelectionMode == true) {
                this.title = this.i18n.disciplines_export_content,
                this.subTitle = this.i18n.disciplines_select_to_export,
                this.available = this.i18n.disciplines_contents_available,
                this.selectAll = this.i18n.disciplines_select_all,
                this.buttonSave = this.i18n.disciplines_continue;
            } else {
                this.title = this.i18n.disciplines_select_classes,
                this.subTitle = this.i18n.disciplines_select_classes_to_export,
                this.available = this.i18n.disciplines_classes_available,
                this.selectAll = this.i18n.disciplines_select_all,
                this.buttonSave = this.i18n.disciplines_export;
            }
            if (this.templateSectionMode) {
                this.title = this.i18n.disciplines_import_modal_select_section,
                this.subTitle = this.i18n.disciplines_import_modal_select_section_content,
                this.buttonSave = this.i18n.disciplines_export;
            }
        } else {
            if (templateSelectionMode == false) {
                this.title = this.i18n.disciplines_import,
                this.subTitle = this.i18n.disciplines_select_to_import,
                this.available = this.i18n.disciplines_contents_available,
                this.selectAll = this.i18n.disciplines_select_all,
                this.buttonSave = this.i18n.disciplines_import;
            } else {
                this.title = this.i18n.disciplines_select_classes,
                this.subTitle = this.i18n.disciplines_select_classes_to_import,
                this.available = this.i18n.disciplines_classes_available,
                this.selectAll = this.i18n.disciplines_select_all,
                this.buttonSave = this.i18n.disciplines_continue;
            }
            if (this.templateSectionImportMode) {
                this.title = this.i18n.disciplines_import_modal_select_section,
                this.subTitle = this.i18n.disciplines_import_modal_select_section_content,
                this.buttonSave = this.i18n.disciplines_import;
            }
        }
    }

    // Call replicate post
    sendEnventToImportData() {
    // this.sendingDisciplineCode.emit(this.disciplineCode); //enviando o evento com o código da turma
        this.sendingDisciplineData.emit(this.modelForm.value); // envia dados para o post da importação
        this.closeModalImport();
    }

    sendEnventImportTemplate() {
        this.sendingImportTemplate.emit(this.modelForm.value);
    }

    createContentsForm() {
        if (this.isItInTheTemplates) {
            this.modelForm = this.formBuilder.group({
                sourceContents: [],
                sourceSections: [],
                targetDisciplines: this.formBuilder.array([]),
                targetSections: this.formBuilder.array([]),
                keepDisciplineInSync: [false],
            });
            this.modelForm?.valueChanges
                .subscribe(
                    () => {
                        this.sendingImportTemplate.emit(this.modelForm.value);
                    }
                );
        } else {
            this.modelForm = this.formBuilder.group({
                sourceContents: [],
                sourceSections: [],
                targetDisciplines: this.formBuilder.array([]),
                targetSections: this.formBuilder.array([]),
                keepDisciplineInSync: [false],
            });
            this.modelForm?.valueChanges
                .subscribe(
                    () => {
                        this.sendingImportTemplate.emit(this.modelForm.value);
                    }
                );
        }
    }

    receivesCheckedContents(dataEvent) {
        this.contentsFormEvent = dataEvent;
        this.modelForm.controls.sourceContents.setValue(this.contentsFormEvent);
    }

    receivesCheckedSection(dataEvent) {
        if (this.templateSectionMode) {
            this.modelForm.controls.targetSections.setValue(dataEvent);
        } else {
            this.modelForm.controls.sourceSections.setValue(dataEvent);
        }
    }

    checkSectionChange(event: any) {
        const targetSections: UntypedFormArray = this.modelForm?.get('targetSections') as UntypedFormArray;
        if (event?.event?.target?.checked) {
            targetSections.push(new UntypedFormControl(event?.event?.target?.value));
            this.targetSectionsToInput.push(event?.event?.target?.value);
        } else {
            let i = 0;
            targetSections.controls.forEach((item: any) => {
                if (item.value == event?.event?.target?.value) {
                    this.targetSectionsToInput.splice(this.targetSectionsToInput.indexOf(event?.event?.target?.value), 1);
                    targetSections.removeAt(i);
                    return;
                }
                i++;
            });
        }
    }

    checkDisciplineChange(event: any) {
        console.log('this.targetDisciplinesToInput ', this.targetDisciplinesToInput);
        console.log('event ', event);

        const targetDisciplines: UntypedFormArray = this.modelForm?.get('targetDisciplines') as UntypedFormArray;
        if (event?.event?.target?.checked) {
            targetDisciplines.push(new UntypedFormControl(event.event.target.value));

            this.targetDisciplinesToInput.push(event);
        } else {
            let i = 0;
            targetDisciplines.controls.forEach((item: any) => {
                console.log('item ', item);

                if (item.value === event.event.target.value) {
                    this.targetDisciplinesToInput.splice(this.targetDisciplinesToInput.indexOf(event), 1);
                    targetDisciplines.removeAt(i);
                    return;
                }
                i += 1;
            });
        }
    }

    checkTemplateChange(event: any) {
        this.targetDisciplineName = event.title;
        const targetDisciplines: UntypedFormArray = this.modelForm?.get('targetDisciplines') as UntypedFormArray;
        if (event?.event?.target?.checked) {
            targetDisciplines.push(new UntypedFormControl(event?.event?.target?.value));
            this.continueImport();
            this.gettingSectionsTemplate();
        } else {
            let i = 0;
            targetDisciplines.controls.forEach((item: any) => {
                if (item.value == event?.event?.target?.value) {
                    targetDisciplines.removeAt(i);
                    return;
                }
                i++;
            });
        }
    }

    goToPage({ activePage }): void {
        const page = activePage;

        this.gettingDisciplines(page, this.per_page);

    // if(!this.loading) {
    //   this.dataDisciplines = this.disciplinesRawData.items;
    //   this.activePage = this.disciplinesRawData.page;
    // }
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.total_items).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.activePage,
            query: this.query
        };

        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }

    receiveTypedData() {
        this.typedData.valueChanges
            .pipe(
                map((value) => value.trim()),
                // tap(value => this.searchVerification(value)),
                // filter(value => value.length > 1),
                // filter(value => value != null),
                distinctUntilChanged(),
                debounceTime(200),
                map((value: any) => this.getSearch(value)),
            ).subscribe();
    }

    getSearch(typedData) {
        typedData == undefined ? typedData = '' : typedData = typedData;
        this.query = typedData;
        this.activePage = 1;

        this.gettingDisciplines(this.activePage, this.per_page);
    }

    searchVerification(typedData) {
        /*  if(typedData.length > 1) {
      this.query = typedData
      this.gettingDisciplines(this.activePage, this.per_page);
    } else if(typedData.length < 1) {
      this.query = ''
      this.gettingDisciplinesSearch(this.activePage, this.per_page);
    } */
    }

    gettingDisciplinesSearch(page, per_page) {
        const params: any = {
            page,
            per_page,
            query: this.query
        };
        this.getDisciplines?.emit(params);
    }
}

export interface type {
    type: 'private' | 'template';
}
