import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

interface Item {
  id: string;
  translation: string;
  active: boolean;
}

@Component({
  selector: 'app-single-choice-dropdown',
  templateUrl: './single-choice-dropdown.component.html',
  styleUrls: ['./single-choice-dropdown.component.scss'],
})
export class SingleChoiceDropdownComponent implements OnDestroy, AfterViewInit {
  defaultStylization = {
    borderWidth: 1,
    closed: {
      borderColor: '#E3E4E4',
      textColor: '#233674',
      iconColor: '#233674',
    },
    expanded: {
      borderColor: '#E3E4E4',
      textColor: '#233674',
    },
  };

  originalIitems: Item[];
  @Input() items: Item[];
  @Input() stylization = this.defaultStylization;
  @Input() block: boolean = false;

  @Output() change = new EventEmitter<Item[]>();

  @ViewChild('dropdown') dropdown: ElementRef;
  dropdownExpanded: boolean = false;
  dropdownKey: symbol;
  dropdownHeight: string;

  dropdownStyle: string;
  dataId: string;

  constructor(
    private shared: SharedService,
    private modals: PlatformModalsService,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    this.calculateHeight();
    this.applyStyles();

    this.dropdownKey = this.shared.toCloseWhenClickOutside(
      '.dropdown',
      this.dropdown,
      { _this: this, propertyToBeToggled: 'dropdownExpanded' }
    );

    this.dataId = `${[...this.items].reverse()[0].translation} ${
      this.items.length
    }`;
    this.originalIitems = JSON.parse(JSON.stringify(this.items));
    this.cd.detectChanges();
  }

  calculateHeight() {
    const totalHeight =
      this.dropdown.nativeElement.scrollHeight +
      this.stylization.borderWidth * 2;
    const heightOverflowLimit = 200 + this.stylization.borderWidth * 2;
    const shouldHaveScrollBar = totalHeight > heightOverflowLimit;
    this.dropdownHeight = `--height: ${
      shouldHaveScrollBar ? heightOverflowLimit : totalHeight
    }px;`; /* setting the dropdown height when active */

    if (!shouldHaveScrollBar) {
      this.dropdown.nativeElement.style.animation = 'none';
      this.dropdown.nativeElement.style.overflow = 'hidden';
    }
    this.cd.detectChanges();
  }

  toggleDropdown() {
    this.dropdownExpanded = !this.dropdownExpanded;
    this.dropdown.nativeElement.scrollTop = 0;
  }

  toggleFilter(item: Item) {
    if (item.id === this.items[0].id) return;

    const animationDelay = 300;

    const filteredItems = this.originalIitems.filter((i) => i.id !== item.id);
    const items = [item, ...filteredItems];
    this.change.emit(items);

    setTimeout(() => {
      this.items = items;
    }, animationDelay);
  }

  applyStyles() {
    const { borderWidth, closed, expanded } = this.stylization;

    const borderWidthPx = `--border-width: ${borderWidth}px;`;

    const borderColorClosed = `--closed-border-color: ${closed.borderColor};`;
    const textColorClosed = `--closed-text-color: ${closed.textColor};`;
    const iconColorClosed = `--closed-icon-color: ${this.modals.hexToCSSFilter(
      closed.iconColor
    )};`;

    const borderColorExpanded = `--expanded-border-color: ${expanded.borderColor};`;
    const textColorExpanded = `--expanded-text-color: ${expanded.textColor};`;

    this.dropdownStyle = `${borderWidthPx} ${borderColorClosed} ${textColorClosed} ${iconColorClosed} ${borderColorExpanded} ${textColorExpanded} ${this.dropdownHeight}`;
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.dropdownKey !== undefined)
      this.shared.toCloseWhenClickOutside(this.dropdownKey);
  }
}
