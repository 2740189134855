import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Router,
  Route,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute,
  RoutesRecognized,
  UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';

import { filter, map, pairwise, take } from 'rxjs/operators';
import { PlatformModalsService } from '../services/modals/platform-modals.service';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public platModalService: PlatformModalsService
  ) {}

  // Verifica se o usuário está logado pelo token ativo
  isUserLoggedIn(state): boolean {
    
    if (!this.sharedService.fnUserHasToken() || !this.sharedService.fnUserHasValidToken()) {
      console.log('state ', state);
      this.sharedService.deleteKeyLocalStorage('token')
      localStorage.setItem('returnUrl', state)
      this.router.navigate(['']);
      this.sharedService.loggedIn.next(false);
      return false;
    }
    this.sharedService.loggedIn.next(true);
    return true;
  }

  // Verifica se está impersonificando algum user
  isUserImpersonated() {
    if (this.sharedService.fnIsThisUserImpersonated()) {
      this.sharedService.impersonating.next(true);
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {


    if (!this.isUserLoggedIn(state.url)) {
      return false;
    }

    this.isUserImpersonated();

    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    // let permissions: any = this.sharedService.getPermissions();
    let attemptedUrl = this.router.getCurrentNavigation()?.extractedUrl.toString();
    
    if (!this.isUserLoggedIn(attemptedUrl)) {
      return false;
    }
    
    
    let url: string = route?.path || '';
    let i18n = {...this.sharedService.getTranslationsOf('Errors')};
    let message = i18n.permission_unauthorized;
    let urlString: string = '';

    segments.forEach((element)=>{
      urlString += '/'+element;
    })

    // if (!this.isUserLoggedIn(urlString)) {
    //   return false;
    // }

    if (url == '') {
      return false;
    }

    if (url == 'dashboard') {
      if (!this.sharedService.checkPermission('dashboard', 'list')) {
        this.sharedService.showUnauthorizedModal(message);
        this.sharedService.logOut();
        return false;
      }
    }

    if (url == 'disciplines') {
      if (!this.sharedService.checkPermission('disciplines', 'list')) {
        this.sharedService.showUnauthorizedModal(message, 'dashboard');
        return false;
      }
    }

    if (url == 'orgunits') {
      if (!this.sharedService.checkPermission('ou', 'list')) {
        this.sharedService.showUnauthorizedModal(message, 'dashboard');
        return false;
      }
    }

    if (url == 'permissions') {
      if (!this.sharedService.checkPermission('permissioning', 'list')) {
        this.sharedService.showUnauthorizedModal(message, 'dashboard');
        return false;
      }
    }

    if (url == 'settings') {
      if (!this.sharedService.checkAtLeastOnePermission('settings', 'list')) {
        this.sharedService.showUnauthorizedModal(message, 'dashboard');
        return false;
      }
    }

    if (url == 'users') {
      if (!this.sharedService.checkPermission('users', 'list')) {
        this.sharedService.showUnauthorizedModal(message, 'dashboard');
        return false;
      }
    }

    if (url === 'content-planner') {
      if (!this.sharedService.checkPermission('plannings', 'list')) {
        this.sharedService.showUnauthorizedModal(message, 'dashboard');
        return false;
      }
    }

    if (url == 'reports') {
      // na página de relatórios para checar se o user pode acessar essa página 
      // verificamos se há permissões nos relatórios existentes na página 
      //  se houver ao menos uma permissão como true, a página aparece

      const permissions = [
        {name: 'report_center'}
      ];
      
      // Checa se há ao menos uma permissão
      let hasPermission =  permissions.some(permission => {
        return this.sharedService.checkPermission(permission.name, 'list')
      });

      if (!hasPermission) {
        this.sharedService.showUnauthorizedModal(message, 'dashboard');
        return false;
      }
    }
    
    if (url == 'reports') {
      if (!this.sharedService.checkPermission('report_center', 'list')) {
            this.sharedService.showUnauthorizedModal(message, 'dashboard');
            return false;
      }
    }

    return true;
  }
}
