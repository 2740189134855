import { MessageModalModule } from './../../components/modals/message-modal/message-modal.module';
import { DecisionModalModule } from 'src/app/components/modals/decision-modal/decision-modal.module';
import { NgModule } from '@angular/core';

import { PermissionsRoutingModule } from './permissions-routing.module';
import { PermissionsComponent } from './permissions.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalPermissionsComponent } from './modal-permissions/modal-permissions.component';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { PermissionModule } from 'src/app/directives/permission/permission.module';


@NgModule({
  declarations: [
    PermissionsComponent,
    ModalPermissionsComponent
  ],
  imports: [
    SharedModule,   
    PermissionsRoutingModule,
    GenericModalModule,
    DecisionModalModule,
    MessageModalModule,
    PermissionModule
  ]
})
export class PermissionsModule { }
