
<!-- Ementa and Participants -->
<div class="mt-8 grid lg:grid-cols-2 md:grid-cols-1 gap-4">

    <!-- Ementa  -->
    <div class="relative border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        <!-- Button  -->
        <button
            type="button" *appCheckPermissions="'disciplines'; type: 'edit'"
            class="absolute right-6 p-2 rounded-full animate-pulse" 
            [style.top]="'-15px'"
        >
            <div class="rounded-full w-8 h-8 " style="background-color: #DEDFE3;">
            </div>
        </button>
        <!--header-->
        <div class="text-start border-b border-solid border-customBlue-light rounded-t">
            <div 
                class="flex mb-2 animate-pulse"
                style="height: 22px; margin-bottom: 24px; margin-top: 24px;"
            >
                <div 
                    class="ml-5 rounded " 
                    style="background-color: #DEDFE3;"
                    [style.width]="55 + '%'"
                >
                </div>
            </div>
        </div>
        <!--body-->
        <div class="p-6 discipline-box">
            <div class="flex flex-col justify-start gap-2 animate-pulse">
                <ng-container *ngFor="let ed of ementaDescription; let i = index">
                    <span 
                        class="rounded ml-4"
                        style="background-color: #DEDFE3; height: 15px;"
                        [style.width]="ed['width'+i] + '%'"
                    ></span>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- Participants  -->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        <!--header-->
        <div class="text-start border-b border-solid border-customBlue-light rounded-t">
            <div 
                class="flex mb-2 animate-pulse"
                style="height: 22px; margin-bottom: 24px; margin-top: 24px;"
            >
                <div 
                    class="ml-5 rounded " 
                    style="background-color: #DEDFE3;"
                    [style.width]="55 + '%'"
                >
                </div>
            </div>
        </div>

        <!--body-->
        <div class="p-6 discipline-box">
            <ng-container>
                <div *ngFor="let teacher of teachers; let i = index" class="m-2 mb-2 p-2 px-6 rounded ng-star-inserted">
                    <!-- Participant -->
                    <div class="grid lg:grid-cols-2 xs:grid-cols-1 gap-2" >
                        <div class="flex lg:justify-start justify-center items-center animate-pulse">
                            <!-- Photo -->
                            <div class="rounded-full w-10 h-10 " style="background-color: #DEDFE3;">
                            </div>
                            <!-- Name -->
                            <span 
                                class="rounded ml-4"
                                style="background-color: #DEDFE3; height: 15px;"
                                [style.width]="teacher['width'+i] + '%'"
                            ></span>
                        </div>
                        <!-- Type description -->
                        <div class="flex lg:justify-end justify-center items-center animate-pulse">
                            <span 
                                class="flex items-center rounded-full pl-14 pr-14 h-8"
                                style="background-color: #DEDFE3; height: 30px; width: 45px;"
                            >
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<!-- Educators -->
<div class="rounded-lg mt-8">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        <!--header-->
        <div class="text-start border-b border-solid border-customBlue-light rounded-t">
            <div 
                class="flex mb-2 animate-pulse"
                style="height: 22px; margin-bottom: 24px; margin-top: 24px;"
            >
                <div 
                    class="ml-5 rounded " 
                    style="background-color: #DEDFE3;"
                    [style.width]="25 + '%'"
                >
                </div>
            </div>
        </div>
        <!--body-->
        <div class="grid lg:grid-cols-3 md:grid-cols-1 items-center  m-2 mb-2 p-2 px-6 gap-2">
            <ng-container>
                <div *ngFor="let teacher of teachers; let i = index">
                    <!-- Participant -->
                    <div class="grid lg:grid-cols-2 xs:grid-cols-1 gap-2 animate-pulse" >
                        <div class="flex lg:justify-start justify-center items-center">
                            <!-- Photo -->
                            <div class="rounded-full w-10 h-10 " style="background-color: #DEDFE3;">
                            </div>
                            <!-- Name -->
                            <span 
                                class="rounded ml-4"
                                style="background-color: #DEDFE3; height: 15px;"
                                [style.width]="teacher['width'+i] + '%'"
                            ></span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>