import { Component, OnInit } from '@angular/core';

import { MessengerToastService } from '../messenger-toast.service';

@Component({
    selector: 'app-messenger-toast',
    templateUrl: './messenger-toast.component.html',
    styleUrls: ['./messenger-toast.component.scss']
})
export class MessengerToastComponent implements OnInit {
    isShowToast = false;
    toastMsg = '';
    toastType = '';
    msgBtn = '';
    urlAction = '';

    constructor(private toastService: MessengerToastService) { }

    ngOnInit(): void {
        this.showToastControl();
        this.getToastMsg();
        this.getMsgBtn();
        this.getUrlAction();
        this.getToastType();
    }

    showToastControl(): void {
        this.toastService.isShowToast$
            .subscribe((value) => {
                this.isShowToast = value;
            });
    }

    getToastMsg(): void {
        this.toastService.toastMsg$
            .subscribe((value) => {
                this.toastMsg = value;
            });
    }

    getToastType(): void {
        this.toastService.toastType$
            .subscribe((value) => {
                this.toastType = value;
                this.removeEvent();
            });
    }

    getMsgBtn(): void {
        this.toastService.msgBtn$
            .subscribe((value) => {
                this.msgBtn = value;
            });
    }

    getUrlAction(): void {
        this.toastService.urlAction$
            .subscribe((value) => {
                this.urlAction = value;
            });
    }

    removeEvent(): void {
        if (this.toastType) {
            this.isShowToast = true;
            if (!this.urlAction) {
                if (this.toastType !== 'load') {
                    setTimeout(() => {
                        this.isShowToast = false;
                    }, 2000);
                }
            }
        }
    }

    clickEvent(): void {
        window.open(this.urlAction, '_blank');
        this.isShowToast = false;
    }
}
