import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-card-teacher',
  templateUrl: './card-teacher.component.html',
  styleUrls: ['./card-teacher.component.scss']
})
export class CardTeacherComponent  {
  @Input() dashData: any = [];
  @Input() user: any = [];
  @Input() i18n: any = [];
  @Output() showSchedule = new EventEmitter();
  name: any = [];
  showButton: boolean = false;

  constructor(
    private dashboardService: DashboardService,
  ) { }

  toggleSchedule() {
    this.showSchedule.emit()
  }

  routeToDisciplines(discipline_id: any) {
    this.dashboardService.toDisciplineOverview(discipline_id)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(this.dashData).length > 0) {
        this.showElements();
    }
 }

 showElements() {
  if (this.dashData.schedule.length > 0 ){
    this.showButton = true
    console.log('SHOW SCHEDULE DENTRO: ', this.showButton);
  }

/*   if (this.studentData.feed.length > 0){
    this.showFeed = true
    console.log('SHOW FEED dentro', this.showFeed);
  } */
}

}
