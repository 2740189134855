<ng-container [ngSwitch]="user.role_type">
    <app-student
        *ngSwitchCase="'student'"
        [studentData]="dashData"
        [user]="user"
        [i18n]="i18n"
    ></app-student>
    <app-teacher
        *ngSwitchCase="'teacher'"
        [dashData]="dashData"
        [user]="user"
        [i18n]="i18n"
    ></app-teacher>
    <app-admin
        *ngSwitchCase="'admin'"
        [dashData]="dashData"
        [user]="user"
        [i18n]="i18n"
    ></app-admin>
    <app-admin
        *ngSwitchCase="'coordinator'"
        [dashData]="dashData"
        [user]="user"
        [i18n]="i18n"
    ></app-admin>
</ng-container>

