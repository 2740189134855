<ng-container *appCheckPermissions="'translations';type:'list'">
    <button class="pulse container-align" *ngIf="createTranslateButton$ | async" (click)="openModalTranslate()">
        <svg width="60  " height="52" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_2459:17892)">
        <circle cx="30.5" cy="26.5" r="26.5" fill="#233674"/>
        </g>
        <path d="M30.6667 14C24.2337 14 19 19.2337 19 25.6667C19 32.0997 24.2337 37.3333 30.6667 37.3333C37.0997 37.3333 42.3333 32.0997 42.3333 25.6667C42.3333 19.2337 37.0997 14 30.6667 14ZM21.3333 25.6667C21.3333 24.6178 21.5153 23.611 21.8362 22.6695L23.6667 24.5L26 26.8333V29.1667L28.3333 31.5L29.5 32.6667V34.9195C24.9045 34.342 21.3333 30.4173 21.3333 25.6667ZM38.0517 31.3518C37.2898 30.7382 36.1348 30.3333 35.3333 30.3333V29.1667C35.3333 28.5478 35.0875 27.9543 34.6499 27.5168C34.2123 27.0792 33.6188 26.8333 33 26.8333H28.3333V23.3333C28.9522 23.3333 29.5457 23.0875 29.9832 22.6499C30.4208 22.2123 30.6667 21.6188 30.6667 21V19.8333H31.8333C32.4522 19.8333 33.0457 19.5875 33.4832 19.1499C33.9208 18.7123 34.1667 18.1188 34.1667 17.5V17.0205C37.5827 18.4077 40 21.7583 40 25.6667C39.9998 27.7255 39.3143 29.7257 38.0517 31.3518Z" fill="white"/>
        <defs>
        <filter id="filter0_d_2459:17892" x="0" y="0" width="61" height="61" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2459:17892"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2459:17892" result="shape"/>
        </filter>
        </defs>
        </svg>
    </button>
</ng-container>