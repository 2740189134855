import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { App_settings } from 'src/environments/environment';
import StackdriverErrorReporter from 'stackdriver-errors-js';

@Injectable({
    providedIn: 'root'
})
export class ErrorReportingService implements ErrorHandler {
    public errorHandler: any;
    public apiKey = '';
    public appId = '';

    constructor(
        private injector: Injector
    ) {
        this.apiKey = App_settings.API_KEY;
        this.appId = App_settings.PROJECT_ID;
        this.errorHandler = new StackdriverErrorReporter();

        this.errorHandler.start({
            key: this.apiKey,
            projectId: this.appId,

            // The following optional arguments can also be provided:

            // service: myServiceName,
            // Name of the service reporting the error, defaults to 'web'.

            // version: myServiceVersion,
            // Version identifier of the service reporting the error.

            // reportUncaughtExceptions: false
            // Set to false to prevent reporting unhandled exceptions, default: `true`.

            // reportUnhandledPromiseRejections: false
            // Set to false to prevent reporting unhandled promise rejections, default: `true`.

            // disabled: true
            // Set to true to not send error reports, this can be used when developing locally, default: `false`.

            // context: {user: this.getUser()}
            // You can set the user later using setUser()
        });
    }

    // chamada de Serviço sem instanciar via construtor por
    // conta de Error: NG0200: Circular dependency in DI detected for ApplicationRef
    // https://stackoverflow.com/questions/65645124/circular-dependency-in-di-detected-for-applicationref-how-to-fix-it
    private get sharedService() {
        return this.injector.get(SharedService);
    }

    getUser() {
        const user: any = localStorage.getItem('token') || 'usuário sem token em localStorage';
        if (user !== null) {
            this.errorHandler.setUser(user.toString());
        }
    }

    handleError(error: any): void {
        const url = window.location.href;
        this.getUser();
        if (!url.includes('localhost')) {
            this.errorHandler.report(error);
        }
        const router = this.injector.get(Router);
        console.error(error);
    }
}
