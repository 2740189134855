import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, EventEmitter, Input, OnInit, Output,
    ViewChild
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

import { DisplayPanelComponent } from '../display-panel/display-panel.component';
import { UploadInlineComponent } from '../upload-inline/upload-inline.component';

@Component({
    selector: 'app-upload-file-list',
    templateUrl: './upload-file-list.component.html',
    styleUrls: ['./upload-file-list.component.scss']
})
export class UploadFileListComponent implements OnInit, AfterViewInit {
    @ViewChild(UploadInlineComponent) UploadInlineComponent: UploadInlineComponent;

    @Input() isLoaded = true;
    @Input() showSendTitle: boolean;
    @Input() files: any = [];
    @Input() i18n;
    @Input() currentLanguage;
    @Input() showDate = true;
    @Input() showReplaceBtn = true;
    @Input() showOpenBtn = true;
    @Input() showRemoveBtn = true;
    @Input() delivery_allowed = false;
    @Input() isUploadLocked = false;
    @Input() headerText = '';
    @Input() isLoading: boolean;

    @Input() isShowUploadTab = true;
    @Input() fileType: string;

    @Output() sendReplaceFile = new EventEmitter();
    @Output() sendReplaceFileGCS = new EventEmitter();
    @Output() sendRemoveFile = new EventEmitter();

    provider;
    isPreviewModalOpen = false;
    filePreviewUrl = '';

    constructor(
        private sharedService: SharedService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.provider = localStorage.getItem('provider');
    }

    ngAfterViewInit(): void {
        this.sharedService.isLoadedUpload$.subscribe({
            next: (isLoaded: boolean) => {
                this.isLoaded = isLoaded;
                this.cd.detectChanges();
            }
        });
    }

    replaceFile(file:any) {
        this.sendReplaceFile.emit(file);
    }

    replaceFileGCS(event: any, file) {
        const fileGCS = {
            event,
            file
        };
        this.sendReplaceFileGCS.emit(fileGCS);
    }

    removeFile(file:any) {
        this.sendRemoveFile.emit(file);
    }

    @ViewChild('displayPanelWarning') displayPanelWarning! : DisplayPanelComponent;
    displayPanelObject: any = {};
    getLocationToDisplayPanel($event, file: any) {
        const width = window.innerWidth;
        const isMobile = width < 1024; // Mobile width
        if (!isMobile) {
            $event.stopPropagation();
            $event.preventDefault();
            return;
        }
        const top = $event.clientY + 10;

        this.displayPanelObject.top = `${top}px`;
        this.displayPanelObject.left = '';
        this.displayPanelObject.right = '0px';

        this.displayPanelWarning.toggle($event);
        this.textFlowMethodWarning = this.returnMessageFlowMethod(file);
    }

    textFlowMethodWarning = '';
    returnMessageFlowMethod(file: any) {
        return file.flow_method === 'platform'
            ? this.i18n.disciplines_file_sharing_warning_flow_method_platform
            : this.i18n.disciplines_file_sharing_warning_flow_method_external_drive;
    }

    defineTypeFile() {
        return 'audio/*,video/*,image/*,application/pdf';
    }

    openPreviewGoogleForms(file) {
        // if (file?.mime_type !== 'application/vnd.google-apps.form') {
        //     return;
        // }
        this.filePreviewUrl = file.path;
        this.isPreviewModalOpen = true;
    }
}
