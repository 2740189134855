<div *ngFor="let announcement of announcements"  class="animate-pulse relative my-6 m-2 p-6 rounded" style="background-color: #FFF;">
    <div 
        class="rounded my-2" 
        style="background-color: #DEDFE3; height: 20px;"
        [style.width]="announcement.width_description1 + '%'"
    >
        <span class="ml-1 font-bold text-customBlue-default" ></span>
    </div>
    <div 
        class="rounded my-2 ml-5" 
        style="background-color: #DEDFE3;  height: 15px"
        [style.width]="announcement.width_description2 + '%'"
    >
        <span class="ml-1 font-bold text-customBlue-default" ></span>
    </div>
    <div 
        class="rounded my-2 ml-5" 
        style="background-color: #DEDFE3; height: 15px"
        [style.width]="announcement.width_description3 + '%'"
    >
        <span class="ml-1 font-bold text-customBlue-default" ></span>
    </div>
    <div class="flex flex-col gap-2 justify-end py-2" >
        <div class="flex items-center justify-end">
            <div class="rounded-full w-4 h-4 " style="background-color: #DEDFE3;">
            </div>
            <span 
                class="rounded ml-2"
                style="background-color: #DEDFE3; height: 10px; width: 10%;"
            ></span>
        </div>
        <div class="flex items-center justify-end">
            <span 
                class="rounded ml-2"
                style="background-color: #DEDFE3; height: 10px; width: 7%;"
            ></span>
        </div>
    </div>
</div>