import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrgUnitsService {

  modalObject$:Subject<any> = new BehaviorSubject<any>([]);
  
  constructor(
    private http: HttpClient
    ) { }
    
  getOrgUnits(): Observable<any> {
    // return this.http.get<any>(`http://localhost:3000/orgUnits/`);
    return this.http.get<any>(`${API_Routes.URL}/ou/`);
  }

  getTypes(): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/types/`);
  }  

  postOrgUnits(params: any): Observable<any> {
    return this.http.post(`${API_Routes.URL}/ou/`, params)
  }

  updateOrgUnits(id:number, params: any): Observable<any> {
    return this.http.patch(`${API_Routes.URL}/ou/${id}`, params);
  }   

  delOrgUnits(id: any): Observable<any> {
    return this.http.delete(`${API_Routes.URL}/ou/${id}`)
  }  

}
