import { debounceTime } from 'rxjs/operators';
import { map, tap, filter, distinctUntilChanged } from 'rxjs';
import { GlobalSearchService } from './global-search.service';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  @Input() i18n: any;
  typedData =  new FormControl();
  queryResults: any = []
  openTooltip: boolean
  isNotFound: boolean;
  hasLoading: boolean = false;

  constructor (
    private globalSearchService: GlobalSearchService
  ) { }

  ngOnInit() {
    this.receiveTypedData();
  }
  
  receiveTypedData() {
    this.typedData.valueChanges
    .pipe(
      map(value => value.trim()),            // Ignora espaços
      tap(value => this.eventVerification(value.length)), // Faz a verificação para abrir a tooltip ou não
      filter(value => value.length > 2),    // Filtra a string menor que 3 caracteres
      filter(value => value != null),       // Filtra null
      distinctUntilChanged(),              // Ignora caracteres repetidos
      debounceTime(900),                   // Adiciona delay
      map((value: any) => {
        this.hasLoading = true;
        this.getSearch(value)
      }),
    ).subscribe()
  }

  getSearch(typedData) {
    this.globalSearchService.getTerm(typedData)
    .subscribe(
      {
        next: (data) => {
              this.queryResults = data
              this.notFoundQuery(data);
        },
        error: (erro) => {
              console.log(erro);
              this.hasLoading = false;
        },
        complete() {
                console.log('Completed');
        },
      }
    )
  }

  eventVerification(value) {
    if(value > 2) {
      this.openTooltip = true
    }
    if (value < 3 ) {
      this.openTooltip = false
    }
  }

  @HostListener("document:click")
  clickedOut() {
   this.openTooltip = false
  }

  routeToLink(type, result) {
    console.log('CLICADO', result);

    let sectionExternalId   = result?.section_external_id
    let disciplineId        =  result?.external_id
    let discExternalId      =  result?.disc_external_id
    let contentType         =   result?.content_type
    let contentExternalId   = result?.content_external_id
    let sectionTitle        = result?.section_title
    let first_content_type        = result?.first_content_type
    let first_content_external_id        = result?.first_content_external_id

    switch (type) {
      case 'Announcement':
              this.globalSearchService
              .toAnnouncements(discExternalId)
               // external id da disciplina
          break;
      case 'Content':
               this.globalSearchService
              .toTheContent(discExternalId, contentExternalId, sectionExternalId, sectionTitle, contentType);
              // external id da disciplina, external ID do conteúdo, external ID da section, título da section e o tipo do conteúdo
          break;
      case 'Disciplines':
               this.globalSearchService
               .toDisciplineOverview(disciplineId);
              // external id da disciplina
          break;
      case 'Series':
               this.globalSearchService
              .toTheSeries(discExternalId, first_content_external_id, sectionExternalId, sectionTitle, first_content_type);
              // external id da disciplina, external ID do conteúdo, external ID da section, título da section e o tipo do conteúdo
          break;
    }
  }

  notFoundQuery(data) {
    let announcements = data.announcements.count
    let disciplines = data.disciplines.count
    let contents = data.contents.count
    let series = data.series.count

    let countQuery = announcements + contents + disciplines + series

    console.log('announcements: ', announcements);
    console.log('contents: ', contents);
    console.log('disciplines: ', disciplines);
    console.log('series: ', series);
    console.log('countQuery: ', countQuery);

    if(countQuery == 0) {
      this.isNotFound = true
      console.log('isNotFound: ', this.isNotFound);
    } else if(countQuery != 0) {
      this.isNotFound = false
      console.log('isNotFound: ', this.isNotFound);
    }
    this.hasLoading = false;
  }
  countQuery(count: any) {
    if(count == 0 || count == 1) {
      console.log('SINGULAR: ', count);
     return count + this.i18n.search_plural
    } else if(count != 0 && count != 1) {
      console.log('Plural: ', this.i18n.search_singular);
      return count + this.i18n.search_singular
    }
  }
}
