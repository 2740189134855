import { Component, Input, OnInit } from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
    selector: 'app-content-types-icons',
    templateUrl: './content-types-icons.component.html',
    styleUrls: ['./content-types-icons.component.scss']
})
export class ContentTypesIconsComponent implements OnInit {
    @Input() type: string;
    @Input() color: string;

    defaultType = 'Assignment';
    classByType = {
        Assignment: 'book-fill-icon',
        Conference: 'camera-fill-icon',
        InternalConference: 'camera-fill-icon',
        InternalQuiz: 'checked-plate-icon',
        UploadQuiz: 'google-forms-icon',
        Scorm: 'compressed-file-icon',
        Link: 'copy-symbol-icon',
        File: 'google-drive-icon',
        TextPage: 'text-file-fill-icon',
        Youtube: 'youtube-fill-icon',
        LTI: 'chat-image-icon',
        Forum: 'double-chat-icon',
        FilePDF: 'book-open-fill-icon'
    };
    selectedClass: string;
    DOMBackground: string;
    iconColorFilter: string;

    constructor(private platsModalService: PlatformModalsService) { }

    ngOnInit(): void {
        this.setDOMClassByType();
        this.setIconColorFilter();
    }

    setDOMClassByType() {
        const typeExists = (type: string) => this.classByType.hasOwnProperty(type);
        const getClassToBeUsed = (typeExists: boolean) => (typeExists
            ? this.classByType[this.type]
            : this.classByType[this.defaultType]);

        this.selectedClass = getClassToBeUsed(typeExists(this.type));
    }

    setIconColorFilter() {
        const iconColorDoesNotExists = this.color === undefined;
        if (iconColorDoesNotExists) return;

        const generateFilterFromHex = (hexColor: string) => {
            const filterTemplate = 'filter: ';
            const filter = this.platsModalService.hexToCSSFilter(hexColor);
            return `${filterTemplate}${filter};`;
        };

        this.iconColorFilter = generateFilterFromHex(this.color);
    }
}
