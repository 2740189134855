import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {

  constructor(
    private http: HttpClient
  ) { }

  getAnnouncements(discipline_external_id: string): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/announcements`);
  }

  postAnnouncements(discipline_external_id: string, params: any): Observable<any> {
    return this.http.post<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/announcements`, params);
  }

  patchAnnouncements(discipline_external_id: string, announcement_external_id: string, params: any): Observable<any> {
    return this.http.patch<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/announcements/${announcement_external_id}`, params);
  }

  replicateAnnouncement(discipline_external_id: string, announcement_external_id: string, params: any): Observable<any> {
    return this.http.post<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/announcements/${announcement_external_id}/replicate`, params);
  }

  removeAnnouncement(discipline_external_id:string, announcement_external_id: string): Observable<any> {
    return this.http.delete<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/announcements/${announcement_external_id}`);
  }
}
