<div class="border border-customBlue-glass rounded-3xl bg-customWhite-default">
  <button class="button-opt w-full" (click)="toggleOptions()">
    <div class="button-opt__text gap-2 pl-3">
      <input
        id="toogle"
        class="options__input"
        style="margin-right: 0"
        type="checkbox"
        [checked]="collapseOption"
      />
      {{ i18n.disciplines_content_substitute_activity_title }}
      <div (click)="displayPanel.toggle()">
        <span class="iconify-inline text-18" data-icon="carbon:help"></span>
      </div>
      <div class="relative">
        <app-display-panel
          #displayPanel
          [style]="{ position: 'absolute' }"
          [custom_radius]="'10px'"
          [hide_arrow]="true"
          [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
          [newStyle]="{ top: '-5rem', left: '0' }"
        >
          <small class="font-normal text-customGray-dark">{{
            i18n.disciplines_content_activity_substitute_help
          }}</small>
        </app-display-panel>
      </div>
    </div>
  </button>
  <div class="overflow-hidden">
    <div [@collapse]="collapseOption">
      <form
        class="flex flex-col gap-2 p-2 text-customGray-dark"
        [formGroup]="form"
      >
        <div>
          <label for="activity_substitute">{{
            i18n.disciplines_content_substitute_for_activity
          }}</label>
          <select
            class="select"
            id="activity_substitute"
            formControlName="activity_substitute"
            (change)="sendInformation()"
          >
            <option
              [value]="content.external_id"
              *ngFor="let content of section"
            >
              {{ content.title }}
            </option>
          </select>
        </div>
        <div>
          <label for="recipients">{{
            i18n.disciplines_content_recipients
          }}</label>
          <select class="select" id="recipients" (click)="toggleModal()">
            <option value="#" (click)="toggleModal()">...</option>
            <option value="#" (click)="toggleModal()">{{ i18n.disciplines_content_student_select }}</option>
            <option
              (click)="toggleModal()"
              selected
              [value]="selectedStudant.external_id"
              *ngFor="let selectedStudant of studentListSelected"
            >
              {{ selectedStudant.name }}
            </option>
          </select>
        </div>
        <div>
          <label for="grade_config">{{
            i18n.disciplines_content_note_configuration
          }}</label>
          <select
            class="select"
            id="grade_config"
            (change)="sendInformation()"
            formControlName="grade_config"
          >
            <option value="higher_grade">
              {{ i18n.disciplines_content_replace_higher_grade }}
            </option>
            <option value="current_grade">
              {{ i18n.disciplines_content_replace_current_grade }}
            </option>
          </select>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Modal  -->
<app-generic-modal [isVisible]="changeModalUsers">
  <div class="py-6 modal_substitute--width">
    <header
      class="flex flex-col items-center justify-center gap-2 text-customBlue-default pb-4 border-b border-customBlue-glass px-6"
    >
      <p class="font-bold text-26">
        {{ i18n.disciplines_content_student_select }}
      </p>
      <p class="text-center">
        {{ i18n.disciplines_content_student_select_apply }}
      </p>
    </header>
    <form
      class="flex items-center rounded-lg border bg-customWhite-default border-customWhite-bWhite m-6">
      <input
        class="p-2 pl-2 rounded-lg w-full outline-none"
        type="text"
        [(ngModel)]="search_user"
        name="search_user"
        id="search_user"

      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mr-4 h-6 w-6 cursor-pointer"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#8F9BB3"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
      <button class="hidden cursor-pointer">{{ i18n.disciplines_send }}</button>
    </form>
    <ul class="px-6 flex flex-col gap-1 overflow-y-auto" style="max-height: 440px;">
      <li
        class="flex items-center justify-between py-2"
        *ngFor="let student of studentList  | filter: search_user"
      >
        <div class="flex items-center gap-2">
          <app-user-frame [img_src]="student.image" [user_name]="student.name">
          </app-user-frame>
          <p>{{ student.name }}</p>
        </div>
        <label class="container__checkbox--config flex cursor-pointer">
          <input
            type="checkbox"
            class="opacity-0"
            #checkInput
            [(ngModel)]="student.isSelected"
            (change)="isAllSelected(student, student.isSelected)"
          />
          <span class="check"></span>
        </label>
      </li>
    </ul>
    <div class="px-6 pb-6 flex justify-center" *ngIf="!studentList.length">
      <span class="text-customGray-default font-medium">
        {{ i18n.disciplines_content_substitute_activity_error_users }}</span>
    </div>
    <footer class="px-6 flex items-center justify-center gap-4">
      <button
        type="button"
        class="button-save text-customWhite-default"
        (click)="toggleModal()"
      >
        {{ i18n.disciplines_save }}
      </button>
      <button
        type="button"
        class="button-cancel font-bold text-customBlue-default"
        (click)="toggleModal()"
      >
        {{ i18n.disciplines_notices_cancel }}
      </button>
    </footer>
  </div>
</app-generic-modal>
