import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SettingsConceptsService } from '../../settings-concepts.service';

@Component({
  selector: 'app-settings-concept-modal',
  templateUrl: './settings-concept-modal.component.html',
  styleUrls: ['./settings-concept-modal.component.scss']
})
export class SettingsConceptModalComponent implements OnInit {

  @Input() isModalOpen: boolean;
  @Input() discipline_external_id: boolean;
  @Input() concept_id: boolean;
  // @Input() conceptObject: any;
  @Output() sendIsModalOpen = new EventEmitter();

  conceptsForm: UntypedFormGroup;
  statusSelected: any = null;
  conceptObject: any;
  editMode: boolean = false;

  i18n: any = [];


  constructor(
    private FormBuilder: UntypedFormBuilder,
    private settingsConceptsService: SettingsConceptsService,
    public platModalService: PlatformModalsService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.createConceptsForm();
    this.loadInputsToEdit();
  }

  getTranslations(){
    this.i18n = this.sharedService.getTranslationsOf('Grades')
  }

  createConceptsForm() {
    this.conceptsForm = this.FormBuilder.group({
      name: ['', Validators.required],
      range_start: ['', Validators.required],
      range_end: ['', Validators.required],
      status: ['', Validators.required]
    });  
  }

  get name(){
    return this.conceptsForm.get('name');
  }

  get range_start(){
    return this.conceptsForm.get('range_start');
  }

  get range_end(){
    return this.conceptsForm.get('range_end');
  }

  get status(){
    return this.conceptsForm.get('status');
  }     

  resetConceptValues() {
    this.conceptsForm.reset();
    this.statusSelected = null
  }

  statusConceptSelected(status: any){
    console.log('status ', status);
    this.statusSelected = status
    this.conceptsForm.controls['status'].setValue(status);
  }
  
  loadInputsToEdit(){
    this.sharedService.isConceptUpdated.subscribe((conceptObject: any)=>{
      console.log('conceptObject ', conceptObject);
      
      if (Object.keys(conceptObject).length > 0) {
        this.conceptObject = conceptObject;
        this.editMode = true;
        console.log('this.conceptObject ', this.conceptObject);
        this.conceptsForm.controls['name'].setValue(conceptObject?.name);
        this.conceptsForm.controls['range_start'].setValue(conceptObject?.range_start);
        this.conceptsForm.controls['range_end'].setValue(conceptObject?.range_end);
        this.statusConceptSelected(conceptObject?.status);
      }
    })
  }

  saveGeneralSettings(){
    let name = this.conceptsForm.controls.name.value; 
    let range_start = this.conceptsForm.controls.range_start.value; 
    let range_end = this.conceptsForm.controls.range_end.value; 

    const params = {
    	name: name,
	    range_start: range_start,
	    range_end: range_end,
	    status: this.statusSelected,

    }

    if (this.editMode) {
      this.settingsConceptsService.patchSettingsConcepts(this.discipline_external_id, this.conceptObject.id, params).subscribe((response)=>{
          console.log('response ', response);
          this.sharedService.editConcept$.next([]);
          
        },(err)=>{
          console.log('Erro',err);
          if (err.status == 409) {
            this.platModalService.toggle('message', 'Conflito entre notas já existentes', 'close');
          }else{
            this.platModalService.toggle('message', err.error.error, 'close');

          }

        })
      
    }else{
      this.settingsConceptsService.postSettingsConcepts(this.discipline_external_id, params).subscribe((response)=>{
        console.log('response ', response);
        this.sharedService.editConcept$.next([]);
        
      },(err)=>{
        console.log('Erro',err);
        if (err.status == 409) {
          this.platModalService.toggle('message', 'Conflito entre notas já existentes', 'close');
        }else{
          this.platModalService.toggle('message', err.error.error, 'close');

        }

      })
    }
    this.resetConceptValues();
    this.closeModal();
  }

  closeModal(){
    this.isModalOpen = false;
    this.editMode = false;
    this.sendIsModalOpen.emit(this.isModalOpen)
    this.resetConceptValues();
    this.sharedService.editConcept$.next([]);
  }

}
