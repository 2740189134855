<app-generic-modal [isVisible]="modalOpen">
    <div
        class="container flex items-center justify-center flex-col p-7 gap-7"
        [class.max-w-sm]="status !== 'generate'"
    >
        <!--content-->
        <div
            class="flex flex-col w-full bg-white outline-none focus:outline-none"
        >
            <!--body-->
            <div class="px-6 pt-6">
                <div
                    class="flex items-center justify-center filter pb-3"
                    *ngIf="status === 'success' || status === 'generate'"
                >
                    <img src="../../../assets/svg/toastr/success.svg" />
                </div>
                <div
                    class="flex items-center justify-center filter pb-3"
                    *ngIf="status === 'error'"
                >
                    <img src="../../../assets/svg/toastr/error.svg" />
                </div>
                <div
                    class="flex items-center justify-center filter pb-3"
                    *ngIf="status === 'info'"
                >
                    <img src="../../../assets/svg/toastr/info.svg" />
                </div>
                <div
                    class="flex text-center items-center justify-center flex-nowrap"
                >
                    <h3
                        *ngIf="status !== 'generate'"
                        class="text-16 font-medium font-roboto text-customGray-dark"
                    >
                        {{ messages[status] }}
                    </h3>
                    <h3
                        *ngIf="status === 'generate'"
                        class="text-16 font-medium font-roboto text-customGray-dark"
                    >
                        {{ i18n?.import_spreadsheet_generate_succes }}
                    </h3>
                </div>
            </div>

            <!--footer-->
            <ng-container *ngIf="status !== 'generate'">
                <div class="flex items-center justify-center p-6 rounded-b">
                    <button
                        class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        (click)="closeModal()"
                    >
                        {{ i18n.import_spreadsheet_btn_goback }}
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="status === 'generate'">
                <div
                    class="flex items-center justify-center p-6 rounded-b gap-3"
                >
                    <a
                        class="bg-customBlue-default text-customWhite-default border border-customBlue-default active:bg-emerald-600 font-bold text-sm px-6 py-2 rounded"
                        type="button"
                        href="{{ linkSheet }}"
                        target="_blank"
                    >
                        {{ i18n.import_spreadsheet_open_sheet }}
                    </a>
                    <button
                        class="bg-customWhite-default text-customBlue-default border border-customBlue-default font-bold text-sm px-6 py-2 rounded"
                        type="button"
                        (click)="closeModal()"
                    >
                        {{ i18n.import_spreadsheet_btn_goback }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</app-generic-modal>
