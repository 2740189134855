import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckPermissionsDirective } from './check-permissions.directive';



@NgModule({
  declarations: [
    CheckPermissionsDirective,
  ],
  imports: [
    CommonModule
  ],
  exports:[
    CheckPermissionsDirective,
  ],
})
export class PermissionModule { }
