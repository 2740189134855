<div class="container">
  <div class="homework-resume-wrapper">
    <table class="homework-resume">
      <thead class="header">
        <tr class="header__titles">
          <th class="header__title">{{ i18n.disciplines_overview_student}}</th>
          <th class="header__title">{{ i18n.disciplines_emit_status}}</th>
          <th class="header__title">{{ i18n.disciplines_content_submissions_date_deliveries}}</th>
          <th class="header__title"></th>
        </tr>
      </thead>

      <tbody class="rows">
        <div class="spacer-divisor"></div>

        <ng-container *ngFor="let submission of submissions">
          <tr class="row">
            <td class="row__student" tabindex="0">
              <div>
                <app-user-frame [img_src]="submission.user.image" [user_name]="submission.user.name"
                  [breakpoint_X]="'infinite'"></app-user-frame>
                <div class="row__student__text">{{ submission.user.name }}</div>
              </div>
            </td>

            <td class="row__status">
              <div tabindex="0" class="row__status__wrapper">
                <i class="row__status__icon"
                  [ngClass]="{
                    'check-fill-icon': submission.status.name === 'completed',
                    'circle-x-fill-icon': submission.status.name === 'pending',
                    'clock-pending-icon': submission.status.name === 'late'
                  }"
                ></i>
                <div class="row__status__text">{{ submission.status.name === 'completed' ? i18n.disciplines_completed : submission.status.name === 'pending' ? i18n.disciplines_content_submissions_pending_summary : i18n.disciplines_content_submissions_completed_late_summary }}</div>
              </div>
            </td>

            <td *ngIf="submission.course_work.at(-1)?.completed_at" class="row__date" tabindex="0">{{ getDateAndHour(submission.course_work.at(-1)?.completed_at) }}</td>
            <td *ngIf="!submission.course_work.at(-1)?.completed_at" class="row__date">- - - - - - - - - -</td>

            <td class="row__see-subs-btn"
              (click)="goToSubmissionsTab(submission)"
              [class.is-disabled]="!submission.course_work.at(-1)?.completed_at"
              [attr.tabindex]="submission.course_work.at(-1)?.completed_at ? 0 : ''">
                <div >
                  <span class="row__see-subs-btn__text">{{ i18n.disciplines_content_submissions_to_view}}</span>
                  <i class="row__arrow-icon arrow-right-icon"></i>
                </div>
            </td>
          </tr>
          <div class="spacer-divisor"></div>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>
