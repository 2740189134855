import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { SubmissionsService } from '../../submissions.service';
import { HomeworkSubmissionsState, selectHomeworkSubmissionsState } from '../../store/homework-submissions.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TimeService } from 'src/app/services/time-services.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
  selector: 'app-submission-comment',
  templateUrl: './submission-comment.component.html',
  styleUrls: ['./submission-comment.component.scss']
})
export class SubmissionCommentComponent implements OnInit {
  @Input() commentsVisibility: boolean = false;
  i18n: any = [];
  userInfo: any;
  commentInput: string = '';
  state: HomeworkSubmissionsState;
  state$: Subscription;
  courseWorkFiles: any = [];
  feedback: any = [];

  constructor(
    private sharedService: SharedService,
    private submissionService: SubmissionsService,
    private store: Store,
    private timeService: TimeService,
    private platformModalService: PlatformModalsService

  ) { }

  ngOnInit(): void {
    this.state$ = this.store.select(selectHomeworkSubmissionsState).subscribe(state => {
      this.state = state;
      if (state?.activeCourseWork?.course_work.hasOwnProperty('feedback')) {
        this.feedback = state.activeCourseWork.course_work.feedback;
      }
    });

    this.getTranslations();
    this.getUserData();
  }

  getTranslations() {
    this.i18n = {
      ...this.sharedService.getTranslationsOf('Errors'),
      ...this.sharedService.getTranslationsOf('QuestionBank'),
      ...this.sharedService.getTranslationsOf('Disciplines'),
      ...this.sharedService.getTranslationsOf('Forum'),
    };
  }

  
  getUserData() {
    const userData = this.sharedService.getUserSync();
    
    this.userInfo = {
      name: userData.name,
      image: userData.image,
      role: this.sharedService.capitalizeFirstLetter(userData.role.name) 
    };
  }

  toggleComments() {
    this.commentsVisibility = !this.commentsVisibility;
  }

  writeComment($event) {
    if (!$event.key) return;
    const $commentInput = $event.currentTarget;
    setTimeout(() => {
      this.commentInput = $commentInput.value;
    }, 150);
  }

  editComment(comment){
    this.commentInput = comment;
  }

  askForRemoveComment(){

    this.platformModalService.toggle('decision', 'deletion_subject_generic', {
			forward: () => {
				this.removeComment();
			},
			finally: () => {
				this.platformModalService.close('decision');
			},
		});
    
  }

  removeComment(){
    let content_external_id = this.state.contentID;
    let content_id = this.state.activeCourseWork.course_work.id;

    this.submissionService.removeComment(content_external_id,content_id).subscribe({
      next: (value: any)=>{
        this.feedback = [];
        this.commentInput = '';
      },
      error:(err)=>{
        let message = '';
        let errorKeys = Object.keys(err.error.error); 
        errorKeys.forEach(element => {
          err.error.error[element].forEach(element => {
            message += element + '<br>';
            
          });
        });
        this.platformModalService.toggle('message', message, 'close');

      }
    })
  }

  getTime(date) {
    return this.timeService.getTime(date);
  }

  onComment() {

    let content_external_id = this.state.contentID;
    let content_id = this.state.activeCourseWork.course_work.id;
    const params = {
      text: this.commentInput
    };

    this.submissionService.postComment(content_external_id,content_id,params).subscribe({
      next: (value: any)=>{
        this.feedback = value.course_work.feedback;
        this.commentInput = '';
      },
      error:(err)=>{
        let message = '';
        let errorKeys = Object.keys(err.error.error); 
        errorKeys.forEach(element => {
          err.error.error[element].forEach(element => {
            message += element + '<br>';
            
          });
        });
        this.platformModalService.toggle('message', message, 'close');

      }
    })
  }

}
