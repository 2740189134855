<section class="grid grid-col grid-cols-1 w-full gap-y-9 justify-center items-center">
 
    <div 
        class="flex bg-customWhite-default items-center justify-center shadow-xl rounded-lg" 
        style="height: 566px; padding-bottom: 5px"
    >
        <app-bullets-loader></app-bullets-loader>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 gap-6 w-full">
        <div class="flex bg-customWhite-default items-center justify-center shadow-xl rounded-lg" style="height: 312px;">
            <app-bullets-loader></app-bullets-loader>
        </div>

        <div class="flex bg-customWhite-default items-center justify-center shadow-xl rounded-lg" style="height: 312px;">
            <app-bullets-loader></app-bullets-loader>
        </div>

        <div class="flex bg-customWhite-default items-center justify-center shadow-xl rounded-lg" style="height: 312px;">
            <app-bullets-loader></app-bullets-loader>
        </div>

        <div class="flex bg-customWhite-default items-center justify-center shadow-xl rounded-lg" style="height: 312px;">
            <app-bullets-loader></app-bullets-loader>
        </div>
    </div>

    <!-- <ng-container *ngSwitchCase="'resources'">
        <app-large-resources
        (screen)="whatIsTheScreen($event)"
        ></app-large-resources>
    </ng-container>

    <ng-container *ngSwitchCase="'active-students'">
        <app-large-active-students
        (screen)="whatIsTheScreen($event)"
        ></app-large-active-students>
    </ng-container>

    <ng-container *ngSwitchCase="'actives'">
        <app-large-activities
        (screen)="whatIsTheScreen($event)"
        ></app-large-activities>
    </ng-container>

    <ng-container *ngSwitchCase="'certificate-reports'">
        <app-large-certificate-reports
        (screen)="whatIsTheScreen($event)"
        ></app-large-certificate-reports>
    </ng-container>

    <ng-container *ngSwitchCase="'large-grades'">
        <app-large-grades
        (screen)="whatIsTheScreen($event)"
        ></app-large-grades>
    </ng-container> -->

</section>