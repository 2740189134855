import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig, ConfirmationService, TreeNode } from 'primeng/api';

@Component({
  selector: 'app-modal-add-discipline',
  templateUrl: './modal-add-discipline.component.html',
  styleUrls: ['./modal-add-discipline.component.scss'],
})
export class ModalAddDisciplineComponent implements OnInit {
  title: string;
  modelForm: UntypedFormGroup;
  trySubmitted: boolean = false;
  isButtonActive: boolean = false;
  required = 'REQUIRED';
  selectedNodes1: any[] = [];
  orgUnitCheck: boolean = false;
  filterOrgUnits: any = [];
  postDisciplineParams: any;
  params: any;
  @Input() modalitiesSelect: any = [];
  @Input() dataDisciplines: any = [];
  @Input() editMode: boolean = false;
  @Input() segmentSelect: any = [];
  @Input() orgUnits: any = [];
  @Input() i18n: any = [];

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() sendingDisciplineData: EventEmitter<boolean> = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.createModelsForm();
    this.modalMode(this.editMode);
  }

  ngAfterViewChecked() {
    this.modelForm?.valueChanges.subscribe(
      () => {
      this.isButtonActive = true;
    })
  }

  modalClose() {
    console.log('PERMISSIONS MODAL CLOSE ');
    this.trySubmitted = false;
    this.visibleChange.next(false);
  }

  modalMode(editMode: any) {
    editMode = this.editMode;
    if (editMode) {
      this.title = this.i18n.disciplines_cert_adit;
      /*  this.setValueForm(this.externalId, this.disciplineName, this.disciplineCod) */
    } else {
      this.title = this.i18n.disciplines_cert_add;
    }
  }

  nodeSelect($event: any) {
    setTimeout(() => {
      console.log('this.selectedNodes1 ', this.selectedNodes1);
      this.filterOrgUnits = this.selectedNodes1.map(
        (array) => array?.external_id
      );
      if (this.selectedNodes1.length == 0) {
        this.orgUnitCheck = false;
      } else {
        this.orgUnitCheck = true;
      }
    }, 100);
    this.isInvalidOrgUnits();
  }

  nodeUnselect($event: any) {
    setTimeout(() => {
      console.log('this.selectedNodes1 ', this.selectedNodes1);
      if (this.selectedNodes1.length == 0) {
        this.orgUnitCheck = false;
      } else {
        this.orgUnitCheck = true;
      }
    }, 100);
    this.isInvalidOrgUnits();
  }

  createModelsForm() {
    this.modelForm = this.formBuilder.group({
      modality_external_id: ['', [Validators.required]],
      segment_external_id: ['', [Validators.required]],
      external_id: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]],
      title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]],
    });
  }

  hasError(field: any) {
    console.log('CHAMADO');
    return this.modelForm.get(field)?.errors;
  }

  formatDisciplineToSend() {
    console.log('CHAMA FORMAT DISCIPLINE');

    let external_id = this.modelForm.value.external_id;
    let title = this.modelForm.value.title;
    let modality_external_id = this.modelForm.value.modality_external_id;
    let segment_external_id = this.modelForm.value.segment_external_id;

    //this.filterOrgUnits = this.selectedNodes1.map(array => array?.external_id);

    const params = {
      title: title,
      external_id: external_id,
      level_external_id: segment_external_id,
      modality_external_id: modality_external_id,
      organizationalUnitsRelateds: this.filterOrgUnits,
      type: 'template',
    };
    this.params = params;

    this.sendingDisciplineData.emit(this.params);
  }

  /*  sendDisciplineData(params: any){
    this.sendingDisciplineData.emit(params)
  } */

   onSubmit() {
    this.trySubmitted = true;
    console.log('Form', this.modelForm.value);
    if (this.modelForm.valid && this.orgUnitCheck) {
      console.log('SUBMIT', this.modelForm.valid);
      this.formatDisciplineToSend();
    }
  }

  onCancel() {
    this.modalClose();
    this.trySubmitted = false;
  }

  isInvalidOrgUnits() {
    if(this.trySubmitted && !this.orgUnitCheck){
      return true
    } /* else if(!this.trySubmitted && !this.orgUnitCheck) {
      return true
    } */ else {
      return false
    }
  }

  textCut(limit: any, textAnnouncement: any) {
    let text = textAnnouncement;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }

  translationsSegment(segment_id: string, segment_name: string) {
    let segmentDefault = segment_name
    switch (segment_id) {
      case 'educacao-basica-ensino-fundamental-series-iniciais':
        return this.textCut(47, this.i18n?.educacao_basica_ensino_fundamental_series_iniciais)
      case 'educacao-basica-ensino-fundamental-series-finais':
        return this.textCut(47, this.i18n?.educacao_basica_ensino_fundamental_series_finais)
      case 'educacao-basica-ensino-medio':
        return this.textCut(47, this.i18n?.educacao_basica_ensino_medio)
      case 'educacao-basica-ensino-tecnico-de-nivel-medio':
        return this.textCut(47, this.i18n?.educacao_basica_ensino_tecnico_de_nivel_medio)
      case 'educacao-superior-bacharelado':
        return this.textCut(47, this.i18n?.educacao_superior_bacharelado)
      case 'educacao-superior-licenciatura':
        return this.textCut(47, this.i18n?.educacao_superior_licenciatura)
      case 'educacao-superior-tecnologia':
        return this.textCut(47, this.i18n?.educacao_superior_tecnologia)
      case 'educacao-superior-especializacao':
        return this.textCut(47, this.i18n?.educacao_superior_especializacao)
      case 'educacao-superior-mestrado':
        return this.textCut(47, this.i18n?.educacao_superior_mestrado)
      case 'educacao-superior-doutorado':
        return this.textCut(47, this.i18n?.educacao_superior_doutorado)
      case 'projeto-especial':
        return this.textCut(47, this.i18n?.projeto_especial)
      default:
        return this.textCut(47, segmentDefault);
    }
  }

  translationsModality(modality_id: any, modality_name: string) {
    let modalityDefault = modality_name;
    switch (modality_id) {
      case 'presencial':
        return this.textCut(47, this.i18n?.presencial);
      case 'semi-presencial':
        return this.textCut(47, this.i18n?.semi_presencial);
      case 'ead':
        return this.textCut(47, this.i18n?.ead);
      default:
        return this.textCut(47, modalityDefault);
    }

  }

}
