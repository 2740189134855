<div class="conference-checker mb-4">
  <i class="atention-icn"><svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2554_18883)">
    <path d="M69.0175 49.68C69.0175 51.084 68.4492 52.155 67.3124 52.893C66.1757 53.631 64.7806 54 63.1272 54H5.89813C4.24471 54 2.84963 53.622 1.7129 52.866C0.576176 52.11 0.0078125 51.048 0.0078125 49.68C0.0078125 48.6 0.379833 47.502 1.12387 46.386L29.7074 2.97C31.0715 0.99 32.6836 0 34.5437 0C36.4038 0 37.9745 0.99 39.2559 2.97L67.9015 46.44C68.6455 47.592 69.0175 48.672 69.0175 49.68ZM38.8219 22.086V14.202H30.2034V22.086C30.2034 22.59 30.2448 23.067 30.3274 23.517C30.4101 23.967 30.5238 24.471 30.6685 25.029C30.8131 25.587 30.9268 26.064 31.0095 26.46L32.6216 35.208H36.2798L37.9539 26.46C38.0365 26.1 38.1605 25.632 38.3259 25.056C38.4912 24.48 38.6152 23.967 38.6979 23.517C38.7806 23.067 38.8219 22.59 38.8219 22.086ZM38.8219 42.984C38.8219 41.94 38.3982 41.058 37.5508 40.338C36.7035 39.618 35.6804 39.258 34.4817 39.258C33.3243 39.258 32.3219 39.618 31.4745 40.338C30.6271 41.058 30.2034 41.94 30.2034 42.984C30.2034 44.028 30.6271 44.919 31.4745 45.657C32.3219 46.395 33.3243 46.764 34.4817 46.764C35.6804 46.764 36.7035 46.395 37.5508 45.657C38.3982 44.919 38.8219 44.028 38.8219 42.984Z" fill="#484B4D"/>
    </g>
    <defs>
    <clipPath id="clip0_2554_18883">
    <rect width="69.023" height="54" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  </i>

  <p>
    {{ i18n.disciplines_conference_none_recorded_found }}
  </p>

  <ng-container *ngIf="user?.role_type !== 'student'">
      <p>
        {{ i18n.disciplines_conference_send_record_google }}
      </p>
    
        <div 
            *ngIf="!isPickerOpended"
            class="google-drive-btn btn" (click)="modalOpen(); checkVideoConference()"
        >
          <i class="google-drive">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2554_18647)">
              <path d="M10.2188 16.25L6.06375 23.75H20.8438L25 16.25H10.2188ZM7.33125 2.87375L0 16.1425L4.215 23.7475L11.5475 10.4787L7.33125 2.87375ZM24.3062 15L16.6863 1.25H8.255L15.865 15H24.3062Z" fill="#233674"/>
              </g>
              <defs>
              <clipPath id="clip0_2554_18647">
              <rect width="25" height="25" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </i>
        {{ i18n.disciplines_conference_search_on_google }}
      </div>

      <div [ngClass]="{'hidden' : !isPickerOpended}">

        <app-upload-inline
            #uploadInline
            [textUploadBtn]="'Procurar no Google Drive'"
            [multiSelect]="false"
            [files]="files"
            [fileType]="'video'"
            [maxFiles]="1"
            [showDate]="false"
            [showOpenBtn]="false"
            [showReplaceBtn]="false"
            [showSendTitle]="false"
            (filesEvent)="receiveFiles($event)"
        ></app-upload-inline>

        <app-upload-file-list
            [showSendTitle]="true"
            [files]="files"
            [i18n]="i18n"
            [currentLanguage]="currentLanguage"
            [showDate]="false"
            [showOpenBtn]="false"
            [showReplaceBtn]="true"
            [headerText]="'Anexos'"
            (sendRemoveFile)="uploadInline.removeFile($event)"
            (sendReplaceFile)="uploadInline.replaceFile($event)"
        ></app-upload-file-list>
      </div>

        <div 
            *ngIf="this.files.length && !isAddingRecord"
        >
            <button 
                (click)="addRecording(this.files)"
                class="bg-customBlue-default px-12 py-2 rounded text-customWhite-default font-medium"
            >
                {{ i18n.disciplines_conference_add_file }}
            </button>
        </div>

        <ng-container *ngIf="isAddingRecord">
            <app-bullets-loader></app-bullets-loader>
        </ng-container>
  </ng-container>
</div>

<!-- MODAL -->
<div *ngIf="modalVisibility" class="modal-wrapper">
  <div [ngSwitch]="modalStatus" class="modal-box">

    <!-- MODAL-CHECKING -->
    <div class="modal-checking" *ngSwitchCase="'checking'">
      <i class="close-btn-icon" (click)="modalClose()">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.716 6.58203C12.716 6.50039 12.6492 6.43359 12.5676 6.43359L11.343 6.43916L9.49863 8.63789L7.65615 6.44102L6.42969 6.43545C6.34805 6.43545 6.28125 6.50039 6.28125 6.58389C6.28125 6.61914 6.29424 6.65254 6.3165 6.68037L8.73047 9.55635L6.3165 12.4305C6.29408 12.4577 6.28164 12.4917 6.28125 12.527C6.28125 12.6086 6.34805 12.6754 6.42969 12.6754L7.65615 12.6698L9.49863 10.4711L11.3411 12.668L12.5657 12.6735C12.6474 12.6735 12.7142 12.6086 12.7142 12.5251C12.7142 12.4898 12.7012 12.4564 12.6789 12.4286L10.2687 9.55449L12.6826 6.67852C12.7049 6.65254 12.716 6.61729 12.716 6.58203Z" fill="#A9AEB3"/>
          <path d="M9.5 1.20703C4.90957 1.20703 1.1875 4.9291 1.1875 9.51953C1.1875 14.11 4.90957 17.832 9.5 17.832C14.0904 17.832 17.8125 14.11 17.8125 9.51953C17.8125 4.9291 14.0904 1.20703 9.5 1.20703ZM9.5 16.4219C5.68887 16.4219 2.59766 13.3307 2.59766 9.51953C2.59766 5.7084 5.68887 2.61719 9.5 2.61719C13.3111 2.61719 16.4023 5.7084 16.4023 9.51953C16.4023 13.3307 13.3111 16.4219 9.5 16.4219Z" fill="#A9AEB3"/>
          </svg>
      </i>

      <i class="loading-icon"></i>

      <strong>{{ i18n.disciplines_conference_searching_on_google }}</strong>

      <p>{{ i18n.disciplines_conference_please_wait_a_moment }}</p>
    </div>

    <!-- MODAL-SUCCESS -->
    <div class="modal-success" *ngSwitchCase="'success'">
      <i class="success-icon">
        <svg width="43" height="46" viewBox="0 0 43 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.5 2L26.8853 6.0236L33.552 6.011L35.5993 12.5084L41 16.511L38.9281 23L41 29.489L35.5993 33.4916L33.552 39.989L26.8853 39.9764L21.5 44L16.1147 39.9764L9.44795 39.989L7.40066 33.4916L2 29.489L4.07189 23L2 16.511L7.40066 12.5084L9.44795 6.011L16.1147 6.0236L21.5 2Z" stroke="#80CC28" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.3203 23L19.4462 28.25L29.6981 17.75" stroke="#80CC28" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </i>

      <strong>
        {{ i18n.disciplines_conference_recording_was_found }}
        <br>
        {{ i18n.disciplines_conference_automatic }}
      </strong>

      <div class="common-btn btn" (click)="modalClose(); conferenceFound.emit()">{{ i18n.disciplines_content_report_close }}</div>
    </div>

    <!-- MODAL-SEARCH -->
    <div class="modal-search" *ngSwitchCase="'search'">

      <i class="atention-icn"><svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2554_18883)">
        <path d="M69.0175 49.68C69.0175 51.084 68.4492 52.155 67.3124 52.893C66.1757 53.631 64.7806 54 63.1272 54H5.89813C4.24471 54 2.84963 53.622 1.7129 52.866C0.576176 52.11 0.0078125 51.048 0.0078125 49.68C0.0078125 48.6 0.379833 47.502 1.12387 46.386L29.7074 2.97C31.0715 0.99 32.6836 0 34.5437 0C36.4038 0 37.9745 0.99 39.2559 2.97L67.9015 46.44C68.6455 47.592 69.0175 48.672 69.0175 49.68ZM38.8219 22.086V14.202H30.2034V22.086C30.2034 22.59 30.2448 23.067 30.3274 23.517C30.4101 23.967 30.5238 24.471 30.6685 25.029C30.8131 25.587 30.9268 26.064 31.0095 26.46L32.6216 35.208H36.2798L37.9539 26.46C38.0365 26.1 38.1605 25.632 38.3259 25.056C38.4912 24.48 38.6152 23.967 38.6979 23.517C38.7806 23.067 38.8219 22.59 38.8219 22.086ZM38.8219 42.984C38.8219 41.94 38.3982 41.058 37.5508 40.338C36.7035 39.618 35.6804 39.258 34.4817 39.258C33.3243 39.258 32.3219 39.618 31.4745 40.338C30.6271 41.058 30.2034 41.94 30.2034 42.984C30.2034 44.028 30.6271 44.919 31.4745 45.657C32.3219 46.395 33.3243 46.764 34.4817 46.764C35.6804 46.764 36.7035 46.395 37.5508 45.657C38.3982 44.919 38.8219 44.028 38.8219 42.984Z" fill="#484B4D"/>
        </g>
        <defs>
        <clipPath id="clip0_2554_18883">
        <rect width="69.023" height="54" fill="white"/>
        </clipPath>
        </defs>
        </svg>
      </i>

      <strong>
        {{ i18n.disciplines_conference_recording_not_found_automatically }}
        <br>
        {{ i18n.disciplines_conference_click_to_select_file }}
      </strong>

      <div class="buttons">
        <div class="common-btn btn" (click)="openPicker()">{{ i18n.disciplines_continue }}</div>
        <div class="naked-btn btn" (click)="modalClose()">{{ i18n.disciplines_notices_cancel }}</div>
      </div>
    </div>
  </div>
</div>

<!-- ON-ERROR -->
<p-dialog header="Erro" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
  <p>{{textError}}</p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="displayBasic=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
