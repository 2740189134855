import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { ImportSpreadsheetService } from '../import-spreadsheet.service';

@Component({
    selector: 'app-select-sheet',
    templateUrl: './select-sheet.component.html',
    styleUrls: ['./select-sheet.component.scss']
})
export class SelectSheetComponent implements OnInit {
    constructor(
        private sharedService: SharedService,
        private router: Router,
        private route: ActivatedRoute,
        private importSpreadsheetService: ImportSpreadsheetService,
    ) { }

    modalOpen$ = this.sharedService.modalOpen$;
    files: any;
    maxFiles = 1;
    hasFile = false;
    i18n: any = [];
    currentLanguage: string | null;
    modeGenerate: any;

    ngOnInit(): void {
        this.getTranslations();

        this.modeGenerate = this.route.snapshot.paramMap.get('type');
        console.log(this.modeGenerate);

        this.importSpreadsheetService.formData$.subscribe((formData) => {
            if (formData) {
                const { file } = formData;
                this.files = file;
                this.hasFile = true;
            }
        });
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('ImportSpreadsheet') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    receiveFiles($event: any) {
        this.files = $event;
        this.sharedService.replaceLinkFile(this.files);

        if (this.files && this.files.length > 0) {
            this.hasFile = true;
            this.sharedService.modalClose();
        }
    }

    openUploadSheet() {
        this.sharedService.replaceLinkFile(this.files);
        this.sharedService.modalOpen();
    }

    emptyFile() {
        this.files = [];
        this.hasFile = false;
    }

    goBack() {
        this.router.navigate(['/import-spreadsheet']);
    }
}
