<!-- MODAL HISTÓRICO DE EDIÇÃO -->
<app-generic-modal [isVisible]="isModalOpen">
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style="max-width: 33.75rem;">
        <!--header-->
        <div class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t">
            <div class="text-center">
                <h3 *ngIf="!editMode" class="text-26 font-bold text-customBlue-default">
                    {{i18n.ou_create_new_ou}}
                  </h3>
                  <h3 *ngIf="editMode" class="text-26 font-bold text-customBlue-default">
                    {{i18n.ou_edit_ou}}
                  </h3>
                  <p *ngIf="!editMode" class="mx-10 text-customBlue-default">
                    {{i18n.ou_create_new_ou_description}}               
                  </p>
                  <p *ngIf="editMode" class="mx-10 text-customBlue-default">
                    {{i18n.ou_edit_new_ou_description}}
                  </p>
            </div>
        </div>
        <!--body-->
        <ng-container >
            <form [formGroup]="orgUnitForm">
                <!--body-->
                <div class="relative p-6 md:px-10">
                    <div>
                        <div class="mt-2">
                          <span class="flex items-center gap-1 relative">
                            <label class="my-1 block text-customBlue-default font-medium">{{i18n.ou_name}}</label>
                            <button type="button" class="z-50 p-1" (click)="displayPanel.toggle()">
                              <span class="iconify-inline text-18 text-customGray-default" data-icon="carbon:help"></span>
                            </button>
                            <app-display-panel
                            class="absolute"
                            #displayPanel
                            [custom_radius]="'10px'"
                            [hide_arrow]="true"
                            [style]="{ position: 'absolute' }"
                            [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
                            [newStyle]="{ top: '-4.75rem', left: '7.75rem' }">
                            <small>{{ i18n.ou_tooltip_name }}</small>
                          </app-display-panel>
                          </span>
                          <!-- Tooltip -->
                            <input type="text" class="w-full border border-customGray-default rounded p-1.5 px-4 text-md" required formControlName="nomeOrgUnit">
                            <div class="mt-2" *ngIf="!nomeOrgUnit?.pristine && nomeOrgUnit?.errors">
                                <p class="text-xs font-bold " style="color: #E8532C;" *ngIf="nomeOrgUnit?.errors?.required">
                                    {{i18n.ou_name_required}}
                                </p>
                            </div>                        
                        </div>                    
                        <!-- <div class="mt-2">
                            <label class="w-full my-1">Nível da unidade organizacional</label>
                            <select class="w-full border border-customGray-default p-1.5 px-4 rounded-md" style="background-color: transparent;" required formControlName="tipoCampo">
                                <option *ngFor="let tipo of tipoCampo" [ngValue]="tipo.id">
                                    {{tipo.name}}
                                </option>
                            </select> 
                        </div> -->
                    </div>
                </div>
                <!--footer-->
                <div class="flex items-center justify-center p-6 rounded-b">
                  <button class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !orgUnitForm.valid }" [disabled]="!orgUnitForm.valid " (click)="postOrgUnits()">
                    {{i18n.ou_add}}
                  </button>
                  <button class="text-customGray-textGray background-transparent font-semibold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="closeModal()">
                    {{i18n.ou_cancel}}
                  </button>
                </div>
              </form>
        </ng-container>
    </div>    
</app-generic-modal>