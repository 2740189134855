
<div
  *ngIf="!isCookieEnabled$"
  class="transition ease-out duration-700 animate-fade-in-down overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex transition duration-1000 ease-in-out"
>
  <div
    class="mx-auto absolute bg-customWhite-default rounded-lg w-full"
    style="max-width: 32.3125rem;"
  >
    <div class="p-5">
      <div class="text-center">
        <span [innerHtml]='"<p>Os cookies estão bloqueados. Por favor habilite os cookies e atualize a página.</p><p>Eles são necessários para o uso da plataforma</p>"'></span>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!isCookieEnabled$"
  class="opacity-25 fixed inset-0 z-40"
  style="background-color: #000"
></div>
