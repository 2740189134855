
<app-generic-modal [minWidth]="'30%'" [maxWidth]="'70%'"  [zIndex]="'90'" [isVisible]="isVisible" >

  <div class="transition ease-out duration-700 animate-fade-in-down overflow-hidden absolute inset-0 z-35 outline-none focus:outline-none justify-center items-center flex transition duration-1000 ease-in-out"
        style="height: fit-content;"
  >
    <div class="relative my-6 mx-auto bg-customWhite-default rounded-xl" style="width: 750px;">
      <!--content-->
      <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style="min-height: 500px;">
        <!--header-->
        <div class="text-center p-5 border-b border-solid rounded-t" style="border-color: #D8E5F8;">
          <button [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isLoading || isUploadingFiles}" [disabled]="isLoading || isUploadingFiles "  (click)="closeModal()" class="flex justify-end w-full"><i class="circle-x-outline-icon w-6 h-6"></i></button>
          <h3 class="text-xl font-bold text-customBlue-default">
            {{i18n.upload_file}}
          </h3>
          <p *ngIf="provider == 'GOOGLE_CLOUD_STORAGE'" class="text-center text-sm text-customBlue-default w-2/3 mx-auto mt-3">{{i18n.upload_new_file_from_your_computer}}</p>
          <p *ngIf="provider == 'GOOGLE_DRIVE'" class="text-center text-sm text-customBlue-default w-2/3 mx-auto mt-3">{{i18n.upload_new_file_from_google_drive}}</p>
        </div>
        <!--body-->
        <div class="relative p-6 flex-auto flex justify-center items-center mx-auto" style="width: 100%;">
          <div *ngIf="!isLoading && !isUploadingFiles" style="width: 40%;">
              <button *ngIf="provider == 'GOOGLE_CLOUD_STORAGE'" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isUploadLocked}" [disabled]="isUploadLocked " 
                        class="upload-local cursor-pointer" type="button" style="width: 100%; padding: 15px;">
                <input *ngIf="!isUploadLocked"
                  id="upload-local" 
                  type="file"
                  [accept]="defineTypeFile()"
                  hidden
                  multiple
                  (change)="fileSelected($event)"
                >
                <label for="upload-local" class="flex flex-col justify-center items-center cursor-pointer" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isUploadLocked}">
                  <span class="iconify" data-icon="line-md:cloud-upload-loop"></span>
                  <p class="text-sm font-semibold text-customBlue-default">{{i18n.upload_upload_it}}</p> 
                </label>
              </button >
              <button *ngIf="provider == 'GOOGLE_DRIVE'" class="upload-drive" (click)="openGoogleDrivePicker()" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isUploadLocked}" [disabled]="isUploadLocked " style="border-color:#D8E5F8; width: 100%; padding: 1.4rem 1.75rem; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 0.75rem;">
                <i class="google-drive-icon"></i>
                <p class="text-sm font-semibold text-customBlue-default">{{i18n.upload_from_google_drive}}</p>
              </button>
              <div *ngIf="maxFiles > 0" class="text-center">
                <p class="text-sm font-semibold text-customBlue-default my-4">{{i18n.upload_max_files_number}} {{maxFiles}}</p>
              </div>
          </div>

          <div *ngIf="isUploadingFiles" class="py-6 px-6" style="border: 1px solid #D8E5F8; border-radius: 15px;">
            <div class="flex justify-center items-center">
              <div class="w-full">
                <div class="relative Progressbar bg-customGray-solitudeGray">
                  <div class="text-customWhite-default h-full font-bold" style="position: absolute; left: 50%; top: 15%;">
                    {{progressBar}}%
                  </div>
                  <div class="Progressbar__value bg-customBlue-default">
                  </div>
                  <progress value="0" max="100">100%</progress>
                </div>
              </div>
            </div>
            <div class="flex items-center mt-2 justify-center gap-2" >
              <span>{{i18n.upload_wait_until_we_load_yor_file}}</span>
            </div>
          </div>

          <div *ngIf="isLoading" style="border: 1px solid #D8E5F8; border-radius: 15px;">
            <div class="flex items-center mt-2 py-10 px-6 justify-center gap-2" >
              <div class="mx-auto">
                <img src="../../../assets/svg/loading.svg"  class="h-20" alt="" srcset="">
              </div>
              <span>{{i18n.upload_wait_until_we_load_yor_file}}</span>
            </div>
          </div>
        </div>
        <!--footer-->
        <div *ngIf="isLoaded"class="p-6 rounded-b border-t border-solid" style="border-color: #D8E5F8;">
          <div class="text-sm font-bold" style="margin-bottom: 1.625rem;">
            {{i18n.upload_uploaded}}
          </div>
          <ul style="display: grid; gap: 1.125rem; max-height: 17vh; overflow-y: auto;">
            <li *ngFor="let file of files; let i = index;" class="grid md:grid-cols-3 grid-cols-1 gap-2 justify-between" >
              <div class="flex lg:justify-start justify-center items-center gap-2" >
                <div [ngSwitch]="true" class="file-icons">
                  <div class="text-file-icon" *ngSwitchCase="file?.mime_type === 'application/vnd.google-apps.document'"></div>
                  <div class="image-file-icon" *ngSwitchCase="file?.mime_type === 'image/png' || file?.mime_type === 'image/jpg' || file?.mime_type === 'image/jpeg'"></div>
                  <div class="video-file-icon" *ngSwitchCase="file?.mime_type ==='video/mp4' || file?.mime_type ==='video/mkv' ||  file?.mime_type ==='application/vnd.google-apps.video'"></div>

                  <div class="text-file-icon" *ngSwitchDefault></div>
                </div>
                <div class="text-sm" style="display: flex; align-items: center; max-width: 13.75rem; word-break: break-word; min-height: 2.125rem;">{{file?.label}}</div>
              </div>

              <div class="flex justify-center items-center">
                <ng-container *ngIf="file?.submitted_at">

                  <span class="text-sm">
                    <ng-container *ngIf="currentLanguage != 'en'">
                      {{i18n.upload_submmited_at}} {{ file?.submitted_at | date: 'dd/MM/yyyy'}} {{i18n.upload_hours}} {{ file?.submitted_at | date: 'h:mm a'}}
                    </ng-container>
                    <ng-container *ngIf="currentLanguage == 'en'">
                      {{i18n.upload_submmited_at}} {{ file?.submitted_at | date: 'MM/dd/yyyy'}} {{i18n.upload_hours}} {{ file?.submitted_at | date: 'h:mm a'}}
                    </ng-container>
                  </span>
                </ng-container>

              </div>
              <div *ngIf="provider == 'GOOGLE_DRIVE'"class="flex justify-center items-center ml-2">
                <button (click)="replaceFile(file)" pTooltip="{{i18n.upload_replace_file}}" tooltipPosition="top" pRipple class="mr-2">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 0C4.256 0 0 4.256 0 9.5C0 14.744 4.256 19 9.5 19C14.744 19 19 14.744 19 9.5C19 4.256 14.744 0 9.5 0ZM9.5 17.1C5.3105 17.1 1.9 13.6895 1.9 9.5C1.9 5.3105 5.3105 1.9 9.5 1.9C13.6895 1.9 17.1 5.3105 17.1 9.5C17.1 13.6895 13.6895 17.1 9.5 17.1ZM13.4615 12.122L12.4165 11.077C13.091 9.8135 12.92 8.2175 11.856 7.1535C11.5476 6.84257 11.1806 6.59596 10.7761 6.42799C10.3717 6.26002 9.93793 6.17403 9.5 6.175C9.4715 6.175 9.443 6.1845 9.4145 6.1845L10.45 7.22L9.443 8.227L6.7545 5.5385L9.443 2.85L10.45 3.857L9.538 4.769C10.7445 4.7785 11.9415 5.225 12.863 6.137C14.478 7.7615 14.6775 10.279 13.4615 12.122V12.122ZM12.2455 13.4615L9.557 16.15L8.55 15.143L9.4525 14.2405C8.211 14.2308 7.02328 13.7325 6.1465 12.8535C5.37324 12.0794 4.89054 11.0624 4.77978 9.97388C4.66903 8.88537 4.93699 7.79196 5.5385 6.878L6.5835 7.923C5.909 9.1865 6.08 10.7825 7.144 11.8465C7.809 12.5115 8.6925 12.8345 9.576 12.806L8.55 11.78L9.557 10.773L12.2455 13.4615V13.4615Z" fill="#484B4D"/>
                  </svg>
                </button>
                <a href="{{file?.path}}" pTooltip="{{i18n.upload_open_file}}" tooltipPosition="top" pRipple class="mr-2" target="_blank" rel="noopener noreferrer">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.41071 2.03571C3.78082 2.03571 3.17673 2.28594 2.73134 2.73134C2.28594 3.17673 2.03571 3.78082 2.03571 4.41071V14.5893C2.03571 15.9003 3.09971 16.9643 4.41071 16.9643H14.5893C15.2192 16.9643 15.8233 16.7141 16.2687 16.2687C16.7141 15.8233 16.9643 15.2192 16.9643 14.5893V11.875C16.9643 11.605 17.0715 11.3462 17.2624 11.1553C17.4533 10.9644 17.7122 10.8571 17.9821 10.8571C18.2521 10.8571 18.511 10.9644 18.7019 11.1553C18.8928 11.3462 19 11.605 19 11.875V14.5893C19 15.7591 18.5353 16.881 17.7081 17.7081C16.881 18.5353 15.7591 19 14.5893 19H4.41071C3.24092 19 2.11904 18.5353 1.29187 17.7081C0.464699 16.881 0 15.7591 0 14.5893V4.41071C0 3.24092 0.464699 2.11904 1.29187 1.29187C2.11904 0.464699 3.24092 0 4.41071 0H7.125C7.39495 0 7.65385 0.107238 7.84473 0.298123C8.03562 0.489009 8.14286 0.747905 8.14286 1.01786C8.14286 1.28781 8.03562 1.54671 7.84473 1.73759C7.65385 1.92848 7.39495 2.03571 7.125 2.03571H4.41071ZM10.1786 1.01786C10.1786 0.747905 10.2858 0.489009 10.4767 0.298123C10.6676 0.107238 10.9265 0 11.1964 0H17.9821C18.2521 0 18.511 0.107238 18.7019 0.298123C18.8928 0.489009 19 0.747905 19 1.01786V7.80357C19 8.07352 18.8928 8.33242 18.7019 8.5233C18.511 8.71419 18.2521 8.82143 17.9821 8.82143C17.7122 8.82143 17.4533 8.71419 17.2624 8.5233C17.0715 8.33242 16.9643 8.07352 16.9643 7.80357V3.47429L11.9157 8.52286C11.8225 8.62286 11.7102 8.70307 11.5853 8.7587C11.4604 8.81433 11.3257 8.84425 11.189 8.84666C11.0523 8.84907 10.9166 8.82393 10.7898 8.77274C10.6631 8.72155 10.548 8.64535 10.4513 8.54869C10.3547 8.45204 10.2785 8.33691 10.2273 8.21017C10.1761 8.08343 10.1509 7.94767 10.1533 7.81101C10.1558 7.67434 10.1857 7.53955 10.2413 7.4147C10.2969 7.28984 10.3771 7.17747 10.4771 7.08429L15.5257 2.03571H11.1964C10.9265 2.03571 10.6676 1.92848 10.4767 1.73759C10.2858 1.54671 10.1786 1.28781 10.1786 1.01786Z" fill="#484B4D"/>
                  </svg>
                </a>
                <button (click)="removeFile(file)" pTooltip="{{i18n.upload_remove_file}}" tooltipPosition="top" pRipple >
                  <svg width="19" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.06693 6.9668C5.2349 6.9668 5.39599 7.03352 5.51476 7.1523C5.63353 7.27107 5.70026 7.43216 5.70026 7.60013V15.2001C5.70026 15.3681 5.63353 15.5292 5.51476 15.648C5.39599 15.7667 5.2349 15.8335 5.06693 15.8335C4.89896 15.8335 4.73787 15.7667 4.61909 15.648C4.50032 15.5292 4.43359 15.3681 4.43359 15.2001V7.60013C4.43359 7.43216 4.50032 7.27107 4.61909 7.1523C4.73787 7.03352 4.89896 6.9668 5.06693 6.9668ZM8.23359 6.9668C8.40156 6.9668 8.56266 7.03352 8.68143 7.1523C8.8002 7.27107 8.86693 7.43216 8.86693 7.60013V15.2001C8.86693 15.3681 8.8002 15.5292 8.68143 15.648C8.56266 15.7667 8.40156 15.8335 8.23359 15.8335C8.06562 15.8335 7.90453 15.7667 7.78576 15.648C7.66699 15.5292 7.60026 15.3681 7.60026 15.2001V7.60013C7.60026 7.43216 7.66699 7.27107 7.78576 7.1523C7.90453 7.03352 8.06562 6.9668 8.23359 6.9668ZM12.0336 7.60013C12.0336 7.43216 11.9669 7.27107 11.8481 7.1523C11.7293 7.03352 11.5682 6.9668 11.4003 6.9668C11.2323 6.9668 11.0712 7.03352 10.9524 7.1523C10.8337 7.27107 10.7669 7.43216 10.7669 7.60013V15.2001C10.7669 15.3681 10.8337 15.5292 10.9524 15.648C11.0712 15.7667 11.2323 15.8335 11.4003 15.8335C11.5682 15.8335 11.7293 15.7667 11.8481 15.648C11.9669 15.5292 12.0336 15.3681 12.0336 15.2001V7.60013Z" fill="#484B4D"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4667 3.8C16.4667 4.13594 16.3332 4.45812 16.0957 4.69567C15.8581 4.93321 15.5359 5.06667 15.2 5.06667H14.5667V16.4667C14.5667 17.1385 14.2998 17.7829 13.8247 18.258C13.3496 18.7331 12.7052 19 12.0333 19H4.43333C3.76145 19 3.11709 18.7331 2.642 18.258C2.1669 17.7829 1.9 17.1385 1.9 16.4667V5.06667H1.26667C0.930726 5.06667 0.608544 4.93321 0.370998 4.69567C0.133452 4.45812 0 4.13594 0 3.8V2.53333C0 2.19739 0.133452 1.87521 0.370998 1.63766C0.608544 1.40012 0.930726 1.26667 1.26667 1.26667H5.7C5.7 0.930726 5.83345 0.608544 6.071 0.370998C6.30854 0.133452 6.63073 0 6.96667 0L9.5 0C9.83594 0 10.1581 0.133452 10.3957 0.370998C10.6332 0.608544 10.7667 0.930726 10.7667 1.26667H15.2C15.5359 1.26667 15.8581 1.40012 16.0957 1.63766C16.3332 1.87521 16.4667 2.19739 16.4667 2.53333V3.8ZM3.31613 5.06667L3.16667 5.1414V16.4667C3.16667 16.8026 3.30012 17.1248 3.53766 17.3623C3.77521 17.5999 4.09739 17.7333 4.43333 17.7333H12.0333C12.3693 17.7333 12.6915 17.5999 12.929 17.3623C13.1665 17.1248 13.3 16.8026 13.3 16.4667V5.1414L13.1505 5.06667H3.31613ZM1.26667 3.8V2.53333H15.2V3.8H1.26667Z" fill="#484B4D"/>
                  </svg>
                </button>
              </div>

              <div *ngIf="provider == 'GOOGLE_CLOUD_STORAGE'" class="flex justify-center items-center ml-2">
                <button type="button" pTooltip="{{i18n.upload_replace_file}}" tooltipPosition="top" pRipple class="mr-2">
                  <input
                    id="replace-local" 
                    type="file"
                    [accept]="defineTypeFile()"
                    hidden
                    multiple
                    (change)="replaceFileGCS($event, file);"
                  >
                  <label for="replace-local" class="flex flex-col justify-center items-center cursor-pointer">
                    <span class="iconify w-6 h-6" data-icon="ic:baseline-autorenew"></span>
                  </label>
                </button>
                <a href="{{file?.path}}" pTooltip="{{i18n.upload_open_file}}" tooltipPosition="top" pRipple class="mr-2" target="_blank" rel="noopener noreferrer">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.41071 2.03571C3.78082 2.03571 3.17673 2.28594 2.73134 2.73134C2.28594 3.17673 2.03571 3.78082 2.03571 4.41071V14.5893C2.03571 15.9003 3.09971 16.9643 4.41071 16.9643H14.5893C15.2192 16.9643 15.8233 16.7141 16.2687 16.2687C16.7141 15.8233 16.9643 15.2192 16.9643 14.5893V11.875C16.9643 11.605 17.0715 11.3462 17.2624 11.1553C17.4533 10.9644 17.7122 10.8571 17.9821 10.8571C18.2521 10.8571 18.511 10.9644 18.7019 11.1553C18.8928 11.3462 19 11.605 19 11.875V14.5893C19 15.7591 18.5353 16.881 17.7081 17.7081C16.881 18.5353 15.7591 19 14.5893 19H4.41071C3.24092 19 2.11904 18.5353 1.29187 17.7081C0.464699 16.881 0 15.7591 0 14.5893V4.41071C0 3.24092 0.464699 2.11904 1.29187 1.29187C2.11904 0.464699 3.24092 0 4.41071 0H7.125C7.39495 0 7.65385 0.107238 7.84473 0.298123C8.03562 0.489009 8.14286 0.747905 8.14286 1.01786C8.14286 1.28781 8.03562 1.54671 7.84473 1.73759C7.65385 1.92848 7.39495 2.03571 7.125 2.03571H4.41071ZM10.1786 1.01786C10.1786 0.747905 10.2858 0.489009 10.4767 0.298123C10.6676 0.107238 10.9265 0 11.1964 0H17.9821C18.2521 0 18.511 0.107238 18.7019 0.298123C18.8928 0.489009 19 0.747905 19 1.01786V7.80357C19 8.07352 18.8928 8.33242 18.7019 8.5233C18.511 8.71419 18.2521 8.82143 17.9821 8.82143C17.7122 8.82143 17.4533 8.71419 17.2624 8.5233C17.0715 8.33242 16.9643 8.07352 16.9643 7.80357V3.47429L11.9157 8.52286C11.8225 8.62286 11.7102 8.70307 11.5853 8.7587C11.4604 8.81433 11.3257 8.84425 11.189 8.84666C11.0523 8.84907 10.9166 8.82393 10.7898 8.77274C10.6631 8.72155 10.548 8.64535 10.4513 8.54869C10.3547 8.45204 10.2785 8.33691 10.2273 8.21017C10.1761 8.08343 10.1509 7.94767 10.1533 7.81101C10.1558 7.67434 10.1857 7.53955 10.2413 7.4147C10.2969 7.28984 10.3771 7.17747 10.4771 7.08429L15.5257 2.03571H11.1964C10.9265 2.03571 10.6676 1.92848 10.4767 1.73759C10.2858 1.54671 10.1786 1.28781 10.1786 1.01786Z" fill="#484B4D"/>
                  </svg>
                </a>
                <button (click)="removeFile(file)" pTooltip="{{i18n.upload_remove_file}}" tooltipPosition="top" pRipple >
                  <svg width="19" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.06693 6.9668C5.2349 6.9668 5.39599 7.03352 5.51476 7.1523C5.63353 7.27107 5.70026 7.43216 5.70026 7.60013V15.2001C5.70026 15.3681 5.63353 15.5292 5.51476 15.648C5.39599 15.7667 5.2349 15.8335 5.06693 15.8335C4.89896 15.8335 4.73787 15.7667 4.61909 15.648C4.50032 15.5292 4.43359 15.3681 4.43359 15.2001V7.60013C4.43359 7.43216 4.50032 7.27107 4.61909 7.1523C4.73787 7.03352 4.89896 6.9668 5.06693 6.9668ZM8.23359 6.9668C8.40156 6.9668 8.56266 7.03352 8.68143 7.1523C8.8002 7.27107 8.86693 7.43216 8.86693 7.60013V15.2001C8.86693 15.3681 8.8002 15.5292 8.68143 15.648C8.56266 15.7667 8.40156 15.8335 8.23359 15.8335C8.06562 15.8335 7.90453 15.7667 7.78576 15.648C7.66699 15.5292 7.60026 15.3681 7.60026 15.2001V7.60013C7.60026 7.43216 7.66699 7.27107 7.78576 7.1523C7.90453 7.03352 8.06562 6.9668 8.23359 6.9668ZM12.0336 7.60013C12.0336 7.43216 11.9669 7.27107 11.8481 7.1523C11.7293 7.03352 11.5682 6.9668 11.4003 6.9668C11.2323 6.9668 11.0712 7.03352 10.9524 7.1523C10.8337 7.27107 10.7669 7.43216 10.7669 7.60013V15.2001C10.7669 15.3681 10.8337 15.5292 10.9524 15.648C11.0712 15.7667 11.2323 15.8335 11.4003 15.8335C11.5682 15.8335 11.7293 15.7667 11.8481 15.648C11.9669 15.5292 12.0336 15.3681 12.0336 15.2001V7.60013Z" fill="#484B4D"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4667 3.8C16.4667 4.13594 16.3332 4.45812 16.0957 4.69567C15.8581 4.93321 15.5359 5.06667 15.2 5.06667H14.5667V16.4667C14.5667 17.1385 14.2998 17.7829 13.8247 18.258C13.3496 18.7331 12.7052 19 12.0333 19H4.43333C3.76145 19 3.11709 18.7331 2.642 18.258C2.1669 17.7829 1.9 17.1385 1.9 16.4667V5.06667H1.26667C0.930726 5.06667 0.608544 4.93321 0.370998 4.69567C0.133452 4.45812 0 4.13594 0 3.8V2.53333C0 2.19739 0.133452 1.87521 0.370998 1.63766C0.608544 1.40012 0.930726 1.26667 1.26667 1.26667H5.7C5.7 0.930726 5.83345 0.608544 6.071 0.370998C6.30854 0.133452 6.63073 0 6.96667 0L9.5 0C9.83594 0 10.1581 0.133452 10.3957 0.370998C10.6332 0.608544 10.7667 0.930726 10.7667 1.26667H15.2C15.5359 1.26667 15.8581 1.40012 16.0957 1.63766C16.3332 1.87521 16.4667 2.19739 16.4667 2.53333V3.8ZM3.31613 5.06667L3.16667 5.1414V16.4667C3.16667 16.8026 3.30012 17.1248 3.53766 17.3623C3.77521 17.5999 4.09739 17.7333 4.43333 17.7333H12.0333C12.3693 17.7333 12.6915 17.5999 12.929 17.3623C13.1665 17.1248 13.3 16.8026 13.3 16.4667V5.1414L13.1505 5.06667H3.31613ZM1.26667 3.8V2.53333H15.2V3.8H1.26667Z" fill="#484B4D"/>
                  </svg>
                </button>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</app-generic-modal>
<app-decision-modal></app-decision-modal>
<app-message-modal></app-message-modal>
