import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-announcements-skeleton',
  templateUrl: './announcements-skeleton.component.html',
  styleUrls: ['./announcements-skeleton.component.scss']
})
export class AnnouncementsSkeletonComponent implements OnInit {

  announcements: any = [{},{},{}];

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.announcements.forEach(element => {
      element['width_button'] = this.sharedService.generateRandomNumber();
      element['width_description1'] = this.sharedService.generateRandomNumber();
      element['width_description2'] = this.sharedService.generateRandomNumber();
      element['width_description3'] = this.sharedService.generateRandomNumber();
    });
  }

}
