

<ng-container [ngSwitch]="user?.role_type">

    <app-teacher-grades-periods-list *ngSwitchCase="'teacher'">
    </app-teacher-grades-periods-list>

    <app-student-grades-periods-list *ngSwitchCase="'student'">
    </app-student-grades-periods-list>

    <app-teacher-grades-periods-list *ngSwitchCase="'admin'">
    </app-teacher-grades-periods-list>

    <app-teacher-grades-periods-list *ngSwitchCase="'coordinator'">
    </app-teacher-grades-periods-list>
    
</ng-container>