import { Component } from '@angular/core';

@Component({
  selector: 'app-certificates-validator',
  templateUrl: './certificates-validator.component.html',
  styleUrls: ['./certificates-validator.component.scss'],
})
export class CertificatesValidatorComponent {
  constructor() {}
}
