import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImportSpreadsheetService } from '../../import-spreadsheet.service';

@Component({
    selector: 'app-modal-help-connection',
    templateUrl: './modal-help-connection.component.html',
    styleUrls: ['./modal-help-connection.component.scss']
})
export class ModalHelpConnectionComponent implements OnInit {
    @Input() i18n: any;
    @Input() isVisible: boolean;
    @Input() modeGenerate: string;
    @Output() eventClose = new EventEmitter<void>();

    constructor(
        private importSpreadsheetService: ImportSpreadsheetService,
    ) { }

    public subtitle: string;
    public isModalLoading = false;
    public stepsTitle: string;

    ngOnInit() {
        this.subtitle = this.modeGenerate === 'connections'
            ? this.i18n?.import_spreadsheet_resume_modal_instructions_subtitle
            : this.i18n?.import_spreadsheet_resume_modal_instructions_subtitle_structure;

        this.stepsTitle = this.modeGenerate === 'connections'
            ? this.i18n?.import_spreadsheet_resume_modal_instructions_steps
            : this.i18n?.import_spreadsheet_resume_modal_instructions_steps_structure;
    }

    generate() {
        this.isModalLoading = true;
        this.importSpreadsheetService
            .generateSheet(this.modeGenerate)
            .subscribe({
                next: (resp) => {
                    this.isModalLoading = false;
                    this.closeModal();
                    this.openConfirmModal('generate', resp.url);
                },
                error: (err) => {
                    this.closeModal();
                    this.openConfirmModal('error');
                    console.log('Error', err);
                }
            });
    }

    closeModal() {
        this.eventClose.emit();
    }

    openConfirmModal(status: string, message?: string): void {
        this.importSpreadsheetService.openConfirmModal(status, message);
    }
}
