<div class="view-scale-wrapper">
  <div class="view-scale mt-2">
    <div class="lowerlabel text-customGray-dark">
      {{ data.value.lowerLabel }}
    </div>

    <div
      class="balls"
      (mouseleave)="unhoverBalls()"
      [style.pointer-events]="preSelectedBall ? 'none' : 'auto'"
    >
      <app-select-button
        class="ball"
        *ngFor="let ball of balls; let index = index"
        [active]="ball.active"
        (mouseenter)="hoverBalls(index)"
        (click)="selectBall(index)"
      ></app-select-button>
    </div>

    <div class="higherlabel text-customGray-dark">
      {{ data.value.higherLabel }}
    </div>
  </div>
</div>
