import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { HomeLayoutComponent } from 'src/app/pages/home/home.component';
import { DisciplinesModelsComponent } from './disciplines-models.component';

import { AnnouncementsComponent } from 'src/app/pages/disciplines/components/announcements/announcements.component';
import { ContentAssignmentComponent } from 'src/app/pages/disciplines/components/content/components/content-assignment/content-assignment.component';
import { ContentComponent } from 'src/app/pages/disciplines/components/content/content.component';
import { OverviewComponent } from 'src/app/pages/disciplines/components/overview/overview.component';
import { SelectTabComponent } from 'src/app/pages/disciplines/components/select-tab/select-tab.component';
import { ContentViewComponent } from '../disciplines/components/content/components/content-view/content-view.component';
import { DisciplineDetailComponent } from '../disciplines/components/disciplines-detail/disciplines-detail.component';
import { DisciplineSettingsCertificatesComponent } from '../disciplines/components/settings/components/discipline-settings-certificates/discipline-settings-certificates.component';
import { DisciplineSettingsDataComponent } from '../disciplines/components/settings/components/discipline-settings-data/discipline-settings-data.component';
import { DisciplineSettingsDetailsComponent } from '../disciplines/components/settings/components/discipline-settings-details/discipline-settings-details.component';
import { DisciplineSettingsGeneralComponent } from '../disciplines/components/settings/components/discipline-settings-general/discipline-settings-general.component';
import { DisciplineSettingsGradesComponent } from '../disciplines/components/settings/components/discipline-settings-grades/discipline-settings-grades.component';
import { DisciplineSettingsTabsComponent } from '../disciplines/components/settings/components/discipline-settings-tabs/discipline-settings-tabs.component';
import { DisciplineSettingsVisibilityComponent } from '../disciplines/components/settings/components/discipline-settings-visibility/discipline-settings-visibility.component';
import { DisciplineSettingsComponent } from '../disciplines/components/settings/discipline-settings.component';
import { DisciplinesComponent } from '../disciplines/disciplines.component';
import { HomeworkSubmissionsComponent } from '../disciplines/components/content/components/homework-submissions/homework-submissions.component';
import { HomeworkSubmissionsOverviewComponent } from '../disciplines/components/content/components/homework-submissions/components/homework-submissions-overview/homework-submissions-overview.component';
import { HomeworkSubmissionsResumeComponent } from '../disciplines/components/content/components/homework-submissions/components/homework-submissions-resume/homework-submissions-resume.component';
import { HomeworkSubmissionsSubmissionsComponent } from '../disciplines/components/content/components/homework-submissions/components/homework-submissions-submissions/homework-submissions-submissions.component';
import { ForumComponent } from '../disciplines/components/forum/forum.component';
import { ForumDetailComponent } from '../disciplines/components/forum/components/forum-detail/forum-detail.component';
import { ForumListComponent } from '../disciplines/components/forum/components/forum-list/forum-list.component';
import { ForumCommentReplyComponent } from '../disciplines/components/forum/components/forum-comment-reply/forum-comment-reply.component';
import { ForumCommentComponent } from '../disciplines/components/forum/components/forum-comment/forum-comment.component';
import { QuestionBankComponent } from '../disciplines/components/question-bank/question-bank.component';
import { GradesComponent } from '../disciplines/components/grades/grades.component';
import { GradesPeriodsListComponent } from '../disciplines/components/grades/components/grades-periods-list/grades-periods-list.component';


import { DisciplinesGuard } from '../disciplines/disciplines.guard';
import { DisciplinesSettingsGuard } from '../disciplines/components/settings/disciplines-settings.guard';
import { ForumGuard } from '../disciplines/components/forum/forum.guard';
import { ContentGuard } from '../disciplines/components/content/content.guard';
import { GradesGuard } from '../disciplines/components/grades/grades.guard';
import { GradePeriodDetailComponent } from '../disciplines/components/grades/components/grade-period-detail/grade-period-detail.component';
import { GradesOverviewComponent } from '../disciplines/components/grades/components/grades-overview/grades-overview.component';
import { GradesStudentDetailComponent } from '../disciplines/components/grades/components/grade-period-detail/components/grades-student-detail/grades-student-detail.component';
import { GradesStudentOverviewComponent } from '../disciplines/components/grades/components/grades-overview/components/grades-student-overview/grades-student-overview.component';
import { ReportsComponent } from '../disciplines/components/reports/reports.component';
import { AssignmentComponent } from '../disciplines/components/content/components/content-assignment/components/assignment/assignment.component';
import { CreateRubricaComponent } from '../disciplines/components/content/components/content-assignment/components/create-rubrica/create-rubrica.component';
import { DisciplinesTitleResolver } from '../disciplines/disciplines-title-resolver.service';
import { DisciplinesModelsTitleResolver } from './disciplines-models-title.resolver';
import { SupportMaterialViewComponent } from '../disciplines/components/content/components/content-view/support-view/support-material-view.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DisciplinesModelsComponent,
        resolve: {
          title: DisciplinesModelsTitleResolver
        },
      },
      {
        path: ':id',
        component: DisciplineDetailComponent,
        canActivate: [DisciplinesGuard],
        children: [
          {
            path: '',
            component: SelectTabComponent,
            canActivate: [DisciplinesGuard],
            children: [
              { path: '', redirectTo: 'overview', pathMatch: 'full' },
              {
                path: 'overview',
                component: OverviewComponent,
                canActivate: [DisciplinesGuard],
                resolve: {
                  title: DisciplinesTitleResolver
                },
              },
              { 
                path: 'reports', 
                component: ReportsComponent,
                resolve: {
                  title: DisciplinesTitleResolver
                },
              },
              {
                path: 'content-tab',
                component: ContentComponent,
                canActivate: [DisciplinesGuard],
                resolve: {
                  title: DisciplinesTitleResolver
                },
              },
              {
                path: 'question-bank',
                component: QuestionBankComponent,
                canActivate: [DisciplinesGuard],
                resolve: {
                  title: DisciplinesTitleResolver
                },
              },
              {
                path: 'announcements',
                component: AnnouncementsComponent,
                canActivate: [DisciplinesGuard],
                resolve: {
                  title: DisciplinesTitleResolver
                },
              },
              {
                path: 'content-assignment',
                component: ContentAssignmentComponent,
                canActivate: [DisciplinesGuard],
                resolve: {
                  title: DisciplinesTitleResolver
                },
                children: [
                  {
                    path: 'assignment',
                    component: AssignmentComponent,
                  },
                  {
                    path: 'create-rubrica',
                    component: CreateRubricaComponent,
                  },
                ],
              },
              {
                path: 'grades',
                component: GradesComponent,
                canActivate: [DisciplinesGuard],
                resolve: {
                  title: DisciplinesTitleResolver
                },
                children: [
                  {
                    path: '',
                    component: GradesPeriodsListComponent,
                    canActivate: [GradesGuard],
                  },
                  {
                    path: 'period',
                    children: [
                      {
                        path: ':id',
                        component: GradePeriodDetailComponent,
                        canActivate: [GradesGuard],
                      },
                      {
                        path: ':period_id/student/:external_id',
                        component: GradesStudentDetailComponent,
                        canActivate: [GradesGuard],
                      },
                    ],
                  },
                  {
                    path: 'periods',
                    children: [
                      {
                        path: 'overview',
                        component: GradesOverviewComponent,
                        canActivate: [GradesGuard],
                      },
                      {
                        path: 'overview/student/:external_id',
                        component: GradesStudentOverviewComponent,
                        canActivate: [GradesGuard],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'forum',
                component: ForumComponent,
                canActivate: [DisciplinesGuard],
                resolve: {
                  title: DisciplinesTitleResolver
                },
                children: [
                  {
                    path: '',
                    component: ForumListComponent,
                  },
                  {
                    path: ':id',
                    component: ForumDetailComponent,
                    canActivate: [ForumGuard],
                    children: [
                      { path: '', component: ForumCommentComponent },
                      {
                        path: 'reply',
                        children: [
                          {
                            path: ':id',
                            component: ForumCommentReplyComponent,
                            canActivate: [ForumGuard],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'discipline-settings',
                component: DisciplineSettingsComponent,
                canActivate: [DisciplinesSettingsGuard],
                data: { isRedirectedFrom: 'discipline-settings' },
                resolve: {
                  title: DisciplinesTitleResolver
                },
                children: [
                  {
                    path: '',
                    redirectTo: 'discipline-settings-data',
                    pathMatch: 'full',
                  },
                  {
                    path: 'discipline-settings-data',
                    component: DisciplineSettingsDataComponent,
                    canActivate: [DisciplinesSettingsGuard],
                  },
                  {
                    path: 'discipline-settings-visibility',
                    component: DisciplineSettingsVisibilityComponent,
                    canActivate: [DisciplinesSettingsGuard],
                  },
                  {
                    path: 'discipline-settings-tab',
                    component: DisciplineSettingsTabsComponent,
                    canActivate: [DisciplinesSettingsGuard],
                  },
                  {
                    path: 'discipline-settings-general',
                    component: DisciplineSettingsGeneralComponent,
                    canActivate: [DisciplinesSettingsGuard],
                  },
                  {
                    path: 'discipline-settings-details',
                    component: DisciplineSettingsDetailsComponent,
                    canActivate: [DisciplinesSettingsGuard],
                  },
                  {
                    path: 'discipline-settings-grades',
                    component: DisciplineSettingsGradesComponent,
                    canActivate: [DisciplinesSettingsGuard],
                  },
                  {
                    path: 'discipline-settings-certificates',
                    component: DisciplineSettingsCertificatesComponent,
                    canActivate: [DisciplinesSettingsGuard],
                  },
                ],
              },
            ],
          },
          {
            path: 'content-view',
            resolve: {
              title: DisciplinesTitleResolver
            },
            children: [
              {
                path: ':id',
                component: ContentViewComponent,
                canActivate: [ContentGuard],
              },
            ],
          },
          {
            path: 'support-material-view',
            resolve: {
              title: DisciplinesTitleResolver
            },
            children: [
              {
                path: ':id',
                component: SupportMaterialViewComponent,
                canActivate: [ContentGuard],
              },
            ],
          },
          {
            path: 'content-assignment',
            component: ContentAssignmentComponent,
            canActivate: [DisciplinesGuard],
            children: [
              {
                path: 'assignment',
                component: AssignmentComponent,
              },
              {
                path: 'create-rubrica',
                component: CreateRubricaComponent,
              },
            ],
          },
          {
            path: ':id/homework-submissions/:id',
            component: HomeworkSubmissionsComponent,
            canActivate: [ContentGuard],
            resolve: {
              title: DisciplinesTitleResolver
            },
            children: [
              { path: '', redirectTo: 'overview', pathMatch: 'full' },
              {
                path: 'overview',
                component: HomeworkSubmissionsOverviewComponent,
              },
              { path: 'resume', component: HomeworkSubmissionsResumeComponent },
              {
                path: 'submissions',
                component: HomeworkSubmissionsSubmissionsComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'homework-submissions/:id', component: HomeworkSubmissionsComponent,
        children: [
          { path: '', redirectTo: 'overview', pathMatch: 'full' },
          { path: 'overview', component: HomeworkSubmissionsOverviewComponent },
          { path: 'resume', component: HomeworkSubmissionsResumeComponent },
          { path: 'submissions', component: HomeworkSubmissionsSubmissionsComponent },
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DisciplinesModelsRoutingModule { }
