<section>
  <div [ngClass]="{ 'grid sm:grid-cols-1 lg:grid-cols-1 gap-4': !expanded }">
    <!-- Content View -->
    <ng-container *ngIf="!isLoading">
      <div [ngClass]="{ 'sm:col-span-1 lg:col-span-2 gap-4': !expanded }">
        <!-- Back to contents -->
        <button
          type="button"
          (click)="returnRouteContent()"
          class="text-customGray-default flex items-center gap-2 mb-2 w-full"
        >
          <i class="iconify-inline" data-icon="bi:chevron-left"></i>
          <span>{{ i18n.grades_back_to_overview }}</span>
        </button>

        <!-- Control contents navigation -->
        <div
          class="flex justify-center items-center py-3 px-3 text-center md:px-16 rounded-lg bg-customBlue-royal text-customWhite-default"
          style="min-height: 54px"
        >
          <span class="font-bold text-xl">
            {{ content?.title }}
          </span>
        </div>

        <!-- Content type description and expand view -->
        <div
          class="mt-3 mb-5 rounded-md border border-customBlue-glass bg-customWhite-default"
        >
          <div class="flex p-4 justify-between gap-4 text-customGray-default">
            <div class="flex items-center gap-2">
              <app-content-types-icons
                class="content-types-icons"
                [type]="content?.type"
                [color]="'#A9AEB3'"
              ></app-content-types-icons>
              <p>{{ getTraductionTypeContent(content?.type) }}</p>
            </div>
            <div class="flex items-center gap-4">
              <app-timer
                [startDate]="startDate"
                [endDate]="endDate"
                [content]="content"
              ></app-timer>
              <button (click)="expandView()">
                <div
                  [ngClass]="{
                    'text-customGray-default': !expanded,
                    'text-customOrange-default': expanded
                  }"
                >
                  <span
                    class="iconify-inline text-26"
                    data-icon="ion:expand-sharp"
                  ></span>
                </div>
              </button>
            </div>
          </div>
        </div>

        <!-- Content Rendering -->
        <div
          *ngIf="!is_locked"
          class="mb-8 rounded-md border border-customBlue-glass bg-customWhite-default"
        >
          <ng-container
            *ngIf="content?.type !== 'InternalQuiz' && content?.description"
          >
          <div class="flex">
              <div
                #textDescription
                [innerHTML]="content?.description | safeHTML"
                class="w-full mt-4 m-4"
              ></div>
          </div>
          </ng-container>

          <!-- YOUTUBE -->
          <ng-container *ngIf="content?.type === 'Youtube'">
            <div class="p-2">
              <iframe
                style="width: 100%"
                class="iframe__youtube"
                [src]="url | safeUrl"
                allowfullscreen
              ></iframe>
            </div>
          </ng-container>

          <!-- SCORM AND FILE -->
          <div *ngIf="hasIframe">
            <ng-container *ngIf="isLoadingUrl">
              <div class="flex justify-center items-center">
                <span
                  class="iconify text-customGray-default"
                  style="width: 50px; height: 50px"
                  data-icon="line-md:loading-twotone-loop"
                ></span>
              </div>
            </ng-container>

            <ng-container *ngIf="errorScorm">
              <div class="flex flex-col justify-center items-center gap-2">
                <span
                  class="iconify w-12 h-12 text-customGray-default"
                  data-icon="ri:alert-fill"
                ></span>
                <span class="text-customGray-dark text-22">{{
                  i18n.disciplines_warning_empty
                }}</span>
              </div>
            </ng-container>

            <iframe
              (load)="onLoad()"
              *ngIf="content?.type !== 'File' && content?.type !== 'UploadQuiz'"
              style="width: 100%; height: 100vh"
              [src]="url | safeUrl"
              allowfullscreen
            ></iframe>

            <ng-container *ngIf="content?.type === 'File'">
              <ng-container *ngFor="let file of content.contentSpecific.files">
                <div class="mb-4">
                  <iframe
                    width="100%"
                    [height]="file.height"
                    style="position: relative"
                    [src]="file?.url | safeUrl"
                    scrolling="auto"
                    allowfullscreen
                  ></iframe>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <!-- Support Files -->
          <!-- <div
            class="mt-8 bg-customWhite-default border border-customBlue-glass rounded-md text-customBlue-default"
            *ngIf="content?.type == 'File'"
          >
            <header
              class="flex items-center justify-center gap-2 text-19 font-bold p-4"
              role="button"
              (click)="toggleCollapseOption()"
              [ngClass]="{ 'border-b border-customBlue-glass': collapseOption }"
            >
              <p>{{ i18n.disciplines_content_view_support_materials }}</p>
              <div
                class="transform transition-all"
                [ngClass]="{
                  'rotate-90': !collapseOption,
                  'rotate--90': collapseOption
                }"
              >
                <span
                  class="iconify-inline text-16"
                  data-icon="bxs:right-arrow"
                ></span>
              </div>
            </header>
            <div class="overflow-hidden">
              <div [@collapse]="collapseOption">
                <ul class="p-4 flex flex-col gap-4">
                  <li
                    class="px-4 py-2 flex items-center justify-between gap-2 rounded shadow font-medium"
                    [ngClass]="{
                      'bg-customWhite-bWhite': i === indexFile
                    }"
                    *ngFor="
                      let file of content?.contentSpecific?.files;
                      let i = index
                    "
                  >
                    <button
                      type="button"
                      class="flex items-center gap-4 py-1"
                      (click)="changeFile(i)"
                    >
                      <app-content-types-icons
                        [type]="returnIconMaterialsSupport(file.mime_type)"
                        [color]="'#233674'"
                      ></app-content-types-icons>
                      <p>{{ file.label }}</p>
                    </button>
                    <button
                      type="button"
                      class="flex items-center gap-2 px-2 py-1 font-medium border-l border-customBlue-light"
                      (click)="getGoogleFile(file)"
                    >
                      <p>{{ i18n.disciplines_content_view_download }}</p>
                      <span
                        class="iconify-inline text-22"
                        data-icon="ic:sharp-cloud-download"
                      ></span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </ng-container>
  </div>
</section>
