import { NgModule } from '@angular/core';
import { EditorModule } from '@tinymce/tinymce-angular';

import { LoginRoutingModule } from './login-routing.module';

import { LoginComponent } from './login.component';
import { CountdownComponentModule } from 'src/app/components/countdown/countdown.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { MessageModalModule } from 'src/app/components/modals/message-modal/message-modal.module';
import { GoogleLoginComponent } from './components/google-login/google-login.component';
import { MicrosoftLoginComponent } from './components/microsoft-login/microsoft-login.component';
import { StandaloneLoginComponent } from './components/standalone-login/standalone-login.component';
import { ModalResetPasswordComponent } from './components/standalone-login/components/modal-reset-password/modal-reset-password.component';
import { ModalResetValidationCodeComponent } from './components/standalone-login/components/modal-reset-validation-code/modal-reset-validation-code.component';
import { HelpLinksComponent } from './components/help-links/help-links.component';
import { UseTermsComponent } from './components/use-terms/use-terms.component';
import { ModalUseTermsComponent } from './components/use-terms/components/modal-use-terms/modal-use-terms.component';
import { ModalPolicyAndPrivacyComponent } from './components/use-terms/components/modal-policy-and-privacy/modal-policy-and-privacy.component';
import { ThirdPartyCookieModule } from 'src/app/components/third-party-cookie/third-party-cookie.module';
import { SSOLoginComponent } from './components/sso-login/sso-login.component';
import { AccessibilityLoginComponent } from './components/accessibility-login/accessibility-login.component';


@NgModule({
  declarations: [
    LoginComponent,
    GoogleLoginComponent,
    MicrosoftLoginComponent,
    SSOLoginComponent,
    StandaloneLoginComponent,
    ModalResetPasswordComponent,
    ModalResetValidationCodeComponent,
    HelpLinksComponent,
    UseTermsComponent,
    ModalUseTermsComponent,
    ModalPolicyAndPrivacyComponent,
    AccessibilityLoginComponent
  ],
  exports: [
    ModalUseTermsComponent,
    ModalPolicyAndPrivacyComponent,
  ],
  imports: [
    SharedModule,
    LoginRoutingModule,
    CountdownComponentModule,
    GenericModalModule,
    MessageModalModule,
    EditorModule,
    ThirdPartyCookieModule
  ]
})
export class LoginModule { }
