import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-active-students',
  templateUrl: './active-students.component.html',
  styleUrls: ['./active-students.component.scss']
})
export class ActiveStudentsComponent implements OnInit, OnChanges {
  activeStudents: any = []
  formatedActStudents: any = []
  activeSum: any = []
  isActStudents: boolean
  i18n: any = [];
  currentLanguage: any;

  @Input() reportsData: any = []
  @Output() screen = new EventEmitter();

  view: any[];

  // options
  yScaleMax = 1
  showXAxis = true;
  showYAxis = false;
  animations = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Country';
  showYAxisLabel = false;
  rotateXAxisTicks = true;
  yAxisLabel = 'Population';
  maxXAxisTickLength = 10
  showDataLabel=true
  barPadding = 30

  colorScheme = {
    domain: ['#233674'],
  };

  constructor(
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.reportsData.currentValue?.ACTIVE_STUDENTS?.WEEKDAYS != undefined) {
      this.activeStudents = changes.reportsData.currentValue?.ACTIVE_STUDENTS?.WEEKDAYS;
      this.dataActiveSum();
      this.formatDataAverageTime();
    }
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Reports'),...this.sharedService.getTranslationsOf('Modal')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  dataActiveSum() {
    let studentsData = this.activeStudents;
    let sum = 0;

    for(var i = 0; i < studentsData.length; i++) {
      sum += studentsData[i].percentage;
      console.log('SOMA FOR', studentsData[i].percentage);
    }

    this.activeSum = sum;
    this.verifyActStudents();
  }

  verifyActStudents() {
    if(this.reportsData?.ACTIVE_STUDENTS?.length == 0 && this.activeSum == 0) {
      this.isActStudents = false
    } else if(this.reportsData?.ACTIVE_STUDENTS?.length != 0 && this.activeSum != 0) {
      this.isActStudents = true
    }
  }


formatDataAverageTime() {
   const studentsData = this.activeStudents;

      this.formatedActStudents = studentsData
      .map(data => {
        let label
        let percentage

        if(data.label != null && data.label != undefined ) {
          label = data.label
        } else{
          label = ''
        }

        if(data.percentage != null && data.percentage != undefined ) {
          percentage = data.percentage
        } else{
          percentage = 0
        }

        return {
          name: label,
          value: percentage
        };
      })
         console.log('TAMANHO DA ARRAY: ', this.formatedActStudents.length);
        if (this.formatedActStudents.length < 7) {
          this.formatedActStudents.push(
              {
              "name": "                           ",
              "value": ''
            },
            {
              "name": "                      ",
              "value": ''
            },
          )
      }
      else if (this.formatedActStudents.length <= 4) {
        this.formatedActStudents.push(
            {
            "name": "                           ",
            "value": ''
          },
        )
    }
      else if (this.formatedActStudents.length >= 7) {
        this.formatedActStudents.push(

        )
    }
    }

  formatDataLabel(val: any)
  {
    if (val == '') {
      return ''
    } else if (val != '') {
      const value: number = parseFloat(val) * 100;
      return parseFloat(value.toFixed(2))  + ' %';
    }

  }

  yTickFormattingFn = this.yAxisTickFormatting.bind(this);

  yAxisTickFormatting(value){
    return this.yPercentTickFormatting(value);
  }

    yPercentTickFormatting(val: any) {
    val = val * 100
    return val  + ' %';
  }

  xTickFormattingFn = this.xAxisTickFormatting.bind(this);

  xAxisTickFormatting(value){
    return this.xPercentTickFormatting(value);
  }

  xPercentTickFormatting(val: any) {
    return this.whatIsTheDay(val)
  }

  whatIsTheDay(day: any) {
    if (this.currentLanguage == 'es') {
      switch (day) {
        case 'lun':
                  return this.i18n.reports_seg
        case 'mar':
                  return this.i18n.reports_ter
        case 'mie':
                  return this.i18n.reports_qua
        case 'jue':
                  return this.i18n.reports_qui
        case 'vie':
                  return this.i18n.reports_sex
        case 'sáb':
                  return this.i18n.reports_sab
        case 'dom':
                  return this.i18n.reports_dom
        default:
        return day
      }
    } else if(this.currentLanguage == 'en') {
      switch (day) {
        case 'mon':
                  return this.i18n.reports_seg
        case 'tue':
                  return this.i18n.reports_ter
        case 'wed':
                  return this.i18n.reports_qua
        case 'thu':
                  return this.i18n.reports_qui
        case 'fri':
                  return this.i18n.reports_sex
        case 'sat':
                  return this.i18n.reports_sab
        case 'sun':
                  return this.i18n.reports_dom
        default:
        return day
      }
    } else {
        switch (day) {
          case 'seg':
                    return this.i18n.reports_seg
          case 'ter':
                    return this.i18n.reports_ter
          case 'qua':
                    return this.i18n.reports_qua
          case 'qui':
                    return this.i18n.reports_qui
          case 'sex':
                    return this.i18n.reports_sex
          case 'sáb':
                    return this.i18n.reports_sab
          case 'dom':
                    return this.i18n.reports_dom
          default:
          return day
      } 
    }
  }

  showBiggerScreen() {
    this.screen.emit('active-students')
  }
}
