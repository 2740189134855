<!-- Ementa Modal  -->
<app-generic-modal [isVisible]="isVisibleModalEmenta">
    <div class="py-6 sm:w-screen container-modal">
      <header
        class="text-customBlue-default font-medium text-26 border-b border-customBlue-glass pb-4 px-6">
        <p class="text-center">{{i18n.disciplines_overview_course_program}}</p>
      </header>
      <form class="p-6 flex flex-col gap-1" [formGroup]="form">
        <label for="description" class="text-customBlue-default font-medium">{{ i18n.disciplines_content_description_text }}</label>
        <app-editor [height]="300" *ngIf="resetEditor" [form]="form" [formControlName]="'description'"></app-editor>
      </form>
      <footer class="flex items-center justify-center gap-4 px-6">
        <button type="button" (click)="updateDisciplineDescription()" [disabled]="form.invalid" [ngClass]="{'opacity-70 cursor-not-allowed': form.invalid}"
         class="px-10 py-2 text-customWhite-default font-bold bg-customBlue-default rounded">{{ i18n.disciplines_save }}</button>
        <button type="button" class="px-6 py-2 text-customBlue-default font-bold" (click)="closeModal()">{{ i18n.disciplines_cancel }}</button>
      </footer>
    </div>
</app-generic-modal>