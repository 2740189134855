import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { OrgUnitsService } from '../../../org-units.service';

@Component({
  selector: 'app-modal-org-units',
  templateUrl: './modal-org-units.component.html',
  styleUrls: ['./modal-org-units.component.scss'],
})
export class ModalOrgUnitsComponent implements OnInit, OnDestroy {
  orgUnitForm: UntypedFormGroup;
  selectedNode: any;
  editMode: boolean = false;
  unsubModalObject$: Subscription;
  i18n: any;

  @Input() isModalOpen: boolean = false;
  @Output() sendIsModalOpen = new EventEmitter();
  @Output() sendGetOrgUnits = new EventEmitter();

  constructor(
    private FormBuilder: UntypedFormBuilder,
    private orgUnitsService: OrgUnitsService,
    private sharedService: SharedService,
    public platModalService: PlatformModalsService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.createOrgUnitForm();
    this.getModalObject();
  }

  getTranslations() {
    this.i18n = this.sharedService.getTranslationsOf('OrgUnits');
  }

  getModalObject() {
    this.unsubModalObject$ = this.orgUnitsService.modalObject$.subscribe(
      (modalObject) => {
        if (Object.keys(modalObject).length) {
          this.selectedNode = modalObject.node;
          this.editMode = modalObject.editMode;
          this.orgUnitForm.reset();
          if (modalObject.editMode) {
            this.orgUnitForm.controls['nomeOrgUnit'].setValue(
              this.selectedNode.label
            );
          }
        }
      }
    );
  }

  //Form
  createOrgUnitForm() {
    this.orgUnitForm = this.FormBuilder.group({
      nomeOrgUnit: ['', [Validators.required]],
    });
  }

  get nomeOrgUnit() {
    return this.orgUnitForm.get('nomeOrgUnit');
  }

  postOrgUnits() {
    let nomeOrgUnit = this.orgUnitForm.controls.nomeOrgUnit.value;

    if (this.editMode) {
      const params = {
        name: nomeOrgUnit,
      };
      this.orgUnitsService
        .updateOrgUnits(this.selectedNode.external_id, params)
        .subscribe(() => {
          this.editMode = false;
          this.sendGetOrgUnits.emit(true);
          this.closeModal();
        });
    } else {
      const params = {
        name: nomeOrgUnit,
        parent_external_id: this.selectedNode.external_id,
      };

      this.orgUnitsService.postOrgUnits(params).subscribe({
        next: () => {
          this.sendGetOrgUnits.emit(true);
          this.closeModal();
        },
        error: (err) => {
          if (err.status == 409) {
            this.platModalService.toggle(
              'message',
              this.i18n.ou_external_id_already_exists,
              'close'
            );
          } else {
            this.platModalService.toggle('message', err.error.message, 'close');
          }
        },
      });
    }
  }

  closeModal() {
    this.isModalOpen = false;
    this.sendIsModalOpen.emit(this.isModalOpen);
  }

  ngOnDestroy(): void {
    if (this.unsubModalObject$) this.unsubModalObject$.unsubscribe();
  }
}
