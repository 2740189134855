<div #randomElement class="wrapper">
  <div class="dropdowns">
    <app-custom-dropdown
      #dropdown
      *ngFor="let alternative of alternatives; let index = index"
      [title]="
        questionBankService.getAlternativeName(
          index,
          i18n.question_bank_alternative
        )
      "
      [marked]="alternative.correct"
    >
      <form [formGroup]="form" class="inside-dropdown">
        <app-editor [height]="300" [form]="form" [formControlName]="'checkbox'" 
        (eventEmitter)="input($event, index, dropdown)" (editorEmitter)="receiveEditor = $event"></app-editor>

        <div class="buttons">
          <div
            class="btn right-alt"
            [class.is-active]="alternative.correct"
            (click)="setCorrectAlt(index)"
          >
            {{ i18n.question_bank_correct_answer }}
          </div>

          <div
            class="btn delete-alt"
            (click)="delete($event, index)"
            *ngIf="alternatives.length > 1"
            [title]="i18n.question_bank_delete"
          >
            <i class="trash-icon"></i>
          </div>
        </div>
      </form>
    </app-custom-dropdown>
  </div>

  <app-plus-button
    class="add-question-btn"
    [label]="i18n.question_bank_add_alternative"
    (click)="add(randomElement)"
    [disabled]="this.alternatives.length >= 10"
  ></app-plus-button>
</div>
