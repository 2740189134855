
<div class="pl-4" >
    <div class="relative">
        <div class="circle-init-forum"></div>
    </div>
    <div class="relative border-reply pl-2 ">
        <ng-container >
            <hr class="text-customGray-grayTwo pl-2" style="margin-left: 8px;">
        </ng-container>
        <div *ngIf="index === 0" class="p-2">
            <span class="text-customGray-darker italic capitalize">{{i18n.forum_replies}}</span>
        </div>
        <!-- Card reply -->
        <app-forum-card-reply
            [isThreadReply]="true"
            [thread]="thread"
            [i18n]="i18n"
            [user]="user"
            [isCommentAreaVisible]="isCommentAreaVisible"
            [isEditMode]="isEditMode"
            [isCanForumPost]="isCanForumPost"
            [isLoading]="isLoading"
            [forum_post]="forum_post"
            [isReplyOpen]="isReplyOpen"
            [isLastLevel]="isLastLevel"
            [hiddenSocialEvents]="hiddenSocialEvents"
            (sendIsCommentAreaVisible)="receiveIsCommentAreaVisible($event)"
            (sendIsEditMode)="receiveIsEditMode($event)"
            (sendIsReplyOpen)="receiveIsReplyOpen($event)"
            (askForGetForum)="fnAskForGetForum()"
            (sendEditHistoryObject)="fnReceiveEditHistoryObject($event)"
        ></app-forum-card-reply>
        <!-- End Card reply -->

    </div>

    <!-- New comment reply -->
    <ng-container *ngIf="isCommentAreaVisible === thread?.id">
        <app-forum-area-text
            [thread]="thread"
            [isEditMode]="isEditMode"
            [i18n]="i18n"
            [user]="user"
            [isReplyOpen]="isReplyOpen"
            (sendIsEditMode)="receiveIsEditMode($event)"
            (sendIsReplyOpen)="receiveIsReplyOpen($event)"
            (sendIsCommentAreaVisible)="receiveIsCommentAreaVisible($event)"
            (sendSaveComment)="saveComment($event)"
        ></app-forum-area-text>
    </ng-container>
    <!-- End New comment reply  -->
    <div class="relative" >
        <div class="circle-init-forum"></div>
    </div>
    
    <div [@collapse]="thread?.isThreadOpen">
        <app-forum-replies
            *ngFor="let nestedReply of thread?.replies; let indexReplies = index"
            [isThreadReply]="true"
            [thread]="nestedReply"
            [i18n]="i18n"
            [user]="user"
            [isCommentAreaVisible]="isCommentAreaVisible"
            [isEditMode]="isEditMode"
            [isCanForumPost]="isCanForumPost"
            [isLoading]="isLoading"
            [forum_post]="forum_post"
            [isReplyOpen]="isReplyOpen"
            [index]="indexReplies"
            [isLastLevel]="nestedReply?.isLastLevel"
            [hiddenSocialEvents]="hiddenSocialEvents"
            (sendIsCommentAreaVisible)="receiveIsCommentAreaVisible($event)"
            (sendIsEditMode)="receiveIsEditMode($event)"
            (sendIsReplyOpen)="receiveIsReplyOpen($event)"
            (askForGetForum)="fnAskForGetForum()"
            (sendEditHistoryObject)="fnReceiveEditHistoryObject($event)"
            (sendSaveComment)="saveComment($event)"
        ></app-forum-replies>
    </div>
</div>            
