<div class="flex bg-customWhite-default shadow-2xl rounded-lg" style="height: 752px;">
    <div class="flex flex-col w-full">
        <div class="flex flex-row w-full justify-end items-center pt-2 pr-2">
            <button
            class="x-close__icon"
            (click)="showMainScreen()"
            ></button>
        </div>
        <form [formGroup]="activitiesForm">
        <div class="flex flex-col xl:flex-row items-start xl:flex-center pl-4 gap-y-4 gap-x-6 pb-4 pt-1 header-fonte" >
            <div  class="search-form ">
                <div class="input-search-container">
                    <label for="search">
                        <img>
                    </label>
                    <input
                        formControlName="q"
                        id="search"
                        class="input-search"
                        [placeholder]="i18n.reports_search"
                    />
                </div>
            </div>
            <p>{{i18n.reports_period}}</p>
            <select class="select-reports" maxlength=42 formControlName="day">
                <option disabled hidden [value]="''">
                  {{i18n.reports_day}}
                </option>
                <option *ngFor="let day of daySelect" [value]="day.value" [ngValue]="day.value">
                    {{day.title}}
                </option>
            </select>
            <select class="select-reports" maxlength=42 formControlName="month">
                <option disabled hidden [value]="''">
                  {{i18n.reports_month}}
                </option>
                <option *ngFor="let month of monthSelect" [value]="month.value" [ngValue]="month.value">
                    {{month.title}}
                </option>
            </select>
        </div>
        <div class="export-container-activities  flex flex-col items-start xl:flex-row xl:items-center gap-y-4 pb-4 pl-4">
            <div class="flex flex-col xl:flex-row gap-y-4 xl:gap-x-4">
                <div class="exportTxt ">
                    <p>{{i18n.reports_month}}</p>
                </div>
                <div class="button-export exportTxt" (click)="getFile(2, 'xlsx')">
                    <p>XLSX</p>
                </div>
                <div class="button-export exportTxt" (click)="getFile(3, 'csv')">
                    <p>CSV</p>
                </div>
                <div class="button-export exportTxt" (click)="getFile(1, 'pdf')">
                    <p>PDF</p>
                </div>
                <div class="button-export exportTxt" (click)="getUrl(4, 'gsheet')" *ngIf="isNotStandalone">
                    <p>{{i18n.reports_google_sheets}}</p>
                </div>
            </div>


            <div class="flex flex-col md:flex-row gap-y-4 gap-x-4">
                <div class="flex flex-col xl:items-center sm:flex-row gap-y-4 gap-x-4 ">
                    <p>{{i18n.reports_order_by}}</p>
                    <select class="select-reports" maxlength=42 formControlName="sortBy">
                        <option disabled hidden [value]="''">{{i18n.reports_type}}</option>
                        <option *ngFor="let by of sortBySelect" [value]="by.value" [ngValue]="by.value">
                            {{by.title}}
                        </option>
                    </select>
                    <select class="select-reports" maxlength=42 formControlName="sortType">
                        <option disabled hidden [value]="''">{{i18n.reports_order}}</option>
                        <option *ngFor="let type of sortTypeSelect" [value]="type.value" [ngValue]="type.value">
                            {{type.title}}
                        </option>
                    </select>
                </div>
                <button class="button-filter" (click)="doQuery()">
                  {{i18n.reports_filter}}
                </button>
            </div>
        </div>
    </form>
        <div class="scroll-container-y" *ngIf="!loading">
            <div class="scroll-container-x">

                <div class="flex flex-row h-full w-full gap-4">
                    <div class="title-col">
                        <div class="header-title pl-14">
                           <p>{{i18n.reports_activies}}</p>
                        </div>
                        <div class="title-text-container" *ngFor="let activitie of activitiesData">
                            <i class="book__icon color-gray-icon"></i>
                            <p>{{activitie.content_name}}</p>
                        </div>
                    </div>
                    <div class="submitted-col">
                        <div class="header-title">
                            <p>{{i18n.reports_delivered}}</p>
                        </div>
                        <div class="element-text-container" *ngFor="let activitie of activitiesData">
                            <p>{{activitie.submitted}}</p>
                        </div>
                    </div>
                    <div class="submitted-col">
                        <div class="header-title">
                            <p>{{i18n.reports_pending}}</p>
                        </div>
                        <div class="element-text-container" *ngFor="let activitie of activitiesData">
                            <p>{{activitie.pending}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading-container" *ngIf="loading">
            <div class="loading">
                <img src="../../../../../../../../assets/svg/loading.svg"/>
                <p>{{i18n.reports_wait}}</p>
                <p>{{i18n.reports_we_are_loading}}</p>
            </div>
        </div>
    </div>
</div>
