<div class="flex bg-customWhite-default shadow-2xl rounded-lg" style="height: 752px;">
    <div class="flex flex-col w-full">
        <div class="flex flex-row  justify-end items-center pt-2 pr-2">
            <button
            class="x-close__icon"
            (click)="showMainScreen()"
            ></button>
        </div>
        <div class="flex flex-col xl:flex-row items-start xl:items-center gap-x-6 gap-y-2 mx-6 my-5 header-fonte" [formGroup]="resourceForm">
            <div  class="search-form ">
                <div class="input-search-container">
                    <label for="search">
                        <img>
                    </label>
                    <input
                        formControlName="q"
                        id="search"
                        class="input-search"
                        [placeholder]="i18n.reports_search"
                    />
                </div>
            </div>
           <!--  <p>Período</p>
            <select class="select-reports" maxlength=42 formControlName="day">
                <option disabled hidden [value]="''">
                    Dia
                </option>
                <option>
                    Dia
                </option>
            </select> -->
            <div class="grades-order">
               <p>{{i18n.reports_order_by}}</p>
               <select class="select-reports" maxlength=42 formControlName="sortBy">
                <option disabled hidden [value]="''">
                    Selecione
                </option>
                <option *ngFor="let by of sortBySelect" [value]="by.value" [ngValue]="by.value">
                    {{by.title}}
                </option>
            </select>
            <select class="select-reports" maxlength=42 formControlName="sortType">
                <option disabled hidden [value]="''">
                    Selecione
                </option>
                <option *ngFor="let type of sortTypeSelect" [value]="type.value" [ngValue]="type.value">
                    {{type.title}}
                </option>
            </select>
            <div class="multi-select shadow-lg">
                <p-multiSelect [options]="periodsData" class="shadow-lg" [displaySelectedLabel]="false" [filter]="false" [style]="{width: '100%'}"  defaultLabel={{i18n.reports_select_period}}  formControlName="periods" optionLabel="name" optionValue="id"></p-multiSelect>
            </div>
            </div>

            <button class="button-save" (click)="doQuery()">
              {{i18n.reports_filter}}
            </button>
        </div>
        <div class="export-container">
            <div class="flex flex-col sm:flex-row gap-2 my-4 ml-2">
                <div class="exportTxt">
                    <p> {{i18n.reports_export}}</p>
                </div>
                <div class="button-export" (click)="getFile(2, 'xlsx')">
                    <p>XLSX</p>
                </div>
                <div class="button-export" (click)="getFile(3, 'csv')">
                    <p>CSV</p>
                </div>
                <div class="button-export" (click)="getFile(1, 'pdf')">
                    <p>PDF</p>
                </div>
                <div class="button-export" (click)="getUrl(4, 'gsheet')" *ngIf="isNotStandalone">
                    <p>{{i18n.reports_google_sheets}}</p>
                </div>
            </div>
        </div>
        <div class="scroll-container-y" *ngIf="!loading">
            <div class="">
                <div class="flex flex-col h-full" style="">
                    <ng-container *ngFor="let reports of reportsData">
                      <div class="flex flex-col h-full mx-5 mt-4">
                        <div class="title-grade">{{reports?.period_name}}</div>
                        <div class="flex items-start">
                            <div class="flex-col mt-2 border-t border-b border-l border-r rounded-t-lg border-solid border-customBlue-superLight">
                               <!--  <div class="title-grade pl-2">{{reports?.period_name}}</div> -->
                                <div class="flex flex-row items-start pt-4">
                                  <div class="user-label flex justify-center items-start">
                                      <div class="top-titles">{{i18n.reports_student}}</div>
                                  </div>
                                  <div class="flex flex-col justify-center items-start" *ngFor="let content of arrayFormatForTitleContent(reports)" >
                                      <div class="content-title flex justify-center text-center px-1 items-start" title="{{content.title}}">
                                          <p>{{textCut(28, content.title)}}</p>
                                      </div>
                                      <div class="substitutive pb-1" id="tooltipSub" *ngIf="content.substitutive_title != null">
                                        (Substitui {{textCut(8, content?.substitutive_title)}})
                                        <span id="substitutiveTip">Substitui {{content?.substitutive_title}}</span>
                                      </div>
                                  </div>
                                  <div class="content-title flex justify-center text-center px-1 items-start">
                                    <div class="top-titles">{{i18n.reports_total}}</div>
                                  </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex" *ngFor="let student of reports.students; let i = index" title="{{student.user_name}}">
                           <div style="min-width: 210px; max-width: 210px; height: 50px;" class="border-r border-l border-customBlue-superLight flex items-center gap-x-2 pl-2 "  [ngClass]="{'top-border': i != 0, 'border-b rounded-bl-lg': reports.students.length == i+1}">
                              <div class="user-name">
                                <div class="user-frame-container">
                                    <app-user-frame
                                    [user_name]="student.user_name"
                                    [img_src]="student.user_image"
                                    [adaptable_size]="true"
                                    ></app-user-frame>
                                </div>
                                <p>{{student.user_name}}</p>
                              </div>
                            </div>
                          <!--   <div class="flex"> -->
                            <div *ngFor="let grade of student.period_grades; let studentIndex = index" class="data-field border-r  border-customBlue-superLight" [ngClass]="{'border-b': reports.students.length == i+1, 'top-border': i != 0}">
                              <div *ngIf="grade.considered_for_average == true"  class="data-fonte">
                                {{grade.evaluation}}
                              </div>
                              <div *ngIf="grade.considered_for_average == false"  class="data-fonte-gray">
                                {{grade.evaluation}}
                              </div>
                            <!--  </div> -->
                            </div>
                            <div class="data-fonte data-field border-r  border-customBlue-superLight" [ngClass]="{'border-b rounded-br-lg': reports.students.length == i+1, 'top-border': i != 0}">
                              {{student.period_average}}
                            </div>
                        </div>
                      </div>
                  </ng-container>
                </div>
            </div>
        </div>
        <div class="loading-container" *ngIf="loading">
            <div class="loading">
                <img src="../../../../../../../../assets/svg/loading.svg"/>
                <p>{{i18n.reports_wait}}</p>
                <p>{{i18n.reports_we_are_loading}}</p>
            </div>
        </div>
    </div>
</div>
