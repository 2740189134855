
<div  
    
    class="flex bg-customWhite-default px-2 py-4 md:p-8 rounded-md mb-4"
    style="box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);"
>
    <div
        #weekday
        class="relative flex flex-col w-full overflow-hidden style-scroll"
    >
        <h1
            class="font-bold text-26 text-customBlue-default leading-6 mb-4"
        >
            {{ i18n.dash_my_week }}
        </h1>

        <ng-container *ngIf="isReadyToRenderCarousel">
            <app-carousel 
                #carouselRef
                [slidesToShow]="slidesToShow"
                [showSlideIndex]="showSlideIndex"
                [carouselWidth]="weekdayElement"
                (emitIsNextDisabled)="receiveIsDisabledNext($event)"
                (emitIsPrevDisabled)="receiveIsDisabledPrev($event)"
            >
                <ng-container *ngFor="let item of weekdays;">
                    <ng-container *carouselItem>
                        <div 
                            class="flex flex-col gap-4 px-2"
                            [ngStyle]="weekdayWidth"
                        >
                            <div 
                                class="rounded-md px-2 py-1 "
                                [ngClass]="{
                                    'bg-customBlue-default text-customWhite-default' : this.formatDateToYYYYMMDD(currentDate) === item.date,
                                    'bg-customGray-iceGray text-customBlue-default' : this.formatDateToYYYYMMDD(currentDate) !== item.date
                                    }"
                            >
                                <div 
                                    class="font-medium text-18"
                                    style="white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;"
                                >
                                    {{ returnWeekNameTranslated(item.weekname) }}
                                </div>
                                <div class="text-14">
                                    ({{ currentLanguage === 'en' ? (item.date | date: 'MM/dd/yyyy') : (item.date | date: 'dd/MM/yyyy') }})
                                </div>
                            </div>

                            <div 
                                class="relative border border-customGray-mediumGray rounded-md overflow-y-auto p-2 "
                                style="min-height: 150px; max-height: 150px;"
                            >
                                <ng-container *ngFor="let content of item.contents">
                                    <div
                                        (click)="routeToLink(content)" 
                                        [pTooltip]="content.title"
                                        tooltipPosition="top"
                                        class="border border-l-4 border-customGray-iceGray rounded-md p-1 mb-2 cursor-pointer "
                                        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                                    >
                                        <span class="text-14 text-customGray-dark">
                                            {{ content.title }}
                                        </span>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!item.contents.length">
                                    <div
                                        class="absolute p-1"
                                        style="left: 50%; transform: translateX(-50%); top: 35%"
                                    >
                                        <span class="text-14 text-customGray-dark">
                                            --
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </app-carousel>

            <div 
                class="flex gap-2 t-1"
                [ngClass]="{
                    'absolute right-0 justify-end': slidesToShow > 1, 
                    'relative justify-center pt-2': slidesToShow === 1
                }"
            >
                <button 
                    (click)="carouselRef.prev()" 
                    [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isDisabledPrev}" 
                    [disabled]="isDisabledPrev"
                    class="flex justify-center items-center border-2 border-customBlue-default rounded-full w-7 h-7"
                >
                    <span class="iconify w-6 h-6 text-customBlue-default" data-icon="iconamoon:arrow-left-2-duotone"></span>
                </button>
                <button 
                    (click)="carouselRef.next()"
                    [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isDisabledNext}" 
                    [disabled]="isDisabledNext"
                    class="flex justify-center items-center border-2 border-customBlue-default rounded-full w-7 h-7"
                >
                    <span class="iconify w-6 h-6 text-customBlue-default" data-icon="iconamoon:arrow-right-2-duotone"></span>
                </button>
            </div>
        </ng-container>
    </div>
</div>