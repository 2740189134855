<app-generic-modal>
  <div class="container">
    <div class="message">
      <i
        *ngIf="icon_existence"
        [ngClass]="custom_icon"
        class="message__icon"
      ></i>
      <span [innerHtml]="message"></span>
    </div>

    <div class="decision-btns center">
      <button class="btn backward" (click)="emitClose($event)" tabindex="1">
        {{ backward_btn }}
      </button>
    </div>
  </div>
</app-generic-modal>
