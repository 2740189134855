import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

interface ISubmissions {
    discipline_id: any,
    section_id: any,
    homework_type: any,
    homework_id: any,
}

@Injectable({
    providedIn: 'root'
})

export class SubmissionsService {
    discipline_id: any;
    section_id: any;
    homework = new BehaviorSubject<any>({});

    constructor(private http: HttpClient) { }

    getHomeworkSubmissions(contentID) {
        return this.http.get(`${API_Routes.URL}/disciplines/empty/sections/empty/contents/${contentID}/submissions`);
    }

    getCourseWork(contentID, courseWorkID) {
        return this.http.get(`${API_Routes.URL}/disciplines/empty/sections/empty/contents/${contentID}/submissions/${courseWorkID}`);
    }

    postComment(contentID, courseWorkID, params) {
        return this.http.post(`${API_Routes.URL}/disciplines/empty/sections/empty/contents/${contentID}/submissions/${courseWorkID}/feedback`, params);
    }

    removeComment(contentID, courseWorkID) {
        return this.http.delete(`${API_Routes.URL}/disciplines/empty/sections/empty/contents/${contentID}/submissions/${courseWorkID}/feedback`);
    }
}
