import { Component, OnInit } from '@angular/core';
import { ImportSpreadsheetService } from '../import-spreadsheet.service';
import { PreviewSheetService } from './preview-sheet.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-preview-sheet',
    templateUrl: './preview-sheet.component.html',
    styleUrls: ['./preview-sheet.component.scss']
})
export class PreviewSheetComponent implements OnInit {
    constructor(
        private importSpreadsheetService: ImportSpreadsheetService,
        private previewSheetService: PreviewSheetService,
        public sharedService: SharedService,
        private router: Router,
    ) { }

    fileId: number | null = null;
    rows: any;
    errorMessage: string | null = null;
    errorMessageSelect: boolean | false = false;
    formConfig: any;
    sheetName: string | null = null;
    i18n: any = [];
    currentLanguage: string | null;
    isLoading = true;

    headerSelect: any;
    selectedOptions: string[] = [];
    formData: any;
    rowEmpty: boolean;

    mockFile = [
        {
            "id": 1559,
            "label": "IN | Template Plataforma Inicie -Estrutura",
            "external_id": "lQTs1jVpu8JuNv3F",
            "file_name": "IN | Template Plataforma Inicie -Estrutura",
            "mime_type": "application/vnd.google-apps.spreadsheet",
            "provider": "GOOGLE_DRIVE",
            "path": "https://drive.google.com/file/d/1LR5CneD7kCWqOzNxpZ8y0lUsQmrUvtiEMlwql6qjemU/preview?usp=sharing",
            "video_uri": "",
            "submitted_at": "2024-02-28 13:49:32",
            "original": null,
            "public_url": "https://drive.google.com/open?id=1LR5CneD7kCWqOzNxpZ8y0lUsQmrUvtiEMlwql6qjemU&authuser=hc@gedu.demo.ilpsnanuvem.com.br",
            "created_at": "2024-02-28 13:49:32",
            "flow_method": "platform"
        }
    ];

    linkInitial = '/import-spreadsheet';

    mockFormValues = {
        "start_import_row": 1,
        "import_type": "users",
        "selected_tabs": "2 - Disciplinas",
        "mode": "structure"
    };

    messagesConfirm: any;

    ngOnInit() {
        this.getRows();
        this.getTranslations();
        this.setHeaders();
        this.populateOptions();
    }

    getRows() {
        this.importSpreadsheetService.formData$.subscribe((formData) => {
            if (formData) {
                const { file, formValues } = formData;
                this.fileId = file[0].id;
                this.sheetName = file[0].label;
                this.formConfig = formValues;
                this.formData = formData;
            }
        });

        // this.fileId = this.mockFile[0].id;
        // this.sheetName = this.mockFile[0].label;
        // this.formConfig = this.mockFormValues;

        this.previewSheetService.formatSheet(this.fileId, this.formConfig)
            .subscribe({
                next: (rows: any) => {
                    this.rows = rows;
                    this.checkErrors(rows);
                    this.isLoading = false;
                    this.rowEmpty = false;

                    if (rows.rows.length === 0) {
                        this.rowEmpty = true;
                        this.importSpreadsheetService.openConfirmModal('error');
                    }
                },
                error: (err: any) => {
                    this.isLoading = false;
                    this.messagesConfirm.error = this.i18n.import_spreadsheet_error;
                    this.importSpreadsheetService.openConfirmModal('error');
                    console.log(err);
                }
            });
    }

    checkErrors(rows) {
        const { blankColumnRows, duplicateRows } = rows;

        if (blankColumnRows && blankColumnRows.length > 0) {
            this.errorMessage = this.i18n.import_spreadsheet_has_blank_line;
            return;
        }

        if (duplicateRows && duplicateRows.length > 0) {
            this.errorMessage = this.i18n.import_spreadsheet_has_duplicate_line;
        }
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('ImportSpreadsheet') };
        this.currentLanguage = localStorage.getItem('currentLanguage');

        this.messagesConfirm = {
            success: this.i18n.import_spreadsheet_generate_succes,
            error: this.i18n.import_spreadsheet_empty,
            info: this.i18n.import_spreadsheet_start
        };
    }

    setHeaders() {
        const headersUsers = [
            { id: 1, label: this.i18n.import_spreadsheet_option_name, value: 'name', options: [] },
            { id: 2, label: this.i18n.import_spreadsheet_option_email, value: 'email', options: [] },
            { id: 3, label: this.i18n.import_spreadsheet_option_cod, value: 'cod', options: [] },
            { id: 4, label: this.i18n.import_spreadsheet_option_profile, value: 'profile', options: [] },
            { id: 5, label: this.i18n.import_spreadsheet_option_ou, value: 'ou', options: [] },
            { id: 6, label: this.i18n.import_spreadsheet_option_phone, value: 'phone', options: [] },
            { id: 7, label: this.i18n.import_spreadsheet_option_cardtime, value: 'timecard', options: [] },
            { id: 8, label: this.i18n.import_spreadsheet_option_start_date_function, value: 'start_date_function', options: [] },
        ];

        const headerDisciplines = [
            { id: 1, label: this.i18n.import_spreadsheet_option_cod_discipline, value: 'cod', options: [] },
            { id: 2, label: this.i18n.import_spreadsheet_option_name_discipline, value: 'name', options: [] },
            { id: 3, label: this.i18n.import_spreadsheet_option_teaching_type, value: 'teaching_type', options: [] },
            { id: 4, label: this.i18n.import_spreadsheet_option_segment, value: 'segment', options: [] },
            { id: 5, label: this.i18n.import_spreadsheet_option_ou, value: 'ou', options: [] },
        ];

        const headerLinking = [
            { id: 1, label: this.i18n.import_spreadsheet_option_cod_discipline, value: 'cod', options: [] },
            { id: 2, label: this.i18n.import_spreadsheet_option_name_discipline, value: 'name', options: [] },
            { id: 3, label: this.i18n.import_spreadsheet_option_profile, value: 'profile', options: [] },
            { id: 4, label: this.i18n.import_spreadsheet_option_email, value: 'email', options: [] },
        ];

        const headerConnections = [
            { id: 1, label: this.i18n.import_spreadsheet_option_cod_discipline, value: 'cod_disc', options: [] },
            { id: 2, label: this.i18n.import_spreadsheet_option_name_discipline, value: 'name_disc', options: [] },
            { id: 3, label: this.i18n.import_spreadsheet_option_teaching_type, value: 'type_teaching', options: [] },
            { id: 4, label: this.i18n.import_spreadsheet_option_segment, value: 'segment', options: [] },
            { id: 5, label: this.i18n.import_spreadsheet_option_ou, value: 'ou', options: [] },
            { id: 6, label: this.i18n.import_spreadsheet_option_cod_model_discipline, value: 'cod_model_disc', options: [] },
            { id: 7, label: this.i18n.import_spreadsheet_option_import_model_discipline, value: 'name_model_disc', options: [] },
            { id: 8, label: this.i18n.import_spreadsheet_option_cod_model_certificate, value: 'cod_model_cert', options: [] },
            { id: 9, label: this.i18n.import_spreadsheet_option_name_model_certificate, value: 'name_model_cert', options: [] },
            { id: 10, label: this.i18n.import_spreadsheet_option_automatic_issue, value: 'auto_issue', options: [] },
            { id: 11, label: this.i18n.import_spreadsheet_option_resources_percentage, value: 'resources_percent', options: [] },
            { id: 12, label: this.i18n.import_spreadsheet_option_start_date_issue, value: 'start_date_issue', options: [] },
        ];

        console.log('Form Config:', this.formConfig);

        if (this.formConfig.import_type === 'users') {
            this.headerSelect = headersUsers;
        }

        if (this.formConfig.import_type === 'disciplines') {
            this.headerSelect = headerDisciplines;
        }

        if (this.formConfig.import_type === 'linking') {
            this.headerSelect = headerLinking;
        }

        if (this.formConfig.import_type === 'connections') {
            this.headerSelect = headerConnections;
        }
    }

    populateOptions() {
        if (this.headerSelect) {
            this.headerSelect.forEach((header) => {
                header.options = this.headerSelect;
                const initialOptionIndex = this.headerSelect.findIndex(item => item.value === header.value);
                this.selectedOptions.push(header.options[initialOptionIndex].value);
            });
        }
    }

    onSelectChange(index: number) {
        const selectedValue = this.selectedOptions[index];

        // Verifica se a opção selecionada já está em outra coluna
        const duplicateIndex = this.selectedOptions.findIndex((value, i) => i !== index && value === selectedValue);

        const uniqueValues = [...new Set(this.selectedOptions)];
        const hasDuplicates = uniqueValues.length !== this.selectedOptions.length;

        if (duplicateIndex !== -1 || hasDuplicates) {
            this.errorMessageSelect = true;
            this.errorMessage = this.i18n.import_spreadsheet_cannot_select_same_collumn;
        } else {
            this.errorMessageSelect = false;
            this.errorMessage = null;

            this.checkErrors(this.rows);
        }
    }

    onSubmit() {
        console.log('Submit');
        this.errorMessage = null;
        this.isLoading = true;

        const formParams = {
            ...this.formConfig, // Copia todas as propriedades existentes
            selected_sheet: this.formConfig.selected_tabs,
            selected_tabs: this.selectedOptions,
            file_id: this.fileId,
        };

        this.previewSheetService.import(formParams)
            .subscribe({
                next: () => {
                    setTimeout(() => {
                        this.isLoading = false;
                        this.importSpreadsheetService.openConfirmModal('info');
                    }, 3000);
                },
                error: (err: any) => {
                    this.isLoading = false;
                    this.messagesConfirm.error = this.i18n.import_spreadsheet_error;
                    this.linkInitial = '';
                    this.importSpreadsheetService.openConfirmModal('error');
                }

            });

        console.log('Form Params:', formParams);
    }

    goBack() {
        console.log('Go Back');
        this.importSpreadsheetService.setFormDataConfigSheet(this.formData);
        this.router.navigate([`/import-spreadsheet/select-sheet/${this.formConfig.mode}`]);
    }
}
