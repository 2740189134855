import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PaginationLib } from '../../libs/pagination.lib';

interface IOutput {
  buttons: any[];
  items: any[];
  activePage: number;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @ViewChild('pageInput') pageInput: ElementRef;

  @Input() params: any = {
    items: [],
    itemsPerPage: 10,
    visibleButtons: 5,
    activePage: 1,
  };

  @Output() navigation = new EventEmitter();

  paginationLib: PaginationLib;
  input: any;
  buttonsFontSize: string;
  buttons: any[] = [];
  activePage: number = 1;
  nextArrowIsDisabled: boolean;
  noArrows: boolean = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.listenEnterKeydown();
    this.initPaginationLib();
    this.cd.detectChanges();
    // Retirado timeout pois atrasava uso do paginador em tela
    // setTimeout(() => {
    //   this.listenEnterKeydown();
    //   this.initPaginationLib();
    //   this.cd.detectChanges();
    // }, 1000);
  }

  initPaginationLib(ingoreFirstLoad = false) {
    const items = this.params.items;
    this.calculateNextArrowState();

    this.paginationLib = new PaginationLib({
      inputItems: items,
      itemsPerPage: this.params.itemsPerPage,
      visibleButtons: this.params.visibleButtons,
      activePage: this.params.activePage,
    });

    const firstLoad = !ingoreFirstLoad;

    this.initializeStatus(this.paginationLib.firstOutput, firstLoad);
  }

  initializeStatus(
    { buttons, items, activePage }: IOutput,
    firstLoad?: boolean
  ) {
    this.activePage = activePage;
    this.buttons = buttons;
    this.adjustButtonsFontSize();

    if (this.activePage === activePage && firstLoad) return;
  }

  updateState({ buttons, items, activePage }: IOutput, firstLoad?: boolean) {
    this.activePage = activePage;
    this.buttons = buttons;
    this.adjustButtonsFontSize();

    if (this.activePage === activePage && firstLoad) return;

    this.navigation.emit({
      items,
      activePage,
      firstLoad,
    });
  }

  setActivePage(page: number): void {
    if (!page) return;

    const output = this.paginationLib.setActivePage(page);
    this.updateState(output);
    this.input = '';

    this.calculateNextArrowState();
  }

  forward(): void {
    const output = this.paginationLib.forwardPage();
    this.updateState(output);

    this.calculateNextArrowState();
  }

  backward(): void {
    const output = this.paginationLib.backwardPage();
    this.updateState(output);

    this.calculateNextArrowState();
  }

  formatInput($event) {
    if (!this.input.length) return;
    const toNumber = parseInt(this.input);
    const isNaN = Number.isNaN(toNumber);
    this.input = isNaN ? '' : Number.isNaN(toNumber) ? '' : toNumber;

    //Checks if the number that the user is typing exceeded the limit of pages that we have or if it is below 0
    const isTooHigh = this.paginationLib.numberOfPages < parseInt(this.input);
    const isTooLow = parseInt(this.input) < 1;

    if (isTooHigh) {
      this.input = String(this.paginationLib.numberOfPages);
    } else if (isTooLow) {
      this.input = String(1);
    }

    $event.currentTarget.value = this.input;
  }

  listenEnterKeydown() {
    let body: any;

    if (!this.pageInput) return;

    //For understand the enter when we search for a name
    this.pageInput.nativeElement.onfocus = (e) => {
      e.stopPropagation();

      body = e.target.closest('body');

      body.onkeypress = (e) => {
        e.stopPropagation();

        const pressedKey = e.key;

        if (pressedKey === 'Enter') this.setActivePage(this.input);
      };
    };

    //For cleaning the body event
    this.pageInput.nativeElement.onblur = (e) => {
      e.stopPropagation();

      body.onkeypress = null;
    };
  }

  adjustButtonsFontSize() {
    const stringedButtonNumber = [...this.buttons].pop().number.toString();

    /*
     * The code below makes the number inside the button bigger or smaller,
     * so 1000 will not overflow the button and 1 will not be too small
     */
    const buttonsFontSize =
      stringedButtonNumber.length > 3
        ? 11
        : stringedButtonNumber.length > 2
        ? 14
        : 16;

    this.buttonsFontSize = `--font-size: ${buttonsFontSize}px`;
  }

  calculateNextArrowState() {
    const maxNumberOfPages = Math.ceil(
      this.params?.items?.length / this.params.itemsPerPage
    );
    this.nextArrowIsDisabled = this.activePage >= maxNumberOfPages;

    this.noArrows = maxNumberOfPages <= this.params.visibleButtons;
  }
}
