import { Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { LoginService } from '../../login.service';

@Component({
  selector: 'app-standalone-login',
  templateUrl: './standalone-login.component.html',
  styleUrls: ['./standalone-login.component.scss']
})
export class StandaloneLoginComponent implements OnInit {
  @Input() providers: any = [];
  userForm: FormGroup;
  isHiddenPass: boolean;
  i18n: any = [];
  isVisible: boolean = false;
  isLoading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private sharedService: SharedService,
    public platformModalsService: PlatformModalsService
  ) { }

  ngOnInit(): void {
    this.form();
    this.getTranslations();
    this.getAllowProvider();
  }

  getAllowProvider(){
    this.loginService.update_localstorage.subscribe((authentication)=>{
      let allowedProviders = authentication?.allowedProviders
      if (allowedProviders.length > 0) {
        let passwordProvider = allowedProviders.find((item)=>{
          return item.name == "password"
        });
        if (passwordProvider !== undefined && Object.keys(passwordProvider).length) {
          this.isVisible = true;
        }
      }
    })
  }

  getTranslations(){
    this.i18n = this.sharedService.getTranslationsOf('Login');
  }

  form(){
    this.userForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  hiddenPass() {
      var x = (<HTMLInputElement>document.getElementById('pass'));
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  openModalResetPassword(){
    this.loginService.openModalResetPassword$.next(true);
  }

  authUser(){
    this.isLoading = true;
    let e = this.userForm.controls.email.value
    let p = this.userForm.controls.password.value
    const params = {
      "user": e,
      "password": p,
    }

    this.loginService.login(params)
      .subscribe({
        next: (response)=>{
          this.isLoading = false;
          this.sharedService.storeUserCredentials(response);
          if (response.terms !== null) {
            this.loginService.authObject$.next(response);
            this.loginService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: false });
          }else{
            this.sharedService.loggedIn.next(true);
            this.loginService.isRedirectRoute();
          }
        },
        error: (err)=>{
          this.isLoading = false;
            if (err.error.error == 'Forbidden: Unauthorized.') {
              console.log('err', err);
              this.platformModalsService.toggle('message', this.i18n.login_password_or_email_wrong, 'close');
            }
          this.sharedService.loggedIn.next(false);
        }
      })
  }

}
