<app-generic-modal [isVisible]="isVisible">
    <div  class="transition ease-out duration-700 animate-fade-in-down overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex transition duration-1000 ease-in-out">
        <div class="mx-auto absolute bg-customWhite-default rounded-lg" style="max-height: 100vh;overflow-y: auto; width: 450px;">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t">
                    <div class="text-center">
                    <h3 class="text-2xl font-semibold text-customBlue-dark-title">{{i18n.users_vinculate_commission}}</h3>
                    <p class="text-sm text-customBlue-dark-subtitle">{{i18n.users_vinculate_user_to_team_commission}}</p>
                    </div>
                </div>
                <i class="close-btn-icon" (click)="closeModal()">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.716 6.58203C12.716 6.50039 12.6492 6.43359 12.5676 6.43359L11.343 6.43916L9.49863 8.63789L7.65615 6.44102L6.42969 6.43545C6.34805 6.43545 6.28125 6.50039 6.28125 6.58389C6.28125 6.61914 6.29424 6.65254 6.3165 6.68037L8.73047 9.55635L6.3165 12.4305C6.29408 12.4577 6.28164 12.4917 6.28125 12.527C6.28125 12.6086 6.34805 12.6754 6.42969 12.6754L7.65615 12.6698L9.49863 10.4711L11.3411 12.668L12.5657 12.6735C12.6474 12.6735 12.7142 12.6086 12.7142 12.5251C12.7142 12.4898 12.7012 12.4564 12.6789 12.4286L10.2687 9.55449L12.6826 6.67852C12.7049 6.65254 12.716 6.61729 12.716 6.58203Z" fill="#A9AEB3"/>
                    <path d="M9.5 1.20703C4.90957 1.20703 1.1875 4.9291 1.1875 9.51953C1.1875 14.11 4.90957 17.832 9.5 17.832C14.0904 17.832 17.8125 14.11 17.8125 9.51953C17.8125 4.9291 14.0904 1.20703 9.5 1.20703ZM9.5 16.4219C5.68887 16.4219 2.59766 13.3307 2.59766 9.51953C2.59766 5.7084 5.68887 2.61719 9.5 2.61719C13.3111 2.61719 16.4023 5.7084 16.4023 9.51953C16.4023 13.3307 13.3111 16.4219 9.5 16.4219Z" fill="#A9AEB3"/>
                    </svg>
                </i>
                <!--body-->
                <div class="flex flex-col items-center gap-2">
                    <div class="flex flex-col w-full pb-2 gap-2 mt-4 pr-4 pl-4" *ngIf="dataSource.length > 0">
                        <div
                            class="flex items-center w-full mb-6 rounded"
                            style="border: 1px solid #a9aeb3"
                        >
                            <input
                                class="p-1 rounded-full w-full outline-none"
                                (keyup)="searchCommission()"                                
                                type="text"
                                ngDefaultControl
                                [(ngModel)]="filterCommission"
                            />
                            <div (click)="searchCommission()" class="mr-2">
                                <span class="iconify w-6 h-6 text-customGray-default" data-icon="ri:search-line"></span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full pb-2 gap-2 mt-2 pr-4 pl-4">
                        <div class="w-full pb-4 gap-2 mt-4 pr-4 pl-4" *ngIf="dataSource.length == 0">
                            <p class="text-customGray-default text-center">{{i18n.users_commissions_empty_state}}</p>
                        </div>
                        <div *ngFor="let commissionGroup of dataSource; let i = index;" class="w-full">                            
                            <button
                                [disabled]="commissionGroup.editing"
                                [ngClass]="{'button-content-selected': commissionGroup.editing, 'disabled:opacity-20 cursor-not-allowed': isLoadingCommissionGroup}"                                
                                class="flex flex-row flex-wrap w-full bg-feed font-bold text-sm py-2 rounded shadow hover:none outline-none focus:outline-none mr-1 mb-1 
                                ease-linear transition-all duration-150"
                                type="button"
                                
                                (dblclick)="editingMode(i)"
                                >
                                <div class="w-full" style="display: flex; justify-content: space-between;">
                                    <div class="flex flex-row" style="justify-content: space-between;">
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                         xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" 
                                         class="iconify iconify--material-symbols iconify-inline aside__icon" 
                                         width="24px" height="24px" 
                                         preserveAspectRatio="xMidYMid meet" 
                                         viewBox="0 0 24 24" _ngcontent-lxx-c345="" 
                                         data-icon="material-symbols:school-outline-rounded"
                                         style="display: inline-flex; margin-left: 10px;">
                                        <path fill="currentColor" d="M6.05 17.775q-.5-.275-.775-.737T5 16v-4.8L2.6 9.875q-.275-.15-.4-.375T2.075 9q0-.275.125-.5t.4-.375l8.45-4.6q.225-.125.463-.188T12 3.275q.25 0 .488.063t.462.187l9.525 5.2q.25.125.388.363T23 9.6V16q0 .425-.288.713T22 17q-.425 0-.713-.288T21 16v-5.9l-2 1.1V16q0 .575-.275 1.038t-.775.737l-5 2.7q-.225.125-.462.188t-.488.062q-.25 0-.488-.063t-.462-.187l-5-2.7ZM12 12.7L18.85 9L12 5.3L5.15 9L12 12.7Zm0 6.025l5-2.7V12.25l-4.025 2.225q-.225.125-.475.188t-.5.062q-.25 0-.5-.063t-.475-.187L7 12.25v3.775l5 2.7Zm0-6.025Zm0 3.025Zm0 0Z"></path>
                                        </svg> 
                                        <span style="margin-top: 3px;">
                                            <span *ngIf="commissionGroup.editing">
                                                <input type="text" id="inputCommissionTitle_{{commissionGroup.commission_id}}" [(ngModel)]="commissionGroup.commission_title" (keyup)="setTitle(i, $event)">
                                            </span>
                                            <span class="truncate" *ngIf="!commissionGroup.editing">{{ commissionGroup.commission_title }}</span>
                                        </span>
                                    </div>
                                    <span *ngIf="!commissionGroup.expanded" style="margin-top: 3px; margin-left: 10px; margin-right: 5px; font-weight: 400;">{{ commissionGroup.commission_users.length }} {{i18n.users_commissions_members}}</span>
                                    <div class="flex flex-row" style="justify-content: space-between;">
                                        <button
                                            class="arrow-down transition-all duration-150"
                                            (click)="sessionExpanded(i)"
                                            [ngClass]="{'transition-arrow-down':commissionGroup.expanded }">
                                            <span class="iconify text-customBlue-default" style="transform: scale(2.2);" data-icon="eva:arrow-down-fill"></span>
                                        </button>
                                        <span style="margin-left: 10px; margin-right: 10px;">
                                            <app-check-button 
                                                [iconSize]="21"
                                                (isActive)="changeCommission(i , commissionUser, $event)"
                                                [active]="isActived(i, commissionUser)"
                                                [roundedValue]="5"                                    
                                            ></app-check-button>
                                        </span>
                                    </div>                                    
                                </div>
                                <div class="mt-4" *ngIf="commissionGroup.expanded">
                                    <div class="flex flex-row mb-2 " *ngFor="let commission_user of commissionGroup.commission_users;">
                                        <app-user-frame [style]="'height: 25px; font-size: medium;'" [img_src]="commission_user.gcs_path?? ''" [user_name]="commission_user.name?? ''">
                                        </app-user-frame>
                                        <div class="ml-1">
                                            <h2 class="truncate" style="text-align: initial;">{{ commission_user.name }}</h2>
                                        </div>
                                    </div>
                                </div>                              
                            </button>
                        </div>
                    </div>                    
                </div>
                <div class="mt-4 flex flex-row pb-2 gap-2 justify-left items-center">
                    <button class="ml-3 py-3.5 px-3.5 right-16 rounded-full add shadow-lg text-customWhite-default ng-star-inserted"
                        (click)="commissionGroup()" id="buttonAddCommissionGroup">
                        <i class="plus-icon color-white-icon w-3 h-3"></i>        
                    </button>
                    <label class="text-customBlue-dark-button" for="buttonAddCommissionGroup"> {{i18n.users_button_add_vinculate_commission}}</label>
                </div>
                <!--footer-->
                <div class="flex items-center justify-center py-6">
                    <div>
                        <button class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                                type="button" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': commissionUser == '' }" [disabled]="commissionUser == ''" (click)="emitCommssionTeam()">
                        {{i18n.users_button_vinculate_commission}}
                        </button>
                        <button class="text-customGray-textGray background-custom-cancel font-semibold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="closeModal()">
                        {{i18n.users_cancel}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</app-generic-modal>
