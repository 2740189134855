import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TitlePageService } from 'src/app/services/titlePageService/title-page.service';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class DisciplinesModelsTitleResolver implements Resolve<void>{
  constructor(
      private sharedService: SharedService,
      private titleService: TitlePageService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
      const i18n = {...this.sharedService.getTranslationsOf('Disciplines')};

      const paths = [
          { url: '', title: i18n.disciplines_templates},
      ]
      
      this.titleService.resolveTitle(route,paths);
  }
}
