<app-submission-overview-box [question_number]="state.activeCourseWork.course_work.id">
    <div class="bg-customGray-lighterGray rounded-3xl p-2 text-customGray-dark">
      <app-forum-detail
          [isComponentForum]="true"
          [forum_post]="content.contentSpecific"
      ></app-forum-detail>
    
      <app-forum-comment
          [isComponentForum]="true"
          [hiddenSocialEvents]="true"
      ></app-forum-comment>
    </div>
</app-submission-overview-box>    
