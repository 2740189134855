import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'app-decision-modal',
  templateUrl: './decision-modal.component.html',
  styleUrls: ['./decision-modal.component.scss'],
})
export class DecisionModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild(GenericModalComponent)
  private generic_modal: GenericModalComponent;

  type?: string;
  subject?: string;
  message: string;
  icon_existence: boolean = true;
  custom_icon: string;
  icon_color: string;
  custom_classes: string;
  handlers: any = {};

  i18n: any;
  phrase: string;
  btn: any = {};

  modalSubscription$: Subscription;

  constructor(
    private platModalService: PlatformModalsService,
    private sharedService: SharedService,
    private element: ElementRef
  ) {}

  ngAfterViewInit(): void {
    //gets subscriptions and reloads the modal
    this.subscribeToModalService();
  }

  OnInit(): void {
    this.getI18n();
    this.getParamsAndHandlers();
    this.setIconColor();
    this.extractTypeAndSubject();
    this.caseHandler();
  }

  subscribeToModalService() {
    this.modalSubscription$ = this.platModalService
      .getModalState('decision')
      .pipe(delay(0))
      .subscribe((boolean) => {
        if (boolean) this.OnInit();
        this.generic_modal.toggle(boolean);
      });
  }

  getI18n() {
    this.i18n = this.sharedService.getTranslationsOf('Modal');
  }

  getParamsAndHandlers() {    
    this.message = this.platModalService.decisionModal.message;
    let text_size = this.platModalService.decisionModal.text_size;
    let text_color = this.platModalService.decisionModal.text_color;

    this.custom_classes = '';
    this.custom_classes += text_size == '' ? 'text-22 ' : text_size + ' '; 
    this.custom_classes += text_color == '' ? 'text-customBlue-default ' : text_color + ' ';

    this.custom_icon = this.platModalService.decisionModal.custom_icon.length
      ? this.platModalService.decisionModal.custom_icon
      : 'attention-icon'; //default icon;

    this.icon_color = this.platModalService.decisionModal.icon_color;

    this.icon_existence = this.platModalService.decisionModal.icon_existence;
    this.handlers = this.platModalService.decisionModal.handlers;
  }

  setIconColor() {
    if (this.icon_color.length)
      this.element.nativeElement.querySelector('.filter-color').style.filter =
        this.icon_color;
  }

  extractTypeAndSubject() {
    const responseSplitedArray = this.message.split('_');

    this.subject = responseSplitedArray.slice(2).join('_');
    this.type = responseSplitedArray.shift();
  }

  caseHandler() {
    
    this.extractBtnText(`btns_${this.type}`);

    if (this.i18n.hasOwnProperty(this.message)) {
      this.message = this.i18n[`${this.type}_subject_${this.subject || 'generic'}`];
    }

  }

  emitDecision($event, direction) {
    $event.stopPropagation();

    if (this.handlers[direction]) this.handlers[direction]();
    this.handlers.finally();
  }

  extractBtnText(btnTranslationKey: string) {
    
    if (!this.i18n[btnTranslationKey]) btnTranslationKey = 'btns_generic';

    this.i18n[btnTranslationKey].split('|').forEach((btn) => {
      const [action, text] = btn.split(':').map((i: string) => i.trim());

      this.btn[action] = text;
    });
  }

  ngOnDestroy(): void {
    if (this.modalSubscription$) this.modalSubscription$.unsubscribe();
  }
}
