import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessengerToastComponent } from './messenger-toast/messenger-toast.component';
import { MessengerToastBaseComponent } from './messenger-toast-base/messenger-toast-base.component';

@NgModule({
    declarations: [
        MessengerToastComponent,
        MessengerToastBaseComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MessengerToastComponent,
        MessengerToastBaseComponent
    ]
})
export class MessengerToastModule { }
