import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserFrameComponent } from './user-frame.component';

@NgModule({
  declarations: [
    UserFrameComponent,
  ],
  exports: [
    UserFrameComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})

export class UserFrameModule {}
