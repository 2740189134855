<div class="quiz">
  <div class="quiz-presentation">
    <div
      class="quiz-presentation__title"
      [innerHTML]="content.title | safeHTML"
    ></div>
    <div
      class="quiz-presentation__description"
      *ngIf="content?.description"
      [innerHTML]="content?.description | safeHTML"
    ></div>
    <div class="quiz-presentation__completition-criterion">
      <i class="iconify-inline quiz-presentation__icon" data-icon="bx:lock"></i>
      <span
        >{{ i18n.question_bank_content_view_criterion }}
        <b>{{ completitionRuleTranslation }}</b></span>
    </div>
  </div>

  <div class="questions" *ngIf="!isStudent || isCompleted || quizInited">
    <div class="questions__title">{{ content.title }}</div>
    <div class="questions__number">
      <span *ngIf="!isStudent || !isCompleted">{{ contentSpecific.questions.length }} {{ i18n.question_bank_question }}</span>

      <span *ngIf="isStudent && isCompleted">
        {{ contentSpecific.questions.length }} <span class="lowercase">{{ contentSpecific.questions.length === 1 ? i18n.question_bank_question_singular : i18n.question_bank_question }}</span> ({{ countCorrect }} {{ countCorrect < 2 ? i18n.question_bank_correct_singular : i18n.question_bank_correct }} {{i18n.question_bank_and}} {{ countIncorrect }} {{ countIncorrect < 2 ? i18n.question_bank_incorrect_singular : i18n.question_bank_incorrect }})
      </span>
    </div>
    <app-question-bank-question
      *ngFor="let question of contentSpecific.questions; let index = index"
      [data]="question"
      [order]="index"
      [noEdit]="true"
      [noDelete]="true"
      [readonly]="!isStudent || !quizInited"
      (change)="saveStudentAnswer($event)"
      [correction]="!quizInited && returnIsComplete()"
    >
    </app-question-bank-question>
  </div>

  <div class="decision-btns right">
    <!-- Button Back  -->
    <button type="button" class="btn backward" (click)="getBackToContents()">{{ i18n.question_bank_go_back }}</button>
    
    <!-- Button Edit  -->
    <button type="button" class="btn forward" *ngIf="!isStudent" (click)="editQuiz()">{{ i18n.question_bank_edit }}</button>
    
    <!-- Button Resume  -->
    <button type="button" class="btn forward" *ngIf="!quizInited && isStudent && remainingAttempts && (content.coursework_submission_attempts !== 0)" [disabled]="!content?.delivery_allowed" [ngClass]="{'cursor-not-allowed': !content?.delivery_allowed}" (click)="startQuiz()">
    {{ i18n.question_bank_content_view_resume_questionnaire }}
    </button>
    <!-- Button Start  -->
    <button type="button" class="btn forward" *ngIf="!quizInited && isStudent && remainingAttempts && (content.coursework_submission_attempts === 0)" (click)="startQuiz()" [disabled]="!content?.delivery_allowed" [ngClass]="{'cursor-not-allowed': !content?.delivery_allowed}">
        {{ i18n.question_bank_content_view_start_quiz }}
    </button>
    <!-- Button Finish  -->
    <button type="button" class="btn forward" [disabled]="!canSubmitQuiz" [ngClass]="{'cursor-not-allowed opacity-75': !canSubmitQuiz}" *ngIf="quizInited && isStudent" (click)="finishQuiz()">
      <ng-container *ngIf="!finishQuizLoading">{{ i18n.question_bank_send }}</ng-container>
      <app-loading-balls-effect *ngIf="finishQuizLoading"></app-loading-balls-effect>
    </button>
  </div>
</div>