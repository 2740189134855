import { NgModule } from '@angular/core';
import { DecisionModalComponent } from './decision-modal.component';
import { GenericModalModule } from '../generic-modal/generic-modal.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    DecisionModalComponent
  ],
  exports: [
    DecisionModalComponent
  ],
  imports: [
    CommonModule,
    GenericModalModule
  ]
})

export class DecisionModalModule { }
