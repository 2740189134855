<section class="grid xs:grid-cols-1 grid-cols-2 items-center bg-customWhite-default h-screen">
    <!-- Tela esquerda Cinza -->
    <div class="relative xs:hidden flex items-stretch justify-center text-center bg-customGray-light h-screen">
        <div class="absolute top-1/2 w-full" style="transform: translateY(-50%)">
        </div>
    </div>


<!-- Tela direita Reset de senha -->
<div class="2xl:mx-48 relative flex flex-col px-4 h-screen items-center justify-center">
    <div class="mb-8">
        <img [src]="main_logo" style="width: 112px;" alt=""
            onerror="this.src='../../../assets/png/inicie_logo-horizontal.png'">
    </div>
    <div class="font-OpenSans">
        <h1 class="text-customBlue-default text-18 font-semibold">
            {{i18n.reset_reset_password}}
        </h1>
        <p class="text-customGray-swampGray text-16 font-normal mt-6">
            {{i18n.reset_reset_password_description}}
        </p>
    </div>
    <form class="mt-6 w-full" [formGroup]="resetForm" (keyup.enter)="updatePassword()">

        <div class="flex flex-col mt-5 font-OpenSans">
            <label class="text-customGray-swampGray text-16">{{i18n.reset_new_password}}</label>
            <div class="relative flex">
                <input 
                    #password (focus)="displayPanel.toggle()" (focusout)="displayPanel.toggle()" (keyup)="checkStrengthPassword(password.value)" 
                    type="password" [placeholder]="i18n.login_type_your_pass" class="input-reset w-full" 
                    required autocomplete="off" formControlName="password" >
                <span class="cursor-pointer" (click)="hiddenPass('password')" (click)="isHiddenPass = !isHiddenPass">
                    <div *ngIf="!isHiddenPass" class="relative">
                        <span class="iconify eye-icon text-customGray-iconGray" data-icon="ph:eye"></span>
                    </div>
                    <div *ngIf="isHiddenPass" class="relative">
                        <span class="iconify eye-icon text-customGray-iconGray" data-icon="ph:eye-slash"></span>
                    </div>
                </span>
                
                <app-display-panel
                    #displayPanel
                    [style]="{ left: '50%', right: '50%',top: '50px', 'min-width': '260px','transform': 'translateX(-50%)'}"
                    [arrow_deslocation]="'50%'"
                    [custom_radius]="'10px'"
                >
                    <div class="font-semibold">
                        <!-- Letras minúsculas -->
                        <div class="flex">
                            <ng-container *ngIf="strengthPassword.downLetters">
                                <div class="flex gap-2 items-center text-14 text-customGreen-approved">
                                    <span class="iconify text-22" data-icon="ri:checkbox-circle-line"></span>
                                    <span>{{i18n.reset_include_lower_case}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!strengthPassword.downLetters">
                                <div class="flex gap-2 items-center text-14 text-inicieColors-orange">
                                    <span class="iconify text-22" data-icon="ri:close-circle-line"></span>
                                    <span>{{i18n.reset_include_lower_case}}</span>
                                </div>
                            </ng-container>
                        </div>

                        <!-- Letras maiúsculas -->
                        <div class="flex">
                            <ng-container *ngIf="strengthPassword.upperLetters">
                                <div class="flex gap-2 items-center text-14 text-customGreen-approved">
                                    <span class="iconify text-22" data-icon="ri:checkbox-circle-line"></span>
                                    <span>{{i18n.reset_include_upper_case}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!strengthPassword.upperLetters">
                                <div class="flex gap-2 items-center text-14 text-inicieColors-orange">
                                    <span class="iconify text-22" data-icon="ri:close-circle-line"></span>
                                    <span>{{i18n.reset_include_upper_case}}</span>
                                </div>
                            </ng-container>
                        </div>

                        <!-- Tem números -->
                        <div class="flex">
                            <ng-container *ngIf="strengthPassword.numbers">
                                <div class="flex gap-2 items-center text-14 text-customGreen-approved">
                                    <span class="iconify text-22" data-icon="ri:checkbox-circle-line"></span>
                                    <span>{{i18n.reset_include_numbers}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!strengthPassword.numbers">
                                <div class="flex gap-2 items-center text-14 text-inicieColors-orange">
                                    <span class="iconify text-22" data-icon="ri:close-circle-line"></span>
                                    <span>{{i18n.reset_include_numbers}}</span>
                                </div>
                            </ng-container>
                        </div>

                        <!-- Tem caracteres especiais -->
                        <div class="flex">
                            <ng-container *ngIf="strengthPassword.symbols">
                                <div class="flex gap-2 items-center text-14 text-customGreen-approved">
                                    <span class="iconify text-22" data-icon="ri:checkbox-circle-line"></span>
                                    <span>{{i18n.reset_include_special_symbols}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!strengthPassword.symbols">
                                <div class="flex gap-2 items-center text-14 text-inicieColors-orange">
                                    <span class="iconify text-22" data-icon="ri:close-circle-line"></span>
                                    <span>{{i18n.reset_include_special_symbols}}</span>
                                </div>
                            </ng-container>
                        </div>

                        <!-- Tem mais que seis caracteres -->
                        <div class="flex">
                            <ng-container *ngIf="strengthPassword.maxLength">
                                <div class="flex gap-2 items-center text-14 text-customGreen-approved">
                                    <span class="iconify text-22" data-icon="ri:checkbox-circle-line"></span>
                                    <span>{{i18n.reset_include_more_than_six_characters}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!strengthPassword.maxLength">
                                <div class="flex gap-2 items-center text-14 text-inicieColors-orange">
                                    <span class="iconify text-22" data-icon="ri:close-circle-line"></span>
                                    <span>{{i18n.reset_include_more_than_six_characters}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </app-display-panel>
            </div>

            <div class="flex justify-end font-semibold">
                <ng-container *ngIf="isPasswordStrength">
                    <div class="flex gap-2 items-center text-12 text-customGreen-approved">
                        <span>{{i18n.reset_stregth_password}}</span>
                        <span class="iconify text-22" data-icon="ri:checkbox-circle-line"></span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isPasswordStrength && isPasswordStrength != null">
                    <div class="flex gap-2 items-center text-12 text-inicieColors-orange">
                        <span>{{i18n.reset_weak_password}}</span>
                        <span class="iconify text-22" data-icon="ri:close-circle-line"></span>
                    </div>
                </ng-container>
            </div>
        </div>



        <div class="flex flex-col mt-5">
            <label class="text-customGray-swampGray text-16">{{i18n.reset_confirm_new_password}}</label>
            <div class="flex font-OpenSans">
                <input 
                    #confirmPassword type="password" [placeholder]="i18n.reset_confirm_your_password" class="input-reset w-full" 
                    required autocomplete="off" formControlName="confirmPassword" (keyup)="checkConfirmPassword(confirmPassword.value)">
                <span class="cursor-pointer" (click)="hiddenPass('confirmPassword')" (click)="isHiddenPassConfirm = !isHiddenPassConfirm">
                    <div *ngIf="!isHiddenPassConfirm" class="relative">
                        <span class="iconify eye-icon text-customGray-iconGray" data-icon="ph:eye"></span>
                    </div>
                    <div *ngIf="isHiddenPassConfirm" class="relative">
                        <span class="iconify eye-icon text-customGray-iconGray" data-icon="ph:eye-slash"></span>
                    </div>
                </span>
            </div>
        </div>

        <div class="flex justify-center text-12 font-semibold font-OpenSans text-customGray-swampGray mt-4">
            {{i18n.reset_password_stregth_description}}
        </div>


        <div class="flex justify-center items-center flex-col mt-10 py-2">
            <button [ngClass]="{'disabled:opacity-20 cursor-not-allowed':!isConfirmPasswordValid || !isPasswordStrength}" 
                    [disabled]="!isConfirmPasswordValid || !isPasswordStrength" 
                    (click)="updatePassword()" style="min-width: 275px;" 
                    type="button" class="bg-customBlue-default text-customWhite-default font-bold px-4 py-3 border btn-blue shadow-lg mx-auto"
            >
                <div class="flex justify-center items-center gap-6">
                    <span>{{i18n.reset_change_password}}</span>
                </div>
            </button> 
        </div>
    </form> 
</div>

</section>
