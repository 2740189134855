import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconModule } from '@visurel/iconify-angular';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';
import { SafeHTMLPipe } from 'src/app/pipes/safe-html/safe-html.pipe';
import { SafeUrlPipe } from 'src/app/pipes/safe-url/safe-url.pipe';

import { BulletsLoaderModule } from '../components/bullets-loader/bullets-loader.module';
import { ButtonsModule } from '../components/buttons/buttons.module';
import { EffectsModule } from '../components/effects/effects.module';
import { PaginationModule } from '../components/pagination/pagination.module';
import { ContentTypesIconsComponent } from '../pages/disciplines/components/content/components/content-types-icons/content-types-icons.component';
import { DateAgoPipe } from '../pipes/date-ago/date-ago.pipe';
import { ReplacePipe } from '../pipes/replaceText/replace-pipe';

@NgModule({
    declarations: [
        SafeUrlPipe,
        SafeHTMLPipe,
        DateAgoPipe,
        ContentTypesIconsComponent,
        ReplacePipe
    ],
    imports: [
        CommonModule,
        ButtonModule,
        ConfirmDialogModule,
        CalendarModule,
        DialogModule,
        DynamicDialogModule,
        DragDropModule,
        DropdownModule,
        FieldsetModule,
        OverlayPanelModule,
        OrganizationChartModule,
        PanelModule,
        TreeSelectModule,
        ToastModule,
        TooltipModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        ColorPickerModule,
        ButtonsModule,
        EffectsModule,
        PaginationModule,
    ],
    exports: [
        ButtonModule,
        ConfirmDialogModule,
        CalendarModule,
        DialogModule,
        DynamicDialogModule,
        DragDropModule,
        DropdownModule,
        FieldsetModule,
        OverlayPanelModule,
        OrganizationChartModule,
        PanelModule,
        TreeSelectModule,
        ToastModule,
        TooltipModule,
        RouterModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        ColorPickerModule,
        SafeUrlPipe,
        SafeHTMLPipe,
        IconModule,
        DateAgoPipe,
        ContentTypesIconsComponent,
        ButtonsModule,
        EffectsModule,
        PaginationModule,
        BulletsLoaderModule,
        ReplacePipe
    ],
})
export class SharedModule { }
