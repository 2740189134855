<!--MODAL Edit Certificate-->
<div class="transition ease-out duration-300 animate-fade-in-down overflow-x-hidden overflow-y-auto outline-none justify-center items-center flex"
    style="position: absolute; z-index: 6; top: 30%; left: 50%;
    transform: translateX(-50%)" >
    <div class="relative w-auto mx-auto max-w-6xl">
        <!--content-->
        <div
            class="border-0 rounded-2xl shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-customWhite-default">
            <div *ngIf="!loading">
                <!--header-->
                <div class="border-b border-solid border-customBlue-superLight rounded-t">
                    <div class="certificateEditHeader my-6 text-customBlue-default text-2xl font-semibold">
                        {{ editModalTitle }}
                    </div>
                </div>
                <!--body-->
                <div class="bodyTitle certificateEditHeader mt-4">
                    <button class="button-edit" (click)="openUploadContent()" *ngIf="editMode">
                        <p>
                            <span>
                                <img />
                            </span>
                            {{i18n.disciplines_cert_change_file}}
                        </p>
                    </button>
                </div>
                <div [formGroup]="editMode ? editCertificateForm : postCertificateForm">
                    <div class="mt-6">
                        <div class="mx-10 mb-1 text-base font-bold text-inicieColors-blue">
                        {{i18n.disciplines_cert_name_tamplate}}
                        </div>
                        <div class="mx-10 mb-4">
                            <input class="relative w-11 inputEditCertificate" formControlName="name"
                            />
                        </div>
                    </div>
                </div>
                <!--footer-->
                <div class="flex items-center justify-center p-3 rounded-b mt-8 mb-8">
                        <!--  {{ postCertificateForm.value | json }}  -->
                <!--    {{ editCertificateForm.value | json }}
                    {{ buttonUnlocked | json }} -->
                <!--   <button class="button-save mr-4" (click)="editingCertificate()"> -->
                        <button class="button-save" (click)="saveCertificate()" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !buttonUnlocked}" [disabled]="!buttonUnlocked">
                        <p>{{i18n.disciplines_save}}</p>
                    </button>
                    <button class="button-cancel" (click)="closeEditCertificate()" >
                        <p>{{i18n.disciplines_cancel}}</p>
                    </button>
                </div>
            </div>
            <ng-container *ngIf="loading">
                <div class="flex justify-center items-center py-20 md:w-96 w-80">
                  <app-bullets-loader></app-bullets-loader>
                </div>
            </ng-container>
      </div>
    </div>
</div>
 <div *ngIf="modalEditCertificate$" class="overlay"
    (click)="closeEditCertificate()" style="z-index: 5"></div>
