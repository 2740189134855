import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-create-scale',
  templateUrl: './create-scale.component.html',
  styleUrls: ['./create-scale.component.scss'],
})
export class CreateScaleComponent implements OnInit {
  @Output() change = new EventEmitter();
  @Input() data: any;

  i18n: any;

  firstDropdown: any[] = [
    { id: 1, translation: 1 },
    { id: 0, translation: 0 },
  ];

  secondDropdown: any[] = [
    { id: 10, translation: 10 },
    { id: 9, translation: 9 },
    { id: 8, translation: 8 },
    { id: 7, translation: 7 },
    { id: 6, translation: 6 },
    { id: 5, translation: 5 },
    { id: 4, translation: 4 },
    { id: 3, translation: 3 },
    { id: 2, translation: 2 },
  ];

  dataPayload = {
    ready: false,
    payload: {
      value: {
        higherBound: this.secondDropdown[0].id,
        higherLabel: '',
        lowerBound: this.firstDropdown[0].id,
        lowerLabel: '',
      },
    },
  };

  constructor(private shared: SharedService) {}

  ngOnInit(): void {
    const translationModuleName = 'QuestionBank';
    this.shared.setI18n(this, translationModuleName);

    if (!this.data) return;

    this.dataPayload.ready = true;

    //SET FIRSTDROPDOWN
    const lowerBound = this.firstDropdown.find(
      (num) => num.id === this.data.value.lowerBound
    );
    const lowerBoundRest = this.firstDropdown.filter(
      (num) => num.id !== this.data.value.lowerBound
    );
    this.firstDropdown = [lowerBound, ...lowerBoundRest];

    //SET SECONDDROPDOWN
    const higherBound = this.secondDropdown.find(
      (num) => num.id === this.data.value.higherBound
    );
    const higherBoundRest = this.secondDropdown.filter(
      (num) => num.id !== this.data.value.higherBound
    );
    this.secondDropdown = [higherBound, ...higherBoundRest];

    //SET LABEL
    this.dataPayload.payload.value.lowerLabel = this.data.value.lowerLabel;
    this.dataPayload.payload.value.higherLabel = this.data.value.higherLabel;

    //SET BOUND
    this.dataPayload.payload.value.lowerBound = this.data.value.lowerBound;
    this.dataPayload.payload.value.higherBound = this.data.value.higherBound;
  }

  setScale(scale: any, choosen: number) {
    if (choosen) this.dataPayload.payload.value.higherBound = scale[0].id;
    else this.dataPayload.payload.value.lowerBound = scale[0].id;

    this.sendData();
  }

  getData($event, choosen: number) {
    if ($event.hasOwnProperty('target')) $event.stopPropagation();
    const inputValue = $event.target.value;

    if (choosen) this.dataPayload.payload.value.higherLabel = inputValue;
    else this.dataPayload.payload.value.lowerLabel = inputValue;

    this.dataPayload.ready = Boolean(
      this.dataPayload.payload.value.higherLabel.length &&
        this.dataPayload.payload.value.lowerLabel.length
    );

    this.sendData();
  }

  sendData() {
    this.dataPayload.ready = Boolean(
      this.dataPayload.payload.value.higherLabel.length &&
        this.dataPayload.payload.value.lowerLabel.length
    );

    this.change.emit(this.dataPayload);
  }
}
