<div class="bg-customWhite-default rounded-lg shadow-lg mb-5">
    <div class="p-2">
      <h1 class="text-xl font-bold mb-3 text-customGray-dark">
            {{dashData?.resume_data?.title }}
      </h1>
      <div class="flex">
        <!-- <img style="max-width: 192px; max-height: 123px;" [errorImage]="errorImage" [lazyLoad]="dashData.resume_data.image" [defaultImage]="defaultImage"
              class="rounded-lg img-main"
          /> -->
          
          <img  style="max-width: 192px; max-height: 123px;" *ngIf="dashData?.resume_data?.image != null"  [src]="dashData?.resume_data?.image" [errorImage]="errorImage"  [lazyLoad]="dashData?.resume_data?.image" [defaultImage]="defaultImage"
          class="rounded-lg img-main" />

        <img  style="max-width: 192px; max-height: 123px;" *ngIf="dashData?.resume_data?.image == null"  src="{{errorImage}}"
          class="rounded-lg img-main" /> 
      <div class="box-text">
        <p class="fonte-announcements-text "> 
           {{dashData?.resume_data?.text}} 
          <!-- Lorem ipsum dolor sit amet consectetur adipiscing elit malesuada elementum, nam tempor potenti euismod nunc magna aliquet suscipit congue lobortis, iaculis pellentesque purus quis class arcu primis lectus. Ultricies proin hac semper maecenas dolor lacus scelerisque, orci ante posuere ut nam nascetur, elementum felis sit nunc ex etiam. A suspendisse proin molestie nibh mollis velit erat nisi, est felis curabitur ipsum auctor metus tortor, sem sodales nunc habitant tempus cubilia mi. Integer rhoncus morbi dictum ligula maximus lorem id feugiat blandit sem mattis fusce, magna imperdiet accumsan ornare elementum suscipit semper nostra scelerisque est nunc, in ante taciti dignissim laoreet aptent ridiculus pellentesque ultricies tortor porttitor. Sodales litora quisque erat feugiat ornare urna, lobortis nunc nec rutrum eros leo, netus iaculis commodo parturient class. Senectus leo proin mollis rutrum efficitur parturient, donec lectus eu vestibulum suscipit ridiculus non, sociosqu pharetra nec placerat viverra. -->
        </p>
      </div>
       <!--  <button 
        (click)="routeToLink(dashData.resume_data)"
        class="
              announcements-button
              border
              rounded-md
              py-2
              px-8
              font-bold
              text-sm
              border-customGray-textGray" style="color: #FFFFFF;background-color: #233674;cursor: pointer;
              ">
          Continuar
        </button> -->
      
      </div>
      <div class="flex justify-end">
        <button  
        class="flex grow h-10 w-28 justify-center border rounded-md py-2 px-8 font-bold text-sm border-customGray-textGray"
        style="color: #ffffff; background-color: #233674"
        (click)="routeToLink(dashData?.resume_data)"
        >
        {{i18n.dash_continue}}
      </button>
    </div>
    </div>
  </div>