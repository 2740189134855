import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bullets-loader',
  templateUrl: './bullets-loader.component.html',
  styleUrls: ['./bullets-loader.component.scss']
})
export class BulletsLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
