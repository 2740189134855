import { finalize } from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, Inject, ViewChild, Renderer2, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConferenceCheckerService } from 'src/app/services/conference-checker.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ConfirmationService } from 'primeng/api';
import { ConditionalAndCriteriaService } from 'src/app/components/conditional-advance/conditional-and-criteria.service';
import { Subscription } from 'rxjs';
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { GoogleGetFileService } from 'src/app/services/google-get-file-service.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { DOCUMENT } from '@angular/common';
import { RestoreFormattingService } from 'src/app/shared/restore-formatting.service';
import { SectionService } from '../../../section.service';
import { ContentService } from '../../../content.service';
import { ContentQuizService } from '../components/content-view-quiz/content-quiz.service';
import { DisciplineDetailService } from '../../../../disciplines-detail/discipline-detail.service';
import { HomeService } from 'src/app/pages/home/home.service';
import { ContentViewContentListComponent } from '../components/content-view-content-list/content-view-content-list.component';


const DEFAULT_DURATION = 250;

@Component({
  selector: 'app-support-material-view',
  templateUrl: './support-material-view.component.html',
  styleUrls: ['./support-material-view.component.scss'],
  providers:[ConfirmationService],
  animations: [
    trigger('collapse', [
      state(
        'true',
        style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
      ),
      state(
        'false',
        style({ height: '0px', visibility: 'hidden', opacity: 0 })
      ),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in')),
    ]),
  ],
})
export class SupportMaterialViewComponent implements OnInit, AfterViewChecked ,OnDestroy {
  @ViewChild('textDescription') textDescription: ElementRef;
  @ViewChild('iframe') iframe: Renderer2
  @ViewChild('contentViewContentList', { static: false }) contentViewContentList!: ContentViewContentListComponent;
  collapseOption: boolean = false;
  discipline_external_id: any;
  section_external_id: any;
  content_external_id: any;
  type: any;
  url: any = '';
  content: any;
  sections: any = [];
  contents: any = [];
  expanded: boolean = false;
  showModal: boolean;
  reportBugForm: UntypedFormGroup;
  hasIframe: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  videoConference = { text: [], video: [] };
  user: any;
  modalOpen$ = this.sharedService.modalOpen$;
  filesToUploadModal: any[];
  fileTypeToModalUpload: string = '';
  isLoading: boolean = false;
  timerSubscription: Subscription;
  currentLanguage: any;
  i18n: any = [];
  maxFiles: any;
  loadingContent: boolean = false;
  isLoadingUrl: boolean = false;
  permissionQuestionnaire: any = {};
  isNextContentPermission: boolean | null = false;

  // Adicionado
  isGapiLoaded = this.googleGetFileService.isGapiLoaded;
  getDownload: boolean = false;
  isGapiLoadedSubscription: Subscription;
  indexFile: number = 0;
  file: any = {};

  startDate: any
  endDate: any;
  getDataFromContentView: any;

  counter: any = 0
  timer: any = []
  timeStatus: any;

  isConcludedStudentNotification: string;
  errorRequest: boolean = false;

  //getterCounterSub: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private conferenceCheckerService: ConferenceCheckerService,
    private sectionService: SectionService,
    private conclusionCriteriaService: ConditionalAndCriteriaService,
    private confirmationService: ConfirmationService,
    private contentService: ContentService,
    private domSanitizer: DomSanitizer,
    private FormBuilder: UntypedFormBuilder,
    private hostElement: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    public sharedService: SharedService,
    private googleGetFileService: GoogleGetFileService,
    private platModalService: PlatformModalsService,
    private restoreFormattingService: RestoreFormattingService,
    private quizService: ContentQuizService,
    private disciplineDetailsService: DisciplineDetailService,
    private homeService: HomeService,
    private cd: ChangeDetectorRef

  ) {}

  elem;

  ngOnInit(): void {
    this.getTranslations();
    this.getUrlParams();
    this.VerifyNotPermissionsQuestionnaire();
    this.createReportBugForm();
    this.getUser();

    this.isGapiLoadedSubscription =
      this.googleGetFileService.isGapiLoaded.subscribe((status: any) => {
        this.isGapiLoaded = status;
        if (this.getDownload) {
          this.downloadFile();
        }
      });
    }
    
  ngAfterViewChecked(): void {
    this.getStateExpanded();
    this.cd.detectChanges();
    
    if (this.content?.description) {
      this.restoreFormattingService.restoreFormatting(this.textDescription);
    }
  }

  onLoad(){
    this.elem = document.querySelector('#notFileIframe');
  }

  get titleValid() {
    return this.reportBugForm.get('title');
  }

  get description() {
    return this.reportBugForm.get('description');
  }

  getTranslations() {
    this.i18n = {
      ...this.sharedService.getTranslationsOf('Disciplines'),
      ...this.sharedService.getTranslationsOf('Modal'),
      ...this.sharedService.getTranslationsOf('AdvanceAndCriterion'),
      ...this.sharedService.getTranslationsOf('Grades'),
    };
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  getReloadStatus() {
    this.getContent(this.discipline_external_id, this.section_external_id, this.content_external_id);
  }

  criteriaType(completion_rule: string) {
    this.conclusionCriteriaService.receivesContent(
      this.discipline_external_id,
      this.content_external_id
    );
    this.conclusionCriteriaService.whatIsTheCriterion(completion_rule);
    if(completion_rule == 'TIME') {
      this.timeCriteriaCounter(this.discipline_external_id, this.content_external_id);
    }
  }

  onSelectFiles($event) {
    /**
     * Adicionada verificação para ver se houve alguma alteração para ser enviada ao servidor, caso os dados de $event sejam iguais aos dados preexistentes nada foi mudado e nenhuma requisição deve ser enviada ao servidor, sem essa verificação mesmo que o usuário não altere nada, apenas abra e feche a modal, uma requisição vai ser enviada, gastando banda do usuario e recursos do servidor desnecessáriamente já que absolumante nada foi alterado
     */
    const idOfFilesFromEvent = $event.map((recording) => recording.id);
    const FilesFromServer = [
      ...this.videoConference.video,
      ...this.videoConference.text,
    ];
    const idOfFilesFromServer = FilesFromServer.map((obj) => obj['id']);

    function checkEveryElement(a, b) {
      return a.every((v, i) => v === b[i]) && b.every((v, i) => v === a[i]);
    }

    if (!checkEveryElement(idOfFilesFromEvent, idOfFilesFromServer)) {
      this.toggleLoading();
      this.conferenceCheckerService
        .addRecording({
          discipline_id: this.discipline_external_id,
          section_id: this.section_external_id,
          content_id: this.content_external_id,
          body: { files: idOfFilesFromEvent },
        })
        .subscribe({
          next: () => this.ngOnInit(),
          error: (err) => {
            let textError: string = `${err.message} | ${err.error.message}`;
            this.platModalService.toggle('message', textError, 'close');
          },
          complete: () => this.toggleLoading(),
        });
    }
  }

  getUser() {
    this.sharedService.getUser().subscribe({
      next: (user) => {
        console.log(user)
        this.user = user;
      }
    });
  }

  getUrlParams() {
    
    this.route?.parent?.parent?.params?.subscribe((params: Params) => {
      this.discipline_external_id = params.id;
    });

    this.route?.params?.subscribe((params: Params) => {
      
      this.section_external_id = params.section;
      if (params.id != this.content_external_id) {
        this.goToContentEmitter(params.id);
      }
    });

  }

  createReportBugForm(): void {
    this.reportBugForm = this.FormBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  nextContent() {
    console.log('this.content ', this.content);
    
    this.contentViewContentList.goToContentByArrow(this.content?.next_content);
  }


  previousContent() {
    this.contentViewContentList.goToContentByArrow(this.content?.previous_content);
  }

  toggleVideoConferenceChatVisibility($event) {
    const $chatHeader = $event.currentTarget;
    const chatHeaderHeight = `${$chatHeader.scrollHeight / 16}rem`;
    const $chatIframes = $event.currentTarget.parentElement;
    const chatIframesHeight = `${$chatIframes.scrollHeight / 16}rem`;

    $chatIframes.classList.toggle('active')
      ? (($chatIframes.style.transition =
          'max-height 300ms cubic-bezier(.91,.25,.55,.96) 100ms'),
        ($chatIframes.style.maxHeight = chatIframesHeight))
      : (($chatIframes.style.transition =
          'max-height 200ms cubic-bezier(.44,.4,.5,.82)'),
        ($chatIframes.style.maxHeight = chatHeaderHeight));
  }


  getStateExpanded() {
    this.homeService.getExpanded$.subscribe({
      next: (reponse: boolean) => {
        this.expanded = reponse;
        this.homeService.setExpanded$.next(this.expanded);
      }
    })
  }

  expandView(): void {
    // Aumenta toda a tela (Comentado pois não está funcionando e está quebrando as demais lógicas)
    // if (content.type == 'Scorm' ||  content.type == 'InternalConference') {
    //   this.elem.requestFullscreen();
    //   return;
    // }
    
    this.expanded = !this.expanded;
    this.homeService.getExpanded$.next(this.expanded);
  }

  sanitizeURL(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }



  getDataEndTime(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
  }


  returnArrayValid(data: []) {
    return data?.filter((data) => data !== null &&  data !== undefined);
  }

  goToContentEmitter(content_external_id: any){
    this.content_external_id = content_external_id
    this.getContent(
      this.discipline_external_id, 
      this.section_external_id, 
      content_external_id
    );
  }

  is_locked: boolean = false;
  isLoadingDocsAndSheets: boolean = false;
  errorScorm: boolean = false;
  getContent(
    discipline_external_id: string,
    section_external_id: string,
    content_external_id: string
    ) {
    this.isLoadingUrl = true;
    this.toggleLoading();
    this.stopTimer()
    this.contentService
    .getOneSupportMaterial(content_external_id)
      .subscribe({
        next: (content) => {
          const materialSupport = content[0];

          this.content = [];
          this.is_locked = false;
          this.url = '';
          this.errorRequest = false;

          this.disciplineDetailsService.titleName$.next(materialSupport.title);
          
          if (materialSupport.status == 'LOCKED') {
            this.is_locked = true;
            this.content = materialSupport;
            return
          }

          this.content = materialSupport;
          
          if (materialSupport.type == 'File') {
            this.hasIframe = true;
            materialSupport.contentSpecific.files.forEach(element => {
              element.height = '480px';
              // let drive_id = element.public_url.split('?id=').pop();
              // let url = `https://drive.google.com/file/d/${drive_id}/preview`;
              let url = element.public_url;
              element.url = url;
              if (element.mime_type == 'application/pdf') {
                element.height = '680px';
              }
              if (element.mime_type.includes('image')) {
                element.height = '300px';
              }
            });
          }

          if (materialSupport.type === 'Link') {
            this.url = materialSupport?.contentSpecific?.link_site;
            
            if (materialSupport?.contentSpecific?.isIframable) {
              this.hasIframe = true;

              setTimeout(() => {
                const iframe =
                  this.hostElement.nativeElement.querySelector('iframe');
                 iframe.src = this.url;
              }, 100);
            } else {
              this.platModalService.toggle('decision', 'generic_subject_link_content', {
                forward: () => {
                  window.open(this.url, '_blank')?.focus();
                },
                finally: () => {
                  this.platModalService.close('decision');
                },
              });
            }
          }

          if (materialSupport.type === 'Youtube') {
            this.url = materialSupport?.contentSpecific?.link_site;
            if (!materialSupport?.contentSpecific?.isIframable) {
              this.platModalService.toggle('decision', 'generic_subject_link_content', {
                forward: () => {
                  window.open(this.url, '_blank')?.focus();
                },
                finally: () => {
                  this.platModalService.close('decision');
                },
              });
            }
          }

          if (materialSupport.type === 'TextPage') this.hasIframe = false;
        },
        error: (error) => {
          this.errorRequest = true;
          this.toggleLoading();
          this.isLoadingUrl = false;
          let messageError: string = '';
          
          switch (error.status) {
            case 422:
              error?.error?.error.forEach((element) => {
                if (Array.isArray(element)) {
                  element.forEach((el) => { messageError += '<p>'+el + '</p><br>' })
                } else {
                  messageError = element;
                }
              })
              break
            default: messageError = error.error.error;
          }

          if (this.i18n.hasOwnProperty(messageError)) {
            messageError = this.i18n[messageError]
          }

          this.platModalService.toggle('message', messageError, 'close');
        },
        complete: () => {
          this.toggleLoading();
          this.isLoadingUrl = false;

        },
      });
  }

  conditionalContent: any;
  getConditionalContent(contentLockedFromSection){
    console.log('contentLockedFromSection. ', contentLockedFromSection);
    this.conditionalContent = contentLockedFromSection;
    
  }

  goToContent(content_external_id){
    this.contentViewContentList.goToContentByArrow(content_external_id)
  }

  async getInternalConferenceUrl(content){
    let token = localStorage.getItem('token');

    if (content.contentSpecific.conference.url) {
      const response = await fetch(content.contentSpecific.conference.url, {
        method: 'GET',
        headers: {
          'Authorization': "Bearer " + token
        }
      })

      const blob = await response.blob();
      const urlObject = URL.createObjectURL(blob);
      if (response) {

        this.url = urlObject;
        setTimeout(() => {
          const iframe =
          this.hostElement.nativeElement.querySelector('iframe');
          iframe.src = this.url ;
          let status = '';
          let origin = window.location.origin;

          let checkStatusConference = setInterval(() => {
            window.addEventListener("message", (e)=>{
              if (e.origin !== origin) {
                return;
              }

              status = e.data;

              if (status == 'finish') {
                clearInterval(checkStatusConference);
                this.returnRouteContent();
              }

            });

          }, 5000);
        }, 100);

      }
    }

  }

  setVideoConference(content: {}) {
    // this.videoConference.text = content.contentSpecific.recordings.text
    // this.videoConference.video = content.contentSpecific.recordings.video
    // this.filesToUploadModal = [...this.videoConference.video, ...this.videoConference.text]
  }

  prepareParamateresToUploadModal(fileType: string) {
    fileType === 'video'
      ? (this.fileTypeToModalUpload = 'video')
      : (this.fileTypeToModalUpload = 'document');
  }

  postBugReport(): void {
    const params = {
      title: this.reportBugForm.controls.title.value,
      description: this.reportBugForm.controls?.description?.value,
    };
    // TO DO API POST
  }

  

  returnRouteContent(): void {
    this.router.navigate([`../../overview`], {
      relativeTo: this.route,
    });
  }

  toggleLoading(): void {
    this.isLoading = !this.isLoading;
    this.loadingContent = !this.loadingContent;
  }

  openModal(): void {
    this.reportBugForm.controls['title'].setValue('');
    this.reportBugForm.controls['description'].setValue('');
    this.showModal = !this.showModal;
  }

  toggleCollapseOption() {
    this.collapseOption = !this.collapseOption;
  }

  closeModal(): void {
    this.showModal = false;
  }

  returnIconMaterialsSupport(mime_type: string) {
    return mime_type.includes('image')
      ? 'LTI'
      : mime_type.includes('video')
      ? 'Youtube'
      : mime_type.includes('text')
      ? 'TextPage'
      : 'FilePDF';
  }

  downloadFile() {
    let filesInfo = {
      url: '',
      mime_type: '',
    };

    // identifica se é um arquivo do google apps
    if (this.file?.mime_type.includes('google-apps')) {
      filesInfo.mime_type =
        this.sharedService.selectMimeTypeToExport(this.file) || '';
    }

    filesInfo.url = this.file.path;

    if (this.isGapiLoaded) {
      this.googleGetFileService
        .downloadFile(filesInfo.url)
        .subscribe({
          next: (response: any) => {
            let url: any;

            if (filesInfo.mime_type !== '') {
              url = response.result.exportLinks[filesInfo.mime_type];
            } else {
              url = response.result.webContentLink;
            }

            window.open(url);
            this.getDownload = false;
          },
          error: (err) => {
            if (err.status == 404) {
              this.platModalService.toggle(
                'message',
                'Sem permissão para acessar o arquivo',
                'close'
              );
            } else {
              setTimeout(() => this.getGoogleFile(), 1000);
            }
          }
        })
    }
  }

  VerifyNotPermissionsQuestionnaire(): void {
    if (localStorage.getItem('permissions')) {
      const permissions = JSON.parse(localStorage.getItem('permissions')!)
      this.permissionQuestionnaire = permissions.questionnaire;
    }
  }

  // Inicia serviço do GoogleDrive para baixar o arquivo
  getGoogleFile(file?) {
    this.file = file;
    this.getDownload = true;
    this.googleGetFileService.initGoogleDriveGapi();
  }

  changeFile(index: number) {
    this.indexFile = index;
    document.getElementsByClassName('router-outlet')[0].scrollTop = 0;
  }

  //Funções do critério de conclusão TEMPO
  timeCriteriaPost(
    discipline_external_id: string,
    content_ext_id: string,
    params: any,
    ) {
    this.conclusionCriteriaService
    .postCompletedContent(
      discipline_external_id,
      content_ext_id,
      params,
      )
    .subscribe(
      (data) => {
        this.timeStatus = data?.status
        if(this.timeStatus == 'COMPLETE') {
          this.stopTimer();
          this.getReloadStatus()
        }
      },
      () => this.stopTimer(),
      () => console.log("TIME POST OK"),
    )
  }

  timeCriteriaCounter(
    discipline_external_id: string,
    content_ext_id: string,
    ) {
      let params = {}
      let counter = 0;
      this.timer = setInterval(() => {
        counter++;
        this.timeCriteriaPost(discipline_external_id, content_ext_id, params)
        this.counter = counter
      }, 60000);
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  studentNotification() {
    if(this.isConcludedStudentNotification = 'PENDING') {
      this.conclusionCriteriaService.studentNotificationMethod();
      this.getReloadStatus();
      this.isConcludedStudentNotification = 'COMPLETE'
    }
  }

  

  getTraductionsCompletionRule(type: string): string | undefined {
    if (type === null || type === undefined) return '';
    
    if (type === 'ACCESS') return this.i18n.adv_and_crit_access;
    if (type === 'TIME') return this.i18n.adv_and_crit_time;
    if (type === 'ATTEMPT_SUBMISSION') return this.i18n.adv_and_crit_attempt_sub;
    if (type === 'STUDENT_NOTIFICATION') return this.i18n.adv_and_crit_std_notification;
  }

  getTraductionTypeContent(type: string): string | undefined {
    if (type === null || type === undefined) return '';

    // Assignment 
    if (type === 'Assignment' || type === 'UploadAssignment') {
      return this.i18n.disciplines_type_upload_assignment;
    }

    // DocsAssignment 
    if (type === 'DocsAssignment') {
      return this.i18n.disciplines_type_docs_assignment;
    }

    // SheetsAssignment 
    if (type === 'SheetsAssignment') {
      return this.i18n.disciplines_type_sheets_assignment;
    }

    // File 
    if (type === 'File') {
      return this.i18n.disciplines_page_file;
    }

    // Youtube 
    if (type === 'Youtube') {
      return this.i18n.disciplines_youtube;
    }

    // TextPage 
    if (type === 'TextPage') {
      return this.i18n.disciplines_text_page;
    }

    // Link 
    if (type === 'Link') {
      return this.i18n.disciplines_link;
    }

    // Forum 
    if (type === 'Forum') {
      return this.i18n.disciplines_select_tab_forum;
    }

    // Conference 
    if (type === 'Conference' || type === 'InternalConference') {
      return this.i18n.disciplines_conference;
    }

    // Quiz 
    if (type === 'InternalQuiz') {
      return this.i18n.disciplines_upload_quiz;
    }
  
    // Quiz 
    if (type === 'UploadQuiz') {
      return this.i18n.disciplines_upload_quiz_external;
    }
   
    // Scorm 
    if (type === 'Scorm') {
      return this.i18n.disciplines_scorm;
    }

    // LTI 
    if (type === 'LTI') {
      return this.i18n.disciplines_page_lti;
    }
  }

  // Função para formatar a data removendo os segundos e timeZone...
  searchFirstPathDate(text: any): string {
    if (!text || text === null || text === undefined) return '';
    
    let primeiraParte = '';
    let index = 0;

    // Encontrar o índice do segundo ':' na string
    for (let i = 0; i < text.length; i++) {
      if (text[i] == ':' && index < 2) {
        index++;
        if (index == 2) {
          primeiraParte = text.slice(0, i);
          break;
        }
      }
    }

    // Se o segundo ':' não for encontrado, retornar a string original
    if (primeiraParte === '') {
      primeiraParte = text;
    }

    return primeiraParte;
  }
  

  ngOnDestroy(): void {
    this.stopTimer();
    this.isGapiLoadedSubscription?.unsubscribe();
    this.disciplineDetailsService.titleName$.next('');
  }


}
