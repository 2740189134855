export const environment = {
  production: true
};

export const API_Routes = {
  URL: '/api',
  DOMAIN: 'localhost',
};

export const App_settings = {
  VERSION: require('../../package.json').version,
  PROJECT_ID: 'inicie-plataforma-prod',
  API_KEY: 'AIzaSyB_8KPUa_QIzxKWOA9tq3kqxTA8qYcoDoQ'

}