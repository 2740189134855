<!-- MODAL ADIÇÃO E EDIÇÃO DE CONCEITO -->
<app-generic-modal [isVisible]="isModalOpen">
  <div
    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style="max-width: 540px;"
  >
    <!--header-->
    <div
      class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t"
    >
      <div class="flex items-center justify-center gap-2">
        <h3 class="text-2xl font-semibold text-customBlue-default">
          <ng-container *ngIf="!editMode">{{
            i18n.grades_period
          }}</ng-container>
          <ng-container *ngIf="editMode">{{
            i18n.grades_edit_period
          }}</ng-container>
        </h3>
        <div class="relative" (click)="displayPanel.toggle()">
          <span
            class="iconify cursor-pointer"
            data-icon="bi:question-circle"
          ></span>
          <div>
            <app-display-panel
              #displayPanel
              [style]="{ position: 'absolute' }"
              [custom_radius]="'10px'"
              [hide_arrow]="true" 
              [panel_style]="{ background: '#FEF8E6', width: '205px' }"
            >
              {{ i18n.grades_period_create_tooltip }}
            </app-display-panel>
          </div>
        </div>
      </div>
    </div>
    <!--body-->
    <form [formGroup]="periodsForm">
      <div class="flex flex-col gap-4 py-4 px-11">
        <div>
          <label class="mb-1 block text-customBlue-default font-medium">{{
            i18n.grades_period_name
          }}</label>
          <input
            type="text"
            class="w-full border border-customGray-grayTwo p-1.5 rounded-sm text-md"
            required
            formControlName="name"
          />
          <div [ngClass]="{'hidden': name?.pristine}">
            <p
              class="text-sm text-customOrange-default"
              [ngClass]="{ 'hidden': !name?.errors?.required }"
            >
              {{ i18n.grades_name_required }}
            </p>
          </div>
        </div>
        <div>
          <label
            class="mb-1 block text-customBlue-default font-medium"
            for="start_date"
            >{{ i18n.grades_period }}</label
          >
          <div class="flex flex-col md:flex-row gap-3 items-center">
            <div>
              <app-picker-calendar
              [i18n]="i18n"
              [currentLanguage]="currentLanguage"
              [editMode]="true"
              [initialDate]="initialDate"
              [Form]="periodsForm"
              [inputNameControlPicker]="'start_date'"
              [inputControlNameMask]="'calendarStartDate'"
              [position]="'absolute'"
              [minDateValidation]="startDate?.value === '' || startDate?.value == null ? initialDate : startDate?.value"
            ></app-picker-calendar>

              <div [ngClass]="{'hidden': start_date?.pristine}" >
                <p
                  class="text-sm text-customOrange-default"
                  [ngClass]="{ 'hidden': !start_date?.errors?.required }"
                >
                  {{ i18n.grades_start_date_required }}
                </p>
              </div>
            </div>
            <span class="text-customBlue-default font-medium">{{
              i18n.grades_date_to
            }}</span>
            <div>
              <app-picker-calendar
              [i18n]="i18n"
              [currentLanguage]="currentLanguage"
              [editMode]="true"
              [initialDate]="initialDate"
              [Form]="periodsForm"
              [inputNameControlPicker]="'end_date'"
              [inputControlNameMask]="'calendarEndDate'"
              [position]="'absolute'"
              [minDateValidation]="startDate?.value === '' || startDate?.value == null ? initialDate : startDate?.value"
            ></app-picker-calendar>
              <div [ngClass]="{'hidden': end_date?.pristine}">
                <p
                  class="text-sm text-customOrange-default"
                  [ngClass]="{ 'hidden': !end_date?.errors?.required }"
                >
                  {{ i18n.grades_start_end_required }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label class="mb-1 block text-customBlue-default font-medium">{{
            i18n.grades_calculation_method
          }}</label>
          <select
            class="w-full border border-customGray-grayTwo px-2 py-2.5 rounded-sm text-md bg-customWhite-default"
            formControlName="calculation_type"
            id="calculation_type"
            required
          >
            <option value="sum">{{ i18n.grades_sum }}</option>
            <option value="weighted_average">
              {{ i18n.grades_weighted_average }}
            </option>
          </select>
          <div [ngClass]="{'hidden': calculation_type?.pristine}">
            <p
              class="text-sm text-customOrange-default"
              [ngClass]="{ 'hidden': !calculation_type?.errors?.required }"
            >
              {{ i18n.grades_calculation_method_required }}
            </p>
          </div>
        </div>
        <div class="flex gap-2 justify-center py-4">
          <button
            class="flex items-center justify-center rounded-md py-2 px-8 font-bold text-sm text-customWhite-default bg-customBlue-default"
            [ngClass]="{
              'disabled:opacity-20 cursor-not-allowed': !periodsForm.valid
            }"
            (click)="saveGeneralSettings()"
            [disabled]="!periodsForm.valid"
          >
            <span *ngIf="!controlButtonPeriodText">{{ i18n.grades_save }}</span>
            <span *ngIf="controlButtonPeriodText">{{ i18n.disciplines_continue }}</span>
          </button>
          <button
            class="flex items-center justify-center rounded-md py-2 px-6 font-bold text-customGray-textGray background-transparent font-semibold outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            (click)="closeModal()"
          >
            {{ i18n.grades_cancel }}
          </button>
        </div>
      </div>
    </form>
  </div>
</app-generic-modal>

<app-grades-categories-modal 
    [controlButtonCategory]="false"
    [discipline_external_id]="discipline_external_id" 
    [isModalOpen]="isModalCategoriesOpen" 
    (sendIsModalOpen)="getStatusCategoriesModal($event)"
    >
</app-grades-categories-modal>