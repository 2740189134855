import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/pages/login/login.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-modal-reset-validation-code',
  templateUrl: './modal-reset-validation-code.component.html',
  styleUrls: ['./modal-reset-validation-code.component.scss']
})
export class ModalResetValidationCodeComponent implements OnInit, OnDestroy {

  validationCodeForm: FormGroup;
  destroyOpenModal$: any;
  destroyValidationCode$: any;
  isVisible: boolean = false;
  isValidationCodeButtonEnabled: boolean = false;
  i18n: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    public platformModalService: PlatformModalsService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.validationForm();
    this.destroyOpenModal$ = this.loginService.openModalValidationCode$
    .subscribe({
      next: (response)=>{
        if (response === true) {
          this.isVisible = true;
        }
      }
    })

    this.destroyValidationCode$ = this.loginService.enableSendValidationCodeButton$
    .subscribe({
      next: (response)=>{
        if (response === true) {
         this.isValidationCodeButtonEnabled = true; 
        }else{
         this.isValidationCodeButtonEnabled = false; 
        }
      }
    })
  }

  getTranslations() {
    this.i18n = {
      ...this.sharedService.getTranslationsOf('Errors'),
      ...this.sharedService.getTranslationsOf('Login'),
    };
  }


  validationForm(){
    this.validationCodeForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.maxLength(6)]]
    })
  }

  get code(){
    return this.validationCodeForm.get('code');
  }

  closeModal(){
    this.isVisible = false;
    this.loginService.openModalValidationCode$.next(false);
  }


  resetPasswordAgain(){
    let e =  localStorage.getItem('email') ? JSON.parse(localStorage.getItem('email') || '') : '';
    
    const params = {
      "email" : e
    }
    this.loginService.resetPassword(params)
    .subscribe({
      next: (response)=>{
        this.loginService.restartCountdownValidatonCode$.next(true);
        this.loginService.enableSendValidationCodeButton$.next(false);

      },
      error: (err)=>{
        console.log(err);
      }
    })
  }
  
  validationToken(){
    let t = this.validationCodeForm.controls.code.value
    let data: any =  localStorage.getItem('email')
    let e = JSON.parse(data)
    const params = {
      "token" : t,
      "email": e
    }
    this.loginService.validationToken(params)
    .subscribe({
      next: (response)=>{
          localStorage.setItem('codeValidation', t);
          this.router.navigate(['reset'])
        },
        error: (err)=>{
          console.log('err ',err);
          let message = 'Erro de sistema, por favor contacte um administador';

          if (err.status == 422) {
            switch (err.error) {
              case 'User Token not found':
                message = 'Código não encontrado';
                break;

              case 'User Token expired':
                message = 'Código já está expirado';
                break;
            
              default:
                break;
            }
          }

          this.platformModalService.toggle('message', message, 'close');
        }
      })

  }

  ngOnDestroy() {
    if (this.destroyOpenModal$) this.destroyOpenModal$.unsubscribe();
    if (this.destroyValidationCode$) this.destroyValidationCode$.unsubscribe();
  }

}
