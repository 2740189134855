<div class="w-full">
  <div #timePickerRef class="input-container">
    <div class="flex">
      <button (click)="enableCalendar = !enableCalendar" class="inputDate">
      </button>
      <input
        [inputMask]="dateInputMask"
        [value]="initialDate"
        type="text" 
        class="input-contents"
        #inputTime
        (click)="enableCalendar = true"
        (keyup)="handleEditTime(inputTime.value)"
      >
    </div>

    <div
      [ngClass]="{ visbile: enableCalendar, hidden: !enableCalendar }"
      class="relative w-full calendar-container cursor-pointer"
    >
      <div class="w-full" [style.position]="position">
        <div class="relative">
          <div
            (click)="enableCalendar = false"
            style="
              position: absolute;
              right: 5px;
              z-index: 100;
              height: 0;
              top: 8px;
            "
          >
            <img src="../../../assets/svg/certificate/close.svg" alt="close" />
          </div>
          <p-calendar
            #calendarTime
            [dateFormat]="'HH:mm'"
            [showTime]="false"
            [timeOnly]="true"
            [inline]="true"
            [(ngModel)]="initialDate"
            [minDate]="minDateValidation"
            [readonlyInput]="false"
            [styleClass]="'calendarStyle'"
            [placeholder]="'HH:mm'"
            (onSelect)="
              fnPickDateToInput(calendarTime.value)
            "
          >
          </p-calendar>
        </div>
      </div>
    </div>
  </div>
</div>
