<div class="view-checkbox">
    <div
        class="alternative"
        [class.is-view-only]="viewOnly"
        *ngFor="let alternative of data.options; let index = index"
        [ngClass]="{
            'is-correct': alternative.correction === 1,
            'is-wrong': alternative.correction === 0
        }"
    >
        <app-check-button
            [ngClass]="{ 'pointer-events-none': viewOnly }"
            (change)="toggleAlternative(alternative)"
            [iconSize]="25"
            class="alternative__ball"
            [active]="alternative[optionKey]"
        ></app-check-button>

        <div
            [innerHTML]="alternativesText[index]"
            class="text-customGray-dark"
        ></div>
    </div>
</div>
