<div class="w-full">
  <form [formGroup]="conditionalForm">
    <ng-container *ngIf="conditonalAdvanceOption">
      
      <div class="flex items-center gap-2 mb-1">
        <input
          formControlName="conditionalAdvance"
          class="checkbox_assignment"
          type="checkbox"
        />
        <div class="label-content">
          {{ i18n.adv_and_crit_conditional_advance }}
        </div>
      </div>
      <div [@collapse]="conditionalAdvance">
        <select 
          class="select-criteria input-contents"
          style="background-color: transparent" 
          formControlName="conditionalAdvanceSelect"
        >
          <option value="" disabled selected>
            {{ i18n.adv_and_crit_select }}
          </option>
  
          <option *ngFor="let content of filteredSelect" [value]="content.id">
            {{ textCutAnnouncements(27, content.title) }}
          </option>
        </select>
      </div>
    </ng-container>
  </form>
</div>
