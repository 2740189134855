import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-content-view-content-list-skeleton',
  templateUrl: './content-view-content-list-skeleton.component.html',
  styleUrls: ['./content-view-content-list-skeleton.component.scss']
})
export class ContentViewContentListSkeletonComponent implements OnInit {
  @Input() expanded: boolean;

  contents: any = [{},{},{},{}]
  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    this.contents.forEach((element, index) => {
      element['width_description'+index] = this.sharedService.generateRandomNumber(80,85);
    });
  }

}
