import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'adm-shortcut-card',
  template: ` 
        <div class="shortcuts-wrapper" (click)="onRedirect(typeShortcut)">
            <img src="{{iconShortcut}}" alt="">
            <div class="shortcut-col__label">
                   {{labelShortcut}}
            </div>
        </div>
 `,
  styleUrls: ['./adm-styles.scss'],
})
export class AdmShortcutCardComponent implements OnInit {

  @Input() typeShortcut: string
  @Input() i18n: any = [];
  labelShortcut: string
  iconShortcut: string

  constructor(
    private dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
    this.chooseShortcuts(this.typeShortcut)
  }
 
  chooseShortcuts(typeShortcut: any) {
    switch (typeShortcut) {
      case 'addUser':
        this.labelShortcut = this.i18n.dash_register_users
        this.iconShortcut = '../../../../../../assets/svg/user-add.svg'
          break;
      case 'addDiscipline':
        this.labelShortcut = this.i18n.dash_create_class
        this.iconShortcut = '../../../../../../assets/svg/graduation-hat.svg'
          break;
      case 'configEnvironment':
        this.labelShortcut = this.i18n.dash_configure_environment
        this.iconShortcut = '../../../../../../assets/svg/gear-config.svg'
          break;
    }
  }

  onRedirect(typeShortcut: any){
    switch (typeShortcut) {
      case 'addUser':
        this.dashboardService.toUsers();
          break;
      case 'addDiscipline':
        this.dashboardService.toDisciplines();
          break;
      case 'configEnvironment':
        this.dashboardService.toSettings();
          break;
    }
  }

}
