import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MessengerToastService {
    isShowToast$: Subject<boolean> = new Subject();
    toastMsg$: BehaviorSubject<any> = new BehaviorSubject<any>('');
    toastType$: BehaviorSubject<any> = new BehaviorSubject<any>('');
    msgBtn$: BehaviorSubject<any> = new BehaviorSubject<any>('');
    urlAction$: BehaviorSubject<any> = new BehaviorSubject<any>('');

    showToastControl(isShowToast: boolean): void {
        this.isShowToast$.next(isShowToast);
    }

    toastMsg(msg: string): void {
        this.toastMsg$.next(msg);
    }

    toastType(toastType: string): void {
        this.toastType$.next(toastType);
    }

    msgBtn(msgBtn?: string): void {
        this.msgBtn$.next(msgBtn);
    }

    urlAction(urlAction?: string): void {
        this.urlAction$.next(urlAction);
    }

    messengerToast(toastType: string, msg: string, isShowToast: boolean, msgBtn?: string, urlAction?: string): void {
        this.showToastControl(isShowToast);
        this.toastMsg(msg);
        this.msgBtn(msgBtn);
        this.urlAction(urlAction);
        // Esse item deve ser mantido por último para funcionar todos os eventos (timer e clique)
        this.toastType(toastType);
    }
}
