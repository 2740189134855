<!-- Modal Vinculate Discipline User -->
<app-generic-modal [isVisible]="isVisible">
    <div
      class="transition ease-out duration-700 animate-fade-in-down overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex transition duration-1000 ease-in-out"
    >
      <div
        class="mx-auto absolute bg-customWhite-default rounded-lg modal-vinculate-discipline"
      >
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <!--header-->
          <div
            class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t"
          >
            <div class="text-center">
              <h3 class="text-2xl font-semibold text-customBlue-dark">
                {{ i18n.users_vinculate_disciplines }}
              </h3>
              <p class="text-sm text-customBlue-dark">
                {{ i18n.users_vinculate_user_to_access_discipline }}
              </p>
            </div>
          </div>
          <!--body-->
          <div class="relative p-6">
            <!-- <div class="flex pb-2 gap-2" *ngIf="countRoles(user, 'both')"> -->
            <div class="flex pb-2 gap-2">
              <div *ngFor="let role of userVinculate.roles" class="w-full">
                <button                 
                  [disabled]="profileSelected == role.role_external_id"
                  [ngClass]="{'button-content-selected': profileSelected == role.role_external_id, 'disabled:opacity-20 cursor-not-allowed': isLoadingDisciplinesByProfile}"
                  (click)="changeProfile(userVinculate, role.role_external_id)"
                  class="w-full bg-feed font-bold text-sm py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  {{ role.role_name }}
                </button>
              </div>
              <!-- <div class="w-1/2">
                <button
                  [disabled]="profileSelected == 'student'"
                  [ngClass]="{
                    ' button-content-selected ': profileSelected == 'student'
                  }"
                  (click)="changeProfile(user, 'student')"
                  class="w-full text-sm py-2 bg-feed font-bold rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  {{ i18n.users_student }}
                </button>
              </div> -->
            </div>
            <div
              class="flex items-center w-full mb-6 rounded"
              style="border: 1px solid #a9aeb3"
            >
            <input
                class="p-1 rounded-full w-full outline-none"
                (keyup)="searchDisciplines()"
                placeholder="{{ i18n.users_find_disciplines }}"
                type="text"
                name="query"
                ngDefaultControl
                [(ngModel)]="filterDisciplines"
              />
              <div (click)="searchDisciplines()" class="mr-2">
                <span class="iconify w-6 h-6 text-customGray-default" data-icon="ri:search-line"></span>
              </div>
            </div>
            <div>
              <ng-container *ngIf="isLoadingDisciplinesByProfile">
                <div>
                  <i class="mx-auto loading-icon"></i>
                </div>
              </ng-container>
              <ng-container *ngIf="!isLoadingDisciplinesByProfile">
                <div
                  *ngFor="
                    let discipline of disciplines;
                    let i = index
                  "
                >
                  <div
                    class="flex w-full items-center justify-between mb-6 p-2 rounded ng-star-inserted"
                    style="border: 1px solid #a9aeb3"
                  >
                    <div>
                      <span
                        class="flex ml-2 font-bold items-center"
                        style="color: #233674"
                        >{{ discipline.discipline_name }}</span
                      >
                    </div>
                    <div>
                    <app-check-button 
                        (change)="touched(); changeVinculateDisciplinesUser(discipline)"
                        [iconSize]="21" 
                        [active]="getChecked(discipline) || discipline.enrolled"
                        [roundedValue]="5"
                        (isActive)="receiveCheckButton($event, discipline.discipline_ext_id)"
                    ></app-check-button>
                    </div>
                  </div>
                </div>
                <div *ngIf="disciplines?.length == 0">
                  <span class="text-md font-bold text-customBlue-default">
                    {{ i18n.users_no_disciplines }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="disciplines?.length">
                <div class="flex items-center justify-center">
                  <app-pagination
                    #pagination
                    class="pagination"
                    [params]="paginationParams"
                    (navigation)="goToPage($event)"
                  ></app-pagination>
                </div>
              </ng-container>
            </div>
          </div>
          <!--footer-->
          <div class="flex flex items-center justify-center pb-6">
            <div>
              <button
                class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                (click)="saveVinculateDisplinesUsers()"
                [ngClass]="{
                  'disabled:opacity-20 cursor-not-allowed':
                    this.arraySaveVinculateDiscipline.length == 0
                }"
                [disabled]="this.arraySaveVinculateDiscipline.length == 0"
              >
                {{ i18n.users_vinculate }}
              </button>
              <button
                class="text-customBlue-default background-transparent font-semibold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                (click)="closeModal()"
              >
                {{ i18n.users_cancel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</app-generic-modal>