import {
    Component, ElementRef, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';
import { GoogleChatService } from 'src/app/services/google-chat.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { RestoreFormattingService } from 'src/app/shared/restore-formatting.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
    @ViewChild('descriptionText') descriptionText!: ElementRef;
    disciplineDetail: any = [];
    showModal: boolean;
    isLoading = true;
    searchForm: UntypedFormGroup;
    i18n: any = [];
    currentLanguage: any;
    discipline_external_id: string;
    dataCertificate: any;
    isVisibleModalEmenta = false;
    isVisibleModalSend = false;
    modalSendType: 'mail' | 'chat' = 'mail';
    unsubscribe$: Subject<boolean> = new Subject();
    discipline_id: any;
    user: any = {};
    _userList: any[] = [];

    isTeacher = false;
    isAdmin = false;
    isNotStandalone = false;

    constructor(
        private router: Router,
        private disciplineDetailService: DisciplineDetailService,
        private FormBuilder: UntypedFormBuilder,
        private sharedService: SharedService,
        private route: ActivatedRoute,
        private restoreFormattingService: RestoreFormattingService,
        private googleChatService: GoogleChatService,
        private localStorageService: LocalStorageService
    ) { }

    ngOnInit(): void {
        this.isNotStandalone = this.localStorageService.isNotStandalone();
        this.getDisciplineId();
        this.getDisciplineDetail();
        this.createOverviewForm();
        this.getTranslations();
    }

    ngOnDestroy() {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    getUser() {
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;

            if (this.user?.role_type === 'teacher') {
                this.isTeacher = true;
            }

            if (this.user?.role_type === 'admin') {
                this.isAdmin = true;
            }
        });
    }

    overviewIsLoading() {
        this.isLoading = true;
    }

    overviewIsNotLoading() {
        this.isLoading = false;
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getDisciplineId() {
        this.discipline_external_id = this.router?.url.split('/')[2];
    }

    students: any = [];
    teachers: any = [];
    getDisciplineDetail() {
        this.overviewIsLoading();
        this.disciplineDetailService
            .disciplineBasic$
            .subscribe((discipline: any) => {
                this.getUser();

                this.disciplineDetail = { ...discipline };
                if (this.disciplineDetail.participants?.student?.length) {
                    this.students = [...this.disciplineDetail.participants?.student];
                    this.orderByName(this.students);
                }

                if (this.disciplineDetail.participants?.teacher?.length) {
                    this.teachers = [...this.disciplineDetail.participants?.teacher];
                    this.orderByName(this.teachers);
                }

                this._userList = [...this.teachers, ...this.students];

                setTimeout(() => {
                    if (this.disciplineDetail?.description) {
                        this.restoreFormattingService.restoreFormatting(this.descriptionText);
                    }
                }, 20);

                if (Object.keys(discipline).length !== 0) {
                    this.overviewIsNotLoading();
                }
            });
    }

    orderByName(array: []) {
        return array.sort((a: any, b: any) => (a.name < b.name ? -1 : 1));
    }

    emitCertificate(certificate: any) {
        this.getCertificate(certificate);
    }

    getCertificate(emited_id: any) {
        this.disciplineDetailService
            .getEmitedCertificates(emited_id)
            .subscribe((blob: Blob): void => {
                const file = new Blob([blob], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank', 'width=800, height=600');
            });
        (err) => console.log('ERROR', err);
    }

    // Forms pronto pra outra versão
    createOverviewForm() {
        this.searchForm = this.FormBuilder.group({
            text: ['', [Validators.required]],
            nivelEnsino: ['', [Validators.required]],
            modalidades: ['', [Validators.required]],
            codTurma: ['', [Validators.required]],
            nomeTurma: ['', [Validators.required]],
            cursoFilter: ['', [Validators.required]],
            nivelFilter: ['', [Validators.required]],
            modalidadeFilter: ['', [Validators.required]],
            anosFilter: ['', [Validators.required]],
            mesesFilter: ['', [Validators.required]],
        });
    }

    closeModal() {
        this.showModal = false;
    }

    openModal() {
        this.searchForm.controls.codTurma.setValue('');
        this.searchForm.controls.nomeTurma.setValue('');
        this.searchForm.controls.nivelEnsino.setValue('');
        this.searchForm.controls.modalidades.setValue('');
        this.showModal = !this.showModal;
    }

    toggleModalEmenta(): void {
        this.isVisibleModalEmenta = !this.isVisibleModalEmenta;
    }

    toggleModalSend(type: 'mail' | 'chat'): void {
        this.isVisibleModalSend = !this.isVisibleModalSend;
        this.modalSendType = type;
    }

    openEmailBox(email: string): void {
        const w = 650;
        const h = 600;
        const l = Number(screen.width / 2 - w / 2);
        const t = Number(screen.height / 3 - h / 3);

        const features = `location=yes, scrollbars=yes, status=yes, resizable=no, width=${w}, height=${h}, top=${t}, left=${l}`;
        const url = `https://mail.google.com/mail/?view=cm&fs=1&su=&body=&bcc=${email}&authuser=${this.user?.email}`;

        window.open(url, '_blank', features);
    }

    openChat(email: string): void {
        this.googleChatService.requestChatScope([email]);
    }

    receiveNewDescription(event: Event): void {
        this.disciplineDetail.description = event;
        setTimeout(() => {
            if (this.disciplineDetail?.description) {
                this.restoreFormattingService.restoreFormatting(this.descriptionText);
            }
        }, 20);
    }
}
