import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-submissions-general-quiz-review',
  templateUrl: './submissions-general-quiz-review.component.html',
  styleUrls: ['./submissions-general-quiz-review.component.scss']
})
export class SubmissionsGeneralQuizReviewComponent implements OnInit, OnDestroy {
  @Input() breakpoint_X: string;
  breakpointObserverKey: any;

  constructor(private element: ElementRef,private sharedService: SharedService) {};

  ngOnInit(): void {
    this.breakpoint_X && this.getMediaQuerySubscription();
  }

  getMediaQuerySubscription () {
    this.breakpointObserverKey = this.sharedService.callFunctionsOnBreakpoint(this.breakpoint_X, {
      element: this.element,
      queryString: '.submissions-resume-alternatives'
    })
  }

  ngOnDestroy () {
    this.sharedService.removeBreakpointObserver(this.breakpointObserverKey);
  }
}
