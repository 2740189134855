import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../../../grades.service';

@Component({
  selector: 'app-grades-categories-modal',
  templateUrl: './grades-categories-modal.component.html',
  styleUrls: ['./grades-categories-modal.component.scss']
})
export class GradesCategoriesModalComponent implements OnInit {

  @Input() isModalOpen: boolean;
  @Input() discipline_external_id: boolean;
  @Input() controlButtonCategory: boolean = false;
  // @Input() period: any;
  @Output() sendIsModalOpen = new EventEmitter();

  categoriesForm: UntypedFormGroup;
  editMode: boolean = false;
  period: any;
  i18n: any = [];

  constructor(
    private gradesService: GradesService,
    private sharedSerivce: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.gradesService.categories$.subscribe((period)=>{
      if (period !== null) {
        this.period = period
      }
    })
  }

  getTranslations(){
    this.i18n = this.sharedSerivce.getTranslationsOf('Grades')
  }  

  closeModal(event: Event){
    this.isModalOpen = false;
    this.editMode = false;
    this.sendIsModalOpen.emit({ event })
  }
}
