import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CertificatesSharedService } from 'src/app/shared/certificates-shared.service';

@Component({
  selector: 'app-resources-select',
  templateUrl: './resources-select.component.html',
  styleUrls: ['./resources-select.component.scss']
})
export class ResourcesSelectComponent implements OnInit {

  modalResourcesCertificate$ = this.certificatesService.modalResourcesCertificate$;

  resourcesForm: UntypedFormGroup;
  processedRessources: any;
  checked:  any;
  contents: any = []
  //newArray: any = []
  arrayExternalId: any = []
  checkedTeste:    any
  formatedArray:   any = []
  term: any;

  sectionsCopy: any = []

  @Input() contentsCertificate: any = []
  @Input() sections: any;
  @Input() i18n: any;

  @Output() emittingPatch = new EventEmitter();


  constructor(
    private certificatesService: CertificatesSharedService,
    private formBuilder: UntypedFormBuilder
  ) { }


  ngOnInit(): void {
    this.formatInit();
    this.resourcesSelectBuilder();
    console.log('Sections Open: ', this.sections)
  }


  ngOnChanges(changes: SimpleChanges) {
    console.log('On Changes contents', changes);
  }


  closeResourcesCertificate() {
    this.certificatesService.modalCloseResourcesCertificate();
  }
  openResourcesCertificate() {
    this.certificatesService.modalOpenResourcesCertificate();
  }


  formatInit() {
    this.formatResources();
    //this.compareId(this.contents);
    this.sections.forEach(section => {
      section.contentParsed = this.compareId(section.contents);
    });
  }

  formatResources() {
    this.sections?.forEach(element => {

      for (let index = 0; index < element.contents.length; index++) {
        const item = element.contents[index];
        console.log('item ', item);

        if (this.contentsCertificate.includes(item.external_id)) {
          item['checked'] = true;
        }else{
          item['checked'] = false;
        }

      }
    });

    this.arrayExternalId = this.contents.map(content => content.external_id);

    console.log('ContentCertificate:', this.contentsCertificate)
    console.log('sectionsCopy: ', this.sectionsCopy);
    console.log('ExtarnalID : ', this.arrayExternalId)
  }


  resourcesSelectBuilder() {
    this.resourcesForm = this.formBuilder.group({
      select: [''],
    });
     this.resourcesForm.valueChanges.subscribe((val) => {
      this.checked = this.resourcesForm.value.checked;
      console.log('Resources Builder: ', val, this.checked)
    })
  }


  compareId(contents) {
  const newArray = contents.map(element => ({
    title: element.title,
    fileType: element.type,
    checked: this.contentsCertificate.includes(element.external_id),
    external_id: element.external_id
    })
    );
    console.log('IDs do certificado:', this.contentsCertificate)

    return newArray;
  }


  onChecked(sectionIndex, index) {
    //this.newArray[index].checked = !this.newArray[index].checked
    this.sections[sectionIndex].contentParsed[index].checked = !this.sections[sectionIndex].contentParsed[index].checked
    console.log('index: ', this.sections[sectionIndex].contentParsed)
  }

  onSave() {
    this.sections.forEach(section => {
      this.formatedArray = section.contentParsed.map((data) => {
        return {external_id: data.external_id, is_checked: data.checked};
      });
      console.log('this.formatedArray ', this.formatedArray);

      this.formatedArray = this.formatedArray.filter((data) => data);
      //this.emittingPatch.emit(this.formatedArray);
      console.log('ON SAVE', this.formatedArray)
    }
    );
    this.emittingPatch.emit(this.formatedArray);
    /*
    this.formatedArray = this.newArray.map((data) => {
      console.dir(data);
      if(data.checked) {
        return data.external_id;
      }
    });
    console.dir("BEFORE FILTER", this.formatedArray);
    this.formatedArray = this.formatedArray.filter((data) => data);
    this.emittingPatch.emit(this.formatedArray);
    console.log('ON SAVE', this.formatedArray)
    */

  }

  hasFilter(value:boolean) {
    console.log(value);
    if(value) {
      const ds = document.getElementsByTagName("details");
      for(let i = 0; i < ds.length; i++) {
        ds[i].setAttribute("open", "true");
      }
    }
  }

}
