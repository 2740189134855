import { NgModule } from '@angular/core';
import { PermissionModule } from 'src/app/directives/permission/permission.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateButtonComponent } from './translate-button.component';



@NgModule({
  declarations: [TranslateButtonComponent],
  exports:[TranslateButtonComponent],
  imports: [
    SharedModule,
    PermissionModule,
  ]
})
export class TranslateButtonModule { }
