<ng-container *appCheckPermissions="'frequency';type:'create'">

    <button class="floating_btn" (click)="toggleSidebar()">
        <div></div>
    </button>

  <div class="sidebar_calling_roll py-5" [ngClass]="{ open: isOpen, close: !isOpen }">
    <div class="title_sidebar">
        <div class="text-customBlue-default font-bold font-Montserrat">
            <span>{{ titleDiary }}</span>
        </div>
        <div class="text-customBlue-default">
            <span>{{ discipline_name }}</span>
        </div>
    </div>

    <div class="close-btn-icon" (click)="handleCloseSidebar()">
        <span class="iconify w-5 h-5 text-customGray-dark" data-icon="ion:close-circle-outline" ></span>
    </div>

    <div 
        class="container_roll_scroll pr-2"
        style="max-height: calc(100vh - 180px);"
    >
        <div>
            <div class="mt-2 mb-4">
                <span class="label_date">{{ i18n?.date_lesson }}</span>
                <div class="mr-8">
                    <app-calendar-picker
                    [currentLanguage]="currentLanguage"
                    [initialDate]="initialDate"
                    [getFrequencyCalendary]="getFrequencyCalendary.bind(this)"
                    [frequencyCalendary]="frequencyCalendary"
                    (emitEvent)="handleChangeDate($event)"
                    ></app-calendar-picker>
                </div>
                <div class="flex gap-4 items-center justify-between mt-1 mr-8">
                    <div>
                    <span class="label_date">{{ i18n?.start_time }}</span>
                    <div class="container_time_input">
                        <app-time-picker
                        [initialDate]="startTime"
                        (emitEvent)="handleChangeStartTime($event)"
                        ></app-time-picker>
                    </div>
                    </div>
                    <div>
                    <span class="label_date">{{ i18n?.end_time }}</span>
                    <div class="container_time_input">
                        <app-time-picker
                        [initialDate]="endTime"
                        (emitEvent)="handleChangeEndTime($event)"
                        ></app-time-picker>
                    </div>
                    </div>
                </div>

                <div class="mr-8 mt-2">
                    <span class="label_topics">{{ i18n?.topics_title }}</span>
                    <textarea
                    #topic
                    class="textarea_topics border border-customGray-mediumGray container_roll_scroll"
                    rows="1"
                    maxlength="1024"
                    placeholder=""
                    [value]="topicDiary"
                    (keyup)="handleChangeTopics(topic.value)"
                    (input)="adjustTextareaHeight($event)"
                    ></textarea>
                </div>
            </div>
        </div>
      
        <div class="container_students">
            <div class="header_table_students mr-3">
                <div class="student_box title_table">{{ i18n?.students }}</div>
                <div class="title_table label_fixed_width">{{ i18n?.presence_title }}</div>
                <div class="title_table label_fixed_width ml-2">{{ i18n?.obs_title }}</div>
            </div>
    
            <div *ngIf="loadingStudents" style="height: 40vh" class="flex items-center justify-center">
                <app-bullets-loader></app-bullets-loader>
            </div>
    
            <div *ngIf="!loadingStudents">
                <div class="student_card border border-customGray-lighterGray rounded-md p-2 mb-2" *ngFor="let student of students; let index">
                    <div class="student_box">
                        <img
                        [src]="student?.image || student?.user_image || 'assets/png/default-avatar.png'"
                        class="user_image"
                        alt="user"
                        onerror="this.src='assets/png/default-avatar.png'"
                        />
                        <span class="student_name">{{ student?.name || student?.user_name }}</span>
                    </div>
                    <div class="prensece_box flex items-center justify-center">
                        <app-select-status
                        [onChangeStatus]="onStatusChange.bind(this)"
                        [userExternalId]="student?.external_id || student?.user_external_id || ''"
                        [status]="student?.presence || 'presence'"
                        ></app-select-status>
                    </div>
                    <div class="obs_box flex items-center justify-center">
                        <div class="btn-animate"
                        alt="edit"
                        (click)="handleSelectStudent(student)"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom_side_bar">
            <div class="container_statistics flex items-center justify-between mt-1 border border-customBlue-glass rounded-md">
                <div class="flex flex-col items-center justify-center text-customGray-dark" style="width: 100px;">
                    <div class="font-bold text-14">{{ i18n?.presence_label }}</div>
                    <div class="flex items-center justify-center">
                        <div class="icon-present"></div>
                        <div class="text-customGray-swampGray">{{ this.calcPercentByStatus("presence") }}</div>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center text-customGray-dark" style="width: 120px;">
                    <div class="font-bold text-14">{{ i18n?.half_presence_label }}</div>
                    <div class="flex items-center justify-center">
                        <div class="icon-neutral"></div>
                        <div class="text-customGray-swampGray">{{ this.calcPercentByStatus("1/2_presence") }}</div>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center text-customGray-dark" style="width: 100px;">
                    <div class="font-bold text-14">{{ i18n?.absence_label }}</div>
                    <div class="flex items-center justify-center">
                        <div class="icon-absent"></div>
                        <div class="text-customGray-swampGray">{{ this.calcPercentByStatus("absent") }}</div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <div class="flex justify-center items-end mt-1">
        <div class="flex gap-4">
            <div
            (click)="!loadingStudents && handleSaveFrequency('finished')"
            class="btn_save flex items-center justify-center btn-animate rounded-md"
            >
                <span>{{ i18n?.finish_btn }}</span>
            </div>
            <div
            (click)="handleCloseSidebar()"
            class="btn_close flex items-center justify-center btn-animate border border-customBlue-default rounded-md"
            >
                <span>{{ i18n?.close_btn }}</span>
            </div>
        </div>
    </div>
  </div>

  <app-modal-confirmation
    *ngIf="modalConfirmation$ | async"
  ></app-modal-confirmation>

  <app-modal-form-add
    [showModal]="showModalHistory"
    [cancelForm]="toggleModalHistory.bind(this)"
    [titleModal]="i18n?.history_modal_frequencies + ' - ' + dateClass"
    [subtitleModal]="''"
  >
    <div class="pl-6 pr-6 pt-2">
      <div class="history_label mb-2">{{ i18n?.history_modal_subtitle }}</div>
      <div
        class="flex items-center justify-between mb-2 text-customGray-dark"
        *ngFor="let history of histories"
      >
        <div>{{getHistoryFormatedTime(history.start_class)}} - {{getHistoryFormatedTime(history.end_class)}} - {{ getHistoryStatusName(history.status)}}</div>
        <div (click)="handleGetOneHistory(history.id)" class="btn_edit_frequency btn-animate">
          {{ i18n?.history_modal_edit }}
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center mt-5">
      <div
        (click)="handleInitiNewFrequency()"
        class="btn_new_frequency flex items-center justify-center btn-animate rounded-md"
        style="height: 37px"
      >
        <span>{{ i18n?.history_modal_save }}</span>
      </div>
      <div
        (click)="toggleModalHistory()"
        class="btn_close_history flex items-center justify-center btn-animate rounded-md"
        style="height: 37px"
      >
        <span>{{ i18n?.history_modal_cancel }}</span>
      </div>
    </div>
  </app-modal-form-add>

  <app-modal-form-add
    [showModal]="showModalObservation"
    [cancelForm]="toggleModalObservation.bind(this)"
    [titleModal]="i18n?.obs_modal_title"
    [subtitleModal]="i18n?.obs_modal_subtitle"
  >
    <div class="p-6">
      <div class="student_box">
        <img
          [src]="selectedStudent?.image || selectedStudent?.user_image || 'assets/png/default-avatar.png'"
          class="user_image"
          alt="user"
        />
        <span class="student_name">{{ selectedStudent?.name || selectedStudent?.user_name }}</span>
      </div>
      <div class="mt-4">
        <span class="label_message mb-2">{{ i18n?.obs_modal_message }}</span>
        <textarea
          #observation
          class="textarea_topics"
          rows="5"
          maxlength="1024"
          placeholder=""
          [value]="selectedStudent?.observation || ''"
          (keyup)="handleInsertObservation(observation.value)"
        ></textarea>
      </div>
      <div class="flex items-center justify-center mt-5">
        <div
          (click)="handleSaveObservation()"
          class="btn_new_frequency flex items-center justify-center btn-animate rounded-md"
          style="height: 37px"
        >
          <span>{{ i18n?.obs_modal_save }}</span>
        </div>
        <div
          (click)="toggleModalObservation()"
          class="btn_close_history flex items-center justify-center btn-animate rounded-md"
          style="height: 37px"
        >
          <span>{{ i18n?.obs_modal_cancel }}</span>
        </div>
      </div>
    </div>
  </app-modal-form-add>
</ng-container>