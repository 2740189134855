import {
    Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output
} from '@angular/core';
import { initializeApp } from 'firebase/app';
import {
    getMessaging,
    getToken,
    isSupported,
    onMessage
} from 'firebase/messaging';
import { ToastrService } from 'ngx-toastr';
import {
    catchError,
    firstValueFrom,
    from,
    map,
    Observable,
    switchMap,
    take,
    throwError,
} from 'rxjs';
import { NotificationsService } from 'src/app/pages/notifications/notifications.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { PushNotificationService } from 'src/app/services/pushNotificationService/push-notification.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-push-notification',
    templateUrl: './push-notification.component.html',
    styleUrls: ['./push-notification.component.scss'],
})
export class PushNotificationComponent implements OnInit, OnDestroy {
    settings_firebase;
    messaging;
    notificationID = 0;
    user: any = {};
    i18n: any = {};
    notificationPermissionStatus = '';
    isTooltipOpen = false;
    isLoading = false;
    currentLanguage: string | null = '';

    @Output() sendNotificationBrowserStatus = new EventEmitter();

    constructor(
        private pushNotificationService: PushNotificationService,
        private platformModalsService: PlatformModalsService,
        private toastrService: ToastrService,
        private notificationsService: NotificationsService,
        private sharedService: SharedService,
        private elementRef: ElementRef,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        this.getTranslations();
        this.getBrowserInfo();
        this.getUser();
        this.getNotificationPermissionStatus();
        this.verifyRegistration();
        this.listenerClickOutsideTooltip();
    }

    getBrowserInfo() {
        const browser = navigator.userAgent;
        if (browser.includes('Firefox')) {
            return 'Mozilla Firefox';
        } if (browser.includes('SamsungBrowser')) {
            return 'Samsung Internet';
        } if (browser.includes('Opera') || browser.includes('OPR')) {
            return 'Opera';
        } if (browser.includes('Trident')) {
            return 'Microsoft Internet Explorer';
        } if (browser.includes('Edg')) {
            return 'Microsoft Edge';
        } if (browser.includes('Chrome')) {
            return 'Google Chrome';
        } if (browser.includes('Safari')) {
            return 'Apple Safari';
        }
        return 'Unknown';
    }

    async verifyRegistration() {
        const hasFirebaseMessagingRegistration = await this.hasFirebaseMessagingRegistration();

        if (
            (!hasFirebaseMessagingRegistration
            || !this.localStorageService.getLocalStorageItem('fcm_token'))
            && this.notificationPermissionStatus === 'granted'
        ) {
            this.requestPermissionNotifications();
        }

        if (this.notificationPermissionStatus === 'denied' && this.localStorageService.getLocalStorageItem('fcm_token')) {
            this.sharedService.deleteKeyLocalStorage('fcm_token');
        }
    }

    async hasFirebaseMessagingRegistration(): Promise<boolean> {
        const registrations = await navigator.serviceWorker?.getRegistrations();

        if (registrations.length) {
            const checkRegistration = registrations.some((registration) => registration.scope.includes('api/firebase/file/'));

            if (checkRegistration) {
                return true;
            }
        }

        return false;
    }

    getNotificationPermissionStatus() {
        if (Notification) {
            this.notificationPermissionStatus = Notification.permission;
        }

        this.sendNotificationBrowserStatus.emit(
            this.notificationPermissionStatus
        );
    }

    setManifestFirebase() {
        this.isLoading = true;
        return this.pushNotificationService.firebaseManifest().pipe(
            map((res) => {
                const link = document.createElement('link');
                const manifestString = JSON.stringify(res);
                link.rel = 'manifest';
                link.href = URL.createObjectURL(
                    new Blob([manifestString], { type: 'application/json' })
                );
                document?.getElementsByTagName('head')[0].appendChild(link);
                this.isLoading = false;
                return true;
            }),
            catchError((err) => {
                this.platformModalsService.toggle(
                    'message',
                    JSON.stringify(err.error),
                    'close'
                );
                this.isLoading = false;
                return throwError(() => err);
            })
        );
    }

    getFirebaseMessagingSettings() {
        this.isLoading = true;
        // checks if serviceWorker is available
        if (!navigator || !('serviceWorker' in navigator)) {
            throw new Error('ServiceWorker is not available');
        }
        return from(isSupported()).pipe(
            switchMap((supported: boolean) => {
                if (!supported) {
                    return throwError(
                        () => new Error('Firebase messaging is not supported')
                    );
                }
                return this.pushNotificationService.firebaseMessagingSeviceWorker();
            }),
            switchMap((res: any) => {
                if (Object.values(res)) {
                    this.settings_firebase = {
                        apiKey: res?.apiKey,
                        authDomain: res?.authDomain,
                        projectId: res?.projectId,
                        storageBucket: res?.storageBucket,
                        messagingSenderId: res?.messagingSenderId,
                        appId: res?.appId,
                        measurementId: res?.measurementId,
                        vapidKey: res?.vapidKey,
                    };

                    const app = initializeApp(this.settings_firebase);
                    this.messaging = getMessaging(app);
                    this.isLoading = false;

                    return from(Promise.resolve(this.settings_firebase));
                }
                return throwError(
                    () => new Error(
                        'Invalid response from firebaseMessagingSeviceWorker'
                    )
                );
            }),
            catchError((err) => {
                this.platformModalsService.toggle(
                    'message',
                    JSON.stringify(err),
                    'close'
                );
                return throwError(() => err);
            })
        );
    }

    url: string;
    setFirebaseRegistration(): Observable<boolean> {
        this.isLoading = true;
        const url = window.location.origin;
        return from(
            navigator.serviceWorker?.register(
                `${url}/api/firebase/file/firebase-messaging`
            )
        ).pipe(
            map((registration) => {
                this.isLoading = false;
                if (registration) {
                    this.messaging.swRegistration = registration;
                    return true; // Return true indicating registration success
                }
                throw new Error('Service worker registration failed');
            }),
            catchError((err) => {
                this.isLoading = false;
                throw new Error(
                    `Failed to register custom service worker: ${err}`
                );
            })
        );
    }

    getTokenFireBaseMessaging(vapidKey: string): Observable<any> {
        if (!vapidKey) {
            throw new Error('No vapidKey provided');
        }
        this.isLoading = true;
        return from(
            getToken(this.messaging, { vapidKey })
        ).pipe(
            map((currentToken) => {
                if (currentToken) {
                    return currentToken;
                }
                this.isLoading = false;
                throw new Error(
                    'No registration token available. Request permission to generate one.'
                );
            }),
            catchError((err) => {
                this.getNotificationPermissionStatus();
                this.platformModalsService.toggle(
                    'message',
                    this.getErrorMessageBlockNotifications(),
                    'close'
                );
                this.isLoading = false;
                throw new Error(`User blocked permission to Notify - ${err}`);
            })
        );
    }

    firebaseCreateDevice(token) {
        this.pushNotificationService.firebaseCreateDevice(token).subscribe({
            next: (result) => {
                localStorage.setItem('fcm_token', JSON.stringify(token.device));
                this.getNotificationPermissionStatus();
                this.isLoading = false;
                this.listen();
                this.toastrService.show(this.i18n.notifications_success_message, this.i18n.notifications_title, { progressBar: true }, 'toast-success');
            },
            error: (err) => {
                this.isLoading = false;
                this.platformModalsService.toggle(
                    'message',
                    JSON.stringify(err.error),
                    'close'
                );
            },
        });
    }

    listen(): void {
        onMessage(this.messaging, (payload) => {
            console.log('payload ', payload);

            if (this.isValidNotification(payload)) {
                const id = this.extractNotificationID(payload);
                const message = this.extractNotificationMessage(payload);

                this.handleNotification(id, message, payload);
            }
        });
    }

    extractNotificationID(payload): number {
        return payload.data.notification_id;
    }

    extractNotificationMessage(payload): string {
        return payload.notification.body.replace(/\[(\d+)\]/, '');
    }

    isValidNotification(payload): boolean {
        return (
            payload.notification
            && payload.notification.body
            && payload.notification.title
            && payload.data
        );
    }

    handleNotification(id: number, message: string, payload): void {
        if (id !== this.notificationID) {
            this.notificationsService
                .getNotification(id)
                .pipe(take(1))
                .subscribe({
                    next: (notification) => {
                        this.showNotification(notification, message, payload);
                    },
                });

            this.notificationID = id;
        }
    }

    showNotification(notification, message: string, payload): void {
        if (notification) {
            if (notification.role_type === this.user.role_type) {
                this.toastrService
                    .show(
                        message,
                        payload.notification?.title,
                        { disableTimeOut: true, closeButton: true },
                        'toast-info'
                    )
                    .onHidden.subscribe({
                        next: (res) => {
                            console.log('res ', res);

                            this.notificationsService.whatIsTheTypeRoute(
                                notification.notification_type,
                                notification.external_id
                            );
                        },
                    });
            } else {
                this.toastrService.show(
                    `${this.i18n.notifications_new_notification_on_profile}: ${notification.role_name}`,
                    payload.notification?.title,
                    {},
                    'toast-info'
                );
            }
        }
    }

    getUser(): void {
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;
        });
    }

    getTranslations(): void {
        this.i18n = this.sharedService.getTranslationsOf('Notifications');
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getErrorMessageBlockNotifications(): string {
        let linkToBlockMessageTutorial;
        switch (this.currentLanguage) {
            case 'en':
                linkToBlockMessageTutorial = 'https://suporte.inicie.digital/how-to-enable-browser-notifications/';
                break;
            case 'es':
                linkToBlockMessageTutorial = 'https://suporte.inicie.digital/permitir-recibir-notificaciones-del-navegador/';
                break;
            default:
                linkToBlockMessageTutorial = 'https://suporte.inicie.digital/docs/permitir-receber-notificacoes-do-navegador/';
                break;
        }
        return `${this.i18n.notifications_block_message} <br><br><a class="underline text-customBlue-royal" target="_blank" href="${linkToBlockMessageTutorial}">${this.i18n.notifications_learn_more}</a>`;
    }

    async checkServiceWorkerInstalling(): Promise<boolean> {
        const registrations = await navigator.serviceWorker?.getRegistrations();

        if (registrations.length) {
            const swFirebase = registrations.find(
                (registration) => registration.installing
            );

            if (swFirebase) {
                return new Promise((resolve) => {
                    swFirebase.installing?.addEventListener(
                        'statechange',
                        (event) => {
                            if (
                                (event.target as ServiceWorker).state
                                === 'installed'
                            ) {
                                resolve(true);
                            }
                        }
                    );
                });
            }
        }

        return false;
    }

    requestPermission() {
        if (Notification) {
            this.isLoading = true;
            Notification.requestPermission()
                .then((permission) => {
                    console.log('permission ', permission);
                    this.isLoading = false;
                    if (permission === 'granted') {
                        this.requestPermissionNotifications();
                        return;
                    }

                    if (permission === 'denied') {
                        this.getNotificationPermissionStatus();
                        this.platformModalsService.toggle(
                            'message',
                            this.getErrorMessageBlockNotifications(),
                            'close'
                        );
                    }
                })
                .catch((err) => {
                    this.getNotificationPermissionStatus();
                    this.platformModalsService.toggle(
                        'message',
                        this.getErrorMessageBlockNotifications(),
                        'close'
                    );
                    this.isLoading = false;
                    throw new Error(`User blocked permission to Notify - ${err}`);
                });
        }
    }

    async requestPermissionNotifications() {
        await firstValueFrom(this.setManifestFirebase());

        const firebaseSettings = await firstValueFrom(
            this.getFirebaseMessagingSettings()
        );

        const isPermissionGranted = await firstValueFrom(
            this.setFirebaseRegistration()
        );

        await this.checkServiceWorkerInstalling();
        if (firebaseSettings && isPermissionGranted) {
            const currentToken = await firstValueFrom(this.getTokenFireBaseMessaging(firebaseSettings?.vapidKey));

            if (currentToken) {
                const params = {
                    device: currentToken
                };

                this.firebaseCreateDevice(params);
            }
        }
    }

    openTooltip() {
        this.isTooltipOpen = true;
        setTimeout(() => {
            const tooltipElement = this.elementRef.nativeElement.querySelector('.tooltip');
            if (tooltipElement) {
                tooltipElement.classList.add('show');
            }
        });
    }

    closeTooltip() {
        const tooltipElement = this.elementRef.nativeElement.querySelector('.tooltip');
        if (tooltipElement) {
            tooltipElement.classList.remove('show');
        }
        setTimeout(() => {
            this.isTooltipOpen = false;
        }, 500);
    }

    listenerClickOutsideTooltip() {
        document.addEventListener('click', this.onClickOutside.bind(this), true);
    }

    onClickOutside(event: Event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.closeTooltip();
        }
    }

    ngOnDestroy(): void {
        document.removeEventListener('click', this.onClickOutside.bind(this), true);
    }
}
