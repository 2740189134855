<div class="max-h-screen overflow-x-hidden overflow-y-auto font-montserrat">
  <main
    [ngStyle]="{
      'background-image':
        'url(' + options?.customization?.login_background?.public_url + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center'
    }"
    class="h-full min-h-screen flex flex-col items-center justify-center p-4 relative"
  >
    <div class="md:absolute md:top-4 md:right-4" *ngIf="!loading && currentLanguage === 'pt-BR'">
        <app-accessibility-login [i18n]="i18n"></app-accessibility-login>
    </div>

    <app-third-party-cookie class="third-cookie" *ngIf="!loading"></app-third-party-cookie>

    <app-loading-balls-effect
      class="block py-6 px-10 rounded-2xl bg-customWhite-default"
      *ngIf="loading"
    ></app-loading-balls-effect>

    <section
      class="bg-customWhite-default rounded-3xl grid-template-columns xsm:w-full w-4/5 sm:w-8/12 md:w-5/6"
      *ngIf="!loading"
    >
      <div
        class="py-6 px-10 flex flex-col gap-6 items-center justify-center text-customGray-dark"
      >
        <img
          [src]="options?.customization?.main_logo?.public_url"
          class="logo"
          alt="Logo"
          onerror="this.src='../../../assets/png/empty_img.png'"
        />
        <span class="text-center">
          <h1 class="text-22 font-semibold">{{ i18n.login_welcome }}</h1>
          <p>{{ i18n.login_access_account }}</p>
        </span>
        <div class="w-full flex flex-col gap-6">
          <app-standalone-login
            *ngIf="allowedProviders.password"
            class="block"
          ></app-standalone-login>
          <div
            class="text-12 text-center"
            *ngIf="
              options?.authentication?.allowedProviders.length > 1 &&
              allowedProviders.password
            "
          >
            <hr class="text-customGray-mediumGray" />
            <p class="mt-2">{{ i18n.login_with }}</p>
          </div>
          <app-google-login
            *ngIf="allowedProviders.google"
            class="block mx-auto w-full max-width-form"
          ></app-google-login>
          <app-sso-login
            *ngIf="allowedProviders.sso"
            class="block mx-auto w-full max-width-form"
          ></app-sso-login>
          <app-microsoft-login
            *ngIf="allowedProviders.microsoft"
            class="block mx-auto w-full max-width-form"
          ></app-microsoft-login>
        </div>
        <app-help-links
          class="block"
          [support_email]="support_email"
        ></app-help-links>
        <app-use-terms></app-use-terms>
      </div>
      <div
        class="rounded-b-3xl md:rounded-l-none md:rounded-r-3xl login_image"
        [ngStyle]="{
          'background-image':
            'url(' + options?.customization?.login_image?.public_url + ')',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'background-position': 'center'
        }"
      >
        <!-- Background  -->
      </div>
    </section>
  </main>
</div>

<ng-container *ngIf="isTranslationsUploaded$ | async">
  <app-modal-reset-password></app-modal-reset-password>
  <app-modal-reset-validation-code></app-modal-reset-validation-code>
  <app-modal-use-terms></app-modal-use-terms>
  <app-modal-policy-and-privacy></app-modal-policy-and-privacy>
</ng-container>
