<app-generic-modal [isVisible]="isVisible">
  <div
    class="border-0 rounded-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
  >
    <!--header-->
    <div
      class="flex items-center justify-center border-b border-solid flex-col"
      style="
        padding: 32px 0;
        gap: 12px;
        font: 700 28px Roboto;
        color: var(--color-naval);
        border-color: var(--color-light-blue);
      "
    >
      <h3>{{ i18n.disciplines_add_content }}</h3>
      <div class="flex items-center justify-center text-center px-4 gap-2">
        <h6 style="font: 400 18px Roboto; color: var(--color-naval)">
          {{ i18n.disciplines_select_resource_add }}
        </h6>
        <button id="tooltip" class="w-5 h-5">
          <div class="interrogation_icon">
            <span
              class="iconify-inline text-19 text-customGray-default"
              data-icon="carbon:help"
            ></span>
          </div>
          <span id="tooltipText">
            <p>
              <b> {{ i18n.disciplines_content_drive_file }}</b>
              {{ i18n.disciplines_content_drive_file_txt }}
            </p>
            <p>
              <b> {{ i18n.disciplines_content_page_txt }}</b>
              {{ i18n.disciplines_content_page_txt_txt }}
            </p>
            <p>
              <b> {{ i18n.disciplines_content_assignment }}</b>
              {{ i18n.disciplines_content_assignment_txt }}
            </p>
            <p>
              <b> {{ i18n.disciplines_content_conference }}</b>
              {{ i18n.disciplines_content_conference_txt }}
            </p>
            <p>
              <b> {{ i18n.disciplines_content_LTI }}</b>
              {{ i18n.disciplines_content_LTI_txt }}
            </p>
            <p>
              <b> {{ i18n.disciplines_content_youtube }}</b>
              {{ i18n.disciplines_content_youtube_txt }}
            </p>
            <p>
              <b> {{ i18n.disciplines_content_URL }}</b>
              {{ i18n.disciplines_content_URL_txt }}
            </p>
            <p>
              <b> {{ i18n.disciplines_content_quiz }}</b>
              {{ i18n.disciplines_content_quiz_txt }}
            </p>
            <p>
              <b> {{ i18n.disciplines_upload_quiz_external }}</b>
              {{ i18n.disciplines_content_upload_quiz_txt }}
            </p>
            <p>
              <b> {{ i18n.disciplines_content_scorm }}</b>
              {{ i18n.disciplines_content_scorm_txt }}
            </p>
          </span>
        </button>
      </div>
    </div>

    <!--body-->
    <div class="relative p-6 scroller-y scroller-x">
      <div class="grid lg:grid-cols-2 md:grid-cols-1 gap-2">
        <button
          class="button-content flex m-2 rounded bg-feed transition-all ease-out"
          *ngFor="let contentType of contentTypes"
          (click)="selectContent(contentType?.type)"
          [class.is-selected]="selected === contentType.type"
        >
          <div class="mr-2">
            <app-types-icons [type]="contentType.type"></app-types-icons>
          </div>
          <div class="text-center" [style.width]="'10rem'">
            <span class="text-sm">{{ contentType.name }}</span>
          </div>
        </button>
      </div>
    </div>
    <!--footer-->
    <div class="decision-btns mb-6 lg:mt-0 center">
      <div class="btn backward" (click)="closeModal()">
        {{ i18n.disciplines_cancel }}
      </div>
      <div
        class="btn forward"
        [class.disabled]="!selected"
        (click)="addAssignment(); isVisible = !isVisible"
      >
        {{ i18n.disciplines_add }}
      </div>
    </div>
  </div>
</app-generic-modal>
