import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImportSpreadsheetService } from '../../import-spreadsheet.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-modal-confirmation',
    templateUrl: './modal-confirmation.component.html',
    styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit {
    @Input() i18n: any;
    @Input() messages: any;
    @Input() link: string | null = null;

    modalOpen = false;
    status: string;
    linkSheet: string | null = null;

    constructor(
        private importSpreadsheetService: ImportSpreadsheetService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.importSpreadsheetService.getModalConfirmState()
            .subscribe((state) => {
                this.modalOpen = state.isOpen;
                this.status = state.status;
                this.linkSheet = state.message ?? null;

                if (state?.message) {
                    this.messages = {
                        error: state.message
                    };
                }
            });
    }

    closeModal(): void {
        if (this.link && this.status !== 'generate') {
            this.router.navigate([this.link]);
        }

        this.importSpreadsheetService.closeConfirmModal();
    }
}
