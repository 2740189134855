<div 
    class="pl-4 pt-4 p-2 hoverComment " 
    [ngClass]="{'pb-4': thread?.deleted}"
>
    <!-- Header -->
    <div class="flex justify-between gap-2 items-center" [ngClass]="{'flex-wrap': hiddenSocialEvents }">
        <!-- author name, role name and status deletion -->
        <div class="flex items-center gap-2 ">
            <app-user-frame
                [style]="{'width':'33px', 'height':'33px', 'font-size': '15px', 'font-weight': 'bold'}"
                [img_src]="thread?.author?.photo || ''"
                [user_name]="thread?.author?.name || ''">
            </app-user-frame>
            <div class="lg:flex gap-2 text-center">
                <h3 
                    class="justify-center text-customGray-dark font-bold"
                    [ngClass]="{'forum__target--comment': userExternalID === thread?.author?.external_id}"
                    style="white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 250px;"
                >
                    {{thread?.author?.name}}
                </h3>
                <div 
                    *ngIf="thread?.author?.role_name === 'teacher'"
                    class="bg-customBlue-royal rounded-md italic p-1 px-4 text-sm text-customWhite-default" 
                >
                    {{i18n.forum_teacher}}
                </div>
            </div>
            <div class="flex text-center">
                <ng-container *ngIf="user?.role_type !== 'student' && thread?.deleted">
                    <span 
                        class="bg-customGray-mediumGray font-bold rounded-md p-1 lg:px-4 text-sm text-customGray-default"
                    >
                        {{i18n.forum_reply_removed}}
                    </span>
                </ng-container>
            </div>
        </div>
        <!-- End author name, role name and status deletion -->

        <!-- count replies and goToCommentReply -->
        <div class="lg:flex items-center gap-2 text-center">
            <ng-container 
                *ngIf="
                    (!thread?.deleted && user?.role?.external_id === 'student') 
                    || (thread?.deleted && user?.role?.external_id !== 'student')
                    || (!thread?.deleted && user?.role?.external_id !== 'student')
                "
            >
                <div 
                    *ngIf="thread?.replies.length" 
                    class="flex items-center gap-2 cursor-pointer"
                    (click)="openThread(thread)"
                >
                    <i class="comment-outlined-icon comment-icon"></i>
                    <div class="italic">
                        {{thread?.replies?.length}}
                        <ng-container *ngIf="thread?.replies?.length <= 1">
                            {{i18n.forum_reply}}
                        </ng-container>
                        <ng-container *ngIf="thread?.replies?.length > 1">
                            {{i18n.forum_replies}}
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <!-- End count replies and goToCommentReply -->
            
            <!-- Show ModalEditHistory button -->
            <ng-container *ngIf="thread?.can_view_history && thread?.has_history">
                
                <div class="flex gap-2">
                    <div class="text-customGray-grayThree text-sm font-bold cursor-pointer" (click)="openModalEditHistory(thread?.id)">
                        {{i18n.forum_see_edits}}
                    </div>
                </div>
            </ng-container>
            <!-- End Show ModalEditHistory button -->

            <!--  Posted Time -->
            <div>
                <span class="text-customGray-default text-sm">{{getTime(thread?.edited_at)}}</span>
            </div>
            <!-- End posted Time -->
        </div>
    </div>
    <!-- End Header -->

    <!-- Comment Text -->
    <div *ngIf="!(isReplyOpen === 'edit'+thread?.id && isEditMode)" class="flex py-4">
        <span class="text-customGray-grayThree">{{thread?.text}}</span>
    </div>
    <!-- End Comment Text -->
    
    <!-- Action Buttons -->
    <ng-container *ngIf="!thread?.deleted && isCanForumPost">
        <div 
            [ngClass]="{
            'lockVisible': isCommentAreaVisible === thread?.id, 
            'items-end sm:items-center': hiddenSocialEvents, 
            'items-center': !hiddenSocialEvents}"
            class="flex gap-2 justify-end text-customGray-default text-sm showSocialEvents">
            <div class="flex items-center gap-1" [ngClass]="{'flex-col sm:flex-row': hiddenSocialEvents}" 
            *ngIf="thread?.grade && thread?.grade?.enabled">
                <label>{{i18n.forum_evaluation}}</label>
                <i *ngIf="loadingGrade" class="loading-icon w-14 h-8"></i>
                <input 
                    *ngIf="!loadingGrade"
                    type="number"
                    [min]="0" 
                    [max]="thread?.grade?.max_grade"
                    [readOnly]="!thread?.grade?.user_edit"
                    [disabled]="!thread?.grade?.user_edit"
                    [(ngModel)]="threadGrade"
                    (focusout)="saveThreadGrade()"
                    (change)="changeThreadGrade()"
                    class="grade-input border border-solid border-customGray-grayTwo rounded-md text-center bg-customWhite-default w-14" 
                    />
            </div>
            <button 
                [ngClass]="{'selected': thread?.like, 'disabled:opacity-20 cursor-not-allowed': isWaitingResponse}" 
                [disabled]="isWaitingResponse" 
                (click)="clickReactionButton(thread?.like, thread?.id, 'like')" 
                class="flex items-center"
            >
                <i class="like-outline-icon icons_forum like-icon" style="margin-right: 3px;margin-bottom: 3px;"></i>
                <ng-container *ngIf="thread?.like">
                    <span [ngClass]="{'xs:hidden': !hiddenSocialEvents, 'hidden': hiddenSocialEvents}">{{thread?.likes}} {{i18n.forum_liked}}</span>
                </ng-container>
                <ng-container *ngIf="!thread?.like">
                    <span [ngClass]="{'xs:hidden': !hiddenSocialEvents, 'hidden': hiddenSocialEvents}">{{thread?.likes}} {{i18n.forum_like}}</span>
                </ng-container>
            </button>

            <!-- Desabilitado temporariamente o botão "seguir" porquanto as notificações foram desativadas -->
            <button
                *ngIf="false"
                [ngClass]="{'selected': thread?.follow, 'disabled:opacity-20 cursor-not-allowed': isWaitingResponse}" 
                [disabled]="isWaitingResponse" 
                (click)="clickReactionButton(thread?.follow, thread?.id, 'follow')" 
                class="flex items-center"
            >
                <i class="bell-outline-icon icons_forum" style="margin-right: 3px;margin-bottom: 2px;"></i>
                <ng-container *ngIf="thread?.follow">
                    <span [ngClass]="{'xs:hidden': !hiddenSocialEvents, 'hidden': hiddenSocialEvents}">{{i18n.forum_followed}}</span>
                </ng-container>
                <ng-container *ngIf="!thread?.follow">
                    <span [ngClass]="{'xs:hidden': !hiddenSocialEvents, 'hidden': hiddenSocialEvents}">{{i18n.forum_follow}}</span>
                </ng-container>
            </button>

            <ng-container *ngIf="!isLastLevel && forum_post?.logged_user_permissions?.can_reply">
                <button 
                    *appCheckPermissions="'forum';type:'create'" 
                    [ngClass]="{'selected-underline': isReplyOpen === 'reply'+thread?.id }" 
                    (click)="replyThread(thread?.id, 'reply')" 
                    class="flex items-center"
                >
                    <i class="reply-outline-icon icons_forum" style="margin-right: 3px;margin-bottom: 3px;"></i>
                    <span [ngClass]="{'xs:hidden': !hiddenSocialEvents, 'hidden': hiddenSocialEvents}">{{i18n.forum_reply_it}}</span>
                </button>
            </ng-container>

            <!-- Admin Action buttons -->
            <ng-container *appCheckPermissions="'forum';type:'edit'">
                <button 
                    *ngIf="(forum_post?.author?.owner || user?.role_type === 'admin') && forum_post?.logged_user_permissions?.can_reply" 
                    [ngClass]="{'selected-underline': isReplyOpen === 'edit'+thread?.id }" 
                    (click)="editForumPost(thread?.id)" 
                    class="flex items-center"
                >
                    <i class="pen-editing-icon icons_forum" style="margin-right: 3px;margin-bottom: 2px;"></i>
                    <span [ngClass]="{'xs:hidden': !hiddenSocialEvents, 'hidden': hiddenSocialEvents}">{{i18n.forum_edit}}</span>
                </button>
            </ng-container>
            <ng-container *appCheckPermissions="'forum';type:'delete'">
                <button 
                    *ngIf="(forum_post?.author?.owner || user?.role_type === 'admin') && forum_post?.logged_user_permissions?.can_reply" 
                    (click)="deleteForum(thread?.id)" 
                    class="flex items-center"
                >
                    <i class="trash-can-icon icons_forum" style="margin-right: 3px;margin-bottom: 3px;"></i>
                    <span [ngClass]="{'xs:hidden': !hiddenSocialEvents, 'hidden': hiddenSocialEvents}">{{i18n.forum_remove}}</span>
                </button>
            </ng-container>
            <!-- End Admin Action buttons -->

        </div>
    </ng-container>
    <!-- End Action Buttons -->
</div>

