import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-discipline-classroom-box',
  templateUrl: './select-discipline-classroom-box.component.html',
  styleUrls: ['./select-discipline-classroom-box.component.scss'],
})
export class SelectDisciplineClassroomBoxComponent {
  @Input() discipline: any;
  @Input() selectedDiscipline: any;
  @Input() modelForm: any; // Certifique-se de que modelForm seja uma propriedade de entrada

  @Output() formDisciplineData = new EventEmitter();

  isSelected() {
    return this.selectedDiscipline === this.discipline.external_id;
  }

  checkDisciplineChange() {
    if (!this.isSelected()) {
      this.formDisciplineData.emit(this.discipline.external_id);
    } else {
      this.formDisciplineData.emit(null);
    }
  }
}
