import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-create-text',
    templateUrl: './create-text.component.html',
    styleUrls: ['./create-text.component.scss'],
})
export class CreateTextComponent implements OnInit {
    @Input() viewOnly = true;
    @Input() data: any;
    @Output() changeText = new EventEmitter();

    previousText = '';
    i18n: any;

    constructor(private shared: SharedService) {}

    ngOnInit(): void {
        const translationModuleName = 'QuestionBank';
        this.shared.setI18n(this, translationModuleName);

        this.changeText.emit({
            ready: true,
            payload: {},
        });

        const lastOptionsIndex = this.data?.options?.length - 1;

        if (Array.isArray(this.data?.options)) {
            this.previousText = this.data.options[lastOptionsIndex]?.text;
        }
    }

    async writeOnData(e) {
        e.stopPropagation();
        const $input = e.currentTarget;

        const { value } = $input;
        const getResponse = await this.shared.debounce(value, 500);
        this.data.user_response = [[getResponse]];
        console.log('this.data.user_response ', this.data.user_response);

        this.changeText.emit();
    }

    sendData() {
        this.changeText.emit({
            ready: true,
            payload: {},
        });
    }
}
