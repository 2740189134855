<section
  class="px-8 my-8 py-8 shadow-card round-card transition ease-out duration-180 animate-fade-in-down bg-customWhite-default main-certificate"
>
  <div [ngClass]="{ 'cursor-not-allowed': isNotAllowed == true }">
    <div [ngClass]="{ 'certifica-not-allowed': isNotAllowed == true }">

      <h1
      class=" text-customBlue-default font-bold text-xl"
      >
        {{ i18n.disciplines_settings_certificates }}
      </h1>

      <div
        [ngClass]="{'hidden': isLoading }"
      >
        <div>
  
          <!-- Accordion Options -->
          <div
            class="options"
          >
            <details>
              <summary>
                {{ i18n.disciplines_settings_certificates_options }}
              </summary>
              <div class="content" [formGroup]="disciplineCertificateForm">
                <div class="flex justify-start mx-3 mt-4 my-3 ml-6">
                  <input
                    id="toogle"
                    style="height: 1.4rem"
                    (click)="changeButtonUnlockedTrue()"
                    formControlName="enabled"
                    class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                    type="checkbox"
                  />
                  <div class="ml-3">
                    {{
                      i18n.disciplines_settings_certificates_enable_certificates
                    }}
                  </div>
                </div>
                <!-- conteúdo de opções -->
                <div class="flex mx-3 my-3 ml-6">
                  <input
                    id="toogle"
                    style="height: 1.4rem"
                    formControlName="automatic"
                    (click)="changeButtonUnlockedTrue()"
                    class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                    type="checkbox"
                  />
                  <div class="ml-3">
                    {{
                      i18n.disciplines_settings_certificates_enable_automatic_generation
                    }}
                  </div>
                </div>
                <!--  <div class="flex mx-3 my-3 ml-6">
              <input
                id="toogle"
                style="height: 1.4rem"
                formControlName="grade"
                (click)="changeButtonUnlockedTrue()"
                class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                type="checkbox"
              />
              <div class="ml-3">Habilitar nota mínima</div>
            </div> -->
                <div class="flex mx-3 my-3 ml-6">
                  <input
                    id="toogle"
                    style="height: 1.4rem"
                    [checked]="collapsedCompletion"
                    (click)="toggleCompletion(); changeButtonUnlockedTrue()"
                    class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                    type="checkbox"
                  />
                  <div class="ml-3">
                    {{
                      i18n.disciplines_settings_certificates_enable_full_activities
                    }}
                  </div>
                </div>
                <!-- Content -->
                <div
                  [@collapse]="collapsedCompletion"
                  class="grid grid-cols-1  justify-items-center mx-3 mb-5 ml-2"
                >
                  <input
                    (onCurrencyEvent)="percentEventOnParent($event)"
                    (click)="clearFieldOfZero()"
                    appCustomPercent
                    maxlength="4"
                    min="0"
                    class="relative w-11 optionsRectangle"
                    formControlName="completion"
                    placeholder="055,55%"
                  />
                  <!-- <button
                    class="flex mx-3 my-3 ml-3 resourcesButton"
                    (click)="modalResourcesCertificateOpen()"
                  >
                    {{ i18n.disciplines_settings_certificates_select_resources }}
                  </button> -->
                </div>
                <section style="z-index: 3; background-color: white">
                  <div class="flex mx-3 -mt-3 ml-6">
                    <input
                      id="toogle"
                      style="height: 1.4rem"
                      [checked]="collapsedDate"
                      formControlName="start_date"
                      (click)="changeButtonUnlockedTrue()"
                      (click)="toggleStart_date()"
                      class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                      type="checkbox"
                    />
                    <div style="z-index: 3; background-color: white" class="ml-3">
                      {{
                        i18n.disciplines_settings_certificates_enable_issue_start
                      }}
                    </div>
                  </div>
                  <!-- Content -->
                  <div
                    [@collapse]="collapsedDate"
                    [ngClass]="{'my-3': collapsedDate}"
                    class="trasitionToggle mx-6"
                  >
                    <app-picker-calendar
                    [i18n]="i18n"
                    [currentLanguage]="currentLanguage"
                    [editMode]="true"
                    [initialDate]="initialDate"
                    [Form]="disciplineCertificateForm"
                    [inputNameControlPicker]="'start_date'"
                    [inputControlNameMask]="'calendarStartDate'"
                    [position]="'absolute'"
                    [minDateValidation]="startDate?.value === '' || startDate?.value == null ? initialDate : startDate?.value"
                  ></app-picker-calendar>
                  </div>
                </section>
              </div>
            </details>
          </div>
        </div>
  
        <h2 class="mt-14">
          {{ i18n.disciplines_settings_certificates_template }}
        </h2>
        <div
          [ngClass]="{
            'cursor-not-allowed': disciplineCertificateForm.value.enabled == false
          }"
        >
          <div
            [ngClass]="{
              'disbled-certificate':
                disciplineCertificateForm.value.enabled == false
            }"
          >
            <div class="scrollModalWrapper">
              <div class="modalBox">
                <div class="modalContent">
                  <div
                    class="mt-3"
                    *ngFor="let certificate of certificates.models"
                  >
                    <div class="rectangle">
                      <div class="img-rectangle">
                        <img
                          class="certificate-thumb"
                          [src]="certificate.thumbnail"
                        />
                      </div>
                      <div class="content-title">
                        <p title="{{certificate.name}}">{{textCut(42, certificate.name)}}</p>
                       <!--  <h3>{{ certificate.name | slice:0:20  }}</h3> -->
                      </div>
                      <div>
                        <img
                        *appCheckPermissions="'certificates_models';type:'edit'"
                          style="cursor: pointer"
                          (click)="modalEditCertificateOpen(certificate)"
                          class="pencil"
                        />
                        <img
                        *appCheckPermissions="'certificates_models';type:'delete'"
                          style="cursor: pointer"
                          (click)="
                            modalConfirmCertificateOpen(certificate.external_id)
                          "
                          class="trash"
                        />
                        <img
                          *ngIf="certificate.type === 'discipline' && certificate.default"
                          style="cursor: pointer"
                          (click)="modalDefaultDelOpen(certificate.external_id)"
                          class="trash"
                        />
                      </div>
                      <div>
                        <div
                          *ngIf="certificate.type === 'discipline'"
                          class="star-text cursor-pointer"
                          (click)="onDefaultDiscipline(certificate)"
                        >
                          <img
                            class="defaultCertificate"
                            [ngClass]="{
                              yellow_star: certificate.default,
                              star: !certificate.default
                            }"
                          />
                          {{ i18n.disciplines_settings_certificates_default }}
                        </div>
                        <div
                          *ngIf="certificate.type === 'global'"
                          class="star-text cursor-pointer"
                          (click)="onDefaultDiscipline(certificate)"
                        >
                          <img
                            class="defaultCertificate"
                            [ngClass]="{
                              yellow_star: certificate.default,
                              star: !certificate.default
                            }"
                          />
                          {{ i18n.disciplines_settings_certificates_default }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="no-certificate-models"
                    *ngIf="isNotHasCertificate"
                  >
                    <div>
                      <img src="../../../../../assets/svg/attention.svg" alt="" />
                    </div>
                    <p class="title">
                      {{ i18n.disciplines_settings_certificates_no_certificate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div
        *appCheckPermissions="'certificates_models';type:'create'"
         class="flex justify-start mt-3">
          <div
            [ngClass]="{
              'cursor-not-allowed':
                certificates.custom_discipline_certificates == false ||
                disciplineCertificateForm.value.enabled == false
            }"
          >
            <div
              [ngClass]="{
                'disbled-certificate':
                  certificates.custom_discipline_certificates == false ||
                  disciplineCertificateForm.value.enabled == false
              }"
            >
              <span style="cursor: pointer">
                <button (click)="openUploadContent()">
                  <img
                    src="../../../../../assets/svg/certificate/plus.svg"
                    alt=""
                  />
                </button>
              </span>
            </div>
          </div>
          <p class="mt-1 ml-2">
            {{
              i18n.disciplines_settings_certificates_add_new_certificate_template
            }}
          </p>
        </div>
  
        <div class="flex flex-col gap-1 w-full items-center sm:flex-row justify-center sm:justify-end mt-2 sm:mr-7">
          <div
            [ngClass]="{
              'cursor-not-allowed':
                disciplineCertificateForm.value.enabled == false
            }"
          >
            <div
              [ngClass]="{
                'disbled-certificate':
                  disciplineCertificateForm.value.enabled == false
              }"
            >
              <button
                class="button-certificate"
                (click)="modalWarnOrModalEmit()"
              >
                <p>
                  <span>
                    <img />
                  </span>
                  {{ i18n.disciplines_settings_certificates_issue }}
                </p>
              </button>
             <!--  <button
                class="button-certificate"
                (click)="modalEmitCertificateOpen()"
              >
                <p>
                  <span>
                    <img />
                  </span>
                  {{ i18n.disciplines_settings_certificates_issue }}
                </p>
              </button> -->
            </div>
          </div>
  
  
            <button
              class="button-save"
              [ngClass]="{
                'disabled:opacity-20 cursor-not-allowed': !buttonUnlocked
              }"
              [disabled]="!buttonUnlocked"
              (click)="onPostCertificatesSettings()"
            >
              <p>{{ i18n.disciplines_settings_certificates_save }}</p>
            </button>
            <!--  {{ disciplineCertificateForm.value.enabled | json}} -->
  
  
        </div>
      </div>

      <ng-container *ngIf="isLoading">
        <div class="flex justify-center items-center" style="height: 400px;">
          <app-bullets-loader></app-bullets-loader>
        </div>
      </ng-container>

    </div>
  </div>
</section>


<app-message-modal></app-message-modal>

<app-modal-confirmation
  *ngIf="modalConfirmation$ | async"
  [messageFeedback]="message"
></app-modal-confirmation>

<app-not-allowed-modal
  *ngIf="notAllowedModal"
  [i18n]="i18n"
  (closeModal)="closeNotAllowed()"
></app-not-allowed-modal>

<app-resources-select
  *ngIf="modalResourcesCertificate$ | async"
  [i18n]="i18n"
  [sections]="sections"
  [contentsCertificate]="contentsCertificate"
  (emittingPatch)="patchResources($event)"
></app-resources-select>

<app-edit-certificate
  *ngIf="modalEditCertificate$ | async"
  [i18n]="i18n"
  [certificateForEdit]="certificateForEdit"
  (editedCertificate)="formatPatchCertificates(certificateForEdit, files)"
  (editingCertificateForm)="editedCertificateForm = $event"
  (postingCertificateForm)="postCertificateForm = $event"
  (postCertificate)="formatPostCertificates()"
  [loading]="Loading"
></app-edit-certificate>

<app-confirmation-modal
  *ngIf="modalConfirmCertificate$ | async"
  [i18n]="i18n"
  [certificateId]="certificateId"
  (confirmedForDeletion)="onDelete($event)"
></app-confirmation-modal>

<app-confirm-default-delete
  *ngIf="modalDefaultCertificateDel$ | async"
  [i18n]="i18n"
  [certificateId]="certificateId"
  (confirmedForDeletion)="onDelDefaultDel($event)"
></app-confirm-default-delete>

<app-add-new-model-certificate
  *ngIf="modalAddModelCertificate$ | async"
  [preview]="preview"
  [i18n]="i18n"
  (modelCertificateForm)="onPostPreviewModel($event)"
></app-add-new-model-certificate>

<app-emit-certificate
  *ngIf="modalEmitCertificate$ | async"
  [i18n]="i18n"
  [elegibles]="elegibles"
  (callingElegibles)="getElegible()"
  (emittingPost)="postEmitCertificates($event)"
></app-emit-certificate>

<app-upload
  *ngIf="modalOpen$ | async"
  [maxFiles]="maxFiles"
  [files]="files"
  [multiSelect]="false"
  [fileType]="'presentation'"
  (filesEvent)="receiveFiles($event)"
  (loadedFile)="buttonUnlocked = $event"
></app-upload>