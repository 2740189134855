<div class="load z-20" *ngIf="!options">
  <img src="../../../assets/svg/loading.svg" alt="" srcset="">
</div>
<div *ngIf="!options" class="opacity-10 fixed inset-0 z-20" style="background-color: #000;"></div>
<section class="flex flex-col md:flex-row items-center bg-customWhite-default">
  <div class="bg-customGray-light h hidden lg:block w-full md:w-1/2 xl:w-2/4 text-center">
    <div class="flex items-stretch h justify-center c">
      <!-- <fa-icon [icon]="faImage" class="self-center text-customGray-iconGray h" *ngIf="!options"></fa-icon> -->
      <img src="{{options.customization.login_background}}" *ngIf="options" class="h" alt="">
    </div>
  </div>
  <div
    class="bg-white w-ful md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 2xl:w-1/4 px-6 lg:px-16 xl:px-12 flex justify-center">
    <div class="w-full h-100 xl:my-auto">
      <img src="../../../assets/svg/logo.png" alt="" *ngIf="!options"
        class="w-4/5 h-2/8 mt-12 mb-8 mx-auto object-cover">
      <img src="{{options.customization.logo}}" alt="" *ngIf="options"
        class="w-4/5 h-2/8 mt-12 mb-8 mx-auto object-cover">

      <form class="mt-6" action="#" method="POST">
        <div class="mt-6">
          <h1 class="text-customBlue-default font-bold text-lg">Redefinir senha</h1>
          <p class="mt-2 text-md">Escolha uma nova senha para a sua conta. Esta senha substituirá a antiga.</p>
        </div>
        <div class="mt-6">
          <label class="block text-gray-700 text-sm font-semibold">Nova senha</label>
          <div class="flex">
            <input type="password" name="" id="pass" placeholder="Digite sua senha"
              class="w-full px-4 py-3 rounded-sm mt-2 border border-gray text-sm" autofocus autocomplete required>
            <label class="checkbox-label">
              <input type="checkbox" (click)="pass()">
              <span class="checkbox-custom ">
                <img src="../../../assets/png/eye.png">
              </span>
            </label>
          </div>
        </div>
        <div class="mt-5">
          <label class="block text-gray-700 text-sm font-semibold">Confirmar senha</label>
          <div class="flex">
            <input type="password" name="" id="confirm" placeholder="Digite sua senha" minlength="6"
              class="w-full px-4 py-3 rounded-sm border border-gray text-sm" required>
            <label class="checkbox-label">
              <input type="checkbox" (click)="passConfirm()">
              <span class="checkbox-custom ">
                <img src="../../../assets/png/eye.png">
              </span>
            </label>
          </div>
        </div>
        <span *ngIf="dontMatch == true" class="font-bold text-sm text-customRed-default">As senhas não são
          iguais.</span>
        <div class="mt-1 flex justify-center">
          <span class="text-xs font-semibold">Caracteres especiais e números deixam sua senha mais segura.</span>
        </div>
      </form>

      <div class="flex flex-col">
        <button type="submit"
          class="w-4/5 bg-customBlue-default text-w block rounded-sm font-semibold px-3 py-3 mt-6 mb-6 mx-auto hover:bg-blue-800 transition duration-700 ease-in-out"
          (click)="passMatch()">Trocar senha</button>
      </div>
      <div class="flex justify-between w-3/4 mx-auto">
        <button class="w-3/6 flex flex-col items-center">
          <!-- <fa-icon [icon]="faUserHeadset" class="text-4xl text-customGray-dark"></fa-icon> -->
          <label class="font-semibold text-md">Suporte</label>
        </button>
        <button class="w-3/6 flex flex-col items-center">
          <!-- <fa-icon [icon]="faQuestionCircle" class="text-4xl text-customGray-dark"></fa-icon> -->
          <label class="font-semibold text-md">Ajuda</label>
        </button>
      </div>
    </div>
  </div>
</section>
