import {
    Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
    selector: 'app-modal-success-planning',
    templateUrl: './modal-success-planning.component.html',
    styleUrls: ['./modal-success-planning.component.scss']
})
export class ModalSuccessPlanningComponent {
    @Output() closeModalSuccessPlanning = new EventEmitter();
    @Input() isVisibleSuccessPlanning = false;
    @Input() message: string;
    @Input() i18n: any = {};

    closeModal(): void {
        this.closeModalSuccessPlanning.emit(false);
    }
}
