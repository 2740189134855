<section 
    class="px-8 my-8 py-8 shadow-card round-card transition ease-out duration-180 animate-fade-in-down bg-customWhite-default">
    <h1 class="text-customBlue-default font-bold text-xl">{{ i18n.disciplines_settings_tab_configuration }}</h1>
    <div class="flex my-8 flex-col gap-2 text-customGray-dark">
        <div class="w-full flex">
            <div class="w-11/12 mt-1">
                <label for="dashboard_visible">{{ i18n.disciplines_content_select_tab_overview }}</label>
            </div>
            <div class="w-1/12 mt-1">
                <input id="dashboard_visible" [checked]="settingsTab.dashboard_visible" (change)="settingsTab.dashboard_visible = !settingsTab.dashboard_visible" id="toogle" (click)="changeTabForm()" style="height:1.4rem;" class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer" type="checkbox" />
            </div>
        </div>
        <div class="w-full flex">
            <div class="w-11/12 mt-1">
                <label for="grades_visible">{{ i18n.disciplines_select_tab_grades }}</label>
            </div>
            <div class="w-1/12 mt-1">
                <input id="grades_visible" [checked]="settingsTab.grades_visible" (change)="settingsTab.grades_visible = !settingsTab.grades_visible" id="toogle" (click)="changeTabForm()" style="height:1.4rem;" class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer" type="checkbox" />
            </div>
        </div>
        <div class="w-full flex">
            <div class="w-11/12 mt-1">
                <label for="reports_visible">{{ i18n.disciplines_reports_}}</label>
            </div>
            <div class="w-1/12 mt-1">
                <input id="reports_visible" [checked]="settingsTab.reports_visible" (change)="settingsTab.reports_visible = !settingsTab.reports_visible" id="toogle" (click)="changeTabForm()" style="height:1.4rem;" class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer" type="checkbox" />
            </div>
        </div>
        <div class="w-full flex">
            <div class="w-11/12 mt-1">
                <label for="announcements_visible">{{ i18n.disciplines_select_tab_waning }}</label>
            </div>
            <div class="w-1/12 mt-1">
                <input id="announcements_visible" [checked]="settingsTab.announcements_visible" (change)="settingsTab.announcements_visible = !settingsTab.announcements_visible" id="toogle" (click)="changeTabForm()" style="height:1.4rem;" class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer" type="checkbox" />
            </div>
        </div>
        <div class="w-full flex">
            <div class="w-11/12 mt-1">
                <label for="bank_questions_visible">{{ i18n.disciplines_select_tab_bank_question }}</label>
            </div>
            <div class="w-1/12 mt-1">
                <input id="bank_questions_visible" [checked]="settingsTab.bank_questions_visible" (change)="settingsTab.bank_questions_visible = !settingsTab.bank_questions_visible" id="toogle" (click)="changeTabForm()" style="height:1.4rem;" class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer" type="checkbox" />
            </div>
        </div>
        <div class="w-full flex">
            <div class="w-11/12 mt-1">
                <label for="forums_visible">{{ i18n.disciplines_select_tab_forum }}</label>
            </div>
            <div class="w-1/12 mt-1">
                <input id="forums_visible" [checked]="settingsTab.forums_visible" (change)="settingsTab.forums_visible = !settingsTab.forums_visible" id="toogle" (click)="changeTabForm()" style="height:1.4rem;" class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer" type="checkbox" />
            </div>
        </div>
    </div>        
    <div class="flex row justify-end">
        <div class="">
            <button class="border rounded-md py-2 px-8 font-bold text-sm border-customGray-textGray" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !buttonActive}" style="color: #FFFFFF;background-color: #233674" (click)="postTabSettings()" [disabled]="!buttonActive">{{ i18n.disciplines_save }}</button>
        </div>
    </div>  
</section>