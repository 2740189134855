import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_Routes } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DisciplinesDataSettingsService {
  constructor(private http: HttpClient) {}

getDisciplineDataSettings(external_id: any): Observable<any> {
  return this.http.get<any>(`${API_Routes.URL}/disciplines/${external_id}/settings/basic`)
}

 getDisciplineLevels(): Observable<any> {
  return this.http.get(`${API_Routes.URL}/levels`);
} 

 getDisciplineModalities(): Observable<any> {
  return this.http.get(`${API_Routes.URL}/modalities`);
} 

postDisciplineDataSettings(external_id: any, params: any): Observable<any> {
  return this.http.patch(`${API_Routes.URL}/disciplines/${external_id}/settings/basic`, params)
}
}

