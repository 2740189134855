import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportSpreadsheetComponent } from './import-spreadsheet.component';
import { ImportSpreadsheetRoutingModule } from './import-spreadsheet.routing.module';
import { SelectSheetComponent } from './select-sheet/select-sheet.component';
import { UploadModule } from 'src/app/components/upload/upload.module';
import { SelectSheetModule } from './select-sheet/select-sheet.module';
import { PreviewSheetComponent } from './preview-sheet/preview-sheet.component';
import { ButtonsFooterComponent } from './components/buttons-footer/buttons-footer.component';
import { AccordionConfigSheetComponent } from './components/accordion-config-sheet/accordion-config-sheet.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomImportComponent } from './components/custom-import/custom-import.component';
import { TableImpotedSheetsComponent } from './components/table-impoted-sheets/table-impoted-sheets.component';
import { ModalHelpConnectionComponent } from './components/modal-help-connection/modal-help-connection.component';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { ModalResumeViewComponent } from './components/modal-resume-view/modal-resume-view.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { AccordionConfigSheetConnectionComponent } from './components/accordion-config-sheet-connection/accordion-config-sheet-connection.component';

@NgModule({
    declarations: [
        ImportSpreadsheetComponent,
        SelectSheetComponent,
        PreviewSheetComponent,
        ButtonsFooterComponent,
        AccordionConfigSheetComponent,
        CustomImportComponent,
        TableImpotedSheetsComponent,
        ModalHelpConnectionComponent,
        ModalResumeViewComponent,
        ModalConfirmationComponent,
        AccordionConfigSheetConnectionComponent
    ],
    imports: [
        CommonModule,
        ImportSpreadsheetRoutingModule,
        UploadModule,
        SelectSheetModule,
        SharedModule,
        MultiSelectModule,
        ReactiveFormsModule,
        GenericModalModule
    ]
})
export class ImportSpreadsheetModule { }
