/* eslint-disable prefer-destructuring */
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

import { DisciplineDetailService } from '../disciplines-detail/discipline-detail.service';
import {
    CallingRollService,
    HistoryTypes, ResponseCalendaryHistory, StatusRegister, StatusTypes, UserTypes
} from './calling-roll.service';

@Component({
    selector: 'app-calling-roll',
    templateUrl: './calling-roll.component.html',
    styleUrls: ['./calling-roll.component.scss'],
})
export class CallingRollComponent implements OnInit {
    isOpen = false;
    showModalObservation = false;
    showModalHistory = false;

    initialDate: Date = new Date();
    startTime: Date = new Date();
    endTime: Date = new Date();

    selectedStartTime: Date;
    selectedEndTime: Date;

    i18n: any = [];
    currentLanguage: any;

    loadingStudents = false;
    students: UserTypes[] = [];
    selectedStudent: UserTypes | null;

    histories: HistoryTypes[] = [];

    discipline_external_id: string;
    discipline_name: string;
    topicDiary = '';
    titleDiary: string;
    teacherIp: string;
    statusRegister: StatusRegister = 'started';

    dateClass: string;

    modalConfirmation$ = this.sharedService.modalConfirmation$;

    selectedMonth: number = new Date().getMonth() + 1;
    selectedYear: number = new Date().getFullYear();

    hasChangedInitialValues = false;

    frequencyCalendary: ResponseCalendaryHistory | undefined;

    constructor(private sharedService: SharedService, private callingRollService: CallingRollService, private router: Router, private disciplineDetailService: DisciplineDetailService) {}

    ngOnInit() {
        this.getTranslations();
        this.loadStartTime();
        this.loadEndTime();

        this.getTeacherIp();
        this.getDisciplineId();
        this.getStudents();
    }

    getStudents() {
        this.loadingStudents = true;

        this.disciplineDetailService.disciplineBasic$
            .subscribe({
                next: (response) => {
                    this.discipline_name = response?.name;
                    this.titleDiary = `${this.i18n?.title_frequency} - ${this.getFormatedDate()}`;

                    this.loadingStudents = false;

                    if (response?.participants?.student?.length) {
                        this.students = response?.participants?.student.map((student) => ({
                            ...student,
                            user_external_id: student.external_id,
                            presence: 'presence',
                            teacher_ip: null,
                            observation: '',
                        }));
                    }
                }
            });
    }

    getDisciplineId() {
        this.discipline_external_id = this.router?.url.split('/')[2];
    }

    getFrequencyCalendary() {
        this.callingRollService.getFrequencyCalendary(this.discipline_external_id, this.selectedYear, this.selectedMonth).subscribe((response) => {
            this.frequencyCalendary = response[0];
        });
    }

    getTeacherIp() {
        this.callingRollService.getMyIpAddress().subscribe((response) => {
            this.teacherIp = response.IPv4;
        });
    }

    getHistory() {
        this.dateClass = this.currentLanguage === 'en' ? formatDate(this.initialDate, 'MM/dd/yyyy', 'en-US') : formatDate(this.initialDate, 'dd/MM/yyyy', 'pt-BR');
        const dateClassToFind = formatDate(this.initialDate, 'yyyy-MM-dd', 'en-US');

        this.callingRollService.getHistories(this.discipline_external_id, dateClassToFind).subscribe((response) => {
            this.histories = response;

            if (this.histories.length > 0) {
                this.toggleModalHistory();
            }
        });
    }

    getHistoryStatusName(status: string) {
        return status === 'started' ? this.i18n?.history_status_incompleted : this.i18n?.history_status_completed;
    }

    getHistoryFormatedTime(time: string) {
        return time ? formatDate(time, 'HH:mm', 'en-US') : '-';
    }

    handleGetOneHistory(historyId: number) {
        this.callingRollService.getOneFrequency(this.discipline_external_id, historyId).subscribe((response) => {
            this.titleDiary = response?.settings?.name;
            this.initialDate = new Date(`${response?.settings?.date_class}T00:00:00`);
            this.startTime = new Date(response?.settings?.start_class);
            this.endTime = new Date(response?.settings?.end_class);

            this.topicDiary = response?.diary;

            this.students = response?.students.map((student) => ({
                ...student,
                user_external_id: student?.external_id || student?.user_external_id,
                teacher_ip: this.teacherIp,
            }));

            this.toggleModalHistory();
        });
    }

    handleInitiNewFrequency() {
        this.statusRegister = 'started';
        this.topicDiary = '';
        this.selectedStudent = null;
        this.students = this.students.map((student) => ({ ...student, presence: 'presence' }));

        this.initialDate = new Date();
        this.startTime = new Date();

        this.loadEndTime();
        this.toggleModalHistory();
    }

    handleSaveFrequency(status: StatusRegister = 'started') {
        this.statusRegister = status;

        this.students = this.students.map((student) => ({
            ...student,
            teacher_ip: this.teacherIp
        }));

        const payload = {
            disciplineExternalId: this.discipline_external_id,
            titleDiary: this.titleDiary,
            topicDiary: this.topicDiary || '',
            initialDate: formatDate(this.initialDate, 'yyyy-MM-dd', 'en-US'),
            startTime: this.selectedStartTime ? formatDate(this.selectedStartTime, 'yyyy-MM-dd HH:mm:ss', 'en-US') : formatDate(this.startTime, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
            endTime: this.selectedEndTime ? formatDate(this.selectedEndTime, 'yyyy-MM-dd HH:mm:ss', 'en-US') : formatDate(this.endTime, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
            status: this.statusRegister,
            students: this.students,
        };

        this.callingRollService.saveFrequency(payload).subscribe(() => {
            if (this.statusRegister === 'finished') {
                this.resetAllInitialValues();
                this.sharedService.modalOpenConfirmation();
            }
            this.toggleSidebar();
        });
    }

    resetAllInitialValues() {
        this.hasChangedInitialValues = false;
        this.topicDiary = '';
        this.selectedStudent = null;
        this.students = this.students.map((student) => ({ ...student, presence: 'presence' }));
        this.initialDate = new Date();

        this.loadStartTime();
        this.loadEndTime();
    }

    handleSelectStudent(student: UserTypes) {
        this.selectedStudent = student;
        this.toggleModalObservation();
    }

    handleSaveObservation() {
        this.selectedStudent = null;
        this.toggleModalObservation();
    }

    calcPercentByStatus(status = 'present') {
        if (this.students.length === 0) return '0 (0%)';

        const total = this.students.length;
        const countByStatus = this.students.filter((student) => student.presence === status).length;
        const percent = (countByStatus / total) * 100;

        return `${countByStatus} (${percent.toFixed(0)}%)`;
    }

    getFormatedDate() {
        const dateFormatted = this.currentLanguage === 'en' ? formatDate(this.initialDate, 'MM-dd', 'en-US') : formatDate(this.initialDate, 'dd-MM', 'pt-BR');
        return dateFormatted.replace('-', '/');
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('CallingRoll') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    handleCloseSidebar() {
        if (this.hasChangedInitialValues) {
            this.handleSaveFrequency('started');
        } else {
            this.toggleSidebar();
        }
    }

    toggleSidebar() {
        this.isOpen = !this.isOpen;
        this.toggleLibrasVisibility();
        this.toggleUserWayVisibility();
    }

    toggleLibrasVisibility() {
        const librasComponents = document.getElementsByClassName('container_libras');
        if (librasComponents.length > 0) {
            const containerLibras = librasComponents[0] as HTMLElement;
            if (this.isOpen) {
                containerLibras.style.display = 'none';
            } else {
                containerLibras.style.display = 'block';
            }
        }
    }

    toggleUserWayVisibility() {
        const userWayComponents = document.getElementsByClassName('userway_buttons_wrapper');
        if (userWayComponents.length > 0) {
            const containerUserWay = userWayComponents[0] as HTMLElement;
            if (this.isOpen) {
                containerUserWay.style.display = 'none';
            } else {
                containerUserWay.style.display = 'block';
            }
        }
    }

    getRamdomNumber(index) {
        if (index === 7) {
            return 3;
        }

        if (index % 2 === 0) {
        // Se o índice for par, retorna 2 ou 3
            return 1;
        }
        // Se o índice for ímpar, retorna 1
        return 2;
    }

    loadStartTime() {
        const timeToReturn = new Date();
        const minutes = timeToReturn.getMinutes();
        const roundedMinutes = Math.floor(minutes / 30) * 30; // Arredonda para baixo para o múltiplo de 30 mais próximo

        timeToReturn.setMilliseconds(0);
        timeToReturn.setSeconds(0);
        timeToReturn.setMinutes(roundedMinutes);

        this.startTime = timeToReturn;
    }

    loadEndTime() {
        this.endTime = new Date(this.startTime.getTime() + 50 * 60000); // adiciona 50 minutos
    }

    toggleModalObservation() {
        this.showModalObservation = !this.showModalObservation;
    }

    toggleModalHistory() {
        this.showModalHistory = !this.showModalHistory;
    }

    handleChangeDate({ formatedDate, month, year }) {
        if (month && year) {
            this.selectedMonth = month;
            this.selectedYear = year;
            this.getFrequencyCalendary();
        } else {
            this.initialDate = new Date(`${formatedDate}T00:00:00`);

            this.titleDiary = `${this.i18n?.title_frequency} - ${this.getFormatedDate()}`;

            this.getHistory();
        }

        this.hasChangedInitialValues = true;
    }

    handleChangeStartTime({ calendarDateValue }) {
        this.selectedStartTime = calendarDateValue;
        this.hasChangedInitialValues = true;
    }

    handleChangeEndTime({ calendarDateValue }) {
        this.selectedEndTime = calendarDateValue;
        this.hasChangedInitialValues = true;
    }

    onStatusChange(status: string, studentExternalId: string) {
        const index = this.students.findIndex((s) => s.external_id === studentExternalId || s.user_external_id === studentExternalId);
        this.students[index].presence = status as StatusTypes | undefined;
        this.hasChangedInitialValues = true;
    }

    handleInsertObservation(observation: string) {
        if (this.selectedStudent) {
            this.selectedStudent.observation = observation;
            const index = this.students.findIndex((s) => s.external_id === this.selectedStudent?.external_id);
            this.students[index].observation = observation;
            this.hasChangedInitialValues = true;
        }
    }

    handleChangeTopics(text: string) {
        this.topicDiary = text;
        this.hasChangedInitialValues = true;
    }

    adjustTextareaHeight(event: Event): void {
        const textarea = event.target as HTMLTextAreaElement;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }
}
