<app-generic-modal [isVisible]="isVisible">
    <div class="container flex flex-col" style="max-width: 520px; height: 70vh">
        <!--header-->
        <div class="border-b border-customBlue-glass mb-4 w-full">
            <div class="p-4 flex flex-col items-center justify-center">
                <h3
                    class="font-bold font-roboto text-22 sm:text-26 text-customBlue-default"
                >
                    {{ i18n?.import_spreadsheet_resume_modal_title }}
                </h3>
            </div>
        </div>
        <!--body-->
        <div
            class="p-7 text-center flex-grow overflow-y-auto"
            style="height: 50vh"
        >
            <div
                class="flex bg-customGreen-approved bg-opacity-10 border border-customGreen-approved py-3 px-3 sm:px-14 justify-center items-center rounded-md"
            >
                <span
                    class="iconify-inline text-24 text-customGreen-approved mr-1 sm:mr-4 font-roboto"
                    data-icon="icon-park-solid:check-one"
                ></span>
                <span
                    class="text-14 sm:text-16 font-roboto font-normal text-center text-customGray-dark"
                >
                    {{ i18n?.import_spreadsheet_resume_modal_importeds }}
                    {{ data?.feedbacks?.successCount ?? 0 }}
                    {{ i18n?.import_spreadsheet_resume_modal_lines_in_total }}
                </span>
            </div>
            <div
                (click)="toggleAccordionErro()"
                class="flex flex-col bg-inicieColors-orange bg-opacity-10 border border-inicieColors-orange py-3 px-3 sm:px-14 justify-center items-center mt-4 rounded-md"
            >
                <div class="flex justify-center items-center w-full">
                    <span
                        class="iconify-inline text-24 text-inicieColors-orange mr-1 sm:mr-4 font-roboto"
                        data-icon="ri:error-warning-fill"
                    ></span>
                    <span
                        class="text-14 sm:text-16 font-roboto font-normal text-center text-customGray-dark"
                    >
                        {{ data?.feedbacks?.errorsCount ?? 0 }}
                        {{ i18n?.import_spreadsheet_resume_modal_lines_errors }}
                    </span>
                    <div class="flex sm:items-center ml-auto" *ngIf="data?.feedbacks?.errorsCount > 0">
                        <div [class.rotate-180]="accordionOpen">
                            <span
                                class="iconify-inline text-18 text-customGray-dark"
                                data-icon="raphael:arrowdown"
                            ></span>
                        </div>
                    </div>
                </div>
                <div>
                    <div *ngIf="accordionOpen" class="mt-4">
                        <div
                            *ngFor="let item of data?.feedbacks?.lineErrors"
                            class="text-14 sm:text-16 font-roboto font-normal text-left text-customGray-dark"
                        >
                            {{ getErrorLine(item) }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngIf="!isLoading"
                class="flex items-center justify-center gap-3 mb-12 p-2 mt-7"
            >
                <button
                    (click)="downloadReport()"
                    class="border border-customBlue-default py-2 px-8 rounded-md bg-customWhite-default"
                >
                    <span
                        class="text-customBlue-default text-12 sm:text-16 font-roboto font-semibold"
                    >
                        {{ i18n?.import_spreadsheet_resume_modal_make_download }}
                    </span>
                </button>
            </div>
            <!-- Loading -->
            <div
                *ngIf="isLoading"
                class="p-7 text-center flex-grow flex items-center justify-center"
            >
                <div class="flex flex-col items-center justify-center gap-2">
                    <div class="mx-auto">
                        <app-bullets-loader></app-bullets-loader>
                    </div>
                </div>
            </div>
        </div>
        <!--footer-->
        <div class="flex items-center justify-center gap-3 mb-12 p-2">
            <button
                (click)="closeModal()"
                class="border border-customBlue-default py-2 px-8 rounded-md bg-customWhite-default"
            >
                <span
                    class="text-customBlue-default text-12 sm:text-16 font-roboto font-semibold"
                >
                    {{ i18n?.import_spreadsheet_btn_goback }}
                </span>
            </button>
        </div>
    </div>
</app-generic-modal>
