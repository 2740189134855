
<app-generic-modal [isVisible]="true">
  <div
    class="transition ease-out duration-300 animate-fade-in-down outline-none"
  >
    <div class="relative w-auto mx-auto max-w-6xl">
      <div
        class="border-0 rounded-2xl shadow-lg relative flex flex-col w-full outline-none focus:outline-none"
      >
        <!-- header -->
        <header
          class="border-b border-solid border-customBlue-superLight p-6"
        >
        <p class="text-center text-customBlue-default text-3xl font-semibold">
          {{i18n.disciplines_emit_cert_title}}
        </p>
        <p class="text-center subTitleHeader">
          {{i18n.disciplines_emit_cert_txt}}
        </p>
          <button
          class="cursor-pointer absolute"
            (click)="closeAddModelCertificate()"
            style="top: 20px; right: 30px"
          >
            <img
              src="../../../../../assets/svg/certificate/close.svg"
              alt="close"
            />
          </button>
        </header>
        <!-- body -->
        <p class="bodyTitle text-center mt-4">{{i18n.disciplines_preview}}</p>
        <form class="p-4 grid grid-cols-1 md:grid-cols-2 gap-4 items-center" [formGroup]="previewCertificateForm">
            <div>
              <div *ngIf="!previewLoading">
                <img src="{{ preview }}" alt="Certificate" />
              </div>
              <div *ngIf="previewLoading" class="certificatePreviewImg">
                <img
                  class="previwImgLoading"
                  src="../../../../assets/svg/loading.svg"
                  alt=""
                  srcset=""
                />
                <div class="message">
                  <p class="title">{{i18n.disciplines_pls_await}}</p>
                  <p>{{i18n.disciplines_load_preview}}</p>
                </div>
              </div>
              <button (click)="previewUpdate()" type="button" class="refreshText mt-2">
                <img
                  style="cursor: pointer"
                  src="../../../../../assets/svg/certificate/refresh.svg"
                />
                {{i18n.disciplines_update_preview}}
              </button>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div class="flex flex-col gap-4">
                <div class="flex flex-col gap-1">
                  <label class="labelNewCertificate" for="student_name">{{i18n.disciplines_user_name}}</label>
                  <input
                  class="relative inputNewCertificate"
                  formControlName="student_name"
                  placeholder="{{i18n.disciplines_user_name_place}}"
                  size="45"
                  maxlength="45"
                  />
                </div>
                <div class="flex flex-col gap-1">
                  <label class="labelNewCertificate" for="discipline_name">{{i18n.disciplines_discipline_name}}</label>
                  <input
                    class="relative inputNewCertificate"
                    formControlName="discipline_name"
                    placeholder="{{i18n.disciplines_discipline_name_place}}"
                    size="45"
                    maxlength="45"
                  />
                </div>
              </div>
            <div class="flex flex-col gap-4">
              <div class="flex flex-col gap-1">
                <label class="labelNewCertificate" for="discipline_duration">{{i18n.disciplines_discipline_duration}}</label>
                <input
                  type="number"
                  max="100000000"
                  min="0"
                  class="relative inputNewCertificate"
                  formControlName="discipline_duration"
                  placeholder="{{i18n.disciplines_discipline_duration_place}}"
                />
              </div>
              <div class="flex flex-col gap-1">
                  <label class="labelNewCertificate" for="emission_date">{{i18n.disciplines_emit_date}}</label>
                  <app-picker-calendar
                  [i18n]="i18n"
                  [currentLanguage]="currentLanguage"
                  [editMode]="true"
                  [initialDate]="initialDate"
                  [Form]="previewCertificateForm"
                  [inputNameControlPicker]="'emission_date'"
                  [inputControlNameMask]="'calendarEmissionDate'"
                  [position]="'absolute'"
                  [minDateValidation]="startDate?.value == '' || startDate?.value == null ? initialDate : startDate?.value"
                  (emitEvent)="fnPickDateToInput()"
                ></app-picker-calendar>
                <!-- Validation  -->
                <div *ngIf="previewCertificateForm.controls['calendarEmissionDate']?.errors">
                  <p
                  class="text-sm"
                  style="color: #e8532c"
                  *ngIf="previewCertificateForm.controls['calendarEmissionDate']?.errors?.dateError"
                >
                  {{i18n.disciplines_date_error}} {{(currentLanguage == 'en' ? (startDate?.value == '' || startDate?.value == null ? (initialDate | date:'MM/dd/yyyy HH:mm') : (startDate?.value | date:'MM/dd/yyyy HH:mm')) : (startDate?.value == '' || startDate?.value == null ? (initialDate | date:'dd/MM/yyyy HH:mm') : (startDate?.value | date:'dd/MM/yyyy HH:mm')))}}
                  </p>
                </div>
              </div>
           </div>
         </div>
        </form>
        <!-- footer -->
        <footer class="px-4 py-6 flex justify-center items-center gap-4">
          <button
            class="bg-customBlue-default px-12 py-2 rounded text-customWhite-default font-medium"
            (click)="addCertificate()"
            [ngClass]="{
              'disabled:opacity-20 cursor-not-allowed': !buttonUnlocked
            }"
            [disabled]="!buttonUnlocked"
          >
            <p>{{i18n.disciplines_add}}</p>
          </button>
          <button class="button-cancel" (click)="closeAddModelCertificate()">
            <p>{{i18n.disciplines_cancel}}</p>
          </button>
        </footer>     
      </div>
    </div>
  </div>
</app-generic-modal>

<!-- <div *ngIf="modalAddModelCertificate$" class="overlay" (click)="closeAddModelCertificate()" style="z-index: 5"></div> -->

