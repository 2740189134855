import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-profile-impersonate',
  templateUrl: './modal-profile-impersonate.component.html',
  styleUrls: ['./modal-profile-impersonate.component.scss']
})
export class ModalProfileImpersonateComponent implements OnInit {
  @Input() i18n: any = [];
  @Input() impersonateUser: any = [];
  @Input() isVisible: boolean = false;
  @Output() sendCloseModal = new EventEmitter();
  @Output() sendProfileToImpersonate = new EventEmitter();

  selected: any = '';
  profile: any = []

  constructor() { }

  ngOnInit(): void { }

  closeModal(){
    this.selected = '';
    this.sendCloseModal.emit(false);
  }

  selectProfileToImpersonate(profile: any){
    this.profile = profile;
    this.selected = profile?.role_external_id;
  }

  emitProfileToImpersonate(){
    const params = {
      "user_external_id": this.impersonateUser.external_id,
      "role_external_id": this.selected
    }
    this.selected = '';
    this.sendProfileToImpersonate.emit(params)
  }

}
