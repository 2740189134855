<div
  #addButton
  class="add-button bg-customOrange-default"
  [style]="addButtonWidth"
  [class.is-from-right]="fromRight"
  [class.is-disabled]="disabled"
>
  <i class="fat-plus-icon add-button__icon"></i>
</div>

<div class="add-button-label" *ngIf="label">{{ label }}</div>
