<div class="input-search-container" *appCheckPermissions="'search';type:'list'">
    <label for="search">
      <img [ngClass]="{'loading': hasLoading, 'search': !hasLoading}">
  </label>
    <input
      [formControl]="typedData"
      id="search"
      class="input-search"
      [placeholder]="i18n.search_global_placeholder"
      autocomplete="off"
    />
</div>

<span id="tooltip"></span>

<div *ngIf="openTooltip">
  <div id="tooltipText" *ngIf="isNotFound == false">
    <ng-container>
      <div class="max-heigth-calc flex flex-col gap-2 w-full transition overflow-auto">
        <div class="result-container flex w-full ">
          <div class="flex flex-col gap-3 w-full">
            <div class="result-title">
              <h2>{{i18n.search_disciplines}}</h2>
              <div><p>{{countQuery(queryResults?.disciplines?.count)}}</p></div>
            </div>
            <div  *ngFor="let result of queryResults?.disciplines?.result">
              <div class="results-title" (click)="routeToLink('Disciplines', result)"> {{result?.title}}</div>
            </div>
          </div>
        </div>
        <div class="result-container flex w-full">
          <div class="flex flex-col gap-3 w-full">
            <div class="result-title">
              <h2>{{i18n.search_contents}}</h2>
              <div><p>{{countQuery(queryResults?.contents?.count)}}</p></div>
            </div>
            <div  *ngFor="let result of queryResults?.contents?.result">
              <div class="results-title" (click)="routeToLink('Content', result)"> {{result?.title}}</div>
            </div>
          </div>
        </div>
        <div class="result-container flex w-full">
          <div class="flex flex-col gap-3 w-full">
            <div class="result-title">
              <h2>{{i18n.search_Series}}</h2>
              <div><p>{{countQuery(queryResults?.series?.count)}}</p></div>
            </div>
            <div  *ngFor="let result of queryResults?.series?.result">
              <div  class="results-title" (click)="routeToLink('Series', result)"> {{result?.section_title}}</div>
            </div>
          </div>
        </div>
        <div class="result-container flex w-full">
          <div class="flex flex-col gap-3 w-full">
            <div class="result-title">
              <h2>{{i18n.search_announcements}}</h2>
              <div><p>{{countQuery(queryResults?.announcements?.count)}}</p></div>
            </div>
            <div  *ngFor="let result of queryResults?.announcements?.result">
              <div  class="results-title" (click)="routeToLink('Announcement', result)"> {{result?.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="openTooltip">
  <div id="tooltipText" *ngIf="isNotFound == true">
    <ng-container>
      <div class="flex flex-col gap-2 w-full h-36 items-center justify-center">
        <div class="not-found-title">
          {{i18n.search_not_found}}
        </div>
      </div>
    </ng-container>
  </div>
</div>
