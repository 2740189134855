import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SubmissionsService } from 'src/app/pages/disciplines/components/content/components/homework-submissions/submissions.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

import { setActiveTab, updateActiveCourseWork } from '../../../../store/homework-submissions.actions';
import { selectHomeworkSubmissionsState, selectSubmissions } from '../../../../store/homework-submissions.reducer';

@Component({
    selector: 'app-submission-generic-board',
    templateUrl: './submission-generic-board.component.html',
    styleUrls: ['./submission-generic-board.component.scss']
})
export class SubmissionGenericBoardComponent implements OnInit, OnDestroy {
    state$: Subscription;
    submissions$: Subscription;
    submissions;
    contentID?: string;
    i18n: any = {};

    constructor(
        private store: Store,
        public shared: SharedService,
        private submissionsService: SubmissionsService,
        private localStorageService: LocalStorageService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.state$ = this.store.select(selectHomeworkSubmissionsState).subscribe((state) => this.contentID = state.contentID);
        this.submissions$ = this.store.select(selectSubmissions).subscribe((subs) => {
            if (!subs.length || !subs[0].status) return;
            this.submissions = subs;
        });
    }

    goToSubmissionsTab(submission) {
        const wasSubmited = submission.course_work.at(-1)?.completed_at;
        if (!wasSubmited) return;

        const newActiveCourseWorkID = submission.course_work.at(-1)?.id;
        const submissionsTab = 'submissions';

        this.submissionsService
            .getCourseWork(this.contentID, newActiveCourseWorkID)
            .subscribe((courseWork: any) => {
                this.store.dispatch(updateActiveCourseWork({ activeCourseWork: courseWork }));

                if (Object.prototype.hasOwnProperty.call(courseWork.contentSpecific, 'tries')) {
                    const { tries } = courseWork.contentSpecific;
                    const tryObj = tries.find((try_obj) => try_obj.try_id === newActiveCourseWorkID);
                    this.submissionsService.homework.next(tryObj);
                }
                
                this.store.dispatch(setActiveTab({ activeTab: submissionsTab }));
                const currentTab = this.router.routerState.snapshot.url.split('/').pop() || submissionsTab;
                this.router.navigateByUrl(this.router.routerState.snapshot.url.replace(currentTab, submissionsTab));
            });
    }

    getDateAndHour(dateString: string) {
        let currentLanguage;
        if (this.localStorageService.getLocalStorageItem('currentLanguage')) {
            currentLanguage = localStorage.getItem('currentLanguage');
        }
        if (!currentLanguage) {
            currentLanguage = 'pt-BR';
        }

        if (!dateString) {
            return '';
        }
        const newHour = new Date(dateString).toLocaleTimeString(currentLanguage, {
            hour: '2-digit', minute: '2-digit'
        });
        const newDate = new Date(dateString).toLocaleDateString(currentLanguage, {
            year: 'numeric', month: '2-digit', day: '2-digit'
        });
        return `${newDate} ${this.i18n.upload_hours} ${newHour}`;
    }

    getTranslations() {
        this.i18n = { ...this.shared.getTranslationsOf('Disciplines'), ...this.shared.getTranslationsOf('Upload') };
    }

    ngOnDestroy(): void {
        if (this.submissions$) this.submissions$.unsubscribe();
    }
}
