<form [formGroup]="form"
  class="bg-customWhite-default py-2 px-4 rounded text-customGray-dark flex items-center gap-3"
>
  <input
    class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
    id="toogle"
    type="checkbox"
    formControlName="accessibility"
    (change)="toggleAccessibility()"
  />
  <label for="toogle">{{ i18n.login_accessibility }}</label>
</form>
