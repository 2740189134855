<app-generic-modal  [isVisible]="isVisible">
  <div class="container">
    <i class="loading-icon"></i>

    <div class="message">
      <p class="title">{{ translations.boldText }}</p>
      <p>{{ translations.lightText }}</p>
    </div>
  </div>
</app-generic-modal>
