
<app-generic-modal [isVisible]="true">
    <div class="container">
     <!--  <i class="loading-icon"></i> -->
      <div class="message">
        <div>
            <img src="../../../../../assets/svg/certificate/exclamation.svg" alt="">
        </div>
        <p class="title">{{i18n.disciplines_emit_disabled_title}}</p>
        <p>{{i18n.disciplines_emit_disabled_text}}</p>
      </div>
      <button class="button-cancel border-2 border-customBlue-default" (click)="closingModal()">
        <p>{{i18n.disciplines_emit_disabled_goback}}</p>
    </button>
    </div>
  </app-generic-modal>
