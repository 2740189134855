<div
  class="flex bg-customWhite-default shadow-2xl rounded-lg"
  style="height: 752px"
>
  <div class="flex flex-col w-full">
    <div class="flex flex-row w-full justify-end items-center pt-2 pr-2">
      <button class="x-close__icon" (click)="showMainScreen()"></button>
    </div>
    <form [formGroup]="certificateForm">
      <div
        class="flex flex-col md:flex-row gap-4 p-4">
          <div class="search-form w-full">
            <div class="input-search-container">
              <label for="search">
                <img />
              </label>
              <input
                formControlName="q"
                id="search"
                class="input-search"
                [placeholder]="i18n.reports_search"
              />
            </div>
          </div>

          <div class="w-full flex flex-col md:flex-row md:items-center gap-2 md:gap-4">
            <p class="whitespace-nowrap">
              {{ i18n.reports_start_date }}
            </p>
            <app-picker-calendar
            class="block w-full"
            [i18n]="i18n"
            [currentLanguage]="currentLanguage"
            [editMode]="true"
            [initialDate]="initialDate"
            [Form]="certificateForm"
            [inputNameControlPicker]="'iniDate'"
            [inputControlNameMask]="'calendarStartDate'"
            [position]="'absolute'"
          ></app-picker-calendar>
          </div>
          <div class="w-full flex flex-col md:flex-row md:items-center gap-2 md:gap-4">
            <p class="whitespace-nowrap">
              {{ i18n.reports_end_date }}
            </p>
            <app-picker-calendar
            class="block w-full"
            [i18n]="i18n"
            [currentLanguage]="currentLanguage"
            [editMode]="true"
            [initialDate]="initialDate"
            [Form]="certificateForm"
            [inputNameControlPicker]="'finDate'"
            [inputControlNameMask]="'calendarEndDate'"
            [position]="'absolute'"
            ></app-picker-calendar>
            <!-- [minDateValidation]="startDate?.value == '' || startDate?.value == null ? initialDate : startDate?.value" -->
          </div>
      </div>
      <div class="export-container-certificate gap-y-6 pb-4 pl-4">
        <div class="flex flex-row items-center gap-2 w-2/4">
          <div class="exportTxt">
            <p>{{ i18n.reports_export }}</p>
          </div>
          <div class="button-export" (click)="getFile(2, 'xlsx')">
            <p>XLSX</p>
          </div>
          <div class="button-export" (click)="getFile(3, 'csv')">
            <p>CSV</p>
          </div>
          <div class="button-export" (click)="getFile(1, 'pdf')">
            <p>PDF</p>
          </div>
          <div class="button-export" (click)="getUrl(4, 'gsheet')" *ngIf="isNotStandalone">
            <p>{{i18n.reports_google_sheets}}</p>
          </div>
        </div>

        <div class="xl:mx-4 flex flex-col w-3/6 lg:flex-row gap-x-6 gap-y-2 lg:gap-y-6 items-start">
          <div class="order-cert">
            <p>{{ i18n.reports_order_by }}</p>
            <select
              class="select-reports"
              maxlength="42"
              formControlName="sortBy"
            >
              <option disabled hidden [value]="''">
                {{ i18n.reports_type }}
              </option>
              <option
                *ngFor="let by of sortBySelect"
                [value]="by.value"
                [ngValue]="by.value"
              >
                {{ by.title }}
              </option>
            </select>
            <select
              class="select-reports"
              maxlength="42"
              formControlName="sortType"
            >
              <option disabled hidden [value]="''">
                {{ i18n.reports_order }}
              </option>
              <option
                *ngFor="let type of sortTypeSelect"
                [value]="type.value"
                [ngValue]="type.value"
              >
                {{ type.title }}
              </option>
            </select>
          </div>

          <button class="button-filter" (click)="doQuery()">
            {{ i18n.reports_filter }}
          </button>
        </div>
      </div>
    </form>
    <div class="scroll-container-y" *ngIf="!loading">
      <div class="scroll-container-x">
        <div class="flex flex-row h-full w-full gap-4">
          <div class="title-col">
            <div class="header-title pl-14">
              <p>{{ i18n.reports_student }}</p>
            </div>
            <div
              class="title-text-container"
              *ngFor="let certificate of certificatesData"
            >
              <div style="width: 23px; height: 23px">
                <app-user-frame
                  [user_name]="certificate.user_name"
                  [img_src]="certificate.user_image"
                  [adaptable_size]="true"
                ></app-user-frame>
              </div>
              <p>{{ certificate.user_name }}</p>
            </div>
          </div>
          <div class="submitted-col">
            <div class="header-title">
              <p>{{ i18n.reports_issue_date }}</p>
            </div>
            <div
              class="element-text-container"
              *ngFor="let certificate of certificatesData"
            >
              <div
              *ngIf="certificate.certificate_emitted_at != null"
              pTooltip="{{ certificate.user_email }}"
              tooltipPosition="top"
              >
               <!--  <div *ngIf="currentLanguage == 'pt-BR'">
                  {{ certificate.certificate_emitted_at | date: "dd/MM/yyyy" }}
                </div>
                <div *ngIf="currentLanguage == 'en'">
                  {{ certificate.certificate_emitted_at | date: "MM/dd/yyyy" }}
                </div>
                <div *ngIf="currentLanguage == 'es'">
                  {{ certificate.certificate_emitted_at | date: "dd/MM/yyyy" }}
                </div> -->
                {{ certificate.certificate_emitted_at }}
              </div>
              <!-- {{ certificate.certificate_emitted_at }} -->
              <div
                class="containerGray element-text-container"
                *ngIf="certificate.certificate_emitted_at == null"
              >
                <p>{{ i18n.reports_unavailable }}</p>
              </div>
            </div>
          </div>
          <div class="submitted-col">
            <div class="header-title">
              <p>Download</p>
            </div>
            <div
              class="element-text-container"
              *ngFor="let certificate of certificatesData"
            >
              <a
                class="download__icon"
                target="_blank"
                [href]="certificate.certificate_link"
                *ngIf="certificate.certificate_emitted_at != null"
                style="cursor: pointer"
              >
              </a>
              <div
                class="containerGray"
                *ngIf="certificate.certificate_emitted_at == null"
              >
                <p>{{ i18n.reports_unavailable }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-container" *ngIf="loading">
      <div class="loading">
        <img src="../../../../../../../../assets/svg/loading.svg" />
        <p>{{ i18n.reports_wait }}</p>
        <p>{{ i18n.reports_we_are_loading }}</p>
      </div>
    </div>
  </div>
</div>
