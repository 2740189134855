import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContentQuizService {
  constructor(private http: HttpClient) {}

  saveStudentQuestionAnswer(params) {
    const contentExternalId = params.contentExternalId;
    return this.http.post(
      `${API_Routes.URL}/quiz/${contentExternalId}/save`,
      params
    );
  }

  startQuiz(params) {
    return this.http.post(
      `${API_Routes.URL}/quiz/${params.contentExternalId}/start`,
      {}
    );
  }

  finishQuiz(params) {
    return this.http.post(
      `${API_Routes.URL}/quiz/${params.contentExternalId}/finish`,
      {}
    );
  }

  getAnswerAndFeedback(contentExternalId) {
    return this.http.get(
      `${API_Routes.URL}/quiz/${contentExternalId}/feedback`,
      {}
    );
  }

  correctionQuiz(params, contentExternalId): Observable<any> {
    return this.http.patch<any>(
      `${API_Routes.URL}/quiz/${contentExternalId}/review`,
      params
    );
  }

  correctionUploadQuiz(params, contentExternalId): Observable<any> {
    return this.http.patch<any>(
      `${API_Routes.URL}/quiz/${contentExternalId}/review_uploadquiz`,
      params
    );
  }
}
