<section class="bg-customWhite-default shadow-xl rounded-lg flex flex-col">
  <header class="flex items-center justify-between pl-4 pr-3 py-1.5 border-b border-customBlue-superLight">
    <div class="flex items-center gap-2">
      <p class="font-medium text-customBlue-default">{{i18n.reports_grades}}</p>
      <button id="tooltip" class="w-5 h-5">
        <div class="circle-interrogation-icon interrogation__icon"></div>
        <span id="tooltipText">{{i18n.reports_interroga_grades}}</span>
      </button>
    </div>
    <button type="button" class="text-customBlue-default text-14 p-1" (click)="showBiggerScreen()"
      *ngIf="isGradesEmpty()">
      {{i18n.reports_view}}
    </button>
  </header>
  <div *ngIf="isGradesEmpty()" class="p-4 grid grid-cols-2 gap-2 text-customGray-dark overflow-y-auto">
    <div>
      <p class="font-bold mb-3">{{i18n.reports_student}}</p>
      <ul class="flex flex-col gap-2.5">
        <li class="flex items-center gap-2" *ngFor="let student of studentList">
          <app-user-frame [user_name]="student.user_name" [img_src]="student.user_image" [adaptable_size]="true"
            [style]="{'width':'23px', 'height':'23px', 'font-size': '15px', 'font-weight': 'bold'}"></app-user-frame>
          <p class="truncate">{{student.user_name}}</p>
        </li>
      </ul>
    </div>
    <ul class="w-full flex justify-between gap-2 overflow-x-auto">
      <li *ngFor="let period of periodList">
        <p class="font-bold mb-3 whitespace-nowrap">{{ period.period_name }}</p>
        <ul class="flex flex-col gap-2.5">
          <li *ngFor="let student of period.students">
            <p class="text-center">{{student.period_average}}</p>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <app-empty-data *ngIf="!isGradesEmpty()" class="flex justify-center items-center h-full p-4"></app-empty-data>
</section>