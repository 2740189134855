import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';

@Component({
    selector: 'app-file-sharing-modal',
    templateUrl: './file-sharing-modal.component.html',
    styleUrls: ['./file-sharing-modal.component.scss']
})
export class FileSharingModalComponent implements OnChanges {
    @Input() isModalOpen = false;
    @Input() i18n;

    @Output() callPostGoogleFiles = new EventEmitter<string>();
    @Output() sentIsModalOpen = new EventEmitter<boolean>();

    platform = false;
    externalDrive = false;

    constructor(
        private disciplineDetailService: DisciplineDetailService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.isModalOpen && this.isModalOpen) {
                this.disciplineDetailService.disciplineBasic$
                    .subscribe({
                        next: (disciplineDetail) => {
                            if (disciplineDetail?.external_drive_default) {
                                this.selectedFileSharing('isExternal');
                            }

                            if (disciplineDetail?.platform_default) {
                                this.selectedFileSharing('isPlatform');
                            }
                        }
                    });
            }
        }
    }

    closeModal() {
        this.isModalOpen = false;
        this.sentIsModalOpen.emit(this.isModalOpen);
    }

    selectedFileSharing(checkbox: any) {
        if (checkbox === 'isPlatform') {
            this.platform = !this.platform;
            this.externalDrive = false;
        }

        if (checkbox === 'isExternal') {
            this.externalDrive = !this.externalDrive;
            this.platform = false;
        }
    }

    isValid() {
        return this.platform || this.externalDrive;
    }

    saveFileSharing() {
        let flowMethod = '';
        if (this.platform) {
            flowMethod = 'platform';
        }

        if (this.externalDrive) {
            flowMethod = 'external_drive';
        }
        this.callPostGoogleFiles.emit(flowMethod);
        this.isModalOpen = false;
    }
}
