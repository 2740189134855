
<ng-container *ngIf="showIframe && !isSumbission_out_date">
    
    <div class="relative md:pt-12 md:pb-8 md:px-24 bg-customWhite-default rounded-md">
        <div 
            *ngIf="user.role_type !== 'student'"
            class="absolute"
            style="top:-50px; left: 0"
        >
            <div class="flex gap-2">
                <button class="px-6 py-3 font-medium text-customWhite-default bg-customBlue-default border border-customBlue-default rounded-t-md">
                    {{ i18n.disciplines_view_form }}
                </button>
        
                <button 
                    class="px-6 py-3 font-medium text-customBlue-default bg-customWhite-default border border-customBlue-default rounded-t-md"
                    (click)="answersUrl()"
                >
                    {{ i18n.disciplines_view_answers }}
                </button>
        
                <button 
                    *ngIf="getDataFromContentView?.results_url !== null"
                    (click)="sheetsAnswersUrl()"
                    class="flex gap-2 px-6 py-3 font-medium text-customGreen-lime bg-customWhite-default border border-customGreen-lime rounded-t-md"
                >
                    <div>
                        <span class="iconify w-6 h-6" style="color: #0f9d58" data-icon="simple-icons:googlesheets"></span>
                    </div>
                    <div>
                        {{ i18n.disciplines_view_sheet_answers }}
                    </div>
                </button>
            </div>
        </div>

        <iframe
            #iframeQuiz
            (load)="onLoadForm()"
            style="position: relative; min-height: 100vh; width: 100%"
            [src]="srcIframe | safeUrl"
            allowfullscreen
        ></iframe>
    </div>
</ng-container>

<ng-container *ngIf="isSumbission_out_date">
    <div class="flex justify-center items-center my-36 font-bold">
        <span class="text-customGray-default">
            {{i18n.disciplines_text_submission_out_date}}
        </span>
    </div>
</ng-container>