<div class="card pb-6">
  <div class="text-center">
    <img src="../../../../assets/png/bigTopImage.png" class="img-card" />
      <div class="user-info">
        <div class="wrapper-user-frame">
          <div style="width: 64px; height: 64px;">
            <app-user-frame
              [img_src]="user?.image"
              [user_name]="user?.name"
              [adaptable_size]="true"
            >
            </app-user-frame>
          </div>
          </div>
        <div class="wrapper-user-infos ">
          <h2 class="user-info-title">{{user.name}}</h2>
          <h3 class="user-info-name">
            {{user.role.name}}
          </h3>
          <div class="user-email" title="{{user.email}}"><p>{{user.email}}</p></div>
        </div>
      </div>
  </div>
</div>
