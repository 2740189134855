import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownTemplateComponent } from './countdown.component';
import { CountdownModule } from 'ngx-countdown';



@NgModule({
  declarations: [
    CountdownTemplateComponent,    
  ],
  exports: [
    CountdownTemplateComponent
  ],  
  imports: [
    CommonModule,
    CountdownModule
    
  ]
})
export class CountdownComponentModule { }
