
<section class="w-full h-full  py-8">
    <div class="lg:flex sm:grid gap-12">
        <div class="w-1/3 my-8 py-8 px-4 shadow-card round-card bg-customWhite-default" style="width: 295px; height:max-content;">
            <a *appCheckPermissions="'disciplines_settings'; type:'list'" (click)="changeTab('discipline-settings-data')">
                <div class="text-left text-customGray-dark  px-4 py-2 bg-card rounded-lg" [ngClass]="{'bg-card': selected === 'discipline-settings-data'}">
                    <h2 class="my-2">{{i18n.disciplines_settings_general_class_data}}</h2>
                </div>
            </a>
            <ng-container *ngIf="hasBothFlowFileSharingMethods">
                <div *appCheckUrlToHide="'disciplines-models'">
                    <a *appCheckPermissions="'disciplines_settings'; type:'list'" (click)="changeTab('discipline-settings-file-sharing')">
                        <div class="text-left text-customGray-dark  px-4 py-2 rounded-lg " [ngClass]="{'bg-card': selected === 'discipline-settings-file-sharing'}" >
                            <h2 class="my-2">{{ i18n.disciplines_settings_file_sharing }}</h2>
                        </div>
                    </a>
                </div>
            </ng-container>
            <div *appCheckUrlToHide="'disciplines-models'">
                <a *appCheckPermissions="'disciplines_settings'; type:'list'" (click)="changeTab('discipline-settings-visibility')">
                    <div class="text-left text-customGray-dark  px-4 py-2 rounded-lg " [ngClass]="{'bg-card': selected === 'discipline-settings-visibility'}" >
                        <h2 class="my-2">{{i18n.disciplines_settings_general_class_visibility}}</h2>
                    </div>
                </a>
            </div>
            <a *appCheckPermissions="'disciplines_settings'; type:'list'" (click)="changeTab('discipline-settings-details')">
                <div class="text-left text-customGray-dark  px-4 py-2 rounded-lg "  [ngClass]="{'bg-card': selected === 'discipline-settings-details'}">
                    <h2 class="my-2">{{i18n.disciplines_settings_general_class_details}}</h2>
                </div>
            </a>

            <div *appCheckUrlToHide="'disciplines-models'">
                <a *appCheckPermissions="'disciplines_settings'; type:'list'" (click)="changeTab('discipline-settings-tab')">
                    <div class="text-left text-customGray-dark  px-4 py-2 rounded-lg" [ngClass]="{'bg-card': selected === 'discipline-settings-tab'}">
                        <h2 class="my-2">{{ i18n.disciplines_settings_tab_configuration }}</h2>
                    </div>
                </a>
            </div>

            <div *appCheckUrlToHide="'disciplines-models'">
                <a *appCheckPermissions="'disciplines_settings'; type:'list'" (click)="changeTab('discipline-settings-participants')">
                    <div class="text-left text-customGray-dark  px-4 py-2 rounded-lg" [ngClass]="{'bg-card': selected === 'discipline-settings-participants'}">
                        <h2 class="my-2">{{i18n.disciplines_settings_participants}}</h2>
                    </div>
                </a>
             </div>
            
            <!-- Issue 1137 -> Removed certificates  -->
            <div *ngIf="isNotStandalone">
                <div *appCheckUrlToHide="'disciplines-models'">
                    <a *appCheckPermissions="'disciplines_settings'; type:'list'" (click)="changeTab('discipline-settings-certificates')">
                        <div class="text-left text-customGray-dark  px-4 py-2 rounded-lg" [ngClass]="{'bg-card': selected === 'discipline-settings-certificates'}">
                            <h2 class="my-2">{{i18n.disciplines_settings_general_certificates}}</h2>
                        </div>
                    </a>
                </div>
            </div>
            <!--  <a (click)="changeTab('discipline-settings-certificates')">
                <div class="text-left text-customGray-dark  px-4 py-2 rounded-lg" [ngClass]="{'bg-card': selected === 'discipline-settings-certificates'}">
                    <h2 class="my-2">{{i18n.disciplines_settings_general_certificates}}</h2>
                </div>
            </a> -->
            <div>
                <a *appCheckPermissions="'disciplines_settings'; type:'list'" (click)="changeTab('discipline-settings-general')">
                    <div class="text-left text-customGray-dark  px-4 py-2 rounded-lg" [ngClass]="{'bg-card': selected === 'discipline-settings-general'}">
                        <h2 class="my-2">{{i18n.disciplines_settings_general}}</h2>
                    </div>
                </a>
             </div>
            <div *appCheckUrlToHide="'disciplines-models'">
                <a *appCheckPermissions="'disciplines_settings'; type:'list'" (click)="changeTab('discipline-settings-grades')">
                    <div class="text-left text-customGray-dark  px-4 py-2 rounded-lg" [ngClass]="{'bg-card': selected === 'discipline-settings-grades'}">
                        <h2 class="my-2">{{i18n.grades_grades}}</h2>
                    </div>
                </a>
            </div>
        </div>
        <div class="lg:w-3/5">
            <router-outlet></router-outlet>
        </div>
    </div>
</section>
