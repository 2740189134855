import { NgModule } from '@angular/core';
import { MessageModalComponent } from './message-modal.component';
import { GenericModalModule } from '../generic-modal/generic-modal.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    MessageModalComponent
  ],
  exports: [
    MessageModalComponent
  ],
  imports: [
    CommonModule,
    GenericModalModule
  ]
})

export class MessageModalModule { }
