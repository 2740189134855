import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/pages/login/login.service';
import { TranslateServiceLocale } from 'src/app/components/translate/translate.service';
import { SharedService } from 'src/app/shared/shared.service';
import { finalize } from 'rxjs/operators';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

//variables
show = false
cont = false
loginForm: UntypedFormGroup;
token: UntypedFormGroup
isLoggedin: boolean = false;
title = 'msal-angular-tutorial';
isIframe = false;
loginDisplay = false;
options
gUser
mUser
apiResp
error
userHasToken: boolean = false;;
hasError: boolean = false;
displayBasic: boolean;
textError: string;
errorMessage: string;
redirectToDiscipline: boolean = false;
isLoading: boolean = false;
support_email: string;
hasTokenPartner: boolean = false;

googleProvider: any;
passwordProvider: any;
microsoftProvider: any;

isTranslationsUploaded$ = this.loginservice.isTranslationsUploaded$;
loading: boolean = true;
i18n: any = {};

allowedProviders: { password: boolean, google: boolean, microsoft: boolean, sso: boolean } = { password: false, google: false, microsoft: false, sso: false };

    currentLanguage: string | null;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private loginservice: LoginService,
    private translateServiceLocale: TranslateServiceLocale,
    private sharedService: SharedService,
		public platModalService: PlatformModalsService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.getTokenStatus();
    this.firstLoad();
    this.getTranslations();
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

    getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('Login') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

  getTokenStatus(){
    const route = this.route.snapshot.queryParams;
    
    if (route.hasOwnProperty('token_partner')){
      this.hasTokenPartner = true;
      localStorage.removeItem('token');
    }
    if (this.sharedService.fnUserHasToken() && this.sharedService.fnUserHasValidToken()) {
      this.userHasToken = true;
    }
    console.log('this.userHasToken ', this.userHasToken);
    
  }

  getTranslationsFile(){
    const uri = localStorage.getItem('gcsInfraPath');
    const selectedLanguage = this.sharedService.getSelectedLanguage();

    this.translateServiceLocale.getTranslationsFile(uri, selectedLanguage).subscribe({
      next: file => {
        if (!file.length) {
          this.localStorageService.checkVersion()
          // throw new Error(`Translations file empty; selectedLanguage ${selectedLanguage}; uri ${uri}`);
        }

        localStorage.setItem('translations', JSON.stringify(file));   
        if (!this.hasTokenPartner){
          this.loginservice.isTranslationsUploaded$.next(true);
        }

        this.loginEdebe();
      },
      error:(error)=>{
        if(error.status == 404){
          this.sharedService.throwError('Cannot load translations file');
        }
      }
    })
  }

  // Primeiro load da aplicação
  firstLoad(){
    // Se o user tiver token ativo não faz o firstLoad();
    if(this.userHasToken == true){
      this.sharedService.loggedIn.next(true);
      
      // Verifica se veio de algum redirecionamento redirectUrl
      let route = localStorage.getItem('returnUrl') || null;
      
      if (route !== null && route !== '' && route !== undefined) {
        this.router.navigateByUrl(route);
        this.sharedService.deleteKeyLocalStorage('returnUrl')
        return;
      }
      
      // Confere se há opção de redirecionamento para Disciplina
      this.redirectToDiscipline = localStorage.getItem('redirect_option') === 'true' ? true : false; 
      if(this.redirectToDiscipline){
        this.router.navigate(['disciplines']);
      }else{
        this.router.navigate(['dashboard']);
      }
    }else{
      // this.options = true;
      // Faz primeiro load da aplicação
      this.loginservice.whoami()
      .pipe(
        finalize(()=>{
          this.getTranslationsFile();
        })
      )
      .subscribe({
        next: (response) => {
          this.options = response
          
          // set configs padrão para serem usados na plataforma
          this.redirectToDiscipline = this.options.authentication.redirect_to_discipline;
          this.support_email = this.options.authentication.support_email;
          localStorage.setItem('redirect_option', this.options.authentication.redirect_to_discipline);
          localStorage.setItem('allowedProviders', JSON.stringify(this.options.authentication.allowedProviders));
          localStorage.setItem('provider', response.storage.provider);
          localStorage.setItem('languages', JSON.stringify(response.translation.languages));
          localStorage.setItem('gcsInfraPath', (response.storage.gcsInfraPath));
          localStorage.setItem('main_logo', (response.customization.main_logo));
          localStorage.setItem('advanced', JSON.stringify(response.advanced));
          
          if (response.authentication.allowedProviders.length) {
            this.loginservice.update_localstorage.next(response.authentication);

            response.authentication.allowedProviders.forEach(element => {
              this.allowedProviders[element.external_id] = true;
            });
          }

          if (response?.customization) {
            const settingsGeneral = response?.customization;
            localStorage.setItem('site_metadata', JSON.stringify({ settingsGeneral }));
          }
          this.applyCustomization();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      })
    }
  }

  loginEdebe(){
    let route = this.route.snapshot.queryParams;
    
    if (route.hasOwnProperty('token_partner')){
      let token = route.token_partner;

      localStorage.setItem('token',token);
      this.loginservice.refreshTokenStandalone().subscribe({
        next:(response)=>{
          this.sharedService.storeUserCredentials(response);
          this.router.navigate(['dashboard']);
        },
      })
    }
  }

  applyCustomization(){
    if(this.options.customization.site_title){
      document.title = this.options.customization.site_title;
    }
    if(this.options.customization.favicon){
      const link = document.querySelector("link[rel~='icon']");
      if(link) link['href'] = this.options.customization.favicon.public_url;
    }
    if(this.options.advanced.login_screen_script){
      this.insertHTMLFromString(this.options.advanced.login_screen_script);
    }
    

    // TODO verificar se Vlibras está habilitado pelo whoami (options)
    // if (this.options.vlibras) {
      this.loginservice.hasVLibrasActivated$.next(true);
      
    // };

    // TODO verificar se UserWay está habilitado pelo whoami (options)
    // if (this.options.UserWay) {
      this.loginservice.hasUserWayActivated$.next(true);
      
    // }

    // this.sharedService.setFavicon();
  }

  insertHTMLFromString(str) {
    const div = document.createElement('div');
    div.innerHTML = str.trim();
    for(let child of div.children){
      const item = document.createElement(child.nodeName.toString());
      for(let attr of child.attributes){
        item[attr.name] = attr.value;
      }
      child.childNodes.forEach((tmp) => {
        item.appendChild(tmp);
      })
      document.head.appendChild(item);
    }
  }

  insertGoogleAnalytics(code) {
    let item = document.createElement('script');
    item.src = 'https://www.googletagmanager.com/gtag/js?id=' + code;
    item.async = true;
    document.head.appendChild(item);
    item = document.createElement('script');
    let strAnalytics = "window.dataLayer = window.dataLayer || [];";
    strAnalytics += "function gtag(){dataLayer.push(arguments);}";
    strAnalytics += "gtag('js', new Date());";
    strAnalytics += "gtag('config', '" + code + "');";
    item.appendChild(document.createTextNode(strAnalytics));
    document.head.appendChild(item);
  }
}


