import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-balls-effect',
  templateUrl: './loading-balls-effect.component.html',
  styleUrls: ['./loading-balls-effect.component.scss']
})
export class LoadingBallsEffectComponent implements OnInit {
  @Input() color: string = '#233674';

  constructor() { }

  ngOnInit(): void {
  }

}
