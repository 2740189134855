import { SharedService } from 'src/app/shared/shared.service';
import { Level } from './../../../../section.service';
import {
  Criterion,
  SectionService,
} from 'src/app/pages/disciplines/components/content/section.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-create-rubrica',
  templateUrl: './create-rubrica.component.html',
  styleUrls: ['./create-rubrica.component.scss'],
})
export class CreateRubricaComponent implements OnInit {
  listCriterion: Criterion[] = [];
  params: Params;
  maxPointsParams: number = 0;
  maxPoints: number = 0;
  rubrica_points_input: number = 0;
  i18n: any = {};
  validationPositive: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sectionService: SectionService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.getParams();
    this.returnListCriterionMock();
    this.changeMaxPoints();
  }

  getTranslations() {
    this.i18n = this.sharedService.getTranslationsOf('Disciplines');
  }

  getParams(): void {
    this.route.params.subscribe((params: Params) => {
      this.params = params;
      console.log('this.params ', this.params);
      

      if (params.calculation_type === 'sum') {
        this.maxPointsParams = parseFloat(params.weight) | 0;
        this.maxPoints = parseFloat(params.weight) | 0;
      } else {
        this.rubrica_points_input = parseFloat(params.weight) | 0;
        this.maxPoints = this.rubrica_points_input;
      }
    });
  }

  addCriterion(): void {
    this.listCriterion.push({
      name: '',
      order: 0,
      deleted: false,
      levels: [
        {
          name: '',
          description: '',
          points: 0,
          order: 0,
          deleted: false,
        },
      ],
    });
  }

  addLevel(indexCriterion: number): void {
    this.listCriterion[indexCriterion].levels.push({
      name: '',
      description: '',
      points: 0,
      order: 0,
      deleted: false,
    });
  }

  deleteLevel(indexCriterion: number, indexLevels: number): void {
    this.listCriterion[indexCriterion].levels.splice(indexLevels, 1);
    if (this.listCriterion[indexCriterion].levels.length === 0) {
      this.listCriterion.splice(indexCriterion, 1);
    }
    this.changeMaxPoints();
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      // Order position
      this.listCriterion.map((el, index: number) => {
        el.order = index + 1;
        el.levels.map((e, i: number) => {
          e.order = i + 1;
        });
      });

      if (this.listCriterion.filter((e) => e.deleted === undefined)) {
        this.listCriterion.map((e) => {
          e['deleted'] = false;
          if (e.levels.filter((el) => el.deleted === undefined)) {
            e.levels.map((el) => (el['deleted'] = false));
          }
        });
      }
      this.sectionService.criterions = this.listCriterion;
      this.backward();
    }
    return;
  }

  backward(): void {
    this.route.params.subscribe((params: Params) => {
      let queryParams = {
        section: params.section,
        section_title: params.section_title,
        type: params?.type,
      };

      if (params?.edit === '1') {
        (queryParams['content'] = params.content),
          (queryParams['edit'] = params?.edit);
      }

      this.router.navigate(['../assignment', queryParams], {
        relativeTo: this.route,
      });
    });
  }

  // Loads an empty criterion, when it is the user's first time
  returnListCriterionMock(): void {
    if (!this.sectionService.criterions.length) {
      this.listCriterion.push({
        name: '',
        order: 0,
        deleted: false,
        levels: [
          {
            name: '',
            description: '',
            points: 0,
            order: 0,
            deleted: false,
          },
        ],
      });
    } else {
      this.listCriterion = this.sectionService.criterions;
    }
  }

  // Updates the points in the weighted average
  changeWeightedAverage(): void {
    this.maxPoints = this.rubrica_points_input;
  }

  // Changes NaN to 0 when the user deletes the Input number
  removeNaN() {
    this.listCriterion.forEach((e) => {
      e.levels.forEach((el) => {
        if (el.points === null || el.points === undefined) {
          el.points = 0;
        }
      });
    });
  }

  // Get the highest score for each criterion
  returnValuesPoints() {
    let maxLevelObj: Level = {} as Level;
    let sumPoints: number = 0;

    this.listCriterion.forEach((e) => {
      maxLevelObj = e.levels.reduce((prev, current) => {
        return prev.points > current.points ? prev : current;
      });
      sumPoints += parseFloat(maxLevelObj.points as unknown as string);
    });

    return sumPoints;
  }

  // Validates the maximum amount of characters in points and grade input
  validationCaracter(value: number, input) {
    if (value === null) return;

    let inputValue = value.toString();
    let maxPoints = this.maxPoints.toString();

    if (inputValue.includes('-')) {
      input.value = 0;
      this.maxPoints = 0;
    }

    if (inputValue.length > 4) {
      input.value = parseFloat(inputValue.slice(0, 4));
      this.maxPoints = parseFloat(maxPoints.slice(0, 4));
    }
  }

  // Subtract values ​​and validate
  changeMaxPoints(): void {
    this.removeNaN();
    this.getParams();

    let valuePositiveOrNegative = Math.sign(
      this.maxPoints - this.returnValuesPoints()
    );
    this.validationPositive = valuePositiveOrNegative;

    if (valuePositiveOrNegative !== -1) {
      if (this.params.calculation_type === 'sum') {
        this.maxPoints = this.maxPointsParams - this.returnValuesPoints();
      } else {
        this.maxPoints = this.rubrica_points_input - this.returnValuesPoints();
      }
    }
  }
}
