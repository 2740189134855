import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { SharedService } from 'src/app/shared/shared.service';
import { EditQuestionComponent } from './components/edit-question/edit-question.component';
import { QuestionBankService } from './question-bank.service';
interface Filter {
  id: string;
  translation: string;
  active: boolean;
}

@Component({
  selector: 'app-question-bank',
  templateUrl: './question-bank.component.html',
  styleUrls: ['./question-bank.component.scss'],
})
export class QuestionBankComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('pipeDropdown') pipeDropdown: ElementRef;
  @ViewChild('tagSugestion') tagSugestion: ElementRef;
  @ViewChild('searchInputElement') searchInputElement: ElementRef;
  @ViewChild(PaginationComponent) pagination: PaginationComponent | any;

  tagFilterStylization = {
    borderWidth: 2,
    closed: {
      borderColor: '#f4f4f4',
      textColor: '#aaafb4',
      iconColor: '#aaafb4',
    },
    expanded: {
      borderColor: '#233674',
      textColor: '#233674',
    },
  };

  //Dropdowns controls
  pipeDropdownExpanded: boolean = false;
  tagsSugestionExpanded: boolean = false;
  pipeDropdownKey: symbol;
  tagsSugestionKey: symbol;

  //Visibility of the modals
  createQuestionVisibility: boolean = false;
  editQuestionVisibility: boolean = false;

  //Inputs
  searchTextInput: string = '';
  searchTagsInput: string = '';

  //Other variables
  questions: any[] = ['']; //Do not remove that string! -> [''] If you remove the ViewChild will return 'undefined'
  lastTimeoutId;
  disciplineExternalId: string;
  activePage: number = 1;
  paginationParams: any;
  sortingType: string = 'desc';
  FiltersOn: boolean = false;
  editInputParams: any;
  emptyBank: boolean = true;
  noQuestionsFound: boolean = true;
  bankInitiated: boolean = false;
  i18n: any;

  allTags: any[] = ['']; //Do not remove that string! -> [''] If you remove the ViewChild will return 'undefined', the tags autoclose will not work anymore
  sugestionTags: any[] = [];
  selectedTags: any[] = [];

  types: Filter[];

  dates: any[];

  constructor(
    private shared: SharedService,
    private questionBank: QuestionBankService
  ) {}

  ngOnInit(): void {
    this.setTranslations();
  }

  ngAfterViewInit(): void {
    this.getDisciplineExternalId();
    this.getQuestions();
    this.listenEnterKeydown();
    this.initDOM();

    fromEvent(this.searchInputElement.nativeElement, 'keydown')
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.activePage = 1;
        this.refreshQuestionsWithoutScroll();
      });
  }

  setTranslations() {
    const translationModuleName = 'QuestionBank';
    this.shared.setI18n(this, translationModuleName);

    this.types = [
      {
        id: 'SCALE',
        translation: this.i18n.question_bank_scale,
        active: false,
      },
      { id: 'LIST', translation: this.i18n.question_bank_list, active: false },
      {
        id: 'TEXT',
        translation: this.i18n.question_bank_short_answer,
        active: false,
      },
      {
        id: 'PARAGRAPH_TEXT',
        translation: this.i18n.question_bank_paragraph,
        active: false,
      },
      {
        id: 'MULTIPLE_CHOICE',
        translation: this.i18n.question_bank_multiple_choice,
        active: false,
      },
      {
        id: 'CHECKBOX',
        translation: this.i18n.question_bank_checkbox,
        active: false,
      },
      { id: 'GRID', translation: this.i18n.question_bank_grid, active: false },
      {
        id: 'CHECKBOX_GRID',
        translation: this.i18n.question_bank_checkbox_grid,
        active: false,
      },
    ];

    this.dates = [
      { id: 'desc', translation: this.i18n.question_bank_newer },
      { id: 'asc', translation: this.i18n.question_bank_older },
    ];

    console.log(this.i18n)
  }

  getDisciplineExternalId() {
    this.disciplineExternalId =
      sessionStorage.getItem('discipline_external_id') || '';
  }

  initDOM() {
    const _this = this;

    const pipeFilterClassName = '.pipe-filter';
    this.pipeDropdownKey = this.shared.toCloseWhenClickOutside(
      pipeFilterClassName,
      this.pipeDropdown,
      { _this, propertyToBeToggled: 'pipeDropdownExpanded' }
    );

    const tagSugestionClassName = '.search-tags';
    this.tagsSugestionKey = this.shared.toCloseWhenClickOutside(
      tagSugestionClassName,
      this.tagSugestion,
      { _this, propertyToBeToggled: 'tagsSugestionExpanded' }
    );
  }

  toggleDropdown(type: string) {
    const dropdownState = this[`${type}DropdownExpanded`];

    this[`${type}DropdownExpanded`] = !dropdownState;
  }

  toggleCreateQuestion() {
    this.createQuestionVisibility = !this.createQuestionVisibility;
  }

  toggleEditQuestion(
    questionData = {},
    questionComponentRef?: EditQuestionComponent
  ) {
    this.editInputParams = {
      allTags: this.allTags,
      ...JSON.parse(JSON.stringify(questionData)),
    };

    if (questionComponentRef !== undefined) {
      setTimeout(() => questionComponentRef.initEdition(), 0);
    }

    setTimeout(
      () => (this.editQuestionVisibility = !this.editQuestionVisibility),
      100
    );
  }

  getQuestions(restartPagination?: boolean, doNotScroll?: boolean) {
    this.bankInitiated = true;

    const getParams = {
      disciplineExternalId: this.disciplineExternalId,
      activePage: this.activePage,
      title: this.searchTextInput,
      tags: this.selectedTags.map((tag) => tag.external_id).toString(),
      sorting: this.sortingType,
      type: this.types
        .filter((type) => type.active)
        .map((type) => type.id)
        .toString(),
    };

    this.questionBank.getQuestions(getParams).subscribe({
      next: (res: any) => {
        //ATENTION: If you add a new filter or query search please add it on the verification bellow
        this.emptyBank = false;
        this.noQuestionsFound = res.questions.length === 0;

        if (this.questions[0] === '') {
          this.questions = [];
        }

        this.questions = res.questions;

        this.allTags = res.tags;
        this.generateTagsSugestions(null, true);

        if (!res.questions.length && res.total_questions) {
          //If the you deleted the last item of a page this if will make the questions section reload again but to the previous and correct page
          this.activePage -= 1;
          this.getQuestions(true);

          return;
        }

        this.paginationParams = {
          items: [...Array(res.total_questions).keys()],
          itemsPerPage: 5,
          visibleButtons: 5,
          activePage: this.activePage,
        };

        if (restartPagination) {
          this.pagination.params = this.paginationParams;
          this.pagination.initPaginationLib();
          if (doNotScroll === false || doNotScroll === undefined)
            this.scrollDown();
        }
      },
      error: () => {
        this.emptyBank = true;
        this.bankInitiated = true;
        this.noQuestionsFound = true;
        this.questions = [];
      },
    });
  }

  goToPage({ activePage }) {
    clearTimeout(this.lastTimeoutId);

    const getParams = {
      disciplineExternalId: this.disciplineExternalId,
      activePage,
      title: this.searchTextInput,
      tags: this.selectedTags.map((tag) => tag.external_id).toString(),
      sorting: this.sortingType,
      type: this.types
        .filter((type) => type.active)
        .map((type) => type.id)
        .toString(),
    };

    this.lastTimeoutId = setTimeout(() => {
      this.questionBank.getQuestions(getParams).subscribe((res: any) => {
        this.questions = res.questions;

        this.activePage = activePage;
        this.scrollDown();
      });
    }, 0);
  }

  scrollDown() {
    const $questionBank = this.pagination.__ngContext__[0];
    const $elementWithTheScroll = $questionBank.closest('.router-outlet');

    setTimeout(() => ($elementWithTheScroll.scrollTop = 100000), 0);
  }

  generateTagsSugestions($event?: any, firstLoad?: boolean) {
    if ($event) $event.stopPropagation();

    const sugestions = this.allTags.filter((originalTag) =>
      originalTag.text
        .toLocaleLowerCase()
        .includes(this.searchTagsInput.toLocaleLowerCase())
    );
    const nonRepetitiveSugestions = sugestions.filter(
      (sug) => !this.selectedTags.some((tag) => tag.text === sug.text)
    );
    this.sugestionTags = nonRepetitiveSugestions;

    if (firstLoad) return;

    this.tagsSugestionExpanded = Boolean(this.sugestionTags.length);
  }

  selectTag(tag, index: number) {
    setTimeout(() => {
      this.sugestionTags.splice(index, 1);
      this.selectedTags.push(tag);
      this.tagsSugestionExpanded = Boolean(this.sugestionTags.length);
      this.refreshQuestionsWithoutScroll();
    }, 0);
  }

  unselectTag(index: number) {
    this.selectedTags.splice(index, 1);

    this.refreshQuestionsWithoutScroll();
  }

  listenEnterKeydown() {
    let body: any;

    //For understand the enter when we search for a name
    this.searchInputElement.nativeElement.onfocus = (e) => {
      e.stopPropagation();

      body = e.target.closest('body');

      body.onkeypress = (e) => {
        e.stopPropagation();

        const pressedKey = e.key;

        if (pressedKey === 'Enter') {
          this.activePage = 1;
          const restartPagination = true;
          const doNotScroll = true;
          this.getQuestions(restartPagination, doNotScroll);
        }
      };
    };

    //For cleaning the body event
    this.searchInputElement.nativeElement.onblur = (e) => {
      e.stopPropagation();

      body.onkeypress = null;
    };
  }

  changeSorting(sortingOptions: any[]) {
    const sortingType: string = [...sortingOptions].shift().id;
    this.dates = [...sortingOptions];

    this.sortingType = sortingType;

    this.refreshQuestionsWithoutScroll();
    this.refreshFilterTracking();
  }

  refreshFilterTracking() {
    const dateModification = this.dates[0].id !== 'desc';
    const typeModification = this.types.some((type) => type.active);

    this.FiltersOn = dateModification || typeModification;
  }

  refreshQuestionsWithoutScroll() {
    const restartPagination = true;
    const doNotScroll = true;
    this.getQuestions(restartPagination, doNotScroll);
  }

  ngOnDestroy(): void {
    if (this.pipeDropdownKey)
      this.shared.toCloseWhenClickOutside(this.pipeDropdownKey);
    if (this.tagsSugestionKey)
      this.shared.toCloseWhenClickOutside(this.tagsSugestionKey);
  }
}
