import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-view-checkbox',
    templateUrl: './view-checkbox.component.html',
    styleUrls: ['./view-checkbox.component.scss']
})
export class ViewCheckboxComponent implements OnInit {
    @Input() data: any;
    @Input() viewOnly: boolean;
    @Input() correction: boolean;

    @Output() change = new EventEmitter();
    @Output() feedback = new EventEmitter();

    alternativesText: SafeHtml[];
    optionKey: string;

    constructor(
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit(): void {
        this.alternativesText = this.data.options.map((option) => this.sanitizer.bypassSecurityTrustHtml(option.text));
        this.optionKey = this.data.options[0].selected !== undefined
            ? 'selected'
            : 'correct';

        if (this.correction) {
            this.optionKey = 'selected';
            this.setCorrection();
            this.checkIfItIsCorrect();
        }
    }

    toggleAlternative(alternative) {
        console.log('this.viewOnly ', this.viewOnly);

        if (this.viewOnly) return;

        const currentAlternativeValue = alternative[this.optionKey];

        alternative[this.optionKey] = Number(!currentAlternativeValue);

        this.data.user_response = [this.data.options.map((option) => option[this.optionKey])];
        this.change.emit();
    }

    setCorrection() {
        this.data.options = this.data.options.map((option) => {
            if (option.selected === 0 && option.correct === 0) {
                option.correction = 3;
            } else if (option.selected && !option.correct) {
                option.correction = 0;
            } else {
                option.correction = 1;
            }

            return option;
        });
    }

    checkIfItIsCorrect() {
        const blankAnswer = this.data.options.every((option) => option.correction === undefined);

        if (blankAnswer) return;

        const isCorrect = !this.data.options.some((option) => option.correction === 0);

        this.feedback.emit({ isCorrect });
    }
}
