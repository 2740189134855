import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from 'src/app/pages/login/login.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { UseTermsService } from '../../use-terms.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { UsersService } from 'src/app/pages/users/users.service';

@Component({
  selector: 'app-modal-use-terms',
  templateUrl: './modal-use-terms.component.html',
  styleUrls: ['./modal-use-terms.component.scss']
})
export class ModalUseTermsComponent implements OnInit, OnDestroy {

  onDestroyOpenModal$: any;
  onDestroyAuthObject$: any;
  isVisible: boolean = false;
  isCheckedUseTerms: boolean = false;
  useTermsText: string = '';
  isTermsMandatory: boolean = false;
  useTermsPolicy: string = '';
  i18n: any = [];
  userAuth: any;
  readOnly: boolean = false;
  userName: string = '';
  user: any;
  msgAcceptedDate: string = '';

  constructor(
    private loginService: LoginService,
    private useTermsService: UseTermsService,
    private sharedService: SharedService,
    public platformModalsService: PlatformModalsService,
    private localStorageService: LocalStorageService,
    private usersService: UsersService,
  ) { }

  ngOnInit(): void {

    this.checkTranslations();
    this.isUserLoggedIn();

    this.onDestroyOpenModal$ = this.loginService.openModalUseTerms$.subscribe({
      next: (response) => {


        if (response.readOnly) {
          this.readOnly = true;
          this.isCheckedUseTerms = true;
          this.getDateAcepted();
        } else {
          this.readOnly = false;
        }

        if (response.openModal) {
          this.getUseTerms();
        }

      },
      error: () => {

      }
    })
    this.onDestroyAuthObject$ = this.loginService.authObject$.subscribe({
      next: (response: any) => {
        this.userAuth = response;
      }
    })
  }


  checkTranslations() {
    let interval = setInterval(() => {
      if (localStorage.getItem('translations') !== null) {
        clearInterval(interval);
        this.getTranslations();
      }
    }, 200);
  }

  getTranslations() {
    this.i18n = this.i18n = { ...this.sharedService.getTranslationsOf('Errors'), ...this.sharedService.getTranslationsOf('UseTerms') }
  }

  userLoggedIn: boolean;
  isUserLoggedIn() {
    this.sharedService.isLoggedIn.subscribe({
      next: (response) => {
        this.userLoggedIn = response;

      }
    })
  }

  getUseTerms() {
    if (this.userLoggedIn) {
      this.useTermsService.getUseTermsWithToken()
        .subscribe({
          next: (response) => {
            this.useTermsText = response.terms_use;
            this.isTermsMandatory = response.terms_mandatory;
            this.useTermsPolicy = response.terms_policy;
            //Se o termos de uso for mandatório, o usuário precisará clicar no botão Li e Concordo para habilitar o botão
            //Se não, o botão já fica habilitado de padrão
            if (!this.isTermsMandatory) {
              this.isCheckedUseTerms = true;
            }
            this.isVisible = true;
          },
          error: () => {

          }
        })
    } else {

      this.useTermsService.getUseTermsNoToken()
        .subscribe({
          next: (response) => {
            this.useTermsText = response.terms_use;
            this.isTermsMandatory = response.terms_mandatory;
            this.useTermsPolicy = response.terms_policy;
            //Se o termos de uso for mandatório, o usuário precisará clicar no botão Li e Concordo para habilitar o botão
            //Se não, o botão já fica habilitado de padrão
            if (!this.isTermsMandatory) {
              this.isCheckedUseTerms = true;
            }
            this.isVisible = true;

          },
          error: () => {

          }
        })
    }
  }

  continueToPolicyAndPrivacy() {
    this.loginService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: this.readOnly, terms_policy: this.useTermsPolicy, terms_mandatory: this.isTermsMandatory });
    this.closeModal();
  }

  acceptPolicyAndPrivacy() {
    const options = {
      token: this.userAuth.token
    }
    this.useTermsService.acceptPolicyAndPrivacy(options)
      .subscribe({
        next: (response) => {
          this.acceptUseTerms();
          this.sharedService.loggedIn.next(true);
          this.sharedService.storeUserCredentials(this.userAuth);
          this.loginService.setGoogleCredencials(this.userAuth);
          this.loginService.isRedirectRoute();
        },
        error: (err) => {
          this.platformModalsService.toggle('message', this.i18n.error_system_error, 'close');
        }
      })

  }

  acceptUseTerms() {
    const options = {
      token: this.userAuth.token
    }
    this.useTermsService.acceptUseTerms(options)
      .subscribe({
        next: (response) => {
          this.sharedService.loggedIn.next(true);
          this.sharedService.storeUserCredentials(this.userAuth);
          this.loginService.setGoogleCredencials(this.userAuth);
          this.loginService.isRedirectRoute();
        },
        error: (err) => {
          this.platformModalsService.toggle('message', this.i18n.error_system_error, 'close');
        }
      })

  }

  getDatesAccepted($externalId: any) {
    this.usersService.getUsersAcceptedTerms($externalId)
      .subscribe({
        next: (response) => {
          let currentLanguage;
          if (this.localStorageService.getLocalStorageItem('currentLanguage')) {
            currentLanguage = localStorage.getItem('currentLanguage')
          }
          if (!currentLanguage) {
            currentLanguage = 'pt-BR'
          }

          if (response?.terms_agreement_date) {
            let newDate = new Date(response?.terms_agreement_date).toLocaleDateString(currentLanguage, { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" })
            this.msgAcceptedDate = `${this.i18n.use_terms_accept} ${newDate} ${this.i18n.use_terms_by} ${this.userName}`;
          }
        }
      })
  }

  getDateAcepted() {
    this.getUser();

    if (this.user?.external_id) {
      this.getDatesAccepted(this.user.external_id);
    }
  }

  getUser() {
    this.sharedService
      .getUser()
      .subscribe((user) => {
        if (user) {
          this.userName = user['name'];
          this.user = user;
        }
      })
  }

  receiveCheckButton(event: any) {
    this.isCheckedUseTerms = event;
  }

  closeModal() {
    this.isVisible = false;
    this.isCheckedUseTerms = false;
    this.useTermsText = '';
  }

  ngOnDestroy(): void {
    if (this.onDestroyOpenModal$) this.onDestroyOpenModal$.unsubscribe();
    if (this.onDestroyAuthObject$) this.onDestroyAuthObject$.unsubscribe();
  }

}
