

<div #randomElement class="wrapper">
    <form [formGroup]="form" class="inside-dropdown">
        <div class="dropdowns">
            <app-custom-dropdown
                *ngFor="let alternative of alternatives; let index = index"
                #dropdown
                [title]="
                    questionBankService.getAlternativeName(
                    index,
                    i18n.question_bank_alternative
                    )
                "
                [marked]="alternative.correct"
            >
                <div class="p-4">
                    <app-editor [height]="300" [form]="form" [formControlName]="'title'+index" 
                    (eventEmitter)="input($event, index, dropdown)" (editorEmitter)="receiveEditor = $event"></app-editor>
                </div>

                <div class="buttons p-4">
                    <div
                        class="btn right-alt"
                        [class.is-active]="alternative.correct"
                        (click)="setCorrectAlt(index)"
                    >
                        {{ i18n.question_bank_correct_answer }}
                    </div>

                    <div
                        class="btn delete-alt"
                        (click)="delete($event, index)"
                        *ngIf="alternatives.length > 1"
                        [title]="i18n.question_bank_delete"
                    >
                        <i class="trash-icon"></i>
                    </div>
                </div>
            </app-custom-dropdown>
        </div>
    </form>
    <app-plus-button
        class="add-question-btn"
        [label]="i18n.question_bank_add_alternative"
        (click)="add(randomElement)"
        [disabled]="this.alternatives.length >= 10"
    ></app-plus-button>
</div>
