<!-- MODAL HISTÓRICO DE EDIÇÃO -->
<app-generic-modal [minWidth]="'30%'" [width]="'540px'"  [isVisible]="isModalOpen">
    <div 
        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
    >
        <!--header-->
        <div class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t">
            <div class="text-center">
                <h3 class="font-bold text-26 text-customBlue-default">{{ i18n.disciplines_file_sharing_file_menagement }}</h3>
                <p class="text-16 text-customBlue-default">{{ i18n.disciplines_file_sharing_selected_file_menagement_option }}</p>
            </div>
        </div>
        <!--body-->
        <ng-container >
            <div class="relative p-2 px-5 mb-28">
                <h3 class="text-16 font-medium text-customBlue-default mb-5">{{ i18n.disciplines_file_sharing }}</h3>
                <div class="flex flex-col md:grid md:grid-cols-2 gap-2">
                    <div 
                        (click)="selectedFileSharing('isExternal')"
                        class="cursor-pointer rounded-md p-4 flex flex-col justify-start outline-none focus:outline-none ease-linear transition-all duration-150"
                        [ngClass]="
                        externalDrive ? 'border border-customBlue-default' : 'border border-customGray-mediumGray'
                        "
                    >
                        <div class="flex justify-center h-10 my-5">
                            <span class="iconify w-10 h-10" data-icon="logos:google-drive"></span>
                        </div>

                        <div class="text-16 font-semibold text-customBlue-default text-center mb-4 h-16 px-6">
                            {{ i18n.disciplines_file_sharing_use_your_own_drive_files }}
                        </div>

                        <div class="text-12 text-customGray-dark text-center">
                            {{ i18n.disciplines_file_sharing_use_your_own_drive_files_description }}
                        </div>
                    </div>

                    <div 
                        (click)="selectedFileSharing('isPlatform')"
                        class="cursor-pointer rounded-md p-4 flex flex-col justify-start outline-none focus:outline-none ease-linear transition-all duration-150"
                        [ngClass]="
                        platform ? 'border border-customBlue-default' : 'border border-customGray-mediumGray'
                        "
                    >
                        <div class="flex justify-center h-10 my-5">
                            <span class="iconify w-10 h-10 text-customBlue-default" data-icon="material-symbols:laptop-mac-outline"></span>
                        </div>

                        <div class="text-16 font-semibold text-customBlue-default flex justify-center items-center mb-4 h-16 ">
                            {{ i18n.disciplines_file_sharing_use_copy_platform }}
                        </div>

                        <div class="text-12 text-customGray-dark text-center">
                            {{ i18n.disciplines_file_sharing_use_copy_platform_description }}
                        </div>
                    </div>
                </div>
            </div>
            <!--footer-->
            <div class="flex items-center justify-center p-6 rounded-b gap-2">
                <button 
                    class="bg-customBlue-default text-customWhite-default border border-customBlue-default rounded-md font-semibold px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150" 
                    type="button"
                    style="width: 152px;" 
                    (click)="saveFileSharing()"
                    [ngClass]="{
                        'disabled:opacity-20 cursor-not-allowed': !isValid()
                      }"
                    [disabled]="!isValid()"
                >
                    {{ i18n.disciplines_save }}
                </button>

                <button 
                    class="bg-customWhite-default text-customBlue-default border border-customBlue-default rounded-md font-semibold px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150" 
                    type="button" 
                    style="width: 152px;"
                    (click)="closeModal()"
                >
                    {{ i18n.disciplines_cancel }}
                </button>
            </div>
        </ng-container>
    </div>    
</app-generic-modal>