import { NgModule } from '@angular/core';

import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { UploadModule } from 'src/app/components/upload/upload.module';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { UserFrameModule } from 'src/app/components/user-frame/user-frame.module';

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { DisplayPanelModule } from 'src/app/components/display-panel/display-panel.module';
import { DecisionModalModule } from 'src/app/components/modals/decision-modal/decision-modal.module';
import { ModalProfileImpersonateComponent } from './components/modal-profile-impersonate/modal-profile-impersonate.component';
import { ModalVinculateDisciplineComponent } from './components/modal-vinculate-discipline/modal-vinculate-discipline.component';
import { LoadingModalModule } from 'src/app/components/modals/loading-modal/loading-modal.module';
import { PermissionModule } from 'src/app/directives/permission/permission.module';
import { UserAssiduityComponent } from './components/user-assiduity/user-assiduity.component';
import { ModalCommissionTeamComponent } from './components/modal-commission-team/modal-commission-team.component';

@NgModule({
  declarations: [
    UsersComponent,
    ModalProfileImpersonateComponent,
    ModalVinculateDisciplineComponent,
    UserAssiduityComponent,
    ModalCommissionTeamComponent
  ],
  imports: [
    UsersRoutingModule,
    GenericModalModule,
    UploadModule,
    SharedModule,
    UserFrameModule,
    DisplayPanelModule,
    DecisionModalModule,
    LoadingModalModule,
    PermissionModule,
    NgxMaskModule.forRoot(),
  ]
})
export class UsersModule { }
