import {
    AfterContentInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { ReadMode } from 'ngx-file-helpers';
import { Subscription, take } from 'rxjs';
import { ContentSharedService } from 'src/app/pages/disciplines/components/content/content-shared.service';
import { LoginService } from 'src/app/pages/login/login.service';
import { GoogleDrivePickerService } from 'src/app/services/google-drive-picker-service.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { DisplayPanelComponent } from '../display-panel/display-panel.component';
import { UploadService } from '../upload/upload.service';

@Component({
    selector: 'app-upload-inline',
    templateUrl: './upload-inline.component.html',
    styleUrls: ['./upload-inline.component.scss']
})
export class UploadInlineComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
    @ViewChild('btn_upload_google') btnUploadGoogle: ElementRef;
    @ViewChild(UploadInlineComponent) UploadInlineComponent: UploadInlineComponent;

    // inputs para configuração de visualização do componente upload
    @Input() maxFiles: number;// Número máximo de arquivos que o User pode fazer upload
    @Input() multiSelect = false;// Opção para User selecionar um arquivo por vez ou vários
    @Input() sharingStatus = false;// Opção para ser compartilhado de forma pública
    @Input() fileType: string;// Tipo do arquivo que será mostrado, se vazio busca todos
    @Input() files: any = [];// arquivos que vem do componente pai
    @Input() syncWithDrive = true;// arquivos que vem do componente pai
    @Input() assignment = '';// arquivos que vem do componente pai

    @Input() maxWidthDriveBtn = '';
  	@Input() textExplanation = '';
    @Input() showRemoveBtn = true;
    @Input() showReplaceBtn = true;
    @Input() showOpenBtn = true;
    @Input() showDate = true;
    @Input() showSendTitle = true;
    @Input() textUploadBtn = '';
    @Input() isShowUploadTab = true;
    @Input() isShowExplanation = false;
    @Input() delivery_allowed = false;
    @Input() hasModalFileSharing = false;
    @Input() isModalFileSharingOpen = false;
    @Input() flowMethod = '';
    @Input() hasFileList = true;
    @Input() showFileList: boolean;

    @Input() hideMaxFileText = false;// Opção para esconder o texto de máximo de arquivos

    // outputs dos arquivos para componente pai
    @Output() filesEvent = new EventEmitter<any[]>();// Emissor dos arquivos que foram feito upload para componente pai
  	@Output() onCloseEvent = new EventEmitter<any[]>();
  	@Output() loadedFile = new EventEmitter<boolean>();
  	@Output() sentIsModalFileSharingOpen = new EventEmitter<boolean>();
  	@Output() googlePostParams = new EventEmitter<any>();

    @Output() sendIsUploadLoading = new EventEmitter<boolean>();
    @Output() sendIsUploadLocked = new EventEmitter<boolean>();

    // consertar depois pois modais de erro estão ficando sobrepostos
    isVisible = true;

    // Variables
    googleData: any = [];
    user;
    token;
    provider: any;
    public readMode = ReadMode.DataURL;
    public picked: File;
    public status: string;
    uploadedFiles: any[] = [];
    dateFile = new Date();
    isLoaded = false;
    isPermissionDone: any;
    displayBasic = false;
    textError: string;
    isUploadLocked = false;
    select:string;
    date: Date;
    currentLanguage: any;
    isLoading = false;
    hasPermissionPicker = false;

    formData = new FormData();

    i18n: any;

    subscription: Subscription[] = [];

    constructor(
        private sharedService: SharedService,
        private googleDrivePickerService: GoogleDrivePickerService,
        private uploadService: UploadService,
        public platModalService: PlatformModalsService,
        private element: ElementRef,
        private route: ActivatedRoute,
        private contentSharedService: ContentSharedService,
        private localStorageService: LocalStorageService,
        private loginService: LoginService,
        private cd : ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        // this.files = [
        // 	{"id":912,"label":"upload 2","external_id":"4WiObcSYkYMc6ntW","file_name":"","mime_type":"application\/pdf","provider":"GOOGLE_DRIVE","path":"1wXCBMBu3SjFNrN7D6A3YMIFIyjjMt6HgLoR-E-62mSg","video_uri":"","submitted_at":"2023-03-27 23:34:35","original":null},
        // 	{"id":913,"label":"arquivo-de-desenvolvimento-proletariado-contra-industrias.jpg","external_id":"gGe5Txj7NSm9RAio","file_name":"","mime_type":"application\/vnd.google-apps.spreadsheet","provider":"GOOGLE_DRIVE","path":"1yT42ucKj9rsU-JXQSY8mrONiwIahvjCa","video_uri":"","submitted_at":"2023-03-27 11:34:37","original":null},
        // 	// {"id":914,"label":"FilePNG.png","external_id":"ZyMZHHIDUoZVvBmz","file_name":"","mime_type":"application\/vnd.google-apps.video","provider":"GOOGLE_DRIVE","path":"1RB7tN_Q_OJWlmSaEaz3tb_5qSG_isVFc","video_uri":"","submitted_at":"2023-03-27 11:34:39","original":null},
        // 	// {"id":915,"label":"FilePNG.png","external_id":"kDSJoM8xFbmBEvNi","file_name":"","mime_type":"image\/png","provider":"GOOGLE_DRIVE","path":"1yaVFh5Ab4PxhyAGPuriPGMOcr2y7QHpe","video_uri":"","submitted_at":"2023-03-27 11:34:41","original":null}
        // ]

        this.getTranslations();
        this.provider = localStorage.getItem('provider');
        this.getUser();
        this.getSyncWithDriveValue();
        this.localStorageService.checkCookiesEnabled();
        this.checkPickerTokenValidation();
        this.responseSubscriptionGoogleData();
    }

    ngAfterContentInit(): void {
        this.emitRemoveFiles();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('files')) {
            this.files == null ? this.files = [] : this.files;
            if (this.files && this.files.length) {
                this.files.forEach((file:any) => {
                    file.submitted_at = file.hasOwnProperty('created_at') ? file.created_at : file.submitted_at;
                });
                this.checkMaxFiles(this.files);
                this.cd.detectChanges();
            }
        }
    }

    getSyncWithDriveValue() {
        const subscription = this.contentSharedService.syncWithDrive$.subscribe({
            next: (value) => {
                console.log('value ', value);
                this.syncWithDrive = value;
            }
        });

        this.subscription.push(subscription);
    }

    interval;
    checkPickerTokenValidation() {
        let token: string | null = null;
        token = localStorage.getItem('googleAuthTokenPicker') || null;
        this.tokenInfoGoogle(token);

        this.interval = setInterval(() => {
            token = localStorage.getItem('googleAuthTokenPicker') || null;
            if (token !== null) {
                this.tokenInfoGoogle(token);
            }
        }, 300000);
    }

    tokenInfoGoogle(token) {
        this.loginService.tokenInfoGoogle(token).subscribe({
            next: (tokenInfo) => {
                if (!tokenInfo.scope.includes('auth/drive')) {
                    this.hasPermissionPicker = false;
                    return;
                }

                this.hasPermissionPicker = true;
                console.log('this.hasPermissionPicker ', this.hasPermissionPicker);
            },
            error: (err) => {
                this.hasPermissionPicker = false;
            }
        });
    }

    defineTypeFile() {
        return 'audio/*,video/*,image/*,application/pdf';
    }

    closeModal() {
    	this.onCloseEvent.emit(this.files);
        this.sharedService.modalClose();
        this.sharedService.removeFiles$.next({ files: this.files, isUpdated: false });
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Upload'), ...this.sharedService.getTranslationsOf('Disciplines') };
        this.currentLanguage = localStorage.getItem('currentLanguage' || '');

        if (this.provider == 'GOOGLE_DRIVE') {
            this.textUploadBtn == '' ? this.i18n.upload_from_google_drive : this.textUploadBtn;
        } else {
            this.textUploadBtn == '' ? this.i18n.upload_upload_it : this.textUploadBtn;
        }
    }

    // Functions
    getUser() {
        const data: any = localStorage.getItem('token');
        this.token = data;
        this.user = jwt_decode(data);
    }

    offset = 0;
    chunkSize = 1000000;
    countLoop: any = 1;

    countFiles = 0;
    totalFiles = 0;
    filesReceived: any = [];
    progressBar = 0;
    progressValue;
    progress;
    isUploadingFiles = false;
    isProcessingFile = false;

    calculateProgressBar(part, totalPart) {
        this.progressBar = Math.ceil(part * 100 / totalPart);
    }

    setValue(value) {
        this.progressValue.style.width = `${value}%`;
        this.progress.value = value;
    }

    fileSelected(event) {
        this.isUploadingFiles = true;
        this.filesReceived = Array.from(event.target.files);
        this.totalFiles = this.filesReceived.length;
        if (this.totalFiles > this.maxFiles) {
            this.sharedService.modalClose();
            this.platModalService.toggle('message', this.i18n.upload_selected_files_number_exceeds, 'close');
            return;
        }
        this.fileSetup(this.filesReceived[this.countFiles]);
    }

    fileSetup(file) {
        console.log('file ', file);

        // Tamanho do arquivo maior que 8MB
        if (file.size > 8000000) {
            this.chunkSize = 8000000;
        }
        if (this.countFiles < this.totalFiles) {
            this.sendChunkPart(this.offset, file);
        }
    }

    sendChunkPart(offset, file) {
        const totalPart: any = Math.ceil(file.size / this.chunkSize) == 0 ? 1 : Math.ceil(file.size / this.chunkSize);

        if (offset < file.size) {
            if (offset + this.chunkSize >= file.size) {
                this.chunkSize = file.size - offset;
                this.isProcessingFile = true;
            }
            const chunk = file.slice(offset, offset + this.chunkSize);
            this.formData = new FormData();
            this.formData.append('file[]', chunk, file.name);
            this.formData.append('mime', file.type);
            this.formData.append('part', this.countLoop.toString());
            this.formData.append('totalPart', totalPart.toString());
            this.postFiles(this.formData, file);
            this.calculateProgressBar(this.countLoop, totalPart);
        }
    }

    postFiles(files: any, file) {
        this.uploadService.postFile(files).subscribe({
            next: (response:any) => {
                this.offset += this.chunkSize;

                this.countLoop++;

                if (this.offset < file.size) {
                    this.sendChunkPart(this.offset, file);
                } else {
                    response.forEach((item:any) => {
                        this.files.push(item);
                    });
                    this.countFiles++;

                    if (this.countFiles < this.totalFiles) {
                        this.formData = new FormData();
                        this.offset = 0;
                        this.countLoop = 1;
                        this.isProcessingFile = false;
                        this.fileSetup(this.filesReceived[this.countFiles]);
                    } else {
                        this.resetPostFileVariables();
                        this.checkMaxFiles(this.files);
                        this.isUploadingFiles = false;
                        this.isProcessingFile = false;
                        this.filesEvent.emit(this.files);
                    }
                }
            },
            error: (err) => {
                if (err.status == 500 || err.status == 413) {
                    setTimeout(() => {
                        this.postFiles(files, file);
                    }, 10000);
                }
            }
        });
    }

    resetPostFileVariables() {
        this.formData = new FormData();
        this.offset = 0;
        this.countLoop = 1;
        this.countFiles = 0;
        this.totalFiles = 0;
        this.isProcessingFile = false;
    }

    replaceFileGCS(fileGCS) {
        this.isUploadingFiles = true;
        const fileToReplace = this.files.indexOf(fileGCS.file);

        if (fileToReplace > -1) {
            this.files.splice(fileToReplace, 1);
            this.fileSelected(fileGCS.event);
        }
    }

    showCookieFail() {
        this.isVisible = false;
        this.platModalService.toggle('message', this.i18n.upload_content_help_cookies_blocked, 'close');
    }

    setFileSelection(): string {
        return this.multiSelect ? 'multiple' : 'single';
    }

    // Requests Provider GOOGLE_DRIVE when not has permission to Drive
    callAddScopeAuthGoogle() {
        this.sharedService.checkIfApiKeyExists();
        if (!localStorage.getItem('apiKey')) {
            return;
        }
        this.googleDrivePickerService.callAddScopeAuthGoogle((tokenPicker) => {
            this.isLoading = true;
            this.sendIsUploadLoading.emit(this.isLoading);
            if (tokenPicker) {
                this.hasPermissionPicker = true;

                this.googleDrivePickerService.setPicker(this.isShowUploadTab, this.setFileSelection(), this.fileType, tokenPicker, this.reponseGoogleData);
            }
        });
    }

    // Requests Provider GOOGLE_DRIVE when has permission to Drive
    openGoogleDrivePicker() {
        this.sharedService.checkIfApiKeyExists();
        if (!localStorage.getItem('apiKey')) {
            return;
        }
        this.isLoading = true;
        this.sendIsUploadLoading.emit(this.isLoading);

        this.googleDrivePickerService.open(this.isShowUploadTab, this.setFileSelection(), this.fileType, this.reponseGoogleData);
    }

    reponseGoogleData = (data) => {
        if (data.action === 'picked') {
            this.googleData = data.docs;
            this.callInsertPermission(this.googleData);
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
        }

        if (data.action === 'cancel') {
            this.isLoading = false;
            this.sendIsUploadLoading.emit(this.isLoading);
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
        }
    };

    responseSubscriptionGoogleData() {
        const subscription = this.googleDrivePickerService.callbackReponse$.subscribe({
            next: (response) => {
                if (!this.sharedService.isCookiesEnabled()) {
                    this.showCookieFail();
                }

                if (response.hasOwnProperty('error')) {
                    if (response.details == 'Cookies are not enabled in current environment.') {
                        this.isVisible = false;
                        this.platModalService.getModalState('message').pipe(take(1)).subscribe({
                            next: (state) => {
                                if (state == false) {
                                    this.platModalService.toggle('message', this.i18n.upload_content_help_cookies_blocked, 'close');
                                }
                            }
                        });
                    }

                    if (response.error.errCode == 400) {
                        if (response.error.error == 'invalid_grant') {
                            // this.sharedService.modalClose();
                            this.isVisible = false;

                            this.platModalService.toggle('message', this.i18n.upload_content_google_invalid_grant, {
                                finally: () => {
                                    this.platModalService.close('message');
                                    this.sharedService.modalClose();
									 // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/dot-notation
									 const redrectTo = this.route.snapshot['_routerState'].url;

                                    this.sharedService.logOut(redrectTo);
                                    this.googleDrivePickerService.callbackReponse$.next({});
                                }
                            });
                        }
                    }
                }
            }
        });

        this.subscription.push(subscription);
    }

    countFilesInsertedPermission = 0;
    async callInsertPermission(files: any) {
        const el: any = document.querySelector('app-content-task-upload');
        if (el !== null) {
            el.click();
        }

        const files_size = files.length;
        await this.googleDrivePickerService.insertPermission(files[this.countFilesInsertedPermission].id, (response) => {
            if (response.status == 200) {
                if (this.countFilesInsertedPermission < files_size - 1) {
                    this.countFilesInsertedPermission++;
                    this.callInsertPermission(files);
                } else {
                    this.uploadFile(files);
                    this.countFilesInsertedPermission = 0;
                }
            } else {
                let error = '';
                switch (response.status) {
                    case 401:
				        error = 'upload_error_unauthorized';
                        break;
                    case 403:
					  error = 'uplaod_error_permission';
					    break;
                    case 404:
					  error = 'uplaod_error_file_not_found';
					    break;
                    default:
					  error = 'upload_error';
					    break;
                }

                this.isLoading = false;
                this.sendIsUploadLoading.emit(this.isLoading);
                this.closeModal();
                this.platModalService.toggle('message', this.i18n[error], 'close');

                // Send errors to Error reporting
                let message_errors = '';
                if (response.hasOwnProperty('result')) {
                    if (response.result.hasOwnProperty('error')) {
                        if (response.result.error.errors.length) {
                            response.result.error.errors.forEach((element) => {
                                element.file_id = files[this.countFilesInsertedPermission].id;
                                message_errors += `${JSON.stringify(element)} `;
                            });
                        }
                    }

                    throw new Error(message_errors);
                }
            }
        });
    }

    replaceGoogleData = (data) => {
        const fileToReplace = this.files.findIndex((file) => file.external_id === this.fileToReplace.external_id);

        if (fileToReplace === -1) {
            this.isLoading = false;
            this.sendIsUploadLoading.emit(this.isLoading);
            this.googleData = [];
            this.platModalService.toggle('message', this.i18n.upload_error_replace_file, 'close');
            return;
        }

        if (fileToReplace > -1 && data.action === 'picked') {
            this.files.splice(fileToReplace, 1);
            this.googleData = data.docs;
            this.callInsertPermission(this.googleData);
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
        }

        if (data.action === 'cancel') {
            this.isLoading = false;
            this.sendIsUploadLoading.emit(this.isLoading);
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
        }
    };

    fileToReplace;
    replaceFile(file:any) {
        this.fileToReplace = file;
        this.isLoading = true;
        this.sendIsUploadLoading.emit(this.isLoading);
        this.googleDrivePickerService.open(this.isShowUploadTab, 'single', this.fileType, this.replaceGoogleData);
    }

    checkMaxFiles(files: any) {
        if (files !== null) {
            if (files.length >= this.maxFiles) {
                this.isUploadLocked = true;
            } else {
                this.isUploadLocked = false;
            }

            this.sendIsUploadLocked.emit(this.isUploadLocked);

            if (files.length > 0) {
                this.isLoaded = true;
                this.sharedService.isLoadedUploadTrue();
            } else {
                this.isLoaded = false;
                this.sharedService.isLoadedUploadfalse();
            }
        }
    }

    decisionTypePlatModalService: any = null;
    removeFile(file:any) {
        this.platModalService.toggle('decision', 'deletion_subject_generic', {
            forward: () => {
                if (this.files.length >= 1) {
                    const fileToRemove = this.files.indexOf(file);

                    if (fileToRemove > -1) {
                        this.files.splice(fileToRemove, 1);
                        this.sharedService.removeFiles$.next({ files: this.files, isUpdated: true });
                    }

                    this.checkMaxFiles(this.files);
                }
            },
            finally: () => {
                this.platModalService.close('decision');
            },
        });
    }

    emitRemoveFiles() {
        const subscription = this.sharedService.removeFiles$.subscribe((item: any) => {
            if (item?.isUpdated) {
                this.filesEvent.emit(item?.files);
            }
        });

        this.subscription.push(subscription);
    }

    uploadFile(googleData: any) {
        const countFiles = this.files.length;
        const countNewFiles = googleData.length;

        if (countFiles + countNewFiles > this.maxFiles) {
            this.googleData = [];
            this.isLoading = false;
            this.sendIsUploadLoading.emit(this.isLoading);
            this.sharedService.modalClose();
            this.platModalService.toggle('message', this.i18n.upload_selected_files_number_exceeds, 'close');
        } else {
            const googleFiles: any = [];
            for (let i = 0; i < googleData.length; i++) {
                const element = googleData[i];
                googleFiles.push(element.id);
            }
            let params: any = {};

            if (this.sharingStatus) {
                params = {
                    public: true,
                    provider: 'GOOGLE_DRIVE',
                    file: googleFiles,
                };
            } else {
                params = {
                    provider: 'GOOGLE_DRIVE',
                    file: googleFiles,
                };
            }

            if (!this.syncWithDrive) {
                params.syncWithDrive = this.syncWithDrive;
            }

            if (this.hasModalFileSharing) {
                this.sentIsModalFileSharingOpen.emit(true);
                this.googlePostParams.emit(params);
                return;
            }

            if (this.flowMethod === '') {
                this.flowMethod = 'platform';
            }
            params.flow_method = this.flowMethod;
            this.postGoogleFiles(params);
        }
    }

    postGoogleFiles(params: any) {
        this.uploadService.uploadFile(params).subscribe({
            next: (response:any) => {
                response.forEach((item:any) => {
                    this.files.push(item);
                });
                this.checkMaxFiles(this.files);
                this.files.forEach((file:any) => {
                    file.submitted_at = file.hasOwnProperty('created_at') ? file.created_at : file.submitted_at;
                });
                this.isLoading = false;
                this.sendIsUploadLoading.emit(this.isLoading);

                this.filesEvent.emit(this.files);
                this.googleData = [];
                this.countFilesInsertedPermission = 0;
            },
            error: (err) => {
                this.googleData = [];
                this.countFilesInsertedPermission = 0;
                this.isLoading = false;
                this.sendIsUploadLoading.emit(this.isLoading);
                if (err.status === 403) {
                    this.platModalService.toggle('message', this.i18n.upload_no_permission, 'close');
                } else {
                    this.platModalService.toggle('message', err.error.error, 'close');
                }
            }
        });
    }

    @ViewChild('displayPanelWarning') displayPanelWarning! : DisplayPanelComponent;
    displayPanelObject: any = {};
    getLocationToDisplayPanel($event, file: any) {
        const windowWidth = window.innerWidth;

        const top = $event.clientY + 10;
        const left = $event.clientX + 8;
        this.displayPanelObject.top = `${top}px`;
        this.displayPanelObject.left = `${left}px`;
        if (windowWidth < 1065) {
            this.displayPanelObject.left = '';
            this.displayPanelObject.right = '0px';
        }
        this.displayPanelWarning.toggle($event);
        this.textFlowMethodWarning = this.returnMessageFlowMethod(file);
    }

    textFlowMethodWarning = '';
    returnMessageFlowMethod(file: any) {
        // if file removed return file removed message
        if (file.trashed) {
            return this.i18n.disciplines_file_sharing_warning_file_removed;
        }
        return file.flow_method === 'platform'
            ? this.i18n.disciplines_file_sharing_warning_flow_method_platform
            : this.i18n.disciplines_file_sharing_warning_flow_method_external_drive;
    }

    ngOnDestroy() {
        this.files = [];
        this.filesEvent.emit(this.files);
        this.onCloseEvent.emit(this.files);
        this.sharedService.removeFiles$.next({ files: this.files, isUpdated: false });

        if (this.subscription.length) {
            this.subscription.forEach((element) => {
                element.unsubscribe();
            });
        }

        if (this.interval) {
            clearInterval(this.interval);
        }
    }
}
