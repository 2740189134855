<div class="resume">
  <h2 class="title" *ngIf="false">Resumo da Atividade</h2>

  <!-- RITGHS AND WRONGS (APPEARS JUST ON QUESTION TYPE) -->
  <div class="rights-wrongs" *ngIf="false">
    <div class="rights-wrongs rights">
      <i class="rights-wrongs__icon circle-x-outline-icon"></i>
      3 {{ i18n.disciplines_error_message}}
    </div>

    <div class="rights-wrongs wrongs">
      <i class="rights-wrongs__icon check-outline-icon"></i>
      2 {{ i18n.disciplines_error_message}}
    </div>
  </div>

  <div class="score-btn">
    <div class="score" *ngIf="false">
      {{ i18n.disciplines_content_submissions_final_score }}:
      <input type="number" step="0.05" max="10" min="0" value="0"
      class="score__input">
    </div>
    <!-- <div class="decision-btns">
      <div class="btn backward" (click)="getGoogleFile()">{{ i18n.disciplines_download_file }}</div>
      <div class="btn forward" *ngIf="false">{{ i18n.disciplines_save }}</div>
    </div> -->

    <div
        class="mt-8 bg-customWhite-default border border-customBlue-glass rounded-md text-customBlue-default w-full"
      >
        <header
          class="flex items-center justify-center gap-2 text-19 font-bold p-4"
          role="button"
          (click)="toggleCollapseOption()"
          [ngClass]="{ 'border-b border-customBlue-glass': collapseOption }"
        >
          <p>{{ i18n.disciplines_add_files }}</p>
          <div
            class="transform transition-all"
            [ngClass]="{
              'rotate-90': !collapseOption,
              'rotate--90': collapseOption
            }"
          >
            <span
              class="iconify-inline text-16"
              data-icon="bxs:right-arrow"
            ></span>
          </div>
        </header>
        <div class="overflow-hidden">
          <div [@collapse]="collapseOption">
            <ul class="p-4 flex flex-col gap-4">
              <li
                class="px-4 py-2 flex items-center justify-between gap-2 rounded shadow font-medium"
                
                *ngFor="
                  let file of files;
                  let i = index
                "
              >
                <button
                  type="button"
                  class="flex items-center gap-4 py-1"
                  (click)="changeFile(i)"
                >
                  <app-content-types-icons
                    [type]="returnIconMaterialsSupport(file.mime_type)"
                    [color]="'#233674'"
                  ></app-content-types-icons>
                  <p>{{ file.label }}</p>
                </button>
                <button
                  type="button"
                  class="flex items-center gap-2 px-2 py-1 font-medium border-l border-customBlue-light"
                  (click)="getGoogleFile(file)"
                >
                  <p>{{ i18n.disciplines_content_view_download }}</p>
                  <span
                    class="iconify-inline text-22"
                    data-icon="ic:sharp-cloud-download"
                  ></span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
  </div>
</div>
