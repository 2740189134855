<div *ngIf="isVisible">
  <form
    action="#"
    method="POST"
    [formGroup]="userForm"
    (keyup.enter)="authUser()"
  >
    <div class="flex flex-col">
      <label class="text-customGray-swampGray text-16">E-mail</label>
      <input
        type="email"
        name=""
        id=""
        [placeholder]="i18n.login_type_your_email"
        class="input-login font-OpenSans"
        autofocus
        autocomplete
        required
        formControlName="email"
      />
    </div>

    <div class="flex flex-col mt-4">
      <label class="text-customGray-swampGray text-16">{{
        i18n.login_password
      }}</label>
      <div class="flex font-OpenSans">
        <input
          type="password"
          name=""
          id="pass"
          [placeholder]="i18n.login_type_your_pass"
          class="input-login w-full"
          autofocus
          autocomplete
          required
          formControlName="password"
        />
        <span
          class="cursor-pointer"
          (click)="hiddenPass()"
          (click)="isHiddenPass = !isHiddenPass"
        >
          <div *ngIf="!isHiddenPass" class="relative">
            <span
              class="iconify eye-icon text-customGray-iconGray"
              data-icon="ph:eye"
            ></span>
          </div>
          <div *ngIf="isHiddenPass" class="relative">
            <span
              class="iconify eye-icon text-customGray-iconGray"
              data-icon="ph:eye-slash"
            ></span>
          </div>
        </span>
      </div>
    </div>

    <div class="flex justify-end mt-2">
      <a
        class="text-customGray-default font-medium text-12 font-OpenSans cursor-pointer"
        (click)="openModalResetPassword()"
        >{{ i18n.login_forgot_password }}</a
      >
    </div>
    <ng-container *ngIf="!isLoading">
        <button
          (click)="authUser()"
          type="button"
          class="bg-customBlue-default text-customGray-lighterGray font-medium px-4 py-3 mt-4 rounded-md shadow-lg block mx-auto w-full max-width-form"
        >
          <div class="flex justify-center items-center gap-6">
            <span>{{ i18n.login_signin }}</span>
          </div>
        </button>
    </ng-container>

    <ng-container *ngIf="isLoading">
        <app-bullets-loader class="py-5 mt-4"></app-bullets-loader>
    </ng-container>
  </form>
</div>
