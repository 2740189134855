import { createFeature, createReducer, on } from '@ngrx/store';

import * as HomeworkSubmissionsActions from './homework-submissions.actions';

export interface HomeworkSubmissionsState {
  activeCourseWork: any;
  courseWorkFileIndex: number;
  activeTab: string | undefined;
  loading: boolean;
  contentName: string | undefined;
  contentDescription: string | undefined;
  contentID: string | undefined;
  contentType: string | undefined;
  deadline: string | undefined;
  concludedSubmissions: Array<any>;
  submissions: Array<any>;
  summary: Object;
};

const initalState: HomeworkSubmissionsState = {
  activeCourseWork: undefined,
  courseWorkFileIndex: 0,
  activeTab: undefined,
  loading: true,
  contentName: undefined,
  contentDescription: undefined,
  contentID: undefined,
  contentType: undefined,
  deadline: undefined,
  concludedSubmissions: [],
  submissions: [],
  summary: {},
};

export const homeworkSubmissionsFeature = createFeature({
  name: 'homeworkSubmissions',
  reducer: createReducer(
    initalState,
    on(HomeworkSubmissionsActions.setActiveTab, (state: HomeworkSubmissionsState, { activeTab }) => ({
      ...state,
      activeTab
    })),
    on(HomeworkSubmissionsActions.setLoading, (state: HomeworkSubmissionsState, { loading }) => ({
      ...state,
      loading
    })),on(HomeworkSubmissionsActions.updateCourseWorkFileIndex, (state: HomeworkSubmissionsState, { courseWorkFileIndex }) => ({
      ...state,
      courseWorkFileIndex
    })),
    on(HomeworkSubmissionsActions.updateActiveCourseWork, (state: HomeworkSubmissionsState, { activeCourseWork }) => ({
      ...state,
      courseWorkFileIndex: activeCourseWork.course_work.id === state.activeCourseWork.course_work.id
        ? state.courseWorkFileIndex
        : 0,
      activeCourseWork
    })),
    on(HomeworkSubmissionsActions.updateSubmissions, (state: HomeworkSubmissionsState, submissions) => {
      const { type, ...rest } = submissions;

      return {
        ...state,
      ...rest
      }
    }),
    on(HomeworkSubmissionsActions.setOverallData, (state: HomeworkSubmissionsState, data: any) => {
      const { type, ...rest } = data;

      return {
        ...state,
        ...rest
      }
    }),
  )
});

export const {
  name,
  reducer,
  selectHomeworkSubmissionsState,
  selectActiveTab,
  selectLoading,
  selectContentName,
  selectContentDescription,
  selectContentID,
  selectContentType,
  selectDeadline,
  selectConcludedSubmissions,
  selectSubmissions,
  selectSummary,
  selectActiveCourseWork,
  selectCourseWorkFileIndex,
} = homeworkSubmissionsFeature;
