import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';
import { ChromeDashboardResponse, canShowChromeExtensionDashboard } from './IChromeDashboardResponse';

@Injectable({
  providedIn: 'root',
})
export class ChromeExtensionServiceService {
  constructor(private http: HttpClient) {}

  getReports(page = 1): Observable<ChromeDashboardResponse> {
    return this.http.get<ChromeDashboardResponse>(
      `${API_Routes.URL}/devices/dashboard?page=${page}`
    );
  }

  canShowChromeExtensionDashboard(): Observable<canShowChromeExtensionDashboard> {
    return this.http.get<canShowChromeExtensionDashboard>(
      `${API_Routes.URL}/devices/dashboard/show_chrome_extension_dashboard`
    );
  }
}
