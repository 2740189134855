<section class="discipline-details transition ease-out duration-180 animate-fade-in-down">
    <h1 class="discipline-details__title">{{i18n.disciplines_settings_details_class}}</h1>
    <ul class="discipline-details__line">
      <li class="discipline-details__line__item-img">
          <img
            lazyLoad="{{cover_imageChanged == true ? defaultImage : settingsDetail?.cover_image?.url}}"
            defaultImage="{{cover_imageChanged == true ? defaultImage : errorImage}}"
            errorImage="{{errorImage}}"
          >
      </li>
      <div class="discipline-details__line__item-text">
        <div class="item-text__title">{{i18n.disciplines_settings_details_cover_image_label}}</div>
        <p>1200px x 185px</p>
      </div>
      <li class="discipline-details__line__item-spacing"></li>
      <li class="discipline-details__line__item-upload">
        <button
          (click)="openUploadContent('cover_image')"
          pTooltip=" {{i18n.disciplines_settings_details_send_image}}"
          tooltipPosition="top"
          pRipple
        >
          <span
            class="iconify ico-color"
            data-icon="ant-design:cloud-upload-outlined"
            data-width="28"
            data-height="28"
          ></span>
        </button>
      </li>
    </ul>
    <ul class="discipline-details__line">
      <li class="discipline-details__line__item-img">
        <img
          lazyLoad="{{card_imageChanged == true ? defaultImage : settingsDetail?.card_image?.url}}"
          defaultImage="{{card_imageChanged == true ? defaultImage : errorImage}}"
          errorImage="{{errorImage}}"
        >
      </li>
      <div class="discipline-details__line__item-text">
        <div class="item-text__title">{{i18n.disciplines_settings_details_card_image_label}}</div>
        <p>288px x 144px</p>
      </div>
      <li class="discipline-details__line__item-spacing"></li>
      <li class="discipline-details__line__item-upload">
        <button
          (click)="openUploadContent('card_image')"
          pTooltip="{{i18n.disciplines_settings_details_send_image}}"
          tooltipPosition="top"
          pRipple
        >
          <span
            class="iconify ico-color"
            data-icon="ant-design:cloud-upload-outlined"
            data-width="28"
            data-height="28"
          ></span>
        </button>
      </li>
    </ul>
    <ul class="discipline-details__line" *appCheckUrlToHide="'disciplines-models'">
      <li class="discipline-details__line__item-img">
        <img
          lazyLoad="{{round_imageChanged == true ? defaultImage : settingsDetail?.round_image?.url}}"
          defaultImage="{{round_imageChanged == true ? defaultImage : errorImage}}"
          errorImage="{{errorImage}}"
        >
      </li>
      <div class="discipline-details__line__item-text">
        <div class="item-text__title">{{i18n.disciplines_settings_details_feed_card_image_label}}</div>
        <p>150px x 150px</p>
      </div>
      <li class="discipline-details__line__item-spacing"></li>
      <li class="discipline-details__line__item-upload">
        <button
          (click)="openUploadContent('round_image')"
          pTooltip=" {{i18n.disciplines_settings_details_send_image}}"
          tooltipPosition="top"
          pRipple
        >
          <span
            class="iconify ico-color"
            data-icon="ant-design:cloud-upload-outlined"
            data-width="28"
            data-height="28"
          ></span>
        </button>
      </li>
    </ul>
  <div class="discipline-details__footer"></div>
</section>

<app-upload
*ngIf="modalOpen$ | async"
[sharingStatus]="true"
[maxFiles]="maxFiles"
[files]="files"
[multiSelect]="false"
[fileType]="'photo'"
(filesEvent)="receiveFiles($event)"
></app-upload>

<p-confirmDialog #cd [style]="{width: '25vw'}" [transitionOptions]="'100ms'">
  <ng-template pTemplate="footer" class="text-center">
    <button
    type="button"
    pButton
    icon="pi pi-check"
    label="Ok"
    (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog>

<p-dialog header="Erro" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{textError}}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayBasic=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
