import { Injectable } from '@angular/core';
import { LoginService } from '../pages/login/login.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';
import { PlatformModalsService } from './modals/platform-modals.service';
import { SharedService } from '../shared/shared.service';


declare const gapi: any;
declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleChatService {
  private scope = 'https://www.googleapis.com/auth/chat.spaces';
  public clientId = '';
  private tokenClient: any = {};
  private users: string[];
  i18n: any = {};

  constructor(
    private loginService: LoginService, 
    private http: HttpClient, 
    private platformModalsService: PlatformModalsService,
    private sharedService: SharedService) {
    this.clientId = JSON.parse(localStorage.getItem('clientId') || 'null');
    this.i18n = this.sharedService.getTranslationsOf('Disciplines');
  }

  getRequestCode() {
    this.tokenClient.requestCode();
  }

  postGoogleChat(params: GoogleChat): Observable<ResponseGoogleChat> {
    return this.http.post<ResponseGoogleChat>(
      `${API_Routes.URL}/googlechat/init`,
      params
    );
  }

  requestGoogleChat(token: string) {
    this.platformModalsService.toggle('loading');
    this.postGoogleChat({ code: token, users: this.users }).subscribe({
      next: (chat) => {
        this.platformModalsService.toggle('loading');
        window.open(chat.google_chat_url, '_blank');
      },
      error: (err) => {
        this.platformModalsService.toggle('loading');
        let message: string = this.i18n.disciplines_have_an_error;

        if (err.status === 404) {
          message = this.i18n.disciplines_error_email_not_found;
        }
        
        this.platformModalsService.toggle('message', message, 'close');
      }
    });
  }

  requestChatScope(users: string[]): void {
    this.users = users;
    this.getGoogleAuthTokenPicker();
    this.onAuthApiLoad();
    gapi.load("client");
  }

  checkScopeDrive(token): boolean {
    return token.scope.includes('chat.spaces') ? true : false;
  }

  checkTokenValid(token) {
    this.loginService.tokenInfoGoogle(token).subscribe({
      next: (tokenInfo) => {
        if (this.checkScopeDrive(tokenInfo)) {
            this.requestGoogleChat(token);
        } else {
            this.authRefreshToken(token);
        }
      },
      error: () => {
        this.authRefreshToken(token);
      },
    });
  }

  getGoogleAuthTokenPicker() {
    if (localStorage.getItem('googleAuthTokenPicker') !== null) {
      let googleAuthTokenPicker = JSON.parse(
        localStorage.getItem('googleAuthTokenPicker') || ''
      );
      if (
        googleAuthTokenPicker !== null &&
        googleAuthTokenPicker !== undefined &&
        googleAuthTokenPicker !== ''
      ) {
        this.checkTokenValid(googleAuthTokenPicker);
      }
    } else {
      this.authRefreshToken();
    }
  }

  authRefreshToken(token?) {
    this.loginService.refreshTokenGoogle().subscribe({
      next: (response) => {
        if (response.hasOwnProperty('token_picker')) {
          localStorage.setItem(
            'googleAuthTokenPicker',
            JSON.stringify(response.token_picker)
          );
          this.getRequestCode();
          if (token) {
              this.gapiLoad(token);
          }
        }
      },
      error: (err) => {
        console.log('CALLBACK HAS ERROR authRefreshToken', err);
      },
    });
  }

  gapiLoad(token) {
    if (gapi.hasOwnProperty('client')) {
      gapi.client.setToken({ access_token: token }); // Atualiza o novo token (permissions)
      gapi.load('client:chat');
    } else {
      gapi.load('client');
      setTimeout(() => { this.gapiLoad(token)}, 500);
    }
  }

  // Adiciona scopo do drive para o usuário google do cliente
  private onAuthApiLoad() {
    this.tokenClient = google.accounts.oauth2.initCodeClient({
      client_id: this.clientId,
      scope: this.scope,
      immediate: false,
      callback: (response) => {
        if (response.hasOwnProperty('error')) {
        } else {
          this.loginService.loginWithGoogle({ code: response.code }).subscribe({
            next: (res) => {
              this.loginService.setGoogleCredencials(res);
              this.requestGoogleChat(res.token_picker);
            },
            error: (err) => {
              if (err.status == 401) {
                response = { error: { errCode: 400, error: 'invalid_grant' } };
              }
            },
          });
        }
      },
    });
  }
}

interface GoogleChat {
  code: string;
  users: string[];
}

interface ResponseGoogleChat {
  google_chat_url: string;
}
