import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-grades',
  templateUrl: './grades.component.html',
  styleUrls: ['./grades.component.scss']
})
export class GradesComponent implements OnInit {
  @Input() reportsData: any = []
  @Output() screen = new EventEmitter();
  i18n: any = [];
  studentList: any[] = [];
  periodList: any[] = [];

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getTranslations();

    if (this.isGradesEmpty()) {
      this.studentList = this.reportsData?.['GRADES']?.periods?.at(-1).students;
      this.periodList = this.reportsData?.['GRADES']?.periods?.slice(0, 3);
    }
  }

  getTranslations() {
    this.i18n = { ...this.sharedService.getTranslationsOf('Reports'), ...this.sharedService.getTranslationsOf('Modal') };
  }

  showBiggerScreen(): void {
    this.screen.emit('large-grades');
  }

  isGradesEmpty(): boolean {
    return Array.isArray(this.reportsData?.GRADES) || !this.reportsData?.['GRADES']?.periods?.length ? false : true;
  }
}
