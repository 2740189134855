import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { first } from 'rxjs/operators';
import { UploadInlineComponent } from 'src/app/components/upload-inline/upload-inline.component';
import { ConferenceCheckerService } from 'src/app/services/conference-checker.service';
import { SharedService } from 'src/app/shared/shared.service';

export interface disciplineSectionContentID {
    discipline_id: string;
    section_id: string;
    content_id: string;
}
@Component({
    selector: 'app-conference-checker',
    templateUrl: './conference-checker.component.html',
    styleUrls: ['./conference-checker.component.scss'],
})
export class ConferenceCheckerComponent implements OnInit {
    @Input() user;
    @ViewChild('uploadInline') uploadInline: UploadInlineComponent;
    modalVisibility = false;
    modalStatus = 'checking';
    modalOpen$ = this.sharedService.modalOpen$;
    urlParams: disciplineSectionContentID;
    @Output() conferenceFound = new EventEmitter();
    text: [];
    video: [];
    displayBasic: boolean;
    textError: string;
    files: any = [];
    checker: any;
    isAddingRecord = false;
    i18n: any;
    currentLanguage: any;

    constructor(
        private conferenceCheckerService: ConferenceCheckerService,
        private sharedService: SharedService,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
    ) {
        this.checker = setInterval(() => {
        // the following is required, otherwise the view will not be updated
            this.cd.markForCheck();
        }, 75);
    }

    ngOnInit() {
        this.getTranslations();
        this.urlParams = this.extractUrlParams();
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines')
        };
        this.currentLanguage = localStorage.getItem('currentLanguage' || '');
    }

    onSelectFiles($event) {
        const files = $event.map((recording) => recording.id);
        this.conferenceCheckerService
            .addRecording({ ...this.urlParams, body: { files } })
            .subscribe(
                () => this.conferenceFound.emit($event),
                (err) => (
                    (this.displayBasic = true),
                    (this.textError = `${err.message} | ${err.error.message}`)
                )
            );
    }

    toggleModalTo(newStatus: string): void {
        if (newStatus === 'upload') {
            this.sharedService.modalOpen();
            this.modalClose();
        } else {
            this.modalStatus = newStatus;
        }
    }

    modalOpen() {
        this.toggleModalTo('checking');
        this.modalVisibility = true;
    }

    modalClose() {
        this.modalVisibility = false;
    }

    private extractUrlParams() {
        let discipline_id = '';

        this.route?.parent?.parent?.params.subscribe((params: Params) => {
            discipline_id = params.id;
        });

        const section_id = this.route.snapshot?.params?.section;
        const content_id = this.route.snapshot?.params?.id;

        return {
            discipline_id,
            section_id,
            content_id,
        };
    }

    checkVideoConference() {
        return this.conferenceCheckerService
            .getRecording(this.urlParams)
            .pipe(first())
            .subscribe({
                next: () => this.toggleModalTo('success'),
                error: (err) => {
                    if (err.status === 409) {
                        this.toggleModalTo('search');
                        return;
                    }
                    this.displayBasic = true;
                    this.textError = `${err.message} | ${err.error.message}`;
                },
            });
    }

    isPickerOpended = false;
    openPicker() {
        this.modalClose();
        this.isPickerOpended = true;
        this.uploadInline.openGoogleDrivePicker();
    }

    receiveFiles(files: any) {
        console.log('files ', files);
        this.files = files;
    }

    addRecording(files: any) {
        this.isAddingRecord = true;
        const idOfFilesFromEvent = files.map((recording) => recording.id);

        this.conferenceCheckerService.addRecording({
            ...this.urlParams,
            body: { files: idOfFilesFromEvent },
        }).subscribe({
            next: (res) => {
                this.conferenceFound.emit(files);
                this.isAddingRecord = false;
            },
            error: () => {

            }
        });
    }
}
