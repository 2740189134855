<div
	class="bg-customBlue-default rounded-lg shadow-lg pt-8 pb-6 px-8 mb-5"
>
	<div class="flex flex-col md:flex-row md:justify-between justify-center gap-4">

		<div class="flex flex-col md:flex-row md:justify-center items-center gap-4">
			<div style="width: 67px; height: 66px">
				<app-user-frame
				[img_src]="user?.image"
				[user_name]="user?.name"
				[adaptable_size]="true"
				>
				</app-user-frame>
			</div>
			<div class="flex flex-col md:justify-center">
				<h2 class="font-black text-26 text-customWhite-default">
					{{ i18n.dash_welcome_student }} {{ capitalizeFirstLetter(user.name) }}
				</h2>
				<span class="text-customWhite-default text-12 md:text-left text-center">
					{{ user?.email }}
				</span>
			</div>
		</div>

		<div *appCheckPermissions="'certifications';type:'list'" class="flex justify-center items-end">
			<button 
                [routerLink]="'../certifications'"
                class="border border-customWhite-default text-center rounded-md px-6 py-1"
            >
				<span class="text-customWhite-default text-14">
					{{ i18n.dash_my_certificates }}
				</span>
			</button>
		</div>
	</div>
</div>
