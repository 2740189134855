import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';

import { GenericModalModule } from '../modals/generic-modal/generic-modal.module';
import { UploadFilePreviewComponent } from './upload-file-preview.component';

@NgModule({
    declarations: [
        UploadFilePreviewComponent
    ],
    exports: [
        UploadFilePreviewComponent
    ],
    imports: [
        SharedModule,
        GenericModalModule
    ]
})
export class UploadFilePreviewModule { }
