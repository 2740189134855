import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ContentTaskUploadService } from './content-task-upload.service';

@Component({
    selector: 'app-content-task-upload',
    templateUrl: './content-task-upload.component.html',
    styleUrls: ['./content-task-upload.component.scss'],
})
export class ContentTaskUploadComponent implements OnInit, OnChanges, OnDestroy {
    modalOpen$ = this.sharedService.modalOpen$;
    i18n: any = {};
    currentLanguage: any;
    discipline_external_id = '';

    text_upload_button = '';
    isValid = false;
    loading = false;
    reopenModalUpload = false;
    isModalMessageOpen = false;
    isModalFileSendOpen = false;
    hasPermissionPicker = false;
    checker: any;

    subscriptions: Subscription[] = [];

    currentDate: Date = new Date();
    statusDelivery = '';
    isStateSended: boolean;

    @Input() files: any[] = [];
    @Input() content;
    @Input() fileType: any;
    @Input() maxFiles: any;
    @Input() section_external_id: string;
    @Input() content_external_id: string;
    @Input() delivery_allowed: boolean;

    @Output() reloadStatus = new EventEmitter();

    constructor(
        private sharedService: SharedService,
        private contentTaskUploadService: ContentTaskUploadService,
        private route: ActivatedRoute,
        private platModalService: PlatformModalsService,
        private cd: ChangeDetectorRef

    ) {
        this.checker = setInterval(() => {
            // the following is required, otherwise the view will not be updated
            this.cd.markForCheck();
        }, 75);
    }

    ngOnInit(): void {
        this.getTranslations();
        this.getParams();
        this.checkFilesLength();
        this.platModalService.getModalState('message').subscribe((status: any) => {
            this.isModalMessageOpen = status;
            if (!status && this.reopenModalUpload) {
                this.sharedService.modalOpen();
                this.reopenModalUpload = false;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.files?.length) {
                this.isStateSended = true;
                this.getPathFile();
            }
        }

        if (this.content && changes.content) {
            this.checkStatusDelivery();
        }
    }

    checkFilesLength() {
        this.text_upload_button = this.i18n.upload_click_to_add_file;
        if (this.files.length) {
            this.text_upload_button = this.i18n.disciplines_click_here_to_add_file;
        }
    }

    checkStatusDelivery() {
        const endDate = new Date(this.content.end_date);
        const courseworkCompletedAt = new Date(this.content.coursework_completed_at !== '' ? this.content.coursework_completed_at : null);
        if (this.content.status === 'COMPLETE') {
            if (courseworkCompletedAt > endDate) {
                this.statusDelivery = 'late_delivered';
                return;
            }

            if (courseworkCompletedAt < endDate && this.currentDate > endDate) {
                this.statusDelivery = 'on_time_delivered';
                return;
            }

            this.statusDelivery = 'open_to_deliveries';
        }
    }

    replaceFileTranslations() {
        this.content.contentSpecific.maxFiles = parseInt(this.content.contentSpecific.maxFiles, 10);
        if (this.content.contentSpecific.maxFiles > 1) {
            this.i18n.upload_description_completion_rule = this.i18n.upload_description_completion_rule.replace('#%', `${this.content.contentSpecific.maxFiles} ${this.i18n.upload_description_completion_rule_files}`);
            return;
        }

        this.i18n.upload_description_completion_rule = this.i18n.upload_description_completion_rule.replace('#%', `${this.content.contentSpecific.maxFiles} ${this.i18n.upload_description_completion_rule_file}`);
    }

    replaceDeliveryTranslations() {
        if (this.content?.completion_rule !== 'ATTEMPT_SUBMISSION') {
            this.i18n.upload_description_completion_rule = this.i18n.upload_description_completion_rule.replace('#$', '');
            return;
        }
        if (this.content?.completion_rule_value === null) {
            this.i18n.upload_description_completion_rule = this.i18n.upload_description_completion_rule.replace('#$', '');
            return;
        }
        if (this.content?.completion_rule_value > 1) {
            this.i18n.upload_description_completion_rule = this.i18n.upload_description_completion_rule.replace('#$', `${this.i18n.upload_description_completion_rule_and} ${this.content.completion_rule_value} ${this.i18n.upload_description_completion_rule_deliveries}`);
            return;
        }

        this.i18n.upload_description_completion_rule = this.i18n.upload_description_completion_rule.replace('#$', `${this.i18n.upload_description_completion_rule_and} ${this.content.completion_rule_value} ${this.i18n.upload_description_completion_rule_delivery}`);
    }

    makeNewDelivery() {
        this.files = [];
        this.isStateSended = false;
    }

    getRemainingAttempts(): number {
        let remainingAttempts = this.content.completion_rule_value - this.content.coursework_submission_attempts;

        if (Number.isNaN(remainingAttempts)) {
            remainingAttempts = 0;
        }

        return remainingAttempts;
    }

    receiveFiles(files: {}[]) {
        this.files = [];
        this.files = files;

        this.sharedService.replaceLinkFile(this.files);
        if (this.files.length > 0) {
            this.isValid = true;
        } else {
            this.isValid = false;
        }
        this.checkFilesLength();
        this.cd.detectChanges();
    }

    sendUploadTasks() {
        this.loading = true;
        const filesId: number[] = [];
        this.files.forEach((file: { id: number }) => filesId.push(file.id));

        const params = { files: filesId };

        this.contentTaskUploadService
            .postUploadAssignment(
                this.discipline_external_id,
                this.section_external_id,
                this.content_external_id,
                params
            )
            .subscribe({
                next: () => {
                    this.isValid = false;
                    this.loading = false;
                    this.isStateSended = true;
                    this.toggleModalFileSend();
                },
                error: (err) => {
                    let message: any = this.i18n.disciplines_have_an_error;

                    err?.error?.error?.error?.forEach((error: any) => {
                        switch (error) {
                            case 'Late submission not allowed':
                                message = 'Tarefa não pode ser entregue pois está fora do prazo de entrega.';
                                this.loading = false;
                                this.isValid = false;
                                break;

                            case 'Maximum number of files exceeded':
                                this.reopenModalUpload = true;
                                message = 'O número máximo de arquivos foi excedido.';
                                break;

                            default:
                                break;
                        }
                    });

                    if (err.status === 504) {
                        message = this.i18n.disciplines_req_timeout_turnin;
                    }

                    this.showModalError(message);
                    this.loading = false;

                    if (message.includes(this.i18n.disciplines_have_an_error)) throw err;
                },
            });
    }

    toggleModalFileSend(): void {
        this.platModalService.toggle(
            'message',
            {
                message: this.i18n.disciplines_upload_file_saved,
                icon_existence: true,
                custom_icon: 'check-outline-icon',
            },
            {
                finally: () => {
                    this.platModalService.close('message');
                    this.reloadStatus.emit();
                },
            }
        );
    }

    showModalError(message: string): void {
        this.platModalService.toggle(
            'message',
            {
                message,
                icon_existence: true,
                icon_color: '#F36C48',
                custom_icon: 'attention-icon',
            },
            {
                finally: () => {
                    this.platModalService.close('message');
                    location.reload();
                },
            }
        );
    }

    getParams() {
        this.route?.parent?.parent?.params?.subscribe((params) => {
            this.discipline_external_id = params.id;
        });

        // this.route?.params?.subscribe((params) => {
        //   console.log('params ', params);

        //   this.section_external_id = params.section;
        //   this.content_external_id = params.id;
        // });

        const sub1 = this.contentTaskUploadService.section_ext_id$.subscribe(({
            next: (section_ext_id) => {
                this.section_external_id = section_ext_id;
            }
        }));

        this.subscriptions.push(sub1);
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Upload') };
        this.currentLanguage = localStorage.getItem('currentLanguage' || '');
        this.replaceFileTranslations();
        this.replaceDeliveryTranslations();
    }

    openModal() {
        this.sharedService.modalOpen();
    }

    getPathFile(): void {
        this.files.forEach((file) => {
            file.path = file.public_url.split('id=')[1];
        });

        this.sharedService.replaceLinkFile(this.files);
    }

    ngOnDestroy(): void {
        clearInterval(this.checker);

        if (this.subscriptions.length) {
            this.subscriptions.forEach((element) => {
                element.unsubscribe();
            });
        }
    }
}
