<div #dropdown class="dropdown" [class.is-expanded]="dropdownExpanded" [class.block]="block" [style]="dropdownStyle" [attr.data-id]="dataId">
  <div class="item__main item tag" (click)="toggleDropdown()">
    <div class="item__text">{{ title }}</div>
  </div>

  <div class="item" *ngFor="let item of items; let i = index" [class.is-active]="item.active" (click)="toggleFilter(item)">
    <app-check-button [active]="item.active"></app-check-button>

    <div class="item__text">{{ item.translation }}</div>
  </div>

  <i class="arrow-icon dropdown__icon"></i>
</div>

<div class="dropdown underblock" [style]="dropdownStyle" *ngIf="!block">
  <div class="item__main item tag" (click)="toggleDropdown()">
    <div class="item__text">{{ title }}</div>
  </div>

  <div class="item" *ngFor="let item of items; let i = index" [class.is-active]="item.active" (click)="toggleFilter(item)">
    <app-check-button [active]="item.active"></app-check-button>

    <div class="item__text">{{ item.translation }}</div>
  </div>

  <i class="arrow-icon dropdown__icon"></i>
</div>

<!-- TWO DROPDOWNS, ONE FOR USER INTERACTION, OTHER FOR SET THE SPACE  -->
