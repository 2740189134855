import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { Router } from '@angular/router';
import { ImportSpreadsheetService } from '../../import-spreadsheet.service';

@Component({
    selector: 'app-accordion-config-sheet-connection',
    templateUrl: './accordion-config-sheet-connection.component.html',
    styleUrls: ['./accordion-config-sheet-connection.component.scss']
})
export class AccordionConfigSheetConnectionComponent {
    @Input() files: any;
    @Input() modeGenerate: string;
    @Output() emptyFile = new EventEmitter();

    accordionItems = [
        {
            id: 1,
            open: false,
        },
        {
            id: 2,
            open: false,
        },
    ];

    selectedColumns: string[] = [];

    i18n: any = [];
    currentLanguage: string | null;

    public configSheetForm: FormGroup = this.formBuilder.group({
        start_import_row: [1, [Validators.required, Validators.min(1)]],
        selected_tabs: [null, Validators.required],
    });

    columns: any[] = [];

    constructor(
        private formBuilder: FormBuilder,
        public sharedService: SharedService,
        private importSpreadsheetService: ImportSpreadsheetService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.setColumns();

        console.log('Files:', this.files);
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('ImportSpreadsheet') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    setColumns() {
        this.importSpreadsheetService
            .getSheets(this.files[0].id)
            .subscribe({
                next: (tabs: any) => {
                    tabs.map((tab: any) => this.columns
                        .push({
                            name: tab?.name,
                            value: tab?.name
                        }));
                },
                error: (err: any) => console.log(err)
            });
    }

    toggleItem(item: any): void {
        this.accordionItems.map((accordionItem: any) => {
            if (accordionItem.id !== item.id) {
                accordionItem.open = false;
            }
        });

        item.open = !item.open;
    }

    submitConfig() {
        if (!this.configSheetForm || this.configSheetForm?.invalid) {
            return;
        }

        const formData = {
            file: this.files,
            formValues: {
                ...this.configSheetForm.value,
                mode: this.modeGenerate,
                import_type: 'connections',
            },
        };

        this.importSpreadsheetService.setFormDataConfigSheet(formData);
        this.router.navigate(['/import-spreadsheet/preview']);
    }

    emptyFileEmit() {
        this.emptyFile.emit();
    }

    goBack() {
        this.emptyFile.emit();
    }
}
