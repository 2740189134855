<div class="question-box-wrapper">
  <div
    #question
    class="question-box"
    [class.is-invisible]="isInvisible"
    *ngIf="type"
    [attr.data-id]="DOMid"
  >
    <div class="question-box__header">
      <div class="header__cod" *ngIf="order === undefined">
        <i class="iconify-inline header__icon" data-icon="bx:book-bookmark"></i>
        <span>COD {{ getCOD() }}</span>
      </div>

      <div class="header__cod" *ngIf="order !== undefined">
        {{ order + 1 }}.
      </div>

      <div class="header__divisor"></div>
      <form
        #Form="ngForm"
        class="flex items-center gap-2 mr-4 text-customGray-dark text-14"
        *ngIf="order !== undefined"
      >
        <p>{{ i18n.question_bank_question_weight }}:</p>
        <input
          type="number"
          class="w-16 p-1 border rounded"
          [ngClass]="{
            'bg-customWhite-bWhite border-customBlue-glass':
              !this.controlweightQuestion,
            'border-customGray-default': this.controlweightQuestion
          }"
          [(ngModel)]="weightQuestion"
          name="weightQuestion"
          (keyup)="validateWeightQuestion()"
          [disabled]="!this.controlweightQuestion"
        />
      </form>
      <ng-container *appCheckPermissions="'quiz'; type: 'create'">
        <div
            class="header__icon pen-editing-icon"
            *ngIf="!noEdit"
            [title]="i18n.question_bank_edit"
            (click)="callEditMode()"
        ></div>
        <div
            class="header__icon trash-icon"
            *ngIf="!noDelete"
            [title]="i18n.question_bank_delete"
            (click)="toggleAskToDelete()"
        ></div>
      </ng-container>
      <div
        class="header__icon--comments"
        *ngIf="!noComment"
        [title]="i18n.question_bank_comment"
        (click)="toggleComments()"
      >
        1
        <i class="iconify-inline" data-icon="fa-regular:comment"></i>
      </div>
      <button
        type="button"
        (click)="changeLikeQuestion(data.external_id)"
        *ngIf="data.user_liked === 1"
        [title]="i18n.question_bank_unlike"
      >
        <span
          class="iconify-inline text-22 text-customOrange-default header__icon--like"
          data-icon="ant-design:heart-filled"
        ></span>
      </button>
      <button
        type="button"
        (click)="changeLikeQuestion(data.external_id)"
        *ngIf="data.user_liked === 0"
        [title]="i18n.question_bank_like"
      >
        <span
          class="iconify-inline text-22 text-customGray-grayThree header__icon--like"
          data-icon="ant-design:heart-outlined"
        ></span>
      </button>
      <app-check-button
        *ngIf="!noSelect"
        [active]="selected"
        (change)="askToSelect()"
        [iconSize]="25"
      ></app-check-button>
      <div *ngIf="showComments">
        <div class="flex items-center gap-2 text-customBlue-default text-14">
          {{ comments.length }}
          <button type="button" *ngIf="user.active_role !== 'student'"
          (click)="refSubmissionComment.commentsVisibility = !refSubmissionComment.commentsVisibility">
            <span class="iconify w-5 h-5" data-icon="fa-regular:comment"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="question-box__body" *ngIf="!deleteMode">
      <div class="title text-customGray-dark" [innerHTML]="title"></div>
      <div
        class="description text-customGray-dark"
        *ngIf="data.text"
        [innerHTML]="description"
      ></div>

      <div #alternatives [ngSwitch]="type">
        <app-view-scale
          *ngSwitchCase="'SCALE'"
          [data]="data"
          [viewOnly]="readonly"
          (change)="onChange()"
        ></app-view-scale>
        <app-view-list
          *ngSwitchCase="'LIST'"
          [data]="data"
          [viewOnly]="readonly"
          (change)="onChange()"
          (feedback)="onFeedback($event)"
          [correction]="correction"
        ></app-view-list>
        <app-create-text
          *ngSwitchCase="'TEXT'"
          [data]="data"
          [viewOnly]="readonly"
          (changeText)="onChange()"
        ></app-create-text>
        <app-create-paragraph-text
          *ngSwitchCase="'PARAGRAPH_TEXT'"
          [data]="data"
          [viewOnly]="readonly"
          (changeParagraphText)="onChange()"
        ></app-create-paragraph-text>
        <app-view-multiple-choice
          *ngSwitchCase="'MULTIPLE_CHOICE'"
          [data]="data"
          [viewOnly]="readonly"
          (change)="onChange()"
          (feedback)="onFeedback($event)"
          [correction]="correction"
        ></app-view-multiple-choice>
        <app-view-checkbox
          *ngSwitchCase="'CHECKBOX'"
          [data]="data"
          [viewOnly]="readonly"
          (change)="onChange()"
          (feedback)="onFeedback($event)"
          [correction]="correction"
        ></app-view-checkbox>
        <app-view-grid-multiple-choice
          *ngSwitchCase="'GRID'"
          [data]="data"
          [viewOnly]="readonly"
          (change)="onChange($event)"
        ></app-view-grid-multiple-choice>
        <app-view-grid-checkbox
          *ngSwitchCase="'CHECKBOX_GRID'"
          [data]="data"
          [viewOnly]="readonly"
          (changeSelect)="onChange($event)"
        ></app-view-grid-checkbox>
      </div>

      <div class="question-box__loading-mode" [class.is-active]="loadingMode">
        <app-loading-balls-effect
          class="loading-mode__balls"
        ></app-loading-balls-effect>
      </div>

      <div
        class="correction-box"
        *ngIf="correction && feedback"
        [ngClass]="{
          'is-correct': feedback.isCorrect,
          'is-wrong': !feedback.isCorrect
        }"
      >
        <ng-container *ngIf="feedback.isCorrect">
          <i
            *ngIf="positiveFeedback"
            class="iconify-inline correction-box__icon"
            data-icon="akar-icons:circle-check"
          ></i>

          <div
            *ngIf="positiveFeedback"
            class="mt-0.5"
            [innerHTML]="positiveFeedback"
          ></div>
        </ng-container>
        <ng-container *ngIf="!feedback.isCorrect">
          <i
            *ngIf="negativeFeedback"
            class="iconify-inline correction-box__icon"
            data-icon="akar-icons:circle-x"
          ></i>

          <div
            *ngIf="negativeFeedback"
            class="mt-0.5"
            [innerHTML]="negativeFeedback"
          ></div>
        </ng-container>
      </div>
      <div
        class="flex gap-2 justify-end text-customGreen-approved"
        *ngIf="isSaveQuestion"
      >
        <i
          class="iconify-inline correction-box__icon"
          data-icon="akar-icons:circle-check"
        ></i>
        <span class="mt-0.5">{{ i18n.question_bank_saved }}</span>
      </div>

      <form
        class="flex items-center gap-2 mr-4 text-customGray-dark text-14"
        *ngIf="_correction"
      >
        <p>{{ i18n.question_bank_punctuation }}:</p>
        <input
            #gradeCorrectionRef   
            type="number"
            class="w-16 p-1 border rounded border-customGray-default"
            [(ngModel)]="gradeCorrection"
            name="gradeCorrection"
            (change)="getGradeCorrection()"
            (keyup)="validateValue(gradeCorrectionRef.value)"
        />
      </form>
    </div>
    <div
      class="question-box__tags"
      *ngIf="
        Array.isArray(data.tags) &&
        data.tags.length &&
        !loadingMode &&
        !noTag &&
        readonly
      "
    >
      <div class="tags__tag" *ngFor="let tag of data.tags">
        <i class="tag-icon tags__icon"></i>
        {{ tag.text }}
      </div>
    </div>
  </div>
</div>

<div>
  <app-submission-comment
    #refSubmissionComment
    [commentsVisibility]="commentsVisibility"
  ></app-submission-comment>
</div>
