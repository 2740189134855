<div class="container" *ngIf="summary">
  <div class="overview-wrapper">
    <div class="homework-pie-chart submission-box">
      <header class="header">
        <h2 class="header__title">{{ i18n.disciplines_content_submissions_summary_task }}</h2>
      </header>
      <div *ngIf="pieChartData" class="w-full flex flex-col md:flex-row items-center gap-2 justify-center homework-pie-chart__pie-chart">
        <ngx-charts-pie-chart
          [view]="view"
          [scheme]="colorScheme"
          [results]="pieChartData"
          [gradient]="false"
          [legend]="false"
          [legendPosition]="legendPosition"
          [labels]="false"
          [doughnut]="false"
          style="fill: #A9AEB3;">
        </ngx-charts-pie-chart>
        <ul>
          <li class="mb-3 flex items-center gap-2" *ngIf="summary.completed > 0">
            <div class="box-chart bg-customGreen-approved"></div>
            <p>{{ completedPercent }}  <span class="lowercase">{{ i18n.disciplines_content_submissions_completed }}</span></p>
          </li>
          <li class="mb-3 flex items-center gap-2" *ngIf="summary.pending > 0">
            <div class="box-chart bg-customOrange-default"></div>
            <p>{{ pendingPercent }} <span class="lowercase">{{ i18n.disciplines_content_submissions_pending }}</span></p>
          </li>
          <li class="flex items-center gap-2" *ngIf="summary.late > 0">
            <div class="box-chart bg-yellow"></div>
            <p>{{ latePercent }} <span class="lowercase">{{ i18n.disciplines_content_submissions_completed_late }}</span></p>
          </li>
        </ul>
      </div>
    </div>

    <div class="last-submissions submission-box">
      <header class="header">
        <h2 class="header__title">{{ i18n.disciplines_content_submissions_latest_deliveries }}</h2>
        <button (click)="goToResumeTab()" class="header__see-all-btn">{{ i18n.disciplines_content_submissions_show_all }}</button>
      </header>

      <div class="last-submissions__submissions">
        <div *ngFor="let lastSub of lastSubmissions" class="last-submissions__submission">
          <div class="last-submissions__user">
            <app-user-frame [img_src]="lastSub.user.image" [user_name]="lastSub.user.name"
              [breakpoint_X]="'870px'"></app-user-frame>

            <div class="last-submissions__user-info">
              <div class="last-submissions__user-name">{{ lastSub.user.name }}</div>
              <div class="last-submissions__submited-at">{{i18n.disciplines_content_submissions_sent_ago}} {{ lastSub.course_work.at(-1).completed_at | dateAgo }}</div>
            </div>
          </div>
          <button class="view-details-btn" (click)="goToSubmissionsTab(lastSub)">
            {{ i18n.disciplines_content_submissions_to_view }}    
            <i class="view-details-btn__icon arrow-right-icon"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="false" class="submissions-resume submission-box">
    <header class="header">
      <h2 class="header__title">{{ i18n.disciplines_content_submissions_summary_deliveries }}</h2>
    </header>

    <div class="submissions-resume__submissions">
      <app-submissions-general-quiz-review [breakpoint_X]="'944px'"></app-submissions-general-quiz-review>

      <app-submissions-general-quiz-review [breakpoint_X]="'944px'"></app-submissions-general-quiz-review>

      <app-submissions-general-quiz-review [breakpoint_X]="'944px'"></app-submissions-general-quiz-review>

      <app-submissions-general-quiz-review [breakpoint_X]="'944px'"></app-submissions-general-quiz-review>
    </div>
  </div>
</div>
