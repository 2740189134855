<section class="min-h-screen flex items-center justify-center p-4 w-full">
  <!-- Validator  -->
  <div
    class="p-6 md:p-10 rounded bg-customWhite-default shadow-md w-full sm:w-10/12 md:w-10/12 lg:w-2/3 xl:w-2/5 animation-card"
  >
  <div *ngIf="!loading">
    <header class="flex flex-col gap-4 items-center">
      <div class="flex flex-col sm:flex-row items-center gap-4">
        <img
          src="./assets/svg/certificates-validator.svg"
          alt="Ícone de certificados"
        />
        <h1 class="text-customBlue-default text-18 sm:text-30 font-semibold">
          {{ i18n.certificates_validator_verify }}
        </h1>
      </div>
      <p class="text-center text-customGray-dark">
        {{ i18n.certificates_validator_verify_message }}
      </p>
    </header>
    <form [formGroup]="form" (ngSubmit)="validateCertificates()" *ngIf="form">
      <div class="flex items-center mt-10">
        <input
          class="border border-customGray-default rounded p-2 w-full border-r-0 rounded-r-none"
          type="text"
          [style.height]="'2.5rem'"
          formControlName="code"
          required
          placeholder="{{ i18n.certificates_validator_code_phaste }}"
        />
        <button
          class="button-save py-2 h-full w-min px-10 text-customWhite-default rounded-l-none"
          type="submit"
          [disabled]="form.invalid"
          [ngClass]="{ 'disabled:opacity-75 cursor-not-allowed': form.invalid }"
        >
          {{ i18n.certificates_validator_button_validate }}
        </button>
      </div>
      <div *ngIf="form.invalid && (form.dirty || form.touched)">
        <small
          class="mt-2 block text-customOrange-default"
          *ngIf="form.controls['code'].errors?.['required']"
          >{{ i18n.certificates_validator_code_required }}</small
        >
      </div>
    </form>
  </div>

  <div *ngIf="loading">
      <app-bullets-loader></app-bullets-loader>
    </div>
  </div>
</section>