<div class="flex flex-col w-full items-center mt-6">
    <div class="w-full flex flex-col justify-between items-center border border-customGray-mediumGray rounded-md p-1">
        <div (click)="toggleCollapse = !toggleCollapse" class="flex w-full justify-between items-center px-4 cursor-pointer">
            <div class="flex gap-2 md:items-center">
                <div class="flex justify-center items-center rounded-full bg-customGray-iceGray w-10 h-10">
                    <span class="iconify w-7 h-7 text-customBlue-default" data-icon="icon-park-outline:degree-hat"></span>
                </div>
                <div class="flex flex-col md:items-center items-baseline md:gap-4">
                    <h2 class="text-customBlue-default font-semibold text-16 md:text-18">
                        {{ titleStatus }}
                    </h2>
                    <!-- Mobile -->
                    <div class="flex md:gap-x-1 md:hidden items-center justify-center">
                        <div class="text-customGray-dark text-15 text-left flex gap-x-1 md:gap-x-4 items-center justify-center">
                            <p 
                                class="text-left" 
                                *ngIf="howManyTasks(statusContent) > 1"
                            >
                                {{ howManyTasks(statusContent) }} {{i18n.dash_tasks}}
                            </p>
                            <p 
                                class="text-left" 
                                *ngIf="howManyTasks(statusContent) === 1"
                            >
                                {{ howManyTasks(statusContent) }} {{i18n.dash_task}}
                            </p>
                        </div>
                    </div>
                    <!-- END mobile -->
                </div>
            </div>


            <div class="flex md:gap-x-1 items-center justify-center">
                <div class="text-customGray-dark text-15 text-left flex gap-x-1 md:gap-x-4 items-center justify-center">
                    <p 
                        class="text-left pl-3 xs:hidden md:visible" 
                        *ngIf="howManyTasks(statusContent) > 1"
                    >
                        {{ howManyTasks(statusContent) }} {{i18n.dash_tasks}}
                    </p>
                    <p 
                        class="text-left pl-3 xs:hidden md:visible" 
                        *ngIf="howManyTasks(statusContent) === 1"
                    >
                        {{ howManyTasks(statusContent) }} {{i18n.dash_task}}
                    </p>
                    <button 
                        
                        [@rotatedState]="toggleCollapse"
                    >
                        <span class="iconify w-8 h-8 text-customBlue-default" data-icon="iconamoon:arrow-up-2-fill"></span>
                    </button>
                </div>
            </div>
        </div>

        <div [@collapse]="toggleCollapse" class="flex flex-col w-full h-full items-center style-scroll">

            <div class="container-tasks" [ngClass]="{'apply-overflow-y': howManyTasks(statusContent) >= 3}">

                <ng-container *ngFor="let task of statusContent;" >
                    <div
                        class="w-full flex md:flex-row md:items-center border border-customGray-lighterGray rounded-md p-2 min-h-11 my-2 cursor-pointer gap-2"
                        (click)="routeToLink(task)"
                    >   
                        <div class="min-w-8">
                            <span class="iconify w-8 h-8 text-customGray-dark" data-icon="basil:book-outline"></span>
                        </div>

                        <div class="w-full flex flex-col md:flex-row justify-start md:justify-between items-baseline md:items-center overflow-hidden">

                            <div 
                                class="flex md:w-1/2 md:overflow-hidden items-baseline gap-2 ml-1 relative"
                            >	
                                
                                <div 
                                    class="flex flex-col "
                                    style="min-width: 0;"
                                >
                                    <div
                                        [pTooltip]="task.title"
                                        tooltipPosition="top"
                                        class="font-semibold text-16 text-customGray-dark"
                                        style="white-space: nowrap;
                                        overflow: hidden; 
                                        text-overflow: ellipsis;"
                                    >
                                        {{ task?.title }}
                                    </div>
                                    <div
                                        pTooltip="{{ getDisciplineName(task.discipline_external_id) }}"
                                        tooltipPosition="top"
                                        class="text-14 text-customGray-dark"
                                    >
                                        {{ getDisciplineName(task.discipline_external_id) }}
                                    </div>
                                </div>
                            </div>
    
                            <!-- date and tag -->
                            <div
                                class="flex md:w-1/2 items-center gap-2 relative"
                            >
                                <ng-container *ngIf="typeStatus === 'pending'">
                                    <div
                                        class="flex mx-auto items-center gap-2"
                                    >
                                        <div class="pb-0.5">
                                            <span class="iconify w-5 h-5 text-customBlue-default" data-icon="gridicons:calendar"></span>
                                        </div>
                                        <span class="text-customBlue-default italic text-14">
                                            {{ returnDatePending(task) }}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
    
                            <ng-container *ngIf="typeStatus === 'pending'">
                                <div 
                                    class="flex md:w-1/2 md:justify-end justify-start md:items-center items-baseline"
                                >
                                    <div class="flex items-center gap-2 mr-6">
                                        <div>
                                            <span class="iconify w-5 h-5 text-customGray-dark" data-icon="mdi:clock-outline"></span>
                                        </div>
                                        <div 
                                            class="text-14 italic text-customGray-dark"
                                        >
                                            {{ task.end_hour.replace(':', 'h') }}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
    
                            <ng-container *ngIf="typeStatus === 'late'">
                                <div 
                                    class="flex md:w-1/2 justify-start items-baseline"
                                    style="white-space: nowrap;"
                                >
                                    <div class="flex items-center gap-2 mr-6">
                                        <div>
                                            <span class="iconify w-5 h-5 text-customOrange-F96319" data-icon="gridicons:calendar"></span>
                                        </div>
                                        <div 
                                            class="text-14 italic text-customOrange-F96319"
                                        >
                                            {{ returnDateLate(task) }}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
    
                            <ng-container *ngIf="typeStatus === 'completed'">
                                <div 
                                    class="flex md:w-1/2 justify-center md:justify-start items-center"
                                    style="white-space: nowrap;"
                                >
                                    <div class="flex items-center gap-2 mr-6">
                                        <div>
                                            <span class="iconify w-5 h-5 text-customBlue-default" data-icon="gridicons:calendar"></span>
                                        </div>
                                        <div 
                                            class="text-14 italic text-customBlue-default"
                                        >
                                            {{ returnDateCompleted(task) }}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        
                    </div>
                </ng-container>

                <ng-container *ngIf="statusContent.length === 0">
                    <div class="w-full flex justify-center text-customGray-default text-18 font-medium py-4">
                        {{ emptyStatusContent(typeStatus) }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>