import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private http: HttpClient
  ) { }

  getGroups(): Observable<any>{
    return this.http.get(`${API_Routes.URL}/permissioning/group`);
  }
  
  getRoles(group_id: any): Observable<any>{
    return this.http.get(`${API_Routes.URL}/permissioning/group/?q=${group_id}`);
  }
  
  getListRoles(params: any): Observable<any>{
    return this.http.get(`${API_Routes.URL}/permissioning/role`, params);
  }

  postRole(params: any):Observable<any>{
    return this.http.post(`${API_Routes.URL}/permissioning/role`,params);
  }

  patchRole(role_external_id: any, params: any):Observable<any>{
    return this.http.patch(`${API_Routes.URL}/permissioning/role/${role_external_id}`, params);
  }
  
  putPermissions(role: any, params: any):Observable<any>{
    return this.http.put(`${API_Routes.URL}/permissioning/group/?q=${role}`,params);
  }

  postPermissions(params: any):Observable<any>{
    return this.http.post(`${API_Routes.URL}/permissioning/permissions`,params);
  }

  delRole(role_external_id: any): Observable<any>{
    return this.http.delete(`${API_Routes.URL}/permissioning/role/${role_external_id}`);
  }

}
