import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { HomeLayoutComponent } from 'src/app/pages/home/home.component';
import { DashboardComponent } from './dashboard.component';
import { DashboardTitleResolver } from './dashboard-title.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        resolve: {
          title: DashboardTitleResolver
        },
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
