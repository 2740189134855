<app-generic-modal [isVisible]="isVisible" style="min-width: 480px">
  <div class="px-12 py-8 flex flex-col items-center justify-center gap-3">
    <span
      class="iconify text-5xl text-customBlue-default"
      data-icon="fluent:info-28-filled"
    ></span>
    <p class="text-customBlue-default font-medium">{{ message }}</p>
    <app-bullets-loader></app-bullets-loader>
  </div>
</app-generic-modal>
