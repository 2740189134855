<form [formGroup]="criteriaForm">
    <select class="select-criteria" formControlName="selectedCriteria">
        <option class="selected-text" [value]="undefined" disabled selected>
            {{ i18n.adv_and_crit_select }}
        </option>
        <option  *ngFor="let type of whatContentObjectSelect(contentType)" class="selected-text" [value]="type.value">
            {{ type.name }}
        </option>
    </select>
</form>
