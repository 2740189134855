import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
    selector: 'app-custom-import',
    templateUrl: './custom-import.component.html',
    styleUrls: ['./custom-import.component.scss']
})
export class CustomImportComponent {
    @Input() i18n: any;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() generateFunction: any;
    @Input() link: string;
    @Input() labelGenerate: string;
    @Input() labelImport: string;
    @Input() modeGenerate: string;

    isModalVisible = false;
    isPopoverVisible = false;

    constructor(private elementRef: ElementRef) { }

    @HostListener('document:click', ['$event'])
    onClick(event: Event): void {
        const clickedInside = this.elementRef.nativeElement.contains(event.target as Node);
        if (!clickedInside) {
            // Clique fora da div do popover, então feche o popover
            this.isPopoverVisible = false;
        }
    }

    openModal() {
        this.isPopoverVisible = false;
        this.isModalVisible = true;
    }

    closeModal() {
        this.isPopoverVisible = false;
        this.isModalVisible = false;
    }

    togglePopover() {
        this.isPopoverVisible = !this.isPopoverVisible;
    }
}
