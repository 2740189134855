<!-- MODAL DE RESETAR SENHA -->
<app-generic-modal [isVisible]="isVisible" [minWidth]="'30%'" [maxWidth]="'70%'" >
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="text-center p-5 border-b border-solid border-customBlue-light rounded-t">
        <h3 class="text-xl font-bold text-customBlue-default">
          {{i18n.login_code_to_password_reset}}
        </h3>
      </div>
      <!--body-->
      <form [formGroup]="validationCodeForm">
        <div class="px-12 p-6 w-full ">
          <p class="text-center text-sm">{{i18n.login_type_reset_code}}</p>
          <div class="mt-5">
            <div class="mt-2">
              <label for="" class="font-semibold text-customGray-dark">{{i18n.login_reset_code}}</label>
              <input type="text" class="w-full border border-customGray-light rounded-sm p-2 text-md mt-2"
                [placeholder]="i18n.login_type_here" required formControlName="code">

            </div>
            <div *ngIf="!code?.pristine && code?.errors">
              <p *ngIf="code?.errors?.required" class="text-xs font-bold" style="color: #e8532c">
                  {{i18n.login_field_cannot_be_empty}}
              </p>
              <p *ngIf="validationCodeForm.controls['code'].hasError('maxlength')" class="text-xs font-bold" style="color: #e8532c">
                  {{i18n.login_fild_cannot_be_empty}}
              </p>
            </div>

            <div class="flex flex-col justify-end mt-2">
              <p class="text-right text-sm">{{i18n.login_reset_code_expires_in}} <app-countdown></app-countdown> {{i18n.login_reset_code_minutes}}</p>
              <button type="button" [ngClass]="{'disabled:opacity-20 cursor-not-allowed':!isValidationCodeButtonEnabled}" [disabled]="!isValidationCodeButtonEnabled" class="font-bold text-customBlue-default text-right mt-1" (click)="resetPasswordAgain()">
                {{i18n.login_reset_code_send_again}}
              </button>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center p-6 rounded-b">
          <button
            [ngClass]="{'disabled:opacity-20 cursor-not-allowed':!validationCodeForm.valid}" [disabled]="!validationCodeForm.valid"
            class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-7 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            (click)="validationToken()" type="button">
            {{i18n.login_next}}
          </button>
          <button
            class="text-customGray-textGray background-transparent font-semibold px-7 py-2.5 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button" (click)="closeModal()">
            {{i18n.login_cancel}}
          </button>
        </div>
      </form>
      <!--footer-->
    </div>
  </app-generic-modal>