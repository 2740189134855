import { NgModule } from '@angular/core';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { SharedModule } from 'src/app/shared/shared.module';

import { DisplayPanelModule } from '../display-panel/display-panel.module';
import { DecisionModalModule } from '../modals/decision-modal/decision-modal.module';
import { GenericModalModule } from '../modals/generic-modal/generic-modal.module';
import { MessageModalModule } from '../modals/message-modal/message-modal.module';
import { UploadFilePreviewModule } from '../upload-file-preview/upload-file-preview.module';
import { UploadFileListComponent } from './upload-file-list.component';

@NgModule({
    declarations: [
        UploadFileListComponent
    ],
    exports: [
        UploadFileListComponent
    ],
    imports: [
        SharedModule,
        NgxFileHelpersModule,
        DecisionModalModule,
        MessageModalModule,
        DisplayPanelModule,
        GenericModalModule,
        UploadFilePreviewModule
    ]
})
export class UploadFileListModule { }
