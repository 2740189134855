import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';
import {
    FormBuilder, FormControl, FormGroup
} from '@angular/forms';
import { QuestionBankService } from 'src/app/pages/disciplines/components/question-bank/question-bank.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-create-multiple-choice',
    templateUrl: './create-multiple-choice.component.html',
    styleUrls: ['./create-multiple-choice.component.scss'],
})
export class CreateMultipleChoiceComponent implements OnInit {
    @ViewChildren('dropdown') dropdowns: any;
    @Input() data: any;
    @Output() change = new EventEmitter();

    i18n: any;

    form: FormGroup;
    receiveEditor;

    alternatives: any[] = [
        { text: '', correct: false },
        { text: '', correct: false },
    ];

    constructor(
        private shared: SharedService,
        public questionBankService: QuestionBankService,
        private fb: FormBuilder
    ) {
        this.createForm();
    }

    ngOnInit(): void {
        const translationModuleName = 'QuestionBank';
        this.shared.setI18n(this, translationModuleName);

        if (!this.data) {
            this.alternatives.forEach((alternative: any, i) => {
                this.form.addControl(`title${i}`, new FormControl(''));
            });
            return;
        }

        this.form.reset();

        this.data.options.forEach((alternative: any, i) => {
            this.form.addControl(`title${i}`, new FormControl(alternative?.text));
        });
        this.alternatives = this.data.options;

        setTimeout(() => {
            this.dropdowns._results.forEach((dropdown, i) => {
                dropdown.title = this.questionBankService.setAlternativeNameFromContent(
                    this.alternatives[i].text,
                    i,
                    this.i18n.que
                );
            });
        }, 0);

        // setTimeout(() => {
        //     this.receiveEditor._results.forEach((editor, i) => {
        //         editor._editor.setContent(this.alternatives[i].text);
        //     });
        // }, 1000);

        this.sendData();
    }

    createForm(): void {
        this.form = this.fb.group({});
    }

    sendData() {
        const arrOfOptions = this.alternatives.map((alt, i) => ({
            text: this.alternatives[i].text,
            correct: alt.correct ? 1 : 0,
            order: i + 1,
        }));

        const ready = arrOfOptions.every((alt) => alt.text?.length)
      && arrOfOptions.some((alt) => alt.correct)
      && arrOfOptions.length > 1;

        this.change.emit({
            ready,
            payload: {
                options: arrOfOptions,
            },
        });
    }

    add($randomElement) {
        const maxNumberOfAlternatives = 10;
        const $formBody = $randomElement.closest('.form-body');

        if (this.alternatives.length < maxNumberOfAlternatives) this.alternatives.push({ text: '', active: false });
        this.form.addControl(`title${this.alternatives.length - 1}`, new FormControl(''));
        this.scroll($formBody);

        this.sendData();
    }

    delete($event, index) {
        $event.stopPropagation();

        this.alternatives.splice(index, 1);

        setTimeout(() => {
            this.dropdowns._results.forEach((dropdown, index) => {
                dropdown.title = this.questionBankService.setAlternativeNameFromContent(
                    this.alternatives[index].text,
                    index,
                    this.i18n.question_bank_alternative
                );
            });
        }, 0);

        this.sendData();
    }

    setCorrectAlt(index: number) {
        this.alternatives.forEach((alt, i) => {
            if (i === index) alt.correct = !alt.correct;
            else alt.correct = false;
        });

        this.sendData();
    }

    input(event, index, dropdown) {
        const receiveEditor = event.editor;
        const receiveEvent = event.event;

        this.questionBankService.verifyPasteImage(receiveEvent);
        setTimeout(() => {
            const editorContent = receiveEditor._editor.getContent();
            this.alternatives[index].text = editorContent;
            dropdown.title = this.questionBankService.setAlternativeNameFromContent(
                editorContent,
                index,
                this.i18n.question_bank_alternative
            );

            this.sendData();
        }, 0);
    }

    scroll($formBody) {
        const itemMainClassheight = 33;
        const flexGap = 16;

        $formBody.scrollTop += itemMainClassheight + flexGap; // this gives the scroll, is them sum of the .item__main (class) height + the flex gap
    }
}
