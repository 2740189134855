<section
    class="container z-50 flex items-center justify-between fixed bottom-4 right-5 py-2 px-3 rounded border"
    *ngIf="showToast"
    [ngClass]="{
        'bg-customBlue-glass border-customBlue-royal': type === 'base-info',
        'bg-customGreen-success border-customGreen-approved': type === 'base-success'
    }"
>
    <!-- Info  -->
    <div *ngIf="type === 'base-info'">
        <span
            class="iconify text-customBlue-royal text-30"
            data-icon="ic:baseline-info"
            data-rotate="180deg"
        ></span>
    </div>
    <!-- Success  -->
    <div *ngIf="type === 'base-success'">
        <span
            class="iconify text-customGreen-approved text-30"
            data-icon="ph:seal-check-bold"
        ></span>
    </div>
    <p
        class="font-semibold"
        [ngClass]="{
            'text-customBlue-royal': type === 'base-info',
            'text-customGreen-approved': type === 'base-success'
        }"
    >
        {{ message }}
    </p>
    <div>
        <!-- Required -->
    </div>
</section>
