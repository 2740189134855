import {
    animate, AUTO_STYLE, state, style, transition, trigger
} from '@angular/animations';
import {
    AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { DisciplineDetailService } from '../../../disciplines-detail/discipline-detail.service';
import { ForumService } from '../../forum.service';
import { ForumSharedService } from '../../shared/forum-shared.service';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-forum-comment-reply',
    templateUrl: './forum-comment-reply.component.html',
    styleUrls: ['./forum-comment-reply.component.scss'],
    animations: [
        trigger('collapse', [
            state(
                'true',
                style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
            ),
            state(
                'false',
                style({ height: '0px', visibility: 'hidden', opacity: 0 })
            ),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ],
})
export class ForumCommentReplyComponent implements OnInit, AfterViewInit {
    @ViewChild('forum_post_anchor') forum_post_anchor: ElementRef;
    @Input() renderFromTemplate = false;
    @Input() reply_id: any;
    @Input() forum_post: any;
    @Input() isComponentForum = false;
    @Input() forumExternalId = false;
    @Input() hiddenSocialEvents = false;
    @Output() total_threads = new EventEmitter();

    // forum_post: any = [];
    hasntForums: any;
    hasntResults: boolean;

    user: any;
    query = '';
    titlePage = '';

    forum_post_external_id: any;
    forum_external_id: any;
    discipline_external_id: any;
    isLoading: boolean;
    isCommentAreaVisible = '';
    isEditMode = false;
    isModalEditHistoryOpen = false;
    edit_history: any;
    i18n: any = [];

    number: any = 3;
    filterTerm: string;
    p = 1;

    disciplineDetail: any;

    showCommentArea = false;

    constructor(
        private route: ActivatedRoute,
        private forumService: ForumService,
        private sharedService: SharedService,
        public platModalService: PlatformModalsService,
        private disciplineDetailService: DisciplineDetailService,
        private forumSharedService: ForumSharedService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.getForumParams();
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;
        });

        this.disciplineDetailService.disciplineBasic$
            .subscribe({
                next: (disciplineDetail) => {
                    if (Object(disciplineDetail).length) {
                        this.disciplineDetail = disciplineDetail;
                    }
                }
            });
    }

    ngAfterViewInit(): void {
        this.checkIfCanPostForum();
    }

    isCanForumPost = false;
    checkIfCanPostForum() {
        if (!this.disciplineDetail) {
            this.showCommentArea = true;
            this.isCanForumPost = true;
            return;
        }

        if (this.disciplineDetail.canPostForum) {
            this.showCommentArea = true;
            this.isCanForumPost = true;
            return;
        }

        this.showCommentArea = false;
        this.isCanForumPost = false;
    }

    getTranslations() {
        this.i18n = this.sharedService.getTranslationsOf('Forum');
        this.titlePage = 'Fórum';
    }

    anchorScrollIntoVew() {
        const element = this.forum_post_anchor.nativeElement;
        element.scrollIntoView({ behavior: 'smooth' });
    }

    // Evento abaixo é chamado quando o botão de voltar do browser é clicado
    @HostListener('window:popstate', ['$event'])
    onPopState() {
        this.route.params.subscribe((params) => {
            sessionStorage.setItem('reply_id', params.id);
        });
        setTimeout(() => {
            this.ngOnInit();
        }, 100);
    }

    getForumParams() {
        this.forum_post_external_id = sessionStorage.getItem('forum_post_external_id');
        this.forum_external_id = sessionStorage.getItem('forum_external_id');
        this.discipline_external_id = sessionStorage.getItem('discipline_external_id');
    }

    askForGetForum() {
        this.forumService.checkForUpdatesOnForum();
    }

    getReplyId(forum_post: any) {
        const forumIds: any = [];
        forum_post?.thread?.replies.forEach((element:any) => {
            forumIds.push(element.id);
        });
        this.postReplyView(forumIds);
    }

    closeModalHistory(event:any) {
        this.isModalEditHistoryOpen = event;
    }

    isReplyOpen = '';
    cancelReply() {
        this.isCommentAreaVisible = '';
        this.isReplyOpen = '';
        this.isEditMode = false;
    }

    receiveEditHistoryObject(id: any) {
        this.forumService.getEditHistory(id).subscribe({
            next: (editHistory) => {
                this.edit_history = editHistory.data;
                this.isModalEditHistoryOpen = true;
            },

            error: (err) => {
                this.platModalService.toggle('message', err.error.error, 'close');
            }
        });
    }

    receiveIsCommentAreaVisible(isCommentAreaVisible: string) {
        this.isCommentAreaVisible = isCommentAreaVisible;
    }

    receiveIsReplyOpen(isReplyOpen: string) {
        this.isReplyOpen = isReplyOpen;
    }

    receiveIsEditMode(isEditMode) {
        this.isEditMode = isEditMode;
    }

    countReplies(reply: any, iterationCount = 1): number {
        let count = 1;
        const replyObj = reply;
        if (reply.replies && reply.replies.length > 0) {
            // recursively count nested replies
            count += reply.replies.reduce((acc, nestedReply) => acc + this.countReplies(nestedReply, iterationCount + 1), 0);
        }

        // Check if is the fifith iteration
        if (iterationCount === 4) {
            replyObj.isLastLevel = true;
        }

        return count;
    }

    postReplyView(params: any) {
        const forumIds = {
            forum_posts_ids: params
        };
        this.forumService.postViewForum(forumIds).subscribe();
    }

    saveComment(threadObject: any) {
        this.sharedService.isLoadingModalOpen();

        let forumExternalId = this.forum_external_id;
        if (this.isComponentForum) {
            forumExternalId = this.forumExternalId;
        }

        if (this.isEditMode) {
            const params = {
                text: threadObject.thread_text
            };
            this.forumService.patchForum(threadObject.thread_id, params)
                .pipe(
                    finalize(() => {
                        this.isEditMode = false;
                        this.sharedService.isLoadingModalClose();
                    })
                )
                .subscribe((res: any) => {
                    const forumPost = this.forumSharedService.getForumThreadById(threadObject.thread_id, this.forum_post);
                    forumPost.text = res.text;
                    forumPost.edited_at = res.edited_at;
                    forumPost.has_history = res.has_history;
                    forumPost.id = res.id;
                    this.cancelReply();
                });
        } else {
            const params = {
                text: threadObject.thread_text,
                title: 'TITULO', // remover
                parent_id: threadObject.thread_id, // id do forum post ou null
                root_id: this.forum_post?.parent_id// id da thread ou null
            };
            this.forumService.postForum(forumExternalId, params)
                .pipe(
                    finalize(() => {
                        this.sharedService.isLoadingModalClose();
                    })
                )
                .subscribe({
                    next: (res: any) => {
                        const forumPost = this.forumSharedService.getForumThreadById(threadObject.thread_id, this.forum_post);
                        forumPost.isThreadOpen = false;
                        forumPost.replies.push(res);
                        this.total_threads.emit();
                        this.cancelReply();
                    },
                    error: (err) => {
                        this.platModalService.toggle('message', err.error.error, 'close');
                    }
                });
        }
    }
}
