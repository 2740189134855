import {
    Directive, EventEmitter, HostListener, Output
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appCustomPercent]'
})
export class CustomPercentDirective {
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onCurrencyEvent = new EventEmitter();

    constructor(public ngControl: NgControl) { }
    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event, false);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
        this.onInputChange(event.target.value, true);
    }

    onInputChange(event: any, backspace: any) {
        let newVal;
        let firstDigit;
        let secondDigit;

        if (event !== null) {

            newVal = event.toString().replace(/\D/g, '');
            firstDigit = newVal.substring(0, 1);
            secondDigit = newVal.substring(0, 2);

            if (backspace && newVal.length <= 5) {
                newVal = newVal.substring(0, newVal.length - 1);
            }

            if ((newVal.length >= 3 && firstDigit > 1) || (newVal.length >= 3 && secondDigit > 1)) {
                newVal = '';
                newVal = '100';
            }

            if (newVal.length == 0) {
                newVal = '';
            }

            this.ngControl.valueAccessor?.writeValue(`${newVal}%`);
            this.onCurrencyEvent.emit(newVal);
        }

    }
}
