<div *ngIf="isVisible">
  <ng-container *ngIf="!isLoading">
    <button
      (click)="loginWithGoogle()"
      type="button"
      class="bg-white text-customGray-dark font-normal px-4 py-3 rounded-md shadow-card w-full"
    >
      <div class="flex justify-center items-center gap-6">
        <span class="iconify w-5 h-5" data-icon="logos:google-icon"></span>
        <span>{{ i18n.login_with_google }}</span>
      </div>
    </button>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="py-3">
      <app-bullets-loader></app-bullets-loader>
    </div>
  </ng-container>
</div>
