import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SubmissionButtonsNavigateForumService {
    private selectedUserExternalID = new BehaviorSubject<string>('');
    private canGetElementsForum = new BehaviorSubject<boolean>(false);

    getSelectedUserExternalID(): Observable<string> {
        return this.selectedUserExternalID.asObservable();
    }

    updateSelectedUserExternalID(externalID: string): void {
        this.selectedUserExternalID.next(externalID);
    }

    checkGetElementsForum(): Observable<boolean> {
        return this.canGetElementsForum.asObservable();
    }

    updateStateElementsForum(value: boolean): void {
        this.canGetElementsForum.next(value);
    }
}
