<section class="">
    <div *ngIf="!hasFile" class="mb-4">
        <button
            id="returnToImportations"
            type="button"
            (click)="goBack()"
            class="text-customGray-default flex items-center gap-2 mb-2 w-full"
        >
            <i class="iconify-inline" data-icon="bi:chevron-left"></i>
            <span>{{ i18n?.import_spreasheet_return_to_importations }}</span>
        </button>
    </div>
    <div
        class="flex flex-col bg-customWhite-default rounded-xl shadow-card items-center"
        style="min-height: 70vh"
    >
        <!-- Page Select -->
        <div *ngIf="!hasFile" class="p-12">
            <div class="flex mb-2 items-baseline justify-center">
                <h1
                    class="text-customBlue-default font-bold text-18 sm:text-22 mr-1"
                >
                    {{ i18n?.import_spreadsheet_import_structure }}
                </h1>

                <div class="relative">
                    <span
                        class="iconify-inline text-18 text-customGray-dark"
                        data-icon="carbon:help"
                    ></span>
                    <div
                        class="w-60 sm:w-80 absolute bg-customWhite-default p-2 rounded-md shadow-card tooltip opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                        style="left: -170px"
                    >
                        <span class="text-center text-14 text-customGray-dark">
                            {{ i18n?.import_spreadsheet_tooltipe_in_drive }}
                        </span>
                    </div>
                </div>
            </div>
            <div style="max-width: 630px">
                <h3
                    class="text-12 sm:text-16 text-customGray-dark text-center font-roboto"
                >
                    {{ i18n?.import_spreadsheet_upload_sheet_subtitle }}
                </h3>
            </div>
            <div class="text-center mt-6">
                <button
                    id="uploadSheet"
                    (click)="openUploadSheet()"
                    class="shadow-card rounded-lg bg-customWhite-default"
                    style="width: 267px; height: 56px"
                >
                    <div class="flex items-end justify-center">
                        <img
                            class="h-6"
                            src="../../../assets/svg/drive_green.svg"
                            alt=""
                        />
                        <span
                            class="ml-2 text-customGray-dark text-16 font-bold"
                        >
                            {{ i18n?.import_spreadsheet_select_sheet }}
                        </span>
                    </div>
                </button>
            </div>
        </div>

        <!-- Page Config -->
        <div
            *ngIf="hasFile && modeGenerate === 'structure'"
            class="w-full flex flex-col items-center"
        >
            <!-- Form config -->
            <div
                class="w-full flex flex-col items-center pb-8 mb-4 border-b border-customBlue-glass p-12"
            >
                <!-- Title -->
                <div
                    class="flex mb-2 items-baseline justify-center text-center"
                >
                    <h1
                        class="text-customBlue-default font-bold text-18 sm:text-22 mr-1 text-center"
                    >
                        {{ files[0]?.label }}
                    </h1>
                    <div class="relative">
                        <span
                            class="iconify-inline text-18 text-customGray-dark"
                            data-icon="carbon:help"
                        ></span>
                        <div
                            class="w-60 sm:w-80 absolute bg-customWhite-default p-2 rounded-md shadow-card tooltip opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                            style="left: -170px"
                        >
                            <span
                                class="text-center text-14 text-customGray-dark"
                            >
                                {{ i18n?.import_spreadsheet_tooltipe_in_drive }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <span class="text-customGray-swampGray">{{
                        i18n?.import_spreadsheet_config_subtitle
                    }}</span>
                </div>
            </div>
            <!-- Form -->
            <div class="flex flex-col w-full px-3 sm:px-12 py-3">
                <app-accordion-config-sheet
                    [files]="files"
                    [modeGenerate]="modeGenerate"
                ></app-accordion-config-sheet>
            </div>
        </div>

        <!-- Page Config Connections-->
        <div
            *ngIf="hasFile && modeGenerate === 'connections'"
            class="w-full flex flex-col items-center"
        >
            <!-- Form config -->
            <div
                class="w-full flex flex-col items-center pb-8 mb-4 border-b border-customBlue-glass p-12"
            >
                <!-- Title -->
                <div class="flex mb-2 items-baseline text-center">
                    <h1
                        class="text-customBlue-default font-bold text-18 sm:text-22 mr-1"
                    >
                        {{ files[0]?.label }}
                    </h1>
                    <div class="relative">
                        <span
                            class="iconify-inline text-18 text-customGray-dark"
                            data-icon="carbon:help"
                        ></span>
                        <div
                            class="w-60 sm:w-80 absolute bg-customWhite-default p-2 rounded-md shadow-card tooltip opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                            style="left: -170px"
                        >
                            <span
                                class="text-center text-14 text-customGray-dark"
                            >
                                {{ i18n?.import_spreadsheet_tooltipe_in_drive }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <span class="text-customGray-swampGray">{{
                        i18n?.import_spreadsheet_config_subtitle
                    }}</span>
                </div>
            </div>
            <!-- Form -->
            <div class="flex flex-col w-full px-3 sm:px-12 py-3">
                <app-accordion-config-sheet-connection
                    [files]="files"
                    [modeGenerate]="modeGenerate"
                    (emptyFile)="emptyFile()"
                ></app-accordion-config-sheet-connection>
            </div>
        </div>
    </div>
</section>

<app-upload
    *ngIf="modalOpen$ | async"
    [files]="files"
    [maxFiles]="maxFiles"
    [multiSelect]="false"
    [fileType]="'sheets'"
    (filesEvent)="receiveFiles($event)"
></app-upload>
