import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})

export class DisciplinesGuard implements CanActivate {
  constructor (
      public platModalService: PlatformModalsService,
      private sharedService: SharedService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const i18n = this.sharedService.getTranslationsOf('Errors');
    const message = i18n.permission_unauthorized;

    const currentPath = route.routeConfig?.path;

    const paths = [
      { url: '', permission: 'disciplines', permissionType: 'list' },
      { url: ':id', permission: 'disciplines', permissionType: 'list' },
      { url: 'overview', permission: 'disciplines', permissionType: 'list' },
      { url: 'content-tab', permission: 'sections', permissionType: 'list' },
      { url: 'announcements', permission: 'announcements', permissionType: 'list' },
      { url: 'forum', permission: 'forum', permissionType: 'list' },
      { url: 'question-bank', permission: 'questionnaire', permissionType: 'list' },
      { url: 'discipline-settings', permission: 'disciplines_settings', permissionType: 'list' },
      { url: 'grades', permission: 'grades', permissionType: 'list' },
      { url: 'content-assignment', permission: 'contents', permissionType: 'list' },
    ]

    //Verify if the route path has the necessary permissions
    const pathPermission: any = paths.find(path => path.url === currentPath) || {};
    const userHasPermission: boolean = this.sharedService.checkPermission(pathPermission.permission, pathPermission.permissionType);
    if (!userHasPermission) this.sharedService.showUnauthorizedModal(message);

    return userHasPermission;
  }
}
