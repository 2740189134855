import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ForumGuard implements CanActivate {
   constructor(
    private sharedService: SharedService,
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    )
  {

    let i18n = this.sharedService.getTranslationsOf('Errors');
    let message = i18n.permission_unauthorized;

    if (route.routeConfig?.path == ':id') {
      if (!this.sharedService.checkPermission("forum", 'list')) {
        this.sharedService.showUnauthorizedModal(message);
        return false;
      }
    }
    return true;
  }
}
