import { Component, Input, OnInit } from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { LoginService } from '../../login.service';
declare const google: any;

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SSOLoginComponent implements OnInit {
  @Input() isVisible: boolean;
  isLoading: boolean;
  i18n: any = [];

  constructor(
    private loginService: LoginService,
    private sharedService: SharedService,
    public platModalService: PlatformModalsService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.getAllowProvider();
  }

  getAllowProvider() {
    this.loginService.update_localstorage.subscribe((authentication) => {
      let allowedProviders = authentication?.allowedProviders;
      if (allowedProviders.length > 0) {
        let googleProvider = allowedProviders.find((item) => {
          return item.name == "google"
        });
        if (googleProvider !== undefined && Object.keys(googleProvider).length) {
          this.getGoogleClientId();

          const whoami = {
            clientId: googleProvider.client_id,
            apiKey: googleProvider.api_key,
            appId: googleProvider.app_id,
            mail: authentication?.backendAccount
          }

          this.loginService.setWhoamiCredencials(whoami);
        }
      }
    })
  }

  getTranslations(): void{
    this.i18n = {...this.sharedService.getTranslationsOf('Login'),...this.sharedService.getTranslationsOf('Errors')}
  }

  getGoogleClientId() {
    this.isLoading = true;
    let allowedProviders = JSON.parse(localStorage.getItem('allowedProviders') || '');
    if (Array.isArray(allowedProviders) || allowedProviders != undefined || allowedProviders != '') {
      console.log(allowedProviders);
      allowedProviders.forEach(element => {
        if (element.name == 'sso' && element.client_id != '' && element.client_id != undefined) {
          this.loadGoogleClient(element.client_id, element.hosted_domain, element.state);
        }
      });
    } else {
      throw new Error("Plataform settings is undefined");
    }
    this.isLoading = false;
    this.isVisible = true;
  }

  client;
  loadGoogleClient(client_id, hosted_domain, state){
    console.log("loadGoogleClient ", client_id, hosted_domain)
    this.client = google.accounts.oauth2.initCodeClient({
      client_id: client_id,
      scope: 'email profile',
      hosted_domain: hosted_domain,
      state: state,
      callback: (response) => {
        if (response != null) {
          this.checkGoogleUser(response.code)
        }

      },
    });
  }

  loginWithSSO() {
    if (this.client) {
      this.client.requestCode();

    } else {
      this.getAllowProvider();
      setTimeout(() => {
        this.client.requestCode();
      }, 200);
    }
  }

  checkGoogleUser(user: any) {
    if (user) {
      this.authGoogle(user);
    } else {
      localStorage.setItem('logOut', 'true')
    }
  }

  authGoogle(code: any){
    this.isLoading = true;
    const params = {
      "code": code
    }

    this.loginService.loginWithGoogle(params)
      .subscribe({
        next: (response) => {
          // Se usuário não aceitou os termos, abre modal e encaminha objeto de
          // resposta de
          this.sharedService.storeUserCredentials(response);
          if (response.terms !== null) {
            this.loginService.authObject$.next(response);
            this.loginService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: false });
          } else {
            this.loginService.setGoogleCredencials(response);
            this.sharedService.loggedIn.next(true);
            this.loginService.isRedirectRoute();
          }
        },
        error: (err) => {
          this.isLoading = false;
          if (err.status == 422) {
            this.platModalService.toggle('message', this.i18n?.[err?.error?.key_error], 'close');
          } else if (err.status == 404) {
            this.platModalService.toggle('message', this.i18n?.error_not_profile_login, 'close');
          } else {
            this.platModalService.toggle('message', this.i18n?.error_system_error, 'close');
          }

          this.sharedService.loggedIn.next(false);
        },
        complete: () => { this.isLoading = false; }
      })
  }
}
