import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-view-multiple-choice',
  templateUrl: './view-multiple-choice.component.html',
  styleUrls: ['./view-multiple-choice.component.scss']
})

export class ViewMultipleChoiceComponent implements OnInit {
  @Input() data: any;
  @Input() viewOnly: boolean;
  @Input() correction: boolean;

  @Output() change = new EventEmitter();
  @Output() feedback = new EventEmitter();

  alternativesText: SafeHtml[];
  optionKey: string;

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.alternativesText = this.data.options.map(option => this.sanitizer.bypassSecurityTrustHtml(option.text));
    this.optionKey = this.data.options[0].selected !== undefined
      ? 'selected'
      : 'correct';

      if (this.correction) {
        this.optionKey = 'selected';
        this.setCorrection();
        this.checkIfItIsCorrect();
      };
  }

    toggleAlternative(alternative): void {
        const alternativeSelected = alternative;
        // Se a alternativa selecionada já estiver selecionada ele retorna 
        if (this.viewOnly || alternativeSelected[this.optionKey] === 1) return;

        const currentAlternativeValue = alternativeSelected[this.optionKey];

        this.resetBalls();

        alternativeSelected[this.optionKey] = Number(!currentAlternativeValue);

        this.data.user_response = [this.data.options.map((option) => option[this.optionKey])];
        this.change.emit();
    }

  setCorrection () {
    this.data.options = this.data.options.map(option => {
      if (option.selected === 0 && option.correct === 0) {
        option.correction = 3;
      } else if (option.selected && !option.correct) {
        option.correction = 0;
      } else  {
        option.correction = 1;
      };

      return option;
    })
  }

  checkIfItIsCorrect () {
    const blankAnswer = this.data.options.every(option => option.correction === undefined);

    if (blankAnswer) return;

    const isCorrect = !this.data.options.some(option => option.correction === 0);
    this.feedback.emit({ isCorrect });
  }

    resetBalls(): void {
        this.data.options.map((e) => {
            e.correct = 0;
            e.selected = 0;
            return null;
        });
    }
}
