<div class="flex justify-center items-center h-full" *ngIf="isLoading$ | async">
  <app-bullets-loader></app-bullets-loader>
</div>

<div
  class="dash-transition"
  *ngIf="!showLoading"
>
  <div class="main">
    <app-adm-card [user]="user"></app-adm-card>
    <div class="flex flex-col items-center justify-center gap-y-4">
      <app-adm-users [adminData]="adminData" [i18n]="i18n"></app-adm-users>
      <app-adm-shortcuts [adminData]="adminData" [i18n]="i18n"></app-adm-shortcuts>
    </div>
  </div>
  <!-- Last Update status -->
  <ng-container *ngIf="adminData?.last_update">
    <div class="flex items-center justify-center gap-2 font-normal text-customBlue-default text-16 pt-8">
      <span class="iconify w-5 h-5" data-icon="mdi:clock-time-three-outline"></span>
      <div>
        {{ getDateTime(adminData?.last_update) }}
      </div>
    </div>
  </ng-container>

  <div class="mt-8">
    <app-adm-charts [adminData]="adminData" [i18n]="i18n"></app-adm-charts>
  </div>
</div>

<!-- <div #skeleton>
  
</div> -->
