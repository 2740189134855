<app-generic-modal [isVisible]="isVisible">
  <header class="header">
    <div class="title">{{ title }}</div>

    {{ description }}
  </header>

  <div class="form-body">
    <ng-content></ng-content>
  </div>
</app-generic-modal>

