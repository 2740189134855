<app-generic-modal [minWidth]="'30%'" [maxWidth]="'70%'" [isVisible]="isVisible">
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        <!--header-->
        <div class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t">
            <div class="text-center">
                <h3 class="text-2xl font-semibold text-customBlue-default">{{i18n.login_forgot_password}}</h3>
                <!-- <p class="text-sm text-customBlue-dark">{{i18n.forum_edits_made_in_replies}}</p> -->
            </div>
        </div>

        <!--body-->
        <ng-container >
            <form [formGroup]="resetForm">
                <div class="relative p-4">
                    <div class="text-center" style="max-width: 550px;">
                        <span class="text-customBlue-dark text-16 font-normal">
                            {{i18n.login_reset_password}}
                        </span>
                    </div>
                    <div class="flex flex-col mt-5">
                        <label class="text-customGray-swampGray text-16">E-mail</label>
                        <input type="email"  [placeholder]="i18n.login_type_your_email"
                            class="input-login font-OpenSans" autofocus autocomplete required formControlName="email">

                        <div *ngIf="!email?.pristine && email?.errors">
                            <p *ngIf="email?.errors?.required" class="text-xs font-bold" style="color: #e8532c">
                                {{ i18n.users_email_is_required }}
                            </p>
                            <p *ngIf="email?.errors?.email" class="text-xs font-bold" style="color: #e8532c">
                                {{ i18n.users_email_is_invalid }}
                            </p>
                        </div>
                    </div>
                </div>
                <!--footer-->
                <div class="flex items-center justify-center p-6 gap-6 rounded-b">
                    <button (click)="resetPassword()" [ngClass]="{'disabled:opacity-20 cursor-not-allowed':!resetForm.valid}" [disabled]="!resetForm.valid" class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                        {{i18n.login_next}}
                    </button>
                    <button (click)="closeModal()" class="bg-white font-semibold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" style="color: #233674" type="button" >
                        {{i18n.login_cancel}}
                    </button>
                </div>
            </form>
        </ng-container>
    </div> 
</app-generic-modal>