import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-help-links',
  templateUrl: './help-links.component.html',
  styleUrls: ['./help-links.component.scss']
})
export class HelpLinksComponent implements OnInit {
  @Input() support_email: string;
  i18n: any = [];

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getTranslations();
  }

  getTranslations(): void{
    this.i18n = this.sharedService.getTranslationsOf('Login');
  }
}
