<div class="flex bg-customWhite-default shadow-xl rounded-lg" style="height: 312px;">
    <div class="flex flex-col w-full">
            <div class="flex flex-row items-center justify-between border-b border-solid border-customBlue-superLight rounded-t w-full" style="height: 45px;">
                <div class="flex">
                    <div class="title">
                      {{i18n.reports_activies}}
                    </div>
                    <button id="tooltip" class="w-5 h-5">
                        <div class="circle-interrogation-icon interrogation__icon" ></div>
                        <span id="tooltipText">{{i18n.reports_interroga_activities}}</span>
                    </button>
                </div>
                <div class="toView" (click)="showBiggerScreen()" *ngIf="isEmpty == false">
                  {{i18n.reports_view}}
                </div>
            </div>

        <div class="scroll-container-y" *ngIf="isEmpty == false">
          <div class="scroll-container-x">
            <div class="flex flex-row h-full w-full gap-4">
                <div class="title-col">
                    <div class="header-title pl-14">
                       <p>{{i18n.reports_title}}</p>
                    </div>
                    <div class="title-text-container" *ngFor="let content of activities">
                        <i class="book__icon color-gray-icon"></i>
                        <p class="truncate">{{content.content_name}}</p>
                    </div>
                </div>
                <div class="submitted-col">
                    <div class="header-title">
                        <p>{{i18n.reports_delivered}}</p>
                    </div>
                    <div class="element-text-container" *ngFor="let content of activities">
                        <p>{{content.submitted}}</p>
                    </div>
                </div>
                <div class="pending-col">
                    <div class="header-title">
                        <p>{{i18n.reports_pending}}</p>
                    </div>
                    <div class="element-text-container" *ngFor="let content of activities">
                        <p>{{content.pending}}</p>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <!-- Empty Activities  -->
        <app-empty-data *ngIf="isEmpty === true" 
        class="flex justify-center items-center h-full"></app-empty-data>
    </div>
</div>

