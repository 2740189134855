<div class="flex bg-customWhite-default shadow-xl rounded-lg" style="height: 312px;">
    <div class="flex flex-col w-full">
        <div class="flex flex-row items-center justify-between border-b border-solid border-customBlue-superLight rounded-t w-full" style="height: 45px; ">
        <div class="flex">
            <div class="title">
              {{i18n.reports_certificate_reports}}
            </div>
            <button id="tooltipCertificate" class="w-5 h-5">
                <div class="circle-interrogation-icon interrogation__icon" ></div>
                <span id="tooltipTxtCertificate">{{i18n.reports_interroga_certificates}}</span>
            </button>
            </div>
            <div class="toView" (click)="showBiggerScreen()" *ngIf="isEmpty == false">
              {{i18n.reports_view}}
            </div>
        </div>
        <div class="scroll-container-y" *ngIf="isEmpty == false">
            <div class="scroll-container-x">
                <div class="flex flex-row h-full w-full gap-4">
                    <div class="title-col">
                        <div class="header-title pl-14">
                           <p>{{i18n.reports_student}}</p>
                        </div>
                        <div class="title-text-container" *ngFor="let certificate of certificatesData">
                            <div>
                                <app-user-frame
                                [user_name]="certificate.user_name"
                                [img_src]="certificate.user_image"
                                [adaptable_size]="true"
                                [style]="{'width':'23px', 'height':'23px', 'font-size': '15px', 'font-weight': 'bold'}"
                                ></app-user-frame>
                            </div>
                            <p class="truncate">{{certificate.user_name}}</p>
                        </div>
                    </div>
                    <div class="submitted-col">
                        <div class="header-title">
                            <p>{{i18n.reports_status}}</p>
                        </div>
                        <div class="element-text-container" *ngFor="let certificate of certificatesData">
                            <a class="containerGreen" [href]="certificate.certificate_link" *ngIf="certificate.certificate_emitted_at != null" style="cursor: pointer;">
                                <p>{{i18n.reports_available}}</p>
                            </a>
                            <div class="containerGray" *ngIf="certificate.certificate_emitted_at == null">
                                <p>{{i18n.reports_unavailable}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <!-- Empty Certificates  -->
        <app-empty-data *ngIf="isEmpty === true"
        class="flex justify-center items-center h-full"></app-empty-data>
    </div>
</div>
