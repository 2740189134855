<div class="fake-input" *ngIf="viewOnly">
  <span class="student-answer">{{ previousText }}</span>
</div>

<input
  *ngIf="!viewOnly"
  (keyup)="writeOnData($event)"
  class="real-input"
  type="text"
  [value]="previousText || ''"
  [placeholder]="i18n.question_bank_student_answer"
/>
