<div 
    class="relative shadow-card rounded-3xl bg-customWhite-default p-4 mb-8"
    *ngFor="let forum of forums; let indexForum = index"
>
    <div 
        class="flex relative items-center rounded-md animate-pulse"
        style="height: 16px;background-color: #DEDFE3; width: 250px;"
    ></div>
    
    <div 
        class="gap-2 pl-0 p-4"
    >
        <ng-container 
            *ngFor="let line of forums[indexForum]?.lines; let i = index"
        >
            
            <div 
                class="flex relative items-center rounded-md animate-pulse mb-1"
                style="height: 8px;background-color: #DEDFE3; "
                [style.width]="line['width_description' + i] + '%'"
            ></div>
        </ng-container>

    </div>

    <div 
        class="flex items-center justify-between"
    >
        <div
            class="rounded-full"
            style="background-color: #DEDFE3"
            [style]="{'width':'22px', 'height':'22px', 'font-size': '15px', 'font-weight': 'bold'}"
        >
        </div>
        <div class="flex justify-center items-center gap-2">
            <div
                class="rounded-full"
                style="background-color: #DEDFE3"
                [style]="{'width':'22px', 'height':'22px', 'font-size': '15px', 'font-weight': 'bold'}"
            ></div>
            <div 
                class="flex relative items-center rounded-md animate-pulse"
                style="height: 32px;background-color: #DEDFE3; width: 50px;"
            ></div>
            <div 
                class="flex relative items-center rounded-md animate-pulse"
                style="height: 32px;background-color: #DEDFE3; width: 75px;"
            ></div>
        </div>
    </div> 
</div>