<section
  class="px-8 my-8 py-8 shadow-card round-card transition ease-out duration-180 animate-fade-in-down bg-customWhite-default"
>
    <h1 class="text-customBlue-default font-bold text-xl">{{ i18n.disciplines_settings_general }}</h1>
    <ng-container *ngIf="!isLoading">
        <form [formGroup]="settingsGeneralForm">
            <div class="flex flex-col py-2">
                <div class="my-2">
                    <span class="text-left text-customGray-dark">
                        {{ i18n.disciplines_settings_general_standard_ordering_content }}
                    </span>
                </div>
                <div>
                    <select
                        (change)="changeConfigForm()"
                        class="dropdown text-customGray-default"
                        style="white-space: nowrap; text-overflow: ellipsis; white-space: nowrap; background-color: transparent"
                        required
                        formControlName="default_ordering"
                    >
                        <option 
                            [value]="true"
                            class="text-customGray-default"
                        >
                            {{i18n.disciplines_settings_general_most_recent_appears_end}}
                        </option>
                        <option 
                            [value]="false"
                            class="text-customGray-default"
                        >
                            {{i18n.disciplines_settings_general_most_recent_appears_top}}
                        </option>
                    </select>
                    
                </div>
            </div>
            <div class="flex row justify-end">
                <div class="">
                    <button
                        class="border rounded-md py-2 px-8 font-bold text-sm border-customGray-textGray"
                        [ngClass]="{
                            'disabled:opacity-20 cursor-not-allowed': !buttonActive
                        }"
                        style="color: #ffffff; background-color: #233674"
                        (click)="postGeneralSettings()"
                        [disabled]="!buttonActive"
                    >
                        {{ i18n.disciplines_save }}
                    </button>
                </div>
            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="isLoading">
        <div class="flex justify-center items-center" style="height: 300px;">
            <app-bullets-loader></app-bullets-loader>
        </div>
    </ng-container>
</section>