<section class="bg-customWhite-default shadow-2xl rounded-lg">
  <header class="flex flex-row w-full justify-end items-center p-2">
    <button class="x-close__icon" (click)="showMainScreen()"></button>
  </header>
  <form [formGroup]="activeStudentsForm">
    <div class="flex flex-col lg:items-center lg:flex-row gap-2 p-4">
      <div class="search-form w-full">
        <div class="input-search-container">
          <label for="search">
            <img />
          </label>
          <input
            formControlName="q"
            id="search"
            class="input-search"
            [placeholder]="i18n.reports_search"
          />
        </div>
      </div>

      <div class="w-full flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4">
        <p class="whitespace-nowrap">
          {{ i18n.reports_period }}
        </p>
        <app-picker-calendar
        class="block w-full"
        [i18n]="i18n"
        [currentLanguage]="currentLanguage"
        [editMode]="true"
        [initialDate]="initialDate"
        [Form]="activeStudentsForm"
        [inputNameControlPicker]="'iniDate'"
        [inputControlNameMask]="'calendarStartDate'"
        [position]="'absolute'"
      ></app-picker-calendar>
      </div>
      <div class="w-full flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4">
        <p class="whitespace-nowrap">
          {{ i18n.reports_until }}
        </p>
        <app-picker-calendar
        class="block w-full"
        [i18n]="i18n"
        [currentLanguage]="currentLanguage"
        [editMode]="true"
        [initialDate]="initialDate"
        [Form]="activeStudentsForm"
        [inputNameControlPicker]="'finDate'"
        [inputControlNameMask]="'calendarEndDate'"
        [position]="'absolute'"
        ></app-picker-calendar>
      </div>

      <div class="w-full flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4">
        <p class="whitespace-nowrap">{{ i18n.reports_group_by }}</p>
        <select class="select-groups " maxlength="42" formControlName="group">
          <option disabled hidden [value]="''"></option>
          <option
            *ngFor="let group of groupSelect"
            [value]="group.value"
            [ngValue]="group.value"
          >
            {{ group.title }}
          </option>
        </select>
      </div>

      <button class="button-save" (click)="doQuery()">
        {{ i18n.reports_filter }}
      </button>
    </div>
      <div class="flex flex-col gap-2 lg:flex-row xl:flex-row p-4">
          <div class="exportTxt flex justify-center">
              <p>{{i18n.reports_export}}</p>
          </div>
        <div class="flex xs:flex-col flex-row gap-2">
          <div class="button-export" (click)="getFile(2, 'xlsx')">
              <p>XLSX</p>
          </div>
          <div class="button-export" (click)="getFile(3, 'csv')">
              <p>CSV</p>
          </div>
          <div class="button-export" (click)="getFile(1, 'pdf')">
              <p>PDF</p>
          </div>
          <div class="button-export" (click)="getUrl(4, 'gsheet')" *ngIf="isNotStandalone">
            <p>{{i18n.reports_google_sheets}}</p>
          </div>
        </div>
      </div>
  </form>

  <div
  class="p-4 scroll-container-y justify-center align-bottom"
  *ngIf="!loading"
>
  <div class="scroll-container-x justify-center align-bottom">
    <div #containerRef class="flex flex-row w-full h-full">
      <div class="chartContainer flex justify-center">
        <ngx-charts-bar-vertical
          [dataLabelFormatting]="formatDataLabel"
          [yScaleMax]="yScaleMax"
          [showDataLabel]="showDataLabel"
          [view]="[1180, 400]"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [results]="formatedActStudents"
          [barPadding]="barPadding"
          [rotateXAxisTicks]="rotateXAxisTicks"
          [animations]="animations"
          [maxXAxisTickLength]="maxXAxisTickLength"
          [yAxisTickFormatting]="yTickFormattingFn"
          [xAxisTickFormatting]="xTickFormattingFn"
          style="fill: #000000"
        >
          <ng-template #tooltipTemplate let-model="model">
            <pre class="my-3">{{ (model.value * 100).toFixed(2) + ' %'}}</pre>
          </ng-template>
        </ngx-charts-bar-vertical>
      </div>
    </div>
  </div>
</div>
<div class="loading-container" *ngIf="loading">
  <div class="loading">
    <img src="../../../../../../../../assets/svg/loading.svg" />
    <p>{{ i18n.reports_wait }}</p>
    <p>{{ i18n.reports_we_are_loading }}</p>
  </div>
</div>
</section>