<div *ngIf="student?.average !== null" class="flex justify-end my-4">
  <div
    class="text-center text-customBlue-default p-2 px-20 bg-customWhite-default rounded-md"
  >
    <div class="text-16 font-semibold">
      {{ i18n.grades_final_grade }}
    </div>
    <div class="text-40 font-semibold leading-none">
      {{ student?.average }}
    </div>
  </div>
</div>
<ng-container *ngFor="let category of student?.categories; let i = index">
  <div
    class="bg-customWhite-default mb-8 rounded-md"
    style="width: auto; overflow: auto"
  >
    <table class="bg-customWhite-default rounded-md w-full rounded-t-lg">
      <thead>
        <tr>
          <th
            colspan="5"
            class="bg-card-student-grade rounded-t-lg p-2 pl-8 text-left text-customBlue-default font-bold text-18 opacity-100"
          >
            {{ category?.name }}
          </th>
        </tr>
        <tr class="header">
          <th class="text-left p-4" style="width: 440px">
            {{ i18n.grades_lesson }}
          </th>
          <th></th>
          <ng-container *ngIf="student?.calculation_type === 'sum'">
            <th>
              {{ i18n.grades_maximum_score_activity }}
            </th>
          </ng-container>
          <ng-container *ngIf="student?.calculation_type === 'weighted_average'">
            <th>
              {{ i18n.grades_grade_activity_weight }}
            </th>
          </ng-container>
          <th>
            {{ i18n.grades_grade_received }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let grade of category[category.name]; let y = index">
          <td>
            <div class="p-4 mb-2 text-customGray-dark">
              {{ grade.content_title }}
            </div>
          </td>
          <td>
            <p
              *ngIf="grade.content_substitutive_id"
              class="py-1 px-2 bg-customBlue-royal text-customWhite-default rounded w-max"
            >
              {{ i18n.disciplines_content_substitute_activity_title }}
            </p>
          </td>
          <td>
            <div
              class="text-center border text-customGray-dark border-customGray-mediumGray rounded-sm p-2 px-4 mx-4"
            >
              {{ grade.content_weight }}
            </div>
          </td>
          <td>
            <div
              class="text-center border text-customGray-dark border-customGray-mediumGray rounded-sm p-2 px-4 mx-4"
              [ngClass]="{ 'bg-no-evaluation': grade?.evaluation == null }"
            >
              <div>
                {{ grade.evaluation }}
              </div>
              <div *ngIf="grade?.evaluation == null">
                {{ i18n.grades_no_grade }}
              </div>
            </div>
          </td>
          <td>
            <div class="flex p-2 gap-x-1.5 items-center text-center">
              <button
                (click)="goToContent(grade)"
                class="p-2 px-4 flex items-center justify-center"
                style="
                border: 1px solid #A9AEB3;
                border-radius: 5px;
                min-width: 172.28px;
                height: 41px;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #484B4D;
                "
              >
                {{ i18n.grades_view_lesson }}
              </button>
              <a target="_blank" href="/disciplines/{{discipline_external_id}}/{{grade.content_section_ext_id}}/homework-submissions/{{grade.content_external_id}}/submissions" rel="noopener noreferrer"
                pTooltip="{{ i18n.disciplines_open_new_window }}" tooltipPosition="top" pRipple
                class=" text-customGray-default flex items-center justify-center"
                style="
                border: 1px solid #A9AEB3;
                border-radius: 5px;
                width: 41px;
                height: 41px;
                ">
                <span class="iconify" data-icon="ic:baseline-open-in-new" style="min-width: 26px; min-height: 26px;"></span>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
