import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AccessibilityService } from '../../../../components/header/accessibility/accessibility.service';

@Component({
    selector: 'app-accessibility-login',
    templateUrl: './accessibility-login.component.html',
    styleUrls: ['./accessibility-login.component.scss']
})

export class AccessibilityLoginComponent implements OnInit {
    form: FormGroup;
    @Input() i18n: any = {};

    constructor(private fb: FormBuilder, private accessibilityService: AccessibilityService) { }

    ngOnInit(): void {
        this.createForm();
        // Base para utilização do UserWay
        this.accessibilityService.createUserWayStyleElement();
        this.accessibilityService.checkUserWayLocalStorage();

        // Base para utilização do VLibras
        this.accessibilityService.checkVLibrasLocalSotrage();

        // Atualiza o input de controle de acordo com o storage (Ativo/Inativo)
        this.form.controls.accessibility.setValue(this.accessibilityService.isUserWayChecked);
    }

    createForm(): void {
        this.form = this.fb.group({
            accessibility: [false]
        });
    }

    // Ativa/Inativa as acessibilidades
    toggleAccessibility(): void {
        this.accessibilityService.isUserWayChecked = this.form.controls.accessibility.value;
        this.accessibilityService.isVLibrasChecked = this.form.controls.accessibility.value;

        this.accessibilityService.checkUserWay();
        this.accessibilityService.checkVLibras();
    }
}
