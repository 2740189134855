import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_Routes } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DisciplineSettingsTabsService {
  constructor(private http: HttpClient) {}

  getDisciplineSettingsTab(external_id: any): Observable<any> {
    return this.http
      .get<any>(`${API_Routes.URL}/disciplines/${external_id}/settings/tabs
  `);
  }

  postDisciplineSettingsTab(external_id: any, params: any): Observable<any> {
    return this.http.patch(
      `${API_Routes.URL}/disciplines/${external_id}/settings/tabs`,
      params
    );
  }
}
