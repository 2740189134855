<section class="text-customBlue-default">
  <h1 class="text-22 font-bold">{{ i18n.planner_for_class_to_class }}</h1>

  <header class="mt-2 mb-5">
    <h2 class="font-extrabold">
      {{ i18n.planner_step_three_detailed_planning }}
    </h2>
    <p>{{ i18n.planner_choose_module_and_explaining_class }}</p>
  </header>

  <article
    class="bg-customWhite-default p-8 border border-dashed border-customGray-mediumGray"
  >
    <header>
      <p class="font-semibold mb-2 text-16 font-Montserrat">
        {{ i18n.planner_planning_class }}
      </p>
      <div
        *ngIf="planningDetail"
        class="max-w-xl rounded bg-customBlue-royal bg-opacity-5 pl-4 py-1"
      >
        <p class="text-19 font-Roboto font-medium">
          {{ planningDetail?.degree_compoente }}
        </p>
      </div>
    </header>

    <form [formGroup]="form">
      <div id="modules" class="flex flex-col gap-6 mt-6">
        <div *ngFor="let module of modulesList; let i = index" class="">
          <div>
            <div
              id="header-module"
              class="flex-col sm:flex-row justify-between items-center px-4 py-1 bg-customBlue-royal text-customWhite-default rounded-t-md"
            >
              <div class="flex flex-col">
                <div class="text-16 font-normal font-roboto">
                  {{ i18n.planner_section }}
                </div>
                <div class="text-20 font-medium font-roboto">
                  {{ module.name }}
                </div>
              </div>
              <div class="flex gap-2">
                <div
                  class="bg-customWhite-default rounded py-1 flex items-center justify-center"
                  style="min-width: 185px"
                >
                  <span
                    class="text-16 font-medium font-roboto text-customBlue-default"
                  >
                    1 {{ i18n.planner_created_class }}
                  </span>
                </div>
                <div class="cursor-pointer" (click)="toggleExpand(i)">
                  <button [ngClass]="{ 'rotate-180': isExpanded[i] }">
                    <span
                      class="iconify text-customWhite-default"
                      data-icon="raphael:arrowdown"
                      data-width="18"
                      data-height="18"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
            <div id="header-content" class="mt-1" *ngIf="isExpanded[i]">
              <div
                class="flex flex-col gap-4 mb-2"
                *ngFor="let class of module?.classes; let k = index"
              >
                <div
                  class="flex flex-col gap-6 border border-customGray-mediumGray rounded p-4 border-l-8"
                >
                  <div class="flex flex-col sm:flex-row justify-between">
                    <div>
                      <p class="font-medium mb-1">
                        {{ i18n.planner_class }} {{ k + 1 }}
                      </p>
                    </div>
                    <div class="flex justify-end sm:justify-center items-center gap-2">
                      <button
                        *ngIf="isExpandedClass[k]"
                        (click)="openModalResource(module, class.id)"
                        class="flex items-center justify-center h-9 w-48 rounded border border-customBlue-glass"
                      >
                        {{ i18n.planner_add_resource }}
                      </button>
                      <button (click)="decisionDeleteClass(class.id)">
                        <span
                          class="iconify text-customBlue-default cursor-pointer"
                          data-icon="bi:trash"
                        ></span>
                      </button>
                      <button (click)="toggleExpandClass(k)">
                        <div [ngClass]="{ 'rotate-180': isExpandedClass[k] }">
                          <span
                            class="iconify text-customBlue-default"
                            data-icon="raphael:arrowdown"
                            data-width="18"
                            data-height="18"
                          ></span>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="flex flex-col gap-6" *ngIf="isExpandedClass[k]">
                    <label for="description"
                      >{{ i18n.planner_class_description }}:</label
                    >
                    <iframe
                      width="100%"
                      height="480px"
                      [src]="class.url_file"
                      height="480px"
                    ></iframe>
                    <div
                      class="mt-8 flex flex-col gap-2"
                      *ngIf="class.resources.length > 0"
                    >
                      <div>
                        <span class="font-Roboto text-16">
                          {{ i18n.planner_resource_of_planning }}
                        </span>
                      </div>
                      <div
                        *ngFor="let resource of class.resources; let j = index"
                        class="flex items-center gap-2 border border-customBlue-royal rounded p-2 justify-between"
                      >
                        <div class="flex items-center gap-2">
                          <app-types-icons
                            [type]="resource?.type"
                          ></app-types-icons>
                          <span class="text-16 font-Roboto">
                            {{ contentTypes[resource?.type] }}
                          </span>
                        </div>
                        <div class="flex items-center gap-2">
                          <button (click)="editComponent(module, resource)">
                            <span
                              class="iconify text-customBlue-default cursor-pointer"
                              data-icon="tabler:edit"
                              data-width="20"
                              data-height="20"
                            ></span>
                          </button>
                          <button (click)="decisionDeleteResource(resource)">
                            <span
                              class="iconify text-customBlue-default cursor-pointer"
                              data-icon="bi:trash"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="mb-8">
                      <div>
                        <fieldset
                          class="flex flex-col gap-1 justify-items-start"
                        >
                          <label for="skills">{{
                            i18n.planner_select_skills
                          }}</label>
                          <div class="multi-select">
                            <p-multiSelect
                              [options]="module?.skills"
                              formControlName="{{ 'skills_' + class.id }}"
                              [displaySelectedLabel]="true"
                              [filter]="false"
                              [style]="{ width: '100%' }"
                              optionLabel="description"
                              optionValue="id"
                              [placeholder]="i18n.planner_select"
                              (onChange)="setSkillsItem($event.value, class.id)"
                            >
                              <ng-template pTemplate="header">
                                <p class="multiSelect-all-label">
                                  {{ i18n.planner_select_all }}
                                </p>
                              </ng-template>
                            </p-multiSelect>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="isExpanded[i]"
            class="flex w-full justify-center"
            style="margin-top: -20px"
          >
            <button
              (click)="decisionCreateClass(module)"
              type="button"
              class="w-10 h-10 bg-customOrange-default text-customWhite-default rounded-full flex justify-center items-center font-bold text-18 button-module-hover"
              tooltipPosition="bottom"
              pRipple
            >
              <span class="iconify" data-icon="ic:baseline-plus"></span>
            </button>
          </div>
        </div>
      </div>
    </form>

    <footer>
      <div class="flex flex-col sm:flex-row justify-center items-center sm:justify-end gap-2 mt-8">
        <button
          (click)="decisionSavePlanning('draft')"
          class="bg-customWhite-default rounded border border-customBlue-default w-56 h-9"
        >
          <span class="text-customBlue-default font-Roboto">{{
            i18n.planner_save_as_draft
          }}</span>
        </button>
        <button
          (click)="decisionSavePlanning('published')"
          class="bg-customBlue-default text-customWhite-default rounded border border-customBlue-default w-56 h-9"
        >
          {{ i18n.planner_save }}
        </button>
      </div>
    </footer>
  </article>
</section>
<app-modal-success-planning
  [i18n]="i18n"
  [isVisibleSuccessPlanning]="isVisibleSuccessPlanningModal"
  [message]="i18n.planner_planning_created_successfully"
  (closeModalSuccessPlanning)="controlModalSuccess($event)"
>
</app-modal-success-planning>
<app-modal-resource
  [isVisible]="isVisibleResourceModal"
  [classId]="classIdResource"
  [module]="moduleResources"
  (closeModalResource)="closeModalResource()"
></app-modal-resource>

<app-modal-loading
  [isVisible]="isVisibleModalLoading"
  [message]="messageModalLoading"
></app-modal-loading>
