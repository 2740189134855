import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlusButtonComponent } from './plus-button/plus-button.component';
import { SelectButtonComponent } from './select-button/select-button.component';
import { CheckButtonComponent } from './check-button/check-button.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    PlusButtonComponent,
    SelectButtonComponent,
    CheckButtonComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
  ],
  exports: [
    PlusButtonComponent,
    SelectButtonComponent,
    CheckButtonComponent,
  ]
})
export class ButtonsModule { }
