<app-form-modal
  [title]="i18n.question_bank_edit_question"
  [description]="i18n.question_bank_review_the_fields_and_save_changes"
  [isVisible]="isVisible"
>
<form [formGroup]="form">
  <div class="container" *ngIf="!down" [class.is-dying]="loadingEdition">
    <div class="title">
      {{ i18n.question_bank_title }}
      <app-editor [height]="300" [form]="form" [formControlName]="'title'"></app-editor>
    </div>

    <div class="type-and-tag">
      <div class="type">
        {{ i18n.question_bank_type }}

        <div class="type__selected">{{ activeQuestionTypeTranslation }}</div>
      </div>

      <div class="tag">
        {{ i18n.question_bank_tags }}

        <div class="search-tags" [class.is-active]="tagsSugestionExpanded">
          <input
            formControlName="searchTagsInput"
            class="search-tags__input"
            [placeholder]="i18n.question_bank_add_a_tag"
            spellcheck="false"
            autocomplete="false"
            (input)="generateTagsSugestions()"
            (click)="generateTagsSugestions()"
            [class.is-active]="tagsSugestionExpanded"
          />

          <i class="tag-icon icon"></i>

          <div
            #tagSugestion
            class="search-tags__sugestions"
            [class.is-invisible]="!tagsSugestionExpanded"
          >
            <div
              class="sugestions__sugestion"
              *ngFor="let tag of sugestionTags; let index = index"
              (click)="selectTag(tag, index)"
            >
              <i class="tag-icon icon"></i>

              {{ tag.text }}
            </div>
          </div>

          <div
            class="search-tags__sugestions"
            *ngIf="
              form.controls.searchTagsInput.value.length &&
              !sugestionTags.length &&
              !tagAlreadySelected
            "
          >
            <div
              class="sugestions__sugestion create-new"
              (click)="selectTag({ text: form.controls.searchTagsInput.value })"
            >
              {{ i18n.question_bank_add_tag }} "{{ form.controls.searchTagsInput.value }}"
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="selected-tags-wrapper" *ngIf="selectedTags.length">
      <div class="selected-tags">
        <div
          class="selected-tags__tag"
          *ngFor="let tag of selectedTags; let index = index"
        >
          {{ tag.text }}

          <i class="x-icon icon" (click)="unselectTag(index)"></i>
        </div>
      </div>
    </div>

    <div class="description">
      {{ i18n.question_bank_description }}
      <app-editor [height]="300" [form]="form" [formControlName]="'description'"></app-editor>
    </div>

    <div class="alternative-and-options"  
    [ngClass]="{'d-hidden': activeQuestionType === 'TEXT' || activeQuestionType === 'PARAGRAPH_TEXT'}">
      {{ i18n.question_bank_alternative_and_options }}

      <!-- The tag below should be a div, not a ng-container, 'cause JS expects it to be a block -->
      <div #alternatives [ngSwitch]="activeQuestionType">
        <app-create-paragraph-text
          #questionCreation
          *ngSwitchCase="'PARAGRAPH_TEXT'"
          (change)="retriveQuestionData($event)"
          [data]="data">
        </app-create-paragraph-text>
        <app-create-text
          #questionCreation
          *ngSwitchCase="'TEXT'"
          (change)="retriveQuestionData($event)"
          [data]="data">
        </app-create-text>
        <app-create-scale
          #questionCreation
          *ngSwitchCase="'SCALE'"
          (change)="retriveQuestionData($event)"
          [data]="data"
        ></app-create-scale>
        <app-create-list
          #questionCreation
          *ngSwitchCase="'LIST'"
          (change)="retriveQuestionData($event)"
          [data]="data"
        ></app-create-list>
        <app-create-multiple-choice
          #questionCreation
          *ngSwitchCase="'MULTIPLE_CHOICE'"
          (change)="retriveQuestionData($event)"
          [data]="data"
        ></app-create-multiple-choice>
        <app-create-checkbox
          #questionCreation
          *ngSwitchCase="'CHECKBOX'"
          (change)="retriveQuestionData($event)"
          [data]="data"
        ></app-create-checkbox>
        <app-create-grid-multiple-choice
          #questionCreation
          *ngSwitchCase="'GRID'"
          (change)="retriveQuestionData($event)"
          [data]="data"
        ></app-create-grid-multiple-choice>
        <app-create-grid-checkbox
          #questionCreation
          *ngSwitchCase="'CHECKBOX_GRID'"
          (change)="retriveQuestionData($event)"
          [data]="data"
        ></app-create-grid-checkbox>
      </div>
    </div>

    <!-- The editor has by default at a minimum of 7 characters, even if you erase everything it returns '<p></p>', which has the length of 7 -->
    <app-custom-dropdown
      [title]="i18n.question_bank_feedback"
      *ngIf="hasFeedbackSupport"
      [marked]="form.controls.positiveFeedback.value.length > 7 || form.controls.negativeFeedback.value.length > 7"
    >
      <div class="feedback">
        <div class="feedback__answer">
          {{ i18n.question_bank_positive_feedback }}
          <app-editor [height]="300" [form]="form" [formControlName]="'positiveFeedback'"></app-editor>
        </div>

        <div class="feedback__answer">
          {{ i18n.question_bank_negative_feedback }}
          <app-editor [height]="300" [form]="form" [formControlName]="'negativeFeedback'"></app-editor>
        </div>
      </div>
    </app-custom-dropdown>

    <div class="decision-btns center">
      <div class="btn backward" (click)="destroy()">
        {{ i18n.question_bank_go_back }}
      </div>
      <div
        class="btn forward"
        [class.disabled]="!questionData.ready || !form.controls.title.value"
        (click)="editQuestion()"
      >
        <span *ngIf="!loadingEdition">{{ i18n.question_bank_edit }}</span>
        <app-loading-balls-effect
          *ngIf="loadingEdition"
          [color]="'#fff'"
        ></app-loading-balls-effect>
      </div>
    </div>

    <div class="white-fadeout-canvas" *ngIf="fadeoutCanvas">
      <div class="edited-successfully edition-result">
        <!-- ANIMATED SVG SUCCESSFULLY -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 48 48"
          class="edition-result__icon"
        >
          <g
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          >
            <path
              fill="transparent"
              stroke="#000"
              d="M24 4L29.2533 7.83204L35.7557 7.81966L37.7533 14.0077L43.0211 17.8197L41 24L43.0211 30.1803L37.7533 33.9923L35.7557 40.1803L29.2533 40.168L24 44L18.7467 40.168L12.2443 40.1803L10.2467 33.9923L4.97887 30.1803L7 24L4.97887 17.8197L10.2467 14.0077L12.2443 7.81966L18.7467 7.83204L24 4Z"
              class="tblUcdcz_0"
            ></path>
            <path
              stroke="#000"
              d="M17 24L22 29L32 19"
              class="tblUcdcz_1"
            ></path>
          </g>
          <style data-made-with="vivus-instant">
            .tblUcdcz_0 {
              stroke-dasharray: 131 133;
              stroke-dashoffset: 132;
              animation: tblUcdcz_draw 1850ms ease-in-out 0ms forwards;
            }
            .tblUcdcz_1 {
              stroke-dasharray: 22 24;
              stroke-dashoffset: 23;
              animation: tblUcdcz_draw 1850ms ease-in-out 0ms forwards;
            }
            @keyframes tblUcdcz_draw {
              100% {
                stroke-dashoffset: 0;
              }
            }
            @keyframes tblUcdcz_fade {
              0% {
                stroke-opacity: 1;
              }
              93.38842975206612% {
                stroke-opacity: 1;
              }
              100% {
                stroke-opacity: 0;
              }
            }
          </style>
        </svg>

        {{ i18n.question_bank_edited }}!
      </div>
    </div>
  </div>
</form>
</app-form-modal>
