import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DisciplineService {
  constructor(private http: HttpClient) {}

  getDisciplines(
    types: any,
    query: any,
    level: any,
    modalities: any,
    months: any,
    years: any,
    page?: number,
    per_page?: number,
  ): Observable<any> {
    return this.http.get<any>(
      `${API_Routes.URL}/disciplines/?type=${types}&q=${query}&levels=${level}&modalities=${modalities}&months=${months}&years=${years}&page=${page}&per_page=${per_page}`
    );

    // return this.http.get<any>(`http://localhost:3000/discplines`);
  }

  postDisciplines(params: any): Observable<any> {
    return this.http.post(`${API_Routes.URL}/disciplines/`, params);
  }

  deleteDisciplines(id: string | number) {
    return this.http.delete(`${API_Routes.URL}/disciplines/${id}`);
  }

  archiveDiscipline(external_id: string): Observable<any>{
    return this.http.post<any>(`${API_Routes.URL}/disciplines/${external_id}/archive`, {});
  }

  unarchiveDiscipline(external_id: string): Observable<any>{
    return this.http.post<any>(`${API_Routes.URL}/disciplines/${external_id}/unarchive`, {});
  }

  patchDiscipline(external_id, params: { description: string }): Observable<any> {
    return this.http.patch<any>(`${API_Routes.URL}/disciplines/${external_id}/`, params)
  }

  subscribeDiscipline(discipline_external_id: any): Observable<any> {
    return this.http.post<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/subscribe`, {});
  }
  
  postDuplicate(discipline_external_id: string, params: any) {
    return this.http.post(`${API_Routes.URL}/disciplines/${discipline_external_id}/duplicate`, params).pipe(take(1));
  }
}
