import { SharedModule } from './../../shared/shared.module';
import { CertificatesValidatorRoutingModule } from './certificates-validator-routing.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CertificatesValidatorComponent } from './certificates-validator.component';
import { MessageModalModule } from 'src/app/components/modals/message-modal/message-modal.module';
import { ValidateComponent } from './components/validate/validate.component';
import { ValidatorComponent } from './components/validator/validator.component';

@NgModule({
  declarations: [CertificatesValidatorComponent, ValidatorComponent, ValidateComponent],
  imports: [
    CommonModule,
    CertificatesValidatorRoutingModule,
    SharedModule,
    MessageModalModule,
  ],
})
export class CertificatesValidatorModule { }
