import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { HomeLayoutComponent } from 'src/app/pages/home/home.component';

import { CurrentYearComponent } from './components/planning/current-year/current-year.component';
import { CurricularParametersComponent } from './components/planning/curricular-parameters/curricular-parameters.component';
import { DetailedClassToClassComponent } from './components/planning/detailed-planning/detailed-class-to-class/detailed-class-to-class.component';
import { DetailedEstimatedComponent } from './components/planning/detailed-planning/detailed-estimated/detailed-estimated.component';
import { MyPlanningComponent } from './components/planning/my-planning/my-planning.component';
import { StartPlanningComponent } from './components/planning/start-planning/start-planning.component';
import { ContentPlannerComponent } from './content-planner.component';

const routes: Routes = [
    {
        path: '',
        component: HomeLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: ContentPlannerComponent,
            },
            {
                path: 'start-planning',
                component: StartPlanningComponent,
            },
            {
                path: 'start-planning/:planning_external_id',
                component: StartPlanningComponent,
            },
            {
                path: 'curricular-parameters/:planning_external_id',
                component: CurricularParametersComponent,
            },
            {
                path: 'detailed-estimated/:planning_external_id/:type',
                component: DetailedEstimatedComponent,
            },
            {
                path: 'detailed-class-to-class/:planning_external_id/:type',
                component: DetailedClassToClassComponent,
            },
            {
                path: 'current-year/:planning_external_id',
                component: CurrentYearComponent
            },
            {
                path: 'my-planning/:planning_external_id',
                component: MyPlanningComponent
            },
        ]
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ContentPlannerRoutingModule { }
