<div class="mt-20">
    <!-- Title my importations -->
    <div>
        <h1 class="text-customBlue-default font-bold text-19 font-montserrat">
            {{ i18n?.import_spreadsheet_google_sheet_my_importations }}
        </h1>
        <div>
            <span
                *ngIf="data?.length > 0"
                class="text-14 sm:text-16 font-roboto text-customGray-dark font-normal"
            >
                {{ i18n?.import_spreadsheet_google_sheet_verify }}
            </span>
            <span
                *ngIf="data?.length === 0"
                class="text-14 sm:text-16 font-roboto text-customGray-dark font-normal"
            >
                {{ i18n?.import_spreadsheet_none_importations }}
            </span>
        </div>
    </div>

    <!-- Table -->
    <div
        *ngIf="data?.length > 0"
        class="mt-8 w-full bg-customWhite-default rounded-lg p-5 overflow-x-auto scrollbar-thin scrollbar-thumb-customBlue-default scrollbar-track-customGray-lighterGray"
    >
        <table class="w-full">
            <thead>
                <tr>
                    <th class="text-center">
                        <span
                            class="text-14 sm:text-18 text-customBlue-default font-medium sm:font-bold"
                            >{{ i18n?.import_spreadsheet_table_id }}</span
                        >
                    </th>
                    <th class="text-center">
                        <span
                            class="text-14 sm:text-18 text-customBlue-default font-medium sm:font-bold"
                            >{{ i18n?.import_spreadsheet_table_file_name }}</span
                        >
                    </th>
                    <th class="text-center">
                        <span
                            class="text-14 sm:text-18 text-customBlue-default font-medium sm:font-bold"
                            >{{ i18n?.import_spreadsheet_table_type }}</span
                        >
                    </th>
                    <th class="text-center">
                        <span
                            class="text-14 sm:text-18 text-customBlue-default font-medium sm:font-bold"
                            >{{ i18n?.import_spreadsheet_table_date }}</span
                        >
                    </th>
                    <th class="text-center">
                        <span
                            class="text-14 sm:text-18 text-customBlue-default font-medium sm:font-bold"
                            >{{ i18n?.import_spreadsheet_table_status }}</span
                        >
                    </th>
                    <th class="text-center"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let item of data"
                    class="border-b border-customGray-iconGray sm:border-customGray-lighterGray"
                >
                    <td
                        class="p-3 text-center text-customGray-dark text-12 sm:text-16"
                    >
                        {{ item?.id }}
                    </td>
                    <td
                        class="p-3 text-center text-customGray-dark text-12 sm:text-16"
                    >
                        {{ item?.fileName }}
                    </td>
                    <td
                        class="p-3 text-center text-customGray-dark text-12 sm:text-16"
                    >
                        {{ getTypeImport(item?.settings?.value) }}
                    </td>
                    <td
                        class="p-3 text-center text-customGray-dark text-12 sm:text-16"
                    >
                        {{ formateData(item?.date) }}
                    </td>
                    <td
                        class="p-3 text-center text-customGray-dark text-12 sm:text-14"
                    >
                        <div
                            [ngClass]="{
                                'bg-customBlue-default':
                                    item.status === 'finished',
                                'bg-inicieColors-orange':
                                    item.status !== 'finished'
                            }"
                            class="p-1 bg-customBlue-default rounded border-0"
                        >
                            <span
                                *ngIf="item.status === 'finished'"
                                class="text-customGray-light font-bold"
                            >
                                {{ i18n?.import_spreadsheet_complete }}
                            </span>
                            <span
                                *ngIf="item.status !== 'finished'"
                                class="text-customGray-light font-bold"
                            >
                                {{ i18n?.import_spreadsheet_incomplete }}
                            </span>
                        </div>
                    </td>
                    <td
                        class="p-3 text-center text-customGray-dark text-12 sm:text-14"
                        *ngIf="item.feedbacks"
                    >
                        <button
                            (click)="openModalResume(item)"
                            class="bg-customWhite-default rounded border border-customBlue-default h-9 w-32"
                        >
                            <span
                                class="text-customBlue-default font-normal font-roboto text-14"
                            >
                                {{ i18n?.import_spreadsheet_resume_view }}
                            </span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<app-modal-resume-view
    [i18n]="i18n"
    [isVisible]="isVisibleModalResume"
    (emitCloseModal)="closeModalResume()"
    [data]="itemSelected"
></app-modal-resume-view>
