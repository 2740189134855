import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ContentGuard implements CanActivate {
  constructor(
    private sharedService: SharedService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    let i18n = this.sharedService.getTranslationsOf('Errors');
    let message = i18n.permission_unauthorized;

    if (state.url.includes('content-view')) {
      if (!this.sharedService.checkPermission("contents", 'list')) {
        this.sharedService.showUnauthorizedModal(message);
        return false;
      }
    }

    if (state.url.includes('homework-submissions')) {
      if (!this.sharedService.checkPermission("contents_submission", 'list')) {
        this.sharedService.showUnauthorizedModal(message);
        return false;
      }
    }

    if (state.url.includes('assignment')) {
      if (!this.sharedService.checkPermission("contents", 'list')) {
        this.sharedService.showUnauthorizedModal(message);
        return false;
      }
    }

    if (state.url.includes('assignment')) {
      if (!this.sharedService.checkPermission("contents", 'list')) {
        this.sharedService.showUnauthorizedModal(message);
        return false;
      }
    }

    return true;
  }

}
