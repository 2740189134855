import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ReportsService } from '../../../reports.service';
import { MessengerToastService } from 'src/app/components/messenger-toast/messenger-toast.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';

@Component({
  selector: 'app-large-activities',
  templateUrl: './large-activities.component.html',
  styleUrls: ['./large-activities.component.scss']
})
export class LargeActivitiesComponent implements OnInit {
  external_id: any = []
  reportsData: any = []
  loading: boolean = false
  contentUsersContents: any = []
  activitiesData: any = []

  activitiesForm: UntypedFormGroup

  sortBySelect: any = [

  ]

  sortTypeSelect: any = [

  ]

  daySelect: any = [

  ]

  monthSelect: any = []

  i18n: any = [];
  currentLanguage: any;

  q: string = '';
  month:  string = '';
  day:  string = '';
  sortBy:   string = 'content_name';
  sortType: string = 'ASC';
  isNotStandalone: boolean = false;

  @Output() screen = new EventEmitter();

  constructor(
    public platModalService: PlatformModalsService,
    private reportsService: ReportsService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private toastMessenger: MessengerToastService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.isNotStandalone = this.localStorageService.isNotStandalone();
    this.getUrlParams();
    this.getReportDetail(
      this.external_id,
      'CONTENTS',
      0,
      1,
      this.q,
      this.day,
      this.month,
      this.sortBy,
      this.sortType,
      )
    this.getTranslations();
    this.loadTranslates();
    this.createActivitiesForm();
  }

  loadingTrue() {
    this.platModalService.toggle('loading');
  }

  loadingFalse() {
    this.platModalService.close('loading');
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Reports'),...this.sharedService.getTranslationsOf('Modal')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  loadTranslates() {
    this.monthSelect = [
      {'title': `${this.i18n.reports_jan}`, 'value': '1'},
      {'title': `${this.i18n.reports_fav}`, 'value': '2'},
      {'title': `${this.i18n.reports_mar}`, 'value': '3'},
      {'title': `${this.i18n.reports_abr}`, 'value': '4'},
      {'title': `${this.i18n.reports_mai}`, 'value': '5'},
      {'title': `${this.i18n.reports_jun}`, 'value': '6'},
      {'title': `${this.i18n.reports_jul}`, 'value': '7'},
      {'title': `${this.i18n.reports_ago}`, 'value': '8'},
      {'title': `${this.i18n.reports_sep}`, 'value': '9'},
      {'title': `${this.i18n.reports_out}`, 'value': '10'},
      {'title': `${this.i18n.reports_nov}`, 'value': '11'},
      {'title': `${this.i18n.reports_dez}`, 'value': '12'},
    ]
    this.daySelect = [
      {'title': `${this.i18n.reports_dom}`, 'value': '1'},
      {'title': `${this.i18n.reports_seg}`, 'value': '2'},
      {'title': `${this.i18n.reports_ter}`, 'value': '3'},
      {'title': `${this.i18n.reports_qua}`, 'value': '4'},
      {'title': `${this.i18n.reports_qui}`, 'value': '5'},
      {'title': `${this.i18n.reports_sex}`, 'value': '6'},
      {'title': `${this.i18n.reports_sab}`, 'value': '7'}
    ]

    this.sortBySelect = [
      {'title': `${this.i18n.reports_name}`, 'value': 'content_name'},
      {'title': `${this.i18n.reports_type}`, 'value': 'content_type'},
      {'title': `${this.i18n.reports_delivered}`, 'value': 'emitted'},
      {'title': `${this.i18n.reports_pending}`,   'value': 'pending'},
    ]

    this.sortTypeSelect = [
      {'title': `${this.i18n.reports_ascending}`,  'value': 'ASC'},
      {'title': `${this.i18n.reports_descending}`, 'value': 'DESC'}
    ]
  }

  getReportDetail(disipline, type, format, detail, q, day, month, sortBy, sortType) {
    let  periods = ''
    let  form_group = ''
    this.loading = true
    this.reportsService
      .getReportDetail(disipline, type, format, detail, q, day, month, sortBy, sortType, periods, form_group)
      .subscribe(
        data => {
          this.activitiesData = data?.contents
          console.log('ACTIVITIES', this.activitiesData);
          this.loading = false
        }, //data
        (err) => console.log('err', err), //err
        ()=> console.log('ok'), //complete
      )
  }

  getFile(report_format, format_file){
    let  discipline_external_id = this.external_id
    let  report_type = 'CONTENTS'
    let  detail_level = 1
    let  q = this.activitiesForm.controls.q.value;
    let  month =  this.activitiesForm.controls.month.value;
    let  day = this.activitiesForm.controls.day.value;
    let  sort_by = this.activitiesForm.controls.sortBy.value
    let  sort_type = this.activitiesForm.controls.sortType.value

    this.reportsService
    .getReportBlobActivities(
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      month,
      day,
      sort_by,
      sort_type
    )
    .subscribe( (blob: Blob): void => {
      this.howToGetTheFile(format_file, blob)
    });
  }

  getUrl(report_format, format_file){
    this.toastMessenger.messengerToast('load', this.i18n.reports_generating, true)
    let  discipline_external_id = this.external_id
    let  report_type = 'CONTENTS'
    let  detail_level = 1
    let  q = this.activitiesForm.controls.q.value;
    let  month =  this.activitiesForm.controls.month.value;
    let  day = this.activitiesForm.controls.day.value;
    let  sort_by = this.activitiesForm.controls.sortBy.value
    let  sort_type = this.activitiesForm.controls.sortType.value

    this.reportsService
    .getReportUrlActivities(
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      month,
      day,
      sort_by,
      sort_type
    )
    .subscribe( (data): void => {
      this.toastMessenger.messengerToast('success', this.i18n.reports_finished, true, this.i18n.reports_view_report, data.url)
    });
  }

  howToGetTheFile(format_file: string, blob: Blob) {
    const file = new Blob([blob], { type: `application/${format_file}` });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');

    switch (format_file) {
      case 'pdf':
       window.open(fileURL, '_blank', 'width=800, height=600');
      break;
      case 'xlsx':
        a.href = fileURL;
        a.download = `active_students.${format_file}`;
        a.click();
      break;
      case 'csv':
        a.href = fileURL;
        a.download = `active_students.${format_file}`;
        a.click();
      break;
      default:
        break;
    }
  }

  getUrlParams() {
    this.route?.parent?.parent?.params
      .subscribe((params) => this.external_id = params.id)
  }

  textCut(limit: any, textToCut: any) {
    let text = textToCut;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }

  showMainScreen() {
    this.screen.emit('show')
    console.log('CHAMANDO RESOURCES');
  }

  createActivitiesForm() {
    this.activitiesForm = this.formBuilder.group({
      q:        ['', [Validators.required]],
      day:      ['', [Validators.required]],
      month:    ['', [Validators.required]],
      sortBy:   ['content_name', [Validators.required]],
      sortType: ['ASC', [Validators.required]],
    })
  }

  doQuery() {
    let  discipline_external_id = this.external_id
    let  report_type = 'CONTENTS'
    let  report_format = 0
    let  detail_level = 1
    let  q = this.activitiesForm.controls.q.value;
    let  month =  this.activitiesForm.controls.month.value;
    let  day = this.activitiesForm.controls.day.value;
    let  sort_by = this.activitiesForm.controls.sortBy.value
    let  sort_type = this.activitiesForm.controls.sortType.value

    this.getReportDetail(
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      month,
      day,
      sort_by,
      sort_type
    );
  }

}
