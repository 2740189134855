import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../grades.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-grades-overview',
  templateUrl: './grades-overview.component.html',
  styleUrls: ['./grades-overview.component.scss'],
})
export class GradesOverviewComponent implements OnInit, OnDestroy {
  // define os estilos do custom-dropdown
  defaultStylization = {
    borderWidth: 1,
    closed: {
      borderColor: '#E3E4E4',
      textColor: '#233674',
      iconColor: '#233674',
      fontWeight: '700',
      backgroundColor: '#EBEEF8',
    },
    expanded: {
      borderColor: '#F4F4F4',
      textColor: '#484B4D',
      iconColor: '#233674',
      fontWeight: '700',
      backgroundColor: '#EBEEF8',
    },
  };

  grade_id: any;
  discipline_ext_id: any;
  grades: any;
  periods: any;
  loading: boolean = false;
  isSaving: boolean = false;
  i18n: any = [];

  tableHead: any = [];

  subscription: Subscription[] = []

  constructor(
    private gradesService: GradesService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedSerivce: SharedService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.getDisciplineId();
    this.getPeriods();
    this.getGradesChanges();
  }

  getTranslations() {
    this.i18n = this.sharedSerivce.getTranslationsOf('Grades');
  }

  getGradesChanges() {
    let sub1 = this.gradesService.grades$.subscribe((isGradesUpdated: any) => {
      console.log('isGradesUpdated ', isGradesUpdated);

      if (isGradesUpdated?.periodId) {
        this.getUpdatedGrades(isGradesUpdated?.periodId);
      }
    });
    
    this.subscription.push(sub1);
  }

  getDisciplineId() {
    this.discipline_ext_id = this.router?.url.split('/')[2];
  }

  iterator: number = 0;
  maxSizePeriods: any;

  getPeriods() {
    this.gradesService
      .getPeriodsList(this.discipline_ext_id)

      .subscribe((periods: any) => {
        this.iterator = 0;
        this.periods = periods;
        this.maxSizePeriods = this.periods.length;
        if (this.maxSizePeriods > 0) {
          this.getGrades(this.periods[this.iterator].id, this.iterator);
        }
      });

      
  }

  getGrades(period_id: any, iterator: any) {
    this.loading = true;
    this.gradesService
      .getGradesList(period_id)
      .pipe(finalize(() => {}))
      .subscribe((grades: any) => {
        this.periods[iterator]['grades'] = grades;
        this.periods[iterator]['countCategories'] = {};
        this.setUpGrades(this.periods[iterator]);
        this.iterator++;

        if (this.iterator < this.maxSizePeriods) {
          this.getGrades(this.periods[this.iterator].id, this.iterator);
        }
      });
  }

  getUpdatedGrades(period_id: any) {
    this.loading = true;
    this.gradesService
      .getGradesList(period_id)
      .pipe(finalize(() => {}))
      .subscribe((grades: any) => {
        console.log('getUpdatedGrades from grades-vierview ', grades );
        
        this.periods.forEach((period: any) => {
          console.log('period ', period);
          
          if (period?.id == period_id) {
            period['grades'] = grades;
            period['countCategories'] = {};
            this.setUpGrades(period);
          }
        });
      });
  }

  // seta os nomes das categorias de cada nota
  setUpGrades(period: any) {
    period.countCategories = {};
    if (period.hasOwnProperty('grades')) {
      period.grades['decimal_places'] = this.periods[0].decimal_places;
      
    }
    period?.grades?.students.forEach((student: any, index: any) => {
      // Checa no primeiro student o número de categorias
      // nas notas
      if (index == 0) {
        const uniqueCategories: any = [];

        student?.grades.forEach((grade: any) => {
          const isDuplicate = uniqueCategories.includes(
            grade?.grade_category_id
          );
          if (!isDuplicate) {
            period.countCategories[grade?.grade_category_id] = 0;
          }
        });
      }

      student?.grades.forEach((grade: any) => {
        period?.categories.forEach((category: any) => {
          // inclui o nome da categoria dentro do
          // objeto de cada nota
          if (category.id == grade.grade_category_id) {
            grade['calculation_type'] = period.calculation_type;
            grade['category_name'] = category.name;
            grade['category_weight'] = category.weight;
          }
        });

        // Soma ocorrência por categoria encontrada nas notas
        // do primeiro student
        if (index == 0) {
          period.countCategories[grade.grade_category_id] =
            period.countCategories[grade.grade_category_id] + 1;
        }
      });
    });
    this.loading = false;
  }

  ngOnDestroy(): void {
    console.log('Destroy grades-overview');
    
    if (this.subscription.length) {
      this.subscription.forEach(element => {
        element.unsubscribe();
      });
    }
  }
}
