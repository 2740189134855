<div class="rounded-lg mt-8 container_box">
  <!--content-->
  <div
    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
  >
    <!--header-->
    <div
      class="text-start p-5 border-b border-solid border-customBlue-light rounded-t"
    >
      <h3 class="text-xl font-bold text-customBlue-royal">
        {{ i18n?.title_support_material }}
      </h3>
    </div>

    <div
      class="shadow-md container_items_support_materials"
      *ngFor="let material of supportMaterials"
    >
      <div class="fisrt_container" (click)="goToSupportMaterial(material)">
        <div class="container_icon_book">
          <app-content-types-icons
            [type]="material.type"
          ></app-content-types-icons>
        </div>
        <div style="min-height: 35px">
          <div class="container_title_material">
            <span class="title_material">{{ material?.title }}</span>
          </div>
          <div class="container_description_material">
            <span class="description_material">
              {{ getDescriptionText(material.description) }}
            </span>
          </div>
        </div>
      </div>

      <div class="second_container">
        <div *ngIf="!material?.only_teacher" class="viewed_by_students_and_teacher">
          {{ i18n?.viewed_by_students_and_teacher }}
        </div>

        <div *ngIf="material?.only_teacher" class="container_only_teacher">
          {{ i18n?.only_teachers_can_view }}
        </div>

        <div
          class="flex"
          style="margin-right: 10px"
          *ngIf="isTeacher || isAdmin"
        >
          <div
            class="container_icon_action"
            (click)="goToEditSupportMaterial(material)"
          >
            <span
              class="iconify"
              data-icon="akar-icons:edit"
              style="color: #233674"
              data-width="15"
              data-height="15"
            ></span>
          </div>
          <div
            class="container_icon_action"
            style="margin-left: 7px"
            (click)="deleteSupportMaterial(material.external_id)"
          >
            <span
              class="iconify"
              data-icon="bi:trash"
              style="color: #233674"
              data-width="15"
              data-height="15"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="supportMaterials?.length === 0" class="container_empty_state">
      <span class="title_empty_state">{{ i18n?.empty_support_materials }}</span>
      <span *ngIf="isTeacher || isAdmin" class="subtitle_empty_state">{{
        i18n?.empty_support_materials_teacher
      }}</span>
    </div>

    <!--body-->
    <div class="container_button_material_support">
      <button
        *ngIf="isTeacher || isAdmin"
        (click)="openModalContent()"
        class="button_add_material btn-animate"
      >
        <span>{{ i18n?.button_add_support_material }}</span>
      </button>
    </div>
  </div>
</div>

<!-- Modal add custom schema -->
<div
  class="fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex duration-100 ease backdrop-blur"
  *ngIf="showModal"
>
  <div
    class="absolute bg-customWhite-default animate-fade-in-down transition"
    style="border-radius: 20px"
  >
    <!--content-->
    <div
      class="border-0 rounded-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
    >
      <!--header-->
      <div
        class="flex items-center justify-center border-b border-solid flex-col"
        style="
          padding: 32px 0;
          gap: 12px;
          font: 700 28px Roboto;
          color: var(--color-naval);
          border-color: var(--color-light-blue);
        "
      >
        <h3>{{ i18n?.title_add_support_material }}</h3>
        <div class="flex items-center justify-center text-center px-4 gap-2">
          <h6 style="font: 400 18px Roboto; color: var(--color-naval)">
            {{ i18n?.select_type_resource }}
          </h6>
          <button id="tooltip" class="w-5 h-5">
            <div class="circle-interrogation-icon interrogation__icon"></div>
            <span id="tooltipText">
              <p>
                <b> {{ i18n.disciplines_content_drive_file }}</b>
                {{ i18n.disciplines_content_drive_file_txt }}
              </p>
              <p>
                <b> {{ i18n.disciplines_content_page_txt }}</b>
                {{ i18n.disciplines_content_page_txt_txt }}
              </p>
              <p>
                <b> {{ i18n.disciplines_content_youtube }}</b>
                {{ i18n.disciplines_content_youtube_txt }}
              </p>
              <p>
                <b> {{ i18n.disciplines_content_URL }}</b>
                {{ i18n.disciplines_content_URL_txt }}
              </p>
            </span>
          </button>
        </div>
      </div>

      <!--body-->
      <div class="relative p-6 scroller-y scroller-x">
        <div class="grid lg:grid-cols-2 md:grid-cols-1 gap-2">
          <button
            class="button-content flex m-2 rounded bg-feed transition-all ease-out"
            *ngFor="let contentType of contentTypes"
            (click)="selectContent(contentType?.type)"
            [class.is-selected]="selected === contentType.type"
          >
            <div class="mr-2">
              <app-content-types-icons
                [type]="contentType.type"
              ></app-content-types-icons>
            </div>
            <div class="text-center" [style.width]="'10rem'">
              <span class="text-sm">{{ contentType.name }}</span>
            </div>
          </button>
        </div>
      </div>
      <!--footer-->
      <div class="decision-btns mb-6 lg:mt-0 center">
        <div
          class="btn forward button_select_type"
          [class.disabled]="!selected"
          (click)="addAssignment(); showModal = !showModal"
        >
          {{ i18n.simple_button_continue }}
        </div>
        <div class="btn backward" (click)="closeModalContent()">
          {{ i18n.disciplines_cancel }}
        </div>
      </div>
    </div>
  </div>
</div>
