import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubmissionCommentComponent } from './submission-comment.component';
import { UserFrameModule } from "../../../../../../../../components/user-frame/user-frame.module";

@NgModule({
    declarations: [
        SubmissionCommentComponent,
    ],
    exports: [
        SubmissionCommentComponent
    ],
    imports: [
        SharedModule,
        UserFrameModule
    ]
})
export class SubmissionsCommentModule {}
