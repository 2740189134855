import { Component, OnInit } from '@angular/core';

import { MessengerToastBaseService } from './messenger-toast-base.service';

@Component({
    selector: 'app-messenger-toast-base',
    templateUrl: './messenger-toast-base.component.html',
    styleUrls: ['./messenger-toast-base.component.scss'],
})
export class MessengerToastBaseComponent implements OnInit {
    showToast = false;
    message = '';
    type = '';

    constructor(private toastService: MessengerToastBaseService) {}

    ngOnInit(): void {
        this.toastControl();
        this.getToastMsg();
        this.getToastType();
    }

    toastControl(): void {
        this.toastService.showToast$.subscribe((value) => {
            this.showToast = value;
        });
    }

    getToastMsg(): void {
        this.toastService.message$.subscribe((value) => {
            this.message = value;
        });
    }

    getToastType(): void {
        this.toastService.type$.subscribe((value) => {
            this.type = value;
            setTimeout(() => { this.showToast = false; }, 2000);
        });
    }
}
