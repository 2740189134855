<!-- File list -->
<div *ngIf="isLoaded" class="py-2">
    <!-- HEADER file list -->
    <ng-container *ngIf="showSendTitle">
        <div
            *ngIf="files?.length !== 0"
            class="text-sm flex justify-start text-customGray-dark mb-2"
        >
            <span>
                {{ files?.length > 1 ? i18n.disciplines_attachments :  i18n.disciplines_attachment }}
            </span>
        </div>
    </ng-container>
    <!-- FIM HEADER file list -->

    <ul
        [ngClass]="{ 'custom_overflow pr-2': files?.length > 2 }"
        style="display: grid; gap: 1.125rem"
    >
        <li
            *ngFor="let file of files; let i = index"
            class="grid grid-cols-3 gap-2 p-2 border border-customGray-default rounded-md"
        >
            <!-- Icon and Title -->
            <div
                (click)="openPreviewGoogleForms(file)"
                class="flex justify-start cursor-pointer items-center gap-2 xs:col-span-2"
                [ngClass]="{ 
                    'col-span-2': !showDate
                }"
            >
                <div [ngSwitch]="true" class="file-icons">
                    <div
                        *ngSwitchCase="
                            file?.mime_type ===
                            'application/vnd.google-apps.document'
                        "
                    >
                        <span
                            class="iconify w-6 h-6"
                            style="color: #4b8cf5"
                            data-icon="mdi:drive-document"
                        ></span>
                    </div>
                    <div
                        *ngSwitchCase="
                            file?.mime_type ===
                            'application/vnd.google-apps.spreadsheet'
                        "
                    >
                        <span
                            class="iconify w-6 h-6"
                            style="color: #0f9d58"
                            data-icon="mdi:google-spreadsheet"
                        ></span>
                    </div>
                    <div
                        *ngSwitchCase="
                            file?.mime_type ===
                            'application/vnd.google-apps.form'
                        "
                    >
                        <span
                            class="iconify w-6 h-6"
                            style="color: #7f3bb8"
                            data-icon="mdi:form"
                        ></span>
                    </div>
                    <div
                        *ngSwitchCase="
                            file?.mime_type === 'image/png' ||
                            file?.mime_type === 'image/jpg' ||
                            file?.mime_type === 'image/jpeg' ||
                            file?.mime_type ===
                                'application/vnd.google-apps.photo'
                        "
                    >
                        <span
                            class="iconify w-6 h-6 text-customGray-dark"
                            data-icon="bi:file-earmark-image"
                        ></span>
                    </div>
                    <div
                        *ngSwitchCase="
                            file?.mime_type === 'video/mp4' ||
                            file?.mime_type === 'video/mkv' ||
                            file?.mime_type ===
                                'application/vnd.google-apps.video'
                        "
                    >
                        <span
                            class="iconify w-6 h-6 text-customGray-dark"
                            data-icon="bi:file-earmark-play"
                        ></span>
                    </div>
                    <div *ngSwitchCase="file?.mime_type === 'application/pdf'">
                        <span
                            class="iconify w-6 h-6 text-customGray-dark"
                            data-icon="mdi:file-pdf-box"
                        ></span>
                    </div>

                    <div *ngSwitchDefault>
                        <span
                            class="iconify w-6 h-6 text-customGray-dark"
                            data-icon="mdi:file-outline"
                        ></span>
                    </div>
                </div>
                <div
                    pTooltip="{{ file?.label }}"
                    tooltipPosition="top"
                    pRipple
                    tooltipEvent="focus"
                    [ngClass]="{
                        'lg:w-52 xs:w-28': showDate
                    }"
                    class="styling-tooltip truncate flex text-sm text-customGray-dark items-center justify-start"
                >
                    <span
                        style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        "
                    >
                        {{ file?.label }}
                    </span>
                </div>
            </div>
            <!-- FIM Icon and Title -->

            <!-- Date Sumbission -->
            <ng-container *ngIf="showDate">
                <div class="flex justify-center items-center xs:hidden">
                    <ng-container *ngIf="file?.submitted_at">
                        <span class="text-sm text-center text-customGray-dark">
                            <ng-container
                                *ngIf="
                                    currentLanguage === 'pt-BR' ||
                                    currentLanguage === 'es'
                                "
                            >
                                {{ i18n.upload_submmited_at }}
                                {{ file?.submitted_at | date : "dd/MM/yyyy" }}
                                {{ i18n.upload_hours }}
                                {{ file?.submitted_at | date : "H:mm" }}
                            </ng-container>
                            <ng-container *ngIf="currentLanguage === 'en'">
                                {{ i18n.upload_submmited_at }}
                                {{ file?.submitted_at | date : "MM/dd/yyyy" }}
                                {{ i18n.upload_hours }}
                                {{ file?.submitted_at | date : "h:mm a" }}
                            </ng-container>
                        </span>
                    </ng-container>
                </div>
            </ng-container>
            <!-- FIM Date Sumbission -->

            <!-- GOOGLE DRIVE feature buttons -->
            <div
                *ngIf="provider === 'GOOGLE_DRIVE'"
                class="flex justify-end items-center ml-2 gap-1"
            >
                <ng-container *ngIf="showOpenBtn">
                    <a
                        href="{{ file?.public_url }}"
                        pTooltip="{{ i18n.upload_open_file }}"
                        tooltipPosition="top"
                        pRipple
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div>
                            <span
                                class="iconify w-5 h-5 text-customGray-default"
                                data-icon="material-symbols:open-in-new-rounded"
                            ></span>
                        </div>
                    </a>
                </ng-container>
                <ng-container *ngIf="showReplaceBtn">
                    <button
                        (click)="replaceFile(file)"
                        pTooltip="{{ i18n.upload_replace_file }}"
                        tooltipPosition="top"
                        pRipple
                    >
                        <div>
                            <span
                                class="iconify w-5 h-5 text-customGray-default"
                                data-icon="gravity-ui:pencil-to-square"
                            ></span>
                        </div>
                    </button>
                </ng-container>
                
                <ng-container>
                    <div
                        (click)="getLocationToDisplayPanel($event, file)"
                        pTooltip="{{ returnMessageFlowMethod(file) }}"
                        tooltipPosition="top"
                        pRipple
                        tooltipStyleClass="custom-tooltip"
                        class="flex cursor-pointer"
                    >
                        <span
                            class="iconify w-5 h-5 text-inicieColors-orange"
                            data-icon="el:warning-sign"
                        ></span>
                    </div>
                </ng-container>
                <ng-container *ngIf="showRemoveBtn && !delivery_allowed">
                    <button
                        (click)="removeFile(file)"
                        pTooltip="{{ i18n.upload_remove_file }}"
                        tooltipPosition="top"
                        pRipple
                    >
                        <div>
                            <span
                                class="iconify w-5 h-5 text-customGray-default"
                                data-icon="bi:trash"
                            ></span>
                        </div>
                    </button>
                </ng-container>
            </div>
            <!-- FIM GOOGLE DRIVE feature buttons -->

            <!-- STANDALONE feature buttons -->
            <div
                *ngIf="provider === 'GOOGLE_CLOUD_STORAGE'"
                class="flex justify-end items-center ml-2"
            >
                <button
                    *ngIf="false"
                    pTooltip="{{ i18n.upload_replace_file }}"
                    tooltipPosition="top"
                    pRipple
                    class="mr-2"
                >
                    <input
                        *ngIf="!isUploadLocked"
                        id="replace-local"
                        type="file"
                        [accept]="defineTypeFile()"
                        hidden
                        multiple
                        (change)="replaceFileGCS($event, file)"
                    />
                    <label
                        for="replace-local"
                        class="flex flex-col justify-center items-center cursor-pointer"
                    >
                        <div>
                            <span
                                class="iconify w-5 h-5 text-customGray-default"
                                data-icon="gravity-ui:pencil-to-square"
                            ></span>
                        </div>
                    </label>
                </button>
                <a
                    *ngIf="false"
                    href="{{ file?.path }}"
                    pTooltip="{{ i18n.upload_open_file }}"
                    tooltipPosition="top"
                    pRipple
                    class="mr-2"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.41071 2.03571C3.78082 2.03571 3.17673 2.28594 2.73134 2.73134C2.28594 3.17673 2.03571 3.78082 2.03571 4.41071V14.5893C2.03571 15.9003 3.09971 16.9643 4.41071 16.9643H14.5893C15.2192 16.9643 15.8233 16.7141 16.2687 16.2687C16.7141 15.8233 16.9643 15.2192 16.9643 14.5893V11.875C16.9643 11.605 17.0715 11.3462 17.2624 11.1553C17.4533 10.9644 17.7122 10.8571 17.9821 10.8571C18.2521 10.8571 18.511 10.9644 18.7019 11.1553C18.8928 11.3462 19 11.605 19 11.875V14.5893C19 15.7591 18.5353 16.881 17.7081 17.7081C16.881 18.5353 15.7591 19 14.5893 19H4.41071C3.24092 19 2.11904 18.5353 1.29187 17.7081C0.464699 16.881 0 15.7591 0 14.5893V4.41071C0 3.24092 0.464699 2.11904 1.29187 1.29187C2.11904 0.464699 3.24092 0 4.41071 0H7.125C7.39495 0 7.65385 0.107238 7.84473 0.298123C8.03562 0.489009 8.14286 0.747905 8.14286 1.01786C8.14286 1.28781 8.03562 1.54671 7.84473 1.73759C7.65385 1.92848 7.39495 2.03571 7.125 2.03571H4.41071ZM10.1786 1.01786C10.1786 0.747905 10.2858 0.489009 10.4767 0.298123C10.6676 0.107238 10.9265 0 11.1964 0H17.9821C18.2521 0 18.511 0.107238 18.7019 0.298123C18.8928 0.489009 19 0.747905 19 1.01786V7.80357C19 8.07352 18.8928 8.33242 18.7019 8.5233C18.511 8.71419 18.2521 8.82143 17.9821 8.82143C17.7122 8.82143 17.4533 8.71419 17.2624 8.5233C17.0715 8.33242 16.9643 8.07352 16.9643 7.80357V3.47429L11.9157 8.52286C11.8225 8.62286 11.7102 8.70307 11.5853 8.7587C11.4604 8.81433 11.3257 8.84425 11.189 8.84666C11.0523 8.84907 10.9166 8.82393 10.7898 8.77274C10.6631 8.72155 10.548 8.64535 10.4513 8.54869C10.3547 8.45204 10.2785 8.33691 10.2273 8.21017C10.1761 8.08343 10.1509 7.94767 10.1533 7.81101C10.1558 7.67434 10.1857 7.53955 10.2413 7.4147C10.2969 7.28984 10.3771 7.17747 10.4771 7.08429L15.5257 2.03571H11.1964C10.9265 2.03571 10.6676 1.92848 10.4767 1.73759C10.2858 1.54671 10.1786 1.28781 10.1786 1.01786Z"
                            fill="#484B4D"
                        />
                    </svg>
                </a>
                <button
                    (click)="removeFile(file)"
                    pTooltip="{{ i18n.upload_remove_file }}"
                    tooltipPosition="top"
                    pRipple
                >
                    <div>
                        <span
                            class="iconify w-5 h-5 text-customGray-default"
                            data-icon="bi:trash"
                        ></span>
                    </div>
                </button>
            </div>
            <!-- FIM STANDALONE feature buttons -->
        </li>
    </ul>
</div>

<app-display-panel
    #displayPanelWarning
    [style]="{ position: 'absolute' }"
    [custom_radius]="'10px'"
    [hide_arrow]="true"
    [panel_style]="{ background: '#FEF8E6', width: '20.5rem' }"
    [newStyle]="{
        top: displayPanelObject.top,
        left: displayPanelObject.left,
        right: displayPanelObject.right
    }"
>
    <span>
        {{ textFlowMethodWarning }}
    </span>
</app-display-panel>

<app-upload-file-preview
    [(isModalOpen)]="isPreviewModalOpen"
    [i18n]="i18n"
    [filePreviewUrl]="filePreviewUrl"
></app-upload-file-preview>
