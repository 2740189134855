import {
    AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { TimeService } from 'src/app/services/time-services.service';
import { RestoreFormattingService } from 'src/app/shared/restore-formatting.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ForumService } from '../../forum.service';
import { ForumSharedService } from '../../shared/forum-shared.service';

@Component({
    selector: 'app-forum-detail',
    templateUrl: './forum-detail.component.html',
    styleUrls: ['./forum-detail.component.scss']
})
export class ForumDetailComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {
    @ViewChild('threadText') threadText! : ElementRef;
    @Input() isComponentForum = false;
    @Input() forum_post: any;

    user: any;
    query = '';
    titlePage = '';

    forum_post_external_id: any;
    forum_external_id: any;
    isLoading: boolean;
    hasntForums: boolean;
    hasntResults: boolean;

    isCommentAreaVisible = '';
    isEditMode = false;
    i18n :any = [];

    number: any = 3;
    filterTerm: string;
    p = 1;

    commentForumForm: UntypedFormGroup;

    isLoading$;
    isModalOpen$;

    isModalEditHistoryOpen = false;
    edit_history: any;

    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private forumService: ForumService,
        private sharedService: SharedService,
        private timeService: TimeService,
        private FormBuilder: UntypedFormBuilder,
        private router: Router,
        public platModalService: PlatformModalsService,
        private ref: ChangeDetectorRef,
        private forumSharedSerive: ForumSharedService,
        private restoreFormattingService: RestoreFormattingService
    ) {

    }

    ngOnInit(): void {
        this.getTranslations();
        this.createCommentForumForm();
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;
        });

        if (!this.isComponentForum) {
            this.getForumIds();
            this.getForumPost();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.forum_post && this.forum_post) {
                if (this.forum_post?.thread) {
                    this.forum_post["items"] = this.forum_post.thread;
                }

                this.forumSharedSerive.insertNewPropertyInObject(this.forum_post.items, 'isThreadOpen', false);
                this.forumService.addForumToObservable(this.forum_post);
            }
        }
    }

    ngAfterViewInit(): void {
        const sub0 = this.forumService.forum$.subscribe({
            next: (forum_post) => {
                this.forum_post = forum_post;
            }
        });

        const sub1 = this.forumService.isForumUpdated$.subscribe((changes) => {
            if (changes) {
                this.getForumPost();
            }
        });
        const sub2 = this.forumService.isForumDeleted$.subscribe((changes) => {
            if (changes?.length !== 0) {
                this.deleteForum(changes);
            }
        });
        const sub3 = this.sharedService.isLoading$.subscribe((isLoading) => {
            this.isLoading$ = isLoading;
        });

        this.subscriptions.push(sub0, sub1, sub2, sub3);

        if (this.forum_post?.items?.text.length) {
            this.restoreFormattingService.restoreFormatting(this.threadText);
        }
    }

    ngAfterViewChecked(): void {
        this.sharedService.modalOpen$.subscribe((isModalOpen) => {
            this.isModalOpen$ = isModalOpen;
        });
        this.ref.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
        }
    }

    getTranslations() {
        this.i18n = this.sharedService.getTranslationsOf('Forum');
        this.titlePage = 'Fórum';
    }

    closeModalHistory(event:any) {
        this.isModalEditHistoryOpen = event;
    }

    openModalEditHistory(thread_edit_history: any) {
        this.edit_history = thread_edit_history;
        this.isModalEditHistoryOpen = true;
    }

    // Forms
    createCommentForumForm() {
        this.commentForumForm = this.FormBuilder.group({
            text: ['', [Validators.required]],
        });
    }

    get text() {
        return this.commentForumForm.get('text');
    }

    searchForum(event: any) {
        this.query = event;
    }

    commentOpenStatus(event:any) {
        this.isCommentAreaVisible = event;
    }

    returnRoute(): void {
        this.router.navigate(['..'], {
            relativeTo: this.route,
        });
    }

    getForumPost(id?:string) {
        this.isLoading = true;
        let forumExternalId: string = this.forum_post_external_id;
        if (id !== undefined) {
            forumExternalId = id;
        }

        if (this.isComponentForum) {
            forumExternalId = this.forum_post?.items?.id;
        }

        this.forumService.getForumPost(forumExternalId, 1, 10, 'DESC')
            .pipe(
                finalize(() => {
                    this.sharedService.isLoadingModalClose();
                })
            )
            .subscribe((forum_post) => {
                // caso não tenha nenhum fórum, seta variavel true para mostrar
                // mensagem personalizada
                if (this.query.length === 0 && forum_post?.items?.replies.length === 0) {
                    this.hasntForums = true;
                }
                // caso pesquisa não traga nenhum resultado, seta variavel true para mostrar
                // mensagem personalizada
                if (this.query.length > 0 && forum_post?.items?.replies.length === 0) {
                    this.hasntResults = true;
                }
                this.forum_post = forum_post;

                this.forumSharedSerive.insertNewPropertyInObject(this.forum_post, 'isThreadOpen', false);

                this.forumService.addForumToObservable(this.forum_post);
                this.isLoading = false;
            });
    }

    getTime(date) {
        return this.timeService.getTime(date);
    }

    getForumIds() {
        this.route?.params?.subscribe((params) => {
            this.forum_post_external_id = params.id;
        });
        this.forum_external_id = sessionStorage.getItem('forum_external_id');
        sessionStorage.setItem('forum_post_external_id', this.forum_post_external_id);
    }

    deleteForum(id:any) {
        this.platModalService.toggle('decision', 'deletion_subject_generic', {
            forward: () => {
                this.sharedService.modalOpen();
                this.forumService.deleteForum(id)
                    .pipe(
                        finalize(() => {
                            this.sharedService.modalClose();
                        })
                    )
                    .subscribe(() => {
                        const forumPost = this.forumSharedSerive.getForumThreadById(id, this.forum_post.items);
                        const dateNow: Date = new Date();
                        const formattedDate: string = dateNow.toISOString();
                        forumPost.edited_at = `${formattedDate}`;
                        forumPost.deleted = true;
                    });
            },
            finally: () => {
                this.forumService.checkForDeleteOnForum(null, true);
                this.platModalService.toggle('decision');
            }
        });
    }
}
