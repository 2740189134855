import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/pages/permissions/permissions.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-permissions',
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit {
    objectToPut: any = {
        permission_roles: [],
    };

    permissionsObject: any;
    permissionsLocalStoreObjt: any;
    selectedRoleType: any;

    i18n: any = [];
    user: any = [];
    roles: any = [];
    groups: any = [];
    roleType: any = [];
    roleName: any = [];
    group_id: any = [];
    groupData: any = [];
    externalId: any = [];
    permissionRoles: any = [];
    currentLanguage: any;
    selectedGroup: string;
    isLoading = false;
    isEditMode = false;
    isModalOpen = false;
    isLoadingGroup = false;
    isButtonActive = false;

    GROUP_NAME: any;

    constructor(
        private sharedService: SharedService,
        private permissionsService: PermissionsService,
        private platModalsService: PlatformModalsService
    ) {}

    ngOnInit(): void {
        this.getUser();
        this.getGroups();
        this.getTranslations();
        this.getLocalPermissions();
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Permissions'), ...this.sharedService.getTranslationsOf('Modal'), ...this.sharedService.getTranslationsOf('ContentPlanner') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getUpdatedTranslations() {
        this.sharedService.translationsUpdate$.subscribe((translations: any) => {
            this.i18n = translations?.Permissions;
        });
    }

    getLocalPermissions() {
        this.permissionsObject = JSON.parse(localStorage.getItem('permissions') || '');
        console.log('TESTE LOCAL PERMISSIONS: ', this.permissionsObject);
    }

    getUser() {
        this.sharedService
            .getUser()
            .subscribe((user) => {
                this.user = user;
            });
    }

    setPermissionsLocalStore() {
        localStorage.setItem('permissions', JSON.stringify(this.permissionsObject));
    }

    processSetPermissionsLocalStore(y, role_type) {
        this.selectedRoleType = role_type;

        let object: any = {};
        const array: any = [];

        if (role_type == this.user.role_type) {
            for (const nameAction in this.permissionRoles[y].action) {
                array.push({ [`${this.group_id}.${nameAction}`]: this.permissionRoles[y].action[nameAction] });
            }

            object = {
                [`${this.group_id}`]: array
            };

            const transformedObject = {
                [this.group_id]: {}
            };

            object[this.group_id].forEach((item) => {
                const key = Object.keys(item)[0];
                transformedObject[this.group_id][key] = item[key];
            });

            // this.permissionsObject[this.group_id] = {... transformedObject}
            this.permissionsObject = { ...this.permissionsObject, ...transformedObject };
        }

        console.log('data object: ', object);
        console.log('data group_id 2: ', ...this.group_id);
        console.log('data object this.permissionsObject: ', ...this.permissionsObject);
        console.log('data object role_type: ', ...role_type);
        console.log('data object this.user.role_type: ', ...this.user.role_type);
        /*  } else {
      console.log('data this.user.role_type: ', this.user.role_type, 'datarole_type: ', role_type);
    } */
    }

    toggleModal($event) {
        this.isModalOpen = $event;
    }

    openModal() {
        this.isEditMode = false;
        this.isModalOpen = true;
    }

    openModalEditMode(role_external_id: any, role_name: any, role_type: any) {
        this.isEditMode = true;
        this.isModalOpen = true;
        this.externalId = role_external_id;
        this.roleName = role_name;
        this.roleType = role_type;
    }

    clearPermissions() {
        this.objectToPut.permission_roles = [];
        this.isButtonActive = false;
    }

    getGroups() {
        this.isLoading = true;
        this.permissionsService.getGroups().subscribe({
            next: (roles) => {
                this.roles = roles.role_type;
                this.groups = roles.groups;
                // console.log('Data Roles: ', roles);
                // console.log('ARRAY GRUPOS: ', this.groups);
            },
            error: (err) => {
                console.log(err);
            },
            complete: () => {
                this.isLoading = false;
                this.selectFirstGroup();
            }
        });
    }

    getTheRolesOfThisGroup(name: any) {
        this.isLoadingGroup = true;
        this.group_id = name;
        this.clearPermissions();
        this.permissionsService.getRoles(name).subscribe({
            next: (groupData) => {
                this.groupData = groupData;
                console.log('DATA GROUP: ', this.groupData);
                this.permissionRoles = groupData.permission_roles;
                console.log('DATA GROUP ROLES: ', this.permissionRoles);
            },
            error: (err) => console.log(err),
            complete: () => {
                this.isLoadingGroup = false;
            }
        });
    }

    askToDeleteRole(role) {
        const modalType = 'decision';
        const translationKey = 'deletion_subject_role';
        const functionHandlers = {
            forward: () => {
                this.deleteRole(role);
            },
            finally: () => {
                this.platModalsService.close(modalType);
            },
        };
        this.platModalsService.toggle(modalType, translationKey, functionHandlers);
    }

    deleteRole(role: any) {
        const modalType = 'message';
        const functionHandlers = {
            finally: () => {
                this.platModalsService.close(modalType);
            },
        };

        this.permissionsService.delRole(role).subscribe({
            next: () => {
                const translationKey = 'successful_role_deletion';
                this.platModalsService.toggle(
                    modalType,
                    translationKey,
                    functionHandlers
                );
                this.getTheRolesOfThisGroup(this.group_id);
            },
            error: () => {
                const translationKey = 'cannot_delete_role_in_use';
                this.platModalsService.toggle(
                    modalType,
                    translationKey,
                    functionHandlers
                );
            }
        });
    }

    selectFirstGroup() {
        const firstElement = this.groups[0]?.name;
        this.getTheRolesOfThisGroup(firstElement);
    }

    changeProfilePermissioning(
        role_type: any,
        role_external_id: any,
        permission_key: any,
        i: any,
        y: any
    ) {
        const objectPut = this.objectToPut;

        const perm = {
            model_external_id: role_external_id,
            model_type: 'role',
            group: this.group_id,
            permissions: this.permissionRoles[y].action,
        };

        this.permissionsLocalStoreObjt = perm;

        let alreadyHaveRoleinArray = false;
        let index;

        if (objectPut?.permission_roles.length > 0) {
            index = null;
            for (
                let indice = 0;
                indice < objectPut?.permission_roles.length;
                indice++
            ) {
                const element = objectPut?.permission_roles[indice];
                console.log('indice ', indice);
                console.log('item?.role_external_id  ', element?.role_external_id);
                if (element?.role_external_id == role_external_id) {
                    alreadyHaveRoleinArray = true;
                    index = indice;
                    break;
                } else {
                    index = indice;
                    alreadyHaveRoleinArray = false;
                }
            }
            console.log('index ', index);

            if (alreadyHaveRoleinArray) {
                objectPut.permission_roles[index].permissions[permission_key] = !objectPut.permission_roles[index].permissions[permission_key];
            } else {
                objectPut.permission_roles.push(perm);
                objectPut.permission_roles[index + 1].permissions[
                    permission_key
                ] = !objectPut.permission_roles[index + 1].permissions[
                    permission_key
                ];
            }
        } else {
            objectPut.permission_roles.push(perm);
            objectPut.permission_roles[0].permissions[permission_key] = !objectPut.permission_roles[0].permissions[permission_key];
        }
        console.log('objectPut ', objectPut);
        console.log('objectToPut ', this.objectToPut);
        this.isSaveLocked();
    }

    postPermissions(objectToPut: any) {
        const params = objectToPut;
        const modalType = 'message';
        const functionHandlers = {
            finally: () => {
                location.reload();
                this.platModalsService.close(modalType);
            },
        };
        this.permissionsService.postPermissions(params).subscribe(
            () => {
                const translationKey = 'permission_confirmation';
                this.platModalsService.toggle(
                    modalType,
                    translationKey,
                    functionHandlers
                );
            },
            (err) => console.log(err),
            () => {
                console.log('FEITO!');
            }
        );
    }

    savePermissions() {
        this.postPermissions(this.objectToPut);
        this.setPermissionsLocalStore();
    }

    isSaveLocked() {
        const permissionLength = this.objectToPut.permission_roles.length;
        console.log('Tamanho array ', permissionLength);
        if (permissionLength) {
            console.log('ENTROU NO IF');
            this.isButtonActive = true;
        }
    }

    formatActions(actionKey: any) {
        switch (actionKey) {
            case 'create':
                return this.i18n.permissions_create;
            case 'delete':
                return this.i18n.permissions_delete;
            case 'edit':
                return this.i18n.permissions_edit;
            case 'list':
                return this.i18n.permissions_list;
        }
    }

    getLabelsGrup(group: any) {
        return {
            account_deactivator: `${this.i18n.permissions_account_deactivator}`,
            announcements: `${this.i18n.permissions_announcements}`,
            certificates_models: `${this.i18n.permissions_certificates_models}`,
            contents: `${this.i18n.permissions_contents}`,
            contents_finish: `${this.i18n.contents_finish}`,
            contents_recording: `${this.i18n.permissions_contents_recording}`,
            contents_submission: `${this.i18n.contents_submission}`,
            disciplines_archive: `${this.i18n.permissions_disciplines_archive}`,
            disciplines_settings: `${this.i18n.permissions_disciplines_settings}`,
            emit_certificates: `${this.i18n.permissions_emit_certificates}`,
            forum: `${this.i18n.permissions_forum}`,
            groups_members: `${this.i18n.permissions_groups_members}`,
            ou_attach: `${this.i18n.permissions_ou_attach}`,
            permissioning: `${this.i18n.permissions_permissioning}`,
            questionnaire: `${this.i18n.permissions_questionnaire}`,
            settings: `${this.i18n.permissions_settings}`,
            terms_accept: `${this.i18n.permissions_terms_accept}`,

            auth_myRoles: `${this.i18n.permissions_auth_myRoles}`,
            certificates: `${this.i18n.permissions_certificates}`,
            bank_questions: `${this.i18n.permissions_bank_questions}`,
            certificates_automatic_emit: `${this.i18n.permissions_certificates_automatic_emit}`,
            certificates_preview: `${this.i18n.permissions_certificates_preview}`,
            certificates_validate: `${this.i18n.permissions_certificates_validate}`,
            commmon_checkUrl: `${this.i18n.permissions_commmon_checkUrl}`,
            content_portal: `${this.i18n.permissions_content_portal}`,
            content_portal_archive: `${this.i18n.permissions_content_portal_archive}`,
            courseWork: `${this.i18n.permissions_courseWork}`,
            dashboard: `${this.i18n.permissions_dashboard}`,
            discipline_settings_certificates: `${this.i18n.permissions_discipline_settings_certificates}`,
            discipline_settings_certificates_elegible: `${this.i18n.permissions_discipline_settings_certificates_elegible}`,
            discipline_settings_certificates_emit: `${this.i18n.permissions_discipline_settings_certificates_emit}`,
            discipline_settings_certificates_models: `${this.i18n.permissions_discipline_settings_certificates_models}`,
            discipline_settings_models: `${this.i18n.permissions_discipline_settings_models}`,
            disciplines: `${this.i18n.permissions_disciplines}`,
            disciplines_announcements: `${this.i18n.permissions_disciplines_announcements}`,
            disciplines_announcements_replicate: `${this.i18n.permissions_disciplines_announcements_replicate}`,
            disciplines_basic: `${this.i18n.permissions_disciplines_basic}`,
            disciplines_replicate: `${this.i18n.permissions_disciplines_replicate}`,
            disciplines_replicate_job: `${this.i18n.permissions_disciplines_replicate_job}`,
            disciplines_sections: `${this.i18n.permissions_disciplines_sections}`,
            disciplines_sectionsOrder: `${this.i18n.permissions_disciplines_sectionsOrder}`,
            disciplines_sections_conents_recording: `${this.i18n.permissions_disciplines_sections_conents_recording}`,
            disciplines_sections_contents: `${this.i18n.permissions_disciplines_sections_contents}`,
            disciplines_sections_contentsOrder: `${this.i18n.permissions_disciplines_sections_contentsOrder}`,
            disciplines_sections_contents_checkSubmission: `${this.i18n.permissions_disciplines_sections_contents_checkSubmission}`,
            disciplines_sections_contents_form: `${this.i18n.permissions_disciplines_sections_contents_form}`,
            disciplines_sections_contents_load: `${this.i18n.permissions_disciplines_sections_contents_load}`,
            disciplines_sections_contents_submissions: `${this.i18n.permissions_disciplines_sections_contents_submissions}`,
            disciplines_sections_contents_turnIn: `${this.i18n.permissions_disciplines_sections_contents_turnIn}`,
            disciplines_sections_contents_visualization: `${this.i18n.permissions_disciplines_sections_contents_visualization}`,
            disciplines_sections_expand: `${this.i18n.permissions_disciplines_sections_expand}`,
            disciplines_sections_pinned: `${this.i18n.permissions_disciplines_sections_pinned}`,
            disciplines_settings_basic: `${this.i18n.permissions_disciplines_settings_basic}`,
            disciplines_settings_details: `${this.i18n.permissions_disciplines_settings_details}`,
            disciplines_settings_general: `${this.i18n.permissions_disciplines_settings_general}`,
            disciplines_settings_tabs: `${this.i18n.permissions_disciplines_settings_tabs}`,
            disciplines_settings_visibility: `${this.i18n.permissions_disciplines_settings_visibility}`,
            disciplines_syllabus: `${this.i18n.permissions_disciplines_syllabus}`,
            disciplines_syllabus_contents_visualization: `${this.i18n.permissions_disciplines_syllabus_contents_visualization}`,
            disciplines_syllabus_form: `${this.i18n.permissions_disciplines_syllabus_form}`,
            enrollments: `${this.i18n.permissions_enrollments}`,
            enrollments_user_disciplines: `${this.i18n.permissions_enrollments_user_disciplines}`,
            files: `${this.i18n.permissions_files}`,
            forum_posts: `${this.i18n.permissions_forum_posts}`,
            forum_posts_reaction: `${this.i18n.permissions_forum_posts_reaction}`,
            forum_posts_thread: `${this.i18n.permissions_forum_posts_thread}`,
            forums_threads: `${this.i18n.permissions_forums_threads}`,
            groups: `${this.i18n.permissions_groups}`,
            'groups_delete-member': `${this.i18n.permissions_groups_delete_member}`,
            'groups_store-member': `${this.i18n.permissions_groups_store_member}`,
            impersonate_depersonificate: `${this.i18n.permissions_impersonate_depersonificate}`,
            impersonate_impersonificate: `${this.i18n.permissions_impersonate_impersonificate}`,
            levels: `${this.i18n.permissions_levels}`,
            me: `${this.i18n.permissions_profile}`,
            modalities: `${this.i18n.permissions_modalities}`,
            notifications: `${this.i18n.permissions_notifications}`,
            'organizational-units': `${this.i18n.permissions_organizational_units}`,
            'organizational-units_delete-related': `${this.i18n.permissions_organizational_units_delete_related}`,
            'organizational-units_store-related': `${this.i18n.permissions_organizational_units_store_related}`,
            ou: `${this.i18n.permissions_ou}`,
            permissioning_group: `${this.i18n.permissions_permissioning_group}`,
            permissioning_permissions: `${this.i18n.permissions_permissioning_permissions}`,
            permissioning_permissions_modelType_modelId: `${this.i18n.permissions_permissioning_permissions_modelType_modelId}`,
            permissioning_role: `${this.i18n.permissions_permissioning_role}`,
            question: `${this.i18n.permissions_question}`,
            question_like: `${this.i18n.permissions_question_like}`,
            question_unlike: `${this.i18n.permissions_question_unlike}`,
            questionnarie_form: `${this.i18n.permissions_questionnarie_form}`,
            questionnarie_question: `${this.i18n.permissions_questionnarie_question}`,
            'questionnarie_quiz-response': `${this.i18n.permissions_questionnarie_quiz_response}`,
            quiz: `${this.i18n.permissions_quiz}`,
            quiz_comment: `${this.i18n.permissions_quiz_comment}`,
            quiz_correction: `${this.i18n.permissions_quiz_correction}`,
            reports: `${this.i18n.permissions_reports}`,
            scorm: `${this.i18n.permissions_scorm}`,
            search: `${this.i18n.permissions_search}`,
            sections: `${this.i18n.permissions_sections}`,
            sections_disciplines: `${this.i18n.permissions_sections_disciplines}`,
            settings_advanced: `${this.i18n.permissions_settings_advanced}`,
            settings_auth: `${this.i18n.permissions_settings_auth}`,
            settings_auth_external: `${this.i18n.permissions_settings_auth_external}`,
            settings_certificates: `${this.i18n.permissions_settings_certificates}`,
            settings_certificates_models: `${this.i18n.permissions_settings_certificates_models}`,
            settings_customSchemas: `${this.i18n.permissions_settings_customSchemas}`,
            settings_disciplines: `${this.i18n.permissions_settings_disciplines}`,
            settings_general: `${this.i18n.permissions_settings_general}`,
            settings_integrations: `${this.i18n.permissions_settings_integrations}`,
            settings_lti: `${this.i18n.permissions_settings_lti}`,
            settings_terms: `${this.i18n.permissions_settings_terms}`,
            settings_timetables: `${this.i18n.permissions_settings_timetables}`,
            settings_timetables_exceptions: `${this.i18n.permissions_settings_timetables_exceptions}`,
            spreadsheet: `${this.i18n.permissions_spreadsheets}`,
            translations: `${this.i18n.permissions_translations}`,
            translations_groups: `${this.i18n.permissions_translations_groups}`,
            types: `${this.i18n.permissions_types}`,
            users: `${this.i18n.permissions_users}`,
            users_activate: `${this.i18n.permissions_users_activate}`,
            users_deactivate: `${this.i18n.permissions_users_deactivate}`,
            settings_grades: `${this.i18n.permissions_settings_grades}`,
            disciplines_syllabus_contents_finish: `${this.i18n.permissions_syllabus_contents_finish}`,
            disciplines_settings_grades: `${this.i18n.permissions_disciplines_settings_grades}`,
            grades: `${this.i18n.permissions_grades}`,
            grades_assignments: `${this.i18n.permissions_grades_assignments}`,
            grades_categories: `${this.i18n.permissions_grades_categories}`,
            grades_concepts: `${this.i18n.permissions_grades_concepts}`,
            grades_periods: `${this.i18n.permissions_grades_periods}`,
            grades_rounding: `${this.i18n.permissions_grades_rounding}`,
            grades_rubrics: `${this.i18n.permissions_grades_rubrics}`,

            discipline_sections_contents: `${this.i18n.permissions_discipline_sections_contents}`,
            disciplines_settings_certificates: `${this.i18n.permissions_disciplines_settings_certificates}`,
            disciplines_settings_certificates_elegible: `${this.i18n.permissions_disciplines_settings_certificates_elegible}`,
            disciplines_settings_certificates_emit: `${this.i18n.permissions_disciplines_settings_certificates_emit}`,
            disciplines_settings_certificates_models: `${this.i18n.permissions_disciplines_settings_certificates_models}`,
            disciplines_settings_models: `${this.i18n.permissions_disciplines_settings_models}`,

            settings_account_deactivator: `${this.i18n.permissions_settings_account_deactivator}`,
            support: `${this.i18n.permissions_support}`,
            terms: `${this.i18n.permissions_terms}`,

            auth_refresh: `${this.i18n.permissions_auth_refresh}`,
            settings_terms_accept: `${this.i18n.permissions_settings_terms_accept}`,

            settings_certifications: `${this.i18n.permissions_settings_certifications}`,
            certifications: `${this.i18n.permissions_certifications}`,
            cms_template_version: `${this.i18n.permissions_cms_version_template}`,
            cms_template_version_close: `${this.i18n.permissions_cms_version_template_close}`,
            cms_template_version_publish: `${this.i18n.permissions_cms_version_template_publish}`,
            cms_trail: `${this.i18n.permissions_cms_trail}`,
            cms_trail_setstatus: `${this.i18n.permissions_cms_trail_setstatus}`,
            integrations: `${this.i18n.permissions_integrations}`,
            assiduity: `${this.i18n.permissions_assiduity}`,
            lti: `${this.i18n.permissions_lti}`,
            report_center: `${this.i18n.permissions_report_center}`,
            report_center_order: `${this.i18n.permissions_report_center_order}`,
            googlechat: `${this.i18n.permissions_google_chat}`,
            audits: `${this.i18n.permissions_audits}`,
            user_notification: `${this.i18n.permissions_user_notification}`,
            areas: `${this.i18n.planner_area}`,
            competencies: `${this.i18n.planner_competencies}`,
            class_support_items: `${this.i18n.planner_class_support_items}`,
            curricular_components: `${this.i18n.planner_curricular_components}`,
            degrees: `${this.i18n.planner_degree}`,
            plannings: `${this.i18n.planner_content_planning}`,
            dashboard_settings: `${this.i18n.permissions_dashboard_settings}`,
            frequency: `${this.i18n.permissions_frequency}`,
            commission: `${this.i18n.permissions_comissions}`,
            chromebook_dashboard: `${this.i18n.permissions_chromebook_dashboard}`,

            Default: 'GROUP PERMISSIONS',
        }[group || 'Default'];
    }
}
