import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { EditorComponent } from './editor.component';

@NgModule({
    declarations: [EditorComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, EditorModule],
    exports: [EditorComponent],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]

})

export class AppEditorModule { }
