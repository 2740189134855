import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { TimeService } from 'src/app/services/time-services.service';

import { SubmissionButtonsNavigateForumService } from '../../../content/components/homework-submissions/components/homework-submissions-submissions/components/submission-buttons-navigate-forum/submission-buttons-navigate-forum.service';
import { ForumService } from '../../forum.service';
import { ForumSharedService } from '../../shared/forum-shared.service';

@Component({
    selector: 'app-forum-card-reply',
    templateUrl: './forum-card-reply.component.html',
    styleUrls: ['./forum-card-reply.component.scss']
})
export class ForumCardReplyComponent implements OnInit, OnDestroy {
    @Input() thread: any;
    @Input() i18n: any;
    @Input() user: any;
    @Input() isThreadReply: boolean;
    @Input() isCommentAreaVisible: string;
    @Input() isEditMode: boolean;
    @Input() isCanForumPost: boolean;
    @Input() isLoading: boolean;
    @Input() forum_post: any;
    @Input() isReplyOpen: string;
    @Input() isLastLevel: boolean;
    @Input() hiddenSocialEvents = false;

    @Output() sendEditHistoryObject = new EventEmitter();
    @Output() askForGetForum = new EventEmitter();
    @Output() sendCancelReply = new EventEmitter();
    @Output() sendIsCommentAreaVisible = new EventEmitter<string>();
    @Output() sendIsReplyOpen = new EventEmitter<string>();
    @Output() sendThreadText = new EventEmitter<string>();
    @Output() sendIsEditMode = new EventEmitter<boolean>();
    @Output() sendIsThreadOpen = new EventEmitter();

    threadGrade = 0.0;
    loadingGrade = false;
    changed = false;

    userExternalID = '';
    private userExternalIDSubscription: Subscription;

    constructor(
        private timeService: TimeService,
        private forumService: ForumService,
        public platModalService: PlatformModalsService,
        private forumSharedService: ForumSharedService,
        private subButtonsNavigateForumService: SubmissionButtonsNavigateForumService
    ) {}

    ngOnInit(): void {
        if (this.thread?.grade?.grade) {
            this.threadGrade = this.thread.grade.grade;
        }

        this.userExternalIDSubscription = this.subButtonsNavigateForumService.getSelectedUserExternalID().subscribe({
            next: (response) => {
                if (response) {
                    this.userExternalID = response;
                }
            }
        });
    }

    isThreadOpen = false;
    openThread(thread:any) {
        const threadObject = thread;
        this.isThreadOpen = !this.isThreadOpen;
        threadObject.isThreadOpen = this.isThreadOpen;
        this.sendIsThreadOpen.emit();
    }

    openModalEditHistory(id: any) {
        this.sendEditHistoryObject.emit(id);
    }

    getTime(date) {
        return this.timeService.getTime(date);
    }

    replyThread(parent_id:any, isReplyOpen: any) {
        // Se usuário clicar novamente no ícone de responder,
        // isCommentAreaVisible já estará preenchido, então
        // cancelará a resposta e limpará o formulário

        if (this.isCommentAreaVisible === parent_id || this.isCommentAreaVisible !== '') {
            this.isCommentAreaVisible = '';
            this.isReplyOpen = '';
            this.sendCancelReply.emit();
        } else {
            this.isCommentAreaVisible = parent_id;
            this.isReplyOpen = isReplyOpen + parent_id;
        }

        this.sendIsCommentAreaVisible.emit(this.isCommentAreaVisible);
        this.sendIsReplyOpen.emit(this.isReplyOpen);
    }

    editForumPost(thread_id: any) {
        this.isEditMode = true;
        this.sendIsEditMode.emit(this.isEditMode);
        this.replyThread(thread_id, 'edit');
    }

    changeThreadGrade() {
        this.changed = true;
    }

    saveThreadGrade() {
        if (this.changed && this.threadGrade != this.thread?.grade?.grade && this.threadGrade >= 0 && this.threadGrade <= parseFloat(this.thread?.grade?.max_grade)) {
            this.loadingGrade = true;
            this.forumService.setGrade(this.thread.id, { grade: this.threadGrade })
                .subscribe()
                .add(() => {
                    this.changed = false;
                    this.loadingGrade = false;
                });
        }
    }

    isWaitingResponse = false;
    clickReactionButton(is_reaction_true: boolean, thread_id: any, reaction_type: any) {
        this.isWaitingResponse = true;

        if (reaction_type === 'like') {
            if (is_reaction_true) {
                this.unlikeForumPost(thread_id);
            } else {
                this.likeForumPost(thread_id);
            }
        }

        if (reaction_type === 'follow') {
            if (is_reaction_true) {
                this.unfollowForumPost(thread_id);
            } else {
                this.followForumPost(thread_id);
            }
        }
        this.isWaitingResponse = this.isLoading;
    }

    unfollowForumPost(id: any) {
        this.forumService.postUnfollowForumPost(id)
            .subscribe(() => {
                const forumPost = this.forumSharedService.getForumThreadById(id, this.thread);
                forumPost.follow = false;
            });
    }

    followForumPost(id: any) {
        this.forumService.postFollowForumPost(id)
            .subscribe(() => {
                const forumPost = this.forumSharedService.getForumThreadById(id, this.thread);
                forumPost.follow = true;
            });
    }

    unlikeForumPost(id: any) {
        this.forumService.postUnlikeForumPost(id)
            .subscribe(() => {
                const forumPost = this.forumSharedService.getForumThreadById(id, this.thread);
                forumPost.like = false;
                forumPost.likes -= 1;
            });
    }

    likeForumPost(id: any) {
        this.forumService.postLikeForumPost(id)
            .subscribe(() => {
                const forumPost = this.forumSharedService.getForumThreadById(id, this.thread);
                forumPost.like = true;
                forumPost.likes += 1;
            });
    }

    deleteForum(id:any) {
        this.forumService.isForumDeleted$.next(id);
    }

    ngOnDestroy(): void {
        if (this.userExternalIDSubscription) {
            this.userExternalIDSubscription.unsubscribe();
        }
    }
}
