import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayPanelComponent } from './display-panel.component';
import { CountdownModule } from 'ngx-countdown';



@NgModule({
  declarations: [
    DisplayPanelComponent,
  ],
  exports: [
    DisplayPanelComponent
  ],
  imports: [
    CommonModule,
    CountdownModule

  ]
})
export class DisplayPanelModule { }
