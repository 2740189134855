import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SubmissionButtonsNavigateForumService } from './submission-buttons-navigate-forum.service';

@Component({
    selector: 'app-submission-buttons-navigate-forum',
    templateUrl: './submission-buttons-navigate-forum.component.html',
    styleUrls: ['./submission-buttons-navigate-forum.component.scss'],
})
export class SubmissionButtonsNavigateForumComponent implements OnInit, OnDestroy {
    private checkGetElementsSelectedSubscription: Subscription;
    commentsList: Element[] = [];
    currentCommentIndex = -1;

    constructor(private subButtonsNavigateForumService: SubmissionButtonsNavigateForumService) {}

    ngOnInit(): void {
        this.getElementsCommented();
    }

    getElementsCommented(): void {
        this.checkGetElementsSelectedSubscription = this.subButtonsNavigateForumService.checkGetElementsForum().subscribe({
            next: (response) => {
                if (response) {
                    this.currentCommentIndex = -1;
                    this.removeActiveComment();
                    this.commentsList = [...document.querySelectorAll('.forum__target--comment')];
                }
            }
        });
    }

    previousComment(): void {
        if (this.currentCommentIndex > 0) {
            this.currentCommentIndex -= 1;
            this.removeActiveComment();
            this.goToActiveComment();
        }
    }

    nextComment(): void {
        if (this.currentCommentIndex < this.commentsList.length - 1) {
            this.currentCommentIndex += 1;
            this.removeActiveComment();
            this.goToActiveComment();
        }
    }

    goToActiveComment(): void {
        this.commentsList[this.currentCommentIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.commentsList[this.currentCommentIndex].classList.add('active-target');
    }

    removeActiveComment(): void {
        this.commentsList.map((e) => {
            if (e.classList.contains('active-target')) {
                e.classList.remove('active-target');
            }
            return null;
        });
    }

    ngOnDestroy() {
        if (this.checkGetElementsSelectedSubscription) {
            this.checkGetElementsSelectedSubscription.unsubscribe();
        }
    }
}
