import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdPartyCookieComponent } from './third-party-cookie.component';

@NgModule({
    declarations: [
        ThirdPartyCookieComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ThirdPartyCookieComponent
    ]
})
export class ThirdPartyCookieModule { }
