import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { SharedService } from '../../shared/shared.service';

@Directive({
  selector: '[appCheckPermissions]'
})
export class CheckPermissionsDirective implements OnInit, OnDestroy  {

  @Input() appCheckPermissions: any;
  @Input() appCheckPermissionsType: any;
  @Input() appCheckPermissionsGroups: any;

  constructor(
    private sharedService: SharedService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit():void{

    if (this.appCheckPermissionsGroups !== undefined) {
      this.checkAtLeastOnePermission();
    }else{
      this.checkPermission();
    }
  }


  checkAtLeastOnePermission(){
    
    if (this.appCheckPermissionsGroups.length) {
      this.appCheckPermissionsGroups.some(permission => {
        if (this.sharedService.checkAtLeastOnePermission(permission, this.appCheckPermissionsType)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          return true;
        }else{
          this.viewContainer.clear();
          return false;
        }
      });

    }
  }

  checkPermission(){
   if (this.appCheckPermissions !== '') {
      if (this.sharedService.checkPermission(this.appCheckPermissions, this.appCheckPermissionsType)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }else{
        this.viewContainer.clear();
      }
    }    
  }


  ngOnDestroy():void{
    
  }

}
