import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DisciplineSettingsDetailService {
  constructor(private http: HttpClient) {}

  getDisciplineDetailSettings(external_id: any): Observable<any> {
    return this.http.get<any>(
      `${API_Routes.URL}/disciplines/${external_id}/settings/details`
    );
  }

  patchDisciplineDetailSettings(external_id: any, params: any): Observable<any> {
    return this.http.patch<any>(
      `${API_Routes.URL}/disciplines/${external_id}/settings/details`, params
    );
  }
}
