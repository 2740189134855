<section>
    <!-- Banner -->
    <div class="flex flex-col items-start justify-center">
        <div class="flex mb-2 items-baseline relative">
            <h1
                class="text-customBlue-default font-bold text-19 mr-1 font-montserrat"
            >
                {{ i18n.import_spreadsheet_new_importation }}
            </h1>
            <div>
                <span
                    class="iconify-inline text-18 text-customGray-dark"
                    data-icon="carbon:help"
                ></span>
                <div
                    class="w-80 absolute bg-customWhite-default p-2 rounded-md shadow-card tooltip opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                >
                    <span class="text-center text-14 text-customGray-dark">
                       {{ i18n.import_spreadsheet_tooltipe_in_import }}
                    </span>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!isLoading">
            <div class="text-center">
                <span
                    class="text-14 sm:text-16 text-customGray-dark font-roboto"
                >
                    {{ i18n.import_spreadsheet_new_importation_subtitle }}
                </span>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="isLoading">
        <app-bullets-loader></app-bullets-loader>
    </ng-container>

    <ng-container *ngIf="!isLoading">
        <!-- Import Structure -->
        <app-custom-import
            [i18n]="i18n"
            [title]="importStructure.title"
            [subtitle]="importStructure.subtitle"
            [link]="importStructure.link"
            [labelGenerate]="this.i18n.import_spreadsheet_generate_data_sheet"
            [labelImport]="this.i18n.import_spreadsheet_import_sheet"
            [modeGenerate]="importStructure.modeGenerate"
        ></app-custom-import>

        <!-- Import Connections -->
        <app-custom-import
            [i18n]="i18n"
            [title]="importConnection.title"
            [subtitle]="importConnection.subtitle"
            [link]="importConnection.link"
            [labelGenerate]="this.i18n.import_spreadsheet_generate_data_sheet"
            [labelImport]="this.i18n.import_spreadsheet_import_sheet"
            [modeGenerate]="importConnection.modeGenerate"
        ></app-custom-import>

        <!-- Table my importations -->
        <app-table-impoted-sheets
            [i18n]="i18n"
            [data]="spreadsheetImported"
            [currentLanguage]="currentLanguage"
        ></app-table-impoted-sheets>
    </ng-container>
</section>

<app-modal-confirmation
    [i18n]="i18n"
    [messages]="messagesConfirm"
></app-modal-confirmation>
