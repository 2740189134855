import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-completion-criteria',
    templateUrl: './completion-criteria.component.html',
    styleUrls: ['./completion-criteria.component.scss']
})
export class CompletionCriteriaComponent implements OnInit, OnChanges {
    criteriaForm: UntypedFormGroup;
    selectedValue: any;
    contentTypes: any;

    @Input() setTypeCriteria: any;
    @Input() contentType: string;
    @Input() i18n: any;

    @Output() selectedCriteria = new EventEmitter();

    constructor(
        private formBuilder: UntypedFormBuilder,
    ) { }

    loadOptions() {
        this.contentTypes = {
            TextPage:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
            ],
            External:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
            ],
            File:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
            ],
            Link:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
            ],
            UploadAssignment:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
                { value: 'ATTEMPT_SUBMISSION', name: this.i18n.adv_and_crit_attempt_sub, },
            ],
            DocsAssignment:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
                { value: 'STUDENT_NOTIFICATION', name: this.i18n.adv_and_crit_std_notification, },
            ],
            SheetsAssignment:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
                { value: 'STUDENT_NOTIFICATION', name: this.i18n.adv_and_crit_std_notification, },
            ],
            Conference:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
            ],
            InternalConference:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
            ],
            Scorm:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
            ],
            LTI:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
            ],
            UploadQuiz:
            [
                { value: 'STUDENT_NOTIFICATION', name: this.i18n.adv_and_crit_std_notification, },
            ],
            InternalQuiz:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'ATTEMPT_SUBMISSION', name: this.i18n.adv_and_crit_validation, },
            ],
            Youtube:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'TIME', name: this.i18n.adv_and_crit_time, },
            ],
            Forum:
            [
                { value: 'ACCESS', name: this.i18n.adv_and_crit_access },
                { value: 'ATTEMPT_SUBMISSION', name: 'Tentativa de envio', },
            ],
        };
    }

    ngOnInit(): void {
        this.loadOptions();
    }

    ngOnChanges(changes:SimpleChanges) {
        if (changes.hasOwnProperty('setTypeCriteria')) {
            if (changes.setTypeCriteria.firstChange) {
                this.createCriteriaForm();
            }

            this.setValueForm(this.setTypeCriteria);
        }
    }

    setValueForm(setTypeCriteria) {
        this.criteriaForm.controls.selectedCriteria.setValue(setTypeCriteria);
    }

    createCriteriaForm() {
        this.criteriaForm = this.formBuilder.group({
            selectedCriteria: [''],
        });
        this.criteriaForm.get('selectedCriteria')?.valueChanges.subscribe(
            (value) => {
                this.selectedValue = value;
                this.selectedCriteria.emit(this.selectedValue);
            }
        );
    }

    whatContentObjectSelect(typeContent: string) {
        return this.contentTypes[typeContent];
    }
}
