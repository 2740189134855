import { NgModule } from '@angular/core';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { SharedModule } from 'src/app/shared/shared.module';

import { DisplayPanelModule } from '../display-panel/display-panel.module';
import { DecisionModalModule } from '../modals/decision-modal/decision-modal.module';
import { GenericModalModule } from '../modals/generic-modal/generic-modal.module';
import { MessageModalModule } from '../modals/message-modal/message-modal.module';
import { UploadFileListModule } from '../upload-file-list/upload-file-list.module';
import { UploadInlineComponent } from './upload-inline.component';

@NgModule({
    declarations: [
        UploadInlineComponent
    ],
    exports: [
        UploadInlineComponent
    ],
    imports: [
        SharedModule,
        NgxFileHelpersModule,
        DecisionModalModule,
        MessageModalModule,
        DisplayPanelModule,
        GenericModalModule,
        UploadFileListModule
    ]
})
export class UploadInlineModule { }
