import { Component, OnInit, ViewChild } from '@angular/core';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { UsersService } from '../../users.service';

@Component({
    selector: 'app-modal-vinculate-discipline',
    templateUrl: './modal-vinculate-discipline.component.html',
    styleUrls: ['./modal-vinculate-discipline.component.scss']
})
export class ModalVinculateDisciplineComponent implements OnInit {
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;

    activeUserVinculate: any = {};
    isVisible: boolean;
    arraySaveVinculateDiscipline: any = [];
    showModalAddDiscipline = false;
    disciplines: any = [];
    disciplinesArr: any = [];
    profileSelected: string;
    isLoadingDisciplinesByProfile = false;
    filterDisciplines: string;
    p1 = 1;
    userVinculate: any = [];
    i18n: any = [];
    vinculateToAdd: any = [];
    vinculateToRemove: any = [];

    perPage = 5;
    query: any = '';
    page = 1;

    paginationParams: any = {};
    activePage = 1;

    constructor(
        private usersService: UsersService,
        public platModalService: PlatformModalsService,
        private sharedService: SharedService

    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.usersService.user$.subscribe({
            next: (user) => {
                if (Object.keys(user).length > 0) {
                    this.isVisible = user.isVisible;
                    this.userVinculate = { ...user };
                    this.activeUserVinculate = user;

                    // Somente trazer role_type que são igual a teacher ou student
                    let getRoleStudentsTeacher: any = {};

                    getRoleStudentsTeacher = user.roles.filter((role) => role.role_type === 'student' || role.role_type === 'teacher');

                    if (Object.keys(getRoleStudentsTeacher).length) {
                        this.userVinculate.roles = getRoleStudentsTeacher;
                    }

                    // Tornar primeira letra do nome do Cargo maiúscula
                    this.userVinculate.roles.forEach((item) => { item.role_name = this.sharedService.capitalizeFirstLetter(item.role_name); });
                    this.openModalVinculateDiscipline(this.userVinculate);
                }
            }
        });
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Users'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Errors'),
        };
    }

    changeProfile(user: any, profile: any) {
        this.resetVariables();
        this.profileSelected = profile;
        this.getDisciplines(user, this.profileSelected);
    }

    openModalVinculateDiscipline(user) {
        console.log('openModalVinculateDiscipline(user) ', user);

        this.profileSelected = user.roles[0].role_external_id;
        this.arraySaveVinculateDiscipline = [];
        this.getDisciplines(user, user.roles[0].role_external_id);
    }

    closeModal() {
        this.isVisible = false;
        this.activeUserVinculate.isVisible = false;
        this.query = '';
        this.resetVariables();
    }

    vinculateCheckboxTouched = false;
    touched() {
        this.vinculateCheckboxTouched = true;
    }

    // Se ele já tiver a disciplina retira da lista de salvar
    // se não inlcui na lista
    changeVinculateDisciplinesUser(discipline) {
        const disciplineVinculate = {
            role_external_id: this.profileSelected,
            user_external_id: this.userVinculate.external_id,
            enrollable_external_id: discipline.discipline_ext_id,
            enrollable_type: 'discipline',
        };

        discipline.toSave = disciplineVinculate;

        if (this.arraySaveVinculateDiscipline.includes(discipline)) {
            this.arraySaveVinculateDiscipline.splice(
                this.arraySaveVinculateDiscipline.indexOf(discipline),
                1
            );
        } else {
            this.arraySaveVinculateDiscipline.push(discipline);
        }
    }

    getChecked(discipline) {
        if (!this.arraySaveVinculateDiscipline.length) return;

        let disciplineSelected: any = {};

        this.arraySaveVinculateDiscipline.filter((el) => {
            if (el.discipline_ext_id === discipline.discipline_ext_id) {
                disciplineSelected = el;
            }
        });

        return !!((discipline.discipline_ext_id == disciplineSelected.discipline_ext_id)
    && disciplineSelected.enrolled);
    }

    saveVinculateDisplinesUsers() {
        this.arraySaveVinculateDiscipline.forEach((element: any) => {
            if (element.enrolled) {
                this.vinculateToAdd.push(element.toSave);
            } else {
                this.vinculateToRemove.push(element.toSave);
            }
        });

        if (this.vinculateToRemove.length) {
            const count = 0;
            this.removeEnrollmentVinculate(count);
        } else {
            this.addEnrollmentVinculate();
        }

        this.closeModal();
    }

    addEnrollmentVinculate() {
    // salva o array de roles
        if (this.vinculateToAdd.length) {
            this.usersService.addEnrollment(this.vinculateToAdd).subscribe({
                next: () => {
                    this.vinculateToAdd = [];
                },
                error: (err) => {
                    this.platModalService.toggle('message', err.error.message, 'close');
                },
            });
        }
    }

    removeEnrollmentVinculate(count) {
        this.usersService.removeEnrollment(this.vinculateToRemove[count]).subscribe({
            next: () => {
                count++;
                if (count > this.vinculateToRemove.length - 1) {
                    this.vinculateToRemove = [];
                    this.addEnrollmentVinculate();
                } else {
                    this.removeEnrollmentVinculate(count);
                }
            },
            error: (err) => {
                this.platModalService.toggle('message', err.error.message, 'close');
            },
        });
    }

    countRolesFromUser = 0;
    lastProfileSaved: any = '';
    lastUserVinculated: any;
    getDisciplines(user: any, role_external_id: any) {
        console.log('role_external_id ', role_external_id);
        console.log('this.page ', this.page);

        const params = {
            page: this.page,
            per_page: this.perPage
        };

        this.isLoadingDisciplinesByProfile = true;
        this.usersService
            .getDisicplineEnrollments(role_external_id, user.external_id, params, this.query)
            .subscribe((response) => {
                console.log('this.userVinculate ', this.userVinculate);

                if (response?.items.length) {
                    this.refreshPagination(response);
                }

                // if (this.profileSelected != this.lastProfileSaved && user.external_id != this.lastUserVinculated?.external_id ) {
                //   this.refreshPagination(response);
                // }

                this.activePage = response.page;

                this.disciplines = response.items;
                this.isLoadingDisciplinesByProfile = false;
                this.showModalAddDiscipline = true;
                this.lastProfileSaved = role_external_id;
                this.lastUserVinculated = user;

                // this.countRolesFromUser++;
                // if (this.countRolesFromUser > user.roles.length-1) {
                //   this.disciplines = this.disciplinesArr[user.roles[0].role_external_id];
                //   this.isLoadingDisciplinesByProfile = false;
                //   this.showModalAddDiscipline = true;
                // }else{
                //   this.getDisciplines(user, user.roles[this.countRolesFromUser].role_external_id)
                // }
            });
    }

    goToPage(event): void {
        this.page = event.activePage;

        this.getDisciplines(this.userVinculate, this.profileSelected);
    }

    refreshPagination(response): void {
        console.log('response ', response);

        this.paginationParams = {
            items: [...Array(response.total_items).keys()],
            itemsPerPage: this.perPage,
            visibleButtons: 5,
            activePage: response.page
        };

        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }

    async searchDisciplines() {
        this.page = 1;
        this.query = await this.debounce(this.filterDisciplines);
        this.getDisciplines(this.userVinculate, this.profileSelected);
    }

    timeout: any = null;
    async debounce(searchText: string) {
        return new Promise((resolve) => {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                resolve(searchText);
            }, 500);
        });
    }

    orderDisciplines(discipline) {
        return discipline.sort((a: any, b: any) => (a.discipline_name < b.discipline_name ? -1 : 1));
    }

    receiveCheckButton(check, disciplineIndex) {
        this.disciplines.filter((e, i) => {
            if (e.discipline_ext_id === disciplineIndex) {
                this.disciplines[i].enrolled = check;
            }
        });
    }

    resetVariables() {
        this.activePage = 1;
        this.perPage = 5;
        this.page = 1;
        this.disciplines = [];
        this.lastUserVinculated = null;
        this.lastProfileSaved = null;
    }
}
