import {
    Component, Input
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-card-student',
    templateUrl: './card-student.component.html',
    styleUrls: ['./card-student.component.scss']
})

export class CardStudentComponent {
    @Input() dashData: any = [];
    @Input() user: any = [];
    @Input() i18n: any = [];

    constructor(
        private sharedService: SharedService
    ) { }

    capitalizeFirstLetter(str: string) {
        return this.sharedService.capitalizeFirstLetter(str);
    }
}
