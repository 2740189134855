import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adm-shortcuts',
  templateUrl: './adm-shortcuts.component.html',
  styleUrls: ['./adm-shortcuts.component.scss']
})
export class AdmShortcutsComponent implements OnInit {
  @Input() adminData: any = []
  @Input() i18n: any = [];
  constructor() { }

  ngOnInit(): void {
  }

}
