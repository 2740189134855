import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionService } from 'src/app/pages/disciplines/components/content/section.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-modal-resource',
    templateUrl: './modal-resource.component.html',
    styleUrls: ['./modal-resource.component.scss']
})
export class ModalResourceComponent implements OnInit {
    @Output() closeModalResource = new EventEmitter();
    @Input() isVisible = false;
    @Input() module: any;
    @Input() message: string;
    @Input() classId: any;
    i18n: any = {};
    contentTypes: any = [];
    selected: any;
    section_external_id: any;
    section_title: any;
    currentLanguage: string | null;

    constructor(
        private localStorageService: LocalStorageService,
        private sectionService: SectionService,
        public sharedService: SharedService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.getTranslations();
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Errors'),
            ...this.sharedService.getTranslationsOf('Upload'),
        };
        this.currentLanguage = localStorage.getItem('currentLanguage');

        this.loadContentTypes();
    }

    closeModal(): void {
        console.log('ModalResourceComponent -> ngOnInit -> this.module', this.module);
        this.closeModalResource.emit(false);
    }

    selectContent(filter: string) {
        this.selected = filter;
    }

    loadContentTypes() {
        this.contentTypes = [
            { type: 'File', name: `${this.i18n.disciplines_page_file}` },
            { type: 'Youtube', name: `${this.i18n.disciplines_youtube}` },
            { type: 'TextPage', name: `${this.i18n.disciplines_text_page}` },
            { type: 'Link', name: `${this.i18n.disciplines_link}` },
            { type: 'Assignment', name: `${this.i18n.disciplines_docs_assignment}` },
            { type: 'Forum', name: `${this.i18n.disciplines_forum}` },
            { type: 'InternalQuiz', name: `${this.i18n.disciplines_upload_quiz}` },
            { type: 'Scorm', name: `${this.i18n.disciplines_scorm}` },
            { type: 'LTI', name: `${this.i18n.disciplines_page_lti}` },
            { type: 'Conference', name: `${this.i18n.disciplines_conference}` },
        ];

        if (this.localStorageService.getLocalStorageItem('provider')) {
            if (localStorage.getItem('provider') !== 'GOOGLE_CLOUD_STORAGE') {
                this.contentTypes.push({ type: 'UploadQuiz', name: `${this.i18n.disciplines_upload_quiz_external}` });
            }
        }
    }

    addAssignment() {
        this.sectionService.criterions = [];
        this.sharedService.getAssignment(this.selected);

        const url = `/disciplines/${this.module.discipline_external_id}/content-assignment/assignment;type=${this.selected};section=${this.module.section.external_id};section_title=${this.module.section.title};edit=0;class_id=${this.classId}`;

        // Abrir a rota em uma nova aba
        window.open(url, '_blank');

        this.closeModal();
    }
}
