import { Component, OnInit } from '@angular/core';
import {OrganizationChartModule} from 'primeng/organizationchart';
import { OrgUnitsService } from 'src/app/pages/org-units/org-units.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig, ConfirmationService } from 'primeng/api';

import {TreeNode} from 'primeng/api';
import {MessageService} from 'primeng/api';
import { OrgUnitsDataFormat } from 'src/app/shared/org-units-data-format.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-org-units',
  templateUrl: './org-units.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['./org-units.component.scss'],
})

export class OrgUnitsComponent implements OnInit {

    data1: TreeNode[] = [];
    selectedNode: any;
    node: any;
    isModalOpen: boolean;   
    tipoCampo: any[];
    editMode: boolean;
    displayBasic: boolean;
    i18n: any;
    tooltipHelp: string;
    tooltipHelpTitle: string;

     
    public textError: string = "";   

    constructor(
        private orgUnitsService: OrgUnitsService,
        private primengConfig: PrimeNGConfig,
        private orgUnitsDataFormat: OrgUnitsDataFormat,
        private sharedService: SharedService,
        public platModalService: PlatformModalsService,

    ) { }

    ngOnInit() {
        this.getTranslations();
        this.primengConfig.ripple = true;
        this.tooltipHelp = this.i18n.ou_description;
        this.tooltipHelpTitle = this.i18n.ou_example_description;
        this.getOrgUnits();
    }

    getTranslations(){
        this.i18n = this.sharedService.getTranslationsOf('OrgUnits');
    }

    closeModalHistory(event:any){
        this.isModalOpen = event;
    } 

    openModal(node: any, editMode: any){

        const modalObject = {
            node: node,
            editMode: editMode,
            isModalOpen: this.isModalOpen = true
        }
        this.orgUnitsService.modalObject$.next(modalObject);
    }

    createOrgUnit(event: any,node: any){
        this.editMode = false;
        this.selectedNode = node;
        this.openModal(node, this.editMode);
        event.stopPropagation();
        
    }

    editOrgUnit(event:any,node: any){
        this.editMode = true;
        this.selectedNode = node;
        this.openModal(node, this.editMode);
        event.stopPropagation();
    }   



    getOrgUnits() {
        this.orgUnitsService.getOrgUnits()
        .subscribe( orgUnits => {
            this.data1 = this.orgUnitsDataFormat.jsonReorder(orgUnits)
        });
       
    }

    getTypes(){
        this.orgUnitsService.getTypes()
        .subscribe( types => this.tipoCampo = types);        
    }


    removeOrgUnit(event:any,item: any){
        event.stopPropagation();

        this.platModalService.toggle('decision', 'deletion_subject_generic', {
            forward: () => {
              this.orgUnitsService.delOrgUnits(item.external_id)
              .subscribe({
                next: () => {
                    this.getOrgUnits();
                },
                error: (err) => {
                    if (err.status == 409 || err.error.error == 'There are childrens in the OU') {
                        this.displayBasic = true;
                        this.platModalService.toggle('message', this.i18n.ou_has_relacional_with_other_units, 'close');
                    }else if (err.error.error == 'There are disciplines in the OU') {
                        this.platModalService.toggle('message', this.i18n.ou_there_disciplines_in_ou, 'close');
                    } else {
                        this.platModalService.toggle('message', err.error.error, 'close');
                    }
                }
              })
            },
            finally: () => {
              this.platModalService.toggle('decision');
            }
          })         
        
    }   

    sendGetOrgUnits(event: any){
        if (event == true) {
            this.getOrgUnits();
        }
    }

}
