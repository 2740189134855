import { Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnChanges{
  @Input() isVisible: boolean;
  @Input() minWidth: string;
  @Input() maxWidth: string;
  @Input() zIndex: string;
  @Input() width: string;

  biggerThanSmScreen: boolean = false;

  toggle (boolean) {
    this.isVisible = boolean;
  }

  @HostListener('window:resize',['$event'])
  getWindowSize(){
    if(window.innerWidth > 640){
      this.biggerThanSmScreen = true;
    } else {
      this.biggerThanSmScreen = false;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {

      if (changes.hasOwnProperty('minWidth')) {

        this.minWidth = changes?.minWidth?.currentValue
      }

      if (changes.hasOwnProperty('maxWidth')) {
        this.maxWidth = changes?.maxWidth?.currentValue
      }
  }
}
