import { SharedService } from 'src/app/shared/shared.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionService } from 'src/app/pages/disciplines/components/content/section.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';

const DEFAULT_DURATION = 250;

@Component({
  selector: 'app-substitute-activity',
  templateUrl: './substitute-activity.component.html',
  styleUrls: ['./substitute-activity.component.scss'],
  animations: [
    trigger('collapse', [
      state(
        'true',
        style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
      ),
      state(
        'false',
        style({ height: '0px', visibility: 'hidden', opacity: 0 })
      ),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in')),
    ]),
  ],
})
export class SubstituteActivityComponent implements OnInit, OnChanges {
  @Output() substitute_activity = new EventEmitter();
  @Input() load_substitute_activity: any = {};
  form: UntypedFormGroup;

  section: any[] = [];
  studentList: any[] = [];
  studentListSelected: any[] = [];
  changeModalUsers: boolean = false;
  collapseOption: boolean = false;
  currentP: number = 1;
  itemsPage: number = 6;
  search_user: string = '';
  discipline_external_id: string = '';
  i18n: any = {};

  constructor(
    private sectionService: SectionService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private sharedService: SharedService,
    private disciplineDetailService: DisciplineDetailService
  ) {}

  ngOnInit(): void {
    this.sectionService.sections = [];
    this.getParams();
    this.getTranslations();
    this.createForm();
    this.getDisciplineDetail();
    this.getSections();
  }

  ngOnChanges(): void {
    if (
      this.load_substitute_activity !== null &&
      Object.keys(this.load_substitute_activity).length !== 0
    ) {
      this.setForm();
    }
  }

  getTranslations() {
    this.i18n = this.sharedService.getTranslationsOf('Disciplines');
  }

  toggleOptions(): void {
    this.collapseOption = !this.collapseOption;
  }

  toggleModal(): void {
    this.changeModalUsers = !this.changeModalUsers;
  }

  createForm(): void {
    this.form = this.fb.group({
      activity_substitute: [''],
      grade_config: [''],
    });
  }

  getParams(): void {
    this.discipline_external_id = this.router?.url.split('/')[2];
  }

  isAllSelected(user, boolean) {
    const index = this.studentListSelected.findIndex((savedUser) => savedUser.external_id === user.external_id);
    
    if (boolean && index === -1) {
      this.studentListSelected.push(user);
    } else if (!boolean && index !== -1) {
      this.studentListSelected.splice(index, 1);
    } else if (boolean && index !== -1) {
      this.studentListSelected[index] = user;
    }
  }

  getDisciplineDetail() {
    this.disciplineDetailService
      .disciplineBasic$
      .subscribe((discipline) => {
        this.studentList = discipline?.participants?.student || [];
        if (this.studentList.length) {
          this.studentList.map((e) => e.isSelected = false);
        }
      });
  }

  getSections() {
    const getSections = setInterval(() => {
      if (this.sectionService.sections.length !== 0) {
        this.sectionService.sections.forEach((sections) => {
          sections.contents?.forEach((content) => {
            this.section.push(content);
          });
        });
        clearInterval(getSections);
      }
    }, 1000);
  }

  sendInformation(): void {
    let external_ids: string[] = [];
    this.studentListSelected.forEach((e) => { external_ids.push(e.external_id)});
    let activity_substitute = this.form.controls['activity_substitute'].value;
    let grade_config = this.form.controls['grade_config'].value;

    if (activity_substitute && grade_config && external_ids.length) {
      this.substitute_activity.emit({
        content_external_id: activity_substitute,
        substitute_way: grade_config,
        elegible_students: external_ids,
      });
    }
  }

  setForm(): void {
    this.form.controls['activity_substitute'].setValue(
      this.load_substitute_activity.content_external_id
    );

    this.form.controls['grade_config'].setValue(
      this.load_substitute_activity.substitution_way
    );

    this.studentList.filter((e) => {
      this.load_substitute_activity.elegible_students.forEach((element) => {
        if (e.external_id === element) this.studentListSelected.push(e);
      });
    });
  }
}
