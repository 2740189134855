<div class="flex justify-center items-center h-full" *ngIf="isLoading$ | async">
  <app-bullets-loader></app-bullets-loader>
</div>

<section
  class="grid xl:grid-cols-2 grid-cols-1 gap-4 dash-transition"
  style="position: relative; align-items: flex-start"
  *ngIf="!showLoading"
>
  <!-- Card de últimas atividades -->
  <app-card-teacher
    [dashData]="dashData"
    [user]="user"
    [i18n]="i18n"
    (showSchedule)="toggleModal()"
  ></app-card-teacher>

  <div class="flex flex-col gap-4">
    <!-- Card de últimos avisos -->
    <app-last-announcements *ngIf="showAnnouncements === true"
      [i18n]="i18n"
     [dashData]="dashData">
    </app-last-announcements>

    <!-- FEED ---->
  <app-dashboard-feed
      [i18n]="i18n"
     [dashData]="dashData">
  </app-dashboard-feed>
  </div>

</section>


