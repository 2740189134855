import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  constructor(
    private  route: ActivatedRoute,
    private   http: HttpClient,
    private router: Router,
  ) { }

  getTerm(term: string,): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/search?term=${term}`);
  }

  toTheContent( discipline_id: any,externalId: any, sectionExternalId: any, sectionTitle: any, contentType: any) {
    this.router.navigate(
      [
        `../disciplines/${discipline_id}/content-view/${externalId}`,
        { section: sectionExternalId, section_title: sectionTitle, type: contentType },
      ],
    );
  }

  toTheSeries( discipline_id: any,externalId: any, sectionExternalId: any, sectionTitle: any, contentType: any) {
    this.router.navigate(
      [
        `../disciplines/${discipline_id}/content-view/${externalId}`,
        { section: sectionExternalId, section_title: sectionTitle, type: contentType },
      ],
    );
  }

  toAnnouncements(discipline_id: any) {
    this.router.navigate(
      [`../disciplines/${discipline_id}/announcements`],
      //{relativeTo: this.route,}
    );
  }

  toDisciplineOverview(discipline_id: any) {
    this.router.navigate(
      [`/disciplines/${discipline_id}/overview`]
    );
  }

  toDisciplines() {
    this.router.navigate(
      [`../disciplines`],
      {relativeTo: this.route,}
    );
  }

  toSettings() {
    this.router.navigate(
      [`../settings`],
      {relativeTo: this.route,}
    );
  }

  toUsers() {
    this.router.navigate(
      [`../users`],
      {relativeTo: this.route,}
    );
  }

  toSeries( discipline_id: any,externalId: any, sectionExternalId: any, contentType: any) {
    this.router.navigate(
      [
        `../disciplines/${discipline_id}/content-view/${externalId}`,
        { section: sectionExternalId, type: contentType },
      ],
      { relativeTo: this.route,}
    );
  }
}
