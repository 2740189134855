<form 
    [formGroup]="commentForumForm" 
    class="pl-4 pt-8 transitionAnimation"
    [ngClass]="{'border-reply': hasDashedBorder}"
>
    <ng-container *ngIf="hasReplyIt">
        <div *ngIf="!isEditMode" class="flex pt-0 p-2">
            <span class="text-customGray-dark font-bold">
                {{i18n.forum_reply_it}} <span class="italic">{{thread?.author?.name}}</span>
            </span>
        </div>
    </ng-container>
    <div class="flex gap-2">
        <div>
            <app-user-frame
                [style]="{'width':'40px', 'height':'40px', 'font-size': '15px', 'font-weight': 'bold'}"
                [img_src]="user?.image || ''"
                [user_name]="user.name || ''">
            </app-user-frame>
        </div>
        <div class="w-full border border-solid border-customGray-grayTwo rounded-md p-2 bg-customWhite-default">
            <textarea class="w-full" rows="5" required formControlName="text"></textarea>
            <div *ngIf="!text?.pristine && text?.errors">
                <p class="text-sm " style="color: #E8532C;" *ngIf="text?.errors?.required">
                    {{i18n.forum_text_is_required}}
                </p>
            </div>
        </div>
    </div>
    <div class="flex mt-8 justify-end">
        <button 
            *ngIf="hasCancelButton"
            class="bg-white font-semibold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            style="color: #233674" 
            type="button" 
            (click)="cancelReply()"
        >
            {{i18n.forum_close}}
        </button>
        <button 
            type="submit" 
            class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !commentForumForm.valid}" 
            [disabled]="!commentForumForm.valid" 
            (click)="saveComment(thread?.id)"
        >
            {{i18n.forum_comment_it}}
        </button>
    </div>
</form>