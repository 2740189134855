<section>
  <h1 class="text-customBlue-default font-bold text-22 mb-5">
    {{ i18n.grades_grades }}
  </h1>
  <ul>
    <li class="my-4" *ngFor="let period of periods">
      <app-custom-dropdown
        [title]="period?.name"
        [stylization]="defaultStylization"
      >
        <div class="bg-customWhite-default rounded-md w-auto overflow-auto">
          <table class="bg-customWhite-default rounded-md w-full rounded-t-lg">
            <thead>
              <tr class="text-customBlue-default text-18 font-bold">
                <th class="text-left p-4 pl-8" [style.width]="'21.875rem'">
                  {{ i18n.grades_category }}
                </th>
                <th>{{ i18n.grades_lesson }}</th>
                <th>{{ i18n.grades_grade }}</th>
                <th><!-- Required --></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let grade of period?.student?.grades">
                <td>
                  <p class="p-2 pl-8 mb-1 text-customGray-dark">
                    {{ grade.category_name }}
                  </p>
                </td>
                <td>
                  <p
                    class="text-center text-customGray-dark rounded-sm p-2 px-4 mx-4"
                  >
                    {{ grade.content_title }}
                  </p>
                </td>
                <td>
                  <div
                    class="text-center text-customGray-dark rounded-sm p-2 px-4 mx-4"
                    [ngClass]="{
                      'bg-no-evaluation': grade?.evaluation == null
                    }"
                  >
                    <div>
                      {{ grade?.evaluation }}
                    </div>
                    <div *ngIf="grade?.evaluation == null">
                      {{ i18n.grades_no_grade }}
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
              <tr class="border-t border-customGray-lighterGray">
                <td class="text-left header p-4 pl-8">
                  {{ i18n.grades_final_grade }}
                </td>
                <td></td>
                <td class="text-center p-4">
                  <div class="text-customBlue-default text-30 font-bold">
                    <div>
                      {{ period?.student?.average }}
                    </div>
                    <div
                      *ngIf="period?.student?.average == null"
                      class="text-18"
                    >
                      {{ i18n.grades_no_grade }}
                    </div>
                  </div>
                  <div class="flex justify-center items-center">
                    <ng-container [ngSwitch]="period?.student?.status">
                      <div
                        *ngSwitchCase="'grade_status_approved'"
                        class="flex items-center text-customGreen-approved"
                      >
                        <span
                          class="iconify w-4 h-4"
                          data-icon="akar-icons:circle-check"
                        ></span>
                        <p class="text-12 ml-1">{{ i18n.grades_approved }}</p>
                      </div>
                      <div
                        *ngSwitchCase="'grade_status_reproved'"
                        class="flex items-center text-inicieColors-orange"
                      >
                        <span
                          class="iconify w-5 h-5"
                          data-icon="mingcute:close-circle-line"
                        ></span>
                        <p class="text-12 ml-1">{{ i18n.grades_reproved }}</p>
                      </div>
                      <div
                        *ngSwitchCase="'grade_status_open'"
                        class="flex items-center text-customBlue-royal"
                      >
                        <span
                          class="iconify w-5 h-5"
                          data-icon="ph:dots-three-circle"
                        ></span>
                        <p class="text-12 ml-1">{{ i18n.grades_open }}</p>
                      </div>
                    </ng-container>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-custom-dropdown>
    </li>
  </ul>
  <app-empty-data class="block p-4" *ngIf="!periods.length"></app-empty-data>
</section>
