import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CertificatesSharedService {
  modalEmitCertificate$ = new BehaviorSubject<boolean>(false);
  modalEditCertificate$ = new BehaviorSubject<boolean>(false);
  modalConfirmCertificate$ = new BehaviorSubject<boolean>(false);
  modalResourcesCertificate$ = new BehaviorSubject<boolean>(false);
  modalAddModelCertificate$ = new BehaviorSubject<boolean>(false);
  modalSettModelCertificate$ = new BehaviorSubject<boolean>(false);
  modalDefaultCertificateDel$ = new BehaviorSubject<boolean>(false);
  modalEditTitle$ = new BehaviorSubject<string>('TESTANDO');
  modalEditOrAdd$ = new BehaviorSubject<boolean>(false);
  previewIsLoading$ = new BehaviorSubject<boolean>(false);

  constructor() { }


  modalOpenEmitCertificate() {
    this.modalEmitCertificate$.next(true);
  }
  modalCloseEmitCertificate() {
    this.modalEmitCertificate$.next(false);
  }

  modalOpenEditCertificate() {
    this.modalEditCertificate$.next(true);
  }
  modalCloseEditCertificate() {
    this.modalEditCertificate$.next(false);
  }

  modalOpenConfirmCertificate() {
    this.modalConfirmCertificate$.next(true);
  }
  modalCloseConfirmCertificate() {
    this.modalConfirmCertificate$.next(false);
  }
  modalOpenDeleteDefault() {
    this.modalDefaultCertificateDel$.next(true);
  }
  modalCloseDeleteDefault() {
    this.modalDefaultCertificateDel$.next(false);
  }

  addFileMode() {
    this.modalEditOrAdd$.next(false);
  }
  editFileMode() {
    this.modalEditOrAdd$.next(true);
  }

  editModalTitleADD() {
    this.modalEditTitle$.next('Adicionar Modelo')
  }
  editModalTitleEDIT() {
    this.modalEditTitle$.next('Editar Modelo')
  }

  modalOpenResourcesCertificate() {
    this.modalResourcesCertificate$.next(true);
  }
  modalCloseResourcesCertificate() {
    this.modalResourcesCertificate$.next(false);
  }

  previewIsLoadingTrue() {
    this.previewIsLoading$.next(true);
  }
  previewIsLoadingFalse() {
    this.previewIsLoading$.next(false);
  }

  modalOpenAddModelCertificate() {
    this.modalAddModelCertificate$.next(true);
  }
  modalCloseAddModelCertificate() {
    this.modalAddModelCertificate$.next(false);
  }

  modalOpenSettModelCertificate() {
    this.modalSettModelCertificate$.next(true);
  }
  modalCloseSettModelCertificate() {
    this.modalSettModelCertificate$.next(false);
  }



}
