import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    saveDataCreatedContent$ : Subject<any> = new BehaviorSubject<any>({});
    receiveNewStateSubmissions = false;
    constructor(private http: HttpClient) {}

    extractDisciplineExternalId() {
        return sessionStorage.getItem('discipline_external_id');
    }

    getContent(
        discipline_external_id: string,
        section_external_id: string,
        content_external_id: string
    ): Observable<any> {
        return this.http
            .get<any>(
            `${API_Routes.URL}/v2/disciplines/${discipline_external_id}/sections/${section_external_id}/contents/${content_external_id}/visualization`
        )
            .pipe(take(1));
    }

    getContentForm(
        discipline_external_id: string,
        section_external_id: string,
        content_external_id: string
    ): Observable<any> {
        return this.http
            .get<any>(
            `${API_Routes.URL}/disciplines/${discipline_external_id}/sections/${section_external_id}/contents/${content_external_id}/form`
        )
            .pipe(take(1));
    }

    getScormUrl(
        discipline_external_id: string,
        section_external_id: string,
        content_external_id: string,
        width: string,
        height: string
    ): Observable<any> {
    // getContent(): Observable<any> {
    // return this.http.get<any>(`http://localhost:3000/content`);
        return this.http
            .get<any>(
            `${API_Routes.URL}/disciplines/${discipline_external_id}/sections/${section_external_id}/contents/${content_external_id}/load?width=${width}&height=${height}`
        )
            .pipe(take(1));
    }

    getConferenceOrganizer(external_id: any, typeConference: string): Observable<any> {
        return this.http
            .get<any>(`${API_Routes.URL}/disciplines/${external_id}/basic?content_type=${typeConference}`)
            .pipe(take(1));
    }

    checkUrl(params: any): Observable<any> {
        return this.http
            .post<any>(`${API_Routes.URL}/common/checkUrl`, params)
            .pipe(take(1));
    }

    postContentsOrder(
        discipline_external_id: string,
        section_external_id: string,
        content_external_id: string,
        params: any
    ): Observable<any> {
        return this.http
            .patch<any>(
            `${API_Routes.URL}/disciplines/${discipline_external_id}/sections/${section_external_id}/contents/${content_external_id}/move`,
            params
        )
            .pipe(take(1));
    }

    // getSection(discipline_external_id: string): Observable<any> {
    //   return this.http.get<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/sections`);
    //   // return this.http.get<any>(`http://localhost:3000/content/${id}`);
    // }

    updateContent(
        section_external_id,
        content_external_id: string,
        params: any
    ): Observable<any> {
        const disciplineExternalId = this.extractDisciplineExternalId();
        return this.http
            .patch(
                `${API_Routes.URL}/disciplines/${disciplineExternalId}/sections/${section_external_id}/contents/${content_external_id}`,
                params
            )
            .pipe(take(1));
    }

    postContent(
        section_external_id: string,
        content_external_id = 'do NOT remove this',
        params: any
    ): Observable<any> {
        const disciplineExternalId = this.extractDisciplineExternalId();
        return this.http
            .post<any>(
            `${API_Routes.URL}/disciplines/${disciplineExternalId}/sections/${section_external_id}`,
            params
        )
            .pipe(take(1));
    // return this.http.post(`http://localhost:3000/content`, params);
    }

    deleteContent(
        discipline_external_id: string,
        section_external_id: string,
        content_external_id: string
    ): Observable<any> {
        return this.http
            .delete<any>(
            `${API_Routes.URL}/disciplines/${discipline_external_id}/sections/${section_external_id}/contents/${content_external_id}`
        )
            .pipe(take(1));
    // return this.http.delete(`http://localhost:3000/content/${id}`);
    }

    getStatusInternalConference(external_id: any): Observable<any> {
        return this.http
            .get<any>(`${API_Routes.URL}/jitsi/status/${external_id}`);
    }

    updateExpandedSection(
        section_external_id: string,
        params: any
    ): Observable<any> {
        const disciplineExternalId = this.extractDisciplineExternalId();
        return this.http
            .patch(
                `${API_Routes.URL}/disciplines/${disciplineExternalId}/sections/${section_external_id}/expand`,
                params
            )
            .pipe(take(1));
    }

    postSupportMaterial(
        section_external_id: string,
        content_external_id = 'do NOT remove this',
        params: any
    ): Observable<any> {
        const disciplineExternalId = this.extractDisciplineExternalId();
        return this.http
            .post<any>(
            `${API_Routes.URL}/support_material/${disciplineExternalId}`,
            params
        )
            .pipe(take(1));
    }

    updateSupportMaterial(
        section_external_id,
        content_external_id: string,
        params: any
    ): Observable<any> {
        const disciplineExternalId = this.extractDisciplineExternalId();
        return this.http
            .patch(
                `${API_Routes.URL}/support_material/${disciplineExternalId}/${content_external_id}`,
                params
            )
            .pipe(take(1));
    }

    getOneSupportMaterial(content_external_id: any): Observable<any> {
        const disciplineExternalId = this.extractDisciplineExternalId();

        return this.http
            .get<any>(`${API_Routes.URL}/support_material/${disciplineExternalId}/${content_external_id}`);
    }
}
