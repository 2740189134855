import {
    Component, EventEmitter, Input,
    Output
} from '@angular/core';

@Component({
    selector: 'app-upload-file-preview',
    templateUrl: './upload-file-preview.component.html',
    styleUrls: ['./upload-file-preview.component.scss']
})
export class UploadFilePreviewComponent {
    @Input() isModalOpen = false;
    @Output() isModalOpenChange = new EventEmitter();
    @Input() i18n;
    @Input() filePreviewUrl = '';

    closeModal() {
        this.isModalOpenChange.emit(false);
    }
}
