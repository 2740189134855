import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../../../grades.service';

@Component({
  selector: 'app-grades-student-overview',
  templateUrl: './grades-student-overview.component.html',
  styleUrls: ['./grades-student-overview.component.scss']
})
export class GradesStudentOverviewComponent implements OnInit {

  // define os estilos do custom-dropdown
  defaultStylization = {
    borderWidth: 1,
    closed: {
      borderColor: '#E3E4E4',
      textColor: '#233674',
      iconColor: '#233674',
      fontWeight: '700',
      backgroundColor: '#F3F4F4'
    },
    expanded: {
      borderColor: '#F4F4F4',
      textColor: '#484B4D',
      iconColor: '#233674',
      fontWeight: '700',
      backgroundColor: '#F4F4F4'

    }
  }
  
  grade_id: any;
  period_id_to_open: any;
  student_external_id: any;
  discipline_external_id: any;
  grades: any;
  periods: any;  
  student: any;
  studentPreSetup: any;
  periodsPreSetup: any;
  loading: boolean = false;


  i18n: any = [];


  constructor(
    private gradesService: GradesService,
    private route: ActivatedRoute, 
    private sharedSerivce: SharedService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getTranslations();
    this.getUrlParams()
    this.getPeriods();
  }

  getTranslations(){
    this.i18n = this.sharedSerivce.getTranslationsOf('Grades')
  }

  getUrlParams(){
    this.route?.params?.subscribe((params)=> {
      this.student_external_id = params.external_id;
      this.period_id_to_open = params.open;
      
    });
    
    this.route?.parent?.parent?.parent?.params?.subscribe((params)=> {
      this.discipline_external_id = params.id;
    });
  } 

  iterator: number = 0;
  maxSizePeriods:any;

  getPeriods(){
    this.gradesService.getPeriodsList(this.discipline_external_id)
    .subscribe((periods:any)=>{
      this.iterator = 0;
      this.periodsPreSetup = periods;
      this.maxSizePeriods = this.periodsPreSetup.length;
      if (this.maxSizePeriods > 0) {
        this.getGrades(this.periodsPreSetup[this.iterator].id, this.iterator)
        
      }     
    })
  }

  getGrades(period_id: any, iterator: any){
    this.gradesService.getGradesList(period_id)
    .subscribe((period:any)=>{
      
      this.studentPreSetup = period?.students.find((student: any)=>{return student.external_id == this.student_external_id});
      
      if (this.studentPreSetup !== undefined) {
        this.periodsPreSetup[iterator]['student'] = this.studentPreSetup;
        this.studentPreSetup['calculation_type'] = this.periodsPreSetup[iterator]['calculation_type'];
        this.periodsPreSetup[iterator]['student']['categories'] =  this.periodsPreSetup[iterator]?.categories;
        this.setUpGrades(this.periodsPreSetup[iterator]?.student);

        this.student = this.studentPreSetup;
        
      }
      this.iterator++;
      
      if (this.iterator < this.maxSizePeriods) {
        this.getGrades(this.periodsPreSetup[this.iterator].id, this.iterator)
      }else{
        this.loading = false;
        this.periods = this.periodsPreSetup;

      }
    })
  }    

  // seta os nomes das categorias de cada nota
  setUpGrades(student:any){


    student?.categories.forEach((category: any)=>{
      category[category.name] = [];
      student?.grades.forEach((grade: any)=>{
        // inclui o nome da categoria dentro do 
        // objeto de cada nota
        if (category.id == grade.grade_category_id) {
          // student[category.name].push(grade);
          category[category.name].push(grade)
          // grade['category_name'] = category.name;
          // grade['category_weight'] = category.weight
        }
      })
    })      

    // console.log('student ', student);
    
  }   

}
