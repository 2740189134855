import {
    Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';

import { DashboardService } from '../dashboard.service';

@Component({
    selector: 'app-student',
    templateUrl: './student.component.html',
    styleUrls: ['./student.component.scss'],
})

export class StudentComponent implements OnInit, OnChanges {
    // Variables
    name: any = [];
    dash: any = [];
    feedName;
    globalSchedule: any = [];
    bigger: any;
    isLoading$ = this.dashboardService.isLoading$;
    showLoading = true;
    showFeed = false;
    showResume = false;

    @Input() i18n: any = [];
    @Input() user: any = [];
    @Input() studentData: any = [];

    objectKeys = Object.keys;

    showModal = false;

    constructor(
        private dashboardService: DashboardService,
        private router: Router
    // private cookieService: CookieService,
    ) {}

    ngOnInit(): void {
    // this.createArrayOfSameTimes();
        this.getLoading();
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.showElements();
        console.log('translate student', this.i18n);
        console.log('data Student from Dash', Object.keys(this.studentData));
        if (this.studentData?.schedule?.length > 0) {
            console.log('CHAMA createArrayOfSameTimes');
            this.createArrayOfSameTimes();
        }
    }

    getLoading() {
        console.log('MOSTRA STUDENT: ', this.showLoading);
        this.isLoading$.subscribe(
            (data) => this.showLoading = data
        );
    }

    showElements() {
        console.log('SHOW RESUME DADOS', this.studentData.resume_data);
        console.log('SHOW RESUME fora', this.showResume);
        if (this.studentData.resume_data?.length > 0) {
            this.showResume = true;
            console.log('SHOW RESUME dentro', this.showResume);
        }

        /*   if (this.studentData.feed.length > 0){
      this.showFeed = true
      console.log('SHOW FEED dentro', this.showFeed);
    } */
    }

    // studentArray = this.student;

    createArrayOfSameTimes() {
        console.log('Student length > 0');

        this.studentData?.schedule?.forEach((element) => {
            const newArray: any = [];
            const sameTimesArray: any = [];

            element.events.forEach((event) => {
                sameTimesArray[event.date] = element.events.filter((check) => check.date == event.date);
            });

            for (const element in sameTimesArray) {
                newArray.push({
                    date: element,
                    values: sameTimesArray[element],
                });
            }
            element.events = newArray;
        });

        this.globalSchedule = this.studentData?.schedule;
        console.log('Teacher Schedule', this.studentData?.schedule);
    }

    toggleModal() {
        this.showModal = !this.showModal;
    }
}
