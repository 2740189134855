<div class="discipline-card-wrapper">
    <div  class="tree-dots-wrapper">
        <button (click)="displayPanel.toggle()">
            <img src="../../../assets/svg/three-vertical-dots.svg">
        </button>
        <app-display-panel #displayPanel
            [style]="{ right: '-18px', top: '35px' }"
            [arrow_deslocation]="'100%'"
            [custom_radius]="'10px'">
        <button
            class="tab-font"
            (click)="
            displayPanel.toggle();
            callImportModa();
            callingGetSections()
            ">
            {{ i18n.disciplines_export }}
        </button>
        <button
            class="tab-font"
            (click)="
            callDuplicate();
            ">
            {{ i18n.disciplines_duplicate }}
        </button>
        <button
            class="tab-font"
            (click)="toDisciplineSettings(disciplineCode)">
            {{ i18n.disciplines_content_edit }}
        </button>
        <button
            class="tab-font"
            (click)="displayPanel.toggle();
            callRemoveTemplate();">
            {{ i18n.disciplines_delete }}
        </button>
      </app-display-panel>
    </div>
    <div class="discipline-card-header"
    [ngClass]="{'background-card': discipline?.card_image === null}"
    [style.background-image]="discipline?.card_image !== null ? 'url(' + discipline?.card_image + ')' : false">
        <h3 title="{{ disciplineTitle }}"
        [style.color]="discipline?.card_text_color">{{ textCut(45, disciplineTitle) }}</h3>
    </div>
    <div class="discipline-card-body">
        <div class="flex items-center justify-between gap-4 px-4 pt-4">
            <div class="flex items-center gap-2">
                <i class="discipline-container__icon html-tags-icon"></i>
                <p class="discipline-cod mb-1 truncate" pTooltip="{{ disciplineCode }}"
                tooltipPosition="top" pRipple>{{ textCut(20, disciplineCode) }}</p>
            </div>

            <!-- <p class="discipline-cod" title="{{ disciplineCode }}">{{ disciplineCode }}</p> -->
            <a target="_blank" href="/disciplines-models/{{ disciplineCode }}/content-tab" rel="noopener noreferrer"
            pTooltip="{{ i18n.disciplines_open_new_window }}" tooltipPosition="top" pRipple
            class="block border border-customGray-default text-customGray-default text-16 rounded p-1.5">
              <span class="iconify" data-icon="ic:baseline-open-in-new"></span>
            </a>
        </div>

        <footer class="flex items-center justify-between p-4 gap-4">
            <button
            class="button-card px-4"
            (click)="toDisciplineOverview(disciplineCode)">
            {{ i18n.disciplines_view_class }}
            </button>

            <button class="button-card px-4"
            (click)="callImportModa()"
            (click)="callingGetSections()">
            {{ i18n.disciplines_export }}
            </button>
        </footer>
    </div>
</div>
