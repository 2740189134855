import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DisciplineDetailService {
    disciplineDetail: any = {};
    constructor(private http: HttpClient) {}

    disciplineBasic$: BehaviorSubject<any> = new BehaviorSubject({});
    titleName$: BehaviorSubject<string> = new BehaviorSubject('');
    hasSelectTab$: Subject<boolean> = new Subject();

    getDisciplineDetail(external_id: any): Observable<any> {
        return this.http.get<any>(
            `${API_Routes.URL}/disciplines/${external_id}/basic`
        );
    // return this.http.get<any>(`http://localhost:3000/disciplineDetail/${id}`);
    }
    getEmitedCertificates(emited_id: any): Observable<Blob> {
        return this.http.get(
            `${API_Routes.URL}/certificates/${emited_id}/export.pdf`,
            { responseType: 'blob' }
        );
    }
}
