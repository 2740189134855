import {
    Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ContentViewUploadQuizService } from './content-view-upload-quiz.service';

@Component({
    selector: 'app-content-view-upload-quiz',
    templateUrl: './content-view-upload-quiz.component.html',
    styleUrls: ['./content-view-upload-quiz.component.scss']
})
export class ContentViewUploadQuizComponent implements OnInit, OnChanges {
    @ViewChild('iframeQuiz') iframeQuiz! : ElementRef;

    @Input() getDataFromContentView: any;
    @Input() content: any;

    elem;
    i18n: any = [];
    loading = false;
    isSumbission_out_date = false;
    showIframe = true;
    srcIframe = '';
    user;

    constructor(
        public platformModalsService: PlatformModalsService,
        private sharedService: SharedService,
        private contentViewUploadQuizService: ContentViewUploadQuizService
    ) {
        this.user = this.sharedService.getUserSync();
    }

    ngOnInit(): void {
        this.getTranslations();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes?.getDataFromContentView
            && this.getDataFromContentView
        ) {
            console.log('getDataFromContentView ', this.getDataFromContentView);

            this.verifyRenderIframe(this.getDataFromContentView);
        }
    }

    verifyRenderIframe(getDataFromContentView: any) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { accepted_response } = getDataFromContentView;
        const srcIframe = getDataFromContentView.view_url;
        if (!accepted_response) {
            this.isSumbission_out_date = true;
            return;
        }

        if (srcIframe != null) {
            this.srcIframe = srcIframe;
            this.showIframe = true;
        }
    }

    answersUrl() {
        window.open(this.getDataFromContentView.edit_url);
    }

    sheetsAnswersUrl() {
        window.open(this.getDataFromContentView.results_url);
    }

    countForm = 0;
    onLoadForm() {
        this.countForm += 1;
        if (this.countForm >= 2) {
            this.contentViewUploadQuizService.finishQuiz(this.content.external_id).subscribe({
                next: (res: any) => {
                    console.log('res ', res);
                },
                error: () => {

                }
            });
            console.log('reload', this.countForm);
            console.log('iframeQuiz ', this.iframeQuiz);
            console.log('iframeQuiz SRC ', this.iframeQuiz?.nativeElement?.src);
        }
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines'),
            ...this.sharedService.getTranslationsOf('Modal'),
        };
    }
}
