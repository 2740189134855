<ng-container *ngIf="viewOnly && !previousText">
  <div class="fake-input desktop">
    <span class="student-answer"
      >{{ i18n.question_bank_student_answer }}...</span
    >
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at mauris
    tincidunt, scelerisque elit nec, eleifend diam. In et erat vehicula,
    fringilla mauris id, maximus neque. Mauris sed fermentum sem.
    <br /><br />
    Donec tincidunt, tortor scelerisque rhoncus facilisis, mauris nulla
    malesuada leo, eget fringilla odio eros rutrum mi. Maecenas magna sem,
    bibendum ac ex vel, ultrices tempor orci. Nullam tempus tincidunt nibh vitae
    venenatis. Fusce in maximus orci.
    <br /><br />
    Quisque placerat luctus velit, quis congue nisi rutrum vel. Phasellus diam
    nulla, mollis suscipit cursus at, viverra sed lacus. Aliquam in ex sem.
  </div>

  <div class="fake-input mobile">
    <span class="student-answer"
      >{{ i18n.question_bank_student_answer }}...</span
    >
    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    <br /><br />
    Donec tincidunt, tortor scelerisque rhoncus facilisis.
    <br /><br />
    Quisque placerat luctus velit, quis congue nisi rutrum vel.
  </div>
</ng-container>

<div class="fake-input desktop" *ngIf="viewOnly && previousText">
  <span class="student-answer">{{ previousText }}</span>
</div>

<textarea
  *ngIf="!viewOnly"
  (keyup)="writeOnData($event)"
  class="real-input"
  type="text"
  [value]="previousText || ''"
  [placeholder]="i18n.question_bank_student_answer"
></textarea>
