import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GoogleGetFileService } from 'src/app/services/google-get-file-service.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const DEFAULT_DURATION = 250;
@Component({
  selector: 'app-submission-approve-final-score',
  templateUrl: './submission-approve-final-score.component.html',
  styleUrls: ['./submission-approve-final-score.component.scss'],
  animations: [
    trigger('collapse', [
      state(
        'true',
        style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
      ),
      state(
        'false',
        style({ height: '0px', visibility: 'hidden', opacity: 0 })
      ),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in')),
    ]),
  ],
})
export class SubmissionApproveFinalScoreComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() type: string;
  @Input() files: any;//pode ser vários arquivos
  @Input() i18n: any;
  title: boolean = true;
  hitsAndMisses: boolean = false;
  fileDownloaded: any = [];
  isGapiLoaded = this.googleGetFileService.isGapiLoaded;
  getDownload: boolean = false;
  isGapiLoadedSubscription: Subscription;
  collapseOption: boolean = false;
  indexFile: number = 0;
  file: any; 



  constructor(
    private googleGetFileService: GoogleGetFileService,
    private platModalService: PlatformModalsService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.handleType();
    // Cria inscrição para escutar quando um serviço do Google Drive for iniciado
    // Ver método getGoogleFile() para iniciar um serviço

    this.isGapiLoadedSubscription =
      this.googleGetFileService.isGapiLoaded.subscribe((status: any) => {
        console.log('status ', status);
        
        this.isGapiLoaded = status;
        if (this.getDownload) {
          this.downloadFile();
        }
      });
  }

  ngAfterContentInit(): void {
    console.log('files ', this.files);
    
  }

  toggleCollapseOption() {
    this.collapseOption = !this.collapseOption;
  }

  changeFile(index: number) {
    this.indexFile = index;
    document.getElementsByClassName('router-outlet')[0].scrollTop = 0;
  }

  returnIconMaterialsSupport(mime_type: string) {
    return mime_type.includes('image')
      ? 'LTI'
      : mime_type.includes('video')
      ? 'Youtube'
      : mime_type.includes('text')
      ? 'TextPage'
      : 'FilePDF';
  }


  handleType() {
    const expectedType = 'UploadAssignment';

    if (this.type === expectedType) {
      this.title = false;
      this.hitsAndMisses = false;
    }
  }

  getFileInfo() {
    
    let filesInfo = {
      url: '',
      mime_type: '',
      name: '',
    };
    // identifica se é um arquivo do google apps
    if (this.file?.mime_type.includes('google-apps')) {
      filesInfo.mime_type =
        this.sharedService.selectMimeTypeToExport(this.file) || '';
    }

    filesInfo.url = this.file?.path;
    filesInfo.name = this.file?.file_name;
    return filesInfo;
  }

  downloadFile() {
    let file = this.getFileInfo();
    if (this.isGapiLoaded) {
      this.googleGetFileService
        .downloadFile(file.url)
        .subscribe({
          next: (response: any) => {
            console.log('downloadFile ',response);
            
            let url: any;
            if (file.mime_type !== '') {
              url = response.result.exportLinks[file.mime_type];
            } else {
              url = response.result.webContentLink;
            }
            switch (true) {
              case response.status == 200:
                window.location.assign(url);
                break;
              case response.status == 404:
                this.platModalService.toggle(
                  'message',
                  'Sem permissão para acessar o arquivo',
                  'close'
                );
                break;
  
              default:
                // setTimeout(() => this.getGoogleFile(file), 1000);
                break;
            }
            this.getDownload = false;
          },
        })
    }
  }

  // Inicia serviço do GoogleDrive para baixar o arquivo
  getGoogleFile(file) {
    console.log('ARQUIVO ', file);
    
    this.file = file;
    this.getDownload = true;
    this.googleGetFileService.initGoogleDriveGapi();
  }

  ngOnDestroy() {
    this.isGapiLoadedSubscription.unsubscribe();
  }
}
