<form class="w-full" [formGroup]="configSheetForm" (ngSubmit)="submitConfig()">
    <div class="w-full">
        <!-- Accordion 0 -->
        <div class="border border-customGray-light rounded-md py-3 px-4 mb-3">
            <div
                (click)="toggleItem(accordionItems[0])"
                class="cursor-pointer flex items-stretch sm:flex-row sm:items-center"
            >
                <span
                    class="text-14 sm:text-16 text-customGray-swampGray mb-2 sm:mb-0"
                >
                    {{ i18n.import_spreadsheet_row_start }}
                </span>
                <div class="flex ml-3 relative">
                    <span
                        class="iconify-inline text-18 sm:text-16 text-customGray-dark mr-3 sm:mr-0"
                        data-icon="carbon:help"
                    ></span>
                    <div
                        class="w-60 sm:w-80 absolute bg-customWhite-default p-2 rounded-md shadow-card tooltip-50 opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                    >
                        <span class="text-center text-14 text-customGray-dark">
                            {{ i18n.import_spreadsheet_tooltipe_start_line }}
                        </span>
                    </div>
                </div>
                <div class="flex sm:items-center ml-auto">
                    <div [class.rotate-180]="accordionItems[0].open">
                        <span
                            class="iconify-inline text-18 text-customGray-dark"
                            data-icon="raphael:arrowdown"
                        ></span>
                    </div>
                </div>
            </div>
            <div *ngIf="accordionItems[0].open" class="py-2 w-full">
                <!-- Campos do formulário para o Acordeão -->
                <div class="w-full">
                    <input
                        formControlName="start_import_row"
                        type="number"
                        min="1"
                        name="start_import_row"
                        required="true"
                        class="w-full border border-customGray-default rounded-sm px-4 py-2 mt-2"
                    />
                </div>
                <div
                    class="flex mt-2"
                    *ngIf="
                        configSheetForm.get('start_import_row')?.errors ||
                        (configSheetForm.get('start_import_row')?.touched &&
                            configSheetForm.get('start_import_row')?.invalid)
                    "
                >
                    <small class="font-normal text-customOrange-default">
                        {{ i18n.import_spreadsheet_form_validation_start_line }}
                    </small>
                </div>
            </div>
        </div>

        <!-- Accordion 1 -->
        <div class="border border-customGray-light rounded-md py-3 px-4 mb-3">
            <div
                (click)="toggleItem(accordionItems[1])"
                class="cursor-pointer flex items-stretch sm:flex-row sm:items-center"
            >
                <span class="text-14 sm:text-16 text-customGray-swampGray">
                    {{ i18n.import_spreadsheet_type_import }}
                </span>
                <div class="flex ml-3 relative">
                    <span
                        class="iconify-inline text-18 sm:text-16 text-customGray-dark mr-3 sm:mr-0"
                        data-icon="carbon:help"
                    ></span>
                    <div
                        class="w-60 sm:w-80 absolute bg-customWhite-default p-2 rounded-md shadow-card tooltip-50 opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                    >
                        <span class="text-center text-14 text-customGray-dark">
                            {{ i18n.import_spreadsheet_tooltipe_import_type }}
                        </span>
                    </div>
                </div>
                <div
                    [class.rotate-180]="accordionItems[1].open"
                    class="flex sm:items-center ml-auto"
                >
                    <span
                        class="iconify-inline text-18 text-customGray-dark"
                        data-icon="raphael:arrowdown"
                    ></span>
                </div>
            </div>
            <div *ngIf="accordionItems[1].open" class="py-2 w-full">
                <!-- Campos do formulário para o Acordeão -->
                <div class="mt-3">
                    <div class="flex items-center gap-4">
                        <label class="cursor-pointer">
                            <input
                                type="radio"
                                value="users"
                                class="mr-2"
                                formControlName="import_type"
                            />
                            <span class="text-16 text-customGray-swampGray">{{
                                i18n.import_spreadsheet_users
                            }}</span>
                        </label>
                        <label class="cursor-pointer">
                            <input
                                type="radio"
                                value="disciplines"
                                class="mr-2"
                                formControlName="import_type"
                            />
                            <span class="text-16 text-customGray-swampGray">{{
                                i18n.import_spreadsheet_label_disciplines
                            }}</span>
                        </label>
                        <label class="cursor-pointer">
                            <input
                                type="radio"
                                value="linking"
                                class="mr-2"
                                formControlName="import_type"
                            />
                            <span class="text-16 text-customGray-swampGray">{{
                                i18n.import_spreadsheet_label_linking
                            }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Accordion 3 -->
        <div class="border border-customGray-light rounded-md py-3 px-4 mb-3">
            <div
                (click)="toggleItem(accordionItems[3])"
                class="cursor-pointer flex items-stretch sm:flex-row sm:items-center"
            >
                <span class="text-14 sm:text-16 text-customGray-swampGray">
                    {{ i18n.import_spreadsheet_tabs_import }}
                </span>
                <div class="flex ml-3 relative">
                    <span
                        class="iconify-inline text-18 sm:text-16 text-customGray-dark mr-3 sm:mr-0"
                        data-icon="carbon:help"
                    ></span>
                    <div
                        class="w-60 sm:w-80 absolute bg-customWhite-default p-2 rounded-md shadow-card tooltip-50 opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                    >
                        <span class="text-center text-14 text-customGray-dark">
                            {{ i18n.import_spreadsheet_tooltipe_select_tabs }}
                        </span>
                    </div>
                </div>
                <div
                    [class.rotate-180]="accordionItems[3].open"
                    class="flex sm:items-center ml-auto"
                >
                    <span
                        class="iconify-inline text-18 text-customGray-dark"
                        data-icon="raphael:arrowdown"
                    ></span>
                </div>
            </div>
            <div *ngIf="accordionItems[3].open" class="py-2 w-full">
                <div class="flex flex-col gap-4">
                    <label
                        class="cursor-pointer"
                        *ngFor="let option of columns"
                    >
                        <input
                            type="radio"
                            [value]="option?.value"
                            class="mr-2"
                            formControlName="selected_tabs"
                        />
                        <span class="text-16 text-customGray-swampGray">
                            {{ option?.name }}
                        </span>
                    </label>
                </div>
                <div
                    class="flex mt-2"
                    *ngIf="
                        configSheetForm.get('selected_tabs')?.touched &&
                        configSheetForm.get('selected_tabs')?.invalid
                    "
                >
                    <small class="font-normal text-customOrange-default">
                        {{
                            i18n.import_spreadsheet_form_validation_selected_tabs
                        }}
                    </small>
                </div>
            </div>
        </div>
    </div>

    <!-- Buttons Footer -->
    <div class="mt-8 flex flex-col items-center sm:items-end w-full">
        <div
            class="w-full flex flex-col sm:flex-row items-center sm:items-end justify-center sm:justify-end"
        >
            <button
                class="bg-customBlue-default text-customWhite-default w-40 py-2 border mt-3 sm:mt-5 rounded-md"
                (click)="submitConfig()"
                [disabled]="configSheetForm.invalid"
                [ngClass]="{ 'disabled-button': configSheetForm.invalid }"
                type="submit"
                style="min-width: 150px"
            >
                <span class="text-16 font-bold">
                    {{ i18n.import_spreadsheet_btn_continue }}
                </span>
            </button>
            <button
                class="bg-customWhite-default text-customBlue-default ml-0 sm:ml-3 sm:w-auto w-40 py-2 border border-customBlue-default mt-3 sm:mt-5 rounded-md"
                (click)="goBack()"
                style="min-width: 150px"
            >
                <span class="text-16 font-bold">
                    {{ i18n.import_spreadsheet_btn_cancel }}
                </span>
            </button>
        </div>
    </div>
</form>
