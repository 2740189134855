import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { HomeLayoutComponent } from '../home/home.component';
import { PermissionsComponent } from './permissions.component';
import { PermissionsTitleResolver } from './permissions-title.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        component: PermissionsComponent,
        resolve: {
          title: PermissionsTitleResolver
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PermissionsRoutingModule { }
