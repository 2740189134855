import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-user-frame',
  templateUrl: './user-frame.component.html',
  styleUrls: ['./user-frame.component.scss']
})
export class UserFrameComponent implements OnInit, OnDestroy, OnChanges {
  @Input() img_src: string;
  @Input() background_color: string;
  @Input() user_name: string;
  @Input() adaptable_size: boolean;
  @Input() style: any;

  //sets the custom breakpoint width to mobile
  @Input() breakpoint_X: string = '944px';

  initial_letter: string;
  img_alt: string;
  breakpointObserverKey: any;

  constructor(private element: ElementRef, private sharedService: SharedService) { }

  ngOnInit() {
    // Removido pois fazia duas ou mais alterações desnecessárias já 
    // que as variáveis são chamadas via input, toda a alteração deve
    // ocorrer no OnChanges e não no OnInit

    // this.getInitialLetter();
    // this.getImageAlt();
    // this.setBackgroundColor();

    // this.adaptable_size
    // ? this.calculateFontSize()
    // : this.getMediaQuerySubscription()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.user_name && this.user_name || changes.img_src && this.img_src) {
        this.getInitialLetter();
        this.getImageAlt();
        this.setBackgroundColor();

        this.adaptable_size
        ? this.calculateFontSize()
        : this.getMediaQuerySubscription()     
      }
    }
  }

  getInitialLetter () {
    const user_name = this.user_name.repeat(1);
    this.initial_letter = user_name.charAt(0).toUpperCase();
    
  }

  getImageAlt () {
    this.user_name
    ? this.img_alt = `Imagem de perfil de ${this.user_name}`
    : this.img_alt = "Imagem de perfil"
  }

  setBackgroundColor () {
    const $frame = this.element.nativeElement.querySelector('.frame')

    this.background_color
    ? $frame.style.backgroundColor = this.background_color
    : $frame.style.backgroundColor = "CornflowerBlue"
  }

  getMediaQuerySubscription () {
    this.breakpointObserverKey = this.sharedService.callFunctionsOnBreakpoint(this.breakpoint_X, {
      element: this.element,
      queryString: '.frame'
    })
  }

  calculateFontSize () {
    const $frame = this.element.nativeElement.querySelector('.frame');
    $frame.classList.add('adaptable')
    const frameSize = $frame.offsetWidth;
    const constantWidth = 40;
    const constantFontSize = 23;

    const fontSize = frameSize * constantFontSize / constantWidth;
    $frame.style.fontSize = `${fontSize}px`;
  }

  onResize ($event) {
    $event.stopPropagation();

    if (this.adaptable_size) this.calculateFontSize();
  }

  onImageError () {
    this.img_src = ''
  }

  ngOnDestroy () {
    this.sharedService.removeBreakpointObserver(this.breakpointObserverKey);
  }
}
