<div class="comments" *ngIf="commentsVisibility">
  <div class="comments__title">{{ i18n.forum_comments }}</div>

  <div *ngIf="feedback.text" class="flex mb-10">
    <app-user-frame
      class="new__user-frame"
      [user_name]="feedback.author.name"
      [img_src]="feedback.author.image"
    ></app-user-frame>

    <div class="flex  flex-col gap-2 ml-5 w-full">

      <div class="flex justify-between gap-2 items-start">
        <div class="flex gap-2 flex-wrap">
          <span class="text-16 text-customGray-dark font-bold">
            {{ feedback.author.name }}
          </span>
          <span class="text-16 text-customWhite-default italic bg-customBlue-royal px-4 rounded-md">
            {{ userInfo.role }}
          </span>

          <span class="text-16 text-customGray-dark italic" *ngIf="feedback?.date">
            {{ i18n.disciplines_notices_published }} {{ getTime(feedback.date) }}
          </span>
        </div>

        <div class="flex gap-2">
          <button (click)="editComment(feedback.text)">
            <span class="iconify w-5 h-5 text-customGray-default" data-icon="akar-icons:edit"></span>
          </button>

          <button (click)="askForRemoveComment()">
            <span class="iconify w-5 h-5 text-customGray-default" data-icon="bi:trash"></span>
          </button>
        </div>
      </div>

      <div class="text-16 text-customGray-dark font-normal">
        {{ feedback.text }}
      </div>

    </div>
  </div>

  <div class="comments__new">
    <app-user-frame
      class="new__user-frame"
      [user_name]="userInfo.name"
      [img_src]="userInfo.image"
    ></app-user-frame>
    <textarea
      class="new__textarea"
      (keyup)="writeComment($event)"
      [value]="commentInput"
    ></textarea>
  </div>

  <div class="decision-btns right">
    <button 
      (click)="onComment()" 
      class="btn forward"  
      [ngClass]="{'disabled:opacity-20 cursor-not-allowed': commentInput == ''}"
      [disabled]="commentInput == ''"
    >
      {{ i18n.disciplines_send }}
    </button>
    <button type="button" class="font-bold text-customBlue-default px-8 py-2" (click)="toggleComments()">
      {{ i18n.question_bank_cancel }}
    </button>
  </div>
</div>