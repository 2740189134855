<!-- MENSAGENS DE EXCEÇÃO -->
<ng-container>
    <div class="flex">
        <div class="mx-auto text-center">
            <i class="attention-icon attention mx-auto"></i>
            <!-- template para quando não há forums criados -->
            <ng-container *ngIf="!isForumsLoading && !forum_post?.items?.replies.length">
                <h3  class=" text-xl font-bold text-customBlue-default">
                    {{i18n.forum_there_is_not_topics_in_this_forums}}
                </h3>
                <p *ngIf="user.role.external_id !== 'student'" class="text-md text-customGray-default">
                    {{i18n.forum_create_new_reply_adding_in_the_under_text_area}}
                </p>
            </ng-container>

            <ng-container *ngIf="isForumsLoading">
                <app-bullets-loader></app-bullets-loader>
            </ng-container>
            <!-- template para quando não há resultados de pesquisa -->
            <!-- <ng-container *ngIf="hasntResults">
                <h3  class=" text-xl font-bold text-customBlue-default">
                    {{i18n.forum_there_is_not_results_for_this_search}}
                </h3>
                <p class="text-md text-customGray-default">
                    {{i18n.forum_do_again_the_search_using_other_words_or_filters}}
                </p>                    
            </ng-container> -->
        </div>
    </div>
</ng-container>
<!-- FIM MENSAGENS DE EXCEÇÃO -->

<!-- AREA DE COMENTARIOS -->
<div *ngIf="forum_post?.items?.replies.length" class="flex items-center gap-4 lg:justify-end justify-between mt-8" [ngStyle]="{'justify-content': 'space-between'}">
    <div 
        class="flex gap-2"
    >
        <i class="comment-outlined-icon comment-icon"></i>
        <div class="italic">
            {{ forum_post?.total_threads }} {{ forum_post?.total_threads < 2 ? i18n.forum_comment : i18n.forum_comments}}
        </div>
    </div>
    <div class="flex items-center gap-8">
        <button (click)="displayPanel.toggle($event);" class="relative rounded-md">
            <span
              class="iconify"
              data-icon="mdi:filter"
              style="color: #233674"
              data-width="30"
              data-height="30"
            ></span>
            <app-display-panel #displayPanel
                (sendOpenStatus)="onToggle($event)"
                [style]="{ right: '-11px', top: '35px' }"
                [hasStyle]="true" [arrow_deslocation]="'100%'" [custom_radius]="'10px'">
                <div class="text-left" style="width: max-content;">
                    <div>
                        <h2 class="text-customGray-dark">{{i18n.forum_order_by}}</h2>
                    </div>
                    <div>
                        <label (click)="checkOrder($event)" class="container flex items-center gap-2">
                            <input type="checkbox" [checked]="orderChecked" class="hidden">
                            <span class="check cursor-pointer"></span>
                            <span class="text-customGray-dark text-16">
                                {{i18n.forum_descending}}
                            </span>
                        </label>
                    </div>
                </div>
            </app-display-panel>
        </button>
    </div>
</div>



<div *ngFor="let thread of forum_post?.items?.replies; let i = index">
    <app-forum-comment-reply 
        [reply_id]="thread?.external_id" 
        [forum_post]="thread"
        [renderFromTemplate]="true"
        [isComponentForum]="isComponentForum"
        [forumExternalId]="forum_post?.items?.forum_external_id"
        [hiddenSocialEvents]="hiddenSocialEvents"
        (total_threads)="forum_post.total_threads = forum_post.total_threads + 1"
    ></app-forum-comment-reply>
</div>

<!-- Pagination -->
<div class="mt-8 flex justify-end" *ngIf="forum_post?.items?.replies.length">
    <app-pagination
    #pagination
    [params]="paginationParams"
    (navigation)="goToPage($event)"
    ></app-pagination>
</div>

<!-- FIM AREA DE COMENTARIOS -->

<!-- AREA DE NOVO COMENTÁRIO -->
<ng-container *ngIf="isCommentAreaVisible === '' && showCommentArea && forum_post?.items?.logged_user_permissions?.can_reply">
    <app-forum-area-text
            [thread]="forum_post?.items"
            [isEditMode]="isEditMode"
            [i18n]="i18n"
            [user]="user"
            [isReplyOpen]="isReplyOpen"
            [hasCancelButton]="false"
            [hasDashedBorder]="false"
            [hasReplyIt]="false"
            (sendIsEditMode)="receiveIsEditMode($event)"
            (sendIsReplyOpen)="receiveIsReplyOpen($event)"
            (sendIsCommentAreaVisible)="receiveIsCommentAreaVisible($event)"
            (sendSaveComment)="saveComment($event)"
        ></app-forum-area-text>
</ng-container>
<!-- FIM AREA DE NOVO COMENTÁRIO --> 

<app-modal-edit-history 
    [isModalOpen]="isModalEditHistoryOpen" 
    [edit_history]="edit_history"
    (sendIsModalOpen)="closeModalHistory($event)">
</app-modal-edit-history>