import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-forum-detail-skeleton',
    templateUrl: './forum-detail-skeleton.component.html',
    styleUrls: ['./forum-detail-skeleton.component.scss']
})
export class ForumDetailSkeletonComponent implements OnInit {
    lines: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    constructor(private sharedService: SharedService) { }

    ngOnInit(): void {
        this.lines.forEach((element, index) => {
            element[`width_description${index}`] = this.sharedService.generateRandomNumber();
        });
    }
}
