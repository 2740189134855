<app-form-modal
    [title]="i18n.question_bank_create_new_question"
    [description]="i18n.question_bank_fill_in_the_fields_to_create_new_question"
    [isVisible]="isVisible"
>
    <form [formGroup]="form">
        <div class="container" *ngIf="!down">
            <div class="title">
                {{ i18n.question_bank_title }}
                <app-editor
                    [height]="300"
                    [form]="form"
                    [formControlName]="'title'"
                ></app-editor>
            </div>

            <div class="type-and-tag">
                <div class="type">
                    {{ i18n.question_bank_type }}

                    <select
                        class="bg-customWhite-default border border-customGray-light"
                        style="
                            border-radius: 5px;
                            padding: 5px;
                            padding-bottom: 7px;
                        "
                        [value]="activeOption"
                        (change)="onOptionChange($event)"
                    >
                        <option *ngFor="let type of types" [value]="type.id">
                            {{ type.translation }}
                        </option>
                    </select>
                </div>

                <div class="tag">
                    {{ i18n.question_bank_tags }}

                    <div
                        class="search-tags"
                        [class.is-active]="tagsSugestionExpanded"
                    >
                        <input
                            formControlName="searchTagsInput"
                            class="search-tags__input"
                            [placeholder]="i18n.question_bank_add_a_tag"
                            spellcheck="false"
                            autocomplete="false"
                            (input)="generateTagsSugestions()"
                            (click)="generateTagsSugestions()"
                            [class.is-active]="tagsSugestionExpanded"
                        />

                        <i class="tag-icon icon"></i>

                        <div
                            #tagSugestion
                            class="search-tags__sugestions"
                            [class.is-invisible]="!tagsSugestionExpanded"
                        >
                            <div
                                class="sugestions__sugestion"
                                *ngFor="
                                    let tag of sugestionTags;
                                    let index = index
                                "
                                (click)="selectTag(tag, index)"
                            >
                                <i class="tag-icon icon"></i>

                                {{ tag.text }}
                            </div>
                        </div>

                        <div
                            class="search-tags__sugestions"
                            *ngIf="
                                form.controls.searchTagsInput.value.length &&
                                !sugestionTags.length &&
                                !tagAlreadySelected
                            "
                        >
                            <div
                                class="sugestions__sugestion create-new"
                                (click)="
                                    selectTag({
                                        text: form.controls.searchTagsInput
                                            .value
                                    })
                                "
                            >
                                {{ i18n.question_bank_add_tag }} "{{
                                    form.controls.searchTagsInput.value
                                }}"
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="selected-tags-wrapper" *ngIf="selectedTags.length">
                <div class="selected-tags">
                    <div
                        class="selected-tags__tag"
                        *ngFor="let tag of selectedTags; let index = index"
                    >
                        {{ tag.text }}

                        <i class="x-icon icon" (click)="unselectTag(index)"></i>
                    </div>
                </div>
            </div>

            <div class="description">
                {{ i18n.question_bank_description }}
                <app-editor
                    [height]="300"
                    [form]="form"
                    [formControlName]="'description'"
                ></app-editor>
            </div>

            <div
                class="alternative-and-options relative"
                [ngClass]="{
                    'd-hidden':
                        activeQuestionType === 'TEXT' ||
                        activeQuestionType === 'PARAGRAPH_TEXT'
                }"
            >
                {{ i18n.question_bank_alternative_and_options }}

        <!-- The tag below should be a div, not a ng-container, 'cause JS expects it to be a block -->
        <div #alternatives [ngSwitch]="activeQuestionType">
          <app-create-paragraph-text
            *ngSwitchCase="'PARAGRAPH_TEXT'"
            (changeParagraphText)="retriveQuestionData($event)"
          >
          </app-create-paragraph-text>
          <app-create-text
            *ngSwitchCase="'TEXT'"
            (changeText)="retriveQuestionData($event)"
          >
          </app-create-text>
          <app-create-scale
            *ngSwitchCase="'SCALE'"
            (change)="retriveQuestionData($event)"
          ></app-create-scale>
          <app-create-list
            *ngSwitchCase="'LIST'"
            (change)="retriveQuestionData($event)"
          ></app-create-list>
          <app-create-multiple-choice
            *ngSwitchCase="'MULTIPLE_CHOICE'"
            (change)="retriveQuestionData($event)"
          ></app-create-multiple-choice>
          <app-create-checkbox
            *ngSwitchCase="'CHECKBOX'"
            (change)="retriveQuestionData($event)"
          ></app-create-checkbox>
          <app-create-grid-multiple-choice
            *ngSwitchCase="'GRID'"
            (change)="retriveQuestionData($event)"
          ></app-create-grid-multiple-choice>
          <app-create-grid-checkbox
            *ngSwitchCase="'CHECKBOX_GRID'"
            (change)="retriveQuestionData($event)"
          ></app-create-grid-checkbox>
        </div>
        <p class="warning_paste_image" *ngIf="questionBank.showPasteImage">
          {{ i18n.question_bank_warning_paste_image }}
        </p>
      </div>

            <!-- The editor by defult has 7 characters at minimum, even if you erase everything, it returns '<p></p>', length of 7 -->
            <app-custom-dropdown
                [title]="i18n.question_bank_feedback"
                [marked]="
                    form.controls.positiveFeedback.value.length > 7 ||
                    form.controls.negativeFeedback.value.length > 7
                "
                *ngIf="hasFeedbackSupport"
            >
                <div class="feedback">
                    <div class="feedback__answer">
                        {{ i18n.question_bank_positive_feedback }}
                        <app-editor
                            [height]="300"
                            [form]="form"
                            [formControlName]="'positiveFeedback'"
                        ></app-editor>
                    </div>

                    <div class="feedback__answer">
                        {{ i18n.question_bank_negative_feedback }}
                        <app-editor
                            [height]="300"
                            [form]="form"
                            [formControlName]="'negativeFeedback'"
                        ></app-editor>
                    </div>
                </div>
            </app-custom-dropdown>

            <div class="decision-btns center">
                <div class="btn backward" (click)="destroy()">
                    {{ i18n.question_bank_go_back }}
                </div>

                <div
                    class="btn forward"
                    [class.disabled]="!questionData.ready || form.invalid"
                    (click)="postQuestion()"
                >
                    <span *ngIf="!loadingEdition">{{
                        i18n.question_bank_create
                    }}</span>
                    <app-loading-balls-effect
                        *ngIf="loadingEdition"
                        [color]="'#fff'"
                    ></app-loading-balls-effect>
                </div>
            </div>
        </div>
    </form>

    <div *ngIf="fadeoutCanvas" 
        class="flex flex-col justify-center items-center"
    >   
        <div>
            <span
              class="iconify w-10 h-10"
              data-icon="icon-park-outline:success"
              style="color: #233674"
            ></span>
        </div>
        <div class="text-customBlue-default text-22">{{ i18n.question_bank_created }}!</div>
    </div>

    <div class="container-spacer" *ngIf="down">&</div>
</app-form-modal>
