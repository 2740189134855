<app-generic-modal [isVisible]=true>
    <div class="header-modal">
        <div class="title-modal">
           {{ title }}
        </div>
    </div>
    <div class="container">
        <div class="body-modal">
            <form class="inputs-wrapper-modal" [formGroup]="roleForm">
                <div class="label-modal"> {{i18n.permissions_name_label}} </div>
                <input class="input-modal" [maxlength]="30" formControlName="roleName"/>
                    <div *ngIf="submitted">
                        <div class="invalid-feedback" *ngIf="hasError('roleName') && hasError('roleName')!['required']">
                            {{i18n.permissions_name_required}}
                        </div>
                        <div class="invalid-feedback" *ngIf="hasError('roleName') && hasError('roleName')!['minlength']">
                            {{i18n.permissions_name_min}} {{ hasError('roleName')!['minlength'].requiredLength }} {{i18n.permissions_characters}}
                        </div>
                        <div class="invalid-feedback" *ngIf="hasError('roleName') && hasError('roleName')!['maxlength']">
                            {{i18n.permissions_name_max}} {{ hasError('roleName')!['maxlength'].requiredLength }} {{i18n.permissions_characters}}
                        </div>
                    </div>
                <div class="label-modal">{{i18n.permissions_type_label}}</div>
                <select class="select-modal" maxlength=42  formControlName="roleType">
                    <ng-container *ngFor="let role of roles">
                        <option [value]="role.name">
                            {{ formatRoleName(role.name) }}
                        </option>
                    </ng-container>
                </select>
                <div *ngIf="submitted">
                    <div class="invalid-feedback" *ngIf="hasError('roleType') && hasError('roleType')!['required']">
                        {{i18n.permissions_type_required}}
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="footer">
        <button class="button-save" (click)="onSubmit()" [disabled]="!isButtonActive" [ngClass]="{'button-disabled': !isButtonActive}">{{i18n.permissions_button_save}}</button>
        <button class="button-cancel" (click)="onCancel()">{{i18n.permissions_button_cancelar}}</button>
    </div>
</app-generic-modal>
