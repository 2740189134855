import {
    Component, ElementRef, HostListener, Input, OnInit
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { WindowRef } from 'src/app/shared/window-ref';

import { AccessibilityService } from './accessibility.service';

@Component({
    selector: 'app-accessibility',
    templateUrl: './accessibility.component.html',
    styleUrls: ['./accessibility.component.scss']
})
export class AccessibilityComponent implements OnInit {
    @Input() isVisible: boolean;
    FadeInUp: boolean;
    headerHeight: number;
    headerWidth: number;
    sidebarWidth: number;

    isUserWayChecked = false;
    isVLibrasChecked = false;
    desktop = false;
    isMobile = false;

    i18n: any = [];

    constructor(
        private hostElement: ElementRef,
        private windowRef: WindowRef,
        private sharedService: SharedService,
        private accessibilityService: AccessibilityService
    ) {}

    ngOnInit(): void {
        this.getTranslations();
        // Base para utilização do UserWay
        this.accessibilityService.createUserWayStyleElement();
        this.getHeaderAndSidebarSize();
        this.accessibilityService.checkUserWayLocalStorage();

        // Base para utilização do VLibras
        this.accessibilityService.checkVLibrasLocalSotrage();

        // Atualiza o input de controle de acordo com o storage (Ativo/Inativo)
        this.isUserWayChecked = this.accessibilityService.isUserWayChecked;
        this.isVLibrasChecked = this.accessibilityService.isVLibrasChecked;
    }

    getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('Header') };
    }

    @HostListener('window:resize', ['$event'])
    getHeaderAndSidebarSize($event?) {
        if ($event) {
            $event.stopPropagation();
        }

        this.headerHeight = this.hostElement.nativeElement.parentElement.clientHeight;
        this.headerWidth = this.hostElement.nativeElement.parentElement.clientWidth;

        if (this.windowRef.nativeWindow.innerWidth > 944) {
            this.sidebarWidth = this.windowRef.nativeWindow.document.querySelector('aside').clientWidth;
            this.desktop = true;
        } else {
            this.desktop = false;
        }
    }

    // Ativa/Inativa UserWay
    changeUserWayInput(): void {
        this.accessibilityService.isUserWayChecked = this.isUserWayChecked;
        this.accessibilityService.checkUserWay();
    }

    // Ativa/Inativa VLibras
    changeVLibrasInput(): void {
        this.accessibilityService.isVLibrasChecked = this.isVLibrasChecked;
        this.accessibilityService.checkVLibras();
    }
}
