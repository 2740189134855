<div
  class="bg-customWhite-default rounded-lg pb-4 shadow-lg"
>
  <div class="text-center">
    <div class="rounded-t-lg imgTopTeacher">
    </div>
    <!-- <img src="../../../../assets/png/teacher_card01.png" class="rounded-t-lg " /> -->
    <div *ngIf="showButton" 
      class="flex justify-end mr-2"
      style="margin-top: -1rem; cursor: pointer"
      (click)="toggleSchedule()"
    >
      <img src="../../../../assets/svg/scheduleIcon.svg"  />
    </div>
    <div class="flex justify-center content-center gap-4 my-3">
      <div class="flex justify-center ">
        <div style="width: 64px; height: 64px;">
          <app-user-frame
          [img_src]="user?.image"
          [user_name]="user?.name"
          [adaptable_size]="true"
          >
          </app-user-frame>
        </div>
      </div>
        <div>
          <h2 id="user-name">{{ user?.name }}</h2>
          <h3 id="user-role-name">{{ user?.role?.name }}</h3>
          <div id="user-email">{{ user?.email }}</div>
        </div> 
    </div>
    <section *ngIf="dashData?.disciplines?.length" class="px-2 py-5 flex items-center justify-between">
      <h1 class="font-bold text-lg text-customGray-dark">{{i18n.dash_last_activity}}</h1>
      <a class="font-card-see-all p-1 cursor-pointer" [routerLink]="['/disciplines']">{{i18n.dash_see_all}}</a>
    </section>
    <section *ngIf="dashData?.disciplines?.length" class="px-1 overflow-x-scroll md:overflow-x-hidden">
      <div class="w-max sm:w-full">
      <ul class="grid grid-cols-4 text-customGray-default">
        <li><p class="p-1">{{i18n.dash_discipline_name}}</p></li>
        <li><p class="p-1">{{i18n.dash_pending}}</p></li>
        <li><p class="p-1">{{i18n.dash_corrected}}</p></li>
        <li><p class="p-1">{{i18n.dash_delivered}}</p></li>
      </ul>
      <ul class="px-2">
        <li *ngFor="let discipline of dashData?.disciplines"
          class="my-4 py-1 px-2 border border-solid border-customGray-lighterGray rounded-lg"
        >
          <div class="grid grid-flow-col grid-cols-4 grid-rows-1 ">
            <div class="flex items-center">
              <img src="../../../../assets/svg/discipline.svg" alt="Disciplina" />
              <button
                class="discipline-card-title -mr-20 cursor-pointer"
                (click)="routeToDisciplines(discipline.discipline_external_id)"
                pTooltip="{{ discipline?.title }}" tooltipPosition="top" pRipple>
                <p class="ml-2">{{ discipline?.title }}</p>
              </button>
            </div>
            <p>{{ discipline?.contents?.pending }}</p>
            <p>{{ discipline?.contents?.corrected }}</p>
            <p>{{ discipline?.contents?.submitted }}</p>
          </div>
        </li>
      </ul>
    </div>
    </section>
  </div>
</div>