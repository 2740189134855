import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisciplineSettingsCertificatesService {

  constructor(
    private http: HttpClient
  ) { }

  postCertificatesPreview(file_id: any, params: any): Observable<any> {
    return this.http.post(`${API_Routes.URL}/certificates/preview/${file_id}`, params)
  }

  
  getCertificatesSettings(): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/settings/certificates/`);
  }

  postCertificatesModels(discipline_id: any, params: any): Observable<any> {
    return this.http.post(`${API_Routes.URL}/disciplines/${discipline_id}/settings/certificates/models/`, params)
  }

  patchCertificatesSettings(discipline_id: any, external_id: any, params: any): Observable<any> {
    return this.http.patch(`${API_Routes.URL}/disciplines/${discipline_id}/settings/models/${external_id}`, params)
  }

  patchCertificatesGlobal(external_id: any, params: any): Observable<any> {
    return this.http.patch(`${API_Routes.URL}/settings/certificates/models/${external_id}`, params)
  }

  deleteCertificatesModels(external_id: any, discipline_id: any): Observable<any> {
    return this.http.delete(`${API_Routes.URL}/disciplines/${discipline_id}/settings/models/${external_id}`,)
  }  

  deleteDefaultModels(external_id: any): Observable<any> {
    return this.http.delete(`${API_Routes.URL}/settings/certificates/models/${external_id}/?force=true`)
  }

  getDisciplineCertificate(external_id: any): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/disciplines/${external_id}/settings/certificates`);
  }

  postDisciplineCertificate(discipline_id: any, params: any): Observable<any> {
    return this.http.post<any>(`${API_Routes.URL}/discipline/${discipline_id}/settings/certificates`, params);
  }

  getElegibleCertificate(discipline_id: any): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/disciplines/${discipline_id}/settings/certificates/elegible`);
  }

  postEmitCertificate(emitted_certificates: any, params: any): Observable<any> {
    return this.http.post<any>(`${API_Routes.URL}/discipline/${emitted_certificates}/settings/certificates`, params);
  }

  patchDisciplineCertificate(discipline_id: any, params: any): Observable<any> {
    return this.http.patch<any>(`${API_Routes.URL}/disciplines/${discipline_id}/settings/certificates`, params);
  }

  
  postEmit(discipline_id: any, params: any): Observable<any> {
    return this.http.post<any>(`${API_Routes.URL}/disciplines/${discipline_id}/settings/certificates/emit`, params);
  }


  getEmitCertificate(emitted_certificates: any): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/discipline/${emitted_certificates}/validate`);
  }


  getSections(ediscipline_id: any): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/disciplines/${ediscipline_id}/sections`);
  }

}

