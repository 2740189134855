import { Level, SectionService } from './../../../../section.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Input,
  OnDestroy,
  AfterViewInit,
  AfterContentInit,
} from '@angular/core';
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { GradesService } from 'src/app/pages/disciplines/components/grades/grades.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ContentSharedService } from '../../../../content-shared.service';
import { SharedService } from 'src/app/shared/shared.service';
import { DisciplineSettingsGradesService } from 'src/app/pages/disciplines/components/settings/components/discipline-settings-grades/discipline-settings-grades.service';
import { formatDate } from '@angular/common';

const DEFAULT_DURATION = 250;

@Component({
  selector: 'app-evaluative-resource-options',
  templateUrl: './evaluative-resource-options.component.html',
  styleUrls: ['./evaluative-resource-options.component.scss'],
  animations: [
        trigger('collapse', [
            state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, })),
            state('false', style({ height: '0px', visibility: 'hidden', opacity: 0, })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`))
        ]),
        trigger('rotatedState', [
            state('true', style({ transform: 'rotate(0)' })),
            state('false', style({ transform: 'rotate(-180deg)' })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ]
})
export class EvaluativeResourceOptionsComponent implements OnInit, OnDestroy {
  collapseOption: boolean = false;
  discipline_ext_id: any;
  periods: any;
  periodParams: any;
  categories: any = [];
  selectCategoriesPlaceholder: any;
  labelWeightName: any = '';
  available_grade: any = null;
  gradesConfigForm: UntypedFormGroup;
  gradesConfig: any;  
  i18n: any = [];

  compositionTypes = [
    {id: 'best_grade', name: this.i18n.grades_forum_calculation_type_best_grade},
    {id: 'average_grade', name: this.i18n.grades_forum_calculation_type_average_grade},
  ];

  @Input() editMode: any;
  @Input() assignment: any;
  @Output() emitRubric = new EventEmitter();

  @Output() evaluationResources = new EventEmitter();
  @Output() weight = new EventEmitter();
  @Output() periodOutput = new EventEmitter();

  @ViewChild('categoryValue') categoryValue: ElementRef;
  @ViewChild('weightValue') weightValue: ElementRef;
  @ViewChild('compositionTypeValue') compositionTypeValue: ElementRef;

  params: any = {};

  period: any = null;
  category: any = null;
  sub: any;
  validationPointsRubrica: boolean = false;
  gradesSettings: any = {};

  constructor(
    private gradesService: GradesService,
    private sectionService: SectionService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private contentSharedService: ContentSharedService,
    private disciplineSettingsGradesService: DisciplineSettingsGradesService,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.createGradesConfigForm();
    this.getDisciplineId();
    this.getPeriods();
    this.selectCategoriesPlaceholder = 'Selecione um período';
    this.getGradesSettings();
  }

  getTranslations() {
    this.i18n = {
      ...this.sharedService.getTranslationsOf('Grades'),
      ...this.sharedService.getTranslationsOf('Disciplines'),
    };
    this.compositionTypes = [
      {id: 'best_grade', name: this.i18n.grades_forum_calculation_type_best_grade},
      {id: 'average_grade', name: this.i18n.grades_forum_calculation_type_average_grade},
    ];
  }

  getGradesSettings() {
    this.disciplineSettingsGradesService
      .getGradesSettings(this.discipline_ext_id)
      .subscribe((gradesSettings) => {
        this.gradesSettings = gradesSettings;
      });
  }

  createGradesConfigForm() {
    this.gradesConfigForm = this.formBuilder.group({
      period_id: ['', []],
      category_id: ['', []],
      weight: ['', []],
      composition_type: ['', []],
    });
    this.gradesConfigForm.controls['category_id'].valueChanges.subscribe(
      (changes: any) => {
        setTimeout(() => {
          this.disableField();
        }, 50);
      }
    );

    this.gradesConfigForm.controls['period_id'].valueChanges.subscribe(
      (changes: any) => {
        setTimeout(() => {
          this.disableField();
        }, 50);
      }
    );

  }

  get category_id_value() {
    return this.gradesConfigForm.controls['category_id'].value;
  }

  returnValuesPoints() {
    let maxLevelObj: Level = {} as Level;
    let sumPoints: number = 0;

    this.sectionService.criterions.forEach((e) => {
      maxLevelObj = e.levels.reduce((prev, current) => {
        return prev.points > current.points ? prev : current;
      });
      sumPoints += parseFloat(maxLevelObj.points as unknown as string);
    });

    return sumPoints;
  }

  setValueForm(grades: any) {
    let category: any = [];
    let periodIndex = this.periods?.findIndex((period: any) => {
      return (category = period?.categories.find((category: any) => {
        return grades?.grade_category_id == category.id;
      }));
    });
    if (periodIndex == -1) {
      return;
    }
    let gradesWeight = parseFloat(grades?.weight);
    this.toggle();

    this.getPeriodSelected(this.periods[periodIndex].id);
    this.checkCalculationType(this.periods[periodIndex].id, category?.id);

    this.available_grade = category?.available_grade + gradesWeight;

    // Validação de pontos usados na Rubrica maior que os pontos disponíveis
    if (this.sectionService.criterions?.length) {
      if (this.returnValuesPoints() > this.available_grade)
        this.validationPointsRubrica = this.validationPointsRubrica = true;
    }

    this.weight.emit(this.available_grade);

    this.gradesConfigForm.controls['period_id'].setValue(
      this.periods[periodIndex].id
    );
    this.gradesConfigForm.controls['category_id'].setValue(
      grades?.grade_category_id
    );
    this.gradesConfigForm.controls['composition_type'].setValue(
      grades?.composition_type
    );
    this.gradesConfigForm.controls['weight'].setValue(gradesWeight);
    this.periodOutput.emit({
      period: this.periods[periodIndex],
      grades: gradesWeight,
    });

    this.params = {
        grade_category_id: grades?.grade_category_id,
        weight: gradesWeight,
        composition_type: grades?.composition_type,
      };
  
      this.evaluationResources.emit(this.params);
  }

  toggle() {
    this.collapseOption = !this.collapseOption;
  }

  disableField() {
    if (
      this.available_grade == undefined ||
      this.available_grade == null ||
      this.available_grade == 0 ||
      isNaN(this.available_grade)
    ) {
      this.gradesConfigForm.controls['weight'].disable();
    } else {
      this.gradesConfigForm.controls['weight'].enable();
    }
  }

  getPeriodSelected(period_id: any) {
    let period = this.periods.find((period: any) => {
      return period.id == period_id;
    });
    if (period.categories.length == 0) {
      this.selectCategoriesPlaceholder = 'Nenhuma categoria encontrada';
    } else {
      this.selectCategoriesPlaceholder = 'Selecione uma categoria';
    }

    this.periodParams = period;
    this.categories = period.categories;
  }

  changePeriod() {
    if (
      this.weightValue?.nativeElement?.value !== undefined &&
      this.categoryValue?.nativeElement?.value !== undefined
    ) {
      this.gradesConfigForm.controls['category_id'].setValue(undefined);
      this.gradesConfigForm.controls['weight'].setValue(undefined);
    }
    this.available_grade = '';
    this.params = {
      grade_category_id: null,
      weight: null,
    };
  }

    checkAvailableGrade(category): void {
        if (!category) return;

        if (this.editMode) {
            let grades = this.gradesConfig;

            if (grades) {
                if (typeof grades.weight === 'string') {
                    grades = parseFloat(grades);
                }

                if (grades.grade_category_id === this.category_id_value) {
                    this.available_grade = category.available_grade + grades.weight;
                } else {
                    this.available_grade = category.available_grade;
                }
            }
        } else {
            this.available_grade = category.available_grade;
        }
    }

  checkCalculationType(period_id: any, category_id: any) {
    let period = this.periods.find((period: any) => {
      return period.id == period_id;
    });
    let category = period?.categories.find((category: any) => {
      return category.id == category_id;
    });
    this.checkAvailableGrade(category);

    if (period?.calculation_type == 'sum') {
      this.labelWeightName = this.i18n?.grades_max_grades_task;
    } else {
      this.labelWeightName = this.i18n?.grades_weight;
    }
    if (this.weightValue?.nativeElement?.value !== undefined) {
      this.checkWeightInputValue();
    }
  }

    // Faz o alinhamento caso a nota digitada seja maior que a disponível no período
    checkWeightInputValue(): void {
        const inputWeightValue = this.gradesConfigForm.controls.weight.value;
        this.gradesConfigForm.controls.weight.setValue(inputWeightValue > this.available_grade ? this.available_grade : inputWeightValue);
    }

  getDisciplineId() {
    this.discipline_ext_id = this.router?.url.split('/')[2];
  }

  filterPeriodAvailable(periods) {
    const currentDateFormated = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    return periods.filter((period) => period.end_date > currentDateFormated);
  }

  getPeriods() {
    this.gradesService
      .getPeriodsList(this.discipline_ext_id)
      .subscribe((periods) => {
        this.periods = periods;

        // Recebe Período e Categoria cadastrada se houver
        this.sub = this.contentSharedService.gradesConfig$.subscribe(
          (grades: any) => {
            if (Object.keys(grades).length !== 0) {
              this.gradesConfig = grades;
              if (this.editMode) {
                  this.setValueForm(grades);
              }
            }
          }
        );
      });
  }

  emitValue() {
    this.params = {
      grade_category_id: parseInt(this.categoryValue.nativeElement.value),
      weight: this.weightValue.nativeElement.value,
      composition_type: this.compositionTypeValue ? this.compositionTypeValue.nativeElement.value : null,
    };

    this.evaluationResources.emit(this.params);
    this.periodOutput.emit({
      period: this.periodParams,
      grades: this.weightValue.nativeElement.value,
      composition_type: this.compositionTypeValue ? this.compositionTypeValue.nativeElement.value : null,
    });
  }

  goCreateRubrica(): void {
    this.emitRubric.emit();

    this.route.params.subscribe((params) => {
      let queryParams = {
        section: params.section,
        section_title: params.section_title,
        type: params?.type,
        calculation_type: this.periodParams.calculation_type,
      };

      if (params?.edit === '1') {
        queryParams['content'] = params.content;
        queryParams['edit'] = params?.edit;
        queryParams['weight'] = this.gradesConfig?.weight || 0 ;
      } else {
        queryParams['weight'] = this.params.weight || 0;
      }

      this.router.navigate(['../create-rubrica', queryParams], {
        relativeTo: this.route,
      });
    });
  }

    // Remove do input letras e númeroa negativos
    async validateWeight() {
        const value = await this.sharedService.debounce(this.gradesConfigForm.controls.weight.value);
        let weight = String(value);

        weight = weight.replace(/[^0-9.]/g, '');
        weight = weight.replace(/^0*(\d+(\.\d+)?)/, '$1');

        const grade = parseFloat(weight);
        const max = Math.max(grade, 0);

        this.gradesConfigForm.controls.weight.setValue(max);

        this.checkWeightInputValue();
        this.emitValue();
    }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}
