import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../grades.service';

@Component({
  selector: 'app-grade-period-detail',
  templateUrl: './grade-period-detail.component.html',
  styleUrls: ['./grade-period-detail.component.scss'],
})
export class GradePeriodDetailComponent implements OnInit {
  discipline_ext_id: string = '';
  period_id: string = '';
  period: any;
  grades: any;
  gradesPreSetup: any;
  loading: boolean = false;
  isSaving: boolean = false;
  tableHead: any = [];
  i18n: any = {};
  countCategories = {};
  filterBy: string = '';
  backupStudent: any[] = [];

  constructor(
    private gradesService: GradesService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedSerivce: SharedService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.getGradeId();
    this.getDisciplineId();
    this.getGradesChanges();
  }

  getTranslations() {
    this.i18n = this.sharedSerivce.getTranslationsOf('Grades');
  }

  getGradesChanges() {
    this.gradesService.grades$.subscribe((e) => {
      this.filterBy = '';
      this.getGrades();
    });
  }

  getGradeId(): void {
    this.route?.params?.subscribe((params) => {
      this.period_id = params.id;
    });
  }

  getDisciplineId(): void {
    this.discipline_ext_id = this.router?.url.split('/')[2];
  }

  getGrades() {
    this.loading = true;
    this.gradesService
      .getGradesList(this.period_id)
      .subscribe({
        next: (grades) => {
          this.gradesPreSetup = grades;
          this.getPeriods();
        }
      })
  }

  getPeriods() {
    this.gradesService
      .getPeriodsList(this.discipline_ext_id)
      .subscribe({
        next: (periods) => {
          this.period = periods.find((period: any) => {
            return period.id == this.period_id;
          });

          this.setUpGrades();
          this.gradesPreSetup['decimal_places'] = this.period.decimal_places;
          this.grades = this.gradesPreSetup;

          if(this.grades.hasOwnProperty('students')) {
            if (this.grades.students.length) {
              this.backupStudent = this.grades.students;
              this.grades.students = this.grades.students.sort((a, b) => a.name < b.name ? -1 : 1);
            }
          }
        }
      })
  }

  // seta os nomes das categorias de cada nota
  setUpGrades() {
    this.countCategories = {};
    this.gradesPreSetup?.students.forEach((student: any, index: any) => {
      // Checa no primeiro student o número de categorias
      // nas notas
      if (index == 0) {
        const uniqueCategories: any = [];

        student?.grades.forEach((grade: any) => {
          const isDuplicate = uniqueCategories.includes(
            grade?.grade_category_id
          );
          if (!isDuplicate) {
            this.countCategories[grade?.grade_category_id] = 0;
          }
        });
      }

      student?.grades.forEach((grade: any) => {
        this.period?.categories.forEach((category: any) => {
          // inclui o nome da categoria dentro do
          // objeto de cada nota
          if (category.id == grade.grade_category_id) {
            grade['calculation_type'] = this.period.calculation_type;
            grade['category_name'] = category.name;
            grade['category_weight'] = category.weight;
          }
        });

        // Soma ocorrência por categoria encontrada nas notas
        // do primeiro student
        if (index == 0) {
          this.countCategories[grade.grade_category_id] =
            this.countCategories[grade.grade_category_id] + 1;
        }
      });
    });

    this.loading = false;
  }

    normalizeString(str: string) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    set search_post_filter(value: string) {
        this.grades.students = this.backupStudent;
        this.filterBy = value;

        const normalizedValue = this.normalizeString(value.toLowerCase());

        this.grades.students = this.grades.students.filter((student) => this.normalizeString(student.name.toLowerCase()).indexOf(normalizedValue) > -1);
    }

    get search_post_filter(): string {
        return this.filterBy;
    }
}
