import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';

import { IQuestionData } from '../../models/IQuestionData';
import { QuestionBankService } from '../../question-bank.service';

@Component({
    selector: 'app-new-question',
    templateUrl: './new-question.component.html',
    styleUrls: ['./new-question.component.scss'],
})
export class NewQuestionComponent implements OnInit, OnDestroy {
    @ViewChild('titleInput') $titleInput: ElementRef;
    @ViewChild('alternatives') $alternatives: ElementRef;
    @ViewChild('tagSugestion') tagSugestion: ElementRef;
    @Input() isVisible = false;
    @Input() data: any = {
        allTags: [''],
    };

    @Output() close = new EventEmitter();
    @Output() create = new EventEmitter();

    form: FormGroup;

    down = false;
    hasFeedbackSupport = false;
    activeQuestionType = 'SCALE';
    disciplineExternalId: string;
    tagAlreadySelected = false;
    fadeoutCanvas = false;
    loadingEdition = false;
    creationSuccessfully = false;
    i18n: any;

    // Dropdowns controls
    tagsSugestionExpanded = false;
    tagsSugestionKey: symbol;

    sugestionTags: any[] = [];
    selectedTags: any[] = [];

    questionData: IQuestionData = {
        ready: false,
        payload: {},
    };

    tags: any = [];
    types: any = [];

    activeOption = 'SCALE';

    constructor(
        public questionBank: QuestionBankService,
        private shared: SharedService,
        private element: ElementRef,
        private fb: FormBuilder
    ) {
        this.createForm();
    }

    ngOnInit(): void {
        this.getDisciplineExternalId();
        this.initDOOM();

        this.setTranslations();
    }

    createForm(): void {
        this.form = this.fb.group({
            title: ['', Validators.required],
            description: [''],
            positiveFeedback: [''],
            negativeFeedback: [''],
            searchTagsInput: ['']
        });
    }

    setTranslations() {
        this.i18n = {
            ...this.shared.getTranslationsOf('Disciplines'),
            ...this.shared.getTranslationsOf('QuestionBank'),
        };

        this.types = [
            {
                id: 'SCALE',
                translation: this.i18n.question_bank_scale,
                active: false,
            },
            { id: 'LIST', translation: this.i18n.question_bank_list, active: false },
            {
                id: 'TEXT',
                translation: this.i18n.question_bank_short_answer,
                active: false,
            },
            {
                id: 'PARAGRAPH_TEXT',
                translation: this.i18n.question_bank_paragraph,
                active: false,
            },
            {
                id: 'MULTIPLE_CHOICE',
                translation: this.i18n.question_bank_multiple_choice,
                active: false,
            },
            {
                id: 'CHECKBOX',
                translation: this.i18n.question_bank_checkbox,
                active: false,
            },
            { id: 'GRID', translation: this.i18n.question_bank_grid, active: false },
            {
                id: 'CHECKBOX_GRID',
                translation: this.i18n.question_bank_checkbox_grid,
                active: false,
            },
        ];
    }

    getDisciplineExternalId() {
        this.disciplineExternalId = sessionStorage.getItem('discipline_external_id') || '';
    }

    initDOOM() {
        setTimeout(() => {
            const _this = this;

            const tagSugestionClassName = '.search-tags';
            this.tagsSugestionKey = this.shared.toCloseWhenClickOutside(
                tagSugestionClassName,
                this.tagSugestion,
                { _this, propertyToBeToggled: 'tagsSugestionExpanded' }
            );
        }, 0);
    }

    /*
   * When we "close" the modal,
   * the component intenionally it's not destroyed,
   * so we need to this manually
   * that's what this fn called 'destroy' do
   */
    destroy() {
        this.close.emit();
        this.reload();
    }

    reload() {
        const animationTime = 350;

        setTimeout(() => {
            // The component is not destroyed, it just becomes invisible.
            // So we are going to reset all those properties manually

            this.tags.forEach((type) => (type.active = false));
            this.form.controls.title.setValue('');
            this.form.controls.description.setValue('');
            this.activeQuestionType = 'SCALE';
            this.form.controls.searchTagsInput.setValue('');
            this.sugestionTags = [];
            this.selectedTags = [];
            this.fadeoutCanvas = false;
            this.loadingEdition = false;
            this.creationSuccessfully = false;

            setTimeout(() => (this.down = false), 150);
        }, animationTime);
    }

    onOptionChange(event: any) {
        const type = event.target.value;
        this.activeOption = type;
        this.activeQuestionType = type;

        this.hasFeedbackSupport = !type.match(/SCALE|TEXT|GRID/);
    }

    retriveQuestionData(data) {
        if (!data.hasOwnProperty('isTrusted')) this.questionData = data; // checks if it is the data I need or a browser event
    }

    getTitle($event) {
        $event.stopPropagation();
        this.form.controls.title.setValue($event.target.innerHTML);
    }

    postQuestion() {
        if (!this.questionData.ready || !this.form.controls.title.value) return;

        this.loadingEdition = true;

        const payload = {
            discipline_external_id: this.disciplineExternalId,
            type: this.activeQuestionType,
            tags: this.selectedTags,
            title: this.form.controls.title.value,
            text: this.form.controls.description.value,
            options: [],
            ...this.questionData.payload,
        };

        if (payload.options.length) {
            const fallback = '';
            payload.options[0].correct_feedback = this.form.controls.positiveFeedback.value || fallback;
            payload.options[0].wrong_feedback = this.form.controls.negativeFeedback.value || fallback;
        }

        this.questionBank.createQuestion(payload).subscribe({
            next: () => {
                this.create.emit();
                this.fadeout();
                this.form.controls.positiveFeedback.setValue('');
                this.form.controls.negativeFeedback.setValue('');
            },
        });
    }

    generateTagsSugestions($event?: any, firstLoad?: boolean) {
        if ($event) $event.stopPropagation();

        const sugestions = this.data.allTags.filter((originalTag) => originalTag.text
            .toLocaleLowerCase()
            .includes(this.form.controls.searchTagsInput.value.toLocaleLowerCase()));
        const nonRepetitiveSugestions = sugestions.filter(
            (sug) => !this.selectedTags.some((tag) => tag.text === sug.text)
        );
        this.sugestionTags = nonRepetitiveSugestions;

        if (firstLoad) return;

        this.tagsSugestionExpanded = Boolean(this.sugestionTags.length);
        this.tagAlreadySelected = this.selectedTags.some(
            (tag) => tag.text.toLocaleLowerCase().trim()
        === this.form.controls.searchTagsInput.value.toLocaleLowerCase().trim()
        );
    }

    selectTag(tag: { text: string }, index?: number) {
        const selectedTag = {
            text: tag.text,
        };

        setTimeout(() => {
            if (index !== undefined) this.sugestionTags.splice(index, 1);
            else this.form.controls.searchTagsInput.setValue('');

            this.selectedTags.push(selectedTag);
            this.tagsSugestionExpanded = Boolean(this.sugestionTags.length);
        }, 0);
    }

    unselectTag(index: number) {
        this.selectedTags.splice(index, 1);
    }

    fadeout(successfully = true) {
        this.down = true;
        const $formModalBody = this.element.nativeElement.querySelector('.form-body');
        // $formModalBody.scrollTop = 0;
        $formModalBody.style.overflow = 'hidden';

        setTimeout(() => {
            this.fadeoutCanvas = true;
        }, 0);

        if (successfully) {
            setTimeout(() => {
                this.creationSuccessfully = true;
            }, 400);
        }

        setTimeout(() => {
            this.reload();
        }, 2900);

        setTimeout(() => {
            $formModalBody.scrollTop = 0;
            $formModalBody.style.overflow = 'auto';
        }, 3400);
    }

    ngOnDestroy() {
        if (this.tagsSugestionKey) this.shared.toCloseWhenClickOutside(this.tagsSugestionKey);
    }
}
