import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-table-impoted-sheets',
    templateUrl: './table-impoted-sheets.component.html',
    styleUrls: ['./table-impoted-sheets.component.scss']
})

export class TableImpotedSheetsComponent {
    @Input() data: any;
    @Input() i18n: any;
    @Input() currentLanguage: any;

    isVisibleModalResume = false;
    itemSelected: any;
    feedbacks: any;

    formateData(dataString: string): string {
        const lang = this.currentLanguage === 'en' ? 'en-US' : 'pt-BR';
        return new Date(dataString).toLocaleDateString(lang);
    }

    openModalResume(item: any) {
        this.itemSelected = item;
        this.feedbacks = item.feedbacks;
        this.isVisibleModalResume = true;
    }

    getTypeImport(item: any) {
        switch (item) {
            case 'users':
                return this.i18n?.import_spreadsheet_users;
                break;
            case 'disciplines':
                return this.i18n?.import_spreadsheet_label_disciplines;
                break;
            case 'linking':
                return this.i18n?.import_spreadsheet_label_linking;
                break;
            case 'connections':
                return this.i18n?.import_spreadsheet_label_connections;
                break;
            default:
                return 'Sheet';
                break;
        }
    }

    closeModalResume() {
        this.isVisibleModalResume = false;
    }
}
