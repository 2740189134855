import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ReportsService } from '../../../reports.service';
import { minDateValidator } from 'src/app/utils/form-validators/form-validators';
import { MessengerToastService } from 'src/app/components/messenger-toast/messenger-toast.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';

@Component({
  selector: 'app-large-active-students',
  templateUrl: './large-active-students.component.html',
  styleUrls: ['./large-active-students.component.scss'],
})
export class LargeActiveStudentsComponent implements OnInit {
  external_id: any = [];
  reportsData: any = [];
  loading: boolean = false;
  activeStudents: any = [];
  formatedActStudents: any = [];
  activeStudentsForm: UntypedFormGroup;
  groupType: any = [];
  initialDate: Date = new Date();

  i18n: any = [];
  currentLanguage: string | null;

  view: any[];

  // options
  yScaleMax = 1;
  showXAxis = true;
  showYAxis = false;
  animations = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Country';
  showYAxisLabel = false;
  rotateXAxisTicks = true;
  yAxisLabel = 'Population';
  maxXAxisTickLength = 16;
  showDataLabel = true;
  barPadding = 60;

  colorScheme = {
    domain: ['#233674'],
  };

  groupSelect: any = [];

  q: string = '';
  finDate: string = '';
  iniDate: string = '';
  sortBy: string = '';
  sortType: string = '';
  periods: string = '';
  formGroup: string = '';
  isNotStandalone: boolean = false;

  @Output() screen = new EventEmitter();

  constructor(
    public platModalService: PlatformModalsService,
    private reportsService: ReportsService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private toastMessenger: MessengerToastService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.isNotStandalone = this.localStorageService.isNotStandalone();
    this.getUrlParams();
    this.getReportDetail(
      this.external_id,
      'ACTIVE_STUDENTS',
      0,
      1,
      this.q,
      this.iniDate,
      this.finDate,
      this.sortBy,
      this.sortType,
      this.periods,
      this.formGroup
    );
    this.getTranslations();
    this.loadGroupSelect();
    this.createModelsForm();
  }

  loadingTrue() {
    this.platModalService.toggle('loading');
  }

  loadingFalse() {
    this.platModalService.close('loading');
  }

  getTranslations() {
    this.i18n = { ...this.sharedService.getTranslationsOf('Reports'), ...this.sharedService.getTranslationsOf('Modal') };
    this.currentLanguage = localStorage.getItem('currentLanguage');
    console.log('TRADUCAO', this.i18n);
  }

  loadGroupSelect() {
    this.groupSelect = [
      { title: `${this.i18n.reports_days_week}`, value: '0' },
      { title: `${this.i18n.reports_days}`, value: '1' },
      { title: `${this.i18n.reports_weeks}`, value: '2' },
      { title: `${this.i18n.reports_months}`, value: '3' },
    ];
  }

  getReportDetail(
    disipline,
    type,
    format,
    detail,
    q,
    iniDate,
    finDate,
    sortBy,
    sortType,
    periods,
    form_group
  ) {
    this.loading = true;
    this.reportsService
      .getActStdDetail(
        disipline,
        type,
        format,
        detail,
        q,
        iniDate,
        finDate,
        form_group
      )
      .subscribe(
        (data) => {
          this.chosenGroup(this.groupType, data);
          this.loading = false;
          console.log('ACTIVE LARGE', this.activeStudents);
          this.formatDataActiveStudents();
        }, //data
        (err) => console.log('err', err), //err
        () => console.log('ok') //complete
      );
  }

  getFile(report_format, format_file) {
    let disipline = this.external_id
    let type = 'ACTIVE_STUDENTS'
    let format = report_format
    let detail: any
    let q = this.activeStudentsForm.controls.q.value;
    let initial_date = this.iniDate;
    let final_date = this.finDate;
    let sort_by = '';
    let sort_type = '';
    let periods = '';
    let form_group = this.groupType;

    this.reportsService
      .getReportBlobActiveStd(disipline, type, format, 1, q, initial_date, final_date, sort_by, sort_type, periods, form_group)
      .subscribe((blob: Blob): void => {
        this.howToGetTheFile(format_file, blob)
      });
  }

  getUrl(report_format, format_file) {
    this.toastMessenger.messengerToast('load', this.i18n.reports_generating, true)
    let disipline = this.external_id
    let type = 'ACTIVE_STUDENTS'
    let format = report_format
    let detail: any
    let q = this.activeStudentsForm.controls.q.value;
    let initial_date = this.iniDate;
    let final_date = this.finDate;
    let sort_by = '';
    let sort_type = '';
    let periods = '';
    let form_group = this.groupType;

    this.reportsService
      .getReportUrlActiveStd(disipline, type, format, 1, q, initial_date, final_date, sort_by, sort_type, periods, form_group)
      .subscribe((data): void => {
        this.toastMessenger.messengerToast('success', this.i18n.reports_finished, true, this.i18n.reports_view_report, data.url)
      });
  }

  howToGetTheFile(format_file: string, blob: Blob) {
    const file = new Blob([blob], { type: `application/${format_file}` });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');

    switch (format_file) {
      case 'pdf':
       window.open(fileURL, '_blank', 'width=800, height=600');
      break;
      case 'xlsx':
        a.href = fileURL;
        a.download = `active_students.${format_file}`;
        a.click();
      break;
      case 'csv':
        a.href = fileURL;
        a.download = `active_students.${format_file}`;
        a.click();
      break;
      default:
        break;
    }
  }

  getUrlParams() {
    this.route?.parent?.parent?.params.subscribe(
      (params) => (this.external_id = params.id)
    );
  }

  textCut(limit: any, textToCut: any) {
    let text = textToCut;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }

  showMainScreen() {
    this.screen.emit('show');
    console.log('CHAMANDO RESOURCES');
  }

  formatDataActiveStudents() {
    const studentsData = this.activeStudents;
    console.log('ACTIVE STUDENTS FORMAT', this.activeStudents);
    this.formatedActStudents = studentsData.map((data) => {
      let label
      let percentage

      if(data.label != null && data.label != undefined ) {
        label = data.label
      } else{
        label = ''
      }

      if(data.percentage != null && data.percentage != undefined ) {
        percentage = data.percentage
      } else{
        percentage = 0
      }

      return {
        name: label,
        value: percentage,
      };
    });
    console.log('TAMANHO DA ARRAY: ', this.formatedActStudents.length);
    if (this.formatedActStudents.length < 6) {
      this.formatedActStudents.push(
        {
          name: '                           ',
          value: '',
        },
        {
          name: '                      ',
          value: '',
        },
        {
          name: '                    ',
          value: '',
        },
        {
          name: '                  ',
          value: '',
        }
        /* {
              "name": "               ",
              "value": ''
            }, */
      );
      this.barPadding = 90;
    } else if (this.formatedActStudents.length <= 4) {
      this.formatedActStudents.push(
        {
          name: '                           ',
          value: '',
        },
        {
          name: '                         ',
          value: '',
        },
        {
          name: '                      ',
          value: '',
        },
        {
          name: '                    ',
          value: '',
        },
        {
          name: '                 ',
          value: '',
        },
        {
          name: '               ',
          value: '',
        },
        {
          name: '             ',
          value: '',
        }
      );
      this.barPadding = 60;
    } else if (this.formatedActStudents.length >= 7) {
      this.formatedActStudents.push();
      this.barPadding = 60;
    }
    console.log('ACTIVE STUDENTS: ', this.activeStudents);
  }

  formatDataLabel(val: any) {
    if (val == '') {
      return '';
    } else if (val != '') {
      val = val * 100;

      return val.toFixed(2) + ' %';
    }
  }

  yTickFormattingFn = this.yAxisTickFormatting.bind(this);

  yAxisTickFormatting(value) {
    return this.yPercentTickFormatting(value);
  }

  yPercentTickFormatting(val: any) {
    val = val * 100;
    return val.toFixed(2) + ' %';
  }

  xTickFormattingFn = this.xAxisTickFormatting.bind(this);

  xAxisTickFormatting(value) {
    return this.xPercentTickFormatting(value);
  }

  xPercentTickFormatting(label: any) {
    return this.whatIsTheTypeLabel(label, this.groupType);
  }

  whatIsTheTypeLabel(label, type) {
    type = this.groupType;
    switch (type) {
      case '3':
        return this.whatIsTheMonth(label);
      case '2':
        return this.whatIsTheWeeks(label);
      default:
        return this.whatIsTheDayWeek(label);
    }
  }

  whatIsTheDayWeek(day: any) {
    if (this.currentLanguage == 'es') {
      switch (day) {
        case 'lun':
                  return this.i18n.reports_seg
        case 'mar':
                  return this.i18n.reports_ter
        case 'mie':
                  return this.i18n.reports_qua
        case 'jue':
                  return this.i18n.reports_qui
        case 'vie':
                  return this.i18n.reports_sex
        case 'sáb':
                  return this.i18n.reports_sab
        case 'dom':
                  return this.i18n.reports_dom
        default:
        return day
      }
    } else if(this.currentLanguage == 'en') {
      switch (day) {
        case 'mon':
                  return this.i18n.reports_seg
        case 'tue':
                  return this.i18n.reports_ter
        case 'wed':
                  return this.i18n.reports_qua
        case 'thu':
                  return this.i18n.reports_qui
        case 'fri':
                  return this.i18n.reports_sex
        case 'sat':
                  return this.i18n.reports_sab
        case 'sun':
                  return this.i18n.reports_dom
        default:
        return day
      }
    } else {
      switch (day) {
        case 'seg':
                  return this.i18n.reports_seg
        case 'ter':
                  return this.i18n.reports_ter
        case 'qua':
                  return this.i18n.reports_qua
        case 'qui':
                  return this.i18n.reports_qui
        case 'sex':
                  return this.i18n.reports_sex
        case 'sáb':
                  return this.i18n.reports_sab
        case 'dom':
                  return this.i18n.reports_dom
        default:
        return day
      }
    }
  }

  whatIsTheDay(day: any) {
    if (this.currentLanguage == 'es') {
      switch (day) {
        case 'lun':
                  return this.i18n.reports_seg
        case 'mar':
                  return this.i18n.reports_ter
        case 'mie':
                  return this.i18n.reports_qua
        case 'jue':
                  return this.i18n.reports_qui
        case 'vie':
                  return this.i18n.reports_sex
        case 'sáb':
                  return this.i18n.reports_sab
        case 'dom':
                  return this.i18n.reports_dom
        default:
        return day
      }
    } else if(this.currentLanguage == 'en') {
      switch (day) {
        case 'mon':
                  return this.i18n.reports_seg
        case 'tue':
                  return this.i18n.reports_ter
        case 'wed':
                  return this.i18n.reports_qua
        case 'thu':
                  return this.i18n.reports_qui
        case 'fri':
                  return this.i18n.reports_sex
        case 'sat':
                  return this.i18n.reports_sab
        case 'sun':
                  return this.i18n.reports_dom
        default:
        return day
      }
    } else {
      switch (day) {
        case 'seg':
                  return this.i18n.reports_seg
        case 'ter':
                  return this.i18n.reports_ter
        case 'qua':
                  return this.i18n.reports_qua
        case 'qui':
                  return this.i18n.reports_qui
        case 'sex':
                  return this.i18n.reports_sex
        case 'sáb':
                  return this.i18n.reports_sab
        case 'dom':
                  return this.i18n.reports_dom
        default:
        return day

      }
    }
  }

  whatIsTheWeeks(day: any) {
    let firstDay = day.substring(0, 2);
    let firstMonth = day.substring(3, 6);
    let secondDay = day.substring(9, 11);
    let secondMonth = day.substring(12, 15);
    let formatedLabel: string;

    if (this.currentLanguage == 'en') {
      formatedLabel =
        this.whatIsTheMonth(firstMonth).substr(0, 3) +
        '/' +
        firstDay +
        ' - ' +
        this.whatIsTheMonth(secondMonth).substr(0, 3) +
        '/' +
        secondDay;
    } else {
      formatedLabel =
        firstDay +
        '/' +
        this.whatIsTheMonth(firstMonth).substr(0, 3) +
        ' - ' +
        secondDay +
        '/' +
        this.whatIsTheMonth(secondMonth).substr(0, 3);
    }
    console.log('WeekTESTE: ', formatedLabel);
    return formatedLabel;
  }

  whatIsTheMonth(month: any) {

    if(this.currentLanguage == 'es') {
      switch (month) {

        default:
        return month;
      }
    } else if(this.currentLanguage == 'en') {
      switch (month) {

        default:
        return month;
      }
    } else {
      switch (month) {
        case 'jan':
          return this.i18n.reports_jan;
        case 'fev':
          return this.i18n.reports_fav;
        case 'mar':
          return this.i18n.reports_mar;
        case 'abr':
          return this.i18n.reports_abr;
        case 'mai':
          return this.i18n.reports_mai;
        case 'jun':
          return this.i18n.reports_jun;
        case 'jul':
          return this.i18n.reports_jul;
        case 'ago':
          return this.i18n.reports_ago;
        case 'set':
          return this.i18n.reports_sep;
        case 'out':
          return this.i18n.reports_out;
        case 'nov':
          return this.i18n.reports_nov;
        case 'dez':
          return this.i18n.reports_dez;
        default:
        return '';
      }
    }
  }

  showBiggerScreen() {
    this.screen.emit('active-students');
    console.log('CHAMANDO ACTIVE');
  }

  createModelsForm() {
    this.activeStudentsForm = this.formBuilder.group({
      q: ['', [Validators.required]],
      iniDate: ['', [Validators.required]],
      finDate: ['', [Validators.required]],
      group: ['0', [Validators.required]],
      calendarStartDate: ['', [this.minDateValidator.bind(this)]],
      calendarEndDate: ['', [this.minDateValidator.bind(this)]],
      startDate: [new Date()],
    });
    this.activeStudentsForm
      .get('iniDate')
      ?.valueChanges.subscribe((iniDate) => {
        iniDate =
          iniDate == null || iniDate == ''
            ? null
            : formatDate(iniDate, 'yyyy-MM-dd', 'en-US');
        this.iniDate = iniDate;
      });
    this.activeStudentsForm
      .get('finDate')
      ?.valueChanges.subscribe((finDate) => {
        finDate =
          finDate == null || finDate == ''
            ? null
            : formatDate(finDate, 'yyyy-MM-dd', 'en-US');
        this.finDate = finDate;
      });
    this.activeStudentsForm.get('group')?.valueChanges.subscribe((type) => {
      this.groupType = type;
      console.log('tipo do grupo', this.groupType);
    });
  }

  doQuery() {
    let discipline_external_id = this.external_id;
    let report_type = 'ACTIVE_STUDENTS';
    let report_format = 0;
    let detail_level = 1;
    let q = this.activeStudentsForm.controls.q.value;
    let initial_date = this.iniDate;
    let final_date = this.finDate;
    let sort_by = '';
    let sort_type = '';
    let periods = '';
    let form_group = this.groupType;

    this.getReportDetail(
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      initial_date,
      final_date,
      sort_by,
      sort_type,
      periods,
      form_group
    );
  }

  chosenGroup(type, data) {
    switch (type) {
      case '0':
        return (this.activeStudents = data.WEEKDAYS);
      case '1':
        return (this.activeStudents = data.DAYS);
      case '2':
        return (this.activeStudents = data.WEEKS);
      case '3':
        return (this.activeStudents = data.MONTHS);
      default:
        return (this.activeStudents = data.WEEKDAYS);
    }
  }

  minDateValidator(control: FormControl): { [key: string]: any } | null {
    const enteredDate = control;
    
    let dateMin : Date = new Date();
    let returnValue: any = null;
    
    if (this.activeStudentsForm && true) {
      dateMin = this.initialDate;
      returnValue = minDateValidator(dateMin)(enteredDate);
    }
    return returnValue;
  }

  fnPickDateToInput(calendarDateValue?){
    // Coloca a data no input vindo do CalendarPicker
    if (calendarDateValue) {
      let format = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'MM/dd/yyyy HH:mm','en-US') : formatDate(calendarDateValue, 'dd/MM/yyyy HH:mm','pt-BR');
      this.activeStudentsForm.controls['calendarStartDate'].setValue(format);
    }
  }

  get startDate() {
    return this.activeStudentsForm.get('startDate');
  }
}
