<!-- MODAL ADIÇÃO E EDIÇÃO DE CONCEITO -->
<app-generic-modal [isVisible]="isModalOpen" >
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        <!--header-->
        <div class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t">
            <div class="text-center">
                <h3 class="text-2xl font-semibold text-customBlue-default">
                    <ng-container *ngIf="!editMode">{{i18n.grades_add_concept}}</ng-container>
                    <ng-container *ngIf="editMode">{{i18n.grades_edit_concept}}</ng-container>
                    
                </h3>
            </div>
        </div>
        <i class="close-btn-icon" (click)="closeModal()">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.716 6.58203C12.716 6.50039 12.6492 6.43359 12.5676 6.43359L11.343 6.43916L9.49863 8.63789L7.65615 6.44102L6.42969 6.43545C6.34805 6.43545 6.28125 6.50039 6.28125 6.58389C6.28125 6.61914 6.29424 6.65254 6.3165 6.68037L8.73047 9.55635L6.3165 12.4305C6.29408 12.4577 6.28164 12.4917 6.28125 12.527C6.28125 12.6086 6.34805 12.6754 6.42969 12.6754L7.65615 12.6698L9.49863 10.4711L11.3411 12.668L12.5657 12.6735C12.6474 12.6735 12.7142 12.6086 12.7142 12.5251C12.7142 12.4898 12.7012 12.4564 12.6789 12.4286L10.2687 9.55449L12.6826 6.67852C12.7049 6.65254 12.716 6.61729 12.716 6.58203Z" fill="#A9AEB3"/>
            <path d="M9.5 1.20703C4.90957 1.20703 1.1875 4.9291 1.1875 9.51953C1.1875 14.11 4.90957 17.832 9.5 17.832C14.0904 17.832 17.8125 14.11 17.8125 9.51953C17.8125 4.9291 14.0904 1.20703 9.5 1.20703ZM9.5 16.4219C5.68887 16.4219 2.59766 13.3307 2.59766 9.51953C2.59766 5.7084 5.68887 2.61719 9.5 2.61719C13.3111 2.61719 16.4023 5.7084 16.4023 9.51953C16.4023 13.3307 13.3111 16.4219 9.5 16.4219Z" fill="#A9AEB3"/>
            </svg>
        </i>
        <!--body-->
        <form [formGroup]="conceptsForm">
            <div class="flex items-center justify-center p-4 px-12">
                <div class="mt-2 mb-8">
                    <div class="text-left py-2">
                        <div>
                            <label class="my-1">{{i18n.grades_name}}</label>
                            <div class="flex">
                                <input type="text" class="w-full border border-customGray-default rounded-sm p-1 text-md" required
                                formControlName="name"
                                />
                            </div>
                            <div [ngClass]="{'invisible': name?.pristine}">
                                <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !name?.errors?.required}">
                                    {{i18n.grades_name_required}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="text-left py-2">
                        <div>
                            <label class="my-1">{{i18n.grades_grade}}</label>
                            <div class="flex items-center">
                                <div>
                                    <input type="number"
                                    class="w-full border border-customGray-default rounded-sm p-1 text-md"
                                    formControlName="range_start"
                                    />
                                    <div [ngClass]="{'invisible': range_start?.pristine}">
                                        <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !range_start?.errors?.required}">
                                            {{i18n.grades_field_required}}
                                        </p>
                                    </div>
                                </div>
                                <span class="mx-2 mb-5">{{i18n.grades_date_to}}</span> 
                                <div>
                                    <input type="number"
                                    class="w-full border border-customGray-default rounded-sm p-1 text-md"
                                    formControlName="range_end"
                                    />
                                    <div [ngClass]="{'invisible': range_end?.pristine}"  >
                                        <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !range_end?.errors?.required}">
                                            {{i18n.grades_field_required}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-left py-2">
                        <div>
                            <label class="my-1">Status</label>
                            <div class="flex items-center justify-center gap-6">
                                <button (click)="statusConceptSelected('approved')" [ngClass]="{'bg-approved-selected':statusSelected == 'approved'}" class="bg-approved border rounded-sm py-1 px-8 font-bold text-sm border-customGreen-approved">
                                    {{i18n.grades_approved}}
                                </button>
                                <button (click)="statusConceptSelected('reproved')" [ngClass]="{'bg-reproved-selected':statusSelected == 'reproved'}" class="bg-reproved border rounded-sm py-1 px-8 font-bold text-sm border-inicieColors-orange">
                                    {{i18n.grades_reproved}}
                                </button>
                                <button (click)="statusConceptSelected('none')" [ngClass]="{'bg-none-selected':statusSelected == 'none'}" class="bg-none rounded-sm py-1 px-8 font-bold text-sm">
                                    {{i18n.grades_none}}
                                </button>
                            </div>
                            <div [ngClass]="{'invisible': status?.pristine}" >
                                <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !status?.errors?.required}">
                                    {{i18n.grades_stats_required}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex row justify-center pt-4">
                        <button class="flex border rounded-md py-2 px-8 font-bold text-sm border-customGray-textGray" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !conceptsForm.valid}" style="color: #FFFFFF;background-color: #233674" (click)="saveGeneralSettings()" [disabled]="!conceptsForm.valid">
                            <span>{{i18n.grades_save}}</span>
                        </button>
                        <button class="text-customGray-textGray background-transparent font-semibold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="closeModal()">
                            {{i18n.grades_cancel}}
                        </button>                
                    </div>
                </div>
            </div>
        </form>
    </div>    
</app-generic-modal>