<!-- custom-buttons.component.html -->
<div
    class="w-full flex flex-col sm:flex-row items-center sm:items-end justify-center sm:justify-end"
>
    <button
        *ngFor="let button of buttons"
        class="{{
            button.style
        }} py-3 border border-customBlue-default sm:border-0 mt-3 sm:mt-5 mr-2"
        (click)="button?.onClick()"
        [disabled]="button?.disabled"
        [ngClass]="{'disabled-button': button?.disabled}"
    >
        <span class="text-16 font-bold">
            {{ button.label }}
        </span>
    </button>
</div>
