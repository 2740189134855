<div class="box-options">
    <div class="box-options__option" [formGroup]="modelForm">
        <div class="text-options-1">
            {{ discipline.title }}
        </div>
        <label class="container" formArrayName="targetDisciplines">
            <input [id]="discipline.external_id" class="{{discipline.external_id}}" type="checkbox" 
            [value]="discipline.external_id" [checked]="selectedDiscipline.includes(discipline.external_id)"
            (change)="checkDisciplineChange($event, discipline.title)"/>
            <span class="check" ></span>
        </label>
    </div>
</div>
