import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { HomeLayoutComponent } from '../home/home.component';
import { OrgUnitsComponent } from './org-units.component';
import { OrgUnitsTitleResolver } from './org-units-title.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        component: OrgUnitsComponent,
        resolve: {
          title: OrgUnitsTitleResolver
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrgUnitsRoutingModule { }
