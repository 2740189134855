<div class="flex flex-auto items-center mt-2">
    <div class="pb-2 md:pr-2" style="width: 100%;">
        <div class="text-md text-customGray-dark">
          {{ i18n.users_time_card }}
        </div>
        <input
          type="number"
          class="w-full border border-customGray-mediumGray p-1 text-md"          
          required
          placeholder="{{ i18n.users_time_card }}"
          [(ngModel)]="assiduity.time_card" />    
      </div>
      <div class="pb-2 md:pl-2" style="width: 100%;">
        <label class="text-md text-customGray-dark">
          {{ i18n.users_start_date }}
        </label>
        <input      
          type="text"
          [mask]="'00/00/0000'"
          class="w-full border border-customGray-mediumGray p-1 text-md"
          placeholder="{{ i18n.users_start_date }}"
          [(ngModel)]="assiduity.start_date" />
      </div>
      <div class="pb-2 md:pl-2">
        <ng-container *ngIf="!assiduity.notDeletable">
          <button            
            class="ml-3 py-3.5 px-3.5 right-16 rounded-full add shadow-lg text-customWhite-default"
            (click)="actionEvent(lastItem)"
            [ngClass]="{'add': lastItem === true, 'delete': lastItem === false }">
            <i *ngIf="lastItem" class="plus-icon color-white-icon w-3 h-3"></i>
            <i *ngIf="!lastItem"class="x-icon color-white-icon w-3 h-3"></i>
          </button>
        </ng-container>
        <ng-container *ngIf="assiduity.notDeletable">
          <div            
            class="ml-3 py-3.5 px-3.5 right-16 rounded-full add shadow-lg text-customWhite-default disabled"
            [ngClass]="{'add': lastItem === true, 'delete': lastItem === false }">
            <i *ngIf="lastItem" class="plus-icon color-white-icon w-3 h-3"></i>
            <i *ngIf="!lastItem"class="x-icon color-white-icon w-3 h-3"></i>
          </div>
        </ng-container>
      </div>  
</div>
 