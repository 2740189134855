
<section>
    <div class="relative flex justify-between mb-5">
        <div>
            <h1 class="text-customBlue-default font-bold text-22">{{i18n.grades_overview}}</h1>
            <p routerLink="../.." class="text-customGray-default text-14 cursor-pointer">{{i18n.grades_back_to_grades}}</p>
        </div>
        <!-- Retirado pois ainda não foi implementado busca por aluno -->
        <!-- <div class="flex gap-6 items-center bg-customWhite-default rounded-lg">
            <div class="flex items-center pl-4 gap-2">
                <i class="search-icon w-5 h-5 "></i>
                <input class="w-full outline-none" [placeholder]="'Busca por aluno'" type="text" >
            </div>
        </div> -->
    </div>
    <ng-container *ngFor="let period of periods">
        <div class="my-4">
            <app-custom-dropdown [isTextCenter]="true" [title]="period?.name" [stylization]="defaultStylization" >
                <app-grades-table [periodId]="period?.id" [grades]="period?.grades" [countCategories]="period?.countCategories"></app-grades-table>
            </app-custom-dropdown>
        </div>
    </ng-container>
</section>
