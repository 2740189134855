import { AfterViewChecked, AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'average-performance-class',
  template: ` 
  <div class="bar-chart">
    <div class="chart-bar-title">{{title}}</div>
    <div class="chart-bar-wrapper" *ngIf="averagePerformanceData?.length">
      <div #containerRef class="chart-class-container">
        <ngx-charts-bar-vertical
          [view]="[containerRef.offsetWidth, 240]"
          [scheme]="colorScheme"
          [results]="single"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [results]="averagePerformanceData"
          [barPadding]="barPadding"
          [rotateXAxisTicks]="rotateXAxisTicks"
          [animations]="animations"
          [maxXAxisTickLength]="maxXAxisTickLength"
          [yAxisTickFormatting]="yAxisTickFormattingFn"
          style="fill: #A9AEB3;"
          >
          <ng-template #tooltipTemplate let-model="model">
              <pre class="mt-1">{{model.name}}</pre>
              <pre>{{model.value + '%'}}</pre>
            </ng-template>
          </ngx-charts-bar-vertical>
      </div>
    </div>   
    <div class="mt-1" *ngIf="!averagePerformanceData.length"><app-empty-data></app-empty-data></div> 
  </div>
 `,
  styleUrls: ['./adm-styles.scss'],
})
export class AveragePerformanceClassComponent implements OnInit, OnChanges {
  @Input() adminData: any 
  @Input() i18n: any 
  averagePerformanceData: any[] = [];
  title: string
  single: any[];
  multi: any[];

  view: any[];

  // options
  showXAxis = true;
  showYAxis = true;
  animations = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  showYAxisLabel = false;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 10
  barPadding = 10
  //barMaxWidth = 10

  colorScheme = {
    domain: ['#233674'],
  };

  constructor () {}

  ngOnInit(): void {
    this.title = this.i18n.dash_average_performance_class
  }

  ngOnChanges(): void {
    this.formatDataAveragePerformance();
  }

  formatDataAveragePerformance() {
  const performanceClass = this.adminData?.average_performance_class || [];

  if (performanceClass) {
      this.averagePerformanceData = performanceClass
      .map(averagePerformance => {
        return {
          name: averagePerformance.name,
          value: averagePerformance.performance * 100,
        };
      }) 
    }
  }
  
  yAxisTickFormattingFn = this.yAxisTickFormatting.bind(this);

  yAxisTickFormatting(value){ 
    return this.percentTickFormatting(value);
  }
   percentTickFormatting(val: any) {
    return val  + '%';
  }
}
