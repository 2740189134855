<div class="flex flex-col bg-customWhite-default shadow-xl rounded-lg" style="height: 566px; padding-bottom: 5px">
  <!-- <div class="container-resources"></div> -->
      <div class="flex flex-row items-center justify-between border-b border-solid border-customBlue-superLight rounded-t w-full" style="height: 45px;">
          <div class="flex">
              <div class="title">
                {{i18n.reports_resources}}
              </div>
             <button id="tooltip" class="w-5 h-5">
                  <div class="circle-interrogation-icon interrogation__icon" ></div>
                  <span id="tooltipText">{{i18n.reports_interroga_resources}}</span>
              </button>
          </div>
          <div class="toView" (click)="showBiggerScreen()" *ngIf="isShowResources()">
            {{i18n.reports_view}}
          </div>
      </div>

      <div class="scroll-container-y" *ngIf="isShowResources()">
        <div class="scroll-container-x">
            <div class="resources flex flex-row">
                <div class="col-name" >
                    <div class="header-name">
                       <p> {{i18n.reports_name}} </p>
                    </div>
                    <div class="element-col-name"  *ngFor="let user of contentUsersUsers">
                        <div class="text-name" title="{{user.name}}">
                            <p>{{user.name}}</p>
                        </div>
                    </div>
                </div>
              <!--   <div class="flex flex-col w-14">
                    <div class="header-percent">
                        <p>
                          {{i18n.reports_progress_std}}
                        </p>
                    </div>
                    <div class="element-col-percent" *ngFor="let user of contentUsersUsers">
                        <div class="text-percent">{{user.completion | percent}}</div>
                    </div>
                </div> -->
                <div class="flex flex-col w-full">
                    <div class="flex flex-row w-full h-36">
                        <div class="w-14 flex flex-row">
                            <div class="header-percent" *ngFor="let content of contentUsersContents">
                                <p title="{{content.name}}">
                                    {{content.name}}
                                </p>
                            </div>
                            <!-- <div class="header-percent" *ngFor="let content of contentUsersContents" >
                                <p title="{{content.name}}">
                                    {{textCut(21, content.name) }}
                                </p>
                            </div> -->
                        </div>
                    </div>
                    <div class="flex flex-row w-full " *ngFor="let user of contentUsersUsers">
                        <div class="flex flex-row " *ngFor="let resource of user.resources">
                             <div class="deliveries-datas">
                                 <div *ngIf="resource.completed == true"  class="circle-check__icon"></div>
                                 <div *ngIf="resource.completed == false"  class="circle-x__icon"></div>
                             </div>
                         </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Empty Resources  -->
    <app-empty-data *ngIf="!isShowResources()"
    class="flex justify-center items-center h-full"></app-empty-data>
  </div>
