import { API_Routes } from './../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CertificatesValidatorService {
  constructor(private http: HttpClient) { }

  validateCertificate(emitted_certificates_id: string): Observable<{}> {
    return this.http.get<{}>(
      `${API_Routes.URL}/certificates/${emitted_certificates_id}/validate`, {
      headers: {
        'Content-type': 'application/json'
      }
    }
    )
  }
}
