import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-content-skeleton',
  templateUrl: './content-skeleton.component.html',
  styleUrls: ['./content-skeleton.component.scss']
})
export class ContentSkeletonComponent implements OnInit {
  sections: any = [{},{},{},{}]
  constructor( private sharedService: SharedService ) { }

  ngOnInit(): void {
    this.sections.forEach((element, index) => {
      element['width_description' + index] = this.sharedService.generateRandomNumber();
    });
  }

}
