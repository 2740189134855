import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

import { DisciplineDetailService } from '../disciplines-detail/discipline-detail.service';

@Component({
    selector: 'app-discipline-settings',
    templateUrl: './discipline-settings.component.html',
    styleUrls: ['./discipline-settings.component.scss']
})
export class DisciplineSettingsComponent implements OnInit, OnDestroy {
    isNotStandalone = false;
    selected: string;
    routeFallback = 'discipline-settings-data';
    i18n: any = [];
    currentLanguage: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService,
        private localStorageService: LocalStorageService,
        private disciplineDetailService: DisciplineDetailService

    ) {
    }

    ngOnInit(): void {
        this.isNotStandalone = this.localStorageService.isNotStandalone();
        this.bootstrapActiveTab();
        this.sharedService.CallFunctionOnRouteChange(this.bootstrapActiveTab.bind(this));
        this.getDisciplineBasic();
        this.getTranslations();
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal'), ...this.sharedService.getTranslationsOf('Grades') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }
    bootstrapActiveTab() {
        this.selectTab();
        this.changeTab(this.selected);
    }

    selectTab() {
        this.selected = this.route?.firstChild?.snapshot?.routeConfig?.path || this.routeFallback;
    }

    changeTab(tab:string) {
        this.selected = tab;
        this.router.navigate([this.selected], { relativeTo: this.route });
    }

    hasBothFlowFileSharingMethods: boolean;
    getDisciplineBasic() {
        this.disciplineDetailService.disciplineBasic$
            .subscribe({
                next: (disciplineDetail) => {
                    this.hasBothFlowFileSharingMethods = false;
                    if (disciplineDetail.general_upload_method === 'both') {
                        this.hasBothFlowFileSharingMethods = true;
                    }
                }
            });
    }

    ngOnDestroy() {
        this.sharedService.CallFunctionOnRouteChange();
    }
}
