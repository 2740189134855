<!-- MODAL ADIÇÃO E EDIÇÃO DE CONCEITO -->
<app-generic-modal  [isVisible]="isModalOpen">
    <div class="xs:py-2 xs:px-4  px-10 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none modal-width">
        <!--header-->
        <div class="flex flex-col justify-center border-b border-solid border-customBlue-light py-4">
            <div  class="flex items-center justify-center rounded-t">
                <div class="flex justify-center items-center gap-2">
                    <h3 class="text-2xl font-semibold text-customBlue-default">
                        {{period?.name}}
                    </h3>
                    <div class="relative cursor-pointer">
                        <div (click)="displayPanel.toggle()" >
                            <span  class="iconify text-customGray-dark text-2xl"  data-icon="mdi:help-circle-outline"></span>
                        </div>
                        <app-display-panel #displayPanel
                            [newStyle]="{'top': '40px', 'left': '50%', 'transform': 'translateX(-50%)', 'box-shadow': '0px 4px 15px rgba(0, 0, 0, 0.15)','border-radius': '15px'}"
                            [hide_arrow]="true"
                            [panel_style]="{'background': '#FEF8E6','width':'304px'}"
                            [custom_radius]="'10px'">
                            <div *ngIf="period?.calculation_type == 'weighted_average'">
                                <span class="text-customGray-dark font-normal text-16">
                                    <p>{{i18n.grades_category_description}}</p>
                                    <p>{{i18n.grades_category_description_note}}</p>
                                </span>
                            </div>
                            <div *ngIf="period?.calculation_type == 'sum'">
                                <span class="text-customGray-dark font-normal text-16">
                                    <p>{{i18n.grades_categorys_description_sum_type}}</p>
                                </span>
                            </div>
                        </app-display-panel>
                    </div>
                </div>
            </div>
            <div class="flex justify-center items-center gap-2">
                <ng-container *ngIf="period?.calculation_type == 'weighted_average'">
                    <span class="text-sm text-customGray-dark">
                        {{i18n.grades_calculation_type}} {{i18n.grades_weighted_average}}
                    </span>
                </ng-container>

                <ng-container *ngIf="period?.calculation_type == 'sum'">
                    <span class="text-sm text-customGray-dark">
                        {{i18n.grades_calculation_type}} {{i18n.grades_sum}}
                    </span>
                </ng-container>

            </div>
        </div>
        <!--body-->
        <h1 class="text-customBlue-default font-bold my-4">{{i18n.grades_categories}}</h1>
        <!-- HEAD FORM-->
        <div class="flex gap-4 justify-center font-bold mb-2">
            <div class="flex flex-col input-size">
                <div class="font-bold">
                    {{i18n.grades_name}}
                </div>
            </div>
            <div class="flex flex-col input-size">
                <div *ngIf="period?.calculation_type == 'weighted_average'" class="font-bold text-center">
                    {{i18n.grades_weight}}
                </div>

                <div *ngIf="period?.calculation_type == 'sum'" class="font-bold text-center">
                    {{i18n.grades_max_grade}}
                </div>
            </div>
            <div class="flex flex-col justify-center buttons-size invisible" >
                none
            </div>
        </div>
        <!-- FIM HEAD FORM-->
        <!-- BODY FORM-->
        <app-new-category-form [controlButton]="controlButtonCategory" (sendIsModalOpen)="closeModal($event)"></app-new-category-form>
        <!-- FIM BODY FORM-->
    </div>    
</app-generic-modal>