import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { LoginService } from '../../login.service';

@Component({
  selector: 'app-microsoft-login',
  templateUrl: './microsoft-login.component.html',
  styleUrls: ['./microsoft-login.component.scss']
})
export class MicrosoftLoginComponent implements OnInit{

  @Input() isVisible: boolean;

  isLoading: boolean = false;
  i18n: any = [];
  login_url: string = '';
  window: any;
  // isVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private sharedService: SharedService,
    public platModalService: PlatformModalsService
  ) { }



  ngOnInit(): void {

    this.getTranslations();
    this.getAllowProvider();

    this.route?.queryParams?.subscribe({
      next:(params)=>{
        if (Object.keys(params).length !== 0) {
          this.getCode(params);
        }
      }
    });

    // window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  getAllowProvider(){
    this.loginService.update_localstorage.subscribe((authentication)=>{
      let allowedProviders = authentication?.allowedProviders;
      if (allowedProviders.length > 0) {
        let microsoftProvider = allowedProviders.find((item)=>{
          return item.name == "microsoft"
        });
        if (microsoftProvider !== undefined && Object.keys(microsoftProvider).length) {

          this.getMicrosoftClientId();
        }
      }
    })
  }


  getTranslations(){
    this.i18n = {...this.sharedService.getTranslationsOf('Errors'),...this.sharedService.getTranslationsOf('Login')};
  }

  getMicrosoftClientId(){
    this.isLoading = true;
    let allowedProviders = JSON.parse(localStorage.getItem('allowedProviders') || '');
    if (Array.isArray(allowedProviders) || allowedProviders != undefined ||  allowedProviders != '') {
      allowedProviders.forEach(element => {
        if (element.name == 'microsoft' && element.client_id != '' && element.client_id != undefined) {
          this.login_url = this.setUpMicrosoftClient(element.client_id);
        }
      });
    }else{
      throw new Error("Plataform settings is undefined");

    }
    this.isLoading = false;
    this.isVisible = true;
  }

  storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      console.log('event ', event);

      let ms_authCode = event?.newValue;
      if (ms_authCode !== undefined && ms_authCode !== null) {
        this.authMicrosoft(ms_authCode);
      }

    }
  }

  setUpMicrosoftClient(client_id: any){
    let uri = window.location.origin;
    return `https://login.live.com/oauth20_authorize.srf?prompt=consent
    &access_type=offline
    &state=bb4e6f98a143f20e1a5de9e70717df57
    &scope=wl.basic,wl.emails
    &response_type=code
    &approval_prompt=auto
    &redirect_uri=${uri}
    &client_id=${client_id}`;
  }

  loginPopup() {
    if (this.login_url !== '' ) {

      this.window = window.open(
        this.login_url,
        'popUpWindow','height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
      )
    }

  }



  getCode(params: any){
    localStorage.setItem('ms_auth_code', params?.code);
    window.close();
  }

  authMicrosoft(code: any){

    this.isLoading = true;

    const params = {
      "code": code
    }

    this.loginService.loginWithMicrosoft(params)
      .subscribe({
        next: (response)=>{
            this.sharedService.storeUserCredentials(response);
            if (response.terms !== null) {
                this.loginService.authObject$.next(response);
                this.loginService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: false });
            } else {
                this.sharedService.deleteKeyLocalStorage('ms_auth_code');
                this.sharedService.loggedIn.next(true);
                this.loginService.isRedirectRoute();
            }
        },
        error: (err) => {
          console.log('err ',err);
          console.log('this.i18n', this.i18n);

          this.isLoading =false;
          if (err.status == 422) {
            this.platModalService.toggle('message', this.i18n?.[err?.error?.key_error], 'close');
          }else{
            this.platModalService.toggle('message', this.i18n?.error_system_error, 'close');
          }

          this.sharedService.loggedIn.next(false);
        },
        complete: () => {this.isLoading =false;}

      })
  }



}


