<div
  class="bg-customWhite-default justify-center rounded-lg shadow-lg pb-2 xl:min-w-min 2xl:min-w-min"
>
  <div class="p-1 cursor-default">
    <div class="xs:flex-col xs:gap-0">
      <h1
        class="font-card-2-title flex justify-center lg:justify-start xs:justify-start xl:justify-start xl:ml-2 mt-4"
      >
        {{ i18n.dash_wellcome }}
      </h1>
      <div
        class="flex justify-center lg:justify-start xl:justify-start xs:justify-start py-2 xl:ml-2"
      >
        <p class="font-card-2 truncate text-customGray-dark">
          {{ i18n.dash_latest_announcement }}
        </p>
      </div>
    </div>
    <div class="justify-center">
      <div
        class="flex ml-1.5 items-center xl:gap-2 justify-center lg:justify-start 2lx:justify-start lx:justify-start gap-6 md:gap-4 lg:gap-3 xs:flex-col xs:gap-0"
      >
        <div
          class="mt-2 border border-customGray-lighterGray p-0.5 px-2"
          [style.width]="'17.5rem'"
          *ngFor="let announcement of dashData.last_announcements"
        >
          <div>
            <div class="font-card-3-title">
              <div class="font-card-3-title-box my-1">
                <p>{{ announcement?.title }}</p>
              </div>
              <div class="py-2 md-box mb-3">
                  <!-- {{ (announcement?.text.length>20)? (announcement?.text | slice:0:19)+'...':(announcement?.text) }} -->
                <!-- <p class="font-card-2 break-all" [innerHTML]="announcement?.text | safeHTML"></p> -->
               <!--  <p class="font-card-2 break-all" [innerHTML]="announcement?.text | safeHTML"></p> -->
                <p class="font-card-2 line-clamp-2">
                  {{ tagsRemove(announcement?.text) }}
                </p>
              </div>
              <div
                class="flex justify-items-center justify-between items-center"
              >
                <div>
                  <div class="font-card-3-user justify-items-start">
                    <div
                      class="flex flex-wrap gap-2 content-start items-center"
                    >
                      <div style="width: 16.96px; height: 18px">
                        <app-user-frame
                          [user_name]="announcement?.user?.name"
                          [img_src]="announcement?.user?.image"
                          [adaptable_size]="true"
                        ></app-user-frame>
                      </div>
                      <div class="font-card-3-user-box">
                        <p>{{ cutName(announcement?.user?.name) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="font-card-3-published_at">
                  {{ announcement?.published_at | dateAgo }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
