<div *ngIf="!expanded" class="sm:col-span-1">
    <div
      class="relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-lg "
    >
      <!--header-->
      <div
        class="flex justify-center p-5 border-b border-solid border-customBlue-light rounded-t-lg w-full "
        style="background-color: #3656bf;"
      >
        <span 
            class="rounded ml-2 animate-pulse"
            style="background-color: #DEDFE3; height: 20px; width: 50%;"
        ></span>
      </div>
      <!--body-->
      <div
        class="p-6 bg-customWhite-default"
        style="
          border: 1px solid var(--color-light-blue);
          border-top: none;

          border-radius: 0 0 8px 8px;
        "
      >
        <div
          *ngFor="let content of contents; let i = index"
          class="justify-center rounded"
        >
          <button
            class="w-full p-2 animate-pulse"
          >
            <div class="flex items-center gap-4 p-element p-2">
              <div class="flex items-center col-span-1">
                  <div 
                    class="rounded-full" 
                    style="background-color: #DEDFE3; width: 25px; height: 25px;">
                  </div>
              </div>
              <div 
                    class="ml-2 rounded " 
                    style="background-color: #DEDFE3; height: 15px;"
                    [style.width]="content['width_description'+i] + '%'"
                >
                </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>