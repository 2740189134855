import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReadFile } from 'ngx-file-helpers';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private http: HttpClient
  ) { }

  uploadFile(params:any){
    const headers = {
      'Content-Type': 'application/json'
    }
    return this.http.post(`${API_Routes.URL}/files`, params, {headers: headers})
  }

	postFile(file: any): Observable<any> {
    // const headers = {
    //   'Content-Type': 'multipart/form-data',
    //   'Authorization': "Bearer " + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hbHBoYS5sb2NhbGhvc3Q6ODA4MFwvYXBpXC9hdXRoIiwiaWF0IjoxNjcxMDQ0OTA2LCJleHAiOjE2NzE2NDk3MDYsIm5iZiI6MTY3MTA0NDkwNiwianRpIjoiMzVkVWdDT2NETmJ3VFU5ZCIsInN1YiI6MiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyIsInJvbGUiOnsibmFtZSI6ImFkbWluaXN0cmF0b3IiLCJleHRlcm5hbF9pZCI6ImFkbWluaXN0cmF0b3IifSwiYWN0aXZlX3JvbGUiOiJhZG1pbmlzdHJhdG9yIiwicm9sZV90eXBlIjoiYWRtaW4iLCJuYW1lIjoiYWRtaW5pc3RyYXRvciBUZXN0IiwiZW1haWwiOiJtY21AaW5pY2llLmRpZ2l0YWwiLCJpbWFnZSI6bnVsbCwiY2FuX2ltcGVyc29uYXRlIjoxLCJpbXBlcnNvbmF0ZWRfYnkiOm51bGwsImN1cnJlbnRSb2xlIjpudWxsfQ.o2HRwzuhtbcdWVg-a0Ns2bFkvj_kaVgBaJ8kAnBwcEw'
    // }
		return this.http.post(`${API_Routes.URL}/files`, file);
	}	   
}
