import {Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SharedService } from './shared/shared.service';
import { LoginService } from './pages/login/login.service';
import { LocalStorageService } from './services/localStorageService/local-storage.service';
import { PlatformModalsService } from './services/modals/platform-modals.service';
import { PrimeNGConfig } from 'primeng/api';
import { PushNotificationService } from './services/pushNotificationService/push-notification.service';
import { TitlePageService } from './services/titlePageService/title-page.service';
declare global {
  interface Window {
    gtag: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(SidebarComponent) sidebar: SidebarComponent;
  @ViewChild(HeaderComponent) header: HeaderComponent;
  
  title = 'Inicie Plataforma';
  userHasToken: boolean | null = null;
  userIsImpersonating: any;
  isTranslationsUploaded: boolean = false;
  isTranslationsUploaded$ = this.loginService.isTranslationsUploaded$;
  addStyle: string = '--screenHeight: 100vh';
  isIOS: boolean = false;
    canAccessibility = false;

  constructor(
    private sharedService: SharedService,
    private loginService: LoginService,
    private titleService: TitlePageService,
    private localStorageService: LocalStorageService,
    public platModalService: PlatformModalsService,
    private primeNGConfig: PrimeNGConfig,
    private pushNotificationService: PushNotificationService,
  ) { }

  ngOnInit(): void {
    this.localStorageService.checkCookiesEnabled();
    this.localStorageService.checkVersion();
    this.getTokenStatus();
    this.setHeadScripts();
    this.getImpersonatingStatus();
    this.setTitle();
    this.checkTranslationsAreLoaded();
    setTimeout(this.getScreenSize.bind(this), 500)
    this.setTranslationToPrimeNgCalendar();
    this.setFavicon();
    // Function reload when is IOS  
    this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    this.getCurrentLanguage();
  }

  testError(){
    throw new Error('Test: Something broke!');
  }

  setTranslationToPrimeNgCalendar(){
    this.localStorageService.getCalendarTranslation().subscribe({
      next:(i18n)=>{
        this.primeNGConfig.setTranslation(i18n.primeng);
      }
    })
  }

  setFavicon(){
    // Verifica se requisião do whoami já finalizou
    if (!this.isTranslationsUploaded) {
      return;
    }
    // Valida chave no localStorage e coloca o favicon
    if (!this.localStorageService.getLocalStorageItem('site_metadata')) {
      throw new Error("Error site_metadata does not exist or is empty in localStorage ");
    }
      const settingsGeneral  = JSON.parse(localStorage.getItem('site_metadata') || '').settingsGeneral;      
      
      let favicon = '';
      if (settingsGeneral.favicon == null) {
        favicon = 'assets/favicon.png';
      }else{
        favicon = settingsGeneral.favicon.public_url;
      }
      
      document?.getElementsByTagName('head')[0]?.querySelector('link[rel="icon"]')?.setAttribute("href", favicon);
  }

  @HostListener('window:resize',['$event'])
  getScreenSize(){
    if(!this.isIOS){
      let getHeight = window.innerHeight + 5;
      this.addStyle = `--screenHeight:${getHeight}px`;
    } 
  }

  checkTranslationsAreLoaded() {
    if (localStorage.getItem('translations') === null) {
      this.loginService.whoami().subscribe({
        next: (response) => {
          localStorage.setItem('gcsInfraPath', (response.storage.gcsInfraPath));

          if (response?.customization) {
            const settingsGeneral = response?.customization;
            localStorage.setItem('site_metadata', JSON.stringify({ settingsGeneral }));
          }

          this.localStorageService.updateTranslationsLocalStorage((file) => {
           if (file) location.reload();
          });
        },
        error: () => {
          throw new Error('Error: was not possible to find gcsInfraPath in localstorage');
        }
      })
    }

    if (localStorage.getItem('translations') !== null) {
      this.isTranslationsUploaded = true;
    }
  }

  getTokenStatus(){
    this.sharedService.loggedIn.subscribe({
      next:(logged:boolean) => {
        if (this.userHasToken !== logged) {
          let url = window.location.href;
          if (!url.includes('localhost')) {
            this.setGtagHead(logged);
          }
          this.userHasToken = logged;
        }
      }
    });
  }
  
  setHeadScripts(){
    let url = window.location.href;
    if (!url.includes('localhost')) {
      
      let clarity = document.createElement('script');
      clarity.type = "text/javascript";
      document?.getElementsByTagName('head')[0].appendChild(clarity).append('(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "dq64wjjrjo");');

      let mouseflow = document.createElement('script');
      mouseflow.type = "text/javascript";
      document?.getElementsByTagName('head')[0].appendChild(mouseflow).append('window._mfq = window._mfq || []; (function() { var mf = document.createElement("script"); mf.type = "text/javascript"; mf.defer = true; mf.src = "//cdn.mouseflow.com/projects/00f50ecc-08b6-4cb0-b889-88f504dd9d12.js"; document.getElementsByTagName("head")[0].appendChild(mf); })();');

      let hotjar = document.createElement('script');
      document?.getElementsByTagName('head')[0].appendChild(hotjar).append(
        `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2476790,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
      )
      
    }
  }

  insertGtag(gtag_id: string, user: any){

    // remove script google tag src if exists
    this.removeScript(gtag_id+'tagmanager');

    let gtmId = document.createElement('script');
    gtmId.async = true;
    gtmId.id = gtag_id+'tagmanager';
    gtmId.src = `https://www.googletagmanager.com/gtag/js?id=${gtag_id}`;
    document?.getElementsByTagName('head')[0].appendChild(gtmId);

    let setUser = ''
    if (user.email !== '') {
      setUser = `gtag('set', 'user_properties',{ email: '${user.email}', domain: '${user.domain}' });`
    }else{
      setUser = `gtag('set', 'user_properties',{ domain: '${user.domain}' });`
    }

    // remove google tag script functions if exists
    this.removeScript(gtag_id);

    let gtmscr = document.createElement('script');
    gtmscr.id = gtag_id;
    document?.getElementsByTagName('head')[0].appendChild(gtmscr).append(`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${gtag_id}');${setUser}`);  
  }

  removeScript(elementId){
    var scriptElement = document.getElementById(elementId);

    if (scriptElement) {
      let headElement = scriptElement.parentNode;
      if (headElement !== null) {
        headElement.removeChild(scriptElement);
      }
    }
  }

  updateGtagWithUser(gtag_id){
    let scriptElement = document.getElementById(gtag_id);
    scriptElement?.parentNode?.replaceChild
  }

  setGtagHead(logged){
    let advanced: any = '';
    let userObj = {
      email: '',
      domain: window.origin
    }

    if (logged) {
      let user = this.sharedService.getUserSync();
      if (user) {
        
        userObj['email'] = user.email;
      }
    }
    if (this.localStorageService.getLocalStorageItem('advanced')) {
      advanced = JSON.parse(localStorage.getItem('advanced') || '');
      if(advanced.google_analytics_id){
        // GTAG cliente
        this.insertGtag(advanced.google_analytics_id, userObj);
      }
    }
    // GTAG Inicie
    // this.insertGtag('G-H9CLF4W3S7', userObj);
    this.insertGtag('G-DC7JXNJ046', userObj);
  }

  getImpersonatingStatus(){
    this.sharedService.impersonating.subscribe((impersonate:boolean) => {

    this.userIsImpersonating = impersonate;
    });
  }

  toggleMenuOnAside() {
    this.sidebar.toggleAside();
  }

  toggleExpandBtnOnHeader() {
    this.header.toggleExpandBtn();
  }

  setTitle(): void {
    // set undefined title to get default title from settings
    this.titleService.setTitleSite(undefined)
  }

    getCurrentLanguage(): void {
        const currentLanguage = localStorage.getItem('currentLanguage');
        this.canAccessibility = currentLanguage === 'pt-BR';
    }
}
