<app-generic-modal [isVisible]="isVisibleModalMaterialList">
    <div class="container-modal w-full sm:w-96">
      <header
        class="text-center text-customBlue-default border-b border-customBlue-glass p-4">
        <p class="font-bold text-26">{{ i18n.planner_material_list }}</p>
      </header>

      <ul class="flex flex-col gap-3 text-customGray-dark p-4">
            <li *ngFor="let material of materialList" class="border border-customGray-mediumGray rounded py-2 px-4">
                <p>{{ material.value }}</p>
            </li>
            <!-- Empty procedure  -->
            <li *ngIf="!materialList.length">
                <p class="text-customGray-darker">{{ i18n.planner_there_no }}</p>
            </li>
        </ul>

        <footer class="p-4 flex justify-center">
            <button type="button" (click)="closeModal()" class="w-full sm:w-auto text-customWhite-default bg-customBlue-default py-1.5 px-10 rounded">
                {{ i18n.planner_close }}
            </button>
        </footer>
    </div>
</app-generic-modal>