import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
//import jwt_decode from 'jwt-decode';
import { DashboardService } from '../dashboard.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.scss'],
})
export class TeacherComponent implements OnInit, OnChanges {
  name: any = [];

  feedName: any = [];
  dash: any =[]
  showAnnouncements: boolean = false;
  globalSchedule: any = [];
  objectKeys = Object?.keys;

  //showResume: boolean = false;


  @Input() user: any = [];
  @Input() dashData: any = [];
  @Input() i18n: any = [];
  isLoading$ = this.dashboardService.isLoading$;

  showModal = false;

  showLoading: boolean = true

  constructor(
    private dashboardService: DashboardService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getLoading();
    console.log('TRANSLATES TEACHER: ', this.i18n);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.info('Object.keys(changes).length ', changes?.length);
    console.info('On Changes emit', changes);
    this.showElements();
    if (this.dashData?.schedule?.length > 0) {
      console.log('CHAMA createArrayOfSameTimes');
     this.createArrayOfSameTimes();
    }
  }

  getLoading() {
    console.log('MOSTRA TEACHER: ', this.showLoading);
    this.isLoading$.subscribe(
      (data) => this.showLoading = data
    )
    /* this.createArrayOfSameTimes();
    console.log('SCHEDULE',  this.globalSchedule); */
  }

  showElements() {
    console.log('SHOW last_announcements', this.dashData.last_announcements);
    console.log('SHOW last_announcements FORA', this.showAnnouncements);

    if ( this.dashData.last_announcements?.length > 0){
      this.showAnnouncements = true
      console.log('SHOW last_announcements dentro', this.showAnnouncements);
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  createArrayOfSameTimes() {

    this.dashData?.schedule?.forEach((element) => {
      const newArray: any = [];
      const sameTimesArray: any = [];

      element.events.forEach((event) => {
        sameTimesArray[event.date] = element.events.filter((check) => {
          return check.date == event.date;
        });
      });

      for (const element in sameTimesArray) {
        newArray.push({
          date: element,
          values: sameTimesArray[element],
        });
      }
      element.events = newArray;
    });

    this.globalSchedule = this.dashData?.schedule;
    console.log('Teacher Schedule', this.globalSchedule);

  }


}
