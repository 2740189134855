<div class="box-options">
  <div class="box-options__option">
      <div class="text-options-1">
          {{ discipline.title }}
      </div>
      <label class="container">
          <input [id]="discipline.external_id" class="{{discipline.external_id}}" type="checkbox"
                 [value]="discipline.external_id" [checked]="isSelected()"
                 (change)="checkDisciplineChange()"/>
          <span class="check"></span>
      </label>
  </div>
</div>
