<div class="impersonate-wrapper" *ngIf="userIsImpersonating">
  <div
    *ngIf="!isCardDepersonateOpen"
    class="flex justify-center items-center button-impersonate"
    (click)="openCardDepersonate()"
  >
    <span class="text-customBlue-default font-bold">
      {{ i18n.impersonafication_end_impersonate }}
    </span>
  </div>
  <div *ngIf="isCardDepersonateOpen" class="impersonate animation">
    <i
      class="circle-x-outline-icon circle-x-outline"
      (click)="isCardDepersonateOpen = !isCardDepersonateOpen"
    ></i>
    <div
      class="flex lg:flex-row flex-col justify-center items-center text-center gap-2 my-3 text-customWhite-default"
    >
      <i class="attention-icon attention"></i>
      <span
        >{{ i18n.impersonafication_you_are_impersonating_the_user }}
        <b>{{ impersonate.name }} ({{ impersonate?.role.name }})</b></span
      >
    </div>
    <div class="flex justify-center my-3">
      <button
        class="border border-customBlue-default bg-transparent text-customBlue-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        (click)="depersonificate()"
      >
        <span>{{ i18n.impersonafication_end_impersonate }}</span>
      </button>
    </div>
  </div>
</div>

<app-message-modal></app-message-modal>
