import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ForumSharedService {
    getForumThreadById(id: number, reply: any): any {
        if (reply.id === id) {
            return reply; // return the current reply if it has the desired ID
        }

        // Check nested replies
        if (reply.replies && reply.replies.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const nestedReply of reply.replies) {
                const foundReply = this.getForumThreadById(id, nestedReply);
                if (foundReply) {
                    return foundReply; // return the found reply
                }
            }
        }

        // Return null if no reply is found
        return null;
    }

    insertNewPropertyInObject(reply: any, propertyName: string, propertyValue: any): void {
        // eslint-disable-next-line no-restricted-syntax
        this.addProperty(reply, propertyName, propertyValue);

        if (reply.items && reply.items.replies) {
            reply.items.replies.forEach((nestedReply: any) => {
                this.insertNewPropertyInObject(nestedReply, propertyName, propertyValue);
            });
        }

        if (reply.replies) {
            reply.replies.forEach((nestedReply: any) => {
                this.insertNewPropertyInObject(nestedReply, propertyName, propertyValue);
            });
        }
    }

    // Adiciona no obj a propriedade isThreadOpen
    addProperty(obj: any, propertyName: string, propertyValue: any): void {
        const reply = obj;

        if (reply.items) {
            reply.items[propertyName] = propertyValue;
        } else {
            reply[propertyName] = propertyValue;
        }
    }
}
