<section>
    <div class="relative flex justify-between mb-5">
        <div>
            <h1 class="text-customBlue-default font-bold text-22">{{student?.period?.name}}</h1>
            <p routerLink="../.." class="text-customGray-default text-14 cursor-pointer">{{i18n.grades_back_to_grades}}</p>
        </div>
        <!-- Retirado pois ainda não foi implementado busca  -->
        <!-- <div class="flex gap-6 items-center bg-customWhite-default rounded-lg">
            <div class="flex items-center pl-4 gap-2">
                <i class="search-icon w-5 h-5 "></i>
                <input class="w-full outline-none" [placeholder]="'Busca'" type="text" >
            </div>
        </div> -->
    </div>
    <div class="md:flex flex-row justify-between bg-customWhite-default rounded-md">
        <div class="flex items-center justify-center p-2 gap-2">
            <div>
                <app-user-frame
                [img_src]="student?.image"
                [user_name]="student?.name">
                </app-user-frame>
            </div>
            <div class="text-center">
                <span class="text-22 text-customGray-dark font-medium">
                    {{student?.name}}
                </span>
            </div>
        </div>
        <!-- Retirado pois ainda não foi implementado gerar relatório -->
        <!-- <div class="flex items-center justify-center p-2">
            <button class="border border-customBlue-royal text-customBlue-default font-medium rounded-sm p-2 px-8">
                Gerar relatório
            </button>
        </div> -->
    </div>    
    <app-grades-student-table [student]="student"></app-grades-student-table>
    <!-- <app-grades-table [grades]="grades"></app-grades-table> -->
</section>