<div class="wrapper">
  <ng-container *ngIf="!markCorrectAlternatives">
    <div class="rows-and-columns">
      <div class="rows">
        {{ i18n.question_bank_rows }}

        <div *ngFor="let row of rows; let index = index" style="pointer-events: auto;">
          <div class="inside-dropdown">
            <div class="label">
              <div class="label__number">{{ +index + 1 }}°</div>
              <div class="label__input">
                <input #rowInput type="text" [placeholder]="i18n.question_bank_row" [attr.value]="row.label" spellcheck="false" autocomplete="off" (input)="input(rowInput, index, 'row')">
                <div class="label_highlight"></div>
              </div>
            </div>
            <div class="buttons">
              <div class="btn delete-alt" (click)="delete($event, index, 'row')" *ngIf="rows.length > 1" [title]="i18n.question_bank_delete">
                <i class="trash-icon"></i>
              </div>
            </div>
          </div>
        </div>

        <app-plus-button class="add-grid-option-btn" [fromRight]="false" [label]="i18n.question_bank_add_row" (click)="add('row', randomElement)" [disabled]="this.rows.length >= 30"></app-plus-button>
      </div>

      <div #randomElement class="divisor"></div>

      <div class="columns">
        {{ i18n.question_bank_columns }}

        <div *ngFor="let column of columns; let index = index" style="pointer-events: auto;">
          <div class="inside-dropdown">
            <div class="label">
              <div class="label__number">{{ +index + 1 }}°</div>
              <div class="label__input">
                <input #columnInput type="text" [placeholder]="i18n.question_bank_column" [attr.value]="column.label" spellcheck="false" autocomplete="off" (input)="input(columnInput, index, 'column')">
                <div class="label_highlight"></div>
              </div>
            </div>
            <div class="buttons">
              <!-- <div class="btn right-alt" [class.is-active]="alternative.correct" (click)="setCorrectAlt(index)">Correta</div> -->
              <div class="btn delete-alt" (click)="delete($event, index, 'column')" *ngIf="columns.length > 1" [title]=i18n.question_bank_delete>
                <i class="trash-icon"></i>
              </div>
            </div>
          </div>
        </div>

        <app-plus-button class="add-grid-option-btn" [fromRight]="true" [label]="i18n.question_bank_add_column" (click)="add('column', randomElement)" [disabled]="this.columns.length >= 30"></app-plus-button>
      </div>
    </div>

    <!-- <div class="bottom-buttons">
      <div class="mark-correct" [class.is-disabled]="!readyForMark" (click)="toggleMarkCorrectAltMode()">Marcar corretas</div>
    </div> -->
  </ng-container>

  <ng-container *ngIf="markCorrectAlternatives">
    <app-view-grid-multiple-choice [data]="{ 'rows': rows, 'columns': columns, 'matrix': matrix }" (change)="sendData()"></app-view-grid-multiple-choice>

    <div class="bottom-buttons center">
      <div class="mark-correct back" (click)="toggleMarkCorrectAltMode()">Voltar</div>
    </div>
  </ng-container>
</div>
