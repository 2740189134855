<app-generic-modal [isVisible]="isVisible">
    <div class="container flex flex-col modal-help-generate" style="max-width: 520px;">
        <!--header-->
        <div class="border-b border-customBlue-glass mb-4 w-full instructions-header">
            <div class="p-4 flex flex-col items-center justify-center">
                <h3
                    class="font-bold font-roboto text-22 sm:text-26 text-customBlue-default instructions-title"
                >
                    {{ i18n?.import_spreadsheet_resume_modal_instructions }}
                </h3>
                <div
                    class="text-customBlue-default px-14 font-normal font-roboto text-12 sm:text-16 text-center"
                >
                    {{ subtitle }}
                </div>
            </div>
        </div>
        <!--body-->
        <div *ngIf="!isModalLoading" class="p-7 text-center flex-grow instructions-content">
            <div class="text-14 sm:text-16 font-roboto font-normal text-center instructions">
                <p>
                    {{
                        stepsTitle
                    }}
                </p>
                <p>
                    {{
                        i18n?.import_spreadsheet_resume_modal_instructions_step1
                    }}
                </p>
                <p>
                    {{
                        i18n?.import_spreadsheet_resume_modal_instructions_step2
                    }}
                </p>
                <p>
                    {{
                        i18n?.import_spreadsheet_resume_modal_instructions_step3
                    }}
                </p>
                <p>
                    {{
                        i18n?.import_spreadsheet_resume_modal_instructions_step4
                    }}
                </p>
            </div>
        </div>
        <!--footer-->
        <div
            *ngIf="!isModalLoading"
            class="flex items-center justify-center gap-3 mb-12 p-2 instructions-footer"
        >
            <button
                (click)="generate()"
                class="border border-customBlue-default py-2 px-7 rounded-md bg-customWhite-default"
            >
                <span
                    class="text-customBlue-default text-12 sm:text-16 font-roboto font-semibold"
                >
                    {{ i18n?.import_spreadsheet_generate_data_sheet }}
                </span>
            </button>
            <button
                (click)="closeModal()"
                class="border border-customBlue-default py-2 px-8 rounded-md bg-customWhite-default"
            >
                <span
                    class="text-customBlue-default text-12 sm:text-16 font-roboto font-semibold"
                >
                    {{ i18n?.import_spreadsheet_btn_goback }}
                </span>
            </button>
        </div>

        <!-- Loading -->
        <div
            *ngIf="isModalLoading"
            class="p-7 text-center flex-grow flex items-center justify-center"
        >
            <div class="flex flex-col items-center justify-center gap-2">
                <div class="mx-auto">
                    <app-bullets-loader></app-bullets-loader>
                </div>
                <span class="text-center text-customGray-dark text-16">
                    {{ i18n?.import_spreadsheet_wait_generate }}
                </span>
            </div>
        </div>
    </div>
</app-generic-modal>
