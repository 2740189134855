<span id="moveTop"></span>
<div class="title">
    {{title}}
</div>
<ng-container *ngIf="!isLoading">
    <section class="grid grid-col grid-cols-1 w-full gap-y-9 justify-center items-center">
        <!-- Esse componente "app-recently-viewed" ainda não existe -->
           <!--  <app-recently-viewed
            [reportsData]="reportsData"
            ></app-recently-viewed>
     -->
      <ng-container [ngSwitch]="showMain">
        <ng-container *ngSwitchCase="'show'">
            <app-resources
            [reportsData]="reportsData"
            (screen)="whatIsTheScreen($event)"
            ></app-resources>
            <div class="grid grid-cols-1 xl:grid-cols-2 gap-6 w-full">
                <app-activities
                [reportsData]="reportsData"
                (screen)="whatIsTheScreen($event)"
                ></app-activities>
                <app-active-students
                [reportsData]="reportsData"
                (screen)="whatIsTheScreen($event)"
                ></app-active-students>
                <app-grades
                [reportsData]="reportsData"
                (screen)="whatIsTheScreen($event)"
                ></app-grades>
                <app-certificate-reports
                [reportsData]="reportsData"
                (screen)="whatIsTheScreen($event)"
                ></app-certificate-reports>
            </div>
        </ng-container>
    
          <ng-container *ngSwitchCase="'resources'">
              <app-large-resources
              (screen)="whatIsTheScreen($event)"
              ></app-large-resources>
          </ng-container>
    
          <ng-container *ngSwitchCase="'active-students'">
              <app-large-active-students
              (screen)="whatIsTheScreen($event)"
              ></app-large-active-students>
          </ng-container>
    
          <ng-container *ngSwitchCase="'actives'">
              <app-large-activities
              (screen)="whatIsTheScreen($event)"
              ></app-large-activities>
          </ng-container>
    
          <ng-container *ngSwitchCase="'certificate-reports'">
              <app-large-certificate-reports
              (screen)="whatIsTheScreen($event)"
              ></app-large-certificate-reports>
          </ng-container>
    
          <ng-container *ngSwitchCase="'large-grades'">
              <app-large-grades
              (screen)="whatIsTheScreen($event)"
              ></app-large-grades>
          </ng-container>
    
      </ng-container>
    </section>
</ng-container>

<ng-container *ngIf="isLoading">
    <app-reports-skeleton></app-reports-skeleton>
</ng-container>

<div *ngIf="isShowTopButton() == true" class="flex justify-center mt-10" (click)="gotoTop()">
  <div class="flex justify-center w-32 cursor-pointer">
    <img class="pb-1 pr-1" src="../../../../../assets/svg/arrow-to-top.svg">
    <button class="to-top-button"  >{{i18n.reports_back_top}}</button>
  </div>
</div>


<!--
<app-class-diary
[reportsData]="reportsData"
></app-class-diary>
<app-class-performance
[reportsData]="reportsData"
></app-class-performance>

<app-course-performance
[reportsData]="reportsData"
></app-course-performance>
<app-attendance
[reportsData]="reportsData"
></app-attendance>

<app-progress
[reportsData]="reportsData"
></app-progress>
-->
