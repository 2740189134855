import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setLoading, updateSubmissions } from '../../store/homework-submissions.actions';
import { selectHomeworkSubmissionsState, selectLoading, selectSubmissions } from '../../store/homework-submissions.reducer';

@Component({
  selector: 'app-homework-submissions-resume',
  templateUrl: './homework-submissions-resume.component.html',
  styleUrls: ['./homework-submissions-resume.component.scss']
})
export class HomeworkSubmissionsResumeComponent implements OnInit, OnDestroy {

  constructor(private store: Store) { }

  submissions;
  deadline;
  state$: Subscription;

  ngOnInit(): void {
    this.state$ = this.store.select(selectHomeworkSubmissionsState).subscribe(state => {
      this.submissions = state.submissions;
    })
  }

  ngOnDestroy(): void {
    if (this.state$)  this.state$.unsubscribe();
  }
}
