import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-overview-skeleton',
  templateUrl: './overview-skeleton.component.html',
  styleUrls: ['./overview-skeleton.component.scss']
})
export class OverviewSkeletonComponent implements OnInit {

  teachers: any = [{},{},{}];
  ementaDescription: any = [{},{},{},{},{},{}];
  constructor( private sharedService: SharedService) { }

  ngOnInit(): void {
    this.teachers.forEach((element,index) => {
      element['width'+index] = this.sharedService.generateRandomNumber();
    });

    this.ementaDescription.forEach((element,index) =>{
      element['width'+index] = this.sharedService.generateRandomNumber();
    })
  }

}
