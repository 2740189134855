import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { Material } from 'src/app/pages/content-planner/interfaces/modules.interface';

@Component({
    selector: 'app-modal-material-list',
    templateUrl: './modal-material-list.component.html',
    styleUrls: ['./modal-material-list.component.scss']
})
export class ModalMaterialListComponent {
    @Output() closeModalMaterialList = new EventEmitter();
    @Input() isVisibleModalMaterialList = false;
    @Input() materialList: Material[] = [];
    @Input() i18n: any = {};

    closeModal(): void {
        this.closeModalMaterialList.emit(false);
    }
}
