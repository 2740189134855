import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import {
    UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators
} from '@angular/forms';
import { finalize } from 'rxjs';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

import { ForumService } from '../../../../forum.service';

@Component({
    selector: 'app-modal-forum-create-update',
    templateUrl: './modal-forum-create-update.component.html',
    styleUrls: ['./modal-forum-create-update.component.scss']
})
export class ModalForumCreateUpdateComponent implements OnInit, OnChanges {
    @Input() isModalOpen: boolean;
    @Input() isEditMode: boolean;
    @Input() i18n;
    @Input() forumPostId: string | null;
    @Input() forumExternalId;
    @Input() forumThreadText;

    @Output() isModalOpenChange = new EventEmitter<boolean>();
    @Output() isEditModeChange = new EventEmitter<boolean>();
    @Output() forumPostIdChange = new EventEmitter<string | null>();
    @Output() sendThread = new EventEmitter<any>();

    searchForumForm: UntypedFormGroup;

    constructor(
        private FormBuilder: UntypedFormBuilder,
        public platModalService: PlatformModalsService,
        private forumService: ForumService,

    ) {}

    ngOnInit(): void {
        if (!this.searchForumForm) {
            this.createSearchForm();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.isEditMode && this.forumThreadText) {
                this.editForum(this.forumThreadText);
            }
        }
    }

    // Forms
    createSearchForm() {
        let validatorTitle: any = '';
        if (!this.isEditMode) {
            validatorTitle = [Validators.required];
        }
        this.searchForumForm = this.FormBuilder.group({
            title: ['', validatorTitle],
            text: ['', [Validators.required]],
        });
    }

    get title() {
        return this.searchForumForm.get('title');
    }

    get text() {
        return this.searchForumForm.get('text');
    }

    closeModal() {
        this.isModalOpen = false;
        this.isEditMode = false;
        this.isModalOpenChange.emit(this.isModalOpen);
        this.isEditModeChange.emit(this.isEditMode);

        this.searchForumForm.reset();
        this.searchForumForm.addControl(
            'title',
            new UntypedFormControl('', [Validators.required])
        );
    }

    editForum(thread_text: any) {
        if (!this.searchForumForm) {
            this.createSearchForm();
        }
        this.searchForumForm.removeControl('title');
        this.searchForumForm.controls.text.setValue(thread_text);
    }

    saveForum() {
        if (this.isEditMode) {
            const params = {
                text: this.searchForumForm.controls.text.value,
            };

            this.forumService
                .patchForum(this.forumPostId, params)
                .subscribe({
                    next: (res: any) => {
                        // this.getForum();
                        this.closeModal();
                        this.forumPostId = null;
                        this.forumPostIdChange.emit(this.forumPostId);
                        const objectsToSend = {
                            thread: res,
                            type: 'edit'
                        };
                        this.sendThread.emit(objectsToSend);
                    },
                    error: (err) => {
                        if (err.status === 401) {
                            this.platModalService.toggle('message', this.i18n.forum_no_permission_edit, 'close');
                        }
                    }
                });
        } else {
            const params = {
                title: this.searchForumForm.controls.title.value,
                text: this.searchForumForm.controls.text.value,
                parent_id: null,
                root_id: null,
            };

            this.forumService
                .postForum(this.forumExternalId, params)
                .pipe(
                    finalize(() => {
                        this.searchForumForm.reset();
                    })
                )
                .subscribe({
                    next: (res) => {
                        // this.getForum();
                        this.closeModal();
                        const objectsToSend = {
                            thread: res,
                            type: 'create'
                        };
                        this.sendThread.emit(objectsToSend);
                    },
                    error: (err) => {
                        if (err.status === 401) {
                            this.platModalService.toggle('message', this.i18n.forum_no_permission_edit, 'close');
                        }
                    }
                });
        }
    }
}
