import { CertificatesSharedService } from 'src/app/shared/certificates-shared.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-emit-certificate',
  templateUrl: './emit-certificate.component.html',
  styleUrls: ['./emit-certificate.component.scss'],
})
export class EmitCertificateComponent implements OnInit {
  modalEmitCertificate$ = this.certificatesService.modalEmitCertificate$;

  elegiblesParams: any = [];
  emitCertificateForm: UntypedFormGroup;
  checked: any = [];
  elegible_id: any;
  finalData: any;
  buttonUnlocked: boolean = false

  @Input() elegibles: any;
  @Input() i18n: any;

  @Output() callingElegibles = new EventEmitter();
  @Output() emittingPost = new EventEmitter();

  constructor(
    private certificatesService: CertificatesSharedService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.callElegible();
    this.emitCertificateBuilder();
  }


  ngOnChanges(changes: SimpleChanges) {
    this.elegibles.forEach((element) => {
      element.checked = false;
    });
    console.log('On Changes emit', changes);
  }


  closeEmitCertificate() {
    this.certificatesService.modalCloseEmitCertificate();
  }
  openEmitCertificate() {
    this.certificatesService.modalOpenEmitCertificate();
  }

  callElegible() {
    this.callingElegibles.emit();
  }

  postEmitCertificate(external_id, index) {
    this.elegible_id = external_id;
    console.log('CHECKED', this.checked);
    if (this.checked) {
      this.elegibles[index].checked = true;
    } else {
      this.elegibles[index].checked = false;
    }
  }


  savePost() {
    this.finalData = this.elegibles.map((data) => {
      if (data.checked) {
        return data.user.external_id;
      }
    });
    this.finalData = this.finalData.filter((data) => data);
    console.log('FINAL: ', this.finalData);
    this.emittingPost.emit(this.finalData);
  }


  emitCertificateBuilder() {
    this.emitCertificateForm = this.formBuilder.group({
      checked: [''],
    });
    this.emitCertificateForm.valueChanges.subscribe((val) => {
      this.checked = this.emitCertificateForm.value.checked;
      this.buttonUnlocked = true
      console.log('EMIT BUILDER', val, this.checked);
    });
  }
}
