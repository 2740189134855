import { NgModule } from '@angular/core';

import { OrgUnitsComponent } from './org-units.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { OrgUnitsRoutingModule } from './org-units-routing.module';
import { ModalOrgUnitsComponent } from './components/modal-org-units/modal-org-units/modal-org-units.component';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { MessageModalModule } from 'src/app/components/modals/message-modal/message-modal.module';
import { DecisionModalModule } from 'src/app/components/modals/decision-modal/decision-modal.module';
import { DisplayPanelModule } from 'src/app/components/display-panel/display-panel.module';
import { PermissionModule } from 'src/app/directives/permission/permission.module';


@NgModule({
  declarations: [
    OrgUnitsComponent,
    ModalOrgUnitsComponent
  ],
  imports: [
    SharedModule,
    OrgUnitsRoutingModule,
    GenericModalModule,
    MessageModalModule,
    DecisionModalModule,
    DisplayPanelModule,
    PermissionModule
  ]
})
export class OrgUnitsModule { }
