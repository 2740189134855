import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LegendPosition } from '@swimlane/ngx-charts';
import { Subscription } from 'rxjs';
import { SubmissionsService } from 'src/app/pages/disciplines/components/content/components/homework-submissions/submissions.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ContentService } from '../../../../content.service';
import { setActiveTab, updateActiveCourseWork } from '../../store/homework-submissions.actions';
import { selectHomeworkSubmissionsState } from '../../store/homework-submissions.reducer';

@Component({
    selector: 'app-homework-submissions-overview',
    templateUrl: './homework-submissions-overview.component.html',
    styleUrls: ['./homework-submissions-overview.component.scss']
})
export class HomeworkSubmissionsOverviewComponent implements OnInit, OnDestroy {
    state$: Subscription;
    contentID;
    summary;
    lastSubmissions;
    pieChartData;
    i18n: any = {};

    // Chart
    view: any[number] = [280, 280];
    colorScheme = { domain: ['#80CC28', '#f8d900', '#E8532C'] };
    public legendPosition: LegendPosition = LegendPosition.Right;

    completedPercent = '';
    pendingPercent = '';
    latePercent = '';

    constructor(
        private store: Store,
        private submissionsService: SubmissionsService,
        public shared: SharedService,
        private contentService: ContentService,
        private router: Router,
        private platformModalsService: PlatformModalsService
    ) { }

    ngOnInit(): void {
        const getNewState = setInterval(() => {
            if (this.contentService.receiveNewStateSubmissions) {
                this.getTranslations();
                this.state$ = this.store.select(selectHomeworkSubmissionsState).subscribe((state) => {
                    this.contentID = state.contentID;
                    this.summary = state.summary;
                    this.lastSubmissions = state.concludedSubmissions;
                    this.lastSubmissions = this.lastSubmissions.filter((e) => e.course_work.at(-1).completed_at);
                });

                this.setSummaryPieChartData();
                clearInterval(getNewState);
            }
        }, 500);
    }

    goToResumeTab() {
        const resumeTab = 'resume';
        this.store.dispatch(setActiveTab({ activeTab: resumeTab }));
        const currentTab = this.router.routerState.snapshot.url.split('/').pop() || resumeTab;
        this.router.navigateByUrl(this.router.routerState.snapshot.url.replace(currentTab, resumeTab));
    }

    goToSubmissionsTab(submission) {
        const wasSubmited = submission.course_work.at(-1).completed_at;
        if (!wasSubmited) return;

        const newActiveCourseWorkID = submission.course_work.at(-1).id;
        const submissionsTab = 'submissions';

        this.submissionsService
            .getCourseWork(this.contentID, newActiveCourseWorkID)
            .subscribe((courseWork: any) => {
                if (Object.prototype.hasOwnProperty.call(courseWork?.contentSpecific, 'tries')) {
                    const tryObj = courseWork.contentSpecific.tries.filter((try_obj) => try_obj.id === newActiveCourseWorkID);
                    console.log('tryObj ', tryObj);

                    this.submissionsService.homework.next(tryObj);
                }
                this.store.dispatch(updateActiveCourseWork({ activeCourseWork: courseWork }));
                this.store.dispatch(setActiveTab({ activeTab: submissionsTab }));
                const currentTab = this.router.routerState.snapshot.url.split('/').pop() || submissionsTab;
                this.router.navigateByUrl(this.router.routerState.snapshot.url.replace(currentTab, submissionsTab));
            });
    }

    handlePercentValue(value: number, totalStudent: number): string {
        let calculatedValue = `${((value / totalStudent) * 100)}`;

        if (calculatedValue.length > 2 && calculatedValue.includes('.')) {
            calculatedValue = Number(calculatedValue).toFixed(2).replace('.', ',');
        }

        return `${calculatedValue}%`;
    }

    setSummaryPieChartData() {
        const totalStudent = this.summary.completed + this.summary.late + this.summary.pending;

        this.completedPercent = this.handlePercentValue(this.summary.completed, totalStudent);
        this.pendingPercent = this.handlePercentValue(this.summary.pending, totalStudent);
        this.latePercent = this.handlePercentValue(this.summary.late, totalStudent);

        const tempSummary = [
            { name: this.i18n.disciplines_content_submissions_completed, value: this.summary.completed },
            { name: this.i18n.disciplines_content_submissions_completed_late, value: this.summary.late },
            { name: this.i18n.disciplines_content_submissions_pending, value: this.summary.pending },
        ];

        this.pieChartData = tempSummary;
    }

    getTranslations() {
        this.i18n = this.shared.getTranslationsOf('Disciplines');
    }

    ngOnDestroy(): void {
        if (this.state$) this.state$.unsubscribe();
    }
}
