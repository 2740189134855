/* eslint-disable @typescript-eslint/no-dupe-class-members */
import { Component, Input } from '@angular/core';

type Status = 'presence' | 'absent' | '1/2_presence' | '3/4_presence';

@Component({
    selector: 'app-select-status',
    templateUrl: './select-status.component.html',
    styleUrls: ['./select-status.component.scss']
})
export class SelectStatusComponent {
    @Input() status: Status = 'presence';
    @Input() userExternalId: string;
    @Input() onChangeStatus = (status, userExternalId) => {};

    handleChangeStatus(status: Status) {
        if (status === 'presence') {
            this.status = 'absent';
        } else if (status === 'absent') {
            this.status = '1/2_presence';
        } else {
            this.status = 'presence';
        }

        this.onChangeStatus(this.status, this.userExternalId);
    }
}
