import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ReportsService } from '../../../reports.service';
import { MessengerToastService } from 'src/app/components/messenger-toast/messenger-toast.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';

@Component({
  selector: 'app-large-resources',
  templateUrl: './large-resources.component.html',
  styleUrls: ['./large-resources.component.scss']
})
export class LargeResourcesComponent implements OnInit {
  external_id: any = []
  reportsData: any = []
  loading: boolean = false
  contentUsersContents: any = []
  contentUsersUsers: any = []
  resourceForm: UntypedFormGroup

  q: string = '';
  finDate:  string = '';
  iniDate:  string = '';
  sortBy:   string = '';
  sortType: string = '';

  i18n: any = [];
  currentLanguage: any;
  isNotStandalone: boolean = false;

  @Output() screen = new EventEmitter();

  constructor(
    public platModalService: PlatformModalsService,
    private reportsService: ReportsService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private toastMessenger: MessengerToastService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.isNotStandalone = this.localStorageService.isNotStandalone();
    this.getUrlParams();
    this.getReportDetail(
      this.external_id,
      'CONTENTS_USERS',
      0,
      1,
      this.q,
      this.iniDate,
      this.finDate,
      this.sortBy,
      this.sortType,
      )
    this.getTranslations();
    this.createResources();
  }

  loadingTrue() {
    this.platModalService.toggle('loading');
  }

  loadingFalse() {
    this.platModalService.close('loading');
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Reports'),...this.sharedService.getTranslationsOf('Modal')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }



  getReportDetail(disipline, type, format, detail, q, iniDate, finDate, sortBy, sortType) {
    this.loading = true
    let periods = ''
    let formgroup = ''
    this.reportsService
      .getReportDetail(disipline, type, format, detail, q, iniDate, finDate, sortBy, sortType, periods, formgroup)
      .subscribe(
        data => {

          this.contentUsersContents = data.contents;
          //this.contentUsersUsers = data.users;
          this.loadingProgressive(data.users, data.contents);
        }, //data
        (err) => console.log('err', err), //err
        ()=> console.log('ok'), //complete
      )
  }

  loadingProgressive(usersData, contentsData){
    const ITEMS_RENDERED = 5;
    const INTERVAL_MS = 15;

    let length = usersData.length + 1;
    let currentIndex = 0;

    const interval = setInterval(() => {
      const nextIndex = currentIndex + ITEMS_RENDERED;
      for (let n = currentIndex; n <= nextIndex ; n++) {
        if (n >= length) {
          clearInterval(interval);
          break;
        }
        // const context = {
        //   item: {
        //     id: n,
        //     label: Math.random()
        //   },
        //   isEven: n % 2 === 0
        // };
        // this.container.createEmbeddedView(this.template, context);
        this.contentUsersUsers = usersData.slice(0, n);
      }

      currentIndex += ITEMS_RENDERED;
      this.loading = false
    }, INTERVAL_MS);
  }

  getFile(format_file){
    switch (format_file) {
      case 'xlsx':
        this.fileGetter(2, format_file)
        break;
      case 'csv':
        this.fileGetter(3, format_file)
        break;
      case 'gsheet':
        this.urlGetter(4, format_file)
        break;
      default:
        break;
    }
  }

  fileGetter(report_format, format_file) {
    this.reportsService
    .getReportBlobResources(this.external_id, 'CONTENTS_USERS', report_format, 1)
      .subscribe( (blob: Blob): void => {
        const file = new Blob([blob], {type: `application/${format_file}`});
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileURL;
        //TODO ajustar o nome do arquivo
        a.download = `resources.${format_file}`;
        a.click();
      });
  }

  urlGetter(report_format, format_file) {
    this.toastMessenger.messengerToast('load', this.i18n.reports_generating, true)
    this.reportsService
    .getReportUrlResources(this.external_id, 'CONTENTS_USERS', report_format, 1)
      .subscribe( (data): void => {
        this.toastMessenger.messengerToast('success', this.i18n.reports_finished, true, this.i18n.reports_view_report, data.url)
      });
  }

  getUrlParams() {
    this.route?.parent?.parent?.params
      .subscribe((params) => this.external_id = params.id)
  }

  textCut(limit: any, textToCut: any) {
    let text = textToCut;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }

  showMainScreen() {
    this.screen.emit('show')
    console.log('CHAMANDO RESOURCES');
  }

  createResources() {
    this.resourceForm = this.formBuilder.group({
      q:        ['', [Validators.required]],
      day:      ['', [Validators.required]],
      month:    ['', [Validators.required]],
      sortBy:   ['', [Validators.required]],
      sortType: ['', [Validators.required]],
    })
  }

  isCompleted(resource) {
  return resource.completed == true ? 'circle-check__icon'
  : 'circle-x__icon'
  }

}
