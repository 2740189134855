<section class="text-customBlue-default">
    <h1 class="text-22 font-bold">{{ i18n.planner_planning_with_estimated_class }}</h1>

    <header class="mt-2 mb-5">
        <h2 class="font-extrabold">{{ i18n.planner_step_three_detailed_planning }}</h2>
        <p>{{ i18n.planner_choose_module_and_explaining_class }}</p>
    </header>

    <article class="bg-customWhite-default p-8 border border-dashed border-customGray-mediumGray">
        <header>
            <p class="font-semibold mb-2">{{ i18n.planner_planning_class }}</p>
        </header>

        <form [formGroup]="form" class="flex flex-col gap-6 mt-6">
            <fieldset class="flex flex-col gap-1">
                <label for="modules">{{ i18n.planner_modules }}</label>
                <select (change)="updateDynamicMaxWidthTimeout()" class="truncate w-full text-customGray-grayThree bg-customWhite-default border border-customGray-mediumGray pl-2 pr-6 py-2 rounded" formControlName="modules" id="modules" required>
                    <option [value]="module.external_id" *ngFor="let module of modulesList">{{ module.index + ' - ' + module.name }}</option>
                    <option value="" selected disabled *ngIf="modulesList.length">{{ i18n.planner_select }}</option>
                    <option value="error" disabled *ngIf="!modulesList.length">{{ i18n.planner_do_not_have_modules_to_select }}</option>
                </select>
            </fieldset>

            <div class="flex flex-col gap-6" *ngIf="form.controls.modules.value">
                <fieldset class="flex flex-col gap-1">
                    <label for="estimated_class">{{ i18n.planner_number_estimated_class }}</label>
                    <input type="text" (keyup)="validateEstimatedClass(estimatedClassInput)" formControlName="number_estimated" #estimatedClassInput class="w-full text-customGray-grayThree bg-customWhite-default border border-customGray-mediumGray px-4 py-1.5 rounded" id="estimated_class" maxlength="3" min="0">
                </fieldset> 
    
                <div class="flex flex-col gap-6 border border-customGray-mediumGray rounded p-4">
                    <fieldset class="flex flex-col gap-1">
                        <label for="description">{{ i18n.planner_description_didactic_sequence }}:</label>
                        <textarea id="description" rows="3" class="w-full text-customGray-grayThree bg-customWhite-default border border-customGray-mediumGray px-4 py-1.5 rounded resize-none" formControlName="description" spellcheck="false"></textarea>
                    </fieldset>
    
                    <fieldset class="flex flex-col gap-1">
                        <p>{{ i18n.planner_resource }}</p>
                        <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 text-customGray-default">
                            <li class="flex items-center gap-2" *ngFor="let resource of resourcesList; let index = index">
                                <app-check-button class="w-6" (change)="setResourcesItem(resource.value);" [iconSize]="22"></app-check-button>
                                <span>{{ resource.name }}</span>
                            </li>
                        </ul>
                    </fieldset>
                    
                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                        <fieldset class="flex flex-col gap-1">
                            <label for="activities">{{ i18n.planner_activities }}</label>
                            <div class="multi-select" #multiSelectElement>
                                <p-multiSelect
                                formControlName="activities"
                                [options]="activitiesList"
                                [displaySelectedLabel]="true"
                                [filter]="false"
                                [style]="{ width: '100%' }"
                                optionLabel="name"
                                optionValue="value"
                                [placeholder]="i18n.planner_select"
                                >
                                    <ng-template pTemplate="header">
                                        <p class="multiSelect-all-label">{{ i18n.planner_select_all }}</p>
                                    </ng-template>
                                </p-multiSelect>
                              </div>
                        </fieldset>

                        <fieldset class="flex flex-col gap-1">
                            <label for="materials">{{ i18n.planner_materials }}</label>
                            <div class="multi-select" #multiSelectElement>
                                <p-multiSelect
                                formControlName="materials"
                                [options]="materialsList"
                                [displaySelectedLabel]="true"
                                [filter]="false"
                                [style]="{ width: '100%' }"
                                optionLabel="value"
                                optionValue="id"
                                [placeholder]="i18n.planner_select"
                                >
                                    <ng-template pTemplate="header">
                                        <p class="multiSelect-all-label">{{ i18n.planner_select_all }}</p>
                                    </ng-template>
                                </p-multiSelect>
                              </div>
                        </fieldset>

                        <fieldset class="flex flex-col gap-1">
                            <label for="procedures">{{ i18n.planner_procedures }}</label>
                            <div class="multi-select" #multiSelectElement>
                                <p-multiSelect
                                formControlName="procedures"
                                [options]="proceduresList"
                                [displaySelectedLabel]="true"
                                [filter]="false"
                                [style]="{ width: '100%' }"
                                optionLabel="value"
                                optionValue="id"
                                [placeholder]="i18n.planner_select"
                                >
                                    <ng-template pTemplate="header">
                                        <p class="multiSelect-all-label">{{ i18n.planner_select_all }}</p>
                                    </ng-template>
                                </p-multiSelect>
                              </div>
                        </fieldset>
                    </div>
                </div>
            </div>

            <p *ngIf="!form.controls.modules.value" class="text-center my-6 sm:my-12">{{ i18n.planner_select_which_module_want_planning }}</p>
            
            <footer class="flex flex-col sm:flex-row justify-end gap-3">
                <a [routerLink]="['/content-planner/curricular-parameters', planningExternalId]" class="flex items-center justify-center w-full sm:w-auto text-customBlue-default border border-customBlue-default bg-transparent py-1.5 px-10 rounded">{{ i18n.planner_back }}</a>
                
                <button type="button" (click)="onSubmit()" *ngIf="form.controls.modules.value" [disabled]="form.invalid || !this.form.controls.resources.value.length" [ngClass]="{'cursor-not-allowed opacity-75': form.invalid || !this.form.controls.resources.value.length }" class="w-full sm:w-auto text-customWhite-default bg-customBlue-default py-1.5 px-10 rounded">
                    {{ i18n.planner_save }}
                </button>
            </footer>
        </form>
    </article>
</section>
<app-modal-success-planning [i18n]="i18n"
[isVisibleSuccessPlanning]="isVisibleSuccessPlanningModal" 
[message]="i18n.planner_planning_created_successfully"
(closeModalSuccessPlanning)="controlModalSuccess($event)">
</app-modal-success-planning>