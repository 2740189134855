import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpersonficationComponent } from './impersonfication.component';
import { TranslateModule } from '../translate/translate.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MessageModalModule } from '../modals/message-modal/message-modal.module';


@NgModule({
  declarations: [
    ImpersonficationComponent,
  ],
  exports: [
    ImpersonficationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    MessageModalModule,
  ]
})
export class ImpersonificationModule { }
