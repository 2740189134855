import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CertificatesSharedService } from 'src/app/shared/certificates-shared.service';

@Component({
  selector: 'app-confirm-default-delete',
  templateUrl: './confirm-default-delete.component.html',
  styleUrls: ['./confirm-default-delete.component.scss']
})
export class ConfirmDefaultDeleteComponent implements OnInit {
  modalDefaultCertificateDel$   = this.certificatesService. modalDefaultCertificateDel$;

  @Input() certificateId: any;
  @Input() i18n: any;

  @Output() confirmedForDeletion = new EventEmitter();

  constructor(
    private certificatesService: CertificatesSharedService
  ) { }

  ngOnInit(): void {
  }

  confirming() {
    this.confirmedForDeletion.emit(this.certificateId)
  }

  closeDelDefault() {
    this.certificatesService.modalCloseDeleteDefault();
  }
  openDelDefault() {
    this.certificatesService.modalOpenDeleteDefault();
  }

}
