import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-create-grid-multiple-choice',
  templateUrl: './create-grid-multiple-choice.component.html',
  styleUrls: ['./create-grid-multiple-choice.component.scss'],
})
export class CreateGridMultipleChoiceComponent implements OnInit {
  @Input() data: any;
  @Output() change = new EventEmitter();

  i18n: any;

  markCorrectAlternatives: boolean = false;
  readyForMark: boolean = false;
  readyForConfirm: boolean = false;

  rows: any[] = [{ label: '' }, { label: '' }];
  columns: any[] = [{ label: '' }, { label: '' }];
  matrix: any[][] = [
    [{ selected: false }, { selected: false }],
    [{ selected: false }, { selected: false }],
  ];

  constructor(private shared: SharedService) {}

  ngOnInit(): void {
    const translationModuleName = 'QuestionBank';
    this.shared.setI18n(this, translationModuleName);

    if (!this.data) return;

    this.columns = this.data.columns.map((column) => ({ label: column }));
    this.rows = this.data.rows.map((row) => ({ label: row }));

    this.matrix = [...Array(this.rows.length).keys()].map((_) =>
      [...Array(this.columns.length).keys()].map((_) => ({ selected: false }))
    );
  }

  sendData() {
    const arrOfAlternatives = [...this.rows, ...this.columns];

    this.readyForMark = arrOfAlternatives.every((alt) => alt.label.length);

    this.readyForConfirm = this.matrix.every((row) =>
      row.some((column) => column.selected === true)
    );

    const ready = this.readyForMark; /* && this.readyForConfirm */

    this.change.emit({
      ready,
      payload: {
        rows: this.rows.map((row) => row.label),
        columns: this.columns.map((columns) => columns.label),
        // matrix: this.matrix
      },
    });
  }

  toggleMarkCorrectAltMode() {
    this.markCorrectAlternatives = !this.markCorrectAlternatives;
  }

  add(type: string, $randomElement) {
    const maxNumberOfAlternatives = 30;

    const $formBody = $randomElement.closest('.form-body');

    if (this[type + 's'].length >= maxNumberOfAlternatives) return;

    this[type + 's'].push({ label: '' }); //creates a new column or row
    this.scroll($formBody);

    if (type === 'row') {
      this.matrix.push(
        [...Array(this.columns.length).keys()].map(() => ({ selected: false }))
      );
    } else if (type === 'column') {
      this.matrix.forEach((row) => {
        row.push({ selected: false });
      });
    }

    this.sendData();
  }

  delete($event, index, type: string) {
    $event.stopPropagation();

    if (this[type + 's'].length <= 1) return;

    this[type + 's'].splice(index, 1);

    if (type === 'row') {
      this.matrix.splice(index, 1);
    } else if (type === 'column') {
      this.matrix.forEach((row) => {
        row.splice(index, 1);
      });
    }

    this.sendData();
  }

  input(inputRef, index, type: string) {
    this[type + 's'][index].label = inputRef.value;

    this.sendData();
  }

  scroll($formBody) {
    const insideDrodownClassheight = 51;
    const flexGap = 10;

    $formBody.scrollTop += insideDrodownClassheight + flexGap; //this gives the scroll, is them sum of the .inside-dropdown (class) height with the flex gap
  }
}
