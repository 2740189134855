import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ReportsService } from '../../../reports.service';
import { minDateValidator } from 'src/app/utils/form-validators/form-validators'; 
import { MessengerToastService } from 'src/app/components/messenger-toast/messenger-toast.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';

@Component({
  selector: 'app-large-certificate-reports',
  templateUrl: './large-certificate-reports.component.html',
  styleUrls: ['./large-certificate-reports.component.scss']
})
export class LargeCertificateReportsComponent implements OnInit {
  external_id: any = []
  certificatesData: any = []
  loading: boolean = false
  certificateForm: UntypedFormGroup
  initialDate: Date = new Date();

  sortBySelect: any = [

  ]
  sortTypeSelect: any = [

  ]

  i18n: any = [];
  currentLanguage: any;

  q: string = '';
  finDate:  string = '';
  iniDate:  string = '';
  sortBy:   string = 'user_name';
  sortType: string = 'ASC';
  periods: string = '';
  form_group: string = '';
  isNotStandalone: boolean = false;

  @Output() screen = new EventEmitter();

  constructor(
    public platModalService: PlatformModalsService,
    private reportsService: ReportsService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    public toastMessenger: MessengerToastService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.isNotStandalone = this.localStorageService.isNotStandalone();
    this.getTranslations();
    this.loadTranslates();
    this.getUrlParams();
    this.getReportDetail(
      this.external_id,
      'CERTIFICATES',
      0,
      1,
      this.q,
      this.iniDate,
      this.finDate,
      this.sortBy,
      this.sortType,
      this.periods,
      this.form_group,
      )
    this.createFormCertificate();
  }

  loadingTrue() {
    this.platModalService.toggle('loading');
  }

  loadingFalse() {
    this.platModalService.close('loading');
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Reports'),...this.sharedService.getTranslationsOf('Modal')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  getReportDetail(disipline, type, format, detail, q, iniDate, finDate, sortBy, sortType, periods, form_group) {
    this.loading = true
    this.reportsService
      .getReportDetail(disipline, type, format, detail, q, iniDate, finDate, sortBy, sortType, periods, form_group)
      .subscribe(
        data => {
          this.certificatesData = data.certificates
          this.loading = false
          console.log('DATA CERTFICATE', this.certificatesData);
        }, //data
        (err) => console.log('err', err), //err
        ()=> console.log('ok'), //complete
      )
  }

 loadTranslates() {
  this.sortBySelect = [
    {'title': `${this.i18n.reports_name}`, 'value': 'user_name'},
    {'title': `${this.i18n.reports_issue_date}`, 'value': 'emitted'}
  ]

  this.sortTypeSelect = [
    {'title': `${this.i18n.reports_ascending}`, 'value': 'ASC'},
    {'title': `${this.i18n.reports_descending}`, 'value': 'DESC'}
  ]
 }

  getFile(report_format, format_file){
    let  discipline_external_id = this.external_id
    let  report_type = 'CERTIFICATES'
    //let  report_format = 0
    let  detail_level = 1
    let  q = this.certificateForm.controls.q.value;
    let  initial_date =  this.iniDate
    let  final_date = this.finDate
    let  sort_by = this.certificateForm.controls.sortBy.value
    let  sort_type = this.certificateForm.controls.sortType.value
    let  periods = ''
    let  form_group = ''

    this.reportsService
    .getReportBlobCertificate(
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      initial_date,
      final_date,
      sort_by,
      sort_type,
      periods,
      form_group,
    )
      .subscribe( (blob: Blob): void => {
        this.howToGetTheFile(format_file, blob)
      });
  }

  getUrl(report_format, format_file){
    this.toastMessenger.messengerToast('load', this.i18n.reports_generating, true)
    let  discipline_external_id = this.external_id
    let  report_type = 'CERTIFICATES'
    //let  report_format = 0
    let  detail_level = 1
    let  q = this.certificateForm.controls.q.value;
    let  initial_date =  this.iniDate
    let  final_date = this.finDate
    let  sort_by = this.certificateForm.controls.sortBy.value
    let  sort_type = this.certificateForm.controls.sortType.value
    let  periods = ''
    let  form_group = ''

    this.reportsService
    .getReportUrlCertificate(
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      initial_date,
      final_date,
      sort_by,
      sort_type,
      periods,
      form_group,
    )
      .subscribe( (data): void => {
        this.toastMessenger.messengerToast('success', this.i18n.reports_finished, true, this.i18n.reports_view_report, data.url)
      });
  }

  howToGetTheFile(format_file: string, blob: Blob) {
    const file = new Blob([blob], { type: `application/${format_file}` });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');

    switch (format_file) {
      case 'pdf':
        window.open(fileURL, '_blank', 'width=800, height=600');
      break;
      case 'xlsx':
        a.href = fileURL;
        a.download = `certificates.${format_file}`;
        a.click();
      break;
      case 'csv':
        a.href = fileURL;
        a.download = `certificates.${format_file}`;
        a.click();
      break;
      default:
        break;
    }
  }

  getUrlParams() {
    this.route?.parent?.parent?.params
      .subscribe((params) => this.external_id = params.id)
  }

  textCut(limit: any, textToCut: any) {
    let text = textToCut;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }

  showMainScreen() {
    this.screen.emit('show');
    console.log('CHAMANDO RESOURCES');
  }

  createFormCertificate() {
    this.certificateForm = this.formBuilder.group({
      q:        ['', [Validators.required]],
      iniDate:  ['', [Validators.required]],
      finDate:  ['', [Validators.required]],
      sortBy:   ['user_name', [Validators.required]],
      sortType: ['ASC', [Validators.required]],
      calendarStartDate: ['', [this.minDateValidator.bind(this)]],
      calendarEndDate: ['', [this.minDateValidator.bind(this)]],
      startDate: [new Date()],
    })
    this.certificateForm.get('iniDate')?.valueChanges.subscribe(
      iniDate => {
        iniDate = iniDate == null || iniDate == "" ? null : formatDate(iniDate, 'yyyy-MM-dd', 'en-US');
        this.iniDate = iniDate;
      }
    )
    this.certificateForm.get('finDate')?.valueChanges.subscribe(
      finDate => {
        finDate = finDate == null || finDate == "" ? null : formatDate(finDate, 'yyyy-MM-dd', 'en-US');
        this.finDate = finDate;
      }
    )
  }

  doQuery() {
    let  discipline_external_id = this.external_id
    let  report_type = 'CERTIFICATES'
    let  report_format = 0
    let  detail_level = 1
    let  q = this.certificateForm.controls.q.value;
    let  initial_date =  this.iniDate
    let  final_date = this.finDate
    let  sort_by = this.certificateForm.controls.sortBy.value
    let  sort_type = this.certificateForm.controls.sortType.value
    let  periods = ''
    let  form_group = ''

    this.getReportDetail(
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      initial_date,
      final_date,
      sort_by,
      sort_type,
      periods,
      form_group
    );
  }

  minDateValidator(control: FormControl): { [key: string]: any } | null {
    const enteredDate = control;
    
    let dateMin : Date = new Date();
    let returnValue: any = null;
    
    if (this.certificateForm && true) {
      dateMin = this.initialDate;
      returnValue = minDateValidator(dateMin)(enteredDate);
    }
    return returnValue;
  }

  fnPickDateToInput(calendarDateValue?){
    // Coloca a data no input vindo do CalendarPicker
    if (calendarDateValue) {
      let format = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'MM/dd/yyyy HH:mm','en-US') : formatDate(calendarDateValue, 'dd/MM/yyyy HH:mm','pt-BR');
      this.certificateForm.controls['calendarStartDate'].setValue(format);
    }
  }

  get startDate() {
    return this.certificateForm.get('startDate');
  }
}
