<!-- Modal add User -->
<div
  *ngIf="showModal"
  class="opacity-25 fixed inset-0"
  [ngClass]="{ hidden: modalUploadOverlay }"
  style="background-color: #000; z-index: 11"
></div>
<div
  *ngIf="showModal"
  class="animate-fade-in-down overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex transition duration-1000 ease-in-out"
  [ngClass]="{ hidden: modalUploadOverlay }"
  style="z-index: 12"
>
  <div
    class="mx-auto absolute bg-customWhite-default xl:w-1/3 lg:w-1/2 md:w-2/3 w-11/12"
    style="max-height: 100vh; overflow-y: auto; border-radius: 20px"
  >
    <!--content-->
    <div
      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
    >
      <!--header-->
      <div
        class="header_modal_add flex items-center justify-center p-5 border-b border-solid border-customBlue-glass text-customGray-dark"
      >
        <div class="text-center">
          <h3 class="text-2xl font-medium text-customBlue-default">
            {{ titleModal }}
          </h3>
          <h5 class="subtitle_modal" *ngIf="subtitleModal">{{ subtitleModal }}</h5>
        </div>
        <button class="close_button_modal" (click)="handleToggleModal()">
          <span
            class="iconify btn-animate"
            data-icon="carbon:close-outline"
            style="color: #484b4d"
            data-width="22"
          ></span>
        </button>
      </div>

      <!--body-->
      <div class="relative p-4 flex-auto">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
