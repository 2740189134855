/* eslint-disable no-restricted-globals */
import { formatDate } from '@angular/common';
import {
    Component, EventEmitter, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { Calendar } from 'primeng/calendar';

import { ResponseCalendaryHistory } from '../../calling-roll.service';

@Component({
    selector: 'app-calendar-picker',
    templateUrl: './calendar-picker.component.html',
    styleUrls: ['./calendar-picker.component.scss']
})
export class CalendarPickerComponent implements OnInit {
    @ViewChild('calendarDate') selectorcalendarEndDate: Calendar;

    @Input() currentLanguage: string | null;
    @Input() position = 'initial';
    @Input() minDateValidation: any;
    @Input() enableCalendar = false;
    @Input() frequencyCalendary: ResponseCalendaryHistory | undefined;
    @Input() initialDate: Date = new Date();
    @Input() getFrequencyCalendary: any;
    @Output() emitEvent = new EventEmitter();

    dateInputMask: any;
    date: Date[] | undefined;
    maxDateValidation: Date = new Date();

    ngOnInit(): void {
        this.createDateMaskInput();
    }

    toggleCalendar() {
        this.enableCalendar = !this.enableCalendar;

        if (this.enableCalendar) {
            this.getFrequencyCalendary();
        }
    }

    createDateMaskInput() {
        this.dateInputMask = createMask<Date>({
            alias: 'datetime',
            inputFormat: this.currentLanguage === 'en' ? 'mm/dd/yyyy' : 'dd/mm/yyyy',
            placeholder: '',
            oncleared: () => {
                this.selectorcalendarEndDate.clear();
            },
        });
    }

    // recebe evento quando usuário escolhe uma data vindo do Picker Calendar
    fnPickDateToInput(calendarDateValue) {
    // Coloca a data no input vindo do CalendarPicker
        if (calendarDateValue) {
            const formatedDate = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'yyyy-MM-dd', 'en-US') : formatDate(calendarDateValue, 'yyyy-MM-dd', 'pt-BR');
            this.emitEvent.emit({ formatedDate });
            this.enableCalendar = false;
        }
    }

    handleMonthChange(monthYear: any) {
        this.frequencyCalendary = undefined;

        this.emitEvent.emit({ month: monthYear.month, year: monthYear.year });
    }

    getStatusInfrequencyCalendary(day: number): string {
        if (this.frequencyCalendary) {
            const dayFound = this.frequencyCalendary.days.find((dayData) => dayData.day === day);

            if (dayFound) return dayFound.status;
            return 'not-found';
        }
        return 'not-found';
    }

    hasStatusStarted(day: number): boolean {
        return this.getStatusInfrequencyCalendary(day) === 'started';
    }

    hasStatusFinished(day: number): boolean {
        return this.getStatusInfrequencyCalendary(day) === 'finished';
    }
}
