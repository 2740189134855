<form [formGroup]="categoryForm">
    <!-- BODY FORM-->
    <div formArrayName="categories" *ngFor="let category of categories.controls; let i = index">
        <div [formGroupName]="i" class="flex gap-4 items-center justify-center mb-2">
            <div class="flex flex-col input-size">
                <input type="text" [ngClass]="{'border-customGray-default text-customGray-dark': editInput[i] == true, 'border-customBlue-default cursor-not-allowed bg-customBlue-glass text-customBlue-royal text-center font-bold': editInput[i] == undefined }" formControlName="name" class="border rounded p-2 " >
                <div [ngClass]="{'invisible': !categories.controls[i]['controls']['name'].errors}">
                    <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !categories.controls[i]['controls']['name']?.errors?.required}">
                        {{i18n.grades_name_required}}
                    </p>
                </div>
            </div>
            <div class="flex flex-col input-size">
                <ng-container *ngIf="period?.calculation_type == 'weighted_average'">
                    <input appCustomPercentAverage maxlength="6" min="0" [ngClass]="{'border-customGray-default text-customGray-dark': editInput[i] == true, 'font-bold': editInput[i] == undefined }" formControlName="weight" class="border rounded text-center p-2 "  >
                    <div [ngClass]="{'invisible': !categories.controls[i]['controls']['weight'].errors}">
                        <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !categories.controls[i]['controls']['weight']?.errors?.required}">
                            {{i18n.grades_weight_required}}
                        </p>
                    </div>
                </ng-container>
                <ng-container *ngIf="period?.calculation_type == 'sum'">
                    <input maxlength="3" min="0" type="number" step="0.01" [ngClass]="{'border-customGray-default text-customGray-dark': editInput[i] == true, 'font-bold': editInput[i] == undefined }" formControlName="weight" class="border rounded text-center p-2 "  >
                    <div [ngClass]="{'invisible': !categories.controls[i]['controls']['weight'].errors}">
                        <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !categories.controls[i]['controls']['weight']?.errors?.required}">
                            {{i18n.grades_max_grade_required}}
                        </p>
                    </div>
                </ng-container>
            </div>
            <div class="flex flex-col buttons-size" >
                <div class="flex items-center justify-center gap-2">
                    <button (click)="editCategory(category['controls']?.id?.value, i)" class="content__professor-btns" aria-labelledby="edit-content-button-label">
                        <div class="bg-customBlue-glass bg-btn">
                            <span class="iconify w-5 h-5 text-customGray-dark"  data-icon="bytesize:edit"></span>
                        </div>
                        <span id="edit-content-button-label" hidden>{{i18n.grades_edit_category}}</span>
                    </button>
                    <button (click)="deleteSettingsCategory(category['controls']?.id?.value, i)" class="content__professor-btns"  aria-labelledby="delete-content-button-label">
                        <div class="bg-customBlue-glass bg-btn">
                            <span class="iconify w-5 h-5 text-customGray-dark font-bold" data-icon="bi:trash"></span>
                        </div>
                        <span id="delete-content-button-label" hidden>{{i18n.grades_remove_category}}</span>
                    </button>
                </div>
                <p class="text-sm " class="invisible">
                    none
                </p>
            </div>
        </div>
    </div>
    <div formArrayName="newCategories" *ngFor="let newCategory of newCategories.controls; let i = index">
        <div [formGroupName]="i" class="flex gap-4 items-center justify-center mb-2">
            <div class="flex flex-col input-size">
                <input type="text" formControlName="name" class="border rounded border-customGray-default text-center p-2  text-customGray-dark">
                <div [ngClass]="{'invisible': newCategories.controls[i]['controls']['name'].pristine}">
                    <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !newCategories.controls[i]['controls']['name']?.errors?.required}">
                        {{i18n.grades_name_required}}
                    </p>
                </div>
            </div>
            <div class="flex flex-col input-size">
                <ng-container *ngIf="period?.calculation_type == 'weighted_average'">
                    <input appCustomPercentAverage maxlength="6" min="0" formControlName="weight" class="border rounded border-customGray-default text-center p-2 text-customGray-dark" >
                    <div [ngClass]="{'invisible': newCategories.controls[i]['controls']['weight'].pristine}">
                        <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !newCategories.controls[i]['controls']['weight']?.errors?.required}">
                            {{i18n.grades_weight_required}}
                        </p>
                    </div>
                </ng-container>
                <ng-container *ngIf="period?.calculation_type == 'sum'">
                    <input maxlength="3" min="0" type="number" step="0.01" formControlName="weight" class="border rounded border-customGray-default text-center p-2 text-customGray-dark" >
                    <div [ngClass]="{'invisible': newCategories.controls[i]['controls']['weight'].pristine}">
                        <p class="text-sm " style="color: #E8532C;" [ngClass]="{'invisible': !newCategories.controls[i]['controls']['weight']?.errors?.required}">
                            {{i18n.grades_max_grade_required}}
                        </p>
                    </div>
                </ng-container>
            </div>
            <div class="flex flex-col justify-center buttons-size" >
                <button (click)="removeControlFormNewCategories(i)" class="flex justify-center"  aria-labelledby="delete-content-button-label">
                    <div class="bg-customBlue-glass bg-btn">
                        <span class="iconify w-5 h-5 text-customGray-dark font-bold" data-icon="bi:trash"></span>
                    </div>
                    <span id="delete-content-button-label" hidden>{{i18n.grades_remove_category}}</span>
                </button>
                <p class="text-sm " class="invisible">
                    none
                </p>
            </div>
        </div>
    </div>
    <button [ngClass]="{'disabled:opacity-20 cursor-not-allowed': weightSum >= 100 }" [disabled]="weightSum >= 100" (click)="addFormCategory()" class="flex items-center my-2">
        <app-plus-button [label]="i18n.grades_new_category" ></app-plus-button>
    </button>
    <div class="my-8">
        <div class="flex row justify-center pt-4 xs:gap-2 gap-8">
            <button class="flex border rounded-md py-2 xs:px-6 px-12 font-bold text-sm border-customGray-textGray" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !categoryForm.valid  }" [disabled]="!categoryForm.valid" style="color: #FFFFFF;background-color: #233674" (click)="saveCategories()" >
                <span *ngIf="controlButton">{{i18n.grades_save}}</span>
                <span *ngIf="!controlButton">{{i18n.disciplines_form_create}}</span>
            </button>
            <button class="text-customBlue-default background-transparent font-semibold xs:px-6 px-12 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="closeModal('close')">
                <span *ngIf="controlButton">{{i18n.grades_cancel}}</span>
                <span *ngIf="!controlButton">{{i18n.disciplines_emit_disabled_goback}}</span>
            </button>
        </div>
    </div>
    <!-- FIM BODY FORM-->
</form>
