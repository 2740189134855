import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PreviewSheetService {
    constructor(
        private http: HttpClient
    ) { }

    getRows(external_id: any): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/spreadsheet/${external_id}/validation`);
    }

    formatSheet(id: any, formData: any): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/spreadsheet/${id}/format-sheet`, formData);
    }

    import(formData: any): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/spreadsheet/import`, formData);
    }
}
