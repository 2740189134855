import { NgModule } from '@angular/core';

import { ProfileRoutingModule } from './profile-routing.module';
import { UploadModule } from 'src/app/components/upload/upload.module';

import { ProfileComponent } from './profile.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    ProfileComponent
  ],
  imports: [
    SharedModule,
    ProfileRoutingModule,
    UploadModule,
  ]
})
export class ProfileModule { }
