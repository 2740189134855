import { LegendPosition } from '@swimlane/ngx-charts';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'content-type',
  template: `
<div class="content-pie-container">
  <div class="pie-chart">
    <div class="w-full p-2.5 text-customBlue-default font-medium">{{title}}</div>
    <div class="flex flex-row justify-start items-start -ml-24" *ngIf="contentTypeData?.length">
      <ngx-charts-pie-chart
          [view]="view"
          [scheme]="colorScheme"
          [results]="contentTypeData"
          [gradient]="gradient"
          [legend]="showLegend"
          [legendPosition]="legendPosition"
          [labels]="showLabels"
          [doughnut]="isDoughnut"
          [legendTitle]="content"
          style="fill: #A9AEB3;"
          >
        <ng-template #tooltipTemplate let-model="model">
            <pre class="mt-1">{{model.name}}</pre>
            <pre>{{model.value*100 + '%'}}</pre>
        </ng-template>
      </ngx-charts-pie-chart>
    </div>
    <div class="mt-6" *ngIf="!contentTypeData?.length"><app-empty-data></app-empty-data></div>
  </div>
</div>
 `,
  styleUrls: ['./adm-styles.scss'],
})
export class ContentTypeComponent implements OnInit, OnChanges {
  @Input() adminData: any
  @Input() i18n: any

  contentTypes: any = [];
  contentTypeData: any[] = [];
  title: string
  content: string

  view: any[number] = [335, 180]

  // options
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  public legendPosition: LegendPosition = LegendPosition.Right;

  colorScheme = {
    domain: [
        '#D8E5F8', '#B2B8CD', '#353232', '#233674', '#233674',
        '#3656BF', '#7090F5', '#989898', '#B3D2FF', '#969BAD',
        '#0D183E', '#0E2A8A'
        ],
  };

  constructor () {}

  ngOnInit(): void {
    this.title = this.i18n.dash_content_types
  }

  ngOnChanges(): void {
    this.formatDataAverageTime();
    this.content = this.i18n.dash_content
  }
  
  formatDataAverageTime() {
    const contentTypes = this.adminData?.content_type || [];

    if (contentTypes) {
      this.contentTypeData = contentTypes
      .map(contentType => {
        return {
          name:  this.getLabelsGrup(contentType.type),
          value: contentType.total
        };
      })
    }
  }

  getLabelsGrup(contentType: any) {
    let typeDefault = this.i18n.disciplines_select_tab_content;
    switch (contentType) {
      case 'Assignment':
        return this.i18n.disciplines_type_upload_assignment;
      case 'UploadAssignment':
        return this.i18n.disciplines_type_upload_assignment;
      case 'DocsAssignment':
        return this.i18n.disciplines_type_docs_assignment;
      case 'SheetsAssignment':
        return this.i18n.disciplines_type_sheets_assignment;
      case 'File':
        return this.i18n.disciplines_page_file;
      case 'Youtube':
        return this.i18n.disciplines_youtube;
      case 'TextPage':
        return this.i18n.disciplines_text_page;
      case 'Link':
        return this.i18n.disciplines_link;
      case 'Forum':
        return this.i18n.disciplines_select_tab_forum;
      case 'Conference':
        return this.i18n.disciplines_conference;
      case 'InternalConference':
        return this.i18n.disciplines_conference;
      case 'InternalQuiz':
        return this.i18n.disciplines_upload_quiz;
      case 'UploadQuiz':
        return this.i18n.disciplines_upload_quiz_external;
      case 'Scorm':
        return this.i18n.disciplines_scorm;
      case 'LTI':
        return this.i18n.disciplines_page_lti;
      default:
        return typeDefault;
    }
  }
}
