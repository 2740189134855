import { DashboardService } from './../../dashboard.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-feed',
  templateUrl: './dashboard-feed.component.html',
  styleUrls: ['./dashboard-feed.component.scss'],
})


export class DashboardFeedComponent {
  @Input() dashData: any = [];
  @Input() i18n: any = [];


  constructor(
    private dashboardService: DashboardService,
    ) { }


  routeToLink(discipline_id: any, object_type: any, object_properties: any) {
    let sectionExternalId = object_properties.section_external_id;
    let contentType   = object_properties.content_type;
    let externalId = object_properties.external_id;

    switch (object_type) {
      case 'Announcement':
              this.dashboardService
              .toAnnouncements(discipline_id)
        break;
      case 'Content':
              this.dashboardService
              .toTheSectionAndType(discipline_id, externalId, sectionExternalId, contentType);
        break;
    }
  }

}
