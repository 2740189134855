import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-grades-periods-list-skeleton',
  templateUrl: './grades-periods-list-skeleton.component.html',
  styleUrls: ['./grades-periods-list-skeleton.component.scss']
})
export class GradesPeriodsListComponentSkeleton implements OnInit {

  periods: any = [{},{},{},{},{},{}];

  constructor(private sharedService: SharedService ) { }

  ngOnInit(): void {
    this.periods.forEach(element => {
      element['width_description1'] = this.sharedService.generateRandomNumber();
      element['width_description2'] = this.sharedService.generateRandomNumber();
    });
  }

}
