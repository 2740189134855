<div class="flex flex-col gap-y-5 justify-center items-center md:justify-start md:items-start">
    <div class="shortcut-title">
        {{i18n.dash_shortcuts}}
    </div>
    <div class="adm-shortcut">
        <adm-shortcut-card [typeShortcut]="'addUser'" [i18n]="i18n"></adm-shortcut-card>
        <adm-shortcut-card [typeShortcut]="'addDiscipline'" [i18n]="i18n"></adm-shortcut-card>
        <adm-shortcut-card [typeShortcut]="'configEnvironment'" [i18n]="i18n"></adm-shortcut-card>
    </div>
</div>
