import { createAction, props } from '@ngrx/store';

export const setActiveTab = createAction(
  '[Homework Submissions] Set active tab',
  props<{ activeTab: string }>(),
)

export const setLoading = createAction(
  '[Homework Submissions] Set loading',
  props<{ loading: boolean }>(),
)

export const updateSubmissions = createAction(
  '[Homework Submissions] Update submissions',
  props<{ submissions: any[], concludedSubmissions: any[], activeCourseWork: any }>(),
)

export const updateActiveCourseWork = createAction(
  '[Homework Submissions] Update active course work',
  props<{ activeCourseWork: any }>(),
)

export const updateCourseWorkFileIndex = createAction(
  '[Homework Submissions] Update course work file index',
  props<{ courseWorkFileIndex: number }>(),
)

//PermanentInfo
export const setOverallData = createAction(
  '[Homework Submissions] Set overall data',
  props<{
    content: any;
    deadline: string,
    contentDescription: string,
    contentName: string,
    contentID: string,
    contentType: string,
    concludedSubmissions: any[],
    submissions: any[],
    summary: any[]
  }>(),
)
