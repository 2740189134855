import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  nome: string;
  show: boolean;
  files:any = [];
  maxFiles:number = 1;
  modalOpen$ = this.sharedService.modalOpen$;

  constructor(
    private sharedService:SharedService
  ) { }

  ngOnInit(): void {   
    this.letter();
  }

  openModal(){
      console.log('this.files ', this.files);
      this.sharedService.modalOpen();
  }

  letter() {
    let name = "Diego";
    let lastname = "Soek";
    let initials = name.charAt(0)+""+lastname.charAt(0);
    this.nome = initials;
  }

  receiveFiles($event:any) {
    
    this.files = $event
    console.log('FILES ', this.files);
  }


}
