import { SharedService } from 'src/app/shared/shared.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-view-grid-multiple-choice',
  templateUrl: './view-grid-multiple-choice.component.html',
  styleUrls: ['./view-grid-multiple-choice.component.scss'],
})
export class ViewGridMultipleChoiceComponent implements OnInit {
  @Input() data: any = {
    rows: [],
    columns: [],
    matrix: [],
    options: [],
  };

  i18n: {
    question_bank_only_alternative_line: string;
    question_bank_select_correct_alternative_line: string;
  };

  rows: any[];
  columns: any[];

  @Input() viewOnly: boolean = false;

  @Output() change = new EventEmitter();

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.getTranslations();
    this.data = {
      ...this.data,
      matrix: [...Array(this.data.rows.length).keys()].map(() =>
        [...Array(this.data.columns.length).keys()].map(() => ({
          selected: false,
        }))
      ),
    };

    const needsToBeProcessed = typeof this.data.rows[0] === 'string';

    if (needsToBeProcessed) {
      this.rows = this.data.rows.map((row) => ({ label: row }));
      this.columns = this.data.columns.map((column) => ({ label: column }));
    } else {
      this.rows = this.data.rows;
      this.columns = this.data.columns;
    }

    if (Array.isArray(this.data.options) && this.data.options.length) {
      this.data.matrix = this.data.options.map((row) =>
        row.map((col) => ({ selected: col }))
      );
    }
  }

  toggleMatrix(rowIndex: number, columnIndex: number, selected: boolean) {
    //use carefully, don't make me reset you again, at any case follow the white rabbit _|-_-|_
    this.data.matrix[rowIndex].forEach((column) => (column.selected = false)); //reseting
    this.data.matrix[rowIndex][columnIndex].selected = !selected;

    this.data.user_response = this.data.matrix.map((row) =>
      row.map((col) => Number(col.selected))
    );

    this.change.emit(this.data);
  }

  getTranslations() {
    this.i18n = this.sharedService.getTranslationsOf('QuestionBank');
  }
}
