import { Component, Input, OnInit } from '@angular/core';
import { SettingsConceptsService } from './settings-concepts.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
  selector: 'app-settings-concept',
  templateUrl: './settings-concept.component.html',
  styleUrls: ['./settings-concept.component.scss'],
})
export class SettingsConceptComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() discipline_external_id: any;

  processing: boolean = false;
  concepts: any;
  conceptObject: any;
  isModalOpen: boolean = false;
  editMode: boolean = false;

  i18n: any = [];

  constructor(
    private settingsConceptsService: SettingsConceptsService,
    private platModalService: PlatformModalsService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.getConceptsChanges();
  }

  getTranslations() {
    this.i18n = this.sharedService.getTranslationsOf('Grades');
  }

  openModalAddConcept() {
    this.isModalOpen = true;
  }

  openModalEditConcept(index: any) {
    this.sharedService.editConcept$.next(this.concepts[index]);
    this.isModalOpen = true;
  }

  getConceptsChanges() {
    this.sharedService.isConceptUpdated.subscribe((conceptObject: any) => {
      console.log('conceptObject ', conceptObject);
      this.getSettingsConceptsList();
    });
  }

  getStatusModal(event: any) {
    this.isModalOpen = event;
  }

  getSettingsConceptsList() {
    this.processing = true;
    this.settingsConceptsService
      .getSettingsConceptsList(this.discipline_external_id)
      .subscribe((concepts) => {
        console.log('concepts ', concepts);
        this.concepts = concepts;
        this.processing = false;
      });
  }

  deleteSettingsConcepts(concept_id: any) {
    this.platModalService.toggle('decision', 'deletion_subject_generic', {
      forward: () => {
        this.settingsConceptsService
          .deleteSettingsConcepts(this.discipline_external_id, concept_id)
          .subscribe((response: any) => {
            this.getSettingsConceptsList();
          });
      },
      finally: () => {
        this.platModalService.close('decision');
      },
    });
  }
}
