import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-grades-student-table',
  templateUrl: './grades-student-table.component.html',
  styleUrls: ['./grades-student-table.component.scss'],
})
export class GradesStudentTableComponent implements OnInit, OnChanges {
  @Input() student: any;

  i18n: any = [];
  discipline_external_id: string

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharedSerivce: SharedService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.getRouteParams();
  }

  getRouteParams() {
    this.route?.parent?.parent?.parent?.params?.subscribe((params)=> {
      this.discipline_external_id = params.id
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.student && this.student) {
      if (this.student.average) {
        this.student.average = this.sharedSerivce.fnReplaceDotForComa(this.student.average);
      }

      if (this.student.categories.length) {
        this.student.categories.map((category)=>{
          category[category.name].map((item)=> {
            if (item.evaluation) {
              item.evaluation = this.sharedSerivce.fnReplaceDotForComa(item.evaluation);
            }
          })
        })
        console.log(this.student)
      }
    }
  }

  getTranslations() {
    this.i18n = {
      ...this.sharedSerivce.getTranslationsOf('Grades'),
      ...this.sharedSerivce.getTranslationsOf('Disciplines'),
    };
  }

  goToContent(grade: any, newTab?: boolean) {
    if (newTab) {
      let link = this.router.createUrlTree([
        `../../../../../${grade.content_section_ext_id}/homework-submissions/${grade.content_external_id}/submissions`,
        { coursework: grade?.coursework_id, period_id: this.student?.period?.id, user_external_id: this.student?.external_id },
      ],
      { relativeTo: this.route }).toString();
      window.open(link, '_blank')
      return
    }
    this.router.navigate(
      [
        `../../../../../${grade.content_section_ext_id}/homework-submissions/${grade.content_external_id}/submissions`,
        { coursework: grade?.coursework_id, period_id: this.student?.period?.id, user_external_id: this.student?.external_id },
      ],
      { relativeTo: this.route }
    );
  }
}
