import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../../../grades.service';

@Component({
  selector: 'app-grades-student-detail',
  templateUrl: './grades-student-detail.component.html',
  styleUrls: ['./grades-student-detail.component.scss']
})
export class GradesStudentDetailComponent implements OnInit {

  period_id: any;
  student_external_id: any;
  discipline_external_id: any;
  periods: any;
  student: any;
  studentSetUp: any;

  i18n: any = []

  constructor(
    private gradesService: GradesService,
    private route: ActivatedRoute, 
    private sharedSerivce: SharedService


  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.getUrlParams();
    this.getGradesChanges();
  }
  

  getTranslations(){
    this.i18n = this.sharedSerivce.getTranslationsOf('Grades')
  }

  getUrlParams(){
    console.log('this.route ', this.route);
    
    this.route?.params?.subscribe((params)=> {
      console.log('params ', params);
      this.period_id = params.period_id;
      this.student_external_id = params.external_id;
      
    });

    this.route?.parent?.parent?.parent?.params?.subscribe((params)=> {
      console.log('params ', params);
      this.discipline_external_id = params.id      
    });    
  }

  // seta os nomes das categorias de cada nota
  setUpGrades(student:any){

    console.log('student ', student);
    
    student?.categories.forEach((category: any)=>{
      category[category.name] = [];
      student?.grades.forEach((grade: any)=>{
        // inclui o nome da categoria dentro do 
        // objeto de cada nota
        if (category.id == grade.grade_category_id) {
          // student[category.name].push(grade);
          category[category.name].push(grade)
          // grade['category_name'] = category.name;
          // grade['category_weight'] = category.weight
        }
      })
    })      

    // console.log('student ', student);
    
  }   

  getGradesChanges(){
    this.gradesService.grades$.subscribe(()=>{
      
      this.getGrades(this.period_id);
    });
  }

  getGrades(period_id: any){
    // this.loading = true;
    this.gradesService.getGradesList(period_id)
    .pipe(
      finalize(()=>{

      })
    )
    .subscribe((period:any)=>{
      this.studentSetUp = period?.students.find((student: any)=>{return student.external_id == this.student_external_id})
      this.studentSetUp['period'] = period.period;
      this.getPeriodsList();
    })
  }

  getPeriodsList(){
    this.gradesService.getPeriodsList(this.discipline_external_id).subscribe((periods:any)=>{
      this.periods = periods;
      let student : any = {};
      this.studentSetUp['categories'] = this.periods.find((period: any)=>{return period?.id == this.period_id})?.categories;
      this.studentSetUp['calculation_type'] = this.periods.find((period: any)=>{return period?.id == this.period_id})?.calculation_type;
      console.log('this.studentSetUp ', this.studentSetUp);
      
      this.setUpGrades(this.studentSetUp);
      this.student = this.studentSetUp;
      console.log('this.periods ', this.periods);
      console.log('this.student ', this.student);
    })
  }  

}
