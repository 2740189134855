<!-- MODAL Preview Google Forms -->
<app-generic-modal
    [minWidth]="'30%'"
    [width]="'740px'"
    [isVisible]="isModalOpen"
>
    <div class="md:pt-12 md:px-4 bg-customWhite-default rounded-md">
        <!-- iframe -->
        <iframe
            *ngIf="filePreviewUrl" 
            style="
                position: relative;
                min-height: calc(100vh - 230px);
                width: 100%;
            "
            [src]="filePreviewUrl | safeUrl"
            allowfullscreen
        ></iframe>

        <!--footer-->
        <div class="flex items-center justify-center p-6">
            <button
                class="bg-customWhite-default text-customBlue-default border border-customBlue-default rounded-md font-semibold px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                style="width: 152px"
                (click)="closeModal()"
            >
                {{ i18n.planner_back }}
            </button>
        </div>
    </div>
</app-generic-modal>
