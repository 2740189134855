<div style="width: 33px; height: 33px">
    <div *ngIf="status === 'presence'"
      (click)="handleChangeStatus(status)"
      class="image_status btn-animate icon-present"
      alt="edit"></div>
    <div *ngIf="status === 'absent'"
      (click)="handleChangeStatus(status)"
      class="image_status btn-animate icon-absent"
      alt="edit"></div>
    <div *ngIf="status === '1/2_presence'"
      (click)="handleChangeStatus(status)"
      class="image_status btn-animate icon-neutral"
      alt="edit"></div>
</div>
