import {
    Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ListItem, PostDetailedPlanning, Resources } from 'src/app/pages/content-planner/interfaces/detailed-planning.interface';
import { ResponseModules } from 'src/app/pages/content-planner/interfaces/modules.interface';
import { ContentPlannerService } from 'src/app/pages/content-planner/services/content-planner.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-detailed-estimated',
    templateUrl: './detailed-estimated.component.html',
    styleUrls: ['./detailed-estimated.component.scss']
})
export class DetailedEstimatedComponent implements OnInit {
    @ViewChildren('multiSelectElement') multiSelectElements: QueryList<ElementRef> | undefined;
    resourcesList: Resources[] = [];
    activitiesList: Resources[] = [];
    modulesList: ResponseModules[] = [];
    materialsList: ListItem[] = [];
    proceduresList: ListItem[] = [];
    planningExternalId = '';
    type: 'class_to_class' | 'estimated' | '' = '';
    form: FormGroup;
    isVisibleSuccessPlanningModal = false;
    i18n: any = {};

    constructor(private route: ActivatedRoute, private fb: FormBuilder, private contentPlannerService: ContentPlannerService, private platformModalService: PlatformModalsService, private sharedService: SharedService) {
        this.createFormModule();
    }

    ngOnInit(): void {
        this.getTraductions();
        this.getParamsFromRoute();
        this.getResources();
        this.getModules();
        this.getListItems();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.updateDynamicMaxWidth();
    }

    getTraductions(): void {
        this.i18n = this.sharedService.getTranslationsOf('ContentPlanner');
    }

    getParamsFromRoute(): void {
        this.planningExternalId = this.route.snapshot.params.planning_external_id;
        this.type = this.route.snapshot.params.type;
    }

    createFormModule(): void {
        this.form = this.fb.group({
            modules: ['', Validators.required],
            number_estimated: ['', Validators.required],
            description: ['', Validators.required],
            resources: [[]],
            activities: [[], Validators.required],
            materials: [[], Validators.required],
            procedures: [[], Validators.required],
        });
    }

    getResources(): void {
        this.resourcesList = [
            {
                name: this.i18n.planner_content_file,
                type: 'File',
                value: 'rsc_file_enabled'
            },
            {
                name: this.i18n.planner_content_link,
                type: 'Link',
                value: 'rsc_link_enabled'
            },
            {
                name: this.i18n.planner_content_youtube,
                type: 'Youtube',
                value: 'rsc_youtube_enabled'
            },
            {
                name: this.i18n.planner_content_textpage,
                type: 'TextPage',
                value: 'rsc_textpage_enabled'
            },
            {
                name: this.i18n.planner_content_scorm,
                type: 'Scorm',
                value: 'rsc_scorm_enabled'
            },
            {
                name: this.i18n.planner_content_lti,
                type: 'LTI',
                value: 'rsc_lti_enabled'
            },
            {
                name: this.i18n.planner_content_conference,
                type: 'Conference',
                value: 'rsc_conference_enabled'
            },
            {
                name: this.i18n.planner_content_external_quiz,
                type: 'UploadQuiz',
                value: 'rsc_external_quiz_enabled'
            },
        ];

        this.activitiesList = [
            {
                name: this.i18n.planner_content_docs_assignment,
                type: 'DocsAssignment',
                value: 'rsc_docs_assignment_enabled'

            },
            {
                name: this.i18n.planner_content_sheets_assignment,
                type: 'SheetsAssignment',
                value: 'rsc_sheets_assignment_enabled'
            },
            {
                name: this.i18n.planner_content_upload_assignment,
                type: 'UploadAssignment',
                value: 'rsc_upload_assignment_enabled'
            },
            {
                name: this.i18n.planner_content_internal_quiz,
                type: 'InternalQuiz',
                value: 'rsc_internal_quiz_enabled'
            },
        ];
    }

    getModules(): void {
        if (this.planningExternalId) {
            this.contentPlannerService.getModules(this.planningExternalId).subscribe({
                next: (response) => {
                    this.modulesList = response.plannings_modules;
                },
                error: () => {
                    this.modulesList = [];
                    this.form.controls.modules.setValue('error');
                }
            });
        }
    }

    getListItems(): void {
        this.contentPlannerService.getListItems().subscribe({
            next: (response) => {
                this.materialsList = response.material;
                this.proceduresList = response.procedure;
            },
            error: () => {
                this.materialsList = [];
                this.proceduresList = [];
            }
        });
    }

    controlModalSuccess(value: boolean): void {
        if (typeof value === 'boolean') {
            this.isVisibleSuccessPlanningModal = value;
        }
    }

    validateEstimatedClass(EstimatedInput: HTMLInputElement): void {
        if (EstimatedInput) {
            let numericValue = EstimatedInput.value.replace(/[^0-9]/g, '');
            numericValue = numericValue.replace(/^0*(\d+)/, '$1');

            if (numericValue === '0') {
                numericValue = '';
            }

            this.form.controls.number_estimated.setValue(numericValue);
        }
    }

    setResourcesItem(value: string): void {
        if (this.form.controls.resources.value.includes(value)) {
            const itemIndex = this.form.controls.resources.value.indexOf(value);
            this.form.controls.resources.value.splice(itemIndex, 1);
        } else {
            this.form.controls.resources.value.push(value);
        }
    }

    resetForm(moduleExternalId: string): void {
        this.form.reset({
            modules: moduleExternalId,
            number_estimated: '',
            description: '',
            resources: [],
            activities: [],
            materials: [],
            procedures: []
        });
    }

    updateDynamicMaxWidthTimeout(): void {
        setTimeout(() => this.updateDynamicMaxWidth(), 500);
    }

    updateDynamicMaxWidth(): void {
        if (this.multiSelectElements) {
            this.multiSelectElements.forEach((e: ElementRef) => {
                const containerWidth = e.nativeElement.parentElement.parentElement.offsetWidth;
                if (window.innerWidth < 640) {
                    e.nativeElement.style.maxWidth = `${containerWidth}px`;
                } else if (window.innerWidth > 640 && window.innerWidth < 768) {
                    e.nativeElement.style.maxWidth = `${(containerWidth / 2) - 8}px`;
                } else {
                    e.nativeElement.style.maxWidth = `${(containerWidth / 3) - 8}px`;
                }
            });
        }
    }

    onSubmit(): void {
        if (this.form.valid && this.form.controls.resources.value.length) {
            const moduleExternalId = this.form.controls.modules.value;
            const payload: PostDetailedPlanning = {
                type: this.type,
                estimated_classes: parseFloat(this.form.controls.number_estimated.value),
                planning_classes: [
                    {
                        description: this.form.controls.description.value,
                        rsc_file_enabled: this.form.controls.resources.value.includes('rsc_file_enabled'),
                        rsc_link_enabled: this.form.controls.resources.value.includes('rsc_link_enabled'),
                        rsc_youtube_enabled: this.form.controls.resources.value.includes('rsc_youtube_enabled'),
                        rsc_textpage_enabled: this.form.controls.resources.value.includes('rsc_textpage_enabled'),
                        rsc_internal_quiz_enabled: this.form.controls.activities.value.includes('rsc_internal_quiz_enabled'),
                        rsc_scorm_enabled: this.form.controls.resources.value.includes('rsc_scorm_enabled'),
                        rsc_lti_enabled: this.form.controls.resources.value.includes('rsc_lti_enabled'),
                        rsc_conference_enabled: this.form.controls.resources.value.includes('rsc_conference_enabled'),
                        rsc_external_quiz_enabled: this.form.controls.resources.value.includes('rsc_external_quiz_enabled'),
                        rsc_docs_assignment_enabled: this.form.controls.activities.value.includes('rsc_docs_assignment_enabled'),
                        rsc_sheets_assignment_enabled: this.form.controls.activities.value.includes('rsc_sheets_assignment_enabled'),
                        rsc_upload_assignment_enabled: this.form.controls.activities.value.includes('rsc_upload_assignment_enabled'),
                        material: this.form.controls.materials.value,
                        procedure: this.form.controls.procedures.value
                    }]
            };

            this.contentPlannerService.postDetailedPlanning(this.planningExternalId, moduleExternalId, payload).subscribe({
                next: () => {
                    this.resetForm(moduleExternalId);
                    this.getResources();
                    this.controlModalSuccess(true);
                },
                error: () => {
                    this.platformModalService.toggle('message', this.i18n.planner_error_create_planning_module, 'close');
                }
            });
        }
    }
}
