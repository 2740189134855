import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform {
    transform(value: any, strToReplace: string, replacementStr: string): unknown {
        if (!value || !strToReplace || !replacementStr) {
            return value;
        }

        return value.replace(new RegExp(strToReplace, 'g'), replacementStr);
    }
}
