import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';

import { ModalConfirmationComponent } from './modal-confirmation.component';
import { GenericModalModule } from '../modals/generic-modal/generic-modal.module';



@NgModule({
  declarations: [
    ModalConfirmationComponent
  ],
  exports: [
    ModalConfirmationComponent
  ],
  imports: [
    SharedModule,
    GenericModalModule
  ]
})
export class ModalConfirmationModule { }
