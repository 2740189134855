import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ChromeExtensionServiceService } from 'src/app/pages/chromebook-extension/chrome-extension-service.service';
import { LoginService } from 'src/app/pages/login/login.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

import { TranslateServiceLocale } from '../translate/translate.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    @Output() onCloseMenu = new EventEmitter();

    user: any;
    selectedLanguage: string;
    otherLanguages: any;
    i18n: any = {};
    sidebarStateOpen: boolean;
    activeURL: string;
    userIsImpersonating = false;
    canShowChromeExtensionDashboard = false;
    isGoogle = false;

    constructor(
        private element: ElementRef,
        private router: Router,
        private sharedService: SharedService,
        private loginService: LoginService,
        private languageService: TranslateServiceLocale,
        private translateServiceLocale: TranslateServiceLocale,
        private chromeExtensionService: ChromeExtensionServiceService,
        private localStorageService: LocalStorageService,
        private platModalService: PlatformModalsService
    ) {}

    ngOnInit() {
        this.getUser();
        this.routeChangeListener();
        this.activeIconPage();
        setTimeout(this.onResize.bind(this), 1000);
        this.getTranslations();
        this.getLanguageInUse();
        this.canShowChromeExtensionDashboardIcon();
        this.isGoogle = this.localStorageService.isGoogle();
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Sidebar'),
            ...this.sharedService.getTranslationsOf('Reports'),
            ...this.sharedService.getTranslationsOf('ChromebookExtension'),
            ...this.sharedService.getTranslationsOf('ContentPlanner'),
        };
    }

    getLanguageInUse() {
        const currentLanguage = this.sharedService.getSelectedLanguage();
        localStorage.setItem('currentLanguage', currentLanguage);
        const allLanguages = JSON.parse(localStorage.getItem('languages') || '');
        const selectedLanguageIndex = allLanguages.findIndex(
            (lang) => lang.locale === currentLanguage
        );

        // this.updateTranslateUser(this.user, currentLanguage);
        this.selectedLanguage = allLanguages[selectedLanguageIndex].name;
        this.otherLanguages = allLanguages.filter(
            (lang) => lang.locale !== currentLanguage
        );
    // this.updateTranslateUser(this.user, currentLanguage)
    }

    switchLanguage($event, locale) {
        $event.stopPropagation();
        console.log('LOCALE LANGUAGE TESTE: ', locale);

        // this.sharedService.getTranslationsFile(locale);
        const uri = localStorage.getItem('gcsInfraPath');
        this.translateServiceLocale.getTranslationsFile(uri, locale).subscribe({
            next: (file) => {
                localStorage.setItem('translations', JSON.stringify(file));
                this.sharedService.setSelectedLanguage(locale);
                this.updateTranslateUser(locale);
            },
            error: (error) => {
                throw new Error('Error while trying to get translations file');
            },
        });
    }

    updateTranslateUser(locale: any) {
        let language = locale;

        locale == 'pt' ? (language = 'pt-BR') : (language = locale);

        const params = {
            locale: language,
        };

        this.languageService.patchUserLanguage(params).subscribe({
            next: (res) => {
                console.log('Language:', res);
                location.reload();
            },
            error: (err) => {
                throw new Error(`Error while switch language user in sidebar: ${err}`);
            },
            complete: () => {
                console.log('language user patch');
            },
        });
    }

    getUser() {
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;
        });
    }

    @HostListener('click', ['$event'])
    onAsideclick($event: any) {
        $event.stopPropagation();

        const $allAsideBtns = [
            ...this.element.nativeElement.querySelectorAll(
                '.aside__btn:not(.aside__languages-btn)'
            ),
        ];
        const $aside = this.element.nativeElement.querySelector('#aside');

        if ($allAsideBtns.some((btn) => $event.target === btn)) {
            $allAsideBtns.forEach((btn) => btn.classList.remove('active'));
            $event.target.classList.add('active');
            $aside.classList.contains('active')
                ? setTimeout(() => {
                    this.cooldownTooltip();
                    this.deactivateAside();
                }, 200)
                : null;
        }
    }

    // It defines wich icon should start with the 'active' class
    activeIconPage() {
        setTimeout(() => {
            // It filters the URL string
            const filteredUrl = this.router.url.replace(/^\//, '');
            const currentMainPage: any = filteredUrl.split('/').shift(); // ex: 'dashboard'

            // remove the all active buttons, if there are any
            const $allAsideBtns = [
                ...this.element.nativeElement.querySelectorAll('.aside__btn'),
            ];

            $allAsideBtns.forEach((btn) => {
                const shouldBeActive = btn.classList.contains(currentMainPage);

                if (shouldBeActive) {
                    btn.classList.add('active');
                } else {
                    btn.classList.remove('active');
                }

                btn.blur();
            });
        }, 150);
    }

    toggleAside(string?: string) {
        if (string) this.onCloseMenu.emit();

        const $aside = this.element.nativeElement.querySelector('#aside');
        const $invisibleFullPageFiv = this.element.nativeElement.querySelector(
            '.invisible-full-page-div'
        );
        $aside.classList.toggle('active');
        $invisibleFullPageFiv.classList.toggle('active');

        if (!$aside.classList.contains('active')) this.toggleLanguage(null, 'close');
    }

    deactivateAside() {
        const $aside = this.element.nativeElement.querySelector('#aside');
        const $invisibleFullPageFiv = this.element.nativeElement.querySelector(
            '.invisible-full-page-div'
        );
        $aside.classList.remove('active');
        $invisibleFullPageFiv.classList.remove('active');
        this.onCloseMenu.emit();
        // if aside is closed, close the language section too!
        if (!$aside.classList.contains('active')) this.toggleLanguage(null, 'close');
    }

    cooldownTooltip() {
        const $allAsideTooltips = [
            ...this.element.nativeElement.querySelectorAll('.aside__tooltip'),
        ];

        $allAsideTooltips.forEach(($tooltip) => {
            $tooltip.classList.add('cooldown');
            setTimeout(() => {
                $tooltip.classList.remove('cooldown');
            }, 900);
        });
    }

    onResize($event?) {
        if ($event) $event.stopPropagation();

        const $pagesWrapper = this.element.nativeElement.querySelector(
            '.aside__pages-wrapper'
        );
        const $aside = this.element.nativeElement.querySelector('#aside');
        const $footer = this.element.nativeElement.querySelector('.footer');
        const $footerTooltip = this.element.nativeElement.querySelector(
            '.footer .aside__tooltip'
        );

        if ($pagesWrapper.scrollHeight > $pagesWrapper.clientHeight) {
            $pagesWrapper.style = 'overflow-y: scroll; overflow-x: clip;';
            $aside.style = 'width: 88px';
            $footer.style = 'padding-right: 24px';
            $footerTooltip.style = 'display: none';
        } else {
            $pagesWrapper.style = 'overflow-y: auto; overflow-x: clip;';
            $aside.style = 'width: 78px';
            $footer.style = 'padding-right: 14px';
            $footerTooltip.style = 'display: block';
        }
    }

    routeChangeListener() {
        this.sharedService.CallFunctionOnRouteChange(
            this.activeIconPage.bind(this)
        );
    }

    toggleLanguage($event?, param?) {
        if ($event) $event.stopPropagation();

        const $languageButton = this.element.nativeElement.querySelector('.language__btn');
        const $languageArrow = $languageButton.querySelector('.language__arrow');
        const $aside = $languageButton.closest('#aside');

        if (param === 'close') {
            $languageButton.classList.remove('expanded');
            $languageArrow.classList.remove('active');
        } else {
            // if the aside is close, open it!
            if (!$aside.classList.contains('active')) this.toggleAside('send');

            $languageButton.classList.toggle('expanded');
            $languageArrow.classList.toggle('active');
        }

        if ($languageButton.classList.contains('expanded')) {
            const buttonHeight = $languageButton.scrollHeight;
            $languageButton.style.height = `${buttonHeight}px`;
        } else {
            $languageButton.style.height = '50px';
        }

        setTimeout(this.onResize.bind(this), 400);
    }

    canShowChromeExtensionDashboardIcon(): void {
        if (!this.sharedService.checkPermission('chromebook_dashboard', 'list') || !Object.keys(this.user).length) return;

        this.chromeExtensionService.canShowChromeExtensionDashboard().subscribe({
            next: (response) => {
                if (response) {
                    this.canShowChromeExtensionDashboard = response.show_chrome_extension_dashboard;
                }
            },
            error: (err) => {
                this.platModalService.toggle('message', err?.error?.error, 'close');
            }
        });
    }

    openModalUseTerms() {
        this.loginService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: true });
    }
}
