import { Component, Input, OnInit } from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
  selector: 'adm-user-card',
  template: `
  <div class="users-wrapper">
    <div class="user-col">
      <div class="user-col__number">
        <div class="user-col__number-text ">{{cardNumber}}</div>
      </div>
      <div class="user-col__name" >
          <button id="tooltip-adm" (click)="showModalMessage(explanation)">
            <div class="icon-interrogation"></div>
            <span id="tooltip-box">{{ explanation }}</span>
          </button>
          <div class="user-col__name-text">
            {{cardLabel}}
          </div>
      </div>
    </div>
  </div>
  <app-message-modal></app-message-modal>
  `,
  styleUrls: ['./adm-styles.scss'],
})
export class AdmUserCardComponent implements OnInit {
  @Input() userStatsLabel: any = [];
  @Input() userStatsNumber: any = [];
  @Input() i18n: any = [];

  cardLabel: string;
  cardNumber: number;
  explanation: string;

  constructor(
    private platModalService: PlatformModalsService,
  ) { }

  ngOnInit(): void {
    this.chooseUserStats(this.userStatsLabel)
  }

  chooseUserStats(userStatsLabel: any) {
    switch (userStatsLabel) {
      case 'active':
          this.cardLabel = this.i18n.dash_active_users
          this.cardNumber = this.userStatsNumber
          this.explanation = this.i18n.dash_explanation_active_users
          break;
      case 'new':
          this.cardLabel = this.i18n.dash_new_users
          this.cardNumber = this.userStatsNumber
          this.explanation = this.i18n.dash_explanation_new_users
          break;
      case 'total':
          this.cardLabel = this.i18n.dash_registered_users
          this.cardNumber = this.userStatsNumber
          this.explanation = this.i18n.dash_explanation_registered_users
        break;
      case 'last_week':
          this.cardLabel = this.i18n.dash_last_week
          this.cardNumber = this.userStatsNumber
          this.explanation = this.i18n.dash_explanation_last_week
        break;
    }
  }

  showModalMessage(message: string): void {
    this.platModalService.toggle(
      'message',
      {
        message: message,
        custom_icon: 'attention-icon',
        icon_color: '#233674',
      },
      {
        finally: () => {
          this.platModalService.close('message');
        },
      }
    )
  }

}
