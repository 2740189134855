import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-buttons-footer',
    templateUrl: './buttons-footer.component.html',
    styleUrls: ['./buttons-footer.component.scss']
})

export class ButtonsFooterComponent {
    @Input() buttons: ButtonConfig[] = [];
    @Output() buttonClick = new EventEmitter();

    onButtonClick(button: ButtonConfig) {
        this.buttonClick.emit(button);
    }
}

export interface ButtonConfig {
    label: string;
    style: string;
    disabled: boolean;
    onClick?: () => void;
    type?: string;
}
