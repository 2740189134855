import {
    animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style
} from '@angular/animations';
import {
    AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter,
    Input, Output, QueryList, ViewChild, ViewChildren
} from '@angular/core';

import { CarouselItemDirective } from './carousel-item.directive';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit {
    @ContentChildren(CarouselItemDirective) items : QueryList<CarouselItemDirective>;
    @ViewChildren('carouselItem', { read: ElementRef }) itemsElements: QueryList<ElementRef>;
    @ViewChild('carousel') private carousel : ElementRef;
    @Input() timing = '250ms ease-in';
    @Input() slidesToShow = 1;
    @Input() showSlideIndex = 1;
    @Input() carouselWidth: ElementRef;

    @Output() emitIsNextDisabled = new EventEmitter();
    @Output() emitIsPrevDisabled = new EventEmitter();

    public player : AnimationPlayer;
    public itemWidth : number;
    public isNextDisabled: boolean;
    public isPrevDisabled: boolean;
    carouselWrapperStyle = {};

    constructor(
        private builder : AnimationBuilder,

    ) {}

    ngAfterViewInit() {
        this.checkIsNextDisabled();
        this.checkIsPrevDisabled();

        if (this.carouselWidth && this.carouselWidth.nativeElement) {
            this.itemWidth = this.carouselWidth.nativeElement.clientWidth / this.slidesToShow;
            this.carouselWrapperStyle = {
                width: `${this.itemWidth * this.slidesToShow}px`
            };

            if (this.showSlideIndex !== 1) {
                const offset = this.showSlideIndex * this.itemWidth;
                this.jumpToSlide(offset);
            }
        }
    }

    jumpToSlide(offset) {
        const myAnimation : AnimationFactory = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
    }

    next() {
        if (this.showSlideIndex + this.slidesToShow === this.items.length) return;
        this.isNextDisabled = false;
        this.showSlideIndex += this.slidesToShow;

        const offset = this.showSlideIndex * this.itemWidth;
        const myAnimation : AnimationFactory = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
        this.checkIsPrevDisabled();
        this.checkIsNextDisabled();
    }

    prev() {
        if (this.showSlideIndex === 0) return;

        this.showSlideIndex -= this.slidesToShow;

        const offset = this.showSlideIndex * this.itemWidth;
        const myAnimation : AnimationFactory = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
        this.checkIsPrevDisabled();
        this.checkIsNextDisabled();
    }

    checkIsNextDisabled() {
        this.isNextDisabled = false;
        if (this.showSlideIndex + this.slidesToShow === this.items.length) {
            this.isNextDisabled = true;
        }
        this.emitIsNextDisabled.emit(this.isNextDisabled);
        return this.isNextDisabled;
    }

    checkIsPrevDisabled() {
        this.isPrevDisabled = false;
        if (this.showSlideIndex === 0) {
            this.isPrevDisabled = true;
        }
        this.emitIsPrevDisabled.emit(this.isPrevDisabled);
        return this.isPrevDisabled;
    }

    buildAnimation(offset) {
        return this.builder.build([
            animate(this.timing, style({ transform: `translateX(-${offset}px)` }))
        ]);
    }
}
