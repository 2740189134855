import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../grades.service';

@Component({
  selector: 'app-grades-periods-list',
  templateUrl: './grades-periods-list.component.html',
  styleUrls: ['./grades-periods-list.component.scss']
})
export class GradesPeriodsListComponent implements OnInit {


  user: any;

  constructor(
    private sharedSerivce: SharedService
  ) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser(){
    this.sharedSerivce.getUser().subscribe((user: any)=>{
      this.user = user;
    })
  }

}
