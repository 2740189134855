<div class="main">
    <div class="bar-container">
        <average-performance-class [adminData]="adminData" [i18n]="i18n"></average-performance-class>
        <!--<average-time-use [adminData]="adminData" [i18n]="i18n"></average-time-use>-->
    </div>
    <div class="bottom-container">
        <content-type [adminData]="adminData" [i18n]="i18n"></content-type>
        <structure-resume [adminData]="adminData" [i18n]="i18n"></structure-resume>
        <active-users [adminData]="adminData" [i18n]="i18n"></active-users>
    </div>
</div>
