import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../../../grades.service';

@Component({
  selector: 'app-student-grades-periods-list',
  templateUrl: './student-grades-periods-list.component.html',
  styleUrls: ['./student-grades-periods-list.component.scss'],
})
export class StudentGradesPeriodsListComponent implements OnInit {
  // define os estilos do custom-dropdown
  defaultStylization = {
    borderWidth: 1,
    closed: {
      borderColor: '#E3E4E4',
      textColor: '#233674',
      iconColor: '#233674',
      fontWeight: '700',
      backgroundColor: 'rgba(54, 86, 191, 0.1)',
    },
    expanded: {
      borderColor: '#F4F4F4',
      textColor: '#233674',
      iconColor: '#233674',
      fontWeight: '700',
      backgroundColor: 'rgba(54, 86, 191, 0.1)',
    },
  };

  discipline_ext_id: any;
  periods: any[] = [];
  i18n: any = [];
  user: any = {};

  constructor(
    private router: Router,
    private gradesService: GradesService,
    private sharedSerivce: SharedService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getTranslations();
    this.getDisciplineId();
    this.getPeriods();
  }

  getTranslations() {
    this.i18n = this.sharedSerivce.getTranslationsOf('Grades');
  }

  getDisciplineId() {
    this.discipline_ext_id = this.router?.url.split('/')[2];
  }

  iterator: number = 0;
  maxSizePeriods: any;

  getPeriods() {
    this.gradesService
      .getPeriodsList(this.discipline_ext_id)

      .subscribe((periods: any) => {
        // this.iterator = 0;
        console.log('aqui', periods)
        this.periods = periods;
        this.maxSizePeriods = this.periods.length;
        if (this.maxSizePeriods > 0) {
          this.getGrades(this.periods[this.iterator].id, this.iterator);
        }
      });
  }

  getGrades(period_id: any, iterator: any) {
    // this.loading = true;
    this.gradesService
      .getGradesList(period_id)
      .pipe(finalize(() => {}))
      .subscribe((grades: any) => {
        this.periods[iterator]['student'] = grades.students[0];
        this.setUpGrades(this.periods[iterator]);
        this.iterator++;

        if (this.iterator < this.maxSizePeriods) {
          this.getGrades(this.periods[this.iterator].id, this.iterator);
        }
      });
  }

  // seta os nomes das categorias de cada nota
  setUpGrades(period: any) {
    period?.student?.grades.forEach((grade: any) => {
      period?.categories.forEach((category: any) => {
        // inclui o nome da categoria dentro do
        // objeto de cada nota
        if (category.id == grade.grade_category_id) {
          grade['category_name'] = category.name;
          grade['category_weight'] = category.weight;
        }
      });
    });
  }

  getUser(): void {
    this.sharedSerivce.getUser().subscribe((user)=> {
      this.user = user;
    })
  }
}
