<section>
    <div
        class="flex flex-col bg-customWhite-default rounded-xl shadow-card p-12 items-center"
        style="height: 82vh"
    >
        <!-- Title -->
        <div class="flex mb-2 items-baseline justify-center">
            <h1
                class="text-customBlue-default font-bold text-18 sm:text-32 mr-1"
            >
                {{ sheetName }}
            </h1>
            <div class="relative">
                <span
                    class="iconify-inline text-18 text-customGray-dark"
                    data-icon="carbon:help"
                ></span>
                <div
                    class="w-60 sm:w-80 absolute bg-customWhite-default p-2 rounded-md shadow-card tooltip-50 opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                >
                    <span class="text-center text-14 text-customGray-dark">
                        {{ i18n.import_spreadsheet_relate_your_spreadsheet }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Subtitle -->
        <div class="text-center justify-center">
            <span class="text-16 text-customGray-dark">
                {{ i18n.import_spreadsheet_config_subtitle }}
            </span>
        </div>

        <!-- Error Message -->
        <div
            *ngIf="errorMessage"
            class="flex border border-inicieColors-orange p-3 mt-8 w-full justify-center items-center bg-inicieColors-orange bg-opacity-10"
        >
            <span
                class="iconify-inline text-inicieColors-orange text-6xl sm:text-24"
                data-icon="carbon:warning-filled"
            ></span>
            <span class="text-12 sm:text-16 text-customGray-dark ml-2">
                {{ errorMessage }}
            </span>
        </div>

        <!-- Table -->
        <div
            *ngIf="!isLoading"
            class="w-full mt-8 overflow-x-auto ounded-t-md event-list-container"
        >
            <table class="w-full rounded-t-md border-collapse">
                <thead>
                    <tr class="text-customWhite-default text-16">
                        <ng-container
                            *ngFor="let header of headerSelect; let i = index"
                        >
                            <th>
                                <select
                                    [(ngModel)]="selectedOptions[i]"
                                    class="bg-customBlue-default text-customWhite-default text-16 font-bold py-2 px-3"
                                    (change)="onSelectChange(i)"
                                    [ngClass]="
                                        i === 0
                                            ? 'rounded-tl-md'
                                            : i === headerSelect.length - 1
                                            ? 'rounded-tr-md'
                                            : ''
                                    "
                                >
                                    <option
                                        *ngFor="let option of header.options"
                                        [value]="option.value"
                                    >
                                        {{ option.label }}
                                    </option>
                                </select>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody class="border border-customGray-mediumGray break-all">
                    <tr *ngFor="let rowItem of rows?.rows">
                        <ng-container
                            *ngFor="let header of headerSelect; let i = index"
                        >
                            <td
                                class="p-4 text-center border border-customGray-mediumGray text-customGray-dark"
                            >
                                {{ rowItem[i] || "" }}
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Buttons Footer -->
        <div
            *ngIf="!isLoading"
            class="mt-8 flex flex-col items-center sm:items-end w-full"
        >
            <div
                class="w-full flex flex-col sm:flex-row items-center sm:items-end justify-center sm:justify-end"
            >
                <button
                    class="bg-customWhite-default text-customBlue-default ml-0 sm:mr-1 sm:w-auto w-40 py-2 border border-customBlue-default mt-3 sm:mt-5 rounded-md"
                    (click)="goBack()"
                    style="min-width: 150px"
                >
                    <span class="text-16 font-bold">
                        {{ i18n.import_spreadsheet_btn_goback }}
                    </span>
                </button>
                <button
                    class="bg-customBlue-default text-customWhite-default w-40 py-2 border mt-3 sm:mt-5 rounded-md"
                    (click)="onSubmit()"
                    [disabled]="errorMessageSelect || rowEmpty"
                    [ngClass]="{
                        'disabled-button': errorMessageSelect || rowEmpty
                    }"
                    type="submit"
                    style="min-width: 150px"
                >
                    <span class="text-16 font-bold">
                        {{ i18n.import_spreadsheet_btn_import }}
                    </span>
                </button>
            </div>
        </div>

        <!-- Loading -->
        <div
            *ngIf="isLoading"
            class="p-7 text-center flex-grow flex items-center justify-center"
        >
            <div class="flex flex-col items-center justify-center gap-2">
                <div class="mx-auto">
                    <app-bullets-loader></app-bullets-loader>
                </div>
                <span class="text-center text-customGray-dark text-16">
                    {{ i18n.import_spreadsheet_wait_generate }}
                </span>
            </div>
        </div>
    </div>
</section>

<app-modal-confirmation
    [i18n]="i18n"
    [messages]="messagesConfirm"
    [link]="linkInitial"
></app-modal-confirmation>
