import { Injectable, } from '@angular/core';
import { WindowRef } from 'src/app/shared/window-ref';

@Injectable({
    providedIn: 'root'
})
export class AccessibilityService {
    // UserWay
    isUserWayChecked = false;

    // VLibras
    isVLibrasChecked = false;

    constructor(private windowRef: WindowRef) {}

    // UserWay
    createUserWayStyleElement(): void {
        const styleUserWay = this.windowRef.nativeWindow.document.createElement('style');
        styleUserWay.id = 'userWay_accessibility';
        this.windowRef.nativeWindow.document.body.appendChild(styleUserWay);
    }

    checkUserWayLocalStorage(): void {
        const currentLanguage = localStorage.getItem('currentLanguage');

        // Desabilita UserWay se o idioma for diferente de pt-BR
        if (currentLanguage !== 'pt-BR') {
            localStorage.setItem('isUserWayEnabled', JSON.stringify(false));
        }

        if (localStorage.getItem('isUserWayEnabled') === null) {
            localStorage.setItem('isUserWayEnabled', JSON.stringify(false));
            this.disableUserWayBtn();
        }

        const isUserWayEnabled = JSON.parse(localStorage.getItem('isUserWayEnabled') || '');

        if (isUserWayEnabled !== null && isUserWayEnabled !== '' && isUserWayEnabled !== undefined) {
            this.isUserWayChecked = isUserWayEnabled;
        }

        this.checkUserWay();
    }

    checkUserWay(): void {
        if (this.isUserWayChecked) {
            this.enableUserWayBtn();
            return;
        }

        this.disableUserWayBtn();
    }

    enableUserWayBtn(): void {
        localStorage.setItem('isUserWayEnabled', JSON.stringify(true));
        this.windowRef.nativeWindow.document.querySelector('#userWay_accessibility').innerText = '';
    }

    disableUserWayBtn(): void {
        localStorage.setItem('isUserWayEnabled', JSON.stringify(false));
        const style = this.windowRef.nativeWindow.document.querySelector('#userWay_accessibility');
        const text = this.windowRef.nativeWindow.document.createTextNode('.userway_p2{display: none}');
        style.appendChild(text);
    }

    // VLibras
    checkVLibrasLocalSotrage(): void {
        const currentLanguage = localStorage.getItem('currentLanguage');

        // Desabilita UserWay se o idioma for diferente de pt-BR
        if (currentLanguage !== 'pt-BR') {
            localStorage.setItem('isVLibrasEnabled', JSON.stringify(false));
        }

        if (localStorage.getItem('isVLibrasEnabled') === null) {
            localStorage.setItem('isVLibrasEnabled', JSON.stringify(false));
            this.disableVLibrasBtn();
        }

        const isVLibrasEnabled = JSON.parse(localStorage.getItem('isVLibrasEnabled') || '');

        if (isVLibrasEnabled !== null && isVLibrasEnabled !== '' && isVLibrasEnabled !== undefined) {
            this.isVLibrasChecked = isVLibrasEnabled;
        }

        this.checkVLibras();
    }

    checkVLibras(): void {
        if (this.isVLibrasChecked) {
            this.enableVLibrasBtn();
            return;
        }

        this.disableVLibrasBtn();
    }

    enableVLibrasBtn(): void {
        localStorage.setItem('isVLibrasEnabled', JSON.stringify(true));
        this.windowRef.nativeWindow.document.querySelector('div[vw]')?.classList.remove('disabled');
        this.windowRef.nativeWindow.document.querySelector('div[vw]')?.classList.add('enabled');
    }

    disableVLibrasBtn(): void {
        localStorage.setItem('isVLibrasEnabled', JSON.stringify(false));
        this.windowRef.nativeWindow.document.querySelector('div[vw]')?.classList.remove('enabled');
        this.windowRef.nativeWindow.document.querySelector('div[vw]')?.classList.add('disabled');
    }
}
