import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-certificate-reports',
  templateUrl: './certificate-reports.component.html',
  styleUrls: ['./certificate-reports.component.scss']
})
export class CertificateReportsComponent implements OnInit, OnChanges {
  certificatesData: any = []

  isEmpty: boolean = false

  i18n: any = [];
  currentLanguage: any;

  @Input() reportsData: any = []

  @Output() screen = new EventEmitter();

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Certificates: ', changes.reportsData.currentValue?.CERTIFICATES?.certificates);
    this.certificatesData = changes.reportsData.currentValue?.CERTIFICATES?.certificates;
    this.test();
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Reports'),...this.sharedService.getTranslationsOf('Modal')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  showBiggerScreen() {
    this.screen.emit('certificate-reports')
    console.log('CHAMANDO CERTIFICATE');
  }

  test() {
    if (Array.isArray(this.reportsData?.CERTIFICATES) == false && this.reportsData?.CERTIFICATES?.certificates == undefined) {
      this.isEmpty = true
      console.log('ENTROU NO TRUE');
    }
    else if (Array.isArray(this.reportsData?.CERTIFICATES) == false && this.reportsData?.CERTIFICATES?.certificates.length == 0) {
      this.isEmpty = true
      console.log('ENTROU NO TRUE');
    }
    else if (this.reportsData?.CERTIFICATES.length == 0) {
      this.isEmpty = true
      console.log('ENTROU NO TRUE');
    }
    else {
      this.isEmpty = false
      console.log('ENTROU NO FALSE');
    }
  }
}
