<div class="flex justify-center items-center h-full" *ngIf="isLoading$ | async">
	<app-bullets-loader></app-bullets-loader>
</div>

<section
	class=""
	*ngIf="!showLoading"
>
	<!-- Card Disciplines -->
	<app-card-student
		[dashData]="studentData"
		[user]="user"
		[i18n]="i18n"
	></app-card-student>

	<app-my-week-tasks
		[i18n]="i18n"
		[contents]="studentData.contents"
        [settings]="studentData.settings"
	></app-my-week-tasks>

	<app-my-tasks
		[i18n]="i18n"
		[dashData]="studentData"
	></app-my-tasks>
	
</section>
