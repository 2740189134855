<div class="pagination-wrapper">
  <div class="pagination">
    <div class="arrows mobile" *ngIf="buttons.length > 1">
      <div class="pagination__arrow left" (click)="backward()" [class.is-disabled]="activePage === 1">
        <i class="arrow-icon pagination__icon left white-filter"></i>
      </div>

      <div class="pagination__arrow right" (click)="forward()" [class.is-disabled]="nextArrowIsDisabled">
        <i class="arrow-icon pagination__icon right white-filter"></i>
      </div>
    </div>

    <div class="pagination__buttons mobile">
      <div class="pagination__button z-index" *ngFor="let button of buttons" [class.is-active]="button.active" (click)="setActivePage(button.number)" [style]="buttonsFontSize">
        {{ button.number }}
      </div>
    </div>

    <div class="pagination__arrow left desktop" *ngIf="buttons.length > 1" (click)="backward()" [class.is-disabled]="activePage === 1">
      <i class="arrow-icon pagination__icon left white-filter"></i>
    </div>

    <div class="pagination__buttons desktop">
      <div class="pagination__button z-index" *ngFor="let button of buttons" [class.is-active]="button.active" (click)="setActivePage(button.number)" [style]="buttonsFontSize">
        {{ button.number }}
      </div>
    </div>

    <div class="pagination__arrow right desktop" *ngIf="buttons.length > 1" (click)="forward()" [class.is-disabled]="nextArrowIsDisabled">
      <i class="arrow-icon pagination__icon right white-filter"></i>
    </div>
  </div>

  <div class="go-to-page" *ngIf="!noArrows && false">
    <input #pageInput [(ngModel)]="input" type="text" class="input" placeholder="n°" (input)="formatInput($event)">
    <div class="go-button" (click)="setActivePage(input)">
      <i class="magnifier-icon go-button__icon"></i>
    </div>
  </div>
</div>
