<app-generic-modal [isVisible]="true">
  
  <div class="container flex items-center justify-center flex-col p-7 gap-7">
    <!--header-->
    <div
      class="border-b border-solid border-customBlue-superLight rounded-t emitHeader"
    >
      <h3 class="text-2xl font-semibold emitHeader" style="color: #233674">
        {{i18n.disciplines_emit}}
      </h3>
      <div class="text-base text-customGray-dark mt-3 mb-2 px-14">
        {{i18n.disciplines_emit_select_student}}
      </div>
    </div>
    <!--body-->
    <div>
      <div class="ml-2 mt-4">
        <div class="flex mb-3 labelEmitCertificate">
          <div class="ml-2">{{i18n.disciplines_emit_status}}</div>
          <div class="ml-8">{{i18n.disciplines_select_name}}</div>
          <div class="ml-24">{{i18n.disciplines_select_percent}}</div>
        </div>
      </div>
      <div class="modalBox">
        <div class="modalContent" [formGroup]="emitCertificateForm">
          <div
            *ngFor="let elegible of elegibles; let i = index"
            class="grid grid-flow-col grid-cols-2 grid-rows-1 py-1 relative text-center nameFont"
          >
            <div class="flex flex-wrap">
              <img
                *ngIf="!elegible.certificate.emitted"
                src="../../../../../assets/svg/certificate/closeBoldButton.svg"
                alt=""
              />
              <!-- <img *ngIf="elegible.certificate.emitted" src="../../../../../assets/svg/certificate/emitted.svg" alt=""> -->
              <div class="ml-12 nameSize" title="{{ elegible.user.name }}">
                {{ elegible.user.name }}
              </div>
            </div>
            <div class="-ml-14">
              {{ elegible.requirements.completion | percent }}
            </div>
            <div style="position: absolute; right: 30px">
              <input
                id="toogle"
                style="height: 1.4rem"
                (change)="postEmitCertificate(elegible.user.external_id, i)"
                formControlName="checked"
                class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
                type="checkbox"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer-->
    <div class="flex items-center justify-center p-3 rounded-b mt-6">
      <!--  <button class="button-save" (click)="savePost()">
        <p>Salvar</p>
      </button> -->
      <div class="ml-3">
        <button
          class="button-save"
          [ngClass]="{
            'disabled:opacity-20 cursor-not-allowed': !buttonUnlocked
          }"
          [disabled]="!buttonUnlocked"
          (click)="savePost()"
        >
          <p>{{i18n.disciplines_emit_button}}</p>
        </button>
      </div>
      <button class="button-cancel" (click)="closeEmitCertificate()">
        <p>{{i18n.disciplines_cancel}}</p>
      </button>
    </div>
  </div>
</app-generic-modal>

