import { Component, EventEmitter, Input, Output } from '@angular/core';
import { settings } from 'cluster';
import { ImportSpreadsheetService } from '../../import-spreadsheet.service';

@Component({
    selector: 'app-modal-resume-view',
    templateUrl: './modal-resume-view.component.html',
    styleUrls: ['./modal-resume-view.component.scss']
})
export class ModalResumeViewComponent {
    @Input() data: any;
    @Input() i18n: any;
    @Input() isVisible: boolean;
    @Output() emitCloseModal = new EventEmitter<void>();

    accordionOpen = false;
    isLoading = false;

    toggleAccordionErro(): void {
        this.accordionOpen = !this.accordionOpen;
    }

    fieldsUsers: any;
    fieldsDisciplines: any;
    fieldsLinking: any;
    fieldsConnections: any;

    constructor(
        private importSpreadsheetService: ImportSpreadsheetService,
    ) { }

    ngOnChanges() {
        if (this.i18n) {
            this.fieldsUsers = {
                name: this.i18n?.import_spreadsheet_option_name,
                email: this.i18n?.import_spreadsheet_option_email,
                cod: this.i18n?.import_spreadsheet_option_cod,
                profile: this.i18n?.import_spreadsheet_option_profile,
                ou: this.i18n?.import_spreadsheet_option_ou,
                phone: this.i18n?.import_spreadsheet_option_phone,
                timecard: this.i18n?.import_spreadsheet_option_cardtime,
                start_date_function: this.i18n?.import_spreadsheet_option_start_date_function,
            };

            this.fieldsDisciplines = {
                cod: this.i18n?.import_spreadsheet_option_cod_discipline,
                name: this.i18n?.import_spreadsheet_option_name_discipline,
                teaching_type: this.i18n?.import_spreadsheet_option_teaching_type,
                segment: this.i18n?.import_spreadsheet_option_segment,
                ou: this.i18n?.import_spreadsheet_option_ou,
            };

            this.fieldsLinking = {
                cod: this.i18n?.import_spreadsheet_option_cod_discipline,
                name: this.i18n?.import_spreadsheet_option_name_discipline,
                profile: this.i18n?.import_spreadsheet_option_profile,
                email: this.i18n?.import_spreadsheet_option_email,
            };

            this.fieldsConnections = {
                cod_disc: this.i18n?.import_spreadsheet_option_cod_discipline,
                name_disc: this.i18n?.import_spreadsheet_option_name_discipline,
                type_teaching: this.i18n?.import_spreadsheet_option_teaching_type,
                segment: this.i18n?.import_spreadsheet_option_segment,
                ou: this.i18n?.import_spreadsheet_option_ou,
                cod_model_disc: this.i18n?.import_spreadsheet_option_cod_model_discipline,
                name_model_disc: this.i18n?.import_spreadsheet_option_import_model_discipline,
                cod_model_cert: this.i18n?.import_spreadsheet_option_cod_model_certificate,
                name_model_cert: this.i18n?.import_spreadsheet_option_name_model_certificate,
                auto_issue: this.i18n?.import_spreadsheet_option_automatic_issue,
                resources_percent: this.i18n?.import_spreadsheet_option_resources_percentage,
                start_date_issue: this.i18n?.import_spreadsheet_option_start_date_issue,
            };
        }
    }

    getErrorLine(error: any): string {
        const { fields } = this.data;
        const { rowIndex } = error;
        let { colIndex } = error;

        if (rowIndex === colIndex) {
            colIndex = 0;
        }

        colIndex = this.transformIndex(colIndex, fields);

        return `${this.i18n?.import_spreadsheet_error_in} ${this.i18n?.import_spreadsheet_line}: ${rowIndex} ${this.i18n?.import_spreadsheet_column}: ${colIndex}`;
    }

    transformIndex(index: number, fields: any) {
        const field = fields[index];
        switch (this?.data?.settings?.value) {
            case 'users':
                return this.fieldsUsers[field];
                break;
            case 'disciplines':
                return this.fieldsDisciplines[field];
                break;
            case 'linking':
                return this.fieldsLinking[field];
                break;
            case 'connections':
                return this.fieldsConnections[field];
                break;
            default:
                return field;
                break;
        }
    }

    closeModal() {
        this.emitCloseModal.emit();
    }

    downloadReport() {
        this.isLoading = true;
        this.importSpreadsheetService.downloadReport(this.data?.id)
            .subscribe({
                next: (blob: Blob) => {
                    this.howToGetTheFile(blob);
                    this.isLoading = false;
                },
                error: (err: any) => {
                    console.log(err);
                    this.isLoading = false;
                    this.importSpreadsheetService.openConfirmModal('error', 'Erro ao realizar o download do relatório');
                }
            });
    }

    howToGetTheFile(blob: Blob) {
        const file = new Blob([blob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');

        window.open(fileURL, '_blank', 'width=800, height=600');
    }
}
