import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TimeService } from 'src/app/services/time-services.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-modal-edit-history',
  templateUrl: './modal-edit-history.component.html',
  styleUrls: ['./modal-edit-history.component.scss']
})
export class ModalEditHistoryComponent implements OnInit, OnChanges {

  @Input() isModalOpen: boolean = false;
  @Input() edit_history: any;
  @Output() sendIsModalOpen = new EventEmitter();
  i18n: any = []

  constructor(
    private timeService: TimeService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.hasOwnProperty("edit_history")) {
        this.edit_history = changes?.edit_history?.currentValue;
      }
  }

  getTranslations() {
    this.i18n = this.sharedService.getTranslationsOf('Forum');
  } 

  getTime(date: any){
    return this.timeService.getTime(date);
  }

  closeModal(){
    this.isModalOpen = false;
    this.sendIsModalOpen.emit(this.isModalOpen)
  }


}
