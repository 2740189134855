import { take } from 'rxjs/operators';
import { ConditionalAndCriteriaService } from './../conditional-and-criteria.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from '@angular/core';
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

const DEFAULT_DURATION = 250;

@Component({
  selector: 'app-conditional-options',
  templateUrl: './conditional-options.component.html',
  styleUrls: ['./conditional-options.component.scss'],
  animations: [
    trigger('collapse', [
      state(
        'true',
        style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
      ),
      state(
        'false',
        style({ height: '0px', visibility: 'hidden', opacity: 0 })
      ),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in')),
    ]),
  ],
})
export class ConditionalOptionsComponent implements OnInit, OnChanges {
  conditonalAdvanceOption: boolean;
  conditionalAdvance: boolean = false;
  collapseOption: boolean = true;
  conditionalForm: UntypedFormGroup;
  contentsSelected: any;
  contentsSelect: any = {};
  conditionalSelectId: any;
  filteredSelect: any = [];

  @Input() i18n: any = [];
  @Input() contentLength: number;
  @Input() contentFormData: any;
  @Input() contentType: string;
  @Input() editMode: boolean;
  @Input() contents: any;

  @Output() selectedOptions = new EventEmitter();

  constructor(
    private conditionalCriteriaService: ConditionalAndCriteriaService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.createConditionalForm();
    this.loadData();
  }

  ngOnChanges() {
    this.receivesContents();
  }

  loadData() {
    const getSetFormData = setInterval(() => {
      if (this.editMode) {
        if (this.contentsSelect !== null && this.contentsSelect !== undefined) {
          if (
            Object.keys(this.contentFormData).length &&
            Object.keys(this.contentsSelect).length
          ) {
            this.loadGeneralData();
            clearInterval(getSetFormData);
          }
        }
      } else {
        if (this.contentsSelect !== null && this.contentsSelect !== undefined) {
          if (Object.keys(this.contentsSelect)?.length) {
            this.loadGeneralData();
            clearInterval(getSetFormData);
          }
        }
      }
    }, 100);
  }

  loadGeneralData(): void {
    this.setValueForm(this.contentFormData);
    this.isShowConditionalAdvanceOption();
    this.filterSelectContents(this.contentsSelect);
  }

  loadsAfterHavingData() {
    // this.conditionalForm.controls['conditionalAdvance'].setValue(false);
  }

  isShowConditionalAdvanceOption() {
    let contentIndex: any = [];
    this.conditionalCriteriaService.loadContentFalse();

    if (this.contentsSelect !== undefined) {
      contentIndex = this.contentsSelect.findIndex(
        (id) => id?.external_id === this.contentFormData?.external_id
      );
    }

    if (this.contentLength != undefined) {

    if (contentIndex == -1 && this.contentLength == 0) {
      this.conditonalAdvanceOption = false;
    }

    if (contentIndex == -1 && this.contentLength > 0) {
      this.conditonalAdvanceOption = true;
    }

    if (contentIndex == 0 && this.contentLength == 0) {
      this.conditonalAdvanceOption = false;
    }

    if (contentIndex > 0 && this.contentLength > 1) {
      this.conditonalAdvanceOption = true;
    }
    if (contentIndex == 0 && this.contentLength == 1) {
      this.conditonalAdvanceOption = false;
    }
    if (contentIndex == 0 && this.contentLength > 1) {
      this.conditonalAdvanceOption = true;
    }


  }


  }

  receivesContents() {
    this.contentsSelect = this.contents[0];
    this.isShowConditionalAdvanceOption();
  }

  filterSelectContents(contentsSelect) {
    if (contentsSelect !== undefined) {
      this.filteredSelect = contentsSelect
        //.filter(({ external_id }) => external_id != this.contentsSelect[0].external_id)
        .filter(
          ({ external_id }) => external_id != this.contentFormData?.external_id
        )
        .map((content) => ({
          id: content.external_id,
          title: content.title,
        }));
    }
  }

  toggleOptions() {
    this.collapseOption = !this.collapseOption;
  }

  setValueForm(contentData) {
    let isConditionalAdvance = contentData.conditional_content_enable;

    if (this.conditionalForm !== undefined) {
      isConditionalAdvance == undefined ? false : isConditionalAdvance;
      this.conditionalForm.controls['hideContent'].setValue(
        contentData?.hide_until_start
      );
      this.conditionalForm.controls['deliveriesUntilTheDate'].setValue(
        contentData?.allow_late_submission
      );
      this.conditionalForm.controls['conditionalAdvance'].setValue(
        isConditionalAdvance
      );
      /* this.conditionalForm.controls['conditionalAdvanceSelect'].setValue(contentData?.conditional_content?.external_id); */
      this.conditionalForm.controls['conditionalAdvanceSelect'].setValue(
        contentData?.conditional_content?.external_id
      );
    }
    if (!this.editMode) {
      this.conditionalForm.controls['conditionalAdvance'].setValue(false);
    }
  }

  createConditionalForm() {
    this.conditionalForm = this.formBuilder.group({
      hideContent: [''],
      //createForum: [''],
      deliveriesUntilTheDate: [''],
      conditionalAdvance: [false],
      conditionalAdvanceSelect: [''],
      evaluativeResource: [''],
    });
    this.conditionalForm?.valueChanges.subscribe((dataForm) => {
      this.selectedOptions.emit(dataForm);
      this.isShowConditionalAdvanceOption();
    });
    this.conditionalForm
      .get('conditionalAdvance')
      ?.valueChanges.subscribe((value) => {
        this.isShowConditionalAdvanceOption();
        this.conditionalAdvance = value;
        if (this.conditionalAdvance == true) {
          this.conditionalForm.controls['conditionalAdvanceSelect'].setValue(
            this.contentFormData?.conditional_content?.external_id
          );
        }
        this.conditionalForm.controls['conditionalAdvanceSelect'].setValue('');
      });
  }

  textCutAnnouncements(limit: any, textAnnouncement: any) {
    let text = textAnnouncement;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }
}
