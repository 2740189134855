import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UsersService } from 'src/app/pages/users/users.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-impersonfication',
  templateUrl: './impersonfication.component.html',
  styleUrls: ['./impersonfication.component.scss']
})
export class ImpersonficationComponent implements OnInit {
  userIsImpersonating: any;
  impersonate: any = [];
  isCardDepersonateOpen: boolean = false;
  i18n: any = [];

  constructor (
    private sharedService: SharedService,
    private usersService: UsersService,
    public platModalService: PlatformModalsService
  ) { }

  ngOnInit(): void {
    this.getImpersonatingStatus();
    this.sharedService.getUser()
      .subscribe((user) => {
        this.impersonate = user;
      })
    
    this.sharedService.setI18n(this, 'Impersonate');
  }

  getImpersonatingStatus(){
    this.sharedService.impersonating.subscribe({
      next: (impersonate: boolean) => {
        this.userIsImpersonating = impersonate;
      }
    })
  }

  openCardDepersonate(){
    this.isCardDepersonateOpen = true;
  }

  depersonificate(){
    this.usersService.depersonificate()
    .pipe(
      finalize(()=>{
      })
    )
    .subscribe({
      next: (depersonificate) => {
        this.sharedService.fnIsImpersonating(depersonificate);
      }, 
      error: (err) => {
        if (err.status == 403) {
          this.platModalService.toggle('message', err.error.message, 'close');
        }
      }
    })
  }
}
