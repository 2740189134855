<div class="bg-customWhite-default content rounded-md h-full">
  <div
    *ngIf="!isThirdCookieEnabled"
    class="bg-inicieColors-orange content rounded-md p-4 text-16 font-semibold bg-opacity-20 text-inicieColors-orange border border-inicieColors-orange"
  >
    <div class="flex flex-col sm:flex-row items-center gap-4">
      <span class="iconify text-32" data-icon="carbon:warning-filled"></span>
      <div class="flex flex-col text-center sm:text-left">
        <p class="text-16 font-semibold">
          {{ i18n?.third_party_cookie_active }}
        </p>

        <p class="text-16 font-semibold">
          {{ i18n?.third_party_cookie_follow_steps }},
          <a href="{{ linkTutorial }}" target="_blank" class="click">
            {{ i18n?.third_party_cookie_this_link }}
          </a>
          {{ i18n?.third_party_cookie_set_config }}
        </p>

        <p class="text-16 font-semibold">
          {{ i18n?.third_party_cookie_after_config }}
          <a href="#" (click)="reload" class="click">
            {{ i18n?.third_party_cookie_click_here }}
          </a>
          {{ i18n?.third_party_cookie_reload_page }}
        </p>
      </div>
    </div>
  </div>
</div>
