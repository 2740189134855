<p-toast [style]="{marginTop: '80px'}"></p-toast>
<section >
  <div class="flex mb-8">
    <h1 class="text-customBlue-default font-bold text-22">{{i18n.ou_organizational_units}}</h1>
      <button class="ml-auto px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" style="background-color: #FFF;border: unset;" (click)="op.toggle($event)">
        <div>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.00051 4C7.93992 4.00121 6.92316 4.38154 6.17321 5.05756C5.42326 5.73359 5.00135 6.65012 5 7.60617H7.03381C7.03381 6.62808 7.91649 5.83333 9.00051 5.83333C10.0845 5.83333 10.9672 6.62808 10.9672 7.60617C10.9672 8.15433 10.4781 8.55217 9.73065 9.09667C9.48648 9.26909 9.25196 9.45234 9.02797 9.64575C8.01309 10.5597 7.9836 11.5304 7.9836 11.6386V12.25H10.0174L10.0164 11.6697C10.0174 11.6551 10.05 11.3159 10.4649 10.9428C10.6174 10.8053 10.8096 10.6678 11.0089 10.523C11.8011 9.94458 13 9.071 13 7.60617C12.9992 6.65013 12.5776 5.73345 11.8277 5.05735C11.0779 4.38125 10.0611 4.00097 9.00051 4ZM7.9836 13.1667H10.0174V15H7.9836V13.1667Z" fill="#484B4D"/>
          <path d="M9 0C4.0374 0 0 4.0374 0 9C0 13.9626 4.0374 18 9 18C13.9626 18 18 13.9626 18 9C18 4.0374 13.9626 0 9 0ZM9 16.2C5.0301 16.2 1.8 12.9699 1.8 9C1.8 5.0301 5.0301 1.8 9 1.8C12.9699 1.8 16.2 5.0301 16.2 9C16.2 12.9699 12.9699 16.2 9 16.2Z" fill="#484B4D"/>
          </svg>
        </div>
      </button>
      <p-overlayPanel #op [showCloseIcon]="false" [style]="{width: '450px'}">
          <ng-template pTemplate>
            <p>{{tooltipHelp}}</p>
            <h5 class="mt-2"><b>{{tooltipHelpTitle}}</b></h5>
            <img class="mt-2" src="../../../assets/png/orgUnits.png" alt="">
          </ng-template>
      </p-overlayPanel>    
    <div >
    </div>
  </div>
  <div style="padding: 15px;height: 100%;overflow: scroll;">
    <p-organizationChart node-header-first [value]="data1" selectionMode="single" [(selection)]="selectedNode" 
        styleClass="company">
        <ng-template let-node pTemplate="person">
            <div class="overflow-hidden">
              <div class="node-header ">
                {{node.label}}
              </div>
            </div>
            <div class="node-content">
                <div>{{node.data.name}}</div>
            </div>
            <div class="absolute button-add-orientation">
              <div class="options-button ">
                  <button *appCheckPermissions="'ou';type:'create'" type="button" (click)="createOrgUnit($event, node)">
                      <svg class="add-svg" width="22" height="22" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12.5" cy="12.5" r="12.5" fill="#E8532C"/>
                      <path d="M13.5547 11.6328H16.375V13.6719H13.5547V16.8594H11.4062V13.6719H8.57812V11.6328H11.4062V8.57812H13.5547V11.6328Z" fill="#F4F4F4"/>
                      </svg>
                  </button>
              </div>
              <div class="options-button animation-option-button hidden">
                  <button *appCheckPermissions="'ou';type:'delete'" type="button" (click)="removeOrgUnit($event, node)">
                      <svg class="trash-svg" width="22" height="22" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <ellipse cx="9.5" cy="9.99916" rx="9.5" ry="9.32143" fill="#E8532C"/>
                      <g clip-path="url(#clip0)">
                      <path d="M7.30794 9.0918C7.41292 9.0918 7.51361 9.13272 7.58784 9.20555C7.66207 9.27839 7.70378 9.37718 7.70378 9.48019V14.1409C7.70378 14.2439 7.66207 14.3427 7.58784 14.4155C7.51361 14.4884 7.41292 14.5293 7.30794 14.5293C7.20296 14.5293 7.10228 14.4884 7.02805 14.4155C6.95381 14.3427 6.91211 14.2439 6.91211 14.1409V9.48019C6.91211 9.37718 6.95381 9.27839 7.02805 9.20555C7.10228 9.13272 7.20296 9.0918 7.30794 9.0918ZM9.28711 9.0918C9.39209 9.0918 9.49277 9.13272 9.56701 9.20555C9.64124 9.27839 9.68294 9.37718 9.68294 9.48019V14.1409C9.68294 14.2439 9.64124 14.3427 9.56701 14.4155C9.49277 14.4884 9.39209 14.5293 9.28711 14.5293C9.18213 14.5293 9.08145 14.4884 9.00721 14.4155C8.93298 14.3427 8.89128 14.2439 8.89128 14.1409V9.48019C8.89128 9.37718 8.93298 9.27839 9.00721 9.20555C9.08145 9.13272 9.18213 9.0918 9.28711 9.0918ZM11.6621 9.48019C11.6621 9.37718 11.6204 9.27839 11.5462 9.20555C11.4719 9.13272 11.3713 9.0918 11.2663 9.0918C11.1613 9.0918 11.0606 9.13272 10.9864 9.20555C10.9121 9.27839 10.8704 9.37718 10.8704 9.48019V14.1409C10.8704 14.2439 10.9121 14.3427 10.9864 14.4155C11.0606 14.4884 11.1613 14.5293 11.2663 14.5293C11.3713 14.5293 11.4719 14.4884 11.5462 14.4155C11.6204 14.3427 11.6621 14.2439 11.6621 14.1409V9.48019Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4323 7.15067C14.4323 7.35669 14.3489 7.55426 14.2004 7.69994C14.052 7.84562 13.8506 7.92746 13.6406 7.92746H13.2448V14.9185C13.2448 15.3306 13.078 15.7257 12.781 16.0171C12.4841 16.3084 12.0814 16.4721 11.6615 16.4721H6.91146C6.49153 16.4721 6.08881 16.3084 5.79187 16.0171C5.49494 15.7257 5.32812 15.3306 5.32812 14.9185V7.92746H4.93229C4.72233 7.92746 4.52097 7.84562 4.3725 7.69994C4.22403 7.55426 4.14062 7.35669 4.14062 7.15067V6.37388C4.14062 6.16787 4.22403 5.97029 4.3725 5.82461C4.52097 5.67894 4.72233 5.5971 4.93229 5.5971H7.70312C7.70312 5.39108 7.78653 5.1935 7.935 5.04783C8.08347 4.90215 8.28483 4.82031 8.49479 4.82031H10.0781C10.2881 4.82031 10.4895 4.90215 10.6379 5.04783C10.7864 5.1935 10.8698 5.39108 10.8698 5.5971H13.6406C13.8506 5.5971 14.052 5.67894 14.2004 5.82461C14.3489 5.97029 14.4323 6.16787 14.4323 6.37388V7.15067ZM6.21321 7.92746L6.11979 7.97329V14.9185C6.11979 15.1245 6.2032 15.3221 6.35167 15.4678C6.50013 15.6135 6.7015 15.6953 6.91146 15.6953H11.6615C11.8714 15.6953 12.0728 15.6135 12.2213 15.4678C12.3697 15.3221 12.4531 15.1245 12.4531 14.9185V7.97329L12.3597 7.92746H6.21321ZM4.93229 7.15067V6.37388H13.6406V7.15067H4.93229Z" fill="white"/>
                      </g>
                      <defs>
                      <clipPath id="clip0">
                      <rect width="12.6667" height="12.4286" fill="white" transform="translate(3.16602 4.82031)"/>
                      </clipPath>
                      </defs>
                      </svg>
                  </button>
              </div>
              <div class="options-button animation-option-button hidden">
                  <button *appCheckPermissions="'ou';type:'edit'" type="button" (click)="editOrgUnit($event, node)">
                  <svg class="edit-svg" width="22" height="22" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <ellipse cx="9.5" cy="9.67885" rx="9.5" ry="9.32143" fill="#E8532C"/>
                  <path d="M12.2737 6.6598L13.282 7.64861L12.2737 6.6598ZM12.9221 5.7887L10.1959 8.46366C10.055 8.60169 9.95895 8.77753 9.91979 8.96904L9.66797 10.2059L10.9285 9.95832C11.1237 9.92002 11.3026 9.82613 11.4436 9.68788L14.1698 7.01291C14.2517 6.93253 14.3167 6.8371 14.361 6.73208C14.4053 6.62705 14.4282 6.51449 14.4282 6.40081C14.4282 6.28713 14.4053 6.17456 14.361 6.06954C14.3167 5.96451 14.2517 5.86908 14.1698 5.7887C14.0878 5.70832 13.9906 5.64455 13.8835 5.60105C13.7765 5.55755 13.6618 5.53516 13.5459 5.53516C13.4301 5.53516 13.3153 5.55755 13.2083 5.60105C13.1013 5.64455 13.004 5.70832 12.9221 5.7887V5.7887Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.4744 11.1395V12.5408C13.4744 12.7885 13.3741 13.0261 13.1955 13.2013C13.017 13.3765 12.7748 13.4749 12.5223 13.4749H7.28604C7.03354 13.4749 6.79138 13.3765 6.61283 13.2013C6.43429 13.0261 6.33398 12.7885 6.33398 12.5408V7.40291C6.33398 7.15515 6.43429 6.91755 6.61283 6.74236C6.79138 6.56717 7.03354 6.46875 7.28604 6.46875H8.71412" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </button>
              </div>                        
            </div>
        </ng-template>
        <ng-template let-node pTemplate="department">
            {{node.label}}
        </ng-template>
    </p-organizationChart>
  </div> 

  <app-modal-org-units [isModalOpen]="isModalOpen" (sendIsModalOpen)="closeModalHistory($event)" (sendGetOrgUnits)="sendGetOrgUnits($event)" ></app-modal-org-units>
</section>

<app-decision-modal></app-decision-modal>
<app-message-modal></app-message-modal>
