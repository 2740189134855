import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../../../grades.service';
import { Subscription } from 'rxjs';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
  selector: 'app-teacher-grades-periods-list',
  templateUrl: './teacher-grades-periods-list.component.html',
  styleUrls: ['./teacher-grades-periods-list.component.scss'],
})
export class TeacherGradesPeriodsListComponent implements OnInit, OnDestroy {
  @ViewChild('displayPanel') displayPanel: ElementRef;
  discipline_external_id: any;
  periods: any;
  period_id: any;
  isModalOpen: boolean = false;
  isModalCategoriesOpen: boolean = false;
  period: any;
  editMode: boolean = false;
  i18n: any = [];
  isLoading: boolean = false;

  subscription: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private gradesService: GradesService,
    private router: Router,
    private sharedSerivce: SharedService,
    private platformModalsService: PlatformModalsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getTranslations();
    this.getDisciplineId();
    let sub1 =this.gradesService.isPeriodUpdated$.subscribe((isPeriodUpdated) => {
      if (isPeriodUpdated) {
        this.getPeriodsList();
      }
    });

    this.subscription.push(sub1);
    this.getPeriodsList();
  }

  getTranslations() {
    this.i18n = this.sharedSerivce.getTranslationsOf('Grades');
  }

  goToDetails(period_id: any) {
    this.router.navigate([`../grades/period/${period_id}`], {
      relativeTo: this.route,
    });
  }

  gotToOverview() {
    this.router.navigate([`../grades/periods/overview`], {
      relativeTo: this.route,
    });
  }

  getDisciplineId() {
    this.discipline_external_id = this.router?.url.split('/')[2];
  }

  openModalPeriod() {
    this.controlButtonPeriodText = true;
    this.isModalOpen = true;
    this.displayPanel?.nativeElement?.click();
  }

  controlButtonPeriodText: boolean = false

  editPeriod(period_id: any) {
    this.controlButtonPeriodText = false;
    this.isModalOpen = true;
    this.editMode = true;
    this.gradesService.period.next(
      this.periods.find((period: any) => {
        return period?.id == period_id;
      })
    );
  }

  openModalCategories(period: any) {
    console.log(period)
    this.period = period;
    this.gradesService.categories$.next(period);
    this.isModalCategoriesOpen = true;
  }

  getStatusModal(event: any) {
    this.isModalOpen = event;
    this.editMode = false;
  }

  getStatusCategoriesModal(event: any) {
    this.isModalCategoriesOpen = event.value;
  }

  getNewPeriod(event: any) {
    this.editMode = false;
    this.periods.push(event);
  }

  getPeriodsList() {
    this.gradesService
      .getPeriodsList(this.discipline_external_id)
      .subscribe({
        next:(periods: any) => {
          this.periods = periods;
          this.gradesService.isPeriodUpdated$.next(false);
          this.gradesService.periods$.next(periods);
          this.isLoading = false;
        },
        error:(err)=>{
          this.isLoading = false;
          this.platformModalsService.toggle('message', err.error.error, 'close');
        }
      });

      
    }

  deletePeriod(grades_id: any) {
    this.gradesService
      .deletePeriod(this.discipline_external_id, grades_id)
      .subscribe((response) => {
        this.gradesService.checkForUpdatesOnPeriod();
      });
  }

  ngOnDestroy(): void {
    if (this.subscription.length) {
      this.subscription.forEach(element => {
        element.unsubscribe();
      });
    }
  }
}
