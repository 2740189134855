<div class="clearfix">

    <div class="c100" [ngClass]="['p' + value, isBig ? 'big' : 'small', color]">
      <span>{{value}}%</span>
      <div class="slice">
          <div class="bar"></div>
          <div class="fill"></div>
      </div>
  </div>
  
  </div>