import {
    animate, AUTO_STYLE, state, style, transition, trigger
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';

import { StatusContent } from '../../interfaces/my-tasks.interface';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-status-contents',
    templateUrl: './status-contents.component.html',
    styleUrls: ['./status-contents.component.scss'],
    animations: [
        trigger('collapse', [
            state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, })),
            state('true', style({ height: '0px', visibility: 'hidden', opacity: 0, })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`))
        ]),
        trigger('rotatedState', [
            state('false', style({ transform: 'rotate(0)' })),
            state('true', style({ transform: 'rotate(-180deg)' })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ]
})
export class StatusContentsComponent implements OnInit {
    @Input() disciplines: any = [];
    @Input() statusContent: StatusContent[] = [];
    @Input() i18n: any = [];
    @Input() titleStatus: any = [];
    @Input() typeStatus = '';

    toggleCollapse = true;
    currentLanguage: any = [];

    constructor(
        private dashboardService: DashboardService,
    ) { }

    ngOnInit(): void {
        this.getLanguages();
    }

    getLanguages() {
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    howManyTasks(tasks: StatusContent[]) {
        return tasks ? tasks?.length : 0;
    }

    routeToLink(task: StatusContent) {
        const sectionExternalId = task.section_external_id;
        const contentType = task.content_type;
        const externalId = task.external_id;
        const disciplineId = task.discipline_external_id;
        const sectionTitle = task.section_title;

        this.dashboardService
            .toTheContent(disciplineId, externalId, sectionExternalId, sectionTitle, contentType);
    }

    getDisciplineName(discipline_external_id: string): string {
        return this.disciplines.find((discipline: { external_id: string; }) => discipline.external_id === discipline_external_id).name;
    }

    returnDatePending(task: StatusContent): string {
        const dateString = `${task.end_date} ${task.end_hour}`;
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        // Check if the date is today
        if (
            inputDate.getDate() === currentDate.getDate()
            && inputDate.getMonth() === currentDate.getMonth()
            && inputDate.getFullYear() === currentDate.getFullYear()
        ) {
            return this.i18n.dash_today;
        }

        // Check if the date is tomorrow
        const tomorrow = new Date(currentDate);
        tomorrow.setDate(currentDate.getDate() + 1);
        if (
            inputDate.getDate() === tomorrow.getDate()
            && inputDate.getMonth() === tomorrow.getMonth()
            && inputDate.getFullYear() === tomorrow.getFullYear()
        ) {
            return this.i18n.dash_tomorrow;
        }

        // Check if the date is after tomorrow
        const afterTomorrow = new Date(currentDate);
        afterTomorrow.setDate(currentDate.getDate() + 2);
        if (
            inputDate.getDate() === afterTomorrow.getDate()
            && inputDate.getMonth() === afterTomorrow.getMonth()
            && inputDate.getFullYear() === afterTomorrow.getFullYear()
        ) {
            return this.i18n.dash_after_tomorrow;
        }

        // Check if the date is yesterday
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        if (
            inputDate.getDate() === yesterday.getDate()
            && inputDate.getMonth() === yesterday.getMonth()
            && inputDate.getFullYear() === yesterday.getFullYear()
        ) {
            return this.i18n.dash_yesterday;
        }

        // If not today or tomorrow, return the day with the weekday, number day, and month name
        const options: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
        };
        return this.returnDateFormatted(inputDate, options);
    }

    returnDateFormatted(inputDate: Date, options: Intl.DateTimeFormatOptions | undefined): string {
        const formattedDate = inputDate.toLocaleDateString(this.currentLanguage, options).replace(':', 'h');
        return formattedDate;
    }

    returnDateLate(task: StatusContent) {
        const dateString = `${task.end_date} ${task.end_hour}`;
        const inputDate = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit'
        };
        return `${this.i18n.dash_late_date_description} ${this.returnDateFormatted(inputDate, options)}`;
    }

    returnDateCompleted(task: StatusContent) {
        const dateString = `${task.completed_at}`;
        const inputDate = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit'
        };
        return `${this.i18n.dash_completed_date_description} ${this.returnDateFormatted(inputDate, options)}`;
    }

    emptyStatusContent(typeStatus: string): string {
        let emptyMessage = '';
        switch (typeStatus) {
            case 'pending':
                emptyMessage = this.i18n.dash_empty_message_pending;
                break;
            case 'late':
                emptyMessage = this.i18n.dash_empty_message_late;
                break;
            case 'no_deadline':
                emptyMessage = this.i18n.dash_empty_message_no_deadline;
                break;
            case 'completed':
                emptyMessage = this.i18n.dash_empty_message_completed;
                break;
            default:
                emptyMessage = '';
                break;
        }

        return emptyMessage;
    }
}
