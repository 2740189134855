<div class="w-full">
  <div class="input-container">
    <div class="flex container_date_input">
      <button (click)="toggleCalendar()" class="inputDate"></button>
      <input
        [inputMask]="dateInputMask"
        [value]="initialDate"
        type="text"
        class="input-contents"
        (click)="toggleCalendar()"
      />
    </div>

    <div
      [ngClass]="{ visbile: enableCalendar, hidden: !enableCalendar }"
      class="relative calendar-container cursor-pointer container_calendar_component"
    >
      <div class="w-full" [style.position]="position">
        <div class="relative">
          <div
            (click)="enableCalendar = false"
            style="
              position: absolute;
              right: 5px;
              z-index: 100;
              height: 0;
              top: 8px;
            "
          >
            <img src="../../../assets/svg/certificate/close.svg" alt="close" />
          </div>
          <p-calendar
            #calendarDate
            [dateFormat]="
              currentLanguage === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
            "
            [showTime]="false"
            [inline]="true"
            [minDate]="minDateValidation"
            [maxDate]="maxDateValidation"
            [readonlyInput]="false"
            [styleClass]="'calendarStyle'"
            [placeholder]="'01/01/2023 20:01'"
            (onSelect)="fnPickDateToInput(calendarDate.value)"
            (onMonthChange)="handleMonthChange($event)"
            [(ngModel)]="date"
          >
            <ng-template pTemplate="date" let-date>
              <!-- <span>{{ date.day }}</span>
              <span
                [ngClass]="{
                  'yellow-dot': hasStatusStarted(date.day),
                  'green-dot': hasStatusFinished(date.day)
                }"
              ></span> -->
              <div
                [ngClass]="{
                  'background_orange': hasStatusStarted(date.day),
                  'background_green': hasStatusFinished(date.day)
                }"
              >
                <span>{{ date.day }}</span>
              </div>
            </ng-template>
          </p-calendar>
        </div>
      </div>
    </div>
  </div>
</div>
