<!-- ATENTION!!! :host it's styled -->
<header class="header" [class.is-visible]="!emptyBank && bankInitiated">
  <div class="filters">
    <div class="search-bar">
      <input
        #searchInputElement
        [(ngModel)]="searchTextInput"
        class="search-bar__input"
        [class.is-active]="searchTextInput.length"
        [placeholder]="i18n.question_bank_search_by_question"
        spellcheck="false"
        autocomplete="false"
      />
      <i class="magnifier-icon icon"></i>
    </div>

    <div
      class="search-tags"
      *ngIf="allTags.length"
      [class.is-active]="tagsSugestionExpanded"
    >
      <input
        [(ngModel)]="searchTagsInput"
        class="search-tags__input"
        [placeholder]="i18n.question_bank_search_by_tag"
        spellcheck="false"
        autocomplete="false"
        (input)="generateTagsSugestions()"
        (click)="generateTagsSugestions()"
        [class.is-active]="tagsSugestionExpanded"
      />
      <i class="tag-icon icon"></i>

      <div
        #tagSugestion
        class="search-tags__sugestions"
        [class.is-invisible]="!tagsSugestionExpanded"
      >
        <div
          class="sugestions__sugestion"
          *ngFor="let tag of sugestionTags; let index = index"
          (click)="selectTag(tag, index)"
        >
          <i class="tag-icon icon"></i>
          {{ tag.text }}
        </div>
      </div>
    </div>

    <div
      #pipeDropdown
      class="pipe-filter"
      [class.is-expanded]="pipeDropdownExpanded"
    >
      <button
        class="pipe-filter__button"
        [class.is-active]="FiltersOn"
        (click)="toggleDropdown('pipe')"
        [title]="i18n.question_bank_filters"
      >
        <i class="filter-outline-icon pipe-filter__icon"></i>
      </button>

      <div class="overlay">
        <div class="button-wrapper"></div>

        <div class="pipe-filters__filters">
          <div class="pipe-filters__filter dates">
            <div class="filter__name">{{ i18n.question_bank_date }}</div>

            <app-single-choice-dropdown
              [items]="dates"
              [block]="true"
              (change)="changeSorting($event)"
            ></app-single-choice-dropdown>
          </div>

          <div class="pipe-filters__filter types">
            <div class="filter__name">{{ i18n.question_bank_type }}</div>

            <div class="multiple-choice">
              <div
                class="type"
                *ngFor="let type of types; let i = index"
                [class.is-active]="type.active"
                (click)="
                  type.active = !type.active;
                  refreshQuestionsWithoutScroll();
                  refreshFilterTracking()
                "
              >
                <app-check-button
                  [iconSize]="16"
                  [active]="type.active"
                  [label]="type.translation"
                ></app-check-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *appCheckPermissions="'quiz'; type: 'create'">
      <app-plus-button
        [title]="i18n.question_bank_create_new_question"
        (click)="createQuestionVisibility = !createQuestionVisibility"
      ></app-plus-button>
    </ng-container>
  </div>
</header>

<div class="selected-tags-wrapper" *ngIf="selectedTags.length">
  <div class="selected-tags-title">
    <i class="tag-icon icon"></i>
    {{ i18n.question_bank_tags }}
  </div>

  <div class="selected-tags">
    <div
      class="selected-tags__tag"
      *ngFor="let tag of selectedTags; let index = index"
    >
      {{ tag.text }}
      <i class="x-icon icon" (click)="unselectTag(index)"></i>
    </div>
  </div>
</div>

<div class="questions">
  <app-question-bank-question
    *ngFor="let question of questions; let index = index"
    [data]="question"
    [DOMid]="index"
    (editQuestion)="toggleEditQuestion(question, editQuestionComp)"
    (deleteQuestion)="refreshQuestionsWithoutScroll()"
    (likeQuestion)="refreshQuestionsWithoutScroll()"
  ></app-question-bank-question>
</div>

<app-pagination
  *ngIf="bankInitiated && !noQuestionsFound && !emptyBank"
  #pagination
  class="pagination"
  [params]="paginationParams"
  (navigation)="goToPage($event)"
></app-pagination>

<!-- Empty Bank  -->
<div *ngIf="emptyBank" class="flex flex-col items-center gap-4">
  <app-empty-data [text]="i18n.question_bank_warning_empty"></app-empty-data>
  <button
    type="button"
    class="button-secondary"
    (click)="createQuestionVisibility = !createQuestionVisibility"
  >
    {{ i18n.question_bank_create_new_question }}
  </button>
</div>

<div class="questions__not-found" *ngIf="noQuestionsFound && !emptyBank">
  <!-- ANIMATED SVG PENCIL -->
  <svg
    class="not-found__icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid meet"
    style="transform: rotate(360deg)"
    viewBox="3.25 2.25 17.5 19.5"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12 8v4m0 4.01l.01-.011M9 3H4v3m0 5v2m16-2v2M15 3h5v3M9 21H4v-3m11 3h5v-3"
      class="TdRVQuGX_0"
    ></path>
    <style data-made-with="vivus-instant">
      .TdRVQuGX_0 {
        stroke-dasharray: 41 43;
        stroke-dashoffset: 42;
        animation: TdRVQuGX_draw 2000ms ease-in-out 200ms forwards;
      }
      @keyframes TdRVQuGX_draw {
        100% {
          stroke-dashoffset: 0;
        }
      }
      @keyframes TdRVQuGX_fade {
        0% {
          stroke-opacity: 1;
        }
        94.44444444444444% {
          stroke-opacity: 1;
        }
        100% {
          stroke-opacity: 0;
        }
      }
    </style>
  </svg>

  {{ i18n.question_bank_search_results_not_found }}
</div>

<!-- MODALS -->
<app-new-question
  [isVisible]="createQuestionVisibility"
  [data]="{ allTags: allTags }"
  (close)="toggleCreateQuestion()"
  (create)="refreshQuestionsWithoutScroll()"
></app-new-question>

<app-edit-question
  #editQuestionComp
  [isVisible]="editQuestionVisibility"
  [data]="editInputParams"
  (close)="toggleEditQuestion()"
  (edit)="refreshQuestionsWithoutScroll()"
></app-edit-question>
