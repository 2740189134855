import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ImportSpreadsheetService {
    private formDataSubject = new BehaviorSubject<any>(null);
    formData$ = this.formDataSubject.asObservable();

    constructor(
        private http: HttpClient
    ) { }

    getImporteds(): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/spreadsheet/list`);
    }

    getRows(external_id: any): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/spreadsheet/${external_id}/validation`);
    }

    setFormDataConfigSheet(formData: any): void {
        this.formDataSubject.next(formData);
    }

    downloadReport(importId: any): Observable<any> {
        const url = `${API_Routes.URL}/spreadsheet/${importId}/download`;
        return this.http.get(url, { responseType: 'arraybuffer' });
    }

    getSheets(external_id: any): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/spreadsheet/${external_id}/get-sheets`);
    }

    generateSheet(mode: string): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/spreadsheet/generate`, { mode });
    }

    private modalstatusState = new BehaviorSubject<{ isOpen: boolean, status: string, message?: string }>({ isOpen: false, status: 'success', message: '' });

    getModalConfirmState(): Observable<{ isOpen: boolean, status: string, message?: string }> {
        return this.modalstatusState.asObservable();
    }

    openConfirmModal(status: string, message?: string): void {
        this.modalstatusState.next({ isOpen: true, status, message });
    }

    closeConfirmModal(): void {
        this.modalstatusState.next({ isOpen: false, status: '' });
    }
}
