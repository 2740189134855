import {
    Component, Input, OnChanges, SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnChanges {
    @Input() startDate: any = [];
    @Input() endDate: any = [];
    @Input() content: any = [];
    isTimer: boolean;
    config = {};

    ngOnChanges(changes: SimpleChanges) {
        const content = changes?.content?.currentValue;
        if (content !== undefined) {
            this.checkIfTimerExists(content);
            this.getTime(content);
        }
    }

    checkIfTimerExists(content: any) {
        const ruleType = content?.completion_rule;
        ruleType == 'TIME' ? this.isTimer = true : false;
    }

    getTime(content) {
        let contentTime = 0;

        if (content.status !== 'COMPLETE') {
            contentTime = content?.completion_rule_value * 60;
        }

        this.config = { leftTime: contentTime, format: 'mm:ss' };
    }
}
