import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContentViewUploadQuizService {
    constructor(
        private http: HttpClient
    ) {}

    finishQuiz(contentExternalId: any) {
        return this.http.post(
            `${API_Routes.URL}/quiz/${contentExternalId}/finish`,
            {}
        );
    }
}
