import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appCheckUrlToHide]'
})
export class CheckUrlToHideDirective implements OnInit {
  @Input('appCheckUrlToHide') pathUrl: string = '';

  constructor (
    private router: Router, 
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.checkUrlToHide(this.pathUrl);
  }
  
  /* 
  * Como usar a diretiva: <div *appCheckUrl="'disciplines-models; disciplines'">
  * Os módulos passados > são onde NÃO irá aparecer aquele elemento
  * Separar módulos por -> ;
  */
  checkUrlToHide(url: string) {
    // Verifica se a diretiva está vazia e retorna (elemento visivel)
    if (url === '') return this.viewContainer.createEmbeddedView(this.templateRef);

    // Separa os pathUrl  
    const urlArray = url.split(';');

    // Pega o módulo atual ex: Disciplinas / Disciplines models / Dashboard...  
    const getFirstModule = this.router.routerState?.snapshot?.url?.split('/')[1];

    // Verifica se é igual ao módulo atual algum pathUrl passado e retorna boolean  
    const isSameUrl = urlArray.some(urla => urla.trim() === getFirstModule);

    // se a URL atual for igual à URL passada, exibe o elemento
    if (!isSameUrl) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
    // caso contrário, oculta o elemento
      this.viewContainer.clear();
    }
  }
}
