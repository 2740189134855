 <div class="scrollWrapper">      
    <div class="usersBox">
        <div class="usersContent">
            <!-- <div class="list-users"> -->
                <div *ngFor="let user of adminData.users | keyvalue" class="card-users">
                    <adm-user-card
                        [userStatsLabel]="user.key"
                        [userStatsNumber]="user.value"
                        [i18n]="i18n"
                    ></adm-user-card>
                </div>
            <!-- </div> -->
        </div>
    </div>
</div> 