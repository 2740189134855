import { BreakpointObserver } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { SettingsStudentDashboard } from 'src/app/pages/settings/components/settings-student-dashboard/interfaces/settings-student-dashboard.interface';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { DashboardService } from '../../dashboard.service';
import { Contents, StatusContent, Weekdays } from '../my-tasks/interfaces/my-tasks.interface';

@Component({
    selector: 'app-my-week-tasks',
    templateUrl: './my-week-tasks.component.html',
    styleUrls: ['./my-week-tasks.component.scss']
})
export class MyWeekTasksComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('weekday', { read: ElementRef }) weekdayElement: ElementRef;
    @Input() contents: Contents;
    @Input() settings: SettingsStudentDashboard;
    @Input() i18n: any = [];

    isReadyToRenderCarousel: boolean;
    weekdayWidth = { width: '' };
    slidesToShow: number;
    currentDate = new Date();

    weekdays: Weekdays[] = [];
    currentLanguage: string | null;
    untilWeekDay: number;
    showSlideIndex = 0;

    isDisabledPrev = false;
    isDisabledNext = false;

    constructor(
        private cd: ChangeDetectorRef,
        private dashboardService: DashboardService,
        private sharedService: SharedService,
        public platformModalsService: PlatformModalsService,
        private breakpointObserver: BreakpointObserver,
    ) {}

    ngOnInit(): void {
        this.getScreenSize();
        this.startStatusContents();
        this.currentLanguage = this.sharedService.getSelectedLanguage();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.settings && this.settings) {
                this.getDashboardSettings();
            }
        }
    }

    ngAfterViewInit(): void {
        this.isReadyToRenderCarousel = true;
        this.weekdayWidth = {
            width: `${this.weekdayElement.nativeElement.clientWidth / this.slidesToShow}px`
        };
        this.cd.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize() {
        if (window.innerWidth < 640) {
            this.slidesToShow = 1;
        }
    }

    getDashboardSettings() {
        this.untilWeekDay = 5;

        if (this.slidesToShow !== 1) {
            this.slidesToShow = 5;
        }

        if (this.settings.show_weekend && this.slidesToShow !== 1) {
            this.untilWeekDay = 0;
            this.slidesToShow = 7;
        }
        if (this.settings.show_only_saturday && this.slidesToShow !== 1) {
            this.untilWeekDay = 6;
            this.slidesToShow = 6;
        }
    }

    startStatusContents() {
        if (this.contents) {
            const allContents: StatusContent[] = [...this.contents.late, ...this.contents.pending];
            this.generateWeekdaysBetweenDates(this.formatDateToYYYYMMDD(this.currentDate), '00:00');
            this.setupStatusContentInWeeks(allContents);
            this.sortContentsByDate(this.weekdays);
            if (this.slidesToShow === 1) {
                this.showSlideIndex = this.getCurrentDay();
            } else {
                this.showSlideIndex = this.getMondaySlide();
            }
        }
    }

    setupStatusContentInWeeks(allContents: StatusContent[]) {
        allContents.forEach((content: StatusContent) => {
            if (!this.isWeekAlreadyExists(content.end_date)) {
                this.generateWeekdaysBetweenDates(content.end_date, content.end_hour);
            }
            this.insertContentInWeekDays(content);
        });
    }

    generateWeekdaysBetweenDates(end_date: string, end_hour: string) {
        const currentDate = new Date(`${end_date} ${end_hour}`);

        if (this.settings.show_only_saturday && currentDate.getDay() === 0) {
            return;
        }

        if (
            !this.settings.show_only_saturday
            && !this.settings.show_weekend
        ) {
            if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
                return;
            }
        }

        const earliestDate = new Date(currentDate);

        while (earliestDate.getDay() !== 1) {
            earliestDate.setDate(earliestDate.getDate() - 1);
        }

        const latestDate = new Date(currentDate);

        while (latestDate.getDay() !== this.untilWeekDay) {
            latestDate.setDate(latestDate.getDate() + 1);
        }

        while (earliestDate.getTime() <= latestDate.getTime()) {
            this.insertEmptyInWeekDays(earliestDate);

            earliestDate.setDate(earliestDate.getDate() + 1);
        }
    }

    isWeekAlreadyExists(end_date: string): boolean {
        const index = this.weekdays.findIndex((weekday) => weekday.date === end_date);

        if (index === -1) {
            return false;
        }

        return true;
    }

    insertContentInWeekDays(statusContent: StatusContent) {
        const endDate = statusContent.end_date;
        const index = this.weekdays.findIndex((entry) => entry.date === endDate);

        if (index !== -1) {
            this.weekdays[index].contents.push(statusContent);
        }
    }

    insertEmptyInWeekDays(date: Date) {
        const endDate = this.formatDateToYYYYMMDD(date);

        this.weekdays.unshift(
            {
                date: endDate,
                weekname: this.getWeekdayName(endDate),
                contents: []
            }
        );
    }

    formatDateToYYYYMMDD(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    sortContentsByDate(weekdays: Weekdays[]) {
        weekdays.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }

    getWeekdayName(dateString: string): string {
        const contentDate = new Date(`${dateString} 00:00`);
        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const dayOfWeek = contentDate.getDay();
        return weekdays[dayOfWeek];
    }

    routeToLink(task: StatusContent) {
        const sectionExternalId = task.section_external_id;
        const contentType = task.content_type;
        const externalId = task.external_id;
        const disciplineId = task.discipline_external_id;
        const sectionTitle = task.section_title;

        this.dashboardService
            .toTheContent(disciplineId, externalId, sectionExternalId, sectionTitle, contentType);
    }

    getMondaySlide(): number {
        const currentDate = new Date();
        while (currentDate.getDay() !== 1) {
            currentDate.setDate(currentDate.getDate() - 1);
        }

        return this.weekdays.findIndex((weekday) => weekday.date === this.formatDateToYYYYMMDD(currentDate));
    }

    getCurrentDay(): number {
        const index = this.weekdays.findIndex((weekday) => weekday.date === this.formatDateToYYYYMMDD(this.currentDate));
        if (index === -1) {
            return this.getMondaySlide();
        }

        return index;
    }

    returnWeekNameTranslated(weekname) {
        return this.i18n[`calendar_${weekname}`];
    }

    receiveIsDisabledNext(isDisabledNext: boolean) {
        this.isDisabledNext = isDisabledNext;
    }

    receiveIsDisabledPrev(isDisabledPrev: boolean) {
        this.isDisabledPrev = isDisabledPrev;
    }
}
