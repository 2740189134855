<div [style]="addStyle">
  <label class="container flex items-center gap-2 cursor-pointer">
    <input
        [(ngModel)]="active"
        class="hidden"
        type="checkbox"
        (click)="check()"
    />
    <span class="check"></span>
    <label>{{label}}</label>
  </label>
</div>


