<section class="bg-customWhite-default rounded-t-xl mb-8 status-wrapper">
    <header
        class="py-4 px-6 flex items-center justify-between text-customBlue-default font-medium text-18 border-b border-customBlue-glass capitalize"
    >
        <p>{{ i18n.disciplines_overview_student }}</p>
        <p>{{ i18n.disciplines_emit_status }}</p>
    </header>
    <div class="py-4 px-6">
        <ul class="flex flex-col gap-3">
            <li
                class="flex flex-col gap-3"
                *ngFor="let sub of concludedSubmissions$"
            >
                <div
                    [ngClass]="{
                        'cursor-pointer': sub.course_work.at(-1)?.completed_at !== null, 
                        'cursor-not-allowed': sub.course_work.at(-1)?.completed_at === null
                    }"
                    (click)="onClickUser($event, sub.course_work.at(-1))"
                    [class.is-selected]="
                        sub.course_work.at(-1)?.id ===
                        activeCourseWork.course_work?.id
                    "
                >
                    <div class="flex items-center justify-between">
                        <div
                            class="flex items-center gap-4 text-customGray-dark"
                        >
                            <app-user-frame
                                [img_src]="sub.user.image"
                                [user_name]="sub.user.name"
                                [breakpoint_X]="'infinite'"
                            >
                            </app-user-frame>

                            <div class="main__data__name">
                                {{ sub.user.name }}
                            </div>
                        </div>
                        <i
                            class="icon"
                            pTooltip="{{
                                sub.status.name === 'late'
                                    ? i18n.upload_status_late_delivered
                                    : sub.status.name === 'pending'
                                    ? i18n.disciplines_content_submissions_pending_summary
                                    : i18n.upload_status_delivered
                            }}"
                            tooltipPosition="top"
                            pRipple
                            [ngClass]="{
                                'check-fill-icon':
                                    sub.status.name === 'completed',
                                'circle-x-fill-icon':
                                    sub.status.name === 'pending',
                                'clock-pending-icon': sub.status.name === 'late'
                            }"
                        ></i>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>
