import { formatDate } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { CertificatesSharedService } from 'src/app/shared/certificates-shared.service';
import { SharedService } from 'src/app/shared/shared.service';
import { minDateValidator } from 'src/app/utils/form-validators/form-validators';

@Component({
  selector: 'app-add-new-model-certificate',
  templateUrl: './add-new-model-certificate.component.html',
  styleUrls: ['./add-new-model-certificate.component.scss'],
})
export class AddNewModelCertificateComponent implements OnInit {
  previewIsLoading$ = this.certificatesSharedService.previewIsLoading$;
  modalAddModelCertificate$ =
    this.certificatesSharedService.modalAddModelCertificate$;
  isLoaded$ = this.sharedService.isLoadedUpload$;

  previewCertificateForm: UntypedFormGroup;
  buttonUnlocked: boolean = false;
  previewLoading: boolean = false;
  currentLanguage: string | null;

  @Input() certificatePreviewPath: any;
  @Input() preview: any;
  @Input() i18n: any;

  @Output() modelCertificateForm = new EventEmitter();
  @Output() addModelCertificate = new EventEmitter();

  initialDate: Date = new Date();

  constructor(
    private certificatesSharedService: CertificatesSharedService,
    public sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private shared: SharedService
  ) {}

  ngOnInit(): void {
    this.previewIsLoading();
    this.certificatePreviewForm();
    this.previewUpdate();
    this.certificatesSharedService.addFileMode();
    this.getTranslations();
  }

  getTranslations() {
    this.currentLanguage = this.shared.getSelectedLanguage();
  }

  closeAddModelCertificate() {
    this.certificatesSharedService.modalCloseAddModelCertificate();
  }

  openAddModelCertificate() {
    this.certificatesSharedService.modalOpenAddModelCertificate();
  }

  private certificatePreviewForm() {
    this.previewCertificateForm = this.formBuilder.group({
      student_name: ['', [Validators.required]],
      discipline_duration: ['', [Validators.required]],
      discipline_name: ['', [Validators.required]],
      emission_date: ['', [Validators.required]],
      calendarEmissionDate: ['', [this.minDateValidator.bind(this)]],
      startDate: [new Date()],
    });

    console.log('previewCertificateForm: ', this.previewCertificateForm);
    //this.modelCertificateForm.emit(this.previewCertificateForm)

    this.previewCertificateForm.valueChanges.subscribe((val) => {
      this.buttonUnlocked = val;
      this.isLoaded$.next(this.buttonUnlocked);
    });
  }

  addCertificate() {
    //this.addModelCertificate.emit()
    console.log('CLICOU EM ADD MODEL!');
    this.certificatesSharedService.editModalTitleADD();
    this.certificatesSharedService.addFileMode();
    this.certificatesSharedService.modalOpenEditCertificate();
    this.certificatesSharedService.modalCloseAddModelCertificate();
  }

  previewUpdate() {
    this.modelCertificateForm.emit(this.previewCertificateForm.value);
  }

  previewIsLoading() {
    this.previewIsLoading$.subscribe((val) => {
      this.previewLoading = val;
      if (this.previewLoading) {
        this.buttonUnlocked = false;
      } else {
        this.buttonUnlocked = true;
      }
    });
  }

  minDateValidator(control: FormControl): { [key: string]: any } | null {
    const enteredDate = control;
    
    let dateMin : Date = new Date();
    let returnValue: any = null;
    
    if (this.previewCertificateForm && true) {
      dateMin = this.initialDate;
      returnValue = minDateValidator(dateMin)(enteredDate);
    }
    return returnValue;
  }

  fnPickDateToInput(calendarDateValue?){
    // Coloca a data no input vindo do CalendarPicker
    if (calendarDateValue) {
      let format = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'MM/dd/yyyy HH:mm','en-US') : formatDate(calendarDateValue, 'dd/MM/yyyy HH:mm','pt-BR');
      this.previewCertificateForm.controls['calendarEmissionDate'].setValue(format);
    }
  }

  get startDate() {
    return this.previewCertificateForm.get('startDate');
  }
}
