import { NgModule } from '@angular/core';
import { ProgressBarComponent } from './progress-bar.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    ProgressBarComponent
  ],
  exports: [
    ProgressBarComponent
  ],  
  imports: [
    SharedModule
  ]
})
export class ProgressBarModule { }
