import { SharedService } from 'src/app/shared/shared.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss'],
})
export class ValidatorComponent implements OnInit {
  form: FormGroup;
  i18n: any = {};
  loading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.createForm();
  }

  getTranslations(): void {
    if (this.localStorageService.getLocalStorageItem('translations')) {
      this.i18n = { ...this.sharedService.getTranslationsOf('CertificatesValidate') };
    }
  }

  createForm(): void {
    // Timeout colocado para correção do reload caso não haja tradução 
    setTimeout(() => {
      if (Object.keys(this.i18n)) {
        this.form = this.fb.group({
          code: ['', Validators.required],
        });
        this.loading = false
      }
    }, 1000);
  }

  validateCertificates() {
    if (this.form.valid) {
      this.router.navigate([`/certificates/${this.form.controls['code'].value}/validate`])
    }

    return;
  }
}
