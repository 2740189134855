<div class=" lg:grid lg:grid-cols-3 flex flex-col justify-center items-center gap-4 mt-16 animate-pulse" >
    <div 
        *ngFor="let period of periods; let i = index"  
        class="w-full flex items-center gap-6 p-4 bg-customWhite-default rounded-lg cursor-pointer"
        style="background-color: #FFF;"
    >
        <div 
            class="rounded-full p-3 w-16 h-16"
            style="background-color: #DEDFE3;"
        >
        </div>
        <div 
            class="flex flex-col gap-2"
            style="width: 69%;"
        >
            <div 
                class="ml-2 rounded " 
                style="background-color: #DEDFE3; height: 20px;"
                [style.width]="period.width_description1 + '%'"
            >
            </div>
            <div 
                class="ml-2 rounded " 
                [style.width]="period.width_description2 + '%'"
                style="background-color: #DEDFE3; height: 15px;"
            >
            </div>
        </div>
    </div>        

</div>
