import { Component, OnDestroy, OnInit } from '@angular/core';
import { DisciplineDetailService } from './discipline-detail.service';


@Component({
  selector: 'app-sections',
  templateUrl: './disciplines-detail.component.html',
  styleUrls: ['./disciplines-detail.component.scss']
})
export class DisciplineDetailComponent implements OnInit, OnDestroy {

  constructor(
    private disciplineDetailService: DisciplineDetailService
  ) { }

  ngOnInit(): void {
  };

  ngOnDestroy(): void {
    this.disciplineDetailService.titleName$.next('');
    
  }

}
