<section>
    <!-- HEAD -->
    <app-header-forum
        [user]="user" [query]="query" [filterOpen]="filterOpen"
        [titlePage]="titlePage" [placeholderSearch]="placeholderSearch"
        (sendQuery)="searchForum($event)" (sendFilter)="checkFilter($event)" (sendOrder)="checkOrder($event)"
        (sendModalOpenStatus)="getModalOpenStatus($event)" >
    </app-header-forum>
    <!-- FIM HEAD -->

    <!-- CONTENT -->
    <div class="mt-8 grid grid-cols-1 gap-8">
        <!-- mensagens tratativas para exceções -->
        <div class="flex" *ngIf="forums?.items.length == 0">
            <div class="mx-auto text-center">
                <!-- template para quando não há forums criados -->
                <app-empty-data *ngIf="hasntForums"
                [text]="i18n.forum_warning_empty"></app-empty-data>
                <!-- template para quando não há resultados de pesquisa -->
                <app-empty-data *ngIf="hasntResults"
                [text]="i18n.forum_there_is_not_results_for_this_search"></app-empty-data>
            </div>
        </div>
        <!-- FIM mensagens tratativas para exceções -->

        <!-- Loading forum list -->
        <ng-container *ngIf="isLoading">
            <app-forum-list-skeleton></app-forum-list-skeleton>
        </ng-container>
        <!-- FIM Loading forum list -->

        <!-- FORUM CARD -->
        <ng-container *ngIf="!isLoading">
            <div class="relative shadow-card rounded-3xl bg-customWhite-default" *ngFor="let thread of forums?.items | filter:filterTerm">
                <div class="p-4">
                    <div class="flex flex-col md:flex-row justify-between md:items-center gap-2">
                        <div class="flex justify-start flex-col md:flex-row items-center gap-2">
                            <h3 class="justify-center text-customGray-dark font-bold cursor-pointer" (click)="goToForumDetail(thread?.id, forums?.forum_external_id)">{{thread?.title}}</h3>
                            <ng-container *ngIf="thread?.deleted">
                                <span class="bg-customGray-mediumGray font-bold rounded-md p-1 px-4 text-center text-sm text-customGray-default">{{i18n.forum_forums_removed}}</span>
                            </ng-container>
                        </div>
                        <div class="flex justify-end">
                            <div class="flex text-center gap-2 items-center text-customGray-default text-sm lg:mr-8" *ngIf="!thread?.deleted">
                                <div *ngIf="thread?.logged_user_permissions?.can_edit"  (click)="editForum(thread?.text, thread?.id)" class="flex items-center cursor-pointer">
                                    <i class="pen-editing-icon icons_forum" style="margin-right: 3px;margin-bottom: 2px;"></i>
                                    <span>{{i18n.forum_edit}}</span>
                                </div>
                                <div *ngIf="thread?.logged_user_permissions?.can_delete" (click)="deleteForum(thread)" class="flex items-center cursor-pointer">
                                    <i class="trash-can-icon icons_forum" style="margin-right: 3px;margin-bottom: 3px;"></i>
                                    <span>{{i18n.forum_remove}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex pl-0 p-4 cursor-pointer" (click)="goToForumDetail(thread?.id, forums?.forum_external_id)">
                        <span class="text-customGray-default">{{thread?.text_sliced}}</span>
                    </div>
                    <div class="flex justify-between">
                        <div class="flex">
                            <ng-container *ngFor="let replier of thread?.last_repliers; let i = index">
                                <app-user-frame *ngIf="i < 3"
                                    [style]="{'width':'22px', 'height':'22px', 'font-size': '15px', 'font-weight': 'bold'}"
                                    [img_src]="replier.photo"
                                    [user_name]="replier.name">
                                </app-user-frame>
                            </ng-container>
                            <div *ngIf="thread?.last_repliers > 3" class="flex justify-center items-center circle" style="width: 22px; height: 22px;">
                                <span>{{thread?.last_repliers - 3}}+</span>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row items-center gap-2">
                            <ng-container *ngIf="thread.replies > 0">
                                <div class="flex gap-2 cursor-pointer" (click)="goToForumDetail(thread?.id, forums?.forum_external_id)">
                                    <i class="comment-outlined-icon comment-icon"></i>
                                    <div *ngIf="thread.replies !== 1 && thread.replies > 0" class="italic">{{thread.replies}} {{i18n.forum_replies}}</div>
                                    <div *ngIf="thread.replies == 1" class="italic">{{thread.replies}} {{i18n.forum_reply}}</div>
                                </div>
                            </ng-container>
                            <div *ngIf="thread?.unread > 0">
                                <div>
                                    <span class="bg-customBlue-royal rounded-md p-2 px-4 text-sm text-customWhite-default">
                                        {{thread?.unread}}
                                        <ng-container *ngIf="thread?.unread == 1">
                                            {{i18n.forum_unread}}
                                        </ng-container>
                                        <ng-container *ngIf="thread?.unread > 1">
                                            {{i18n.forum_unreads}}
                                        </ng-container>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- FIM FORUM CARD -->
    </div>
    <!-- Pagination -->
    <div class="mt-8 flex justify-center" *ngIf="forums?.items.length">
        <app-pagination
        #pagination
        [params]="paginationParams"
        (navigation)="goToPage($event)"
        ></app-pagination>
    </div>
    <!-- FIM CONTENT -->

</section>

<app-decision-modal></app-decision-modal>

<!-- MODAL ADICIONAR NOVO FÓRUM -->
<app-modal-forum-create-update
    *ngIf="isModalOpen"
    [(isModalOpen)]="isModalOpen"
    [(isEditMode)]="isEditMode"
    [i18n]="i18n"
    [(forumPostId)]="forum_post_id"
    [forumThreadText]="threadText"
    [forumExternalId]="forums?.forum_external_id"
    (sendThread)="receiveThread($event)"
></app-modal-forum-create-update>

<!-- <app-generic-modal [isVisible]="isModalOpen" >
    <div [style.max-width]="'53rem'" class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        <div class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t">
            <div *ngIf="!isEditMode" class="text-center">
                <h3 class="text-26 font-semibold text-customBlue-default">{{i18n.forum_new_publication}}</h3>
                <p class="text-16 text-customBlue-default">{{i18n.forum_create_new_publication_in_forum}}</p>
            </div>
            <div *ngIf="isEditMode" class="text-center">
                <h3 class="text-26 font-semibold text-customBlue-default">{{i18n.forum_edit_publication}}</h3>
                <p class="text-16 text-customBlue-default">{{i18n.forum_edit_forum_text}}</p>
            </div>
        </div>
        <i class="close-btn-icon" (click)="closeModal()">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.716 6.58203C12.716 6.50039 12.6492 6.43359 12.5676 6.43359L11.343 6.43916L9.49863 8.63789L7.65615 6.44102L6.42969 6.43545C6.34805 6.43545 6.28125 6.50039 6.28125 6.58389C6.28125 6.61914 6.29424 6.65254 6.3165 6.68037L8.73047 9.55635L6.3165 12.4305C6.29408 12.4577 6.28164 12.4917 6.28125 12.527C6.28125 12.6086 6.34805 12.6754 6.42969 12.6754L7.65615 12.6698L9.49863 10.4711L11.3411 12.668L12.5657 12.6735C12.6474 12.6735 12.7142 12.6086 12.7142 12.5251C12.7142 12.4898 12.7012 12.4564 12.6789 12.4286L10.2687 9.55449L12.6826 6.67852C12.7049 6.65254 12.716 6.61729 12.716 6.58203Z" fill="#A9AEB3"/>
            <path d="M9.5 1.20703C4.90957 1.20703 1.1875 4.9291 1.1875 9.51953C1.1875 14.11 4.90957 17.832 9.5 17.832C14.0904 17.832 17.8125 14.11 17.8125 9.51953C17.8125 4.9291 14.0904 1.20703 9.5 1.20703ZM9.5 16.4219C5.68887 16.4219 2.59766 13.3307 2.59766 9.51953C2.59766 5.7084 5.68887 2.61719 9.5 2.61719C13.3111 2.61719 16.4023 5.7084 16.4023 9.51953C16.4023 13.3307 13.3111 16.4219 9.5 16.4219Z" fill="#A9AEB3"/>
            </svg>
        </i>
        <form [formGroup]="searchForumForm">
            <div class="relative p-12">
                <div>
                    <div *ngIf="!isEditMode" class="mb-4 w-fit">
                        <label class="my-1 font-bold text-customBlue-default">{{i18n.forum_post_title}}</label>
                        <input type="text" class="w-full border border-customGray-default rounded-md p-1 text-md mr-4" required formControlName="title">
                        <div *ngIf="!title?.pristine && title?.errors">
                            <p class="text-sm " style="color: #E8532C;" *ngIf="title?.errors?.required">
                                {{i18n.forum_post_title_is_required}}
                            </p>
                        </div>
                    </div>
                    <div>
                        <label class="font-bold text-customBlue-default my-1">{{i18n.forum_text}}</label>
                        <app-editor [form]="searchForumForm" [formControlName]="'text'" [height]="375"></app-editor>
                        <div *ngIf="!text?.pristine && text?.errors">
                            <p class="text-sm " style="color: #E8532C;" *ngIf="text?.errors?.required">
                                {{i18n.forum_text_is_required}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-center p-6 rounded-b">
                <button type="submit" class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !searchForumForm.valid}" [disabled]="!searchForumForm.valid"(click)="saveForum()">
                    <span *ngIf="!isEditMode">
                        {{i18n.forum_add}}
                    </span>
                    <span *ngIf="isEditMode">
                        {{i18n.forum_save}}
                    </span>
                </button>
                <button class="bg-white font-semibold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" style="color: #233674" type="button" (click)="closeModal()">
                    {{i18n.forum_close}}
                </button>
            </div>
        </form>
    </div>
</app-generic-modal> -->
<!-- FIM MODAL ADICIONAR NOVO FÓRUM -->
<!-- FIM MODAIS -->
