import {
    animate, AUTO_STYLE, state, style, transition, trigger
} from '@angular/animations';
import {
    AfterContentInit, Component, ElementRef, OnDestroy, OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SubmissionsService } from 'src/app/pages/disciplines/components/content/components/homework-submissions/submissions.service';
import { GradesService } from 'src/app/pages/disciplines/components/grades/grades.service';
import { SharedService } from 'src/app/shared/shared.service';

import { PlatformModalsService } from '../../../../../../../../services/modals/platform-modals.service';
import { maxValueValidator } from '../../../../../../../../utils/form-validators/form-validators';
import { SettingsConceptsService } from '../../../../../settings/components/discipline-settings-grades/components/settings-concept/settings-concepts.service';
import { DisciplineSettingsGradesService } from '../../../../../settings/components/discipline-settings-grades/discipline-settings-grades.service';
import { ContentService } from '../../../../content.service';
import { Criterion } from '../../../../section.service';
import { ContentQuizService } from '../../../content-view/components/content-view-quiz/content-quiz.service';
import { updateActiveCourseWork } from '../../store/homework-submissions.actions';
import { selectHomeworkSubmissionsState } from '../../store/homework-submissions.reducer';

const DEFAULT_DURATION = 250;
@Component({
    selector: 'app-homework-submissions-submissions',
    templateUrl: './homework-submissions-submissions.component.html',
    styleUrls: ['./homework-submissions-submissions.component.scss'],
    animations: [
        trigger('collapse', [
            state(
                'true',
                style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
            ),
            state(
                'false',
                style({ height: '0px', visibility: 'hidden', opacity: 0 })
            ),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ],
})

export class HomeworkSubmissionsSubmissionsComponent
implements OnInit, AfterContentInit, OnDestroy {
    addNewCommentInput = '';
    addNewCommentAriaLabel = 'Seu comentário aqui...';
    showDecisionModal = false;
    $element: any;
    $allElements: any;
    state$: Subscription;
    public state;
    diferentCourseWorkSubmissionsCountArray: number[] = [0];
    courseWorkFileIndex: number;

    file: any;

    discipline_external_id: string;
    section_external_id: string;
    content_external_id: string;
    correctionQuestion: any = {};

    listCriterion: Criterion[] = [];
    rubric_levels_id: number[] = [];
    gradesSettings: any = {};
    conceptsList: any[] = [];
    grade_final = '0';
    concept_id: number;
    isLoading = false;
    controlButtonForm = false;
    i18n: any = {};
    maximum_grade: any;

    collapseOption = false;
    enableDownload = false;
    course_workList: any[] = [];
    gradeForm: FormGroup;
    decimal_places_mask: number;

    content: any = {};
    resume: any;

    isLoadingCourseWork = false;

    constructor(
        private element: ElementRef,
        private store: Store,
        private contentService: ContentService,
        private router: Router,
        private route: ActivatedRoute,
        private gradesService: GradesService,
        private disciplineSettingsGradesService: DisciplineSettingsGradesService,
        private settingsConceptsService: SettingsConceptsService,
        private sharedService: SharedService,
        private contentQuizService: ContentQuizService,
        private platModalsService: PlatformModalsService,
        private submissionsService: SubmissionsService,
        private FormBuilder: FormBuilder
    ) {
        this.$element = this.element.nativeElement.querySelectorAll.bind(
            this.element.nativeElement
        );
        this.$allElements = this.element.nativeElement.querySelectorAll.bind(
            this.element.nativeElement
        );
    }

    gradeId: any = null;
    ngOnInit(): void {
        this.getParams();
        this.getTranslations();

        this.createGradeForm();
        this.state$ = this.store
            .select(selectHomeworkSubmissionsState)
            .subscribe((state) => {
                this.state = state;

                console.log('this.state ', this.state);

                if (Object.prototype.hasOwnProperty.call(this.state.activeCourseWork.contentSpecific, 'tries')) {
                    // Return completed course work array
                    this.course_workList = [...this.state?.activeCourseWork?.contentSpecific?.tries];
                }
                // this.state?.contentSpecific?.tries?.forEach((element) => {
                //     // if (element.user.name === this.state.activeCourseWork.user.name) {
                //     //     this.course_workList = [...element.course_work].reverse();
                //     // }

                // });

                const maxGrade = state.activeCourseWork.course_work.maximum_grade;
                this.maximum_grade = maxGrade == null ? 0 : maxGrade;

                if (this.currentLanguage !== 'en' && this.maximum_grade !== 0) {
                    if (typeof this.maximum_grade === 'string') {
                        this.maximum_grade = this.maximum_grade.replace('.', ',');
                    }
                }
                // Start only once
                if (state.activeCourseWork.course_work.id !== this.gradeId) {
                    this.createGradeForm(this.maximum_grade);
                    this.gradeForm.controls.gradeValue.setValue(this.sharedService.fnReplaceDotForComa(state.activeCourseWork.course_work.grade.toString()));
                    this.gradeId = this.state.activeCourseWork.course_work.id;
                }

                const contentSpecificKey = this.state.contentType.match(/Quiz/)
                    ? 'tries'
                    : 'files';

                this.file = this.state?.activeCourseWork?.contentSpecific[contentSpecificKey];

                if (Object.keys(this.file).length) {
                    this.enableDownload = true;
                }

                this.getTries();
            });

        this.getCriterions();
        this.getGradesSettings();
        this.getSettingsConceptsList();
    }

    ngAfterContentInit(): void {
        this.getPeriodsList();
    }

    getTries() {
        if (this.state?.activeCourseWork?.contentSpecific?.tries?.length) {
            this.changeActiveCourseWork(!this.tryId ? this.state?.activeCourseWork?.contentSpecific?.tries[0].try_id : this.tryId);
            return;
        }

        this.submissionsService.homework.next({});
    }

    receiveIsLoadingCourseWork($event) {
        this.isLoadingCourseWork = $event;
    }

    // Forms
    createGradeForm(maximum_grade?) {
        this.gradeForm = this.FormBuilder.group({
            gradeValue: ['', [Validators.required, maxValueValidator(maximum_grade)]]
        });
    }

    clearIfZeroGrade(field): void {
        if (field.value == '0') {
            field.value = '';
        }
    }

    sheetsAnswersUrl() {
        window.open(this.state?.activeCourseWork?.contentSpecific.results_url);
    }

    currentLanguage = '';
    getTranslations() {
        this.currentLanguage = this.sharedService.getSelectedLanguage();

        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines'),
            ...this.sharedService.getTranslationsOf('Grades'),
            ...this.sharedService.getTranslationsOf('Upload'),
            ...this.sharedService.getTranslationsOf('Errors'),
        };
    }

    getParams(): void {
        this.discipline_external_id = this.router?.url.split('/')[2];
        this.section_external_id = this.router?.url.split('/')[3];
        this.content_external_id = this.router?.url.split('/')[5];
    }

    // This is not implemented, it can be deleted in future if it does not have use anymore
    toggleComments($event, type?) {
        $event.stopPropagation();

        // reset the newCommentInput
        this.addNewCommentInput = '';
        this.syncAllAddNewCommentInput();

        const element = this.element.nativeElement;

        const allCommentsButtons = [
            ...element.querySelectorAll('.homework-submission__question-comments'),
        ];

        const allStringCommentsButtons = [
            ...element.querySelectorAll(
                '.homework-submission__comments-new__btn.cancel'
            ),
        ];

        const allCommentsWrapper = [
            ...element.querySelectorAll('.homework-submission__comments-wrapper'),
        ];

        const allSubmission = [
            ...element.querySelectorAll('.homework-submission__submission'),
        ];

        // calculate the index
        let index = 0;

        if (type === 'icon-btn') {
            index = allCommentsButtons.indexOf($event.target);
        } else if (type === 'cancel-btn') {
            index = allStringCommentsButtons.indexOf($event.target);
        }

        const commentsWrapper = [
            ...element.querySelectorAll('.homework-submission__comments-wrapper'),
        ][index];

        const submission = [
            ...element.querySelectorAll('.homework-submission__submission'),
        ][index];

        // reset other wrappers, except the one called
        allCommentsWrapper.forEach((node, i) => (index !== i ? (node.style.height = '28px') : null));
        allSubmission.forEach((node, i) => (index !== i ? node.classList.remove('expanded') : null));
        allCommentsWrapper.forEach((node, i) => (index !== i ? node.classList.remove('expanded') : null));

        // do the expand action of the called wrapper
        submission.classList.toggle('expanded');
        commentsWrapper.classList.toggle('expanded');
        if (commentsWrapper.classList.contains('expanded')) {
            commentsWrapper.style.height = `${commentsWrapper.scrollHeight + 28}px`;
            setTimeout(() => {
                commentsWrapper.style.height = 'unset';
            }, 500);
        } else {
            commentsWrapper.style.height = '28px';
            this.editComment(null, 'closeAll');
        }
    }

    bobble($event) {
        $event.stopPropagation();

        const CommentActionBtn = [
            ...this.element.nativeElement.querySelectorAll(
                '.comment-container__user-info__btn'
            ),
        ];

        if (CommentActionBtn.some((btn) => btn === $event.target)) {
            $event.target.classList.add('up');
            setTimeout(() => {
                $event.target.classList.remove('up');
            }, 300);
        }
    }

    editComment($event?, action?, type?) {
        if ($event) {
            $event.stopPropagation();
        }

        const element = this.element.nativeElement;

        let index = 0;

        if (type === 'pen') {
            const commentContent = [
                ...element.querySelectorAll('.comment-container__user-info__btn.pen'),
            ];

            index = commentContent.indexOf($event.target);
        } else if (type === 'cancel') {
            const commentContent = [
                ...element.querySelectorAll(
                    '.comment-container__text-edit__btn.cancel'
                ),
            ];

            index = commentContent.indexOf($event.target);
        }

        // Single Elements - nodes
        const commentContent = element.querySelectorAll(
            '.comment-container__text-content'
        )[index];
        const commentUserInfoBtns = element.querySelectorAll(
            '.comment-container__user-info__btns'
        )[index];
        const commentEdit = element.querySelectorAll(
            '.comment-container__text-edit'
        )[index];
        const commentEditText = element.querySelectorAll(
            '.comment-container__text-edit__text'
        )[index];
        const commentEditTrace = element.querySelectorAll(
            '.comment-container__text-edit__trace'
        )[index];
        const commentEditBtns = element.querySelectorAll(
            '.comment-container__text-edit__btns'
        )[index];

        // MultipleElements - NodeLists
        const allCommentContent = element.querySelectorAll(
            '.comment-container__text-content'
        );
        const allCommentUserInfoBtns = element.querySelectorAll(
            '.comment-container__user-info__btns'
        );
        const allCommentEdit = element.querySelectorAll(
            '.comment-container__text-edit'
        );
        const allCommentEditTrace = element.querySelectorAll(
            '.comment-container__text-edit__trace'
        );
        const allCommentEditBtns = element.querySelectorAll(
            '.comment-container__text-edit__btns'
        );

        if (action === 'open') {
            closeEditingForAll();
            openEditing();
        } else if (action === 'close') {
            closeEditing();
        } else if (action === 'closeAll') {
            closeEditingForAll();
        }

        function openEditing() {
            commentEditText.innerHTML = commentContent.innerHTML;
            commentEdit.hidden = false;
            commentContent.hidden = true;
            commentUserInfoBtns.classList.add('not-visible');
            commentEditTrace.classList.add('active');
            commentEditBtns.classList.add('active');
        }

        function closeEditing() {
            commentEditText.innerHTML = commentContent.innerHTML;
            commentEdit.hidden = true;
            commentContent.hidden = false;
            commentUserInfoBtns.classList.remove('not-visible');
            commentEditTrace.classList.remove('active');
            commentEditBtns.classList.remove('active');
        }

        function closeEditingForAll() {
            allCommentEdit.forEach((node) => (node.hidden = true));
            allCommentContent.forEach((node) => (node.hidden = false));
            allCommentUserInfoBtns.forEach((node) => node.classList.remove('not-visible'));
            allCommentEditTrace.forEach((node) => node.classList.remove('active'));
            allCommentEditBtns.forEach((node) => node.classList.remove('active'));
        }
    }

    onNewCommentInput($event) {
        $event.stopPropagation();
        const element = $event.currentTarget;

        this.addNewCommentInput = element.innerHTML;

        this.addNewCommentInput.length
            ? (element.ariaLabel = '')
            : (element.ariaLabel = this.addNewCommentAriaLabel);
    }

    onDeleteComment($event) {
        $event.stopPropagation();

        const allElements = this.element.nativeElement.querySelectorAll.bind(
            this.element.nativeElement
        );
        const allTrashBtns = [
            ...allElements('.comment-container__user-info__btn.trash'),
        ];

        let index = 0;

        index = allTrashBtns.indexOf($event.currentTarget);

        this.toggleDecisionModal();
    }

    syncAllAddNewCommentInput() {
        this.element.nativeElement
            .querySelectorAll('.homework-submission__comments-new__editable')
            .forEach((node) => {
                node.innerHTML = this.addNewCommentInput;
                node.ariaLabel = this.addNewCommentAriaLabel;
            });
    }

    toggleDecisionModal(decision?: boolean, $event?) {
        this.showDecisionModal
            ? (this.showDecisionModal = false)
            : (this.showDecisionModal = true);
    }

    getCriterions(): void {
        this.contentService
            .getContentForm(
                this.discipline_external_id,
                this.section_external_id,
                this.state.contentID
            )
            .subscribe({
                next: (response) => {
                    this.content = response;
                    this.listCriterion = response.criterions;
                },
                error: (err) => {
                    const message = this.errorMessage(err.status, err.error.error);
                    this.platModalsService.toggle('message', message, 'close');
                }
            });
    }

    getGradesSettings() {
        this.disciplineSettingsGradesService
            .getGradesSettings(this.discipline_external_id)
            .subscribe((gradesSettings) => {
                this.gradesSettings = gradesSettings;
            });
    }

    getSettingsConceptsList() {
        this.settingsConceptsService
            .getSettingsConceptsList(this.discipline_external_id)
            .subscribe((concepts) => {
                this.conceptsList = concepts;
            });
    }

    getPeriodsList() {
        this.gradesService.getPeriodsList(this.discipline_external_id).subscribe({
            next: (periods) => {
                if (periods.length) this.decimal_places_mask = periods[0].decimal_places;
            },
            error: (err) => {
                const message = this.errorMessage(err.status, err.error.error);
                this.platModalsService.toggle('message', message, 'close');
            }
        });
    }

    receiveRubricLevels(event: number): void {
        let editItem = false;

        this.rubric_levels_id.map((element, i: number) => {
            if (element === event) {
                this.rubric_levels_id[i] = event;
                editItem = true;
            }
        });

        if (!editItem) {
            this.rubric_levels_id.push(event);
        }
    }

    receiveFromDecimalPlaces(event) {
        if (event) {
            this.gradeForm.controls.gradeValue.setValue(event);
        }
    }

    postGradeCourseWork() {
        let message: string;

        this.isLoading = true;
        // Replace coma for dot to conver into float
        let formGradeValue = this.gradeForm.controls.gradeValue.value;

        if (formGradeValue.includes(',')) {
            formGradeValue = formGradeValue.replace(',', '.');
        }
        // Convert in float
        formGradeValue = parseFloat(formGradeValue);

        const params: any = {
            grade: formGradeValue,
            force: false,
            rubric_levels: [],
            concept_id: 0
        };

        if (this.gradesSettings.rubric_enable) {
            params.rubric_levels = this.rubric_levels_id;
        }

        if (this.gradesSettings.concept_enable) {
            params.concept_id = parseInt(this.concept_id as unknown as string);
        }

        this.updateGrade(params);
    }

    errorMessage(statusCode: any, errorMessage: any) {
        if (Object.prototype.hasOwnProperty.call(this.i18n, errorMessage)) {
            return this.i18n[errorMessage];
        }
        return this.i18n.error_system_error;
    }

    finished_at = '';
    receiveCorrection(event): void {
        this.correctionQuestion = event.review;
        this.finished_at = event.finished_at;
    }

    updateGrade(params) {
        this.gradesService
            .patchGrade(this.state.activeCourseWork.course_work.id, params)
            .subscribe({
                next: () => {
                    this.isLoading = false;
                },
                error: (err) => {
                    this.isLoading = false;
                    const message = this.errorMessage(err.status, err.error.error);
                    this.platModalsService.toggle('message', message, 'close');
                },
            });
    }

    tryIdScore = 0;
    correctionQuestionnaire(): void {
        const params_questionnaire = {
            user_external_id: this.state.activeCourseWork.user.external_id,
            review: this.correctionQuestion,
            try_id: this.tryId,
            finished_at: this.finished_at
        };

        const typeQuiz = this.state.contentType === 'UploadQuiz' ? 'correctionUploadQuiz' : 'correctionQuiz';

        this.contentQuizService
            [typeQuiz](params_questionnaire, this.content_external_id)
            .subscribe({
                next: (review:any) => {
                    this.isLoading = false;
                    this.gradeForm.controls.gradeValue.setValue(review.coursework_grade.toString());
                    this.tryIdScore = review.score;
                    this.getCourseworkById(review.coursework_id);
                },
                error: () => {
                    this.isLoading = false;
                    this.showModalError(
                        this.i18n.disciplines_content_submissions_error_correction_default
                    );
                },
            });
    }

    toggleOptions(): void {
        this.collapseOption = !this.collapseOption;
    }

    showModalError(message: string): void {
        this.platModalsService.toggle(
            'message',
            {
                message,
                icon_existence: true,
                icon_color: '#F36C48',
                custom_icon: 'attention-icon',
            },
            'close'
        );
    }

    tryId: any;
    tryObject: any;
    changeActiveCourseWork(newCourseWorkID) {
        if (this.tryId === newCourseWorkID) {
            return;
        }
        const { tries } = this.state.activeCourseWork.contentSpecific;

        this.tryId = newCourseWorkID;

        this.tryObject = tries.find((try_obj) => try_obj.try_id === newCourseWorkID);

        if (this.tryObject) {
            this.resume = this.tryObject.resume;
            this.tryIdScore = this.tryObject.score;
        }

        this.submissionsService.homework.next(this.tryObject);
    }

    getCourseworkById(newCourseWorkID) {
        this.submissionsService
            .getCourseWork(this.state.contentID, newCourseWorkID)
            .subscribe((courseWork) => {
                this.store.dispatch(
                    updateActiveCourseWork({ activeCourseWork: courseWork })
                );
            });
    }

    validateMaxValue(): void {
        let grade = parseFloat(this.gradeForm.controls.gradeValue.value.replace(',', '.'));
        let gradeString = this.gradeForm.controls.gradeValue.value;

        if (gradeString.includes('-')) {
            gradeString = gradeString.replace(/-/g, '');
            this.gradeForm.controls.gradeValue.setValue(gradeString);
            grade = parseFloat(gradeString);
        }

        if (grade > parseFloat(this.maximum_grade.replace(',', '.'))) {
            this.gradeForm.controls.gradeValue.setValue(this.maximum_grade);
        }
    }

    // errorGrade: boolean = false;
    // @ViewChild('gradeValue') gradeValue_ER: ElementRef;
    // checkMaximumGrade(value){
    //   console.log('value ', value);
    //   let replaceValue = value.replace(",", ".");
    //   let inputValue = value == '' ? 0 : value
    //   console.log('inputValue ', inputValue);
    //   let maximum_grade = this.maximum_grade == null ? 0 : this.maximum_grade;
    //   console.log('maximum_grade ', maximum_grade);

    //   if (inputValue > maximum_grade) {
    //     this.controlButtonForm = false;
    //     this.errorGrade = true;
    //   }else{
    //     this.errorGrade = false;
    //   }

    //   this.gradeValue_ER.nativeElement.value =
    //     inputValue < 0 ? 0 : inputValue;

    // }

    ngOnDestroy(): void {
        console.log('submission detroy');

        if (this.state$) this.state$.unsubscribe();
    }
}
