import {
    Directive, EventEmitter, HostListener, Input, Output
} from '@angular/core';

@Directive({
    selector: '[appDecimalPlaces]'
})
export class DecimalPlacesDirective {
    @Input('appDecimalPlaces') decimalPlaces: number;

    @Output() onValueChange = new EventEmitter();

    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        const decimalIndex = value.search(/[,\.]/);

        if (decimalIndex !== -1) {
            const decimalSeparator = value[decimalIndex] === ',' ? ',' : '.';
            const decimalPart = value.substring(decimalIndex + 1);
            const trimmedDecimalPart = decimalPart.substring(0, this.decimalPlaces);
            value = value.substring(0, decimalIndex) + decimalSeparator + trimmedDecimalPart;
            this.onValueChange.emit(value);
        }
    }
}
