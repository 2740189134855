<!--MODAL RESOURCES-->
<!-- <div
  class="scrollModalWrapper transition ease-out duration-300 animate-fade-in-down outline-none focus:outline-none flex items-center justify-center resourcesModal"
  style="z-index: 6"
>

</div> -->
<!-- <div
  *ngIf="modalResourcesCertificate$"
  class="overlay"
  (click)="closeResourcesCertificate()"
  style="z-index: 5"
></div> -->

<app-generic-modal [isVisible]=true>
  <div class="relative w-auto my-6 mx-auto max-w-lg">
    <!--content-->
    <div
      class="rounded-lg relative flex flex-col w-full outline-none focus:outline-none"
    >
      <!--header-->
      <div
        class="border-b border-solid border-customBlue-superLight rounded-t resourcesHeader"
      >
        <h3
          class="text-3xl font-semibold resourcesHeader mt-2"
          style="color: #233674"
        >
        {{i18n.disciplines_select_resources}}
        </h3>
        <div class="textHeader mt-3 mb-1">
          {{i18n.disciplines_select_resources_text}}
        </div>
      </div>
      <!--body-->
      <div class="search mx-6 mt-6 mb-6">
        <input #searchBox class="searchBox" type="text" [(ngModel)]="term" (keyup)="hasFilter(searchBox.value.length > 0)"/>
        <span></span>
      </div>
      <div class="modal-box">
        <div class="modalContent wrapper-modal" style="position: relative;" [formGroup]="resourcesForm">
          <details *ngFor="let section of sections; let sidx = index" class="mt-6">
            <summary>
             <p title="{{ section.title }}">{{ section.title }}</p>
            </summary>
            <div class="content-modal mt-4">
              <div class=" pl-8 flex-auto">
                <label
                  *ngFor="let content of section.contentParsed | filter : term ; let i = index"
                  class="container flex justify-between my-2 mr-6"
                >
                  <div class="flex" [ngSwitch]="true" >
                    <img
                      class="pr-4 text-content-icon"
                      *ngSwitchCase="content?.fileType === 'File'"
                      alt="Arquivo de conteúdo"
                    />
                    <img
                      class="pr-4 text-content-icon"
                      *ngSwitchCase="content?.fileType === 'TextPage'"
                      alt="Conteúdo de texto"
                    />
                    <img
                      class="pr-4 complementary-content-icon"
                      *ngSwitchCase="content?.fileType === 'External'"
                      alt="Conteúdo externo"
                    />
                    <img
                      class="pr-4 complementary-content-icon"
                      *ngSwitchCase="content?.fileType === 'UploadAssignment'"
                      alt="Vídeo aula"
                    />
                    <img
                      class="pr-4 complementary-content-icon"
                      *ngSwitchCase="content?.fileType === 'Link'"
                      alt="Conteúdo Externo"
                    />
                    <img
                      class="pr-4 text-content-icon"
                      *ngSwitchDefault
                      alt="Conteúdo"
                    />
                   <div title="{{ content.title }}" class="content-title">{{ content.title }}</div>
                  </div>
                  <input
                    id="toogle"
                    type="checkbox"
                    [checked]="content.checked"
                    (click)="onChecked(sidx, i)"
                  />
                  <span class="check mr-6"></span>
                </label>
              </div>
            </div>
          </details>
        </div>
        </div>

      <!--footer-->
      <div class="flex flex-col md:flex-row items-center justify-center p-3 mt-6">
        <button class="button-save-resources" (click)="onSave()">
          <p>{{i18n.disciplines_save}}</p>
        </button>
        <button class="button-cancel-resources" (click)="closeResourcesCertificate()">
          <p>{{i18n.disciplines_cancel}}</p>
        </button>
      </div>
    </div>
  </div>
</app-generic-modal>
