import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  criterions: Criterion[] = [];
  sections: any[] = [];

  constructor(private http: HttpClient) {}

  getSections(discipline_external_id: string): Observable<any> {
    return this.http.get<any>(
      `${API_Routes.URL}/disciplines/${discipline_external_id}/sections`
    );
  }

  getSection(discipline_external_id: string, section_external_id?: string): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/sections/${section_external_id}`);
  }

  updateSection(
    discipline_external_id: string,
    section_external_id: string,
    params: any
  ): Observable<any> {
    return this.http.patch(
      `${API_Routes.URL}/disciplines/${discipline_external_id}/sections/${section_external_id}`,
      params
    );
  }

  postSectionsOrder(
    discipline_external_id: string,
    params: any
  ): Observable<any> {
    return this.http.patch<any>(
      `${API_Routes.URL}/disciplines/${discipline_external_id}/sectionsOrder`,
      params
    );
  }

  postSection(discipline_external_id: string, params: any): Observable<any> {
    return this.http.post<any>(
      `${API_Routes.URL}/disciplines/${discipline_external_id}/sections`,
      params
    );
    // return this.http.post(`http://localhost:3000/content`, params);
  }

  deleteSection(
    discipline_external_id: string,
    section_external_id: string
  ): Observable<any> {
    return this.http.delete<any>(
      `${API_Routes.URL}/disciplines/${discipline_external_id}/sections/${section_external_id}`
    );
    // return this.http.delete(`http://localhost:3000/content/${id}`);
  }
}

export interface Criterion {
  name: string;
  order: number;
  deleted: boolean;
  levels: Level[];
  id?: number;
}

export interface Level {
  name: string;
  description: string;
  points: number;
  order: number;
  deleted: boolean;
  id?: number;
}
