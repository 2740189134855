<app-generic-modal [isVisible]="isVisibleModalDetailedPlanning">
    <div class="py-6 container-modal">
      <header
        class="text-center text-customBlue-default border-b border-customBlue-glass pb-6 px-6">
        <p class=" font-bold text-26">{{ i18n.planner_detailed_planning }}</p>
        <p>{{ i18n.planner_select_how_want_work_planning }}</p>
      </header>
      <ul class="p-6 text-customBlue-default flex flex-col gap-4">
        <li *ngFor="let detailed of detailedPlanningList">
          <button type="button" (click)="selectPlanning(detailed.type)" class="w-full border border-customGray-mediumGray border-l-12 pl-3 py-5 sm:pr-12 rounded-2xl text-left" [ngClass]="{'border-customBlue-royal': detailed.isSelected }"
          [id]="detailed.type">
            <p class="font-semibold font-Montserrat">{{ detailed.title }}</p>
            <p class="text-14 font-Montserrat">{{ detailed.description }}</p>
          </button>
        </li>
      </ul>
      <footer class="flex flex-col sm:flex-row justify-center gap-3 p-6 sm:mt-16">
        <button type="button" (click)="resetModalData(); closeModal();" class="flex items-center justify-center w-full sm:w-auto text-customBlue-default border border-customBlue-default bg-transparent py-1.5 px-10 rounded">
            {{ i18n.planner_back }}
        </button>
        <button type="button" [disabled]="!selectedPlanning" [ngClass]="{'cursor-not-allowed opacity-75': !selectedPlanning }" (click)="onSubmit()" class="w-full sm:w-auto text-customWhite-default bg-customBlue-default py-1.5 px-10 rounded">
            {{ i18n.planner_continue }}
        </button>
    </footer>
    </div>
</app-generic-modal>