<div
  #dropdown
  class="dropdown"
  [class.is-expanded]="dropdownExpanded || awaysOpen"
  [class.is-marked]="marked"
  [class.block]="block"
  [style]="dropdownStyle"
  [class.is-view-only]="awaysOpen"
  [attr.data-id]="dataId"
>
  <div class="item__main item tag" (click)="toggleDropdown()">
    <div class="item__text">{{ modals.firstCharUpperCase(title) }}</div>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>

  <i class="arrow-icon dropdown__icon"></i>
</div>

<div
  #dropdown
  class="dropdown underblock"
  [style]="dropdownStyle"
  *ngIf="!block"
>
  <div class="item__main item tag">
    <div class="item__text">{{ title }}</div>
  </div>
  <i class="arrow-icon dropdown__icon"></i>
</div>

<!-- TWO DROPDOWNS, ONE FOR SET THE SPACE, OTHER FOR USER INTERACTION -->
