
<div class="flex bg-customWhite-default shadow-2xl rounded-lg" style="min-height: 720px;">
    <div class="flex flex-col w-full px-1 pb-2 pt-1">
        <div class="flex flex-row w-full justify-end items-center pt-2 pr-2">
            <button
            class="x-close__icon"
            (click)="showMainScreen()"
            ></button>
        </div>
        <div class="flex justify-center lg:justify-start">
            <div class="ml-2 flex flex-col justify-center gap-y-2 gap-x-2 lg:flex-row xl:flex-row mb-6">
                <div class="exportTxt flex justify-center">
                    <p>{{i18n.reports_export}}</p>
                </div>
              <div class="flex xs:flex-col flex-row gap-y-2 gap-x-2">
                <div class="button-export" (click)="getFile('xlsx')">
                    <p>XLSX</p>
                </div>
                <div class="button-export" (click)="getFile('csv')">
                    <p>CSV</p>
                </div>
                <div class="button-export" (click)="getFile('gsheet')" *ngIf="isNotStandalone">
                    <p> {{i18n.reports_google_sheets}} </p>
                </div>
              </div>
            </div>
        </div>
        <div class="flex flex-col w-full relative scroll-container" *ngIf="!loading">
          <div class="resources flex flex-row justify-center w-full sticky top-0 " style="z-index: 17;">
            <div class="flex flex-row w-full h-36" >
                <div class="w-14 flex flex-row" >
                  <div class="header-name" style="background: white; z-index: 17;">
                    <p> {{i18n.reports_name}} </p>
                  </div>
                    <div class="header-percent" style="background: white;" *ngFor="let content of contentUsersContents" >
                        <p title="{{content.name}}">
                            {{textCut(31, content.name) }}
                        </p>
                    </div>
                </div>
            </div>
          </div>
          <div class="flex flex-row mt-14" >
            <div class="col-name" >
              <div class="element-col-name"  *ngFor="let user of contentUsersUsers">
                <div class="text-name" title="{{user.name}}">
                    <p>{{user.name}}</p>
                </div>
              </div>
            </div>
            <div class="flex flex-col  w-full h-full">
              <div class="flex w-full h-full" *ngFor="let user of contentUsersUsers">
                <div class="flex flex-row" *ngFor="let resource of user.resources">
                  <div class="deliveries-datas w-14 h-14" >
                    <div *ngIf="resource.completed == true"  class="circle-check__icon"></div>
                      <div *ngIf="resource.completed == false"  class="circle-x__icon"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loading-container" *ngIf="loading">
            <div class="loading">
                <img src="../../../../../../../../assets/svg/loading.svg"/>
                <p>{{i18n.reports_wait}}</p>
                <p>{{i18n.reports_we_are_loading}}</p>
            </div>
        </div>
        <div class="bg-customWhite-default content rounded-md m-4" *ngIf="contentUsersUsers.length === 100">
          <div
          class="border-customBlue-royal content rounded-md p-2 text-16 font-semibold bg-opacity-20 bg-customBlue-royal text-customBlue-royal border"
          >
            <div class="flex flex-col sm:flex-row items-center gap-4">
              <span class="iconify text-32" data-icon="carbon:warning-filled"></span>
              <div class="flex flex-col text-center sm:text-left">
                <p class="text-16 font-semibold">
                  {{i18n.reports_limit_exceed}}
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>



<!-- <cdk-virtual-scroll-viewport itemSize="30" class="h-full">
  <div class="flex flex-col w-full">


    <div class="flex flex-row w-full " *cdkVirtualFor="let user of contentUsersUsers">
        <div class="flex flex-row " *ngFor="let resource of user.resources">
          <div class="deliveries-datas w-14 h-14">
            <div class="{{ isCompleted(resource) }}"></div>
          </div>
        </div>
    </div>

  </div>
</cdk-virtual-scroll-viewport> -->


<!-- <div class="flex flex-col w-full h-full" >
  <div class="flex gap-4">---</div>
  <div class="flex flex-row w-full">
    <div class="deliveries-datas w-14 h-14" *ngFor="let user of contentUsersUsers">
      <div class="{{ isCompleted(resource) }}"></div>
        C
    </div>
  </div>
</div> -->
