import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

interface Item {
  id: string,
  translation: string,
  active: boolean
}

@Component({
  selector: 'app-multiple-choice-dropdown',
  templateUrl: './multiple-choice-dropdown.component.html',
  styleUrls: ['./multiple-choice-dropdown.component.scss']
})

export class MultipleChoiceDropdownComponent implements AfterViewInit, OnDestroy {
  defaultStylization = {
    borderWidth: 1,
    closed: {
      borderColor: '#E3E4E4',
      textColor: '#233674',
      iconColor: '#233674'
    },
    expanded: {
      borderColor: '#233674',
      textColor: '#E3E4E4',
    }
  }

  @Input() title: string = 'your_dropdown_name_here';
  @Input() items: Item[];
  @Input() block: boolean = false;
  @Input() stylization = this.defaultStylization;

  @Output() change = new EventEmitter<Item[]>();

  @ViewChild('dropdown') dropdown: ElementRef;
  dropdownExpanded: boolean = false;
  dropdownKey: symbol;
  dropdownHeight: string;

  dropdownStyle: string;
  dataId: string;
  constructor(private shared: SharedService, private modals: PlatformModalsService) { }

  ngAfterViewInit(): void {
    this.calculateHeight();

    this.applyStyles();

    this.dropdownKey = this.shared.toCloseWhenClickOutside('.dropdown', this.dropdown, { _this: this, propertyToBeToggled: 'dropdownExpanded' });
    this.dataId = `${[...this.items].reverse()[0].translation} ${this.items.length}`;
  };

  toggleDropdown() {
    this.dropdownExpanded = !this.dropdownExpanded;
    this.dropdown.nativeElement.scrollTop = 0;
  }

  calculateHeight () {
    const totalHeight = this.dropdown.nativeElement.scrollHeight + this.stylization.borderWidth * 2;
    const heightOverflowLimit = 200 + this.stylization.borderWidth * 2;
    const shouldHaveScrollBar = totalHeight > heightOverflowLimit;
    this.dropdownHeight = `--height: ${shouldHaveScrollBar ? heightOverflowLimit : totalHeight}px;`;/* setting the dropdown height when active */

    if (!shouldHaveScrollBar) {
      this.dropdown.nativeElement.style.animation = 'none';
      this.dropdown.nativeElement.style.overflow = 'hidden';
    }
  }

  toggleFilter(item: Item) {
    const itemState = item.active;
    const itemId = item.id;

    this.items.forEach((item: Item) => {
      if (item.id === itemId) item.active = !itemState;
    });

    this.change.emit(this.items)
  }

  applyStyles() {
    const { borderWidth, closed, expanded } = this.stylization;

    const borderWidthPx = `--border-width: ${borderWidth}px;`;

    const borderColorClosed = `--closed-border-color: ${closed.borderColor};`;
    const textColorClosed = `--closed-text-color: ${closed.textColor};`;
    const iconColorClosed = `--closed-icon-color: ${this.modals.hexToCSSFilter(closed.iconColor)};`;

    const borderColorExpanded = `--expanded-border-color: ${expanded.borderColor};`;
    const textColorExpanded = `--expanded-text-color: ${expanded.textColor};`;

    this.dropdownStyle = `${borderWidthPx} ${borderColorClosed} ${textColorClosed} ${iconColorClosed} ${borderColorExpanded} ${textColorExpanded} ${this.dropdownHeight}`;
  }

  ngOnDestroy(): void {
    if (this.dropdownKey !== undefined) this.shared.toCloseWhenClickOutside(this.dropdownKey);
  }
}
