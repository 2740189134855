<div >
    <div 
        class="flex relative items-center rounded-md animate-pulse ml-0 m-4"
        style="height: 16px;background-color: #DEDFE3; width: 350px;"
    ></div>
    
    <div 
        class="flex flex-row gap-2"
        *ngFor="let line of lines; let i = index"
    >
        <div 
            class="flex relative items-center rounded-md animate-pulse mb-1"
            style="height: 8px;background-color: #DEDFE3; "
            [style.width]="line['width_description' + i] + '%'"
        ></div>
    </div>

    <div 
        class="flex items-center justify-end"
    >
        <div class="lg:flex flex xs:flex-col-reverse text-center items-center gap-2">
            <div class="grid text-center">
                <span 
                    class="text-customGray-darker font-bold text-sm"
                    style="white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 250px;"
                >
                    <div 
                        class="flex relative items-center rounded-md animate-pulse mb-1"
                        style="height: 8px;background-color: #DEDFE3; width: 100px;"
                    ></div>
                </span>
                <div 
                    class="flex relative items-center rounded-md animate-pulse"
                    style="height: 8px;background-color: #DEDFE3; width: 75px;"
                ></div>
            </div>
            <div
                class="rounded-full"
                style="background-color: #DEDFE3"
                [style]="{'width':'40px', 'height':'40px'}"
            >
            </div>
        </div>
    </div> 
</div>