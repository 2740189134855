<div class="submission-wrapper">
  <div class="submission submission-box outline">

    <header class="flex gap-3 justify-between flex-wrap w-full px-3 pb-3 border-b border-customBlue-glass">
      <div class="flex gap-3">
        <div class="flex items-center gap-2">
            <h2 class="title">COD {{ question_number }}</h2>
            <div 
                *ngIf="score_value"
                class="question-value" 
                [class.no-comment]="!comments.length" 
            >
                {{ i18n.disciplines_content_card_rubric_valid }}: {{ score_value }}
            </div>
        </div>
  
        <div class="flex">
          <div class="flex gap-2 font-normal text-customGray-default">
            <span class="iconify w-5 h-5" data-icon="ph:book-bookmark-thin"></span>
            {{ question_subject }}
          </div>
        </div>
      </div>

      <div class="cursor-pointer" (click)="scrollTo()">
        <div 
          class="flex gap-2 title" pTooltip="{{ i18n.disciplines_insert_comment }}" tooltipPosition="top" pRipple
        >
          <ng-container  *ngIf="comments.length">
            {{ comments.length }}
          </ng-container>
          <span class="iconify w-5 h-5" data-icon="fa-regular:comment"></span>
        </div>
      </div>
    </header>

    <div class="question">
      <div #title class="question-title" *ngIf="question_title" [style.padding-left]="question_title_left_padding" 
      [innerHTML]="question_title | safeHTML">
        <!-- description here  -->
      </div>

      <div class="question-body">
        <ng-content></ng-content>
      </div>
    </div>

  </div>
  <div #scrollToElement>
    <app-submission-comment
      #refSubmissionComment
      [commentsVisibility]="isCommentOpen"
    ></app-submission-comment>
  </div>
