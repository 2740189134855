import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleChoiceDropdownComponent } from './single-choice-dropdown/single-choice-dropdown.component';
import { MultipleChoiceDropdownComponent } from './multiple-choice-dropdown/multiple-choice-dropdown.component';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { ButtonsModule } from '../buttons/buttons.module';

@NgModule({
  declarations: [
    SingleChoiceDropdownComponent,
    MultipleChoiceDropdownComponent,
    CustomDropdownComponent
  ],
  exports: [
    SingleChoiceDropdownComponent,
    MultipleChoiceDropdownComponent,
    CustomDropdownComponent
  ],
  imports: [
    CommonModule,
    ButtonsModule,
  ]
})

export class DropdownsModule { }
