<ng-container *ngIf="user?.role_type === 'student' && content?.end_date !== null">

    <div 
        class="w-full border p-1 mb-4 bg-customWhite-default rounded-md"
        [ngClass]="{
            'border-customOrange-default': status === 'not_allow_late_delivery',
            'border-customBlue-royal': status === 'delivery_on_time',
            'border-customBlue-default': (status === 'allow_late_delivery' || status === 'late_delivery')
        }"
    >
        <div class="flex flex-col justify-center text-center">
            <div class="flex justify-center">
                <div 
                    class="flex justify-center items-center bg-customGray-iceGray rounded-full w-10 h-10"
                    [ngClass]="{
                        'bg-customOrange-default': status === 'not_allow_late_delivery'
                    }"
                >
                    <span 
                        class="iconify w-5 h-5" 
                        data-icon="mdi:calendar-text"
                        [ngClass]="{
                            'text-customWhite-default': status === 'not_allow_late_delivery',
                            'text-customBlue-royal': status === 'delivery_on_time',
                            'text-customBlue-default': (status === 'allow_late_delivery' || status === 'late_delivery')
                        }"
                    ></span>
                </div>
            </div>
            <span 
                class="text-13 mt-1"
                [ngClass]="{
                    'text-customGray-dark': status === 'not_allow_late_delivery',
                    'text-customBlue-royal': status === 'delivery_on_time',
                    'text-customBlue-default': (status === 'allow_late_delivery' || status === 'late_delivery' )
                }"
            >
                {{ statusText }}
            </span>
            <span 
                class="text-16 font-medium"
                [ngClass]="{
                    'text-customOrange-default': status === 'not_allow_late_delivery',
                    'text-customBlue-royal': status === 'delivery_on_time',
                    'text-customBlue-default': (status === 'allow_late_delivery' || status === 'late_delivery')
                }"
            >
                {{ content?.status === "COMPLETE" ? getDateTime(content?.coursework_completed_at) : getDateTime(content?.end_date) }}
            </span>
        </div>
    </div>
</ng-container>
