import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { BulletsLoaderModule } from 'src/app/components/bullets-loader/bullets-loader.module';
import { ButtonsModule } from 'src/app/components/buttons/buttons.module';
import { DisplayPanelModule } from 'src/app/components/display-panel/display-panel.module';
import { EmptyDataModule } from 'src/app/components/empty-data/empty-data.module';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { PaginationModule } from 'src/app/components/pagination/pagination.module';

import { ModalSuccessPlanningComponent } from './components/modals/modal-success-planning/modal-success-planning.component';
import { CurrentYearComponent } from './components/planning/current-year/current-year.component';
import { ModalDecisionModuleComponent } from './components/planning/curricular-parameters/components/modal-decision-module/modal-decision-module.component';
import { ModalDetailedPlanningComponent } from './components/planning/curricular-parameters/components/modal-detailed-planning/modal-detailed-planning.component';
import { ModalSelectSkillsComponent } from './components/planning/curricular-parameters/components/modal-select-skills/modal-select-skills.component';
import { CurricularParametersComponent } from './components/planning/curricular-parameters/curricular-parameters.component';
import { DetailedClassToClassComponent } from './components/planning/detailed-planning/detailed-class-to-class/detailed-class-to-class.component';
import { DetailedEstimatedComponent } from './components/planning/detailed-planning/detailed-estimated/detailed-estimated.component';
import { ModalMaterialListComponent } from './components/planning/my-planning/modal-material-list/modal-material-list.component';
import { MyPlanningComponent } from './components/planning/my-planning/my-planning.component';
import { StartPlanningComponent } from './components/planning/start-planning/start-planning.component';
import { ContentPlannerComponent } from './content-planner.component';
import { ContentPlannerRoutingModule } from './content-planner-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { ModalResourceComponent } from './components/modals/modal-resource/modal-resource.component';
import { TypesIconsComponent } from './components/modals/types-icons/types-icons.component';
import { ModalLoadingComponent } from './components/modals/modal-loading/modal-loading.component';

@NgModule({
    declarations: [
        ContentPlannerComponent,
        StartPlanningComponent,
        CurricularParametersComponent,
        ModalSelectSkillsComponent,
        ModalSuccessPlanningComponent,
        ModalDetailedPlanningComponent,
        DetailedClassToClassComponent,
        DetailedEstimatedComponent,
        CurrentYearComponent,
        ModalDecisionModuleComponent,
        MyPlanningComponent,
        ModalMaterialListComponent,
        ModalResourceComponent,
        TypesIconsComponent,
        ModalLoadingComponent,
    ],
    imports: [
        CommonModule,
        ContentPlannerRoutingModule,
        ButtonsModule,
        DisplayPanelModule,
        ReactiveFormsModule,
        FormsModule,
        MultiSelectModule,
        GenericModalModule,
        BulletsLoaderModule,
        EmptyDataModule,
        DragDropModule,
        TooltipModule,
        PaginationModule,
        DropdownModule
    ],
})
export class ContentPlannerModule {}
