<section
  class="px-8 mt-8 mb-4 py-8 shadow-card round-card transition ease-out duration-180 animate-fade-in-down bg-customWhite-default"
>
  <h1 class="text-customBlue-default font-bold text-xl">
    {{ i18n.grades_grades }}
  </h1>
  <ng-container *ngIf="!isLoading">
    <form [formGroup]="settingsGradesForm">
      <div class="text-customGray-dark">
        <div class="my-8">
          <div
            class="text-left px-4 py-2"
            [ngClass]="
              gradesSettings.concept_enable ? 'border-check' : 'border-invisible'
            "
          >
            <div class="flex items-center">
              <label class="container-checkbox flex">
                <input
                  type="checkbox"
                  formControlName="concept_enable"
                  (change)="ableCheckEvaluation('isCheckTrueConcept')"
                />
                <span
                  class="check"
                  [ngClass]="{ checked: gradesSettings.concept_enable }"
                ></span>
              </label>
              <div class="flex flex-col">
                <span>{{ i18n.grades_enable_concept_evaluation }}</span>
                <span class="text-xs">{{
                  i18n.grades_grades_enable_description
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="text-left px-4 py-2"
            [ngClass]="
              gradesSettings.numeric_enable ? 'border-check' : 'border-invisible'
            "
          >
            <div class="flex items-center">
              <label class="container-checkbox flex">
                <input
                  type="checkbox"
                  formControlName="numeric_enable"
                  (change)="ableCheckEvaluation('isCheckTrueNumber')"
                />
                <span
                  class="check"
                  [ngClass]="{ checked: gradesSettings.numeric_enable }"
                ></span>
              </label>
              <div class="flex flex-col">
                <span>{{ i18n.grades_enable_numeric_evaluation }}</span>
                <span class="text-xs">{{
                  i18n.grades_enable_numeric_description
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center my-4 gap-4">
          <input
            formControlName="rubric_enable"
            id="toogle"
            style="height: 1.4rem"
            class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
            type="checkbox"
          />
          <label>{{ i18n.grades_enable_rubrics }}</label>
          <i
            (click)="displayPanelRubric.toggle()"
            class="circle-interrogation-icon circle-interrogation cursor-pointer"
          ></i>
          <div class="relative">
            <app-display-panel
              #displayPanelRubric
              [style]="{ position: 'absolute' }"
              [custom_radius]="'10px'"
              [hide_arrow]="true"
              [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
              [newStyle]="{ top: '-13.125rem', right: '-0.1875rem' }"
            >
              <small>{{
                i18n.disciplines_content_help_rubric_create_tooltip
              }}</small>
            </app-display-panel>
          </div>
        </div>
        <div class="flex items-center my-4 gap-4">
          <input
            formControlName="average_rounding_enable"
            id="toogle"
            style="height: 1.4rem"
            class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
            type="checkbox"
          />
          <label>{{ i18n.grades_possibility_final_grade_rounded }}</label>
          <i
            (click)="displayPanel.toggle()"
            class="circle-interrogation-icon circle-interrogation cursor-pointer"
          ></i>
          <div class="relative">
            <app-display-panel
              #displayPanel
              [style]="{ position: 'absolute', right: '-100px', top: '17px' }"
              [custom_radius]="'10px'"
              [hide_arrow]="true"
              [panel_style]="{ background: '#FEF8E6' }"
            >
              <small
                class="text-customGray-dark font-normal"
                style="width: 250px"
              >
                {{ i18n.grades_this_option_rounding_final_average_students
                }}
              </small>
            </app-display-panel>
          </div>
        </div>
        <app-settings-concept
        *appCheckPermissions="'grades_concepts'; type: 'list'"
          [discipline_external_id]="discipline_external_id"
          [isVisible]="gradesSettings.concept_enable"
        ></app-settings-concept>
      </div>
      <div class="flex row justify-end">
        <div class="">
          <button
            class="flex border rounded-md py-2 px-8 font-bold text-sm border-customGray-textGray"
            [ngClass]="{
              'disabled:opacity-20 cursor-not-allowed':
                !settingsGradesForm.valid || settingsGradesForm.pristine
            }"
            style="color: #ffffff; background-color: #233674"
            (click)="patchGradesSettings()"
            [disabled]="!settingsGradesForm.valid || settingsGradesForm.pristine"
          >
            <span>{{ i18n.grades_save }}</span>
          </button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="flex justify-center items-center" style="height: 300px;">
      <app-bullets-loader></app-bullets-loader>
    </div>
  </ng-container>
</section>

<!-- Modal de confirmação de ação -->
<app-modal-confirmation *ngIf="false"></app-modal-confirmation>
