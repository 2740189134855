import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minDateValidator(refDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const inputDate = control.value == null ? '' : control.value;
        if (inputDate.length !== 0 && inputDate < refDate) {
            return { dateError: true };
        }
        return null;
    };
}

export function maxValueValidator(maxValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let { value } = control;
        if (value.includes(',')) {
            value = value.replace(',', '.');
        }
        value = parseFloat(value);

        if (value !== null && value !== undefined && value > maxValue) {
            return { maxValue: { value: control.value } };
        }
        return null;
    };
}
