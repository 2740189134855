import { SharedService } from './../../../../../../shared/shared.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-view-list',
  templateUrl: './view-list.component.html',
  styleUrls: ['./view-list.component.scss'],
})
export class ViewListComponent implements OnInit {
  i18n: any = {};
  @Input() data: any;
  @Input() viewOnly: boolean = false;
  @Input() correction: boolean;

  @Output() change = new EventEmitter();
  @Output() feedback = new EventEmitter();

  optionKey: string;

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.getTranslations();
    this.optionKey =
      this.data.options[0].selected !== undefined ? 'selected' : 'correct';

    if (this.correction) {
      this.optionKey = 'selected';
      this.setCorrection();
      this.checkIfItIsCorrect();
    }
  }

  toggleAlternative(alternative) {
    if (this.viewOnly) return;

    const currentAlternativeValue = alternative[this.optionKey];

    this.resetBalls();
    alternative[this.optionKey] = Number(!currentAlternativeValue);

    this.data.user_response = [
      this.data.options.map((option) => option[this.optionKey]),
    ];
    this.change.emit();
  }

  resetBalls() {
    this.data.options.forEach((option) => (option[this.optionKey] = 0));
  }

  setCorrection() {
    this.data.options = this.data.options.map((option) => {
      if (option.selected === 0 && option.correct === 0) {
        option.correction = 3;
      } else if (option.selected && !option.correct) {
        option.correction = 0;
      } else {
        option.correction = 1;
      }

      return option;
    });
  }

  checkIfItIsCorrect() {
    const blankAnswer = this.data.options.every(
      (option) => option.correction === undefined
    );

    if (blankAnswer) return;

    const isCorrect = !this.data.options.some(
      (option) => option.correction === 0
    );

    this.feedback.emit({ isCorrect });
  }

  getTranslations() {
    this.i18n = this.sharedService.getTranslationsOf('Disciplines');
  }
}
