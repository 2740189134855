import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';

import { PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetPasswordGuard } from './pages/reset-password/reset-password.guard';


const routes: Routes = [
  { path: 'login', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    component: LoginComponent,
  },
  {
    canActivate: [ResetPasswordGuard],
    path: 'reset',
    component: ResetPasswordComponent,
  },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canLoad: [AuthGuard] },
  { path: 'disciplines', loadChildren: () => import('./pages/disciplines/disciplines.module').then(m => m.DisciplinesModule), canLoad: [AuthGuard] },
  { path: 'disciplines-models', loadChildren: () => import('./pages/disciplines-models/disciplines-models.module').then(m => m.DisciplinesModelsModule) },
  { path: 'import', loadChildren: () => import('./pages/import/import.module').then(m => m.ImportModule) },
  { path: 'import-spreadsheet', loadChildren: () => import('./pages/import-spreadsheet/import-spreadsheet.module').then(m => m.ImportSpreadsheetModule) },
  { path: 'support', loadChildren: () => import('./pages/widget-help/widget-help.module').then(m => m.WidgetHelpModule) },
  { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule) },
  { path: 'orgunits', loadChildren: () => import('./pages/org-units/org-units.module').then(m => m.OrgUnitsModule), canLoad: [AuthGuard] },
  { path: 'permissions', loadChildren: () => import('./pages/permissions/permissions.module').then(m => m.PermissionsModule), canLoad: [AuthGuard] },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },//verificar se essa rota vai ser usada
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule), canLoad: [AuthGuard] },
  { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), canLoad: [AuthGuard] },
  { path: 'certificates', loadChildren: () => import('./pages/certificates-validator/certificates-validator.module').then(m => m.CertificatesValidatorModule) },
  { path: 'certificates-dashboard', loadChildren: () => import('./pages/certificates-dashboard/certificates-dashboard.module').then(m => m.CertificatesDashboardModule) },
  { path: 'certifications', loadChildren: () => import('./pages/certifications/certifications.module').then(m => m.CertificationsModule)},
  { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule), canLoad: [AuthGuard] },
  { path: 'chromebook-dashboard-extension', loadChildren: () => import('./pages/chromebook-extension/chromebook-extension.module').then(m => m.ChromebookExtensionModule), canLoad: [AuthGuard] },
  { path: 'assiduity', loadChildren: () => import('./pages/assiduity/assiduity.module').then(m => m.AssiduityModule), canLoad: [AuthGuard]},
  { path: 'content-planner', loadChildren: () => import('./pages/content-planner/content-planner.module').then(m => m.ContentPlannerModule), canLoad: [AuthGuard] },
  // { path: 'reset', loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: '**', loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
