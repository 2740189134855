<div class="view-multiple-choice" *ngIf="alternativesText">
  <div
    class="alternative"
    *ngFor="let alternative of data.options; let index = index"
    (click)="toggleAlternative(alternative)"
    [class.is-view-only]="viewOnly"
    [ngClass]="{
      'is-correct': alternative.correction === 1,
      'is-wrong': alternative.correction === 0
    }"
  >
    <app-select-button
      class="alternative__ball"
      [active]="alternative[optionKey]"
    ></app-select-button>

    <div
      class="text-customGray-dark"
      [innerHTML]="alternativesText[index]"
    ></div>
  </div>
</div>
