import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-content-view-skeleton',
  templateUrl: './content-view-skeleton.component.html',
  styleUrls: ['./content-view-skeleton.component.scss']
})
export class ContentViewSkeletonComponent implements OnInit {
  @Input() expanded: boolean;

  contentDescription: any = [{},{},{},{},{},{}];

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    this.contentDescription.forEach((element, index) => {
      if (index == 0) {
        element['width_description'+index] = 45;
        return
      }
      element['width_description'+index] = this.sharedService.generateRandomNumber(80,85);
    });

  }

}
