import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { API_Routes } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { ContentSharedService } from 'src/app/pages/disciplines/components/content/content-shared.service';
//import { setInterval, clearInterval } from 'timers';

@Injectable({
  providedIn: 'root',
})
export class ConditionalAndCriteriaService {
  timerObs = new Subject<any>();
  statusObs = new Subject<void>();
  disciplineExtId: any;
  contentExtId: any;
  timeStatus: any;
  timer: any;
  params = {};

  constructor(
    private contentSharedService: ContentSharedService,
    private http: HttpClient
  ) {}

  loadContentTrue() {
    this.contentSharedService.isLoadingTrue();
  }

  loadContentFalse() {
    this.contentSharedService.isLoadingFalse();
  }

  postCompletedContent(
    discipline_external_id: string,
    content_ext_id: string,
    params: any
  ): Observable<any> {
    console.log('DEBUG STUDENT postCompletedContent');
    return this.http
      .post<any>(
        `${API_Routes.URL}/disciplines/${discipline_external_id}/syllabus/contents/${content_ext_id}/finish`,
        params
      )
      .pipe(take(1));
  }

  whatIsTheCriterion(criteriaType) {
    switch (criteriaType) {
      case 'ACCESS':
        //this.accessMethod();
        break;
      case 'TIME':
        //this.timeMethod();
        break;
      case 'ATTEMPT_SUBMISSION':
       // this.attemptSubmissionMethod();
        break;
      case 'STUDENT_NOTIFICATION':
        //this.studentNotificationMethod();
        break;
    }
  }

  receivesContent(discipline_ext_id: any, content_ext_id: any) {
    this.disciplineExtId = discipline_ext_id;
    this.contentExtId = content_ext_id;
  }

  getTimer(): Observable<any> {
    return this.timerObs.asObservable();
  }

  whatIsTheStatus(): Observable<any> {
    console.log('STOP whatIsTheStatus() CHAMADO');
    return this.statusObs.asObservable();
  }

  stopTimer() {
   clearInterval(this.timer)
    console.log('STOP TIMER CHAMADO');
  }

  timerPost() {
    console.log('DEBUG STUDENT timerPost');
    this.postCompletedContent(
      this.disciplineExtId,
      this.contentExtId,
      this.params
    ).subscribe(
      (response) => {
        this.timeStatus = response?.status;
        this.statusObs.next(this.timeStatus)
        if (this.timeStatus == 'COMPLETE') {
          clearInterval(this.timer)
        }
      },
      () => clearInterval(this.timer),
      () => {}
    );
  }

  timeMethod() {
    let counter = 0;

    this.timer = setInterval(() => {
      counter++;
      if(this.timeStatus != 'COMPLETE') {
        this.timerPost();
      }
        //this.timerObs.next(counter);
        //this.statusObs.next(this.timeStatus);
      }, 60000);

    /* if (this.timeStatus == 'COMPLETE') {
      //this.statusObs.next(this.timeStatus);
      clearInterval(this.timer)
      console.log('ENTROU NO timeMethod: COMPLETE');
    } */
  }

  accessMethod() {
    //this.statusObs.next();
  }

  attemptSubmissionMethod() {
    console.log('DEBUG STUDENT attemptSubmissionMethod');
    this.postCompletedContent(
      this.disciplineExtId,
      this.contentExtId,
      this.params
    ).subscribe(
      () => {},
      () => {},
      () => this.statusObs.next()
    );
  }

  studentNotificationMethod() {
    console.log('DEBUG STUDENT NOTIFICATION: ',
     this.disciplineExtId,
    this.contentExtId,);

    this.postCompletedContent(
      this.disciplineExtId,
      this.contentExtId,
      this.params
    ).subscribe(
      () => {},
      () => {},
      () => this.statusObs.next()
    );
  }

}
