<!--content-->
<div >
  <ng-container *ngFor="let section of sections; let i = index">
    <div 
        class="section mb-6" 
    >
      <div class="relative flex flex-col w-full ">
        <!--header-->
        <div 
            class="flex relative items-center rounded-t animate-pulse"
            style="height: 53px;background-color: #DEDFE3;"
        >
            <div
                class="rounded-full absolute -top-4 right-5 w-8 h-8"
                style="background-color: #ffffff;"
            >
            </div>
        </div>

        <!--body-->
        <!-- Teacher View -->
        <div 
            *ngIf="i !== 1"
            class="animate-pulse"
            style="height:200px;background-color: #ffffff;"
        >
            <ng-container *ngFor="let section of sections; let i = index">

                <div class="flex px-4 py-4">
                    <div 
                        class="rounded-full w-4 h-4"
                        style="background-color: #DEDFE3;"
                    >
                    </div>
    
                    <div 
                        class="ml-2 rounded " 
                        style="background-color: #DEDFE3;"
                        [style.width]="section['width_description' + i] + '%'"
                    >
                    </div>
                </div>
            </ng-container>
        </div> 

      </div>
    </div>
  </ng-container>
</div>