import {
    Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
    selector: 'app-modal-decision-module',
    templateUrl: './modal-decision-module.component.html',
    styleUrls: ['./modal-decision-module.component.scss']
})
export class ModalDecisionModuleComponent {
    @Output() closeModalDecisionModuleAndNotSave = new EventEmitter();
    @Output() closeModalDecisionModuleAndSave = new EventEmitter();
    @Input() isVisibleModalDecisionModule = false;
    @Input() i18n: any = {};

    closeModalAndNotSave(): void {
        this.closeModalDecisionModuleAndNotSave.emit(false);
    }

    closeModalAndSave(): void {
        this.closeModalDecisionModuleAndSave.emit(false);
    }
}
