import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'average-time-use',
  template: ` 
  <div class="bar-chart">
    <div class="chart-bar-title">{{title}}</div>
    <div class="chart-bar-wrapper" *ngIf="averageTimeData?.length">
      <div #containerRef class="chart-class-container">
        <ngx-charts-bar-vertical
          [view]="[containerRef.offsetWidth, 240]"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [results]="averageTimeData"
          [barPadding]="barPadding"
          [rotateXAxisTicks]="rotateXAxisTicks"
          [animations]="animations"
          [maxXAxisTickLength]="maxXAxisTickLength"
          [yAxisTickFormatting]="yTickFormattingFn"
          [xAxisTickFormatting]="xTickFormattingFn"
          style="fill: #A9AEB3;"
        >
        <ng-template #tooltipTemplate let-model="model">
              <!-- <pre class="mt-1">{{model.name}}</pre> -->
              <pre class="my-3">{{model.value + ' h'}}</pre>
            </ng-template>
        </ngx-charts-bar-vertical>
      </div>
    </div>
    <div class="mt-6" *ngIf="!averageTimeData?.length"><app-empty-data></app-empty-data></div>
  </div>
 `,
  styleUrls: ['./adm-styles.scss'],
})
export class AverageTimeUseComponent implements OnInit, OnChanges {

  @Input() adminData: any 
  @Input() i18n: any 
  averageTimeData: any[] = [];
  title: string

  view: any[];

  // options
  showXAxis = true;
  showYAxis = true;
  animations = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Country';
  showYAxisLabel = false;
  rotateXAxisTicks = true;
  yAxisLabel = 'Population';
  maxXAxisTickLength = 10
  barPadding = 12

  colorScheme = {
    domain: ['#D8E5F8'],
  };

  constructor() { }

  ngOnInit(): void {
    this.title = this.i18n.dash_average_platform_time
  }

  ngOnChanges(): void {
    this.formatDataAverageTime();
  }

  formatDataAverageTime() {
    const timeUse = this.adminData.average_time_use || [];
    if (timeUse) {
      this.averageTimeData = timeUse
      .map(averageTimeUse => {
        return {
          name: averageTimeUse.month,
          value: averageTimeUse.time
        };
      }) 
    }
  }

  yTickFormattingFn = this.yAxisTickFormatting.bind(this);

  yAxisTickFormatting(value){ 
    return this.yPercentTickFormatting(value);
  }

  yPercentTickFormatting(val: any) {
    return val  + ' h';
  }

  xTickFormattingFn = this.xAxisTickFormatting.bind(this);

  xAxisTickFormatting(value){ 
    return this.xPercentTickFormatting(value);
  }

  xPercentTickFormatting(val: any) {
    if(val > 0) {
    return this.whatIsTheMonth(val)
    } else {
      return val
    }
  }

  whatIsTheMonth(month: any) {
    switch (month) {
      case 1:
                return this.i18n.dash_jan
          break;
      case 2:
                return this.i18n.dash_feb;
          break;
      case 3:
                return this.i18n.dash_mar;
          break;
      case 4:
                return this.i18n.dash_apr;
          break;
      case 5:
                return this.i18n.dash_may;
          break;
      case 6:
                return this.i18n.dash_june;
          break;
      case 7:
                return this.i18n.dash_jul;
          break;
      case 8:
                return this.i18n.dash_aug;
          break;
      case 9:
                return this.i18n.dash_sept;
          break;
      case 10:
                return this.i18n.dash_oct;
          break;
      case 11:
                return this.i18n.dash_nov;
          break;
      case 12:
                return this.i18n.dash_dec;
          break;
    }
   }
}
