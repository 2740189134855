import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'structure-resume',
  template: ` 
<div class="structure-resume-container">
  <div class="structure-resume"> 
  <div class="w-full p-2.5 text-customBlue-default font-medium">{{title}}</div>
    <div class="structure-resume-scroll" *ngIf="structureData.length">  
      <div class="table-wrapper-labels">
        <div class="cols-table-wrapper">
          <div class="cols-table-content">{{i18n.dash_unit_dash}}</div>
          <div class="cols-table-content">{{i18n.dash_classes}}</div>
          <div class="cols-table-content">{{i18n.dash_resources}}</div>
        </div>
        <ng-container *ngFor="let structure of structureData" >
        <div class="cols-table-wrapper-data">
          <div class="cols-table-content">{{structure.unit}}</div>
          <div class="cols-table-content">{{structure.disciplines}}</div>
          <div class="cols-table-content">{{structure.contents}}</div>
        </div>
        </ng-container>
      </div> 
    </div>   
    <div class="mt-6" *ngIf="!structureData.length"><app-empty-data></app-empty-data></div>     
  </div>
</div>
 `,
  styleUrls: ['./adm-styles.scss'],
})
export class StructureResumeComponent implements OnInit, OnChanges {
  @Input() adminData: any 
  @Input() i18n: any 

  structureData: any[] = [];
  title: string
 
  ngOnInit(): void {
    this.title = this.i18n.dash_structure_summary
  }
  
  ngOnChanges(): void {
    this.formatDataAverageTime();
  }

  formatDataAverageTime() {
    const structureResumes = this.adminData?.structure_resume || [];
    this.structureData = structureResumes;
  }
}
