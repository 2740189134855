<ng-container *ngIf="!isLoading">
  <!-- Certificate Button -->
  <div class="flex justify-end mt-8" *ngIf="user.role_type === 'student' && disciplineDetail?.certificate_id">
    <button (click)="emitCertificate(disciplineDetail?.certificate_id)"
      class="bg-transparent text-customBlue-royal font-semibold py-1 px-4 rounded border-2 border-customBlue-royal">
      <p>{{ i18n.disciplines_overview_certificate }}</p>
    </button>
  </div>

  <!-- Feed -->
  <div class="rounded-lg mt-8" *ngIf="false">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div
        class="text-start p-5 border-b border-solid border-customBlue-light rounded-t flex items-center justify-between">
        <h3 class="text-xl font-bold text-customBlue-royal">
          {{ i18n.disciplines_overview_course_feed }}
        </h3>
      </div>
      <!--body-->
      <div class="p-6 discipline-box">
        <ng-container *ngIf="disciplineDetail?.syllabus.length > 0">
          <div *ngFor="let feed of disciplineDetail?.syllabus"
            class="flex items-center justify-start m-2 p-2 rounded bg-feed">
            <div class="flex items-center">
              <!-- <div [ngSwitch]="true">
                            <svg *ngSwitchCase="feed?.icon == 'class'" width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.514 18.6667H21V2.33333C21 1.71449 20.7542 1.121 20.3166 0.683417C19.879 0.245833 19.2855 0 18.6667 0H3.5C2.093 0 0 0.932167 0 3.5V19.8333C0 22.4012 2.093 23.3333 3.5 23.3333H21V21H3.514C2.975 20.986 2.33333 20.7725 2.33333 19.8333C2.33333 18.8942 2.975 18.6807 3.514 18.6667ZM5.83333 4.66667H16.3333V7H5.83333V4.66667Z" fill="#233674"/>
                            </svg>
                        </div> -->
              <div>
                <app-content-types-icons class="content-types-icons" [type]="feed?.type"></app-content-types-icons>
              </div>
              <span class="ml-2">{{ feed?.title }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="disciplineDetail?.syllabus.length == 0">
          <div class="flex">
            <div class="text-customBlue-default">
              {{ i18n.disciplines_overview_no_message_yet }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Ementa and Participants -->
  <div class="mt-8 grid lg:grid-cols-2 md:grid-cols-1 gap-4">
    <!-- Ementa  -->
    <div
      class="relative border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!-- Button  -->
      <button type="button" (click)="toggleModalEmenta()" *appCheckPermissions="'disciplines'; type: 'edit'"
        class="absolute right-6 p-2 bg-customBlue-royal text-customWhite-default rounded-full text-18"
        [style.top]="'-15px'">
        <span class="iconify-inline" data-icon="akar-icons:edit"></span>
      </button>
      <!--header-->
      <div class="text-start p-5 border-b border-solid border-customBlue-light rounded-t">
        <h3 class="text-xl font-bold text-customBlue-royal">
          {{ i18n.disciplines_overview_course_program }}
        </h3>
      </div>
      <!--body-->
      <div class="p-6 discipline-box">
        <div 
            class="text-customGray-dark break-words" 
            #descriptionText
            [innerHTML]="disciplineDetail?.description || '' | safeHTML"
        ></div>
        <!-- Empty -->
        <ng-container *ngIf="disciplineDetail?.description === ''">
          <div class="flex">
            <div class="text-customBlue-default">
              {{ i18n.disciplines_overview_no_program_yet }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Participantes  -->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div
        class="text-start flex items-center justify-between p-5 border-b border-solid border-customBlue-light rounded-t">
        <h3 class="text-xl font-bold text-customBlue-royal">
          {{ i18n.disciplines_overview_participants }}
        </h3>
        <div *ngIf="isNotStandalone">
          <button type="button" (click)="displayPanel.toggle()"
            *ngIf="disciplineDetail.general_settings?.gmail || disciplineDetail.general_settings?.google_chat"
            class="border border-customBlue-royal rounded text-customBlue-royal font-bold py-1.5 px-8 relative">{{
            i18n.disciplines_options }}
            <app-display-panel #displayPanel [style]="{ right: '22px', top: '54px', 'z-index': '7'}" [arrow_deslocation]="'100%'"
              [custom_radius]="'10px'" [lockWidth]="true">
              <button type="button" *ngIf="disciplineDetail.general_settings?.gmail" (click)="toggleModalSend('mail')"
                class="whitespace-nowrap p-1 text-customBlue-default">{{ i18n.disciplines_send_gmail }}</button>
              <button type="button" *ngIf="disciplineDetail.general_settings?.google_chat"
                (click)="toggleModalSend('chat')" class="whitespace-nowrap p-1 text-customBlue-default">{{
                i18n.disciplines_send_chat }}</button>
            </app-display-panel>
          </button>
        </div>
      </div>
      <!--body-->
      <div *ngIf="disciplineDetail" class="p-6 discipline-box">
        <ng-container *ngIf="teachers.length > 0 || students.length > 0">
          <div *ngFor="let teacher of teachers" class="m-2 mb-2 p-2 px-6 rounded ng-star-inserted">
            <div class="grid sm:grid-cols-2 grid-cols-1 gap-2">
              <div class="flex justify-start items-center">
                <app-user-frame [img_src]="teacher?.image" [user_name]="teacher?.name">
                </app-user-frame>
                <!-- <div class="w-10">
                                    <img src="{{teacher?.image}}" alt="">
                                    <img src="../../../../assets/png/aluno1.png" class="rounded-full" alt="">
                                </div> -->
                <span class="flex ml-2 font-medium items-center text-customBlue-default">{{ teacher?.name }}</span>
              </div>
              <div class="flex justify-end items-center">
                <div class="flex items-center gap-1 mr-4" *ngIf="isNotStandalone">
                  <button *ngIf="disciplineDetail.general_settings?.gmail" type="button" class="p-1 w-8 h-8"
                    [disabled]="teacher.email === user.email"
                    [ngClass]="{'cursor-auto filter-disabled': teacher.email === user.email}"
                    (click)="openEmailBox(teacher.email)"><img src="./assets/svg/icon-mail.svg" alt="Mail"></button>
                  <button *ngIf="disciplineDetail.general_settings?.google_chat" type="button" class="p-1 w-8 h-8"
                    [disabled]="teacher.email === user.email"
                    [ngClass]="{'cursor-auto filter-disabled': teacher.email === user.email}"
                    (click)="openChat(teacher.email)"><img src="./assets/svg/icon-chat.svg" alt="Chat"></button>
                </div>
                <span
                  class="flex items-center text-bold rounded-full pl-6 pr-6 h-8 bg-customBlue-royal text-customWhite-default">
                  {{ teacher?.role_name?.charAt(0).toUpperCase() }}{{ teacher?.role_name?.slice(1)  }}
                </span>
              </div>
            </div>
          </div>
          <div *ngFor="let student of students" class="m-2 mb-2 p-2 px-6 rounded ng-star-inserted">
            <div class="grid sm:grid-cols-2 grid-cols-1 gap-2">
              <div class="flex justify-start items-center">
                <app-user-frame [img_src]="student?.image" [user_name]="student?.name">
                </app-user-frame>
                <!-- <div class="w-10">
                                    <img src="{{student?.image}}" alt="">
                                    <img src="../../../../assets/png/aluno2.png" class="rounded-full" alt="">
                                </div> -->
                <span class="flex ml-2 font-medium items-center text-customBlue-default">{{ student?.name }}</span>
              </div>
              <div class="flex justify-end items-center">
                <div class="flex items-center gap-1 mr-4" *ngIf="isNotStandalone">
                  <button *ngIf="disciplineDetail.general_settings?.gmail" type="button" class="p-1 w-8 h-8"
                    [disabled]="student.email === user.email"
                    [ngClass]="{'cursor-auto filter-disabled': student.email === user.email}"
                    (click)="openEmailBox(student.email)"><img src="./assets/svg/icon-mail.svg" alt="Mail"></button>
                  <button *ngIf="disciplineDetail.general_settings?.google_chat" type="button" class="p-1 w-8 h-8"
                    [disabled]="student.email === user.email"
                    [ngClass]="{'cursor-auto filter-disabled': student.email === user.email}"
                    (click)="openChat(student.email)"><img src="./assets/svg/icon-chat.svg" alt="Chat"></button>
                </div>
                <span
                  class="flex items-center text-bold rounded-full pl-6 pr-6 h-8 mx-1 bg-customBlue-default text-customWhite-default">
                  {{ student?.role_name?.charAt(0).toUpperCase() }}{{ student?.role_name?.slice(1)  }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="
            disciplineDetail?.participants?.count?.student === 0 &&
            disciplineDetail?.participants?.count?.teacher === 0
          ">
          <div class="flex">
            <div class="text-customBlue-default">
              {{ i18n.disciplines_overview_no_participants_yet }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Support Material -->
  <app-add-support-materials 
  *ngIf="isNotStandalone"
  [isTeacher]="isTeacher" 
  [isAdmin]="isAdmin">
 </app-add-support-materials>
  <!-- Support Material -->
</ng-container>

<ng-container *ngIf="isLoading">
  <app-overview-skeleton></app-overview-skeleton>
</ng-container>

<!-- <app-loading-modal *ngIf="isLoading" [isVisible]="true"></app-loading-modal> -->

<app-modal-ementa [isVisibleModalEmenta]="isVisibleModalEmenta" [disciplineExternalID]="disciplineDetail?.external_id"
  [description]="disciplineDetail?.description" [i18n]="i18n" (close)="toggleModalEmenta()"
  (update)="receiveNewDescription($event)"></app-modal-ementa>

<app-modal-send-message [isVisibleModalSend]="isVisibleModalSend" [userList]="students" [i18n]="i18n"
  [type]="modalSendType" [userLogged]="user" (close)="toggleModalSend($event);"></app-modal-send-message>