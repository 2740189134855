<div class="flex flex-row bg-customWhite-default shadow-xl rounded-lg overflow-hidden" style="height: 312px;">
    <div class="flex flex-col w-full">
      <div class="flex flex-row items-center justify-between border-b border-solid border-customBlue-superLight rounded-t w-full" style="height: 45px;">
          <div  class="flex">
              <div class="title">
                  {{i18n.reports_active_students}}
              </div>
              <button id="tooltip" class="w-5 h-5">
                  <div class="circle-interrogation-icon interrogation__icon" ></div>
                  <span id="tooltipText">{{i18n.reports_interroga_active_std}}</span>
              </button>
          </div>
          <div class="toView" (click)="showBiggerScreen()" *ngIf="isActStudents">
            {{i18n.reports_view}}
          </div>
      </div>
        <div #containerRef class="flex flex-row w-full h-full justify-center" *ngIf="isActStudents">
          <div class="chartContainer">
            <ngx-charts-bar-vertical
                [dataLabelFormatting] = "formatDataLabel"
                [yScaleMax]="yScaleMax"
                [showDataLabel]=showDataLabel
                [view]="[containerRef.offsetWidth, 240]"
                [scheme]="colorScheme"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [results]="formatedActStudents"
                [barPadding]="barPadding"
                [rotateXAxisTicks]="rotateXAxisTicks"
                [animations]="animations"
                [maxXAxisTickLength]="maxXAxisTickLength"
                [yAxisTickFormatting]="yTickFormattingFn"
                [xAxisTickFormatting]="xTickFormattingFn"
                style="fill: #000000;"
                >
                <ng-template #tooltipTemplate let-model="model">
                    <pre class="my-3">{{ (model.value * 100).toFixed(2) + ' %'}}</pre>
                </ng-template>
            </ngx-charts-bar-vertical>
          </div>
        </div>
         <!-- Empty Activities Student -->
         <app-empty-data *ngIf="!isActStudents"
         class="flex justify-center items-center h-full"></app-empty-data>
    </div>
</div>
