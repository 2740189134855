<form class="text-customBlue-default" [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Header -->
    <header class="mb-6">
        <h1 class="text-22 font-bold font-Montserrat">{{ i18n.planner_current_year_planning }}</h1>
        <p>{{ i18n.planner_choose_calendar }}.</p>
    </header>

    <article class="grid grid-cols-1 md:grid-cols-2 gap-4" cdkDropListGroup>
        <section class="flex flex-col gap-4 bg-customWhite-default p-4 rounded-lg">
            <!-- Fixed inputs -->
            <fieldset class="flex flex-col gap-1">
                <label class="font-semibold font-Montserrat" for="planning">{{ i18n.planner_my_planners }}</label>
                <select class="truncate w-full text-customGray-grayThree bg-customWhite-default border border-customGray-mediumGray pl-2 pr-6 py-2 rounded text-14" id="planning" formControlName="planning" (change)="getModules();" required>
                    <option value="" disabled>{{ i18n.planner_select }}</option>
                    <option [value]="planning.external_id" *ngFor="let planning of planningList">{{ planning.degree_name + ' - ' + planning.component_name }}</option>
                    <option value="error" *ngIf="!planningList.length" disabled>{{ i18n.planner_dont_have_planning_to_select }}</option>
                </select>
            </fieldset>
            <!-- Selection module -->
            <fieldset class="flex flex-col gap-2.5" *ngIf="isVisibleModulesFound">
                <p class="font-semibold font-Montserrat">{{ modulesList.length }} {{ i18n.planner_module_founded }}</p>
                <ul class="flex flex-col gap-2.5">
                    <li *ngFor="let module of modulesList">
                        <button type="button" (click)="getClasses(module);" class="border border-l-8 border-customGray-lighterGray module border-left-blue-royal p-3 rounded text-left w-full hover:shadow-sm font-Montserrat">
                            <p><span class="font-semibold">{{ i18n.planner_module }} {{ module.index }} -</span> {{ module.name }}</p>
                        </button>
                    </li>
                </ul> 
            </fieldset>
            <!-- Class module -->
            <fieldset class="flex flex-col gap-2.5 font-Montserrat" *ngIf="isVisibleClassesFound" 
            cdkDropList
            #todoList="cdkDropList"
            [cdkDropListData]="classesList"
            (cdkDropListDropped)="drop($event)">
                <p class="font-semibold">{{ i18n.planner_module }} - {{ selectedModule.name }}</p>
                <p class="font-medium">{{ classesList.length }} {{ i18n.planner_classes_founded }}</p>
                <ul class="flex flex-col gap-2.5">
                    <li *ngFor="let class of classesList; let i = index" cdkDrag class="list-none w-full">
                        <button type="button" class="border border-l-8 border-customGray-lighterGray border-left-blue-royal hover:shadow-sm p-3 rounded text-left w-full bg-customWhite-default cursor-grab">
                            <p class="font-semibold">{{ i18n.planner_class }} {{ i + 1 }}</p>
                           <p class="text-14">{{ class.description }}</p>
                        </button>
                    </li>
                </ul> 
            </fieldset>
        </section>

        <div class="bg-customWhite-default p-4 rounded-lg border border-dashed border-customGray-mediumGray flex flex-col justify-between">
            <section>
                <!-- Header date -->
                <header class="mb-8">
                    <div class="flex items-center justify-between mb-3 flex-wrap gap-2">
                        <div class="font-semibold font-Montserrat text-18">
                            <p>{{ i18n.planner_planning_class }}</p>
                            <p class="bg-customBlue-light w-min pr-4">{{ i18n.planner_weeks }}</p>
                        </div>
                        <select class="truncate text-customGray-grayThree bg-customWhite-default border border-customGray-mediumGray pl-2 pr-6 py-2 rounded w-32" id="months" formControlName="selected_month" (change)="selectMonth()" *ngIf="isVisibleClassesFound">
                            <option value="">{{ i18n.planner_overview }}</option>
                            <option [value]="month.month" *ngFor="let month of monthsList">{{ getMonthNameTranslated(month.month) }}</option>
                        </select>
                    </div>
                    <p class="text-14" *ngIf="form.controls.planning.value">{{ i18n.planner_drag_and_drop_planning }}</p>
                </header>
                <!-- Box select -->
                <div class="flex justify-center items-center p-4 border border-customGray-dark border-dotted h-56" 
                *ngIf="!form.controls.planning.value">
                    <p class="text-customGray-dark text-center text-14">{{ i18n.planner_select_planning_work }}</p>
                </div>

                <!-- Months -->
                <ul class="flex flex-col gap-4 mt-4" formArrayName="months" *ngIf="isVisibleClassesFound">
                    <li class="border border-customGray-mediumGray rounded px-4 py-1 transition-all" 
                    *ngFor="let month of form.get('months')!['controls']; let monthIndex = index" [formGroupName]="monthIndex"
                    [ngClass]="{'border-opacity-0': month.get('control').value, 
                    'hidden': form.controls.selected_month.value.length ? month.get('month').value !== form.controls.selected_month.value : ''}">
                        <button type="button" class="flex items-center justify-between w-full my-1" (click)="toggleMonths(monthIndex)">
                            <p class="font-Montserrat font-bold">{{ getMonthNameTranslated(month.get('month').value) }}</p>
                            <div class="transition-all" [ngClass]="{'transform rotate-180': month.control }">
                                <span class="iconify text-customBlue-default w-7 h-7" data-icon="iconamoon:arrow-down-2-fill"></span>   
                            </div>
                        </button>

                        <!-- Weeks -->
                        <div>
                            <div [@collapse]="month.get('control').value">
                                <ul formArrayName="weeks" class="flex flex-col gap-4">
                                    <li *ngFor="let week of month.get('weeks').controls; let weekIndex = index" [formGroupName]="weekIndex">
                                    <!-- Error  -->
                                    <small *ngIf="week.get('error').value" class="text-customOrange-default">{{ i18n.planner_spaces_required }}</small>

                          
                                    <article [ngClass]="{'border-customOrange-default border-opacity-60': week.get('error').value }" class="border border-l-8 border-customBlue-light p-3 rounded text-left w-full">
                                        <div class="flex items-center justify-between flex-wrap gap-2">
                                            <div>
                                                <p class="font-semibold font-Montserrat">{{ i18n.planner_weeks }} {{ weekIndex + 1 }}</p>
                                                <p class="text-12">{{ formatDateRange(week.get('initial_date').value, week.get('final_date').value) }}</p>
                                            </div>
                
                                            <input type="text" id="qtd_class" name="qtd_class" placeholder="{{ i18n.planner_insert_qtd_classes }}" class="border border-customBlue-default rounded p-1.5 w-full sm:w-auto" autocomplete="off" maxlength="3" min="0" (keyup)="generateSlot(classInput, monthIndex, weekIndex)" formControlName="class_number" #classInput>
                                        </div>
                                        
                                        <!-- Slots -->
                                        <div formArrayName="slots" class="w-full">
                                            <ul *ngFor="let slot of week.get('slots').controls || []; let slotIndex = index" class="w-full" [formGroupName]="slotIndex">
                                                <li class="flex flex-col justify-center items-center gap-3 mt-3 w-full" 
                                                [ngClass]="{'p-3 border border-customGray-dark border-dotted': !slot.get('class').value.length}"
                                                (cdkDropListDropped)="drop($event)" 
                                                [cdkDropListEnterPredicate]="validateMaxItem"
                                                [cdkDropListData]="slot.get('class').value"
                                                cdkDropList>
                                                    <p *ngIf="!slot.get('class').value.length" 
                                                    class="text-customGray-dark text-center text-14">{{ i18n.planner_select_classes_wanted_work_week }}</p>
                                                    
                                                    <!-- Class -->
                                                    <button *ngFor="let class of slot.get('class').value; let i = index" type="button" class="border border-l-8 border-customGray-lighterGray border-left-blue-royal hover:shadow-sm p-3 rounded text-left w-full bg-customWhite-default cursor-grab" cdkDrag>
                                                        <div class="flex justify-between items-center flex-wrap-reverse gap-2 sm:gap-0">
                                                            <p class="font-semibold">{{ i18n.planner_class }} {{ slotIndex + 1 }}</p>
                                                            <div class="multi-select" #multiSelectElement>
                                                                <p-multiSelect
                                                                [options]="skillsList"
                                                                [displaySelectedLabel]="true"
                                                                formControlName="skills"
                                                                [filter]="false"
                                                                [style]="{ width: '100%' }"
                                                                [emptyMessage]="i18n.planner_empty"
                                                                [placeholder]="i18n.planner_skills"
                                                                optionLabel="description"
                                                                optionValue="id">
                                                                </p-multiSelect>
                                                            </div>
                                                        </div>

                                                        <p class="text-14">{{ class.description }}</p>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </article>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
            <!-- Footer buttons -->
            <footer class="mt-6" *ngIf="form.controls.planning.value">
                <div class="text-customOrange-default text-right text-14 mb-3" *ngIf="errorInvalidForm">
                    <p>{{ i18n.planner_classes_required }}</p>
                </div>

                <div class="flex flex-col sm:flex-row justify-end gap-3">
                    <a [routerLink]="['/content-planner']" class="flex items-center justify-center w-full sm:w-auto text-customBlue-default border border-customBlue-default bg-transparent py-1.5 px-10 rounded">{{ i18n.planner_back }}</a>
                    
                    <button type="submit" class="w-full flex items-center gap-1 sm:w-auto text-customWhite-default bg-customBlue-default py-1.5 px-10 rounded" [disabled]="loading" [ngClass]="{'cursor-not-allowed opacity-75': loading }">
                        <span *ngIf="!loading">{{ i18n.planner_save }}</span>
                        <div *ngIf="loading">
                            <span class="iconify text-24 text-customWhite-default" data-icon="line-md:loading-alt-loop"></span>
                        </div>
                    </button>
                </div>
            </footer>
        </div>
    </article>
</form>

<app-modal-success-planning [i18n]="i18n"
[isVisibleSuccessPlanning]="isVisibleSuccessPlanningModal" 
[message]="i18n.planner_planning_updated_success"
(closeModalSuccessPlanning)="controlModalSuccess($event)">
</app-modal-success-planning>