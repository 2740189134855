
import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
@Pipe({
  name: 'dateAgo',
  pure: true,
})

export class DateAgoPipe implements PipeTransform {
  currentLanguage: any;
  i18n: any = [];

  constructor(private sharedService: SharedService) {
    this.getTranslations();
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Humanize'),...this.sharedService.getTranslationsOf('Modal')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  transform(value: string): string {
    const currentDate = new Date();
    const pastDate = new Date(value);

    const distanceInSeconds = Math.floor((currentDate.getTime() - pastDate.getTime()) / 1000);

    if (distanceInSeconds < 60) {
      return `${distanceInSeconds} ${distanceInSeconds === 1 ? this.i18n.humanize_second : this.i18n.humanize_seconds}`;
    }

    const distanceInMinutes = Math.floor(distanceInSeconds / 60);
    if (distanceInMinutes < 60) {
      return `${distanceInMinutes} ${distanceInMinutes === 1 ? this.i18n.humanize_minute : this.i18n.humanize_minutes}`;
    }

    const distanceInHours = Math.floor(distanceInMinutes / 60);
    if (distanceInHours < 24) {
      return `${distanceInHours} ${distanceInHours === 1 ? this.i18n.humanize_hour : this.i18n.humanize_hours}`;
    }

    const distanceInDays = Math.floor(distanceInHours / 24);
    if (distanceInDays < 7) {
      return `${distanceInDays} ${distanceInDays === 1 ? this.i18n.humanize_day : this.i18n.humanize_days}`;
    }

    const distanceInWeeks = Math.floor(distanceInDays / 7);
    if (distanceInWeeks < 4) {
      return `${distanceInWeeks} ${distanceInWeeks === 1 ? this.i18n.humanize_week : this.i18n.humanize_weeks}`;
    }

    const distanceInMonths = Math.floor(distanceInDays / 30);
    if (distanceInMonths < 12) {
      return `${distanceInMonths} ${distanceInMonths === 1 ? this.i18n.humanize_month : this.i18n.humanize_months}`;
    }

    const distanceInYears = Math.floor(distanceInDays / 365);
    return `${distanceInYears} ${distanceInYears === 1 ? this.i18n.humanize_year : this.i18n.humanize_yers}`;
  }
}
