import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adm-card',
  templateUrl: './adm-card.component.html',
  styleUrls: ['./adm-card.component.scss']
})
export class AdmCardComponent implements OnInit {
  @Input() user: any = [];
  constructor() { }

  ngOnInit(): void {
  }

}
