<div class="fade-in" [ngClass]="{'hidden':!isVisible}">
    <div>
        <h3 class="font-bold mb-4">{{i18n.grades_settings}}</h3>
        <!-- HEAD -->
        <div  class="grid grid-cols-5 gap-4 justify-center font-bold mb-2">
            <div class="flex col-span-2">
                {{i18n.grades_concept}}
            </div>
            <div class="flex items-center justify-center">
                {{i18n.grades_grade}}
            </div>
            <div class="flex items-center justify-center">
                {{i18n.grades_status}}
            </div>
            <div>

            </div>
        </div>
        <!-- FIM HEAD -->
        <!-- BODY -->
        <div *ngFor="let concept of concepts; let i = index" class="grid grid-cols-5 gap-4 justify-center mb-2">
            <div class="flex justify-center col-span-2 border border-customGray-iconGray p-2">
                {{concept.name}}
            </div>
            <div class="flex items-center justify-center gap-1">
                {{concept.range_start}}
                <span>{{i18n.grades_date_to}}</span>
                {{concept.range_end}}
            </div>
            <div class="flex items-center justify-center ">

                <ng-container [ngSwitch]="concept.status">
                    <div *ngSwitchCase="'approved'" class="bg-approved py-1 px-8 font-bold text-sm">
                        {{i18n.grades_approved}}
                    </div>
                    <div *ngSwitchCase="'reproved'" class="bg-reproved py-1 px-8 font-bold text-sm">
                        {{i18n.grades_reproved}}
                    </div>
                    <div *ngSwitchCase="'none'" class="bg-none rounded-sm py-1 px-8 font-bold text-sm">
                        {{i18n.grades_none}}
                    </div>
                </ng-container>
            </div>
            <div class="flex items-center justify-center gap-2">
                <button *appCheckPermissions="'grades_concepts'; type: 'edit'" (click)="openModalEditConcept(i)" class="content__professor-btns" aria-labelledby="edit-content-button-label">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
                    <path d="M11.7817 3.05229L13.5423 4.81204M12.9139 1.50201L8.1533 6.26257C7.90732 6.50821 7.73957 6.82116 7.67118 7.16199L7.23145 9.36314L9.4326 8.92257C9.77341 8.85441 10.086 8.68733 10.332 8.44128L15.0926 3.68071C15.2356 3.53766 15.3491 3.36782 15.4265 3.18091C15.504 2.994 15.5438 2.79367 15.5438 2.59136C15.5438 2.38905 15.504 2.18872 15.4265 2.00181C15.3491 1.81489 15.2356 1.64506 15.0926 1.50201C14.9495 1.35895 14.7797 1.24547 14.5928 1.16805C14.4059 1.09063 14.2055 1.05078 14.0032 1.05078C13.8009 1.05078 13.6006 1.09063 13.4137 1.16805C13.2268 1.24547 13.0569 1.35895 12.9139 1.50201Z" stroke="#233674" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.8813 11.0254V13.5191C13.8813 13.9601 13.7062 14.3829 13.3944 14.6947C13.0826 15.0065 12.6598 15.1816 12.2188 15.1816H3.0751C2.63418 15.1816 2.21131 15.0065 1.89953 14.6947C1.58775 14.3829 1.4126 13.9601 1.4126 13.5191V4.37539C1.4126 3.93447 1.58775 3.5116 1.89953 3.19983C2.21131 2.88805 2.63418 2.71289 3.0751 2.71289H5.56885" stroke="#233674" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span id="edit-content-button-label" hidden>{{i18n.grades_edit_concept}}</span>
                </button>
                <button *appCheckPermissions="'grades_concepts'; type: 'delete'" (click)="deleteSettingsConcepts(concept?.id)" class="content__professor-btns"  aria-labelledby="delete-content-button-label">
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
                    <path d="M5.6674 7.76953C5.83456 7.76953 5.99488 7.83521 6.11308 7.95213C6.23128 8.06905 6.29769 8.22762 6.29769 8.39297V15.8742C6.29769 16.0396 6.23128 16.1981 6.11308 16.3151C5.99488 16.432 5.83456 16.4977 5.6674 16.4977C5.50023 16.4977 5.33992 16.432 5.22172 16.3151C5.10351 16.1981 5.03711 16.0396 5.03711 15.8742V8.39297C5.03711 8.22762 5.10351 8.06905 5.22172 7.95213C5.33992 7.83521 5.50023 7.76953 5.6674 7.76953ZM8.81884 7.76953C8.986 7.76953 9.14632 7.83521 9.26452 7.95213C9.38272 8.06905 9.44913 8.22762 9.44913 8.39297V15.8742C9.44913 16.0396 9.38272 16.1981 9.26452 16.3151C9.14632 16.432 8.986 16.4977 8.81884 16.4977C8.65168 16.4977 8.49136 16.432 8.37316 16.3151C8.25496 16.1981 8.18855 16.0396 8.18855 15.8742V8.39297C8.18855 8.22762 8.25496 8.06905 8.37316 7.95213C8.49136 7.83521 8.65168 7.76953 8.81884 7.76953ZM12.6006 8.39297C12.6006 8.22762 12.5342 8.06905 12.416 7.95213C12.2978 7.83521 12.1374 7.76953 11.9703 7.76953C11.8031 7.76953 11.6428 7.83521 11.5246 7.95213C11.4064 8.06905 11.34 8.22762 11.34 8.39297V15.8742C11.34 16.0396 11.4064 16.1981 11.5246 16.3151C11.6428 16.432 11.8031 16.4977 11.9703 16.4977C12.1374 16.4977 12.2978 16.432 12.416 16.3151C12.5342 16.1981 12.6006 16.0396 12.6006 15.8742V8.39297Z" fill="#233674"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0125 4.65273C17.0125 4.98343 16.8797 5.30057 16.6433 5.53441C16.4069 5.76824 16.0862 5.89961 15.7519 5.89961H15.1216V17.1215C15.1216 17.7829 14.856 18.4172 14.3832 18.8848C13.9104 19.3525 13.2691 19.6152 12.6005 19.6152H5.03702C4.36837 19.6152 3.7271 19.3525 3.25429 18.8848C2.78149 18.4172 2.51587 17.7829 2.51587 17.1215V5.89961H1.88558C1.55125 5.89961 1.23062 5.76824 0.994214 5.53441C0.75781 5.30057 0.625 4.98343 0.625 4.65273V3.40586C0.625 3.07517 0.75781 2.75802 0.994214 2.52419C1.23062 2.29035 1.55125 2.15898 1.88558 2.15898H6.2976C6.2976 1.82829 6.43041 1.51115 6.66681 1.27731C6.90321 1.04348 7.22385 0.912109 7.55817 0.912109L10.0793 0.912109C10.4137 0.912109 10.7343 1.04348 10.9707 1.27731C11.2071 1.51115 11.3399 1.82829 11.3399 2.15898H15.7519C16.0862 2.15898 16.4069 2.29035 16.6433 2.52419C16.8797 2.75802 17.0125 3.07517 17.0125 3.40586V4.65273ZM3.92519 5.89961L3.77644 5.97318V17.1215C3.77644 17.4522 3.90925 17.7693 4.14566 18.0032C4.38206 18.237 4.70269 18.3684 5.03702 18.3684H12.6005C12.9348 18.3684 13.2554 18.237 13.4918 18.0032C13.7282 17.7693 13.8611 17.4522 13.8611 17.1215V5.97318L13.7123 5.89961H3.92519ZM1.88558 4.65273V3.40586H15.7519V4.65273H1.88558Z" fill="#233674"/>
                    </svg>
                    <span id="delete-content-button-label" hidden>{{i18n.grades_remove_concept}}</span>
                </button>
            </div>
        </div>
        <!--FIM BODY -->
        <div class="my-4">
            <div *appCheckPermissions="'grades_concepts'; type: 'create'" class="flex items-center gap-2" (click)="openModalAddConcept()">
                <button class="py-3 px-3 right-16 rounded-full add shadow-lg" style="border-radius:50%;background-color: #E8532C;">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.19727 4.61035H11.5586V6.49023H7.19727V11.4316H5.19922V6.49023H0.837891V4.61035H5.19922V0.0449219H7.19727V4.61035Z" fill="white"/>
                    </svg>
                </button>
                <span>{{i18n.grades_add_new_concept}}</span>
            </div>
        </div>
    </div>
</div>

<app-settings-concept-modal [discipline_external_id]="discipline_external_id" [isModalOpen]="isModalOpen" (sendIsModalOpen)="getStatusModal($event)"></app-settings-concept-modal>
