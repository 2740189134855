import { Component, ElementRef, OnInit } from '@angular/core';


@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {

  constructor(
    private element: ElementRef,
  ) { }  

  ngOnInit(): void {
    this.element.nativeElement.ownerDocument.body.style.overflow = 'clip';

  }

  ngOnDestroy () {
    this.element.nativeElement.ownerDocument.body.removeAttribute('style');
  }
  
}
