import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContentTaskUploadService {

  section_ext_id$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private http: HttpClient) {}

  postUploadAssignment(
    discipline_external_id: string,
    section_external_id: string,
    content_external_id: string,
    params: any
  ): Observable<any> {
    return this.http
      .post<any>(
        `${API_Routes.URL}/disciplines/${discipline_external_id}/sections/${section_external_id}/contents/${content_external_id}/turnIn`,
        params
      )
      .pipe(take(1));
  }
}
