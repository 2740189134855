import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-translate-button',
  templateUrl: './translate-button.component.html',
  styleUrls: ['./translate-button.component.scss']
})
export class TranslateButtonComponent implements OnInit {
  
  createTranslateButton$ = this.sharedService.createTranslateButton$;
  modalOpenTranslate$ = this.sharedService.modalOpenTranslate$;
  isOpened: boolean = false;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
  }


  openModalTranslate(){
    this.modalOpenTranslate$.subscribe(item=>{
      this.isOpened = item
    })
    if (!this.isOpened) {
      this.sharedService.modalOpenTranslate();
    }else{
      this.sharedService.modalCloseTranslate();
    }
  }



}
