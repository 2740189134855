import {
    Component, ElementRef, Input, OnDestroy, OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ContentQuizService } from './content-quiz.service';

@Component({
    selector: 'app-content-view-quiz',
    templateUrl: './content-view-quiz.component.html',
    styleUrls: ['./content-view-quiz.component.scss'],
})
export class ContentViewQuizComponent implements OnInit, OnDestroy {
    @Input() content: any = {};
    contentSpecific: any = {};
    urlParams: any = {};
    i18n: any = {};
    i18nAdvanceCriteria: any;
    routeObservable1: any;
    routeObservable2: any;
    completitionRuleTranslation = '';
    quizInited = false;
    finishQuizLoading = false;
    isStudent = false;
    isCompleted = false;
    countIncorrect = 0;
    countCorrect = 0;

    remainingAttempts = false;
    canSubmitQuiz = false;

    constructor(
        private platModalsService: PlatformModalsService,
        private activatedRoute: ActivatedRoute,
        private quizService: ContentQuizService,
        private elementRef: ElementRef,
        private shared: SharedService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getTraductions();
        this.setCompletitionRuleTranslation();
        this.contentSpecific = this.content.contentSpecific;
        this.contentSpecific.questions = this.contentSpecific.questions.flat();
        this.hasRemainingAttempts();
        this.isStudent = this.shared.getUserSync().role_type === 'student';
        this.isCompleted = this.returnIsComplete();
        this.getUrlParams();
        this.getAnswerAndFeedback();
    }

    public returnIsComplete(): boolean {
        return this.isStudent && this.content.status.includes('COMPLETE');
    }

    hasRemainingAttempts(): void {
        this.remainingAttempts = this.content.coursework_submission_attempts < this.content.completion_rule_value;
    }

    getUrlParams(): void {
        this.routeObservable1 = this.activatedRoute?.params?.subscribe((params) => {
            this.urlParams.content = params.id;
        });

        this.routeObservable2 = this.activatedRoute?.params?.subscribe((params) => {
            this.urlParams.section = params.section;
            this.urlParams.section_title = params.section_title;
            this.urlParams.type = params.type;
            this.urlParams.edit = 1;
        });
    }

    getAnswerAndFeedback(): void {
        this.quizService.getAnswerAndFeedback(this.urlParams.content).subscribe({
            next: (res: any) => {
                this.contentSpecific.questions.filter((question_default, i) => {
                    res.tries.find((question_with_response) => {
                        if (
                            question_default.external_id === question_with_response.external_id
                        ) {
                            question_with_response.weight = question_default.weight;
                            this.contentSpecific.questions[i] = question_with_response;
                        }
                    });
                });
            },
        });
        this.countQuestions(this.contentSpecific.questions);
    }

    countQuestions(questions: any[]): void {
        questions.forEach((question, i) => {
            if (question.answer_status === 'incorrect') {
                this.countIncorrect++;
            }

            if (question.answer_status === 'correct') {
                this.countCorrect++;
            }
        });
    }

    setCompletitionRuleTranslation(): void {
        this.i18nAdvanceCriteria = this.shared.getTranslationsOf('AdvanceAndCriterion');

        const completitionRulesTranslations = {
            ACCESS: this.i18nAdvanceCriteria.adv_and_crit_access,
            TIME: this.i18nAdvanceCriteria.adv_and_crit_time,
            ATTEMPT_SUBMISSION: this.i18nAdvanceCriteria.adv_and_crit_attempt_sub,
            STUDENT_NOTIFICATION:
                this.i18nAdvanceCriteria.adv_and_crit_std_notification,
        };

        this.completitionRuleTranslation = completitionRulesTranslations[this.content.completion_rule];
    }

    startQuiz(): void {
        this.platModalsService.toggle('loading');
        this.quizService
            .startQuiz({ contentExternalId: this.content.external_id })
            .subscribe({
                next: () => {
                    this.getAnswerAndFeedback();
                    addEventListener('beforeunload', this.alertBeforeRefresh.bind(this));
                    this.controlStartQuiz(true);
                    this.platModalsService.toggle('loading');
                    this.canSubmitQuiz = false;
                },
                error: (error) => {
                    this.platModalsService.toggle('loading');
                    let message = '';

                    if (error.error?.error.includes('The form can\'t have multipe answers')) {
                        message = this.i18n.discipline_content_view_quiz_error_multipe_answers;
                    } else if (error.error?.error.includes('The form attempt is already open')) {
                        message = this.i18n.discipline_content_view_quiz_error_already_open;
                    } else if (error.error?.error.includes('The form is closed to answers')) {
                        message = this.i18n.discipline_content_view_quiz_error_closed_answers;
                    } else if (error.error?.error.includes('The form can not have multipe answers')) {
                        message = this.i18n.discipline_content_view_quiz_error_multipe_answers;
                    } else if (error.error?.error.includes('Quiz Data not found')) {
                        message = this.i18n.discipline_content_view_quiz_error_not_found;
                    } else {
                        message = this.i18n.question_bank_content_view_quiz_start_error;
                    }

                    this.platModalsService.toggle('message', message, 'close');
                }
            });
    }

    controlStartQuiz(boolean: boolean): void {
        this.quizInited = boolean;
    }

    finishQuiz(): void {
        const reqParams = { contentExternalId: this.content.external_id };
        this.platModalsService.toggle('loading');

        this.quizService.finishQuiz(reqParams).subscribe({
            next: () => {
                this.getBackToContents();
                this.canSubmitQuiz = false;
            },
            error: (res) => {
                this.platModalsService.toggle('message', res.error.message, 'close');
                this.platModalsService.close('loading');
            },
        });

        removeEventListener('beforeunload', this.alertBeforeRefresh);
    }

    editQuiz(): void {
        const disciplineExternalId = this.shared.getDisciplineExternalId(
            this.router.url
        );

        this.elementRef.nativeElement.closest('.router-outlet').scrollTop = 0;
        this.router.navigate([
            `disciplines/${disciplineExternalId}/content-assignment/assignment`,
            this.urlParams,
        ]);
    }

    saveStudentAnswer(answer: any): void {
        if (answer.type === 'change') {
            return;
        }
        this.canSubmitQuiz = false;

        const { user_response, external_id } = answer;

        if (!user_response) return;

        const reqParams = {
            contentExternalId: this.content.external_id,
            question_external_id: external_id,
            user_response,
        };

        this.quizService.saveStudentQuestionAnswer(reqParams).subscribe(
            {
                next: () => {
                    this.canSubmitQuiz = true;
                },
                error: (err) => {
                    this.platModalsService.toggle('message', err.error.error, 'close');
                }
            }
        );
    }

    getBackToContents(): void {
        const disciplineExternalId = this.shared.getDisciplineExternalId(
            this.router.url
        );

        this.router.navigateByUrl(
            `disciplines/${disciplineExternalId}/content-tab`
        );
    }

    alertBeforeRefresh(event): void {
        event.preventDefault();
        event.returnValue = 'Unsaved modifications';
        return event;
    }

    getTraductions(): void {
        this.i18n = { ...this.shared.getTranslationsOf('QuestionBank'), ...this.shared.getTranslationsOf('Errors') };
    }

    ngOnDestroy() {
        if (this.routeObservable1) this.routeObservable1.unsubscribe();
        if (this.routeObservable2) this.routeObservable1.unsubscribe();
    }
}
