import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SubmissionsService } from 'src/app/pages/disciplines/components/content/components/homework-submissions/submissions.service';
import { SharedService } from 'src/app/shared/shared.service';
import { setActiveTab, updateActiveCourseWork } from '../../../../store/homework-submissions.actions';
import { selectHomeworkSubmissionsState, selectSubmissions } from '../../../../store/homework-submissions.reducer';

@Component({
  selector: 'app-submission-generic-board-mobile',
  templateUrl: './submission-generic-board-mobile.component.html',
  styleUrls: ['./submission-generic-board-mobile.component.scss']
})
export class SubmissionGenericBoardMobileComponent implements OnInit {
  state$: Subscription;
  submissions$: Subscription;
  submissions;
  contentID?: string;
  i18n: any = {};
  
  constructor (
    private store: Store,
    public shared: SharedService,
    private submissionsService: SubmissionsService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.state$ = this.store.select(selectHomeworkSubmissionsState).subscribe(state => this.contentID = state.contentID);
    this.submissions$ = this.store.select(selectSubmissions).subscribe(subs => {
      if (!subs.length || !subs[0].status) return;

      this.submissions = subs;
    })
  }

  goToSubmissionsTab (submission) {
    const wasSubmited = submission.course_work[0]?.completed_at;
    if (!wasSubmited) return;

    const newActiveCourseWorkID = submission.course_work[0]?.id;
    const submissionsTab = 'submissions';

    this.submissionsService
    .getCourseWork(this.contentID, newActiveCourseWorkID)
    .subscribe(courseWork => {
      this.store.dispatch(updateActiveCourseWork({ activeCourseWork: courseWork }));
      this.store.dispatch(setActiveTab({ activeTab: submissionsTab }));
    })
  }

  getTranslations() {
    this.i18n = this.shared.getTranslationsOf('Disciplines');
  }

  ngOnDestroy(): void {
    if (this.submissions$) this.submissions$.unsubscribe();
  }

}
