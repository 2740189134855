import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { DisciplineService } from '../../discipline.service';

@Component({
    selector: 'app-select-tab',
    templateUrl: './select-tab.component.html',
    styleUrls: ['./select-tab.component.scss'],
})
export class SelectTabComponent implements OnInit, OnDestroy {
    disciplineDetail: any;
    selected: any;
    id: string;
    user: any;
    unsubscribe$: Subject<boolean> = new Subject();
    i18n: any = [];
    currentLanguage: any;

    subscription: Subscription[] = [];
    isLoading = false;

    constructor(
        private disciplineDetailService: DisciplineDetailService,
        private sharedService: SharedService,
        private route: ActivatedRoute,
        private router: Router,
        private disciplineService: DisciplineService,
        private platModalService: PlatformModalsService
    ) {}

    ngOnInit(): void {
        this.isLoading = true;

        this.route.params.subscribe((params: Params) => {
            this.id = params.id;
            this.getCoverImg();
        });

        this.getSelected();
        this.sharedService.CallFunctionOnRouteChange(this.getSelected.bind(this));
        this.getUser();
        this.getTranslations();
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getSelected() {
        const tabFallback = ['', 'overview'];
        const currentURL = `${this.router?.url}/`;
        // disciplines-models
        if (currentURL.includes('disciplines-models')) {
            this.selected = (currentURL.match(/disciplines-models\/.+?\/(.+?)\//)
        || tabFallback)[1];
        } else {
            this.selected = (currentURL.match(/disciplines\/.+?\/(.+?)\//)
        || tabFallback)[1];
        }
    }

    // get user info
    getUser() {
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;
        });
    }

    discipline_id: any;

    getDisciplineDetail() {
        this.route?.params.subscribe((params: Params) => {
            this.discipline_id = params.id;
        });

        sessionStorage.setItem('discipline_external_id', this.discipline_id);

        this.disciplineDetailService
            .getDisciplineDetail(this.discipline_id)
            .subscribe({

                next: (discipline) => {
                    this.disciplineDetailService.disciplineBasic$.next(discipline);
                    this.disciplineDetailService.titleName$.next(discipline.name);

                    // this.disciplineDetailService.disciplineDetail = discipline;
                    this.disciplineDetail = discipline;
                    if (this.disciplineDetail.hasOwnProperty('cover_image')) this.disciplineDetail.cover_image = encodeURI(this.disciplineDetail.cover_image);
                },
                error: (err) => {
                    console.log('err ', err);
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
    }

    // função abaixo foi movida para component src/app/pages/disciplines/components/overview/overview.component.ts
    // pois fazia múltiplas chamadas indevidas
    getCoverImg() {
    // A subscribe to get changes from settings
        const sub1 = this.sharedService.isChangeCoverImg$.subscribe(() => {
            this.getDisciplineDetail();
        });

        this.subscription.push(sub1);
    }

    changeTab(tab: string) {
        localStorage.setItem('clickedInDisciplinesSettings', 'clicked');
        this.selected = tab;
        this.router.navigate([this.selected], { relativeTo: this.route });
        this.id = String(this.route?.snapshot?.paramMap?.get('id'));
    }

    subscribeDiscipline(discipline_external_id) {
        this.disciplineService.subscribeDiscipline(discipline_external_id).subscribe({
            next: () => {
                location.reload();
            },
            error: (err: any) => {
                this.platModalService.toggle('message', err.error.error, 'close');
            }
        });
    }

    ngOnDestroy() {
        this.sharedService.CallFunctionOnRouteChange();
        this.disciplineDetailService.disciplineBasic$.next({});
        if (this.subscription.length) {
            this.subscription.forEach((element) => {
                element.unsubscribe();
            });
        }
    }
}
