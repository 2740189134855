import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConferenceCheckerService {

  constructor(private http: HttpClient) { }

   getRecording ({ discipline_id, section_id, content_id }): Observable<any> {
    return this.http.get(`${API_Routes.URL}/disciplines/${discipline_id}/sections/${section_id}/contents/${content_id}/recording
    `)
  }

  addRecording ({ discipline_id, section_id, content_id, body }): Observable<any> {
    return this.http.post(`${API_Routes.URL}/disciplines/${discipline_id}/sections/${section_id}/contents/${content_id}/recording`, body)
  }
}
