import { CertificatesSharedService } from 'src/app/shared/certificates-shared.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-certificate',
  templateUrl: './edit-certificate.component.html',
  styleUrls: ['./edit-certificate.component.scss']
})
export class EditCertificateComponent implements OnInit {

  modalEditCertificate$  = this.certificatesSharedService.modalEditCertificate$;
  /* modalEditTitle$ = this.certificatesSharedService.modalEditTitle$ */
  modalEditOrAdd$  = this.certificatesSharedService.modalEditOrAdd$;

  editMode: boolean = false;
  editModalTitle: any;
  editCertificateForm: UntypedFormGroup
  postCertificateForm: UntypedFormGroup
  //postCertificateForm: FormGroup
  buttonUnlocked: boolean = false
  isLoaded$ = this.sharedService.isLoadedUpload$;

  @Input() i18n: any

 @Input() certificateForEdit: any
 @Input() certificatePost: any
 @Input() editOrAdd: string
 @Input() loading: boolean = false;

 @Output() editedCertificate = new EventEmitter();
 @Output() postCertificate = new EventEmitter();
 @Output() editingCertificateForm = new EventEmitter();
 @Output() postingCertificateForm = new EventEmitter();

  constructor(
    private certificatesSharedService: CertificatesSharedService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder
  ) { }


  ngOnInit(): void {
    console.log('CertificateToEdit: ', this.certificateForEdit)
    //this.addOrEditCertificate();
    //this.certificatePostForm();
    this.editOrAddMode();
    this.certificatePostForm();
    this.certificateEditForm();
    this.setValueForm(this.certificateForEdit, this.postCertificateForm)
    this.editingCertificateForm.emit(this.editCertificateForm)
    this.postingCertificateForm.emit(this.postCertificateForm)
    this.buttonUnlocked = false;
    this.title();
  }


  closeEditCertificate() {
    this.certificatesSharedService.modalCloseEditCertificate();
    this.isLoaded$.next(false)
  }


  openEditCertificate() {
    this.certificatesSharedService.modalOpenEditCertificate();
  }


setValueForm(certificateForEdit: any, certificateForPost: any){
  if (this.editMode) {
    console.log('MODO EDITAR')
    this.editCertificateForm.controls['name'].setValue(certificateForEdit.name);
  } else {
    console.log('MODO ADICIONAR')
    this.postCertificateForm.controls['name'].setValue(certificateForPost.name);
    //this.postCertificateForm.controls['name'].setValue('PARA CRIAR');
  }

}

certificateEditForm() {
  this.editCertificateForm = this.formBuilder.group({
    name:  ['', [Validators.required] ],
  })

  console.log('editCertificateForm: ', this.editCertificateForm)

  this.editCertificateForm.valueChanges.subscribe(val => {
    this.buttonUnlocked = val;
    this.isLoaded$.next(this.buttonUnlocked)
  })
}


certificatePostForm() {
  this.postCertificateForm = this.formBuilder.group({
    name:  ['', [Validators.required] ],
  })

  console.log('postCertificateForm: ', this.postCertificateForm)

  this.postCertificateForm.valueChanges.subscribe(val => {
    this.buttonUnlocked = val;
    this.isLoaded$.next(this.buttonUnlocked)
    })
  }

/* addOrEditCertificate(){
if (this.editMode) {
  this.certificateEditForm();
} else {
  this.certificatePostForm();
  }
} */

  openUploadContent(){
    this.sharedService.modalOpen();
    this.isLoaded$.subscribe( val => {
      this.buttonUnlocked = val
      if(val === true ) {
        this.sharedService.modalClose();
      }
    })
   }


   saveCertificate() {
     if (this.editMode) {
      this.editedCertificate.emit()
     } else {
       console.log('SAVE MODO POST')
      this.postCertificate.emit()
     }

   }


   title() {
   if (this.editMode) {
    this.editModalTitle = this.i18n.disciplines_cert_adit
   } else {
    this.editModalTitle = this.i18n.disciplines_cert_add
   }
   }


   editOrAddMode() {
    this.modalEditOrAdd$.subscribe( val => {
      console.log('Modo editar? : ', val)
      this.editMode = val
    })
   }

}
