<div class="container submission-box">
  <header class="header">
    <h2 class="header__title">{{ i18n.disciplines_content_submissions_details_tasks }}</h2>
  </header>

  <section class="overall">
    <div class="submission" *ngFor="let submission of submissions">
      <div class="submission__user">
        <app-user-frame [img_src]="submission.user.image" [user_name]="submission.user.name"
        [breakpoint_X]="'infinite'"></app-user-frame>

        <div class="user__name">
          {{ submission.user.name }}
        </div>
      </div>

      <div class="submission__user-info">
        <div class="row stats">
          <div class="row__title">
            {{ i18n.disciplines_emit_status }}:
          </div>

          <div class="row__content">
            <i class="stats__icon"
              [ngClass]="{
              'check-fill-icon': submission.status.name === 'completed',
              'circle-x-fill-icon': submission.status.name === 'pending',
              'clock-pending-icon': submission.status.name === 'late'
            }"></i>
            {{ submission.status.translation }}
          </div>
        </div>

        <div class="row deadline">
          <div class="row__title">
            {{ i18n.disciplines_content_submissions_date_deliveries }}:
          </div>

          <div *ngIf="submission.course_work.at(-1)?.completed_at" class="row__content">
            {{ shared.getLocaleDate(submission.course_work.at(-1)?.completed_at) }}
          </div>

          <div *ngIf="!submission.course_work.at(-1)?.completed_at" class="row__content">
            - - - - - - - - - -
          </div>
        </div>
      </div>

      <div class="view-details-btn query"
        [class.is-disabled]="!submission.course_work.at(-1)?.completed_at"
        [attr.tabindex]="submission.course_work.at(-1)?.completed_at ? 0 : ''"
        (click)="goToSubmissionsTab(submission)">
        {{ i18n.disciplines_content_submissions_to_view }}

        <i class="view-details-btn__icon arrow-right-icon"></i>
      </div>
    </div>
  </section>
</div>
