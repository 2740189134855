import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';

@Component({
  selector: 'app-cookies-enabled-modal',
  templateUrl: './cookies-enabled-modal.component.html',
  styleUrls: ['./cookies-enabled-modal.component.scss']
})
export class CookiesEnabledModalComponent implements OnInit {

  isCookieEnabled$: boolean = true; 

  constructor(
    private localStorageService: LocalStorageService,

  ) { }

  ngOnInit(): void {
    this.checkCookies()
  }

  checkCookies(){
    this.localStorageService.isCookieEnabled$.subscribe({
      next:(isCookieEnabled)=>{
        if (isCookieEnabled !== undefined) {
          this.isCookieEnabled$ = isCookieEnabled;
        }
      }
    })
  }

}
