import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit, OnChanges {
  contentUsersContents: any = []
  contentUsersUsers: any = []

  i18n: any = [];
  currentLanguage: any;

  @Input() reportsData: any = []

  @Output() screen = new EventEmitter();

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('users', changes?.reportsData?.currentValue?.CONTENTS_USERS?.users);
    this.contentUsersUsers = changes?.reportsData?.currentValue?.CONTENTS_USERS?.users;
    console.log('contents', changes?.reportsData?.currentValue?.CONTENTS_USERS?.contents);
    this.contentUsersContents = changes?.reportsData?.currentValue?.CONTENTS_USERS?.contents;
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Reports'),...this.sharedService.getTranslationsOf('Modal')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
    console.log('TRADUCAO', this.i18n);
  }

  textCut(limit: any, textToCut: any) {
    let text = textToCut;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }

  showBiggerScreen() {
    this.screen.emit('resources')
    console.log('CHAMANDO RESOURCES');

  }

  isShowResources() {
    if(this.contentUsersContents?.length > 0) {
      return true
    } else {
      return false
    }
  }
}
