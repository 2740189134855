import {
    AfterViewInit,
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-forum-area-text',
    templateUrl: './forum-area-text.component.html',
    styleUrls: ['./forum-area-text.component.scss']
})
export class ForumAreaTextComponent implements OnInit, AfterViewInit {
    commentForumForm: UntypedFormGroup;

    @Input() thread: any;
    @Input() isEditMode: boolean;
    @Input() i18n: any;
    @Input() user: any;
    @Input() isCommentAreaVisible: string;
    @Input() isReplyOpen: string;
    @Input() hasCancelButton = true;
    @Input() hasDashedBorder = true;
    @Input() hasReplyIt = true;

    @Output() sendIsEditMode = new EventEmitter<boolean>();
    @Output() sendIsReplyOpen = new EventEmitter<string>();
    @Output() sendIsCommentAreaVisible = new EventEmitter<string>();
    @Output() sendSaveComment = new EventEmitter();

    constructor(
        private FormBuilder: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        this.createCommentForumForm();
    }

    ngAfterViewInit(): void {
        if (
            this.thread
            && this.isReplyOpen
        ) {
            if (this.isReplyOpen.includes('edit')) {
                this.setValueForm(this.thread.text);
            }
        }
    }

    // Forms
    createCommentForumForm() {
        this.commentForumForm = this.FormBuilder.group({
            text: ['', [Validators.required]]
        });
    }

    setValueForm(threadText: string) {
        this.commentForumForm.controls.text.setValue(threadText);
    }

    get text() {
        return this.commentForumForm.get('text');
    }

    cancelReply() {
        this.sendIsEditMode.emit(false);
        this.sendIsReplyOpen.emit('');
        this.sendIsCommentAreaVisible.emit('');
        this.commentForumForm.reset();
    }

    saveComment(thread_id: any) {
        const sendCommentObject = {
            thread_id,
            thread_text: this.commentForumForm.controls.text.value
        };
        this.sendSaveComment.emit(sendCommentObject);
        this.commentForumForm.reset();
    }
}
