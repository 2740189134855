<!--MODAL Confirm Delete Certificate-->
<div class="transition ease-out duration-300 animate-fade-in-down overflow-x-hidden overflow-y-auto outline-none justify-center items-center flex"
    style="position: absolute; z-index: 6; top: 40%; left: 40%">
    <div class="relative w-auto mx-auto max-w-6xl">
        <!--content-->
        <div
            class="border-0 rounded-2xl shadow-lg relative flex flex-col confirmCentralized w-full outline-none focus:outline-none bg-customWhite-default">
            <!--body-->
            <div class="confirmCentralized mt-4 mb-2">
                <img src="../../../../../assets/svg/certificate/exclamation.svg" alt="">
            </div>
            <div class="confirmBody confirmCentralized">
            <p> {{i18n.disciplines_default_del}}<span style="color: red;">{{i18n.disciplines_cert_default}} </span></p>
            </div>
            <!--footer-->
            <div class="flex items-center justify-center p-3 rounded-b mt-4 mb-2">
                <button class="button-save mr-4" (click)="confirming()">
                    <p>{{i18n.disciplines_delete}}</p>
                </button>
                <button class="button-cancel border-2 border-customBlue-default" (click)="closeDelDefault()">
                    <p>{{i18n.disciplines_emit_disabled_goback}}</p>
                </button>
            </div>
        </div>
    </div>
</div>
 <div *ngIf=" modalDefaultCertificateDel$" class="overlay"
    (click)="closeDelDefault() " style="z-index: 5"></div>
