import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SidebarComponent } from './sidebar.component';
import { LoginModule } from 'src/app/pages/login/login.module';
import { PermissionModule } from 'src/app/directives/permission/permission.module';


@NgModule({
  declarations: [
    SidebarComponent,
  ],
  exports: [
    SidebarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LoginModule,
    PermissionModule
  ]
})

export class SidebarModule { }
