<app-generic-modal [isVisible]="isVisibleModalSelectSkills">
  <div class="py-6 container-modal">
    <header
      class="text-center text-customBlue-default border-b border-customBlue-glass pb-4 px-6"
    >
      <p class="font-bold text-26">{{ i18n.planner_selection_skills }}</p>
      <p>{{ i18n.planner_select_skills_want_add_in_planner }}</p>
    </header>
    <div class="p-6">
      <article
        class="text-customGray-default mb-8 flex items-center rounded-lg border bg-customWhite-default border-customGray-lighterGray w-full sm:w-auto"
      >
        <button class="cursor-pointer p-2" type="button">
          <span class="iconify text-24" data-icon="mingcute:search-line"></span>
        </button>
        <input
          class="px-4 py-2 rounded-lg w-full outline-none"
          type="text"
          name="search-skills"
          id="search-skills"
          autocomplete="off"
          placeholder="{{ i18n.planner_looking_for }}..."
          [(ngModel)]="searchQuery"
          (keyup)="getSearchSkills(searchQuery)"
        />
        <label class="hidden" for="search-skills">{{
          i18n.planner_looking_for_planners
        }}</label>
      </article>

      <!-- Grouped By guideline -->
      <div>
        <div
          *ngIf="guidelines.length && !loading && !searchQuery"
          class="guidelines flex flex-col gap-4 text-customGray-dark overflow-y-auto h-max-list"
        >
          <div
            *ngFor="let guideline of guidelines; let i = index"
            class="guideline border border-l-12 border-customGray-lighterGray rounded-md px-3 pt-3 pb-6"
          >
            <div
              id="guideline-title"
              class="flex flex-row justify-between"
              (click)="toggleExpand(i)"
            >
              <span
                class="font-Montserrat font-semibold text-16 text-customBlue-royal cursor-pointer"
              >
                {{ guideline?.name }}
              </span>
              <div [ngClass]="{ 'rotate-180': isExpanded[i] }">
                <span
                  class="iconify"
                  data-icon="raphael:arrowdown"
                  style="color: #233674"
                  data-width="16"
                  data-height="16"
                ></span>
              </div>
            </div>
            <div id="guideline-content" [ngClass]="{ hidden: !isExpanded[i] }">
              <div
                *ngFor="let segment of guideline?.segment; let j = index"
                class="flex flex-col gap-2"
              >
                <div class="flex gap-2">
                  <div class="border-divider flex justify-center items-center">
                    <div class="divider-line"></div>
                    <div class="divider-circle"></div>
                  </div>
                  <div>
                    <span
                      (click)="toggleExpandSegment(j, guideline.id, segment.id)"
                      class="segment-title text-customBlue-royal font-Montserrat text-16 font-semibold hover:underline cursor-pointer"
                    >
                      {{ segment.description }}
                    </span>
                  </div>
                </div>

                <div
                  *ngIf="isExpandedSegment[j]"
                  class="component-content flex flex-col gap-4 text-customGray-dark"
                >
                  <div *ngIf="componentsList[j]?.length">
                    <div
                      *ngFor="let component of componentsList[j]; let k = index"
                      class="flex flex-col gap-2"
                    >
                      <div>
                        <span
                          (click)="
                            toggleExpandComponent(k, guideline.id, component.id)
                          "
                          class="segment-title text-customBlue-royal font-Montserrat text-16 font-semibold hover:underline cursor-pointer"
                        >
                          {{ component.description }}
                        </span>
                      </div>
                      <div
                        *ngIf="isExpandedComponent[k]"
                        class="flex flex-col gap-4 text-customGray-dark"
                      >
                        <div *ngIf="skillsList[k]">
                          <article
                            class="text-customGray-default flex items-center rounded-lg border bg-customWhite-default border-customGray-lighterGray w-full sm:w-auto"
                          >
                            <button class="cursor-pointer p-2" type="button">
                              <span
                                class="iconify text-24"
                                data-icon="mingcute:search-line"
                              ></span>
                            </button>
                            <input
                              class="px-4 py-2 rounded-lg w-full outline-none"
                              type="text"
                              name="search-skills"
                              id="search-skills"
                              autocomplete="off"
                              placeholder="{{ i18n.planner_looking_for }}..."
                              [(ngModel)]="searchQueryByComponent"
                              (keyup)="
                                getSearchSkillsByComponent(
                                  k,
                                  guideline.id,
                                  component.id,
                                  searchQueryByComponent
                                )
                              "
                            />
                            <label class="hidden" for="search-skills">{{
                              i18n.planner_looking_for_planners
                            }}</label>
                          </article>
                        </div>
                        <ul class="flex flex-col gap-4 text-customGray-dark">
                          <li
                            class="border border-customGray-mediumGray p-2"
                            *ngFor="let skill of skillsList[k]; let l = index"
                          >
                            <header
                              class="flex items-center flex-wrap sm:flex-nowrap gap-3 mb-2"
                            >
                              <app-check-button
                                class="w-6"
                                [iconSize]="22"
                                [active]="skill.isSelected"
                                (change)="
                                  skill.isSelected = !skill.isSelected;
                                  setSelected(skill, skill.isSelected)
                                "
                              >
                              </app-check-button>
                              <p
                                class="text-customBlue-default border border-customBlue-default py-0.5 px-2 rounded flex items-center justify-center"
                              >
                                {{ "(" + skill.code + ")" }}
                              </p>
                            </header>
                            <div>
                              <p>{{ skill.description }}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          class="text-customBlue-royal p-2 bg-customGray-tableGray text-center"
          *ngIf="!guidelines.length && !loading"
        >
          {{ i18n.planner_do_not_have_skills_to_select }}
        </p>
        <app-bullets-loader *ngIf="loading"></app-bullets-loader>
      </div>

      <!-- Global Search -->
      <div *ngIf="searchQuery">
        <ul
          class="flex flex-col gap-4 text-customGray-dark overflow-y-auto h-max-list"
          *ngIf="filteredSkills.length && !loading"
        >
          <li
            class="border border-customGray-mediumGray p-2"
            *ngFor="let skill of filteredSkills"
          >
            <header
              class="flex items-center flex-wrap sm:flex-nowrap gap-3 mb-2"
            >
              <app-check-button
                class="w-6"
                [iconSize]="22"
                [active]="skill.isSelected"
                (change)="
                  skill.isSelected = !skill.isSelected;
                  setSelected(skill, skill.isSelected)
                "
              >
              </app-check-button>
              <p
                class="text-customBlue-default border border-customBlue-default py-0.5 px-2 rounded flex items-center justify-center"
              >
                {{ "(" + skill.code + ")" }}
              </p>
            </header>
            <div>
              <p>{{ skill.description }}</p>
            </div>
          </li>
        </ul>
        <p
          class="text-customBlue-royal p-2 bg-customGray-tableGray text-center"
          *ngIf="!filteredSkills.length && !loading && searchQuery"
        >
          {{ i18n.planner_do_not_have_skills_to_select }}
        </p>
        <div class="flex justify-center" *ngIf="filteredSkills.length">
          <app-pagination
            #pagination
            class="pagination"
            [params]="paginationParams"
            (navigation)="goToPage($event)"
          ></app-pagination>
        </div>
      </div>
    </div>
    <footer class="flex flex-col sm:flex-row justify-center gap-3 p-6 pb-0">
      <button
        type="button"
        (click)="closeModal(); resetModalData(); emitGuidelines()"
        class="flex items-center justify-center w-full sm:w-auto text-customBlue-default border border-customBlue-default bg-transparent py-1.5 px-10 rounded"
      >
        {{ i18n.planner_back }}
      </button>
      <button
        type="button"
        [disabled]="!skillsSelectedList.length"
        [ngClass]="{
          'cursor-not-allowed opacity-75': !skillsSelectedList.length
        }"
        (click)="onSubmit()"
        class="w-full sm:w-auto text-customWhite-default bg-customBlue-default py-1.5 px-10 rounded"
      >
        {{ i18n.planner_save }}
      </button>
    </footer>
  </div>
</app-generic-modal>
