import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';

@Injectable({
  providedIn: 'root'
})
export class TitlePageService {
  subscription: Subscription[]=[]

  constructor(
    private titleService: Title,
    private disciplineDetailService: DisciplineDetailService,
  ) { }

  setTitleSite(title?: string){
    
    if (title !== undefined && title !== '' ) {
      this.titleService.setTitle(title);
    }else{
      if (localStorage.getItem('site_metadata') !== null) {
        const data = JSON.parse(localStorage.getItem('site_metadata')!);
        if (data.settingsGeneral) {
          this.titleService.setTitle(data.settingsGeneral.site_title);
        }
      }
    }
  }

  resolveTitle(route: ActivatedRouteSnapshot, paths: any){
    //  unsubscribe to avoiid memory leaks
    if (this.subscription.length > 0) {
      this.subscription.forEach(element => {
          element.unsubscribe();
      });
    }
    // get the current path
    const currentPath = route.routeConfig?.path;

    //get the path from paths objects to set title
    const path: any = paths.find(path => path.url === currentPath) || {};

    // If has no route send undefined to titleService to
    // get title from default site_metada settings
    if (!path) {
        this.setTitleSite(undefined);
        return;
    }

    let title = path.title;

    // If title dont need replace treatment ($) send
    // title from i18n translations
    if (title != undefined && !title.includes('$')) {
        this.setTitleSite(title);
    }
    // Receive title if it was sent from observable
    let sub1 = this.disciplineDetailService.titleName$
    .pipe(
        filter(valor => valor !== undefined && valor !== ''),//only complete the subscribe if has disciplineName
    )
    .subscribe({
        next:(disciplineName: any) => {
            title = path.title;
            if (title != undefined && title.includes('$')) {
                title = title.replace('$', disciplineName);
            }
            this.setTitleSite(title);
        },
        complete:()=>{

        }
    })
    this.subscription.push(sub1);
  }
}
