import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { SubmissionsService } from '../../../../submissions.service';

@Component({
    selector: 'app-submission-type-quiz',
    templateUrl: './submission-type-quiz.component.html',
    styleUrls: ['./submission-type-quiz.component.scss'],
})
export class SubmissionTypeQuizComponent implements OnInit, OnDestroy {
    @Output() sendCorrectionQuestion = new EventEmitter();
    @Input() evaluative_content = false;
    state$: Subscription;
    questions: any[];
    finished_at = '';
    questionCorrection: any[] = [];

    constructor(
        private submissionService: SubmissionsService
    ) {}

    ngOnInit(): void {
        this.subscribeState();
    }

    subscribeState() {
        this.state$ = this.submissionService.homework
            .subscribe((questionsObj) => {
                console.log('questionsObj ', questionsObj);

                this.questionCorrection = [];
                this.questions = questionsObj.questions;
                this.finished_at = questionsObj?.date;
            });
    }

    receiveCorrection(event) {
        let editItem = false;

        this.questionCorrection.map((element, i: number) => {
            if (element.question_external_id === event.question_external_id) {
                this.questionCorrection[i] = event;
                editItem = true;
            }
        });

        if (!editItem) {
            this.questionCorrection.push(event);
        }

        this.sendCorrectionQuestion.emit({ review: this.questionCorrection, finished_at: this.finished_at });
    }

    ngOnDestroy(): void {
        if (this.state$) this.state$.unsubscribe();
    }
}
