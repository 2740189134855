<div class="questions">
  <app-question-bank-question
    *ngFor="let question of questions; let index = index"
    [data]="question"
    [noEdit]="true"
    [noDelete]="true"
    [order]="index"
    [readonly]="true"
    [correction]="true"
    [correctionTeacher]="true"
    [comments]="question.comments"
    [evaluative_content]="evaluative_content"
    (sendPointsCorrection)="receiveCorrection($event)"
  >
  </app-question-bank-question>
</div>
