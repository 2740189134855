<section class="container pl-40 px-12 py-8">
    <h1 class="text-customBlue-default font-bold text-3xl">Meu Perfil</h1>
    <div class="my-8 py-12 container px-48 bg-customWhite-default shadow-lg rounded-lg mx-auto">
        <div class="flex justify-center">
            <div id="name" class="rounded-full bg-inicieColors-orange mr-12 font-bold">
                {{nome}}
            </div>
            <div class="text-center tracking-wide">
                <h2 class="font-bold my-2">Diego Soek</h2>
                <h3 class="font-semibold my-2">Super Administrador</h3>
                <span class="font-light">diego@inicie.digital</span>
            </div>
        </div>
    </div>  

    <div class="container w-2/5 rounded-lg bg-customWhite-default">
        <div class="bg-customBlue-light h-12 items-center flex justify-center rounded-t-lg">
            <h4 class="font-bold text-inicieColors-blue text-md">Minhas Configurações</h4>
        </div>
        <div class="bg-customWhite-default rounded-b-lg p-6">
            <div class="flex justify-evenly">
                <button class="border rounded-md py-2 px-8 font-bold text-sm border-customGray-textGray">Emitir Token</button>
                <button class="border rounded-md py-2 px-8 font-bold text-sm border-customGray-textGray">Alterar senha</button>
            </div>
            <div class="flex justify-between my-6 px-6">
                <span>Idioma</span>
                <select class="border rounded-md py-0.5 px-8 flex font-bold text-sm border-customGray-textGray cursor-pointer">
                    <option value="">Português</option>
                    <option value="">English</option>
                    <option value="">Español</option>
                </select>
            </div>
            <div class="flex justify-between my-6 px-6">
                <span>Acessibilidade</span>
                <label class="flex items-center">
                    <input id="toogle" class="relative right-28 w-12 h-5 transition-all duration-200 ease-in-out bg-gray-400 rounded-full shadow-inner outline-none appearance-none cursor-pointer" type="checkbox" />
                </label>
            </div>
        </div>
    </div>
    <button class="upload" (click)="openModal()">
        <!-- <fa-icon [icon]="faCloudUpload"></fa-icon> -->
        <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6835 9.09779C13.6616 9.06732 13.6338 9.04269 13.6019 9.02575C13.5701 9.0088 13.5351 9 13.4997 9C13.4643 9 13.4294 9.0088 13.3975 9.02575C13.3657 9.04269 13.3378 9.06732 13.316 9.09779L10.0495 13.612C10.0226 13.6495 10.0059 13.6946 10.0013 13.7421C9.9967 13.7895 10.0044 13.8374 10.0236 13.8803C10.0428 13.9231 10.0726 13.9592 10.1097 13.9845C10.1467 14.0097 10.1896 14.023 10.2332 14.0229H12.3885V21.7451C12.3885 21.8853 12.4935 22 12.6219 22H14.3718C14.5001 22 14.6051 21.8853 14.6051 21.7451V14.0261H16.7662C16.9616 14.0261 17.0695 13.7808 16.9499 13.6152L13.6835 9.09779Z" fill="white"/>
        <path d="M22.5221 6.5455C21.142 2.72017 17.6253 0 13.506 0C9.38672 0 5.87009 2.717 4.48996 6.54233C1.90748 7.25483 0 9.728 0 12.6667C0 16.1658 2.69699 19 6.02377 19H7.23214C7.36473 19 7.47321 18.886 7.47321 18.7467V16.8467C7.47321 16.7073 7.36473 16.5933 7.23214 16.5933H6.02377C5.00826 16.5933 4.05301 16.169 3.34185 15.3995C2.63371 14.6332 2.25703 13.6008 2.29018 12.5305C2.3173 11.6945 2.5885 10.9092 3.07969 10.2473C3.58292 9.57283 4.28806 9.082 5.07154 8.8635L6.21362 8.55L6.63248 7.391C6.89163 6.669 7.25324 5.9945 7.70826 5.38333C8.15747 4.77757 8.68958 4.24507 9.28728 3.80317C10.5258 2.888 11.9843 2.4035 13.506 2.4035C15.0278 2.4035 16.4863 2.888 17.7248 3.80317C18.3244 4.2465 18.8548 4.7785 19.3038 5.38333C19.7588 5.9945 20.1204 6.67217 20.3796 7.391L20.7954 8.54683L21.9345 8.8635C23.5677 9.32583 24.7098 10.887 24.7098 12.6667C24.7098 13.7148 24.3211 14.7028 23.616 15.4438C23.2702 15.8094 22.8588 16.0992 22.4057 16.2965C21.9526 16.4938 21.4667 16.5947 20.9762 16.5933H19.7679C19.6353 16.5933 19.5268 16.7073 19.5268 16.8467V18.7467C19.5268 18.886 19.6353 19 19.7679 19H20.9762C24.303 19 27 16.1658 27 12.6667C27 9.73117 25.0986 7.26117 22.5221 6.5455Z" fill="white"/>
        </svg>
        
    </button>    
</section>
<app-upload *ngIf="modalOpen$ | async" [maxFiles]="maxFiles" [files]="files" [multiSelect]="false" [fileType]="'photo'" (filesEvent)="receiveFiles($event)"></app-upload>
