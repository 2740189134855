import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    i18n: any = [];
    notificationAll$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    getNotifications$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private router: Router,
    ) {
        this.getTranslations();
    }

    getNotificationsConfigList() {
        return this.http.get<any>(`${API_Routes.URL}/users/notifications/list`);
    }

    postNotificationsConfigList(params) {
        return this.http.post<any>(`${API_Routes.URL}/users/notifications`, params);
    }

    getNotificationsAll(): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/notifications?all=1`);
    }

    getNotification(id: any): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/notifications/${id}`);
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Errors'),
            ...this.sharedService.getTranslationsOf('Notifications'),
        };
    }

    toAnnouncements(discipline_id: any) {
        this.router.navigate(
            [`/disciplines/${discipline_id}/announcements`],
        );
    }

    toSections(discipline_id: any) {
        this.router.navigate(
            [`/disciplines/${discipline_id}/content-tab`],
        );
    }

    toCertificates(discipline_id: any) {
        this.router.navigate(
            [`/disciplines/${discipline_id}`],
        );
    }

    toDisciplineOverview(discipline_id: any) {
        this.router.navigate(
            [`/disciplines/${discipline_id}/overview`]
        );
    }

    toDisciplineForum(discipline_id: any) {
        this.router.navigate(
            [`/disciplines/${discipline_id}/forum`]
        );
    }

    toDisciplineContent(discipline_id: any) {
        this.router.navigate(
            [`/disciplines/${discipline_id}/content-tab`]
        );
    }

    toUsers() {
        this.router.navigate(
            ['/users']
        );
    }

    whatIsTheTypeTitle(type) {
        switch (type) {
            case 'Announcement':
                return this.i18n.notifications_announcement;

            case 'UpdatePassword':
                return this.i18n.notifications_update_password;

            case 'RedefinePassword':
                return this.i18n.notifications_redefine_password;

            case 'NotificationClass':
                return this.i18n.notifications_notification_class;

            case 'NewContent':
                return this.i18n.notifications_new_content;

            case 'Certificate':
                return this.i18n.notifications_certificate;

            case 'VinculatedDiscipline':
                return this.i18n.notifications_user_vinculated_discipline;

            case 'CourseWorkCompletion':
                return this.i18n.notifications_coursework_completion;

            case 'ForumInteraction':
                return this.i18n.notifications_forum_interaction;

            case 'NewDelivery':
                return this.i18n.notifications_new_devlivery;

            case 'NewUser':
                return this.i18n.notifications_new_user;

            default:
                return this.i18n.notifications_default;
        }
    }

    whatIsTheTypeIcon(type) {
        switch (type) {
            case 'Announcement':
                return 'letter-notification-icon';

            case 'NewContent':
                return 'hat-notification-icon';

            case 'Certificate':
                return 'hat-notification-icon';

            case 'NotificationClass':
                return 'hat-notification-icon';

            case 'VinculatedDiscipline':
                return 'hat-notification-icon';

            default:
                return 'bell-notification-icon';
        }
    }

    whatIsTheTypeRoute(type: any, external_id: any) {
        switch (type) {
            case 'Announcement':
                this.toAnnouncements(external_id);
                break;
            case 'NewContent':
                this.toSections(external_id);
                break;
            case 'Certificate':
                this.toCertificates(external_id);
                break;
            case 'NotificationClass':
                this.toDisciplineOverview(external_id);
                break;
            case 'VinculatedDiscipline':
                this.toDisciplineOverview(external_id);
                break;
            case 'CourseWorkCompletion':
                this.toDisciplineContent(external_id);
                break;
            case 'ForumNewThread':
                this.toDisciplineForum(external_id);
                break;
            case 'ForumInteraction':
                this.toDisciplineForum(external_id);
                break;
            case 'NewDelivery':
                this.toDisciplineContent(external_id);
                break;
            case 'NewUser':
                this.toUsers();
                break;
            default:
                return console.log('Erro');
        }
    }
}
