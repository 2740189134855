import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { DisciplineSettingsGeneralService } from './discipline-settings-general.service';

@Component({
    selector: 'app-discipline-settings-general',
    templateUrl: './discipline-settings-general.component.html',
    styleUrls: ['./discipline-settings-general.component.scss']
})
export class DisciplineSettingsGeneralComponent implements OnInit {
    external_id: any;
    settingsGeneral: any = [];
    settingsGeneralForm: UntypedFormGroup;
    isLoading = false;
    public buttonActive = false;
    i18n: any = [];
    currentLanguage: any;
    constructor(
        private settingsGeneralService: DisciplineSettingsGeneralService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        public platModalService: PlatformModalsService,
        private sharedService: SharedService,
        private toastrService: ToastrService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.isLoading = true;
        this.buttonActive = false;
        this.getUrlParams();
        this.getGeneralSettings(this.external_id);
        this.createGenralForm();
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal'), ...this.sharedService.getTranslationsOf('Errors') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getUrlParams() {
        this.route?.parent?.parent?.params?.subscribe((params) => {
            this.external_id = params.id;
        });
    }

    getGeneralSettings(external_id: any) {
        this.settingsGeneralService.getDisciplineGeneral(external_id)
            .subscribe({
                next: (settingsGeneral) => {
                    this.setValueForm(settingsGeneral);
                    this.settingsGeneral = settingsGeneral;
                },
                error: (err) => {
                    this.isLoading = false;
                    this.platModalService.toggle('message', err.error.error, 'close');
                },
                complete: () => {
                    this.buttonActive = false;
                    this.isLoading = false;
                }
            });
    }

    postGeneralSettings() {
        const defaultOrdering = this.settingsGeneralForm.controls.default_ordering.value;
        let ordering: boolean;

        if (defaultOrdering === 'true') {
            ordering = true;
        } else {
            ordering = false;
        }
        const params = {
            default_ordering: ordering,
        };

        this.settingsGeneralService.postDisciplineGeneral(this.external_id, params)
            .subscribe({
                next: () => {
                    this.buttonActive = false;
                    this.toastrService.show(this.i18n.disciplines_settings_general_order_updated, this.i18n.disciplines_settings_general, { progressBar: true }, 'toast-success');
                },
                error: (err) => {
                    if (err.status === 403) {
                        this.platModalService.toggle('message', this.i18n.discipline_archived, 'close');
                    }
                }
            });
    }

    setValueForm(settingsGeneral: any) {
        this.settingsGeneralForm.controls.default_ordering.setValue(settingsGeneral.default_ordering);
    }

    private createGenralForm() {
        this.settingsGeneralForm = this.formBuilder.group({
            default_ordering: ['', [Validators.required]],
        });
    }

    changeConfigForm() {
        this.buttonActive = true;
    }
}
