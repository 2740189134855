<section class="text-customBlue-default">
  <h1 class="text-22 font-bold font-Montserrat">
    {{ i18n.planner_new_planner }}
  </h1>

  <header
    class="bg-customWhite-default p-3 flex items-center gap-4 rounded my-8"
    *ngIf="planning"
  >
    <div class="bg-customBlue-light p-3 w-max rounded-full">
      <span class="iconify text-24" data-icon="tabler:school"></span>
    </div>
    <span>
      <p>{{ planning.segment_name }}</p>
      <p>
        {{ planning.degree_name }} - {{ planning.area_name }} -
        {{ planning.component_name }}
      </p>
    </span>
  </header>

  <article class="my-8">
    <h2 class="font-extrabold font-Montserrat">
      {{ i18n.planner_step_two_curricular_parameters }}
    </h2>
    <p>{{ i18n.planner_fill_the_fields_and_Select_skills }}</p>
  </article>

  <div class="flex flex-col sm:flex-row gap-4">
    <!-- Novos módulos -->
    <div id="new-module" class="sm:w-1/2 rounded-tl-md rounded-tr-md">
      <!-- Titulo -->
      <header
        class="flex items-center justify-between flex-wrap gap-2 bg-customGray-gainsboro rounded-tl-md rounded-tr-md p-2 pt-4"
      >
        <p class="font-extrabold text-customBlue-royal font-Montserrat">
          {{ i18n.planner_module }} {{ modulesList.length + 1 }}
        </p>
      </header>

      <!-- Form to new module -->
      <div
        class="bg-customWhite-default p-8 border border-dashed border-customGray-mediumGray"
      >
        <form
          class="self-start flex flex-col gap-6"
          [formGroup]="form"
          *ngIf="modulesValuesListSelect.length && !loading"
        >
          <fieldset
            class="flex flex-col gap-1"
            *ngFor="let select of modulesValuesListSelect"
          >
            <label
              class="font-semibold font-Montserrat letter-first-uppercase"
              for="{{ select.key_name }}"
              >{{ select.name }}</label
            >
            <div
              *ngFor="let subSelect of select.values; let i = index"
              class="mb-1"
            >
              <div class="flex flex-row gap-2 w-full">
                <div class="multi-select w-full">
                  <p-dropdown
                    [options]="subSelect.values"
                    optionLabel="description"
                    placeholder="{{ i18n.planner_select }}"
                    optionValue="id"
                    formControlName="{{
                      select.key_name + '_' + subSelect.index
                    }}"
                    [style]="{ width: '100%'}"
                    (onChange)="onSelectChange($event)"
                  >
                  </p-dropdown>
                </div>
                <!-- <div class="multi-select w-full" #multiSelectElement>
                  <p-multiSelect
                    [options]="subSelect.values"
                    formControlName="{{ select.key_name + '_' + subSelect.index }}"
                    [displaySelectedLabel]="true"
                    [filter]="true"
                    [style]="{
                      width: '100%',
                      'max-width': '100%',
                      height: '36px'
                    }"
                    optionLabel="description"
                    optionValue="id"
                    [placeholder]="i18n.planner_select"
                    [emptyFilterMessage]="i18n.planner_filter_empty_message"
                    [selectionLimit]="1"
                    (onChange)="onSelectChange($event)"
                  >
                    >
                  </p-multiSelect>
                </div> -->
                <button
                  *ngIf="i > 0"
                  (click)="
                    removeModelValueSelects(select.key_name, subSelect.index)
                  "
                  type="button"
                  class="flex border border-inicieColors-orange rounded-md justify-center items-center"
                  style="width: 36px"
                >
                  <span
                    class="iconify text-inicieColors-orange"
                    data-icon="bi:trash"
                  ></span>
                </button>
                <button
                  (click)="addModelValueSelects(select.key_name)"
                  type="button"
                  class="flex border border-customBlue-default rounded-md justify-center items-center"
                  style="width: 36px"
                >
                  <span
                    class="iconify text-customBlue-default"
                    data-icon="ic:baseline-plus"
                  ></span>
                </button>
              </div>
            </div>
          </fieldset>
        </form>

        <div *ngIf="!loading" class="mt-6 w-full">
          <button
            type="button"
            (click)="toggleModalSelectSkills(true)"
            class="text-13 border border-customBlue-default p-2 rounded leading-4 w-full"
          >
            <span class="font-Roboto text-15 font-medium">
              {{ i18n.planner_add_skills_and_learning_objectives }}
            </span>
          </button>
        </div>

        <!-- Sugestões de nome do módulo -->
        <div
          *ngIf="suggestedModuleNames.length > 0"
          class="flex flex-col gap-3 mt-6"
        >
          <p
            class="font-Montserrat font-medium text-16 text-customBlue-default font-semibold"
          >
            {{ i18n.planner_name_suggestions_module }}
          </p>
          <div class="flex flex-col overflow-y-auto pr-1.5">
            <div
              *ngFor="let name of suggestedModuleNames"
              class="flex items-start gap-2 mb-3"
            >
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  [value]="name"
                  (click)="selectModuleNameSuggestion(name)"
                  class="hidden"
                />
                <span class="check"></span>
              </label>
              <span>{{ name }}</span>
            </div>
          </div>
        </div>

        <!-- Botão de salvar -->
        <div
          *ngIf="!loading"
          class="flex w-full items-center justify-end gap-6 mt-10"
        >
          <button
            (click)="onSubmit()"
            type="button"
            [disabled]="!modulesValuesList.length"
            [ngClass]="{
              'cursor-not-allowed opacity-75': !modulesValuesList.length
            }"
            class="w-36 h-9 bg-customBlue-default text-customWhite-default flex justify-center items-center font-Roboto font-normal text-15"
          >
            <span class="">
              {{ i18n.planner_save }}
            </span>
          </button>
        </div>

        <!-- Loading  -->
        <app-bullets-loader *ngIf="loading"></app-bullets-loader>
      </div>
    </div>

    <!-- Módulos adicionados -->
    <div id="module-add" class="sm:w-1/2 rounded-tl-md rounded-tr-md h-full">
      <!-- Titulo -->
      <header
        class="flex items-center justify-between flex-wrap gap-2 bg-customGray-gainsboro rounded-tl-md rounded-tr-md p-2 pt-4"
      >
        <p class="font-extrabold text-customBlue-royal font-Montserrat">
          {{ i18n.planner_created_modules }}
        </p>
      </header>
      <div
        class="bg-customWhite-default p-8 border border-dashed border-customGray-mediumGray"
      >
        <p
          *ngIf="!modulesList.length && !loading"
          class="text-center font-Roboto font-medium text-18 text-customGray-default"
        >
          {{ i18n.planner_msg_empty_modules }}
        </p>

        <div>
          <ul class="flex flex-col gap-3 overflow-y-auto pr-1.5">
            <li *ngFor="let module of modulesList">
              <div
                class="relative border border-l-12 border-customGray-lighterGray rounded px-3 pt-3 pb-6"
                [ngClass]="{
                  'border-customOrange-default':
                    name_module.value.length > 255 ||
                    (module.name === null && triedGoToPlanning)
                }"
              >
                <textarea
                  class="w-full resize-none font-medium"
                  spellcheck="false"
                  name="name_module"
                  #name_module
                  placeholder="{{ i18n.planner_module }} {{ module.index }} -"
                  (input)="autoResizeTextArea($event)"
                  [style.height]="onLoadResizeTextArea(name_module)"
                  (blur)="
                    patchModuleName(module.external_id, name_module.value)
                  "
                  >{{ module.name }}</textarea
                >

                <footer
                  class="flex items-center text-customBlue-default absolute text-18 right-0 bottom-0 p-1"
                >
                  <button
                    type="button"
                    class="p-1"
                    (click)="decisionModule(module.external_id)"
                  >
                    <span class="iconify" data-icon="tabler:edit"></span>
                  </button>
                  <button
                    type="button"
                    class="p-1"
                    (click)="decisionDeleteModule(module.external_id)"
                  >
                    <span class="iconify" data-icon="bi:trash"></span>
                  </button>
                </footer>
              </div>

              <!-- Error message  -->
              <div>
                <small
                  *ngIf="name_module.value.length > 255"
                  class="text-customOrange-default"
                  >{{ i18n.planner_name_maxlength_255 }}</small
                >
                <small
                  *ngIf="module.name === null && triedGoToPlanning"
                  class="text-customOrange-default"
                  >{{ i18n.planner_fill_module_name }}</small
                >
              </div>
            </li>
          </ul>
        </div>

        <div
          *ngIf="!loading"
          class="flex w-full items-center justify-center gap-6 mt-10"
        >
          <button
            (click)="addModule()"
            type="button"
            [disabled]="!modulesValuesList.length"
            [ngClass]="{
              'cursor-not-allowed opacity-75': !modulesValuesList.length
            }"
            class="w-10 h-10 bg-customOrange-default text-customWhite-default rounded-full flex justify-center items-center font-bold text-18 button-module-hover"
            tooltipPosition="bottom"
            pRipple
            pTooltip="{{
              editMode
                ? i18n.planner_curricular_parameters_edit_params
                : i18n.planner_curricular_parameters_add_params
            }}"
          >
            <span class="iconify" data-icon="ic:baseline-plus"></span>
          </button>
          <span class="font-Roboto text-16 font-medium">
            {{ i18n.planner_add_new_module }}
          </span>
        </div>

        <!-- Loading  -->
        <app-bullets-loader *ngIf="loading"></app-bullets-loader>
      </div>
    </div>
  </div>

  <footer
    class="flex flex-col sm:flex-row items-stretch justify-end gap-3 mt-8"
  >
    <a
      [routerLink]="['/content-planner/start-planning', planningExternalId]"
      class="flex items-center justify-center w-full sm:w-auto text-customBlue-default border border-customBlue-default bg-transparent py-1.5 px-10 rounded"
    >
      {{ i18n.planner_back }}
    </a>
    <button
      type="button"
      (click)="checkHasNameModule()"
      [disabled]="!modulesList.length"
      [ngClass]="{ 'cursor-not-allowed opacity-75': !modulesList.length }"
      class="flex flex-col items-center justify-center w-full sm:w-auto text-customWhite-default bg-customBlue-default px-10 rounded text-14 py-1.5"
    >
      <span>{{ i18n.planner_go_to_planner }}</span>
    </button>
  </footer>
</section>

<app-modal-detailed-planning
  [i18n]="i18n"
  [isVisibleModalDetailedPlanning]="valueModalDetailedPlanning"
  (closeModalDetailedPlanning)="toggleModalDetailedPlanning($event)"
  [planningExternalId]="planningExternalId"
></app-modal-detailed-planning>

<app-modal-decision-module
  [i18n]="i18n"
  [isVisibleModalDecisionModule]="valueModalDecisionModule"
  (closeModalDecisionModuleAndNotSave)="closeModalDecisionModuleAndNotSave()"
  (closeModalDecisionModuleAndSave)="closeModalDecisionModuleAndSave()"
>
</app-modal-decision-module>

<app-modal-select-skills
  [isVisibleModalSelectSkills]="valueModalSelectSkills"
  [i18n]="i18n"
  [skillsSelectedList]="receiveSelectedSkills"
  (closeModalSelectSkills)="toggleModalSelectSkills($event)"
  (sendSelectedGuidelines)="receiveGuidelines($event)"
  [createdModule]="createdModule"
  [editModeSkills]="editModeSkills"
></app-modal-select-skills>

<app-modal-loading
  [isVisible]="isVisibleModalLoading"
  [message]="messageModalLoading"
></app-modal-loading>
