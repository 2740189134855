import {
    Component, Input, OnDestroy, OnInit
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { HomeworkSubmissionsState, selectHomeworkSubmissionsState } from '../../../../store/homework-submissions.reducer';

@Component({
    selector: 'app-submission-type-upload',
    templateUrl: './submission-type-upload.component.html',
    styleUrls: ['./submission-type-upload.component.scss']
})
export class SubmissionTypeUploadComponent implements OnInit, OnDestroy {
    @Input() i18n: any = {};

    state: HomeworkSubmissionsState;
    state$: Subscription;
    courseWorkFiles: any = [];
    courseWorkFileIndex;

    constructor(
        private store: Store,
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit(): void {
        this.state$ = this.store.select(selectHomeworkSubmissionsState).subscribe((state) => {
            console.log('state ', state);

            this.state = state;

            this.courseWorkFiles = [...this.state.activeCourseWork.contentSpecific?.files];

            if (!this.courseWorkFiles.length) return this.courseWorkFiles = [];
        });
    }

    ngOnDestroy(): void {
        if (this.state$) this.state$.unsubscribe();
    }
}
