<section
  class="rounded-lg transition ease-out duration-180 animate-fade-in-down bg-customWhite-default border-2 border-customGray-lighterGray rounded-xl"
>
  <header
    class="flex flex-col gap-4 md:flex-row items-center justify-between py-3 px-9 border-b border-customBlue-glass"
  >
    <div class="flex items-center gap-2 text-customBlue-default">
      <p class="text-18 font-bold">{{ i18n.disciplines_content_rubric_new }}</p>
      <button type="button" (click)="displayPanel.toggle()">
        <span class="iconify-inline text-18" data-icon="carbon:help"></span>
      </button>
      <!-- Tooltip -->
      <div class="relative">
        <app-display-panel
          #displayPanel
          [style]="{ position: 'absolute' }"
          [custom_radius]="'10px'"
          [hide_arrow]="true"
          [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
          [newStyle]="{ top: '-13.125rem', left: '-0.1875rem' }">
          <small class="text-customGray-dark">
            {{ i18n.disciplines_content_help_rubric_create_tooltip }}
          </small>
        </app-display-panel>
      </div>
    </div>
    <div
      class="flex flex-col gap-3 md:flex-row items-center text-customGray-dark"
    >
      <p *ngIf="params.calculation_type === 'weighted_average'">
        {{ i18n.disciplines_content_points_for }}:
      </p>
      <form
        #FormPoints="ngForm"
        *ngIf="params.calculation_type === 'weighted_average'"
      >
        <input
          class="border border-customGray-default rounded py-2 px-3 text-14"
          id="rubrica_points"
          name="rubrica_points"
          type="number"
          autocomplete="off"
          #rubricapointsInput
          [min]="0"
          [max]="9999"
          [value]="rubrica_points_input"
          required
          [(ngModel)]="rubrica_points_input"
          (keyup)="
            changeWeightedAverage();
            validationCaracter(rubrica_points_input, rubricapointsInput)
          "
        />
      </form>
      <span class="flex items-center gap-2">
        <div class="flex items-center gap-1">
          <span>{{ i18n.disciplines_content_points_remain }}:</span>
          <span>{{ maxPoints }}</span>
          <span class="font-bold" *ngIf="maxPoints > 1">{{
            i18n.disciplines_content_points
          }}</span>
          <span class="font-bold" *ngIf="maxPoints < 2">{{
            i18n.disciplines_content_point
          }}</span>
        </div>
        <button type="button" (click)="displayPanelPoint.toggle()">
          <span class="iconify-inline text-18" data-icon="carbon:help"></span>
        </button>
      </span>
      <!-- Tooltip -->
      <div class="relative">
        <app-display-panel
          #displayPanelPoint
          [style]="{ position: 'absolute' }"
          [custom_radius]="'10px'"
          [hide_arrow]="true"
          [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
          [newStyle]="{ top: '-7.5rem', right: '90%' }"
        >
          <small>
            {{ i18n.disciplines_content_help_rubric_points_tooltip }}
          </small>
        </app-display-panel>
      </div>
    </div>
  </header>
  <form #Form="ngForm" (ngSubmit)="onSubmit(Form)">
    <!-- Criterion  -->
    <div
      class="flex flex-col gap-6 py-7 px-9 criterion__container--border"
      *ngFor="let criterion of listCriterion; let index = index"
    >
      <header
        class="flex flex-col gap-4 md:flex-row items-center justify-between"
      >
        <div class="flex flex-col gap-2">
          <label for="title_criterion_{{ index }}">{{
            i18n.disciplines_content_criterion_title
          }}</label>
          <input
            class="border border-customGray-mediumGray rounded p-4 text-center"
            id="title_criterion_{{ index }}"
            name="title_criterion_{{ index }}"
            type="text"
            autocomplete="off"
            [value]="criterion.name"
            required
            [(ngModel)]="criterion.name"
          />
        </div>
        <div class="flex items-center gap-2" *ngIf="index === 0">
          <app-plus-button
            [label]="i18n.disciplines_content_criterion_add_new"
            (click)="addCriterion()"
          ></app-plus-button>
          <button
            type="button"
            (click)="displayPanelAddCriterion.toggle()"
          >
            <span class="iconify-inline text-18" data-icon="carbon:help"></span>
          </button>
          <!-- Tooltip -->
          <div class="relative">
            <app-display-panel
              #displayPanelAddCriterion
              [style]="{ position: 'absolute' }"
              [custom_radius]="'10px'"
              [hide_arrow]="true"
              [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
              [newStyle]="{ top: '-10rem', right: '90%' }"
            >
              <small class="text-customGray-dark">
                {{ i18n.disciplines_content_help_criterion_tooltip }}
              </small>
            </app-display-panel>
          </div>
        </div>
      </header>
      <!-- Levels List  -->
      <ul class="flex flex-col gap-6">
        <li
          class="levels__grid--container grid items-stretch gap-6"
          *ngFor="let level of criterion.levels; let i = index"
        >
          <div class="flex flex-col gap-2">
            <label for="level_{{ index }}_{{ i }}">{{
              i18n.disciplines_content_level_text
            }}</label>
            <input
              class="border border-customGray-mediumGray rounded p-6 text-center h-full"
              id="level_{{ index }}_{{ i }}"
              name="level_{{ index }}_{{ i }}"
              type="text"
              autocomplete="off"
              [value]="level.name"
              [(ngModel)]="level.name"
              required
            />
          </div>
          <div class="flex flex-col gap-2 relative">
            <label
              class="flex gap-2 items-center"
              for="description_{{ index }}_{{ i }}"
              >{{ i18n.disciplines_content_description_text }}
              <button
                type="button"
                (click)="displayPanelDescription.toggle()"
              >
                <span
                  class="iconify-inline text-18"
                  data-icon="carbon:help"
                ></span>
              </button>
            </label>
            <input            
            class="border border-customGray-mediumGray rounded p-6 text-center h-full"
            id="description_{{ index }}_{{ i }}"
            name="description_{{ index }}_{{ i }}"
            type="text"
            autocomplete="off"
            [value]="level.description"
            [(ngModel)]="level.description"
            required
            >
            <!-- <textarea
              class="border border-customGray-mediumGray rounded p-3 text-center"
              id="description_{{ index }}_{{ i }}"
              name="description_{{ index }}_{{ i }}"
              type="text"
              rows="3"
              [(ngModel)]="level.description"
              required
            >
              {{ level.description }}</textarea
            > -->
            <!-- Tooltip -->
            <app-display-panel
              class="absolute"
              #displayPanelDescription
              [style]="{ position: 'absolute' }"
              [custom_radius]="'10px'"
              [hide_arrow]="true"
              [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
              [newStyle]="{ top: '-7.5rem' }"
            >
              <small>
                {{ i18n.disciplines_content_help_level_tooltip }}
              </small>
            </app-display-panel>
          </div>
          <div class="flex flex-col gap-2 relative">
            <label for="grade_{{ index }}_{{ i }}">{{
              i18n.disciplines_content_grade_text
            }}</label>
            <input
              class="border border-customGray-mediumGray rounded p-6 text-center h-full"
              id="grade_{{ index }}_{{ i }}"
              name="grade_{{ index }}_{{ i }}"
              type="number"
              autocomplete="off"
              #gradeInput
              [value]="level.points"
              [(ngModel)]="level.points"
              (keyup)="
                changeMaxPoints(); validationCaracter(level.points, gradeInput)
              "
              required
              [min]="0"
              [max]="9999"
              [ngClass]="{
                'border-customOrange-default': validationPositive === -1
              }"
            />
            <small
              class="text-customOrange-default text-center absolute bottom bottom-0 -bottom-6 left-0 w-full"
              *ngIf="validationPositive === -1"
              >{{ i18n.disciplines_content_validation_points }}</small
            >
          </div>
          <div class="flex items-end">
            <button class="p-4" type="button" (click)="deleteLevel(index, i)">
              <span
                class="iconify-inline text-18 text-customGray-dark"
                data-icon="bi:trash"
              ></span>
            </button>
          </div>
        </li>
      </ul>
      <div class="relative">
        <button
          class="flex items-center justify-center gap-2 border border-customBlue-default p-2 rounded text-customBlue-default font-medium px-10"
          type="button"
          (click)="addLevel(index)"
        >
          {{ i18n.disciplines_content_create_level }}
          <div (mouseenter)="displayPanelNewLevel.toggle()">
            <span class="iconify-inline text-18" data-icon="carbon:help"></span>
          </div>
        </button>
        <!-- Tooltip -->
        <app-display-panel
          class="absolute"
          #displayPanelNewLevel
          [style]="{ position: 'absolute' }"
          [custom_radius]="'10px'"
          [hide_arrow]="true"
          [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
          [newStyle]="{ top: '-10rem' }"
        >
          <small>
            {{ i18n.disciplines_content_help_level_tooltip }}
          </small>
        </app-display-panel>
      </div>
    </div>
    <footer class="flex items-center gap-2 justify-end px-9 py-4">
      <button
        class="bg-customBlue-default text-customWhite-default py-3 px-8 rounded text-center font-bold"
        type="submit"
        [class.is-disabled]="Form.invalid || maxPoints < 0"
      >
        {{ i18n.disciplines_add }}
      </button>
      <button
        class="text-customBlue-default py-3 px-8 rounded text-center font-bold"
        type="button"
        (click)="backward()"
      >
        {{ i18n.disciplines_cancel }}
      </button>
    </footer>
  </form>
</section>
