import { PermissionsService } from 'src/app/pages/permissions/permissions.service';
import { Component, OnInit, Input, EventEmitter, Output, AfterViewChecked } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
  selector: 'app-modal-permissions',
  templateUrl: './modal-permissions.component.html',
  styleUrls: ['./modal-permissions.component.scss']
})
export class ModalPermissionsComponent implements OnInit, AfterViewChecked{

  title: string
  roleForm: UntypedFormGroup
  isButtonActive: boolean = false
  submitted: boolean = false

  @Input() i18n: any = [];
  @Input() roles: any = []
  @Input() roleName: any = []
  @Input() roleType: any = []
  @Input() group_id: any = []
  @Input() externalId: any = []
  @Input() editMode: boolean = true

  @Output() reload = new EventEmitter();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private permissionsService: PermissionsService,
    private platModalsService: PlatformModalsService
  ) { }

  ngOnInit(): void {
    this.createRoleForm()
    this.modalMode(this.editMode);
  }

  ngAfterViewChecked() {
    this.roleForm?.valueChanges.subscribe(
      () => {
      this.isButtonActive = true;
    })
  }

  modalMode(editMode: any) {
    editMode = this.editMode
    if (editMode) {
      this.title = this.i18n.permissions_title_edit
      this.setValueForm(this.externalId, this.roleName, this.roleType)
    } else {
      this.title = this.i18n.permissions_title_create
    }
  }

  setValueForm(external_id: any, name: any, role_type: any){
    console.log('roleName', name);
    console.log('roleId', external_id);
    console.log('roleType', role_type);

    this.roleForm.controls['roleName'].setValue(name);
    this.roleForm.controls['roleId'].setValue(external_id);
    this.roleForm.controls['roleType'].setValue(role_type);
  }

  createRoleForm() {
    this.roleForm = this.formBuilder.group({
      roleName:  ['', [Validators.required, Validators.minLength(3), Validators.maxLength(26)]],
      roleId:    [''],
      roleType:  ['', [Validators.required]],
    })
  }

  modalClose() {
    console.log('PERMISSIONS MODAL CLOSE ')
    this.submitted = false;
    this.visibleChange.next(false);
  }

  hasError(field: any) {
    return this.roleForm.get(field)?.errors;
  }

  reloadRoles() {
    this.reload.emit(this.group_id)
  }

  formatRoleParams() {
    let roleName = this.roleForm.value.roleName;
    let roleType = this.roleForm.value.roleType;
    let roleId: any

    if (this.editMode) {
      roleId = this.roleForm.value.roleId;
    } else {
      roleId = roleName;
    }

    const params = {
      "name": roleName,
      "guard_name": "web",
      "external_id": roleId,
      "role_type": roleType
    }

    if (this.editMode) {
      this.patchRole(roleId, params)
    } else {
      this.postRole(params)
    }
  }

  patchRole(role_external_id: any, params: any) {
    this.permissionsService.patchRole(role_external_id, params)
      .subscribe({
        next: () => this.modalClose(),
        error: (err) => console.log(err),
        complete: () => this.reloadRoles(),
      }
    )
  }

  postRole(params: any) {
    this.permissionsService.postRole(params)
      .subscribe({
        next: () => this.modalClose(),
        error: () => {
          this.modalClose();
          this.showModalError(this.i18n.permissions_profile_name_exists);
        },
        complete: () => this.reloadRoles(), 
      }
    )
  }

  formatRoleName(roleName: any) {
    switch (roleName) {
      case 'admin':
        return this.i18n.permissions_admin
          break;
      case 'teacher':
        return this.i18n.permissions_teacher
          break;
      case 'student':
        return this.i18n.permissions_student
          break;
    }
  }

  onSubmit() {
    this.submitted = true;
    console.log('Form Role Value', this.roleForm.value);
    if (this.roleForm.valid) {
      console.log('SUBMIT', this.roleForm.valid);
      this.formatRoleParams();
    }
  }

  onCancel() {
    this.modalClose();
    this.submitted = false;
  }

  showModalError(messageError: string): void {
    this.platModalsService.toggle(
      'message',
      {
        message: messageError,
        icon_existence: true,
        icon_color: '#F36C48',
        custom_icon: 'attention-icon',
      },
      'close'
    );
  }

}
