import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-not-allowed-modal',
  templateUrl: './not-allowed-modal.component.html',
  styleUrls: ['./not-allowed-modal.component.scss']
})
export class NotAllowedModalComponent {

  @Input() i18n: any
  @Output() closeModal = new EventEmitter();

  closingModal(){
    this.closeModal.emit()
    console.log('CLICOU NO CLOSE');

  }

}
