import { NotificationsService } from './../../../pages/notifications/notifications.service';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-notification-tooltip',
  templateUrl: './notification-tooltip.component.html',
  styleUrls: ['./notification-tooltip.component.scss']
})
export class NotificationTooltipComponent implements OnChanges {
  @Output() closeNotifications = new EventEmitter();
  @Input() notificationsData: any[] = [];
  @Input() i18n: any = [];

  id: any
  isLoading: boolean = false
  isShow: boolean

  constructor (
    private router: Router, 
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
  ) { }

  ngOnChanges(): void {
    this.getNotifications();
  }

  @HostListener("document:click")
  clickedOut() {
    this.closingNotifications()
  }

  closingNotifications() {
    this.closeNotifications.emit(false);
  }

  navigate() {
    setTimeout(() => {
      this.router.navigate(['/notifications']);
    }, 150);
  }

  getNotifications() {
    this.isLoading = true
    this.verifyNotifications();
  }

  getNotification(id: any) {
    this.isLoading = true
    this.notificationsService
    .getNotification(id)
    .subscribe({
      next: (notification) => {
        this.whatIsTheTypeRoute(notification.notification_type, notification.external_id);
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false
        console.error(e)
      },
      complete: () => {
        
      }
    });
  }

  whatIsTheTypeRoute(type: any, external_id: any) {
    return this.notificationsService.whatIsTheTypeRoute(type, external_id)
  }


  whatIsTheTypeTitle(type: any) {
    let notificationType = type.notification_type
    return this.notificationsService.whatIsTheTypeTitle(notificationType)
  }

  whatIsTheTypeIcon(type: any) {
    let notificationType = type.notification_type
    return this.notificationsService.whatIsTheTypeIcon(notificationType)
  }

  verifyNotifications() {
    this.isLoading = false;
    let lengthNotifications = this.notificationsData.length
    lengthNotifications == 0 ? this.isShow = false : this.isShow = true;
  }

  checkIfSeen(notification) {
    let isVisualized = notification.seen
      return isVisualized == 0 ? 'pending' : 'visualized'
  }
}
