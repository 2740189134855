<app-generic-modal [isVisible]="true">
    <div class="container flex items-center justify-center flex-col p-7 gap-7">
        <!--content-->
        <div class="flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--body-->
            <div class="px-6 pt-6 ">
                <div class="flex items-center justify-center filter pb-3">
                    <img src="../../../assets/svg/check-outline.svg">
                </div>
                <div class="flex text-center items-center justify-center">
                    <h3 class="text-xl font-bold text-customBlue-default">
                        {{ hasCustomMessage ? messageFeedback : i18n.disciplines_saved_changes }}
                    </h3>
                </div>
            </div>

            <!--footer-->
            <div class="flex items-center justify-center p-6 rounded-b">
                <button class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="closeModal()">
                    {{ i18n.disciplines_modal_confirmation_back }}
                </button>
            </div>
        </div>
    </div>
</app-generic-modal>
