/* eslint-disable @typescript-eslint/ban-types */
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-modal-form-add',
    templateUrl: './modal-form-add.component.html',
    styleUrls: ['./modal-form-add.component.scss']
})
export class ModalFormAddComponent {
    @Input() showModal = false;
    @Input() modalUploadOverlay = false;

    @Input() titleModal = '';
    @Input() subtitleModal = '';

    @Input() toggleModal: Function = () => {};
    @Input() cancelForm: Function = () => {};

    handleToggleModal() {
        this.cancelForm();
    }

    handleCancelForm() {
        this.cancelForm();
    }
}
