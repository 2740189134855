import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface Ball {
  active: boolean;
}

@Component({
  selector: 'app-view-scale',
  templateUrl: './view-scale.component.html',
  styleUrls: ['./view-scale.component.scss'],
})
export class ViewScaleComponent implements OnInit {
  @Input() data: any;
  @Input() viewOnly: boolean;

  @Output() change = new EventEmitter();

  balls: Ball[];
  selectedBalls: Ball[];
  scaleNumbers: number[];

  hoveredBall: number;
  highlightedBall: number;
  selectedBall: number;
  preSelectedBall: number;

  DOMBallTransform: string;

  numberOfBalls: number;

  constructor() {}

  ngOnInit(): void {
    const ballObjectTemplate = { active: false };
    this.numberOfBalls =
      this.data.value.higherBound - this.data.value.lowerBound + 1;
    this.scaleNumbers = [...Array(this.numberOfBalls).keys()].map(
      (i) => i + this.data.value.lowerBound
    );

    this.selectedBalls = [...Array(this.numberOfBalls).keys()].map(
      () => ballObjectTemplate
    ); //generating balls array from higherbound value
    this.balls = JSON.parse(JSON.stringify(this.selectedBalls));

    this.preSelectBall();
  }

  preSelectBall() {
    if (!this.data?.options?.length) return;

    this.preSelectedBall = this.data.options.pop().text;
    const preSelectBallIndex = this.scaleNumbers.indexOf(this.preSelectedBall);

    this.selectedBalls = this.selectedBalls.map((ball, i) => {
      return {
        active: i <= preSelectBallIndex,
      };
    });

    this.balls = JSON.parse(JSON.stringify(this.selectedBalls));
    this.highlightedBall = this.highlightedBall = preSelectBallIndex;
    this.selectDOMStarScale();
  }

  unhoverBalls() {
    if (this.preSelectedBall) return;

    this.balls.forEach(
      (ball, i) => (ball.active = this.selectedBalls[i].active)
    );

    this.highlightedBall = this.selectedBall;
    this.selectDOMStarScale();
  }

  hoverBalls(index) {
    if (this.preSelectedBall) return;

    this.balls.forEach((ball, i) => (ball.active = i <= index));

    this.highlightedBall = this.hoveredBall = index;
    this.selectDOMStarScale();
  }

  selectBall(index) {
    if (this.viewOnly) return;

    this.selectedBalls = this.selectedBalls.map((_, i) => ({
      active: i <= index,
    }));

    this.highlightedBall = this.selectedBall = index;
    this.selectDOMStarScale();

    this.data.user_response = [[this.scaleNumbers[this.highlightedBall]]];
    this.change.emit();
  }

  selectDOMStarScale() {
    const styleTemplate = `--scale: 1.`;

    const proportionalValue =
      ((this.highlightedBall + 1) / this.numberOfBalls) * 10;
    const transformScaleDecimal = (proportionalValue / 2)
      .toString()
      .replace('.', ''); // 5 -> 2.5 -> 25 -> --scale: 1.25

    this.DOMBallTransform = `${styleTemplate}${transformScaleDecimal};`;
  }
}
