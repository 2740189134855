import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CertificatesSharedService } from 'src/app/shared/certificates-shared.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  modalConfirmCertificate$   = this.certificatesService.modalConfirmCertificate$;

  @Input() certificateId: any;
  @Input() i18n: any;

  @Output() confirmedForDeletion = new EventEmitter();

  constructor(
    private certificatesService: CertificatesSharedService
  ) { }

  ngOnInit(): void {
  }

  confirming() {
    this.confirmedForDeletion.emit(this.certificateId)
  }

  closeConfirmCertificate() {
    this.certificatesService.modalCloseConfirmCertificate();
  }
  openConfirmCertificate() {
    this.certificatesService.modalOpenConfirmCertificate();
  }

}
