import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayPanelModule } from 'src/app/components/display-panel/display-panel.module';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ImportModalComponent } from './import-modal/import-modal.component';
import { SelectOptionBoxComponent } from './select-option-box/select-option-box.component';
import { SelectDisciplineBoxComponent } from './select-discipline-box/select-discipline-box.component';
import { ImportClassroomModalComponent } from './import-classroom-modal/import-classroom-modal.component';
import { SelectDisciplineClassroomBoxComponent } from './select-discipline-classroom-box/select-discipline-classroom-box.component';

@NgModule({
  declarations: [
    ImportModalComponent,
    SelectOptionBoxComponent,
    SelectDisciplineBoxComponent,
    ImportClassroomModalComponent,
    SelectDisciplineClassroomBoxComponent,
  ],
  imports: [CommonModule, SharedModule, DisplayPanelModule, GenericModalModule],
  exports: [
    ImportModalComponent,
    SelectOptionBoxComponent,
    SelectDisciplineBoxComponent,
    ImportClassroomModalComponent,
    SelectDisciplineClassroomBoxComponent,
  ],
})
export class CopyAndReplicationModule {}
