<!--body-->
<div>
    <div class="p-4">
        <!-- Standalone GCS Button Upload -->
        <button 
              *ngIf="provider === 'GOOGLE_CLOUD_STORAGE'" 
              [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isUploadLocked || isUploadingFiles}" 
              [disabled]="isUploadLocked || isUploadingFiles"
              class="flex flex-col md:flex-row items-center w-full gap-2 py-2 px-4 rounded-md border border-customBlue-default cursor-pointer justify-center mt-2" 
              type="button" 
            >
            <input 
                *ngIf="!isUploadLocked"
                id="upload-local"
                type="file"
                [accept]="defineTypeFile()"
                hidden
                multiple
                [disabled]="isUploadLocked || isUploadingFiles"
                (change)="fileSelected($event)"
            >
            <label 
                for="upload-local" 
                class="cursor-pointer" 
                [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isUploadLocked || isUploadingFiles}"
            >
                <span class="text-14 md:text-left font-medium text-customBlue-default">
                    {{ i18n.upload_file }}
                </span>
            </label>
        </button >
        <!--FIM Standalone GCS Button Upload -->


        <!-- Google Drive Button Upload -->
        <div class="flex justify-center">
            <button
                #btn_upload_google
                *ngIf="provider === 'GOOGLE_DRIVE'" class="w-full flex justify-center my-4 border border-customBlue-default rounded-md cursor-default"
                [style]="{'max-width': maxWidthDriveBtn}"
                (click)="hasPermissionPicker ? openGoogleDrivePicker() : callAddScopeAuthGoogle()" 
                [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isUploadLocked || isLoading || delivery_allowed}" [disabled]="isUploadLocked || isLoading || delivery_allowed" 
            >
                <div 
                    class="flex items-center h-9 gap-2 cursor-pointer" 
                    [ngClass]="{'cursor-not-allowed': isUploadLocked || isLoading || delivery_allowed}"
                >
                    <div>
                        <span class="iconify w-5 h-5 text-customBlue-default" data-icon="mdi:google-drive"></span>
                    </div>
                    <div class="text-sm font-semibold text-customBlue-default">{{textUploadBtn}}</div>
                </div>
                <div *ngIf="isShowExplanation" (click)="displayPanelType.toggle()" class="relative pl-4" style="top: 8px; cursor: pointer;">
                    <button type="button" >
                        <span class="iconify-inline text-19 text-customGray-default" data-icon="carbon:help"></span>
                    </button>
                    <app-display-panel
                        #displayPanelType
                        [style]="{ position: 'absolute' }"
                        [custom_radius]="'10px'"
                        [hide_arrow]="true"
                        [panel_style]="{ background: '#FEF8E6', width: '20.5rem'}"
                        [newStyle]="{ left: '0', top: '-3.125rem' }">
                        <small class="text-customGray-dark font-normal">
                        {{ textExplanation }}
                        </small>
                    </app-display-panel>
                </div>
            </button>
        </div>
        <!-- FIM Google Drive Button Upload -->

        <!-- MAX files -->
        <div *ngIf="maxFiles > 0 && !hideMaxFileText" class="text-center">
            <p class="text-sm text-customBlue-default my-4">{{i18n.upload_max_files_number}} {{maxFiles}}</p>
        </div>
    </div>

     <!-- Standalone Loading -->
     <div *ngIf="isUploadingFiles" class="py-6 px-6" style="border: 1px solid #D8E5F8; border-radius: 15px;">
        <div class="flex justify-start items-center text-sm font-semibold text-customBlue-default">
          {{ i18n.upload_sending}} {{ countFiles+1 }}{{'/'}}{{ filesReceived.length }}
        </div>
        <ng-container *ngIf="!isProcessingFile">
          <div class="flex justify-center items-center">
            <div class="w-full">
              <div class="relative progress-bar-container bg-customGray-solitudeGray w-full h-7 rounded-md">
                <div class="text-customWhite-default h-full font-bold" style="position: absolute;left: 50%;top: 4px; transform: translateX(-50%);">
                  {{progressBar}}%
                </div>
                <div 
                  class="progress-bar bg-customBlue-default rounded-md" 
                  [style.width]="progressBar + '%'"
                >
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isProcessingFile">
          <div class="flex justify-center items-center">
            <div class="w-full">
              <div class="flex justify-center font-semibold text-customBlue-default mb-4">
                {{ i18n.upload_processing_file }}
              </div>
              <div class="flex justify-center">
                <app-bullets-loader></app-bullets-loader>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="flex items-center mt-2 justify-center gap-2 text-customBlue-default font-medium" >
          <span>{{i18n.upload_wait_until_we_load_yor_file}}</span>
        </div>
      </div>

</div>

<!--FOOTER-->

<!-- Google Drive Loading -->
<div *ngIf="isLoading">
    <div class="flex flex-col items-center justify-center gap-2" >
        <div class="mx-auto">
            <app-bullets-loader></app-bullets-loader>
        </div>
        <span class="text-center text-customGray-dark text-16">{{i18n.upload_wait_until_we_load_yor_file}}</span>
    </div>
</div>
