import {
    animate, AUTO_STYLE, state, style, transition, trigger
} from '@angular/animations';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
    Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren
} from '@angular/core';
import {
    FormArray, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import {
    ClassPlannedWeek, Month, PostPlannedWeek, Week
} from '../../../interfaces/current-year.interface';
import { Skill } from '../../../interfaces/curricular-parameters.interface';
import { PlanningClasses, ResponseModules } from '../../../interfaces/modules.interface';
import { ResponsePlanning } from '../../../interfaces/planning.interface';
import { ContentPlannerService } from '../../../services/content-planner.service';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-current-year',
    templateUrl: './current-year.component.html',
    styleUrls: ['./current-year.component.scss'],
    animations: [
        trigger('collapse', [
            state(
                'true',
                style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
            ),
            state(
                'false',
                style({ height: '0rem', visibility: 'hidden', opacity: 0 })
            ),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ],
})
export class CurrentYearComponent implements OnInit {
    @ViewChildren('multiSelectElement') multiSelectElements: QueryList<ElementRef> | undefined;
    form: FormGroup;
    planningList: ResponsePlanning[] = [];
    modulesList: ResponseModules[] = [];
    monthsList: Month[] = [];
    selectedModule: ResponseModules = {} as ResponseModules;
    classesList: PlanningClasses[] = [];
    skillsList: Skill[] = [];
    isVisibleSuccessPlanningModal = false;
    isVisibleClassesFound = false;
    isVisibleModulesFound = false;
    errorInvalidForm = false;
    loading = false;
    currentLenguage = '';
    i18n: any = {};

    constructor(private sharedService: SharedService, private contentPlannerService: ContentPlannerService, private fb: FormBuilder, private platformModalService: PlatformModalsService) {
        this.createForm();
    }

    ngOnInit(): void {
        this.getTraductions();
        this.getMonths();
        this.getPlanning();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.updateDynamicMaxWidth();
    }

    getTraductions(): void {
        this.i18n = this.sharedService.getTranslationsOf('ContentPlanner');
        const language = localStorage.getItem('currentLanguage');

        if (language) {
            this.currentLenguage = language;
        }
    }

    createForm(): void {
        this.form = this.fb.group({
            planning: ['', Validators.required],
            selected_month: [''],
            months: this.fb.array([]),
        });
    }

    createFormWeeks(weeks: Week[]): FormGroup[] {
        const weeksArray: FormGroup[] = [];

        weeks.forEach((week) => {
            weeksArray.push(this.fb.group({
                slots: this.fb.array([]),
                error: false,
                class_number: [''],
                initial_date: [week.initial_date],
                final_date: [week.final_date],
            }));
        });

        return weeksArray;
    }

    createFormClasses(): FormGroup {
        return this.fb.group({
            class: this.fb.array([]),
            skills: [[]],
        });
    }

    getPlanning(): void {
        this.contentPlannerService.getPlanning().subscribe({
            next: (response) => {
                this.planningList = response.plannings;
            },
            error: () => {
                this.planningList = [];
                this.form.controls.planning.setValue('error');
            }
        });
    }

    getModules(): void {
        this.controlClassesFound(false);
        const planningExternalID = this.form.controls.planning.value;

        if (planningExternalID) {
            this.contentPlannerService.getModules(planningExternalID).subscribe({
                next: (response) => {
                    this.modulesList = response.plannings_modules;
                    this.errorInvalidForm = false;
                    this.controlModulesFound(true);
                },
                error: () => {
                    this.modulesList = [];
                }
            });
        }

        this.resetFormMonths();
    }

    getClasses(selectedModule: ResponseModules): void {
        const planningExternalID = this.form.controls.planning.value;
        const moduleExternalID = selectedModule.external_id;
        this.selectedModule = selectedModule;
        this.classesList = [];

        if (planningExternalID && moduleExternalID) {
            this.contentPlannerService.getModulesDetail(planningExternalID, moduleExternalID).subscribe({
                next: (response) => {
                    this.skillsList = response.skills;

                    response.planned_classes.forEach((planned_class) => {
                        planned_class.planning_classes.forEach((e) => {
                            this.classesList.push(e);
                        });
                    });

                    this.controlModulesFound(false);
                    this.controlClassesFound(true);
                },
                error: () => {
                    this.classesList = [];
                }
            });
        }
    }

    getMonths(): void {
        this.contentPlannerService.getMonths(new Date().getFullYear(), 0).subscribe({
            next: (response) => {
                const year = Object.keys(response)[0];
                this.monthsList = response[year];

                const monthsArray = this.form.get('months') as FormArray;

                this.monthsList.forEach((e) => {
                    monthsArray.push(
                        this.fb.group({
                            month: [e.month],
                            control: false,
                            weeks: this.fb.array(this.createFormWeeks(e.weeks))
                        })
                    );
                });
            },
            error: () => {
                this.monthsList = [];
            }
        });
    }

    onSubmit(): void {
        const { payload, validPayload } = this.buildPayload();
        const planningExternalID = this.form.controls.planning.value;
        const moduleExternalID = this.selectedModule.external_id;

        if (validPayload && planningExternalID && moduleExternalID) {
            this.loading = true;
            this.contentPlannerService.postPlannedWeek(planningExternalID, moduleExternalID, payload).subscribe({
                next: () => {
                    this.controlModalSuccess(true);
                    this.resetFullForm();
                    this.loading = false;
                },
                error: () => {
                    this.loading = false;
                    this.platformModalService.toggle('message', this.i18n.planner_error_create_planned_week, 'close');
                },
            });
        }
    }

    buildPayload(): { payload: PostPlannedWeek[], validPayload: boolean } {
        let validPayload = true;
        this.errorInvalidForm = false;

        const payload: PostPlannedWeek[] = [];

        const monthsArray = this.form.get('months') as FormArray;

        // Acessa todos os meses
        monthsArray.controls.forEach((month) => {
            const weeksArray = month.get('weeks') as FormArray;

            // Acessa todas as semanas
            weeksArray.controls.forEach((week) => {
                const payloadModuleClasses: ClassPlannedWeek[] = [];
                const slotsArray = week.get('slots') as FormArray;

                // Acessa todos os slots
                if (slotsArray.controls.length) {
                    slotsArray.controls.forEach((slot) => {
                        const classesArray = slot.get('class') as FormArray;
                        const skillsArray = slot.get('skills') as FormArray;

                        // Verifica se há aula nesses slots e guarda elas
                        if (classesArray.value.length) {
                            payloadModuleClasses.push({
                                class_detail_id: classesArray.value[0].id,
                                skills: skillsArray.value
                            });

                            week.get('error')?.setValue(false);
                        } else {
                            // Se houver slots mas não houver aula não pode enviar o formulário
                            validPayload = false;
                            week.get('error')?.setValue(true);
                            month.get('control')?.setValue(true);
                        }
                    });

                    // Monta o payload
                    if (validPayload) {
                        payload.push({
                            initial_date: week.value.initial_date,
                            final_date: week.value.final_date,
                            quantity: parseFloat(week.value.class_number) || slotsArray.controls.length,
                            module_classes: payloadModuleClasses,
                        });
                    }
                } else {
                    week.get('error')?.setValue(false);
                }
            });
        });

        // Caso não hover não pode enviar o formulário
        if (!payload.length) {
            this.errorInvalidForm = true;
            validPayload = false;
        }

        return { payload, validPayload };
    }

    resetFormMonths(): void {
        const monthsArray = this.form.get('months') as FormArray;
        this.form.controls.selected_month.setValue('');

        monthsArray.controls.forEach((month) => {
            const fieldControl = month.get('control');

            if (fieldControl) {
                fieldControl.setValue(false);
            }

            const weeksArray = month.get('weeks') as FormArray;

            weeksArray.controls.forEach((week) => {
                const classNumber = week.get('class_number') as FormGroup;
                const slotsArray = week.get('slots') as FormArray;

                slotsArray.clear();
                classNumber.reset();
            });
        });
    }

    resetFullForm(): void {
        this.resetFormMonths();
        this.selectedModule = {} as ResponseModules;
        this.form.controls.planning.setValue('');
        this.controlModulesFound(false);
        this.controlClassesFound(false);
    }

    generateSlot(EstimatedInput: HTMLInputElement, monthIndex: number, weekIndex: number): void {
        if (EstimatedInput) {
            let numericValue = EstimatedInput.value.replace(/[^0-9]/g, '');
            numericValue = numericValue.replace(/^0*(\d+)/, '$1');

            const numberOfClasses = parseInt(numericValue, 10);
            const monthsArray = this.form.get('months') as FormArray;
            const weeksArray = monthsArray.at(monthIndex).get('weeks') as FormArray;
            const classesArray = weeksArray.at(weekIndex).get('slots') as FormArray;

            while (classesArray.length > numberOfClasses) {
                const [deletedValue] = classesArray.at(classesArray.length - 1).value.class;
                // Volta a aula para a listagem quando o usuário deleta um slot preenchido
                if (deletedValue) {
                    this.classesList.push(deletedValue);
                }
                classesArray.removeAt(classesArray.length - 1);
            }

            while (classesArray.length < numberOfClasses) {
                classesArray.push(this.createFormClasses());
            }

            const input = weeksArray.at(weekIndex).get('class_number');

            if (input) {
                input.setValue(numericValue);
            }
        }
    }

    controlClassesFound(value: boolean): void {
        if (typeof value === 'boolean') {
            this.isVisibleClassesFound = value;
        }
    }

    controlModulesFound(value: boolean): void {
        if (typeof value === 'boolean') {
            this.isVisibleModulesFound = value;
        }
    }

    controlModalSuccess(value: boolean): void {
        if (typeof value === 'boolean') {
            this.isVisibleSuccessPlanningModal = value;
        }
    }

    toggleMonths(monthIndex: number): void {
        const monthsArray = this.form.get('months') as FormArray;
        const monthGroup = monthsArray.at(monthIndex) as FormGroup;
        const control = monthGroup.get('control');

        if (control) {
            control.setValue(!control.value);
        }
    }

    selectMonth(): void {
        const selectedMonthId = this.form.controls.selected_month.value;
        const monthsArray = this.form.get('months') as FormArray;

        monthsArray.controls.forEach((month) => {
            const fieldControl = month.get('control');
            const fieldID = month.get('month');

            if (fieldControl && fieldID) {
                if (selectedMonthId === fieldID.value) {
                    fieldControl.setValue(true);
                } else {
                    fieldControl.setValue(false);
                }
            }
        });
    }

    validateMaxItem(drag, drop): boolean {
        if (drag.container === drop) return true;
        return drop.data.length < 1;
    }

    formatDateRange(initialDate: string, finalDate: string): string {
        const initialComponents = initialDate.split('-');
        const finalComponents = finalDate.split('-');

        const initialDay = initialComponents[2];
        const finalDay = finalComponents[2];
        const month = this.getMonthIndex(Number(finalComponents[1]));

        let formattedDateRange: string;

        if (this.currentLenguage === 'en') {
            formattedDateRange = `${month} ${initialDay} to ${finalDay}`;
        } else {
            formattedDateRange = `${initialDay} a ${finalDay} de ${month}`;
        }

        return formattedDateRange;
    }

    getMonthIndex(monthIndex: number): string {
        const months = {
            1: this.i18n.planner_month_jan,
            2: this.i18n.planner_month_feb,
            3: this.i18n.planner_month_mar,
            4: this.i18n.planner_month_apr,
            5: this.i18n.planner_month_may,
            6: this.i18n.planner_month_jun,
            7: this.i18n.planner_month_jul,
            8: this.i18n.planner_month_aug,
            9: this.i18n.planner_month_sep,
            10: this.i18n.planner_month_oct,
            11: this.i18n.planner_month_nov,
            12: this.i18n.planner_month_dec,
        };
        return months[monthIndex];
    }

    getMonthNameTranslated(month: string): string {
        switch (month) {
            case 'January':
                return this.i18n.planner_month_jan;
            case 'February':
                return this.i18n.planner_month_feb;
            case 'March':
                return this.i18n.planner_month_mar;
            case 'April':
                return this.i18n.planner_month_apr;
            case 'May':
                return this.i18n.planner_month_may;
            case 'June':
                return this.i18n.planner_month_jun;
            case 'July':
                return this.i18n.planner_month_jul;
            case 'August':
                return this.i18n.planner_month_aug;
            case 'September':
                return this.i18n.planner_month_sep;
            case 'October':
                return this.i18n.planner_month_oct;
            case 'November':
                return this.i18n.planner_month_nov;
            case 'December':
                return this.i18n.planner_month_dec;
            default:
                break;
        }
        return '';
    }

    // Adjust mult-select
    updateDynamicMaxWidth(): void {
        if (this.multiSelectElements) {
            this.multiSelectElements.forEach((e: ElementRef) => {
                e.nativeElement.style.maxWidth = '139.917px';
            });
        }
    }

    drop(event: CdkDragDrop<PlanningClasses[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }

        setTimeout(() => this.updateDynamicMaxWidth(), 400);
    }
}
