<app-generic-modal [isVisible]="isVisibleModalDecisionModule">
    <div class="p-8 flex flex-col gap-8 container-modal">
      <header class="flex justify-center">
        <span class="iconify text-55 text-customBlue-royal" data-icon="fa:exclamation-circle"></span>
      </header>
     <article class="flex justify-center">
        <p class="font-medium text-customGray-dark text-center sm:w-2/3 text-18">{{ i18n.planner_modal_decision_module }}</p>
     </article>
      <footer class="flex flex-col sm:flex-row justify-center gap-3 leading-4">
        <button type="button" class="w-full sm:w-auto text-customWhite-default bg-customBlue-default bg-transparent py-2.5 sm:py-1.5 px-10 rounded whitespace-nowrap" (click)="closeModalAndNotSave()">
            <p class="flex sm:flex-col items-center justify-center gap-1" [innerHTML]="i18n.planner_continue_and_not_save"></p>
        </button>

        <button type="button" class="w-full sm:w-auto text-customBlue-default border border-customBlue-default bg-transparent py-2.5 sm:py-1.5 px-10 rounded whitespace-nowrap" (click)="closeModalAndSave()">
            <p class="flex sm:flex-col items-center justify-center gap-1" [innerHTML]="i18n.planner_continue_and_save"></p>
        </button>
    </footer>
    </div>
</app-generic-modal>