import {
    AfterViewInit,
    Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChildren
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';

declare const tinymce;

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditorComponent),
            multi: true,
        },
    ],
})
export class EditorComponent implements OnInit, AfterViewInit {
    @ViewChildren('editor') editor;
    @Input() form: FormGroup;
    @Input() formControlName: string;
    @Input() height;
    @Output() eventEmitter = new EventEmitter();
    @Output() editorEmitter = new EventEmitter();

    constructor(
        private sharedService: SharedService
    ) {}

    editorConfig;

    settings: any;

    onChange: any = () => {};
    onTouch: any = () => {};
    selectorTinyMceEditor;

    ngOnInit(): void {
        const currentLanguage = this.sharedService.getSelectedLanguage().replace('-', '_');
        this.settings = {
            height: this.height,
            language: currentLanguage,
            language_url: `/assets/langs/${currentLanguage}.js`,
            resize: true,
            mobile: true,
            paste_data_images: true,
            schema: 'html5',
            entity_encoding: 'raw',
            encoding: 'UTF-8',
            promotion: false,
            plugins: 'lists code emoticons link',
            toolbar: 'undo redo | styles | bold italic | '
                        + 'alignleft aligncenter alignright alignjustify | '
                        + 'forecolor backcolor | '
                        + 'outdent indent | numlist bullist | emoticons link',
            toolbar_mode: 'sliding',
            menubar: 'edit view insert format tools',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
        };
        this.setupEditor();
    }

    ngAfterViewInit(): void {
        this.editorEmitter.emit(this.editor);
    }

    writeValue(value: any): void {
        const currentValue = this.form.controls[this.formControlName].value;

        if (value !== currentValue) {
            this.form.controls[this.formControlName].setValue(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    updateValue(value: any): void {
        this.form.controls[this.formControlName].setValue(value);
        this.onChange(value);
        this.onTouch(value);
    }

    setupEditor(): void {
        this.editorConfig = {
            min_height: this.height, ...this.settings
        };
    }

    async getText(value) {
        const editorValue = await this.sharedService.debounce(value);

        this.writeValue(editorValue);
    }

    generateRandomNumberId() {
        return this.sharedService.generateRandomString(this.sharedService.generateRandomNumber(5, 7));
    }

    emitEvent(_event, _editor): void {
        this.eventEmitter.emit({ event: _event, editor: _editor });
    }
}
