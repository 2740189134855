import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { MessageModalModule } from 'src/app/components/modals/message-modal/message-modal.module';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { ResetPasswordComponent } from './reset-password.component';
import { DisplayPanelModule } from 'src/app/components/display-panel/display-panel.module';


@NgModule({
  declarations: [
    ResetPasswordComponent
  ],
  imports: [
    SharedModule,
    MessageModalModule,
    GenericModalModule,
    DisplayPanelModule
  ]
})
export class ResetPasswordModule { }
