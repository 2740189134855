import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GradesService {
  period: Subject<any> = new BehaviorSubject<any>([]);
  periods$: Subject<any> = new BehaviorSubject<any>([]);
  categories$: Subject<any> = new BehaviorSubject<any>([]);
  isPeriodUpdated$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  grades: Subject<any> = new BehaviorSubject<any>([]);
  isGradesUpdated$: Subject<any> = new BehaviorSubject<any>({});

  constructor(private http: HttpClient) {}

  // Observable Period
  get period$() {
    return this.period.asObservable().pipe(filter((period: any) => !!period));
  }

  get grades$() {
    return this.isGradesUpdated$.asObservable();
  }

  checkForUpdatesOnGrades(periodId?: any) {
    this.isGradesUpdated$.next({ isUpdated: true, periodId: periodId });
  }

  checkForUpdatesOnPeriod() {
    this.isPeriodUpdated$.next(true);
  }

  getPeriodsList(discipline_external_id: any): Observable<any> {
    return this.http.get<any>(
      `${API_Routes.URL}/grade/disciplines/${discipline_external_id}/periods`
    );
  }

  postPeriod(discipline_external_id: any, params: any): Observable<any> {
    return this.http.post<any>(
      `${API_Routes.URL}/grade/disciplines/${discipline_external_id}/periods`,
      params
    );
  }

  patchPeriod(
    discipline_external_id: any,
    grade_period_id: any,
    params: any
  ): Observable<any> {
    return this.http.patch<any>(
      `${API_Routes.URL}/grade/disciplines/${discipline_external_id}/periods/${grade_period_id}`,
      params
    );
  }

  deletePeriod(
    discipline_external_id: any,
    grade_period_id: any
  ): Observable<any> {
    return this.http.delete<any>(
      `${API_Routes.URL}/grade/disciplines/${discipline_external_id}/periods/${grade_period_id}`
    );
  }

  postCategory(grade_period_id: any, params: any): Observable<any> {
    return this.http.post<any>(
      `${API_Routes.URL}/grade/periods/${grade_period_id}/categories`,
      params
    );
  }

  patchCategory(period_id: any, params: any): Observable<any> {
    return this.http.patch<any>(
      `${API_Routes.URL}/grade/periods/${period_id}/categories`,
      params
    );
  }

  deleteCategory(period_id: any, grade_category_id: any): Observable<any> {
    return this.http.delete<any>(
      `${API_Routes.URL}/grade/periods/${period_id}/categories/${grade_category_id}`
    );
  }

  getGradesList(grade_period_id: any): Observable<any> {
    return this.http.get<any>(
      `${API_Routes.URL}/grade/periods/${grade_period_id}`
    );
  }

  patchGrade(coursework_id: any, params: any): Observable<any> {
    return this.http.patch<any>(
      `${API_Routes.URL}/grade/coursework/${coursework_id}/assignment`,
      params
    );
  }
}
