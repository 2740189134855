import { NgModule } from '@angular/core';
import { HomeLayoutComponent } from './home.component';
import { HeaderModule } from 'src/app/components/header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateButtonModule } from 'src/app/components/translate-button/translate-button.module';



@NgModule({
  declarations: [
    HomeLayoutComponent
  ],
  imports: [
    SharedModule,
    HeaderModule,
    TranslateButtonModule,
  ]
})
export class HomeModule { }
