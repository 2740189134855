import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/shared.service';

// import { multi, single } from '../../data';
import { DashboardService } from './dashboard.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    user: any;
    dashData: any = [];
    currentLanguage: any;
    i18n: any = [];

    constructor(
        private dashboardService: DashboardService,
        private sharedService: SharedService,
        private router: Router
    ) {
        /*
    Object.assign(this, { multi });
    Object.assign(this, { single }); */
    }

    ngOnInit(): void {
        this.getUserDashboard();
        this.getDataDashboard();
        this.getTranslations();
    //  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Dashboard'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Disciplines'),
            ...this.sharedService.getTranslationsOf('Upload'),
            ...this.sharedService.getTranslationsOf('Calendar'),
        };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getUpdatedTranslations() {
        this.sharedService.translationsUpdate$.subscribe((translations: any) => {
            this.i18n = translations?.Dashboard;
        });
    }

    getUserDashboard() {
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;
        });
    }

    getDataDashboard() {
        this.dashboardService.loadingTrue();
        this.dashboardService.getDash()
        // No término da requisição chama finalize
            .pipe(
                finalize(() => {
                    this.dashboardService.loadingFalse();
                })
            )
            .subscribe({
                next: (data) => {
                    this.dashData = data;
                },
                error: (err) => {
                    this.dashData = [];
                    console.log('Erro no get do dash', err);
                }
            });
    }

    onSelect(event: any) {
        console.log(event);
    }
}
