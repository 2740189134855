<div class="notification" *ngIf="isLoading == false">
  <div *ngIf="isShow == true" class="flex flex-col gap-3">
    <ng-container  *ngFor="let notification of notificationsData | slice:0:2">
    <div  class="notification-button {{ checkIfSeen(notification) }}" (click)="getNotification(notification?.id)">
      <div class="notification-wrapper">
        <div class="circle-icon p-2">
          <i class="notification-icon__icon {{ whatIsTheTypeIcon(notification) }}"></i>
        </div>
        <p>{{ whatIsTheTypeTitle(notification) }}</p>
      </div>
    </div>
    </ng-container>
  </div>
  <div *ngIf="isShow == true" class="footer" (click)="navigate()">
    <p >{{i18n.notifications_view}}</p>
  </div>
  <p *ngIf="isShow == false" class="text-customGray-default text-center pb-3">{{i18n.notifications_page_empty}}</p>
</div>
