import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-discipline-box',
  templateUrl: './select-discipline-box.component.html',
  styleUrls: ['./select-discipline-box.component.scss']
})
export class SelectDisciplineBoxComponent {
  @Input() discipline: any = []
  @Input() selectedDiscipline: any = []
  @Input() selected: boolean
  @Input() modelForm: any

  @Output() formDisciplineData = new EventEmitter();

  constructor() { }

  toggleSelect() {
    this.selected = !this.selected
  }

  checkDisciplineChange($event, title: string) {
    this.formDisciplineData.emit({event: $event, title: title});
  }
}
