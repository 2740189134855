import 'moment/locale/pt-br';

import {
    animate, AUTO_STYLE, state, style, transition, trigger
} from '@angular/animations';
import {
    Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Contents } from './interfaces/my-tasks.interface';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-my-tasks',
    templateUrl: './my-tasks.component.html',
    styleUrls: ['./my-tasks.component.scss'],
    animations: [
        trigger('collapse', [
            state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, })),
            state('true', style({ height: '0px', visibility: 'hidden', opacity: 0, })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`))
        ]),
        trigger('rotatedState', [
            state('false', style({ transform: 'rotate(0)' })),
            state('true', style({ transform: 'rotate(-180deg)' })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ]

})
export class MyTasksComponent implements OnInit, OnChanges {
    @Input() dashData: any = [];
    @Input() i18n: any = [];
    currentLanguage: any = [];

    tasksData: any = [];

    filteredCompletedTasks: any = [];
    filteredPendingTasks: any = [];

    closedCompletedTasks = false;
    closedPendingTasks = false;

    selectedDisciplineForm: UntypedFormGroup;
    disciplines: any = [];
    status: any [];

    formatedTags: any = [];

    contentFiltered: Contents = {
        completed: [],
        late: [],
        no_deadline: [],
        pending: []
    };
    isDropdownOpen = false;

    checkedList : any[];
    allSelected = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
    ) {
        this.checkedList = [];
    }

    ngOnInit(): void {
        this.getLanguages();
        this.buildSelectedForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.dashData && this.dashData) {
                this.orderAlphabetic(this.dashData.disciplines);
                this.disciplinesFilter();
            }
        }
    }

    orderAlphabetic(disciplines) {
        if (disciplines) {
            disciplines.sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    getSelectedValue(discipline) {
        if (discipline.checked) {
            this.checkedList.push(discipline);
        } else {
            const index = this.checkedList.indexOf(discipline);
            this.checkedList.splice(index, 1);
        }
        this.verifyAllSelectedDisciplines();
        this.disciplinesFilter();
    }

    verifyAllSelectedDisciplines() {
        if (this.checkedList.length === this.dashData.disciplines.length) {
            this.allSelected = true;
            return;
        }
        this.allSelected = false;
    }

    removeFilter(discipline) {
        const disciplineObj = discipline;
        disciplineObj.checked = !disciplineObj.checked;
        this.getSelectedValue(discipline);
    }

    selectAll() {
        this.checkedList = [];
        if (this.allSelected) {
            this.dashData.disciplines.forEach((item) => {
                const obj = item;
                obj.checked = true;
                this.checkedList.push(item);
            });
        } else {
            this.dashData.disciplines.forEach((item) => {
                const obj = item;
                obj.checked = false;
            });
        }
        this.disciplinesFilter();
    }

    getLanguages() {
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    buildSelectedForm() {
        this.selectedDisciplineForm = this.formBuilder.group({
            selectedDiscipline: [''],
            selectedStatus: [''],
        });
    }

    disciplinesFilter() {
        if (this.checkedList.length) {
            Object.keys(this.contentFiltered).forEach((status) => {
                this.contentFiltered[status] = this.dashData.contents[status].filter((content) => this.checkedList.some((checkedDiscipline) => checkedDiscipline.external_id === content.discipline_external_id));
            });
            return;
        }

        if (Object.keys(this.dashData).length) {
            Object.keys(this.contentFiltered).forEach((status) => {
                this.contentFiltered[status] = this.dashData.contents[status];
            });
        }
    }
}
