/* eslint-disable no-restricted-globals */
import {
    Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { Calendar } from 'primeng/calendar';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
    @ViewChild('calendarTime') selectorcalendarEndDate: Calendar;
    @ViewChild('timePickerRef') timePickerRef! : ElementRef;

    @Input() currentLanguage: string | null;
    @Input() position = 'initial';
    @Input() minDateValidation: any;
    @Input() enableCalendar = false;
    @Input() initialDate: Date = new Date();
    @Output() emitEvent = new EventEmitter();

    dateInputMask: any;

    ngOnInit(): void {
        this.createDateMaskInput();
    }

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
        if (!this.timePickerRef.nativeElement.contains(event.target)) {
            this.enableCalendar = false;
        }
    }

    createDateMaskInput() {
        this.dateInputMask = createMask<Date>({
            alias: 'datetime',
            inputFormat: 'HH:MM',
            placeholder: '',
            oncleared: () => {
                this.selectorcalendarEndDate.clear();
            },
        });
    }

    handleEditTime(timeString) {
        // Obter a hora e os minutos do tempo fornecido
        const [hours, minutes] = timeString.split(':').map(Number);

        // Verificar se os valores de hora e minuto são válidos
        if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            this.emitEvent.emit({ calendarDateValue: this.initialDate }); return;
        }

        // Criar um novo objeto de data com a data de hoje
        const today = new Date();

        // Definir as horas e os minutos fornecidos na data de hoje
        today.setHours(hours);
        today.setMinutes(minutes);
        today.setSeconds(0);
        today.setMilliseconds(0);

        this.initialDate = today;

        this.emitEvent.emit({ calendarDateValue: today });
    }

    // recebe evento quando usuário escolhe uma data vindo do Picker Calendar
    fnPickDateToInput(calendarDateValue) {
    // Coloca a data no input vindo do CalendarPicker
        if (calendarDateValue) {
            // const formatedHour = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'HH:mm', 'en-US') : formatDate(calendarDateValue, 'HH:mm', 'pt-BR');
            this.emitEvent.emit({ calendarDateValue });
        }
    }
}
