import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisciplineSettingsGradesService {

  constructor(
    private http: HttpClient
  ) { }

  getGradesSettings(discipline_external_id: any): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/settings/grades`);
  }

  patchGradesSettings(discipline_external_id: any, params: any): Observable<any> {
    return this.http.patch(`${API_Routes.URL}/disciplines/${discipline_external_id}/settings/grades`, params)
  }   
}
