import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MessengerToastBaseService {
  showToast$: Subject<boolean> = new Subject();
  message$: BehaviorSubject<any> = new BehaviorSubject<any>('')
  type$: BehaviorSubject<any> = new BehaviorSubject<any>('')

  constructor() { }

  showToastControl(showToast: boolean) {
    this.showToast$.next(showToast)
  }

  toastMsg(msg: string): void {
    this.message$.next(msg);
  }

  toastType(toastType: string): void {
    this.type$.next(toastType)
  }

  messengerToast(toastType: string, msg: string, showToast: boolean): void {
    this.showToastControl(showToast);
    this.toastMsg(msg);
    this.toastType(toastType);
  }
}
