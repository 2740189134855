<app-submission-overview-box [question_number]="22293" [question_subject]="'Matéria e energia: Transformações e reações químicas'" [question_title]="'O gás nobre utilizado em lâmpadas de flash é o:'">
  <div class="submissions-resume-alternatives">
    <div class="submissions-resume-alternatives__alternative correct">
      <div class="submissions-resume-alternatives__question">
        <div class="submissions-resume-alternatives__question-circle"></div>
        <div class="submissions-resume-alternatives__question-text">
          Radônio
        </div>
      </div>

      <div class="submissions-resume-alternatives__answers">
        8 respostas
      </div>
    </div>

    <div class="submissions-resume-alternatives__alternative">
      <div class="submissions-resume-alternatives__question">
        <div class="submissions-resume-alternatives__question-circle"></div>
        <div class="submissions-resume-alternatives__question-text">
          Criptônio
        </div>
      </div>

      <div class="submissions-resume-alternatives__answers">
        5 respostas
      </div>
    </div>

    <div class="submissions-resume-alternatives__alternative">
      <div class="submissions-resume-alternatives__question">
        <div class="submissions-resume-alternatives__question-circle"></div>
        <div class="submissions-resume-alternatives__question-text">
          Xenôio
        </div>
      </div>

      <div class="submissions-resume-alternatives__answers">
        5 respostas
      </div>
    </div>

    <div class="submissions-resume-alternatives__alternative">
      <div class="submissions-resume-alternatives__question">
        <div class="submissions-resume-alternatives__question-circle"></div>
        <div class="submissions-resume-alternatives__question-text">
          Hélio
        </div>
      </div>

      <div class="submissions-resume-alternatives__answers">
        3 respostas
      </div>
    </div>
  </div>
</app-submission-overview-box>



