<div class="grid">
  <div class="grid__columns border border-customGray-lighterGray">
    <div class="grid__label"></div>

    <div
      class="grid__label text-customGray-dark"
      *ngFor="let column of columns"
    >
      {{ column.label }}
    </div>
  </div>

  <div
    class="grid__rows border-b border-l border-r border-customGray-lighterGray"
    *ngFor="let row of rows; let rowIndex = index"
  >
    <div class="grid__label text text-customGray-dark">
      {{ row.label }}
    </div>

    <div
      class="grid__label"
      *ngFor="let matrixUnit of data.matrix[rowIndex]; let columnIndex = index"
      [class.is-view-only]="viewOnly"
    >
      <app-check-button
        (change)="toggleMatrix(rowIndex, columnIndex, matrixUnit.selected)"
        [active]="matrixUnit.selected"
        [iconSize]="21"
      ></app-check-button>
    </div>
  </div>
</div>

<div class="grid-help" *ngIf="!viewOnly">
  *{{ i18n.question_bank_select_correct_alternative_line }}
</div>
<div class="grid-help" *ngIf="viewOnly">
  *{{ i18n.question_bank_least_one_alternative_line }}
</div>
