import { Component, Input } from '@angular/core';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-resume-data',
  templateUrl: './resume-data.component.html',
  styleUrls: ['./resume-data.component.scss']
})


export class ResumeDataComponent {
  @Input() dashData: any = []
  @Input() i18n: any = []
  defaultImage = "../../../../../assets/svg/loading.svg";
  errorImage = "../../../../../assets/png/resume_default.png"

  
  constructor(
    private dashboardService: DashboardService,
  ) { }

  
  routeToLink(resume_data: any) {
    let sectionExternalId = resume_data.object_properties.section_external_id
    let disciplineId     =  resume_data.object_properties.discipline_external_id
    let contentType     =   resume_data.object_properties.content_type
    let externalId     =    resume_data.object_properties.external_id
    let objectType    =     resume_data.object_type

    switch (objectType) {
      case 'Announcement':
              this.dashboardService
              .toAnnouncements(disciplineId)
          break;
      case 'Content':
              this.dashboardService
              .toTheSectionAndType(disciplineId, externalId, sectionExternalId, contentType);
          break;
    }
  }


 
}
