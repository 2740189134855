import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ResponseSupportMaterial } from './IsupportMaterialsTypes';

@Injectable({
  providedIn: 'root',
})
export class SupportMaterialsService {
  constructor(private http: HttpClient) { }

  extractDisciplineExternalId() {
    return sessionStorage.getItem('discipline_external_id');
  }

  getSupportMaterial(): Observable<ResponseSupportMaterial[]> {
    const disciplineExternalId = this.extractDisciplineExternalId();
    return this.http
      .get<any>(
        `${API_Routes.URL}/support_material/${disciplineExternalId}`,
      );
  }

  deleteSupportMaterial(supportMaterialExternalId: string) {
    const disciplineExternalId = this.extractDisciplineExternalId();
    return this.http
      .delete<any>(
        `${API_Routes.URL}/support_material/${disciplineExternalId}/${supportMaterialExternalId}`,
      );
  }
}
