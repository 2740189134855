import {
    AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { TimeService } from 'src/app/services/time-services.service';
import { SharedService } from 'src/app/shared/shared.service';

import { DisciplineDetailService } from '../../../disciplines-detail/discipline-detail.service';
import { ForumService } from '../../forum.service';
import { ForumSharedService } from '../../shared/forum-shared.service';
import { SubmissionButtonsNavigateForumService } from '../../../content/components/homework-submissions/components/homework-submissions-submissions/components/submission-buttons-navigate-forum/submission-buttons-navigate-forum.service';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';

@Component({
    selector: 'app-forum-comment',
    templateUrl: './forum-comment.component.html',
    styleUrls: ['./forum-comment.component.scss']
})
export class ForumCommentComponent implements OnInit, AfterViewInit {
    // ---- Pagination ----
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;
    paginationParams: any = {};
    activePage = 1;
    per_page = 10;

    @Input() isComponentForum = false;
    @Input() hiddenSocialEvents = false;

    hasntForums = false;
    forum_post: any;
    hasntResults = false;
    forum_external_id: any;

    user: any;
    query = '';
    titlePage = 'Fórum';

    commentForumForm: UntypedFormGroup;

    forum_post_external_id: any;
    isLoading: boolean;
    isForumsLoading: boolean;
    isCommentAreaVisible = '';
    isRepliesOpen = '';
    isEditMode = false;
    isModalEditHistoryOpen = false;
    edit_history: any;
    i18n: any = [];

    number: any = 3;
    filterTerm: string;
    p = 1;

    disciplineDetail: any;

    showCommentArea = false;

    orderChecked = true;
    order = 'DESC';

    filterOpen: false;

    constructor(
        private route: ActivatedRoute,
        private forumService: ForumService,
        private sharedService: SharedService,
        private timeService: TimeService,
        public platModalService: PlatformModalsService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private FormBuilder: UntypedFormBuilder,
        private disciplineDetailService: DisciplineDetailService,
        private forumSharedService: ForumSharedService,
        private subButtonsNavigateForumService: SubmissionButtonsNavigateForumService

    ) { }

    ngOnInit(): void {
        this.isForumsLoading = true;
        this.getTranslations();
        this.getForumIds();
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;
        });

        this.disciplineDetailService.disciplineBasic$
            .subscribe({
                next: (disciplineDetail) => {
                    if (Object(disciplineDetail).length) {
                        this.disciplineDetail = disciplineDetail;
                    }
                }
            });
    }

    onToggle(event:any) {
        this.filterOpen = event;
    }

    ngAfterViewInit(): void {
        this.forumService.forum$.subscribe((forum_post) => {
            this.hasntForums = false;
            this.hasntResults = false;

            if (forum_post.hasOwnProperty('items')) {
                this.getReplyId(forum_post);
            }
            // caso não tenha nenhum fórum, seta variavel true para mostrar
            // mensagem personalizada
            if (this.query.length === 0 && forum_post?.items?.replies.length === 0) {
                this.hasntForums = true;
            }
            // caso pesquisa não traga nenhum resultado, seta variavel true para mostrar
            // mensagem personalizada
            if (this.query.length > 0 && forum_post?.items?.replies.length === 0) {
                this.hasntResults = true;
            }

            this.forum_post = forum_post;

            if (this.forum_post?.items?.replies.length) {
                this.refreshPagination(this.forum_post);
            }
        });

        this.checkIfCanPostForum();

        this.sharedService.modalOpen$.subscribe();
        this.ref.detectChanges();
    }

    checkOrder(event:any) {
        this.orderChecked = !this.orderChecked;
        if (this.orderChecked) {
            this.order = 'DESC';
        } else {
            this.order = 'ASC';
        }
        this.goToPage({ activePage: this.activePage });
        event?.preventDefault();
        event?.stopPropagation();
    }

    checkIfCanPostForum() {
        if (!this.disciplineDetail) {
            this.showCommentArea = true;
            return;
        }

        if (this.disciplineDetail.canPostForum) {
            this.showCommentArea = true;
            return;
        }

        this.showCommentArea = false;
    }

    getTranslations() {
        this.i18n = this.sharedService.getTranslationsOf('Forum');
        this.titlePage = 'Fórum';
    }

    getForumIds() {
        this.forum_post_external_id = sessionStorage.getItem('forum_post_external_id');
        this.forum_external_id = sessionStorage.getItem('forum_external_id');
    }

    getReplyId(forum_post: any) {
        const forumIds: any = [];
        forum_post?.items?.replies.forEach((element:any) => {
            forumIds.push(element.id);
        });
        this.postForumView(forumIds);
        this.isForumsLoading = false;

        setTimeout(() => {
            this.subButtonsNavigateForumService.updateStateElementsForum(true);
        }, 600);
    }

    goToCommentReply(thread_id:any) {
    // console.log('this.forum_external_id ', this.forum_external_id);
    // this.router.navigate([`../../forum/${this.forum_post?.items?.external_id}/reply/${thread_id}`], {relativeTo:this.route});
        this.isRepliesOpen = thread_id;
    }

    isReplyOpen = '';
    replyThread(parent_id:any, isReplyOpen: any) {
        // Se usuário clicar novamente no ícone de responder,
        // isCommentAreaVisible já estará preenchido, então
        // cancelará a resposta e limpará o formulário
        if (this.isCommentAreaVisible === parent_id || this.isCommentAreaVisible !== '') {
            this.isCommentAreaVisible = '';
            this.isReplyOpen = '';
            this.cancelReplyComment();
        } else {
            this.isCommentAreaVisible = parent_id;
            this.isReplyOpen = isReplyOpen + parent_id;
        }
    }

    cancelReplyComment() {
        this.isCommentAreaVisible = '';
        this.isReplyOpen = '';
        this.isEditMode = false;
    }

    closeModalHistory(event:any) {
        this.isModalEditHistoryOpen = event;
    }

    openModalEditHistory(thread_edit_history: any) {
        this.edit_history = thread_edit_history;
        this.isModalEditHistoryOpen = true;
    }

    editForumPost(thread_text:any, thread_id: any) {
        this.commentForumForm.controls.text.setValue(thread_text);
        this.isEditMode = true;
        this.replyThread(thread_id, 'edit');
    }

    receiveIsCommentAreaVisible(isCommentAreaVisible: string) {
        this.isCommentAreaVisible = isCommentAreaVisible;
    }

    receiveIsReplyOpen(isReplyOpen: string) {
        this.isReplyOpen = isReplyOpen;
    }

    receiveIsEditMode(isEditMode) {
        this.isEditMode = isEditMode;
    }

    getTime(date) {
        return this.timeService.getTime(date);
    }

    askForGetForum() {
        this.forumService.checkForUpdatesOnForum();
    }

    postForumView(params: any) {
        const forumIds = {
            forum_posts_ids: params
        };
        /*  this.forumService.postViewForum(forumIds)
    .subscribe((res)=>{
    }) */
    }

    saveComment(threadObject: any) {
        this.sharedService.isLoadingModalOpen();

        let parentId = threadObject?.parent_id;
        // Quando o parent_id for vazio recebe o id do forum_post
        if (parentId == null) {
            parentId = this.forum_post?.items?.id;
        }

        let forumExternalId = this.forum_external_id;
        if (this.isComponentForum) {
            forumExternalId = this.forum_post?.items?.forum_external_id;
        }

        if (this.isEditMode) {
            const params = {
                text: threadObject?.thread_text,
            };
            this.forumService.patchForum(parentId, params)
                .pipe(
                    finalize(() => {
                        this.isEditMode = false;
                        this.sharedService.isLoadingModalClose();
                    })
                )
                .subscribe((res: any) => {
                    const forumPost = this.forumSharedService.getForumThreadById(parentId, this.forum_post);
                    Object.assign(forumPost, res.thread);
                    this.cancelReplyComment();
                });
        } else {
            const params = {
                text: threadObject?.thread_text,
                title: 'TITULO', // remover
                parent_id: parentId, // id do forum post ou null
                root_id: this.forum_post?.items?.id// id da thread ou null
            };

            this.forumService.postForum(forumExternalId, params)
                .pipe(
                    finalize(() => {
                        this.sharedService.isLoadingModalClose();
                    })
                )
                .subscribe({
                    next: (res: any) => {
                        const forumPost = this.forumSharedService.getForumThreadById(parentId, this.forum_post.items);
                        forumPost.replies.push(res);
                        this.forum_post.total_threads += 1;
                        this.cancelReplyComment();
                    },
                    error: (err) => {
                        this.platModalService.toggle('message', err.error.error, 'close');
                    }
                });
        }
    }

    isWaitingResponse = false;
    clickReactionButton(is_reaction_true: boolean, thread_id: any, reaction_type: any) {
        this.isWaitingResponse = true;

        if (reaction_type === 'like') {
            if (is_reaction_true) {
                this.unlikeForumPost(thread_id);
            } else {
                this.likeForumPost(thread_id);
            }
        }

        if (reaction_type === 'follow') {
            if (is_reaction_true) {
                this.unfollowForumPost(thread_id);
            } else {
                this.followForumPost(thread_id);
            }
        }
        this.isWaitingResponse = this.isLoading;
    }

    unfollowForumPost(id: any) {
        this.forumService.postUnfollowForumPost(id)
            .subscribe((res) => {
                this.askForGetForum();
            });
    }

    followForumPost(id: any) {
        this.forumService.postFollowForumPost(id)
            .subscribe((res) => {
                this.askForGetForum();
            });
    }

    unlikeForumPost(id: any) {
        this.forumService.postUnlikeForumPost(id)
            .subscribe((res) => {
                this.askForGetForum();
            });
    }

    likeForumPost(id: any) {
        this.forumService.postLikeForumPost(id)
            .subscribe((res) => {
                this.askForGetForum();
            });
    }

    deleteForum(id:any) {
        this.forumService.checkForDeleteOnForum(id);
    }

    goToPage({ activePage }): void {
        const page = activePage;

        console.log(this.order);

        this.forumService.getForumPost(this.forum_post?.items?.id, page, this.per_page, this.order).subscribe({
            next: (response) => {
                this.activePage = response.page;

                this.hasntForums = false;
                this.hasntResults = false;

                if (response.hasOwnProperty('items')) {
                    this.getReplyId(response);
                }
                // caso não tenha nenhum fórum, seta variavel true para mostrar
                // mensagem personalizada
                if (this.query.length === 0 && response?.items?.replies.length === 0) {
                    this.hasntForums = true;
                }
                // caso pesquisa não traga nenhum resultado, seta variavel true para mostrar
                // mensagem personalizada
                if (this.query.length > 0 && response?.items?.replies.length === 0) {
                    this.hasntResults = true;
                }

                this.forum_post = response;

                this.forumSharedService.insertNewPropertyInObject(this.forum_post.items, 'isThreadOpen', false);
                this.forumService.addForumToObservable(this.forum_post);
            }
        });
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.total_items).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.activePage,
        };

        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }
}
