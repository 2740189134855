import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeFirstHourZero'
})
export class RemoveFirstHourZeroPipe implements PipeTransform {

  transform(value) {
    return value.startsWith("0")
      ? value.substring(1)
      : value
  }

}
