import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-forum-list-skeleton',
    templateUrl: './forum-list-skeleton.component.html',
    styleUrls: ['./forum-list-skeleton.component.scss']
})
export class ForumListSkeletonComponent implements OnInit {
    forums: any = [{}, {}, {}, {}, {}, {}];
    constructor(private sharedService: SharedService) { }

    ngOnInit(): void {
        this.forums.forEach((element, index) => {
            element.lines = [{}, {}, {}, {}];
            element.lines.forEach((line, indexLine) => {
                line[`width_description${indexLine}`] = this.sharedService.generateRandomNumber(85, 100);
            });
        });
    }
}
