import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UseTermsService {
  constructor(private http: HttpClient) { }

  acceptUseTerms(options: any): Observable<any>{
    const headers = {
      'Authorization': "Bearer " + options.token
    }
    return this.http.post<any>(`${API_Routes.URL}/settings/terms/accept/terms_use` ,{}, {headers: headers});
  }

  getUseTermsNoToken(): Observable<any>{
    return this.http.get<any>(`${API_Routes.URL}/terms`);
  }

  getUseTermsWithToken(): Observable<any>{
    return this.http.get<any>(`${API_Routes.URL}/settings/terms`);
  }

  acceptPolicyAndPrivacy(options: any): Observable<any>{
    const headers = {
      'Authorization': "Bearer " + options.token
    }
    return this.http.post<any>(`${API_Routes.URL}/settings/terms/accept/terms_policy` ,{}, {headers: headers});
  }

  acceptedDate(options: any): Observable<any>{
    const headers = {
      'Authorization': "Bearer " + options.token
    }
    return this.http.post<any>(`${API_Routes.URL}/settings/terms/accept/terms_policy` ,{}, {headers: headers});
  }
}
