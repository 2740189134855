<ng-container *ngIf="dashData?.feed?.length > 0">
  <div class="p-4 bg-customWhite-default rounded-lg shadow-lg">
    <h1 class="mb-3 text-xl text-bold text-customGray-dark font-bold">
      {{ i18n.dash_my_feed }}
    </h1>
    <div class="overflow-y-scroll overflow-x-hidden" id="container">
      <div class="mr-4" *ngFor="let feed of dashData?.feed">
        <div class="mb-3">
          <a
              class="flex flex-wrap sm:flex-nowrap items-center justify-between text-12 font-medium mb-4 gap-4"
              role="link"
              (click)="
                routeToLink(
                  feed.object_properties.discipline_external_id,
                  feed.object_type,
                  feed.object_properties
                )
              "
            >
            <div class="flex items-center gap-4">
              <app-user-frame
                [img_src]="feed?.user?.image"
                [user_name]="feed?.user?.name"
              >
              </app-user-frame>
              <div class="w-full" style="max-width: 21.625rem;">
                <h2
                  class="w-full truncate font-bold text-18 overflow-hidden text-customGray-dark"
                >
                  {{ feed?.user?.name }}
                </h2>
                <h2
                  class="feed-title-font font-bold text-14 overflow-hidden text-customBlue-default"
                >
                  {{ feed?.title }}
                </h2>
                <p
                  *ngIf="feed?.text"
                  class="feed-text-font py-1 text-limit break-all"
                  [innerHTML]="feed?.text | safeHTML"
                ></p>
              </div>
            </div>
            <div 
              class="flex justify-end bg-customBlue-dark p-4 rounded-lg"
              *ngIf="feed.object_type == 'Announcement'"
            >
              <div class="flex feed-badge">
                <div
                  class="text-customWhite-default text-center text-16 font-medium"
                >
                {{ i18n.dash_announcement }}
                </div>
                <div
                  class="text-customWhite-default text-center text-22 font-medium"
                >
                  {{feed?.created_at | date:'dd/MM'}}
                </div>
              </div>
            </div>
            <div 
              class="flex justify-end bg-customOrange-default p-4 rounded-lg"
              *ngIf="feed.object_type != 'Announcement'"
            >
              <div class="flex feed-badge">
                <div
                  class="text-customWhite-default text-center text-16 font-medium"
                >
                  {{ i18n.dash_task }}
                </div>
                <div
                  class="text-customWhite-default text-center text-22 font-medium"
                >
                  {{feed?.created_at | date:'dd/MM'}}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div
  class="grid lg:place-content-center h-full"
  *ngIf="dashData?.feed?.length <= 0"
>
  <div
    class="text-26 font-bold flex items-center justify-center text-center text-customGray-default"
  >
    {{ i18n.dash_empty }}
  </div>
</div>
