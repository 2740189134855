<!-- HEAD -->
<div>
    <h1 class="text-customBlue-default font-bold text-2xl">{{titlePage}}</h1>
</div>

<div class="flex items-center gap-4 lg:justify-end justify-between mt-8">
    <div *ngIf="showSearch" class="flex items-center forum-search rounded-full">
        <svg (click)="searchForum()" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="#8F9BB3">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        <input class="p-2 rounded-full w-full outline-none" placeholder="{{placeholderSearch}}" type="text" (keyup.enter)="searchForum()" [(ngModel)]="query">
    </div>
    <div class="flex items-center gap-8">
        <button *ngIf="showFilter" (click)="displayPanel.toggle($event);" class="relative rounded-md"  style="background-color: #FFF;">
            <i class="filter-outline-icon filter-icon" [ngClass]="{'filter-icon-default': !displayPanel.visible,'filter-icon-orange': displayPanel.visible }"></i>
            <app-display-panel #displayPanel
                (sendOpenStatus)="onToggle($event)"
                [style]="{ right: '-11px', top: '35px' }"
                [hasStyle]="true" [arrow_deslocation]="'100%'" [custom_radius]="'10px'">
                <div class="text-left" style="width: max-content;">
                    <div>
                        <h2 class="text-customGray-dark">{{i18n.forum_order_by}}</h2>
                    </div>
                    <!-- <div>
                        <label (click)="checkFilter($event,'unread'); " class="container flex items-center gap-2">
                            <input type="checkbox" [checked]="filterChecked" class="hidden">
                            <span class="check cursor-pointer"></span>
                            <span class="text-customGray-dark text-16">
                                {{i18n.forum_only_not_read_forums}}
                            </span>
                        </label>
                    </div> -->
                    <div>
                        <label (click)="checkOrder($event,'order'); " class="container flex items-center gap-2">
                            <input type="checkbox" [checked]="orderChecked" class="hidden">
                            <span class="check cursor-pointer"></span>
                            <span class="text-customGray-dark text-16">
                                {{i18n.forum_descending}}
                            </span>
                        </label>
                    </div>
                </div>
            </app-display-panel>
        </button>
        <ng-container *appCheckPermissions="'forum'; type:'create'">
            <button *ngIf="user.role_type !== 'student' && showAddModal" (click)="openModal()" class="py-3.5 px-3.5 right-16 rounded-full add shadow-lg" style="border-radius:50%;background-color: #E8532C;">
                <i class="plus-icon"></i>
            </button>
        </ng-container>
    </div>
</div>
<!-- FIM HEAD -->




