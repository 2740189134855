import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { ImportSpreadsheetService } from './import-spreadsheet.service';

interface ImportStructure {
    title: string;
    subtitle: string;
    link: string;
    modeGenerate: string;
}

@Component({
    selector: 'app-import-spreadsheet',
    templateUrl: './import-spreadsheet.component.html',
    styleUrls: ['./import-spreadsheet.component.scss']
})
export class ImportSpreadsheetComponent implements OnInit {
    i18n: any = {};
    currentLanguage: string | null = null;
    isPopoverVisible = false;
    isLoading = false;

    importStructure: ImportStructure = {
        title: '',
        subtitle: '',
        link: '',
        modeGenerate: ''
    };

    importConnection: ImportStructure = {
        title: '',
        subtitle: '',
        link: '',
        modeGenerate: ''
    };

    spreadsheetImported: any;

    messagesConfirm: any;

    constructor(
        public sharedService: SharedService,
        private importSpreadsheetService: ImportSpreadsheetService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.getImportedSpreadsheets();
    }

    getImportedSpreadsheets(): void {
        this.isLoading = true;
        this.importSpreadsheetService
            .getImporteds()
            .subscribe({
                next: ({ data }) => {
                    this.spreadsheetImported = data;
                    this.isLoading = false;
                },
                error: (err) => {
                    this.isLoading = false;
                    console.log('Error', err);
                }
            });

        this.importSpreadsheetService.setFormDataConfigSheet(null);
    }

    getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('ImportSpreadsheet') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
        this.setImportStructure();
        this.setImportConnection();

        this.messagesConfirm = {
            success: this.i18n.import_spreadsheet_generate_succes,
            error: this.i18n.import_spreadsheet_generate_error,
            info: ''
        };
    }

    setImportStructure(): void {
        this.importStructure = {
            title: this.i18n.import_spreadsheet_import_structure,
            subtitle: this.i18n.import_spreadsheet_import_structure_subtitle,
            link: '/import-spreadsheet/select-sheet/structure',
            modeGenerate: 'structure'
        };
    }

    setImportConnection(): void {
        this.importConnection = {
            title: this.i18n.import_spreadsheet_import_connections,
            subtitle: this.i18n.import_spreadsheet_import_connections_subtitle,
            link: '/import-spreadsheet/select-sheet/connections',
            modeGenerate: 'connections'
        };
    }

    togglePopover(): void {
        this.isPopoverVisible = !this.isPopoverVisible;
    }
}
