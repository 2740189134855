import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DisciplineSettingsVisibilityService {
  constructor(private http: HttpClient) {}

  getDisciplineVisibility(external_id: any): Observable<any> {
    return this.http.get(
      `${API_Routes.URL}/disciplines/${external_id}/settings/visibility`
    );
  }

  postDisciplineVisibility(external_id: any, params: any): Observable<any> {
    return this.http.patch(
      `${API_Routes.URL}/disciplines/${external_id}/settings/visibility`,
      params
    );
  }
}
