import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'active-users',
  template: `
  <div class="active-users">
    <div class="w-full p-2.5 text-customBlue-default font-medium">{{title}}</div>
    <div class="rectangle-teacher-container" *ngIf="structureResumeData.length && haveSeries !== 0">
      <div class="rectangle-teacher"><p>{{i18n.dash_act_teacher}}</p></div>
    </div>
      <div class="active-chart-container" *ngIf="structureResumeData.length && haveSeries !== 0">
        <ngx-charts-bar-vertical-2d
          [view]="view"
          [scheme]="colorScheme"
          [results]="structureResumeData"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [barPadding]="barPadding"
          [xAxisTickFormatting]="xTickFormattingFn"
          [yAxisTickFormatting]="yTickFormattingFn"
          style="fill: #A9AEB3;">
          <ng-template #tooltipTemplate let-model="model">
            <pre>{{model.value}}</pre>
          </ng-template>
        </ngx-charts-bar-vertical-2d>
          <div class="rectangle-student" *ngIf="structureResumeData.length && haveSeries !== 0"><p>{{i18n.dash_act_student}}</p></div>
      </div>
      <div class="mt-6" *ngIf="!structureResumeData.length || haveSeries === 0"><app-empty-data></app-empty-data></div>
  </div>
 `,
  styleUrls: ['./adm-styles.scss'],
})
export class ActiveUsersComponent implements OnInit, OnChanges {
  @Input() adminData: any = {};
  @Input() i18n: any
  structureResumeData: any[] = [];
  title: string

  view: any[number] = [280, 190]
  haveSeries: number = 0;
  
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  barPadding = 15
  xTickFormattingFn = this.xAxisTickFormatting.bind(this);
  yTickFormattingFn = this.yAxisTickFormatting.bind(this);
  colorScheme = {
    domain:
    [
        '#D8E5F8', '#B2B8CD',
    ],
  };

  constructor() { }

  ngOnInit(): void {
    this.title = this.i18n.dash_active_users_last_3_months
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.validateSeries();
    this.structureResumeData = this.adminData.active_users || [];
  }

  validateSeries(): void {
    if (Object.keys(this.adminData).length) {
      this.adminData.active_users.forEach((element) => {
        if (element.series?.length) this.haveSeries++
      });
    }
  }

  yAxisTickFormatting(value){
    return this.yNumberTickFormatting(value);
  }

  yNumberTickFormatting(val: any) {
    return parseInt(val);
  }


  xAxisTickFormatting(value){
    return this.xPercentTickFormatting(value);
  }

  xPercentTickFormatting(val: any) {
    return this.whatIsTheMonth(val)
  }

  whatIsTheMonth(month: any) {
    switch (month) {
      case 'January':
                return this.i18n.dash_jan
      case 'February':
                return this.i18n.dash_feb;
      case 'March':
                return this.i18n.dash_mar;
       case 'April':
                 return this.i18n.dash_apr;
       case 'May':
                return this.i18n.dash_may;
      case 'June':
                return this.i18n.dash_june;
      case 'July':
                return this.i18n.dash_jul;
      case 'August':
                return this.i18n.dash_aug;
      case 'September':
                return this.i18n.dash_sept;
      case 'October':
                return this.i18n.dash_oct;
      case 'November':
                return this.i18n.dash_nov;
      case 'December':
                return this.i18n.dash_dec;
      default:
        'Month'
      break;
    }
  }
}
