<div class="display-panel-wrapper" [ngStyle]="newStyle" [class.is-visible]="visible">
  <div class="diamond-wrapper" [ngStyle]="arrow_style">
    <div *ngIf="!hide_arrow" class="display-panel-diamond"></div>
  </div>

  <div class="display-panel" [ngStyle]="panel_style">
    <ng-content></ng-content>
  </div>
</div>

<div class="invisible-overlay" [class.is-visible]="visible" [ngStyle]="z_index" (click)="toggle($event)"></div>
