import { Injectable } from '@angular/core';
import { BehaviorSubject,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpClient,
    ) { }

    getReports(discipline_external_id: any): Observable<any> {
      return this.http.get<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports`);
    }

    getReportBlob(discipline_external_id: any, report_type: any, report_format: any, detail_level: any): Observable<Blob> {
      return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}`, {responseType: 'blob'});
    }

    getReportBlobResources(discipline_external_id: any, report_type: any, report_format: any, detail_level: any): Observable<Blob> {
      return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}`, {responseType: 'blob'});
    }

    getReportUrlResources(discipline_external_id: any, report_type: any, report_format: any, detail_level: any): Observable<any> {
      return this.http.get<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}`);
    }

    getPeriods(discipline_external_id: any): Observable<any> {
      return this.http.get<any>(`${API_Routes.URL}/grade/disciplines/${discipline_external_id}/periods`);
    }

    getReportDetail(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      day: any,
      month: any,
      sort_by: any,
      sort_type: any,
      periods: any,
      form_group: any,
      ): Observable<any> {
      return this.http.get<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&day=${day}&month=${month}&sortBy=${sort_by}&sortType=${sort_type}&periods=${periods}&form_group=${form_group}`, );
    }

    getActStdDetail(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      initial_date: any,
      final_date: any,
      form_group: any,
      ): Observable<any> {
      return this.http.get<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&initial_date=${initial_date}&final_date=${final_date}&form_group=${form_group}`,);
    }

    getReportBlobGrade(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      initial_date: any,
      final_date: any,
      sort_by: any,
      sort_type: any,
      periods: any,
      form_group: any,
      ): Observable<Blob> {
        return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&initial_date=${initial_date}&final_date=${final_date}&sortBy=${sort_by}&sortType=${sort_type}&periods=${periods}&form_group=${form_group}`, {responseType: 'blob'});
      /* return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/sort_by/${sort_by}/sortType/${sort_type}`, {responseType: 'blob'}); */
    }

    getReportUrlBlobGrade(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      initial_date: any,
      final_date: any,
      sort_by: any,
      sort_type: any,
      periods: any,
      form_group: any,
      ): Observable<any> {
        return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&initial_date=${initial_date}&final_date=${final_date}&sortBy=${sort_by}&sortType=${sort_type}&periods=${periods}&form_group=${form_group}`);
    }

    getReportBlobActiveStd(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      initial_date: any,
      final_date: any,
      sort_by: any,
      sort_type: any,
      periods: any,
      form_group: any,
      ): Observable<Blob> {
        return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&initial_date=${initial_date}&final_date=${final_date}&sortBy=${sort_by}&sortType=${sort_type}&periods=${periods}&form_group=${form_group}`, {responseType: 'blob'});
    }

    getReportUrlActiveStd(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      initial_date: any,
      final_date: any,
      sort_by: any,
      sort_type: any,
      periods: any,
      form_group: any,
      ): Observable<any> {
        return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&initial_date=${initial_date}&final_date=${final_date}&sortBy=${sort_by}&sortType=${sort_type}&periods=${periods}&form_group=${form_group}`);
    }

    getReportBlobActivities(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      month: any,
      day: any,
      sort_by: any,
      sort_type: any,
      ): Observable<Blob> {
        return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&month=${month}&day=${day}&sortBy=${sort_by}&sortType=${sort_type}`, {responseType: 'blob'});
      /* return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/sort_by/${sort_by}/sortType/${sort_type}`, {responseType: 'blob'}); */
    }

    getReportUrlActivities(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      month: any,
      day: any,
      sort_by: any,
      sort_type: any,
      ): Observable<any> {
        return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&month=${month}&day=${day}&sortBy=${sort_by}&sortType=${sort_type}`);
    }

    getReportBlobCertificate(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      initial_date: any,
      final_date: any,
      sort_by: any,
      sort_type: any,
      periods: any,
      form_group
      ): Observable<Blob> {
        return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&initial_date=${initial_date}&final_date=${final_date}&sortBy=${sort_by}&sortType=${sort_type}&periods=${periods}&form_group=${form_group}`, {responseType: 'blob'});
      /* return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/sort_by/${sort_by}/sortType/${sort_type}`, {responseType: 'blob'}); */
    }

    getReportUrlCertificate(
      discipline_external_id: any,
      report_type: any,
      report_format: any,
      detail_level: any,
      q: any,
      initial_date: any,
      final_date: any,
      sort_by: any,
      sort_type: any,
      periods: any,
      form_group
      ): Observable<any> {
        return this.http.get(`${API_Routes.URL}/disciplines/${discipline_external_id}/reports/${report_type}/format/${report_format}/detail_level/${detail_level}/?q=${q}&initial_date=${initial_date}&final_date=${final_date}&sortBy=${sort_by}&sortType=${sort_type}&periods=${periods}&form_group=${form_group}`);
    }

}
