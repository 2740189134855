<div class="scale-dropdowns">
  <app-single-choice-dropdown [items]="firstDropdown" (change)="setScale($event, 0)"></app-single-choice-dropdown>
    {{ i18n.question_bank_to }}
  <app-single-choice-dropdown [items]="secondDropdown" (change)="setScale($event, 1)"></app-single-choice-dropdown>
</div>

<div class="scale-labels">
  <div class="label">
    <div class="label__number">{{ dataPayload.payload.value.lowerBound }}</div>

    <div class="label__input">
      <input type="text" [placeholder]="i18n.question_bank_min_mark" [value]="this.dataPayload.payload.value.lowerLabel" spellcheck="false" autocomplete="off" (input)="getData($event, 0)">

      <div class="label_highlight"></div>
    </div>
  </div>

  <div class="label">
    <div class="label__number">{{ dataPayload.payload.value.higherBound }}</div>

    <div class="label__input">
      <input type="text" [placeholder]="i18n.question_bank_max_mark" [value]="this.dataPayload.payload.value.higherLabel" spellcheck="false" autocomplete="off" (input)="getData($event, 1)">

      <div class="label_highlight"></div>
    </div>
  </div>
</div>
