import {Injectable} from '@angular/core';
import { SharedService } from '../shared/shared.service';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthCheckService {

  constructor(
    private sharedService: SharedService,
  ){}

  public checkStatusSignedGoogle(){
    // Verificar como implementar com a nova lib GSI


    // google.load('auth2', ()=> {
    //       // habilita chamadas para a API
    //       let googleAuthTest = google.auth2.getAuthInstance();
    //       console.log('googleAuthTest ', googleAuthTest);
          

    //       // Cria o listener para receber alterações de status de autenticação do Google
    //       googleAuthTest.isSignedIn.listen((isLogged: boolean)=>{
    //         // Se deslogar da Conta Google, desloga do sistema também
    //         if (!isLogged) {
    //         // TO DO -> Implementar modal informando que foi deslogado da conta 
    //         // do Google e para continuar será preciso logar novamente
    //           this.sharedService.logOut();
    //         }
    //       })
          
    //   });
  }  
  


  
}
