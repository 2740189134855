import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/pages/login/login.service';
import { WindowRef } from 'src/app/shared/window-ref';

@Component({
  selector: 'app-user-way',
  templateUrl: './user-way.component.html',
  styleUrls: ['./user-way.component.scss']
})
export class UserWayComponent implements OnInit, AfterContentInit {

  subscription: Subscription[] = [];
  hasUserWayActivated: boolean = false;
  constructor(
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.getPlatformAdvancedSettings();
  }
  
  ngAfterContentInit(): void {
    this.insertUserWayPlugin();
    // esse subscribe abaixo é para quando estiver iniciando o sistema
    // ele notifica quando é inserido as customizações da plataforma
    let subscription$ = this.loginService.update_localstorage.subscribe({
      next:(hasUserWayActivated)=>{
        if (hasUserWayActivated) {
          this.getPlatformAdvancedSettings();
        }
      }
    });
  
    this.subscription.push(subscription$);
  }

  getPlatformAdvancedSettings(){
    if (localStorage.getItem("advanced") === null) {
      return;
    }
    const advanced = JSON.parse(localStorage.getItem('advanced') || '');
    
    if (advanced !== null && advanced !== '' && advanced !== undefined) {
      this.hasUserWayActivated = advanced.userway_enabled;
    }
  }

  insertUserWayPlugin(){
    let userway = document.createElement('script');
    userway.src = 'https://cdn.userway.org/widget.js';
    document.body.appendChild(userway);

    let _userway_config =`let _userway_config = {
      /* uncomment the following line to override default position*/
      position: '2',
      /* uncomment the following line to override default size (values: small, large)*/
      size: 'small',
      /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
      language: 'pt-BR',
      /* uncomment the following line to override color set via widget (e.g., #053f67)*/
      /* color: '#053f67', */
      /* uncomment the following line to override type set via widget(1=person, 2=chair, 3=eye)*/
      /* type: '1', */
      /* uncomment the following line to override support on mobile devices*/
      /* mobile: true, */
      account: 'gNzOxmefdO'
    };`

    let item = document.createElement('script');
    item.appendChild(document.createTextNode(_userway_config));
    document.body.appendChild(item);
  }

}
