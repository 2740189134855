<div #randomElement class="wrapper">
  <div class="dropdowns">

    <app-custom-dropdown [title]="i18n.question_bank_select" [awaysOpen]="true" style="pointer-events: none;">
      <div *ngFor="let alternative of alternatives; let index = index" style="pointer-events: auto;">
        <div class="inside-dropdown">
          <div class="label">
            <div class="label__number">{{ +index + 1 }}°</div>

            <div class="label__input">
              <input type="text" [placeholder]="i18n.question_bank_option" [value]="alternative.text"  spellcheck="false" autocomplete="off" (input)="input($event, index)">

              <div class="label_highlight"></div>
            </div>
          </div>

          <div class="buttons">
            <div class="btn right-alt" [class.is-active]="alternative.correct" (click)="setCorrectAlt(index)">{{ i18n.question_bank_correct_answer }}</div>

            <div class="btn delete-alt" (click)="delete($event, index)" *ngIf="alternatives.length > 1" [title]="i18n.question_bank_delete">
              <i class="trash-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </app-custom-dropdown>
  </div>

  <app-plus-button class="add-question-btn" [label]="i18n.question_bank_add_alternative" (click)="add(randomElement)" [disabled]="this.alternatives.length >= 30"></app-plus-button>
</div>

