<section>
    <!-- HEAD -->
    <div class="flex justify-between ">
        <h1 class="text-customBlue-default font-bold text-22">{{i18n.grades_grades}}</h1>
        <div (click)="displayPanel.toggle();" class="relative flex gap-2 items-center cursor-pointer">
            <i class="gear-fill-icon gear-icon"></i>
            <span class="text-customGray-dark">{{i18n.grades_settings}}</span>
            <app-display-panel #displayPanel
                [newStyle]="{'top': '40px', 'left': '50%', 'transform': 'translateX(-50%)', 'box-shadow': '0px 4px 15px rgba(0, 0, 0, 0.15)','border-radius': '15px'}"
                [arrow_style]="{'left':'50%', 'transform':'translateX(-50%)'}"
                [custom_radius]="'10px'">
                <div class="grid">
                    <button class="flex items-center gap-2" (click)="openModalPeriod();">
                        <div>
                            <span class="iconify w-6 h-6 text-customGray-dark" data-icon="circum:calendar"></span>
                        </div>
                        <span class="text-customGray-dark">{{i18n.grades_add_period}}</span>
                    </button>
                    <!-- <button class="flex items-center gap-2" (click)="displayPanel.toggle(); ">
                        <i class="grades-settings-icon calendar-icon w-5 h-5"></i>
                        <span class="text-customGray-dark">Configurações de notas</span>
                    </button> -->
                </div>
            </app-display-panel>
        </div>
    </div>

    <ng-container *ngIf="!isLoading">

        <div class=" lg:grid lg:grid-cols-3 flex flex-col justify-center items-center gap-4 mt-16" >
            <div (click)="gotToOverview()" *ngIf="periods?.length > 0" class="w-full flex items-center gap-6 p-4 bg-customWhite-default rounded-lg cursor-pointer">
                <div class="bg-opacity-periods rounded-full p-3">
                    <i class="periods-with-config-icon w-12 h-12"></i>
                </div>
                <div class="flex flex-col ml-5">
                    <span class="text-customBlue-default text-22 font-bold">{{i18n.grades_overview}}</span>
                    <span class="text-customGray-dark text-16">{{i18n.grades_all_periods}}</span>
                </div>
            </div> 
            <div *ngFor="let period of periods; let i = index" class="w-full relative flex items-center gap-6 p-4 bg-customWhite-default rounded-lg cursor-pointer">
                <div (click)="goToDetails(period?.id)" class="bg-opacity-periods rounded-full p-3 cursor-pointer">
                    <i class="periods-icon w-12 h-12"></i>
                </div>
                <div (click)="goToDetails(period?.id)" class="flex flex-col ml-5 ">
                    <span class="text-customBlue-default text-22 font-bold">{{period.name}}</span>
                    <span class="text-customGray-dark text-16 leading-tight">{{period.start_date | date}} {{i18n.grades_date_to}} {{period.end_date | date}}</span>
                </div>
                <div class="absolute right-0 top-0 m-4">
                    <button (click)="displayPanel.toggle()">
                        <i class="dots-three-vertical-icon dots-three-vertical"></i>
                    </button>

                    <app-display-panel #displayPanel
                        [newStyle]="{'top': '40px', 'left': '50%', 'transform': 'translateX(-50%)', 'box-shadow': '0px 4px 15px rgba(0, 0, 0, 0.15)','border-radius': '15px'}"
                        [arrow_style]="{'left':'50%', 'transform':'translateX(-50%)'}"
                        [custom_radius]="'10px'">
                        <div style="width: 130px;">
                            <button
                                (click)="displayPanel.toggle();editPeriod(period?.id)">
                                <span class="text-customGray-dark">{{i18n.grades_edit_period}}</span>
                            </button>
                            <button
                                (click)="displayPanel.toggle(); openModalCategories(period)">
                                <span class="text-customGray-dark">{{i18n.grades_edit_categories}}</span>
                            </button>
                            <button
                                (click)="displayPanel.toggle(); deletePeriod(period?.id)">
                                <span class="text-customGray-dark">{{i18n.grades_remove_period}}</span>
                            </button>
                        </div>
                    </app-display-panel>
                </div>
            </div>
                

            <div (click)="openModalPeriod()" *ngIf="periods?.length == 0" class="flex items-center gap-6 p-4 bg-customWhite-default rounded-lg cursor-pointer">
                <div class="bg-opacity-periods rounded-full p-3">
                    <i class="periods-with-config-icon w-12 h-12"></i>
                </div>
                <div class="flex flex-col ml-5">
                    <span class="text-customBlue-default text-22 font-bold">{{i18n.grades_create_period}}</span>
                    <span class="text-customGray-dark text-14">{{i18n.grades_create_period_to_start_using_grades_module}}</span>
                </div>
            </div>
        </div>

    </ng-container> 

    <ng-container *ngIf="isLoading">
        <app-grades-periods-list-skeleton></app-grades-periods-list-skeleton>
    </ng-container>


</section>

<app-grades-periods-modal 
    [discipline_external_id]="discipline_external_id" 
    [isModalOpen]="isModalOpen" 
    [controlButtonPeriodText]="controlButtonPeriodText"
    [period_id]="period_id" 
    [editMode]="editMode"
    (sendIsModalOpen)="getStatusModal($event)"
    (sendNewPeriod)="getNewPeriod($event)">
</app-grades-periods-modal>

<app-grades-categories-modal 
    [controlButtonCategory]="true"
    [discipline_external_id]="discipline_external_id" 
    [isModalOpen]="isModalCategoriesOpen" 
    (sendIsModalOpen)="getStatusCategoriesModal($event)">
</app-grades-categories-modal>