import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/pages/login/login.service';
import { CountdownComponent } from 'ngx-countdown';


@Component({
  selector: 'app-countdown',
  template: `<countdown #countdown (event)="handleEvent($event)" [config]="{ leftTime: 300, format: 'mm:ss', notify: 300}"></countdown>`,
  styleUrls: ['./countdown.component.scss']
})
export class CountdownTemplateComponent implements OnInit, OnDestroy {

  destroyRestartCountdownValidatonCode$: any;
  @ViewChild('countdown') counter: CountdownComponent;

  constructor(
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.destroyRestartCountdownValidatonCode$ = this.loginService.restartCountdownValidatonCode$
    .subscribe({
      next: (response)=>{
        if (response === true) {
         this.counter.restart();
        }
      }
    })
  }

  handleEvent(event){

    if (event.hasOwnProperty('action')) {
      if (event.action == 'done') {
       this.loginService.enableSendValidationCodeButton$.next(true);
      }
    }

    if (event.hasOwnProperty('action')) {
      if (event.action == 'start') {
       this.loginService.enableSendValidationCodeButton$.next(false);
      }
    }
    
  }

  ngOnDestroy() {
    if (this.destroyRestartCountdownValidatonCode$) this.destroyRestartCountdownValidatonCode$.unsubscribe();
  }

}
