import { Directive, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[appCustomPercentAverage]'
})
export class CustomPercentAverageDirective implements OnInit {

	@Output() onCurrencyEvent = new EventEmitter();

	constructor(public ngControl: NgControl) { }

	ngOnInit() {
		//this.onInputChange
	}


	@HostListener('ngModelChange', ['$event'])
	onModelChange(event) {
		this.onInputChange(event, false);
	}

	@HostListener('keydown.backspace', ['$event'])
	keydownBackspace(event) {
		this.onInputChange(event.target.value, true);
	}

	onInputChange(event: any, backspace: any) {
		let newVal;
		let firstDigit;

		if (event == null) {
			return
		} else {
			newVal = event.toString().replace(/[^\d.,]/g, '');
			firstDigit = newVal.substring(0, 1);

			if (backspace && newVal.length <= 6) {
				newVal = newVal.substring(0, newVal.length - 1);
			}

			if (newVal.length >= 6 && firstDigit > 1) {
				newVal = ''
				newVal = '100'
			}

      if (newVal.toString().replace('%', '') > 100) {
				newVal = ''
				newVal = '100'
			}

			if (newVal.length == 0) {
				newVal = '';
			}

			this.ngControl.valueAccessor?.writeValue(newVal + '%');
			this.onCurrencyEvent.emit(newVal);
		}
	}
}
