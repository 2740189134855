import {
    AfterContentInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { ReadMode } from 'ngx-file-helpers';
import { Subscription, take } from 'rxjs';
import { UploadService } from 'src/app/components/upload/upload.service';
import { ContentSharedService } from 'src/app/pages/disciplines/components/content/content-shared.service';
import { LoginService } from 'src/app/pages/login/login.service';
import { GoogleDrivePickerService } from 'src/app/services/google-drive-picker-service.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-upload-task-delivery',
    templateUrl: './upload-task-delivery.component.html',
    styleUrls: ['./upload-task-delivery.component.scss']
})
export class UploadTaskDeliveryComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
    // inputs para configuração de visualização do componente upload
    @Input() maxFiles: number;// Número máximo de arquivos que o User pode fazer upload
    @Input() multiSelect = false;// Opção para User selecionar um arquivo por vez ou vários
    @Input() sharingStatus = false;// Opção para ser compartilhado de forma pública
    @Input() fileType: string;// Tipo do arquivo que será mostrado, se vazio busca todos
    @Input() files: any = [];// arquivos que vem do componente pai
    @Input() syncWithDrive = true;// arquivos que vem do componente pai

    @Input() textExplanation = '';
    @Input() showRemoveBtn = true;
    @Input() showReplaceBtn = true;
    @Input() showOpenBtn = true;
    @Input() showDate = true;
    @Input() showSendTitle = true;
    @Input() textUploadBtn = '';
    @Input() isShowUploadTab = true;
    @Input() isShowExplanation = false;
    @Input() delivery_allowed: boolean;
    @Input() statusDelivery: string;
    @Input() isStateSended: boolean;
    @Input() remainingAttempts: number;

    @Input() hideMaxFileText = false;// Opção para esconder o texto de máximo de arquivos

    @Input() content: any;

    // outputs dos arquivos para componente pai
    @Output() filesEvent = new EventEmitter<any[]>();// Emissor dos arquivos que foram feito upload para componente pai
    @Output() onCloseEvent = new EventEmitter<any[]>();
    @Output() loadedFile = new EventEmitter<boolean>();

    // consertar depois pois modais de erro estão ficando sobrepostos
    isVisible = true;

    // Variables
    googleData: any = [];
    user;
    token;
    provider: any;
    public readMode = ReadMode.DataURL;
    public picked: File;
    public status: string;
    uploadedFiles: any[] = [];
    dateFile = new Date();
    isLoaded = false;
    isPermissionDone: any;
    displayBasic = false;
    textError: string;
    isUploadLocked = false;
    select:string;
    date: Date;
    currentLanguage: any;
    isLoading = false;
    hasPermissionPicker = false;

    formData = new FormData();

    i18n: any;

    subscription: Subscription[] = [];

    constructor(
        private sharedService: SharedService,
        private googleDrivePickerService: GoogleDrivePickerService,
        private uploadService: UploadService,
        public platModalService: PlatformModalsService,
        private element: ElementRef,
        private route: ActivatedRoute,
        private contentSharedService: ContentSharedService,
        private localStorageService: LocalStorageService,
        private loginService: LoginService,
    ) {}

    ngOnInit(): void {
        this.getTranslations();
        this.getProvider();
        this.getUser();
        this.getSyncWithDriveValue();
        this.localStorageService.checkCookiesEnabled();
        this.checkPickerTokenValidation();
        this.responseSubscriptionGoogleData();
    }

    ngAfterContentInit(): void {
        this.emitRemoveFiles();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('changes ', changes);

        if (changes) {
            this.files == null ? this.files = [] : this.files;
            if (this.files) {
                this.files.forEach((file:any) => {
                    file.submitted_at = file.hasOwnProperty('created_at') ? file.created_at : file.submitted_at;
                });
                this.checkMaxFiles(this.files);
            }
            if (this.statusDelivery) {
                console.log('statusDelivery ', this.statusDelivery);
            }
        }
    }

    getProvider() {
        this.provider = localStorage.getItem('provider') || null;

        if (this.provider === null) {
            this.getWhoami();
        }
    }

    getWhoami() {
        this.loginService.whoami().subscribe({
            next: (response) => {
                this.provider = response?.storage?.provider;
                localStorage.setItem('provider', response?.storage?.provider);
            },
            error: (err) => {
                this.platModalService.toggle('message', err.error.error, 'close');
            }
        });
    }

    getSyncWithDriveValue() {
        const subscription = this.contentSharedService.syncWithDrive$.subscribe({
            next: (value) => {
                console.log('value ', value);
                this.syncWithDrive = value;
            }
        });

        this.subscription.push(subscription);
    }

    interval;
    checkPickerTokenValidation() {
        let token: string | null = null;
        token = localStorage.getItem('googleAuthTokenPicker') || null;
        this.tokenInfoGoogle(token);

        this.interval = setInterval(() => {
            token = localStorage.getItem('googleAuthTokenPicker') || null;
            if (token !== null) {
                this.tokenInfoGoogle(token);
            }
        }, 300000);
    }

    tokenInfoGoogle(token) {
        this.loginService.tokenInfoGoogle(token).subscribe({
            next: (tokenInfo) => {
                if (!tokenInfo.scope.includes('auth/drive')) {
                    this.hasPermissionPicker = false;
                    return;
                }

                this.hasPermissionPicker = true;
            },
            error: (err) => {
                this.hasPermissionPicker = false;
            }
        });
    }

    defineTypeFile() {
        return 'audio/*,video/*,image/*,application/pdf';
    }

    closeModal() {
        this.onCloseEvent.emit(this.files);
        this.sharedService.modalClose();
        this.sharedService.removeFiles$.next({ files: this.files, isUpdated: false });
    }

    getTranslations() {
        this.i18n = this.sharedService.getTranslationsOf('Upload');
        this.currentLanguage = localStorage.getItem('currentLanguage' || '');

        if (this.provider === 'GOOGLE_DRIVE') {
            this.textUploadBtn === '' ? this.i18n.upload_from_google_drive : this.textUploadBtn;
        } else {
            this.textUploadBtn === '' ? this.i18n.upload_upload_it : this.textUploadBtn;
        }
    }

    // Functions
    getUser() {
        const data: any = localStorage.getItem('token');
        this.token = data;
        this.user = jwt_decode(data);
    }

    offset = 0;
    chunkSize = 1000000;
    countLoop: any = 1;

    countFiles = 0;
    totalFiles = 0;
    filesReceived: any = [];
    progressBar = 0;
    progressValue;
    progress;
    isUploadingFiles = false;

    calculateProgressBar(part, totalPart) {
        this.progressValue = this.element.nativeElement.querySelector('.Progressbar__value');
        this.progress = this.element.nativeElement.querySelector('progress');

        this.progressBar = Math.ceil(part * 100 / totalPart);
        console.log('this.progressBar ', this.progressBar);
        console.log('part ', part, 'totalPart ', totalPart);
        // console.log('fileSize ', fileSize);
        this.setValue(this.progressBar);
    }

    setValue(value) {
        this.progressValue.style.width = `${value}%`;
        this.progress.value = value;
    }

    fileSelected(event) {
        this.isUploadingFiles = true;
        this.filesReceived = Array.from(event.target.files);
        this.totalFiles = this.filesReceived.length;
        if (this.totalFiles > this.maxFiles) {
            this.sharedService.modalClose();
            this.platModalService.toggle('message', this.i18n.upload_selected_files_number_exceeds, 'close');
            return;
        }
        this.fileSetup(this.filesReceived[this.countFiles]);
    }

    fileSetup(file) {
        // Tamanho do arquivo maior que 20MB
        if (file.size > 20000000) {
            this.chunkSize = Math.ceil(file.size / 150);
        }
        if (this.countFiles < this.totalFiles) {
            this.sendChunkPart(this.offset, file);
        }
    }

    sendChunkPart(offset, file) {
        const totalPart: any = Math.ceil(file.size / this.chunkSize) == 0 ? 1 : Math.ceil(file.size / this.chunkSize);

        if (offset < file.size) {
            const chunk = file.slice(offset, offset + this.chunkSize);
            this.formData = new FormData();
            this.formData.append('file[]', chunk, file.name);
            this.formData.append('mime', file.type);
            this.formData.append('part', this.countLoop.toString());
            this.formData.append('totalPart', totalPart.toString());
            this.postFiles(this.formData, file);
            this.calculateProgressBar(this.countLoop, totalPart);
        }
    }

    postFiles(files: any, file) {
        this.uploadService.postFile(files).subscribe({
            next: (response:any) => {
                this.offset += this.chunkSize;

                this.countLoop++;

                if (this.offset < file.size) {
                    this.sendChunkPart(this.offset, file);
                } else {
                    response.forEach((item:any) => {
                        this.files.push(item);
                    });
                    this.countFiles++;

                    if (this.countFiles < this.totalFiles) {
                        this.formData = new FormData();
                        this.offset = 0;
                        this.countLoop = 1;
                        this.fileSetup(this.filesReceived[this.countFiles]);
                    } else {
                        this.resetPostFileVariables();
                        this.checkMaxFiles(this.files);
                        this.isUploadingFiles = false;
                        this.filesEvent.emit(this.files);
                    }
                }
            },
            error: (err) => {
                if (err.status === 500 || err.status === 413) {
                    setTimeout(() => {
                        console.log('files ', files);
                        console.log('file ', file);

                        this.postFiles(files, file);
                    }, 10000);
                }
            }
        });
    }

    resetPostFileVariables() {
        this.formData = new FormData();
        this.offset = 0;
        this.countLoop = 1;
        this.countFiles = 0;
        this.totalFiles = 0;
    }

    replaceFileGCS(event, file) {
        this.isUploadingFiles = true;
        const fileToReplace = this.files.indexOf(file);

        if (fileToReplace > -1) {
            this.files.splice(fileToReplace, 1);
            this.fileSelected(event);
        }
    }

    showCookieFail() {
        this.isVisible = false;
        this.platModalService.toggle('message', this.i18n.upload_content_help_cookies_blocked, 'close');
    }

    setFileSelection(): string {
        return this.multiSelect ? 'multiple' : 'single';
    }

    // Requests Provider GOOGLE_DRIVE when not has permission to Drive
    callAddScopeAuthGoogle() {
        this.sharedService.checkIfApiKeyExists();
        if (!localStorage.getItem('apiKey')) {
            return;
        }
        this.googleDrivePickerService.callAddScopeAuthGoogle((tokenPicker) => {
            console.log('tokenPicker ', tokenPicker);
            this.isLoading = true;

            if (tokenPicker) {
                this.hasPermissionPicker = true;

                this.googleDrivePickerService.setPicker(this.isShowUploadTab, this.setFileSelection(), this.fileType, tokenPicker, this.reponseGoogleData);
            }
        });
    }

    // Requests Provider GOOGLE_DRIVE when has permission to Drive
    openGoogleDrivePicker() {
        this.sharedService.checkIfApiKeyExists();
        if (!localStorage.getItem('apiKey')) {
            return;
        }
        this.isLoading = true;

        this.googleDrivePickerService.open(this.isShowUploadTab, this.setFileSelection(), this.fileType, this.reponseGoogleData);
    }

    reponseGoogleData = (data) => {
        console.log('data ', data);

        if (data.action === 'picked') {
            this.googleData = data.docs;
            this.callInsertPermission(this.googleData);
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
        }

        if (data.action === 'cancel') {
            this.isLoading = false;
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
        }
    };

    responseSubscriptionGoogleData() {
        const subscription = this.googleDrivePickerService.callbackReponse$.subscribe({
            next: (response) => {
                console.log('response ', response);

                if (!this.sharedService.isCookiesEnabled()) {
                    this.showCookieFail();
                }

                if (response.hasOwnProperty('error')) {
                    if (response.details == 'Cookies are not enabled in current environment.') {
                        this.isVisible = false;
                        this.platModalService.getModalState('message').pipe(take(1)).subscribe({
                            next: (state) => {
                                if (state == false) {
                                    this.platModalService.toggle('message', this.i18n.upload_content_help_cookies_blocked, 'close');
                                }
                            }
                        });
                    }

                    if (response.error.errCode == 400) {
                        if (response.error.error == 'invalid_grant') {
                            // this.sharedService.modalClose();
                            this.isVisible = false;

                            this.platModalService.toggle('message', this.i18n.upload_content_google_invalid_grant, {
                                finally: () => {
                                    this.platModalService.close('message');
                                    this.sharedService.modalClose();
                                    // eslint-disable-next-line @typescript-eslint/dot-notation
                                    const redrectTo = this.route.snapshot['_routerState'].url;

                                    this.sharedService.logOut(redrectTo);
                                    this.googleDrivePickerService.callbackReponse$.next({});
                                }
                            });
                        }
                    }
                }
            }
        });

        this.subscription.push(subscription);
    }

    countFilesInsertedPermission = 0;
    async callInsertPermission(files: any) {
        const el: any = document.querySelector('app-content-task-upload');
        if (el !== null) {
            el.click();
        }
        console.log('files callInsertPermission', files);
        console.log('this.countFilesInsertedPermission ', this.countFilesInsertedPermission);

        const files_size = files.length;
        await this.googleDrivePickerService.insertPermission(files[this.countFilesInsertedPermission].id, (response) => {
            console.log('response', response);
            if (response.status == 200) {
                if (this.countFilesInsertedPermission < files_size - 1) {
                    this.countFilesInsertedPermission++;
                    this.callInsertPermission(files);
                } else {
                    this.uploadFile(files);
                    this.countFilesInsertedPermission = 0;
                }
            } else {
                let error = '';
                switch (response.status) {
                    case 401:
                        error = 'upload_error_unauthorized';
                        break;
                    case 403:
                        error = 'uplaod_error_permission';
                        break;
                    case 404:
                        error = 'uplaod_error_file_not_found';
                        break;
                    default:
                        error = 'upload_error';
                        break;
                }

                this.isLoading = false;
                this.closeModal();
                if (this.i18n && this.i18n[error]) {
                    this.platModalService.toggle('message', this.i18n[error], 'close');
                }

                // Send errors to Error reporting
                let message_errors = '';
                message_errors += `user: ${JSON.stringify(this.token)} `;
                if (response.hasOwnProperty('result')) {
                    if (response.result.hasOwnProperty('error')) {
                        if (response.result.error.hasOwnProperty('errors')) {
                            if (response.result.error.errors.length) {
                                response.result.error.errors.forEach((element) => {
                                    element.file_id = files[this.countFilesInsertedPermission].id;
                                    message_errors += `${JSON.stringify(element)} `;
                                });
                            }
                        }
                        message_errors += JSON.stringify(response.result.error);
                    }
                    message_errors += JSON.stringify(response.result);
                }
                throw new Error(message_errors);
            }
        });
    }

    replaceGoogleData = (data) => {
        const fileToReplace = this.files.indexOf(data);

        if (fileToReplace > -1) {
            this.files.splice(fileToReplace, 1);
            this.callInsertPermission(data);
        }
    };

    replaceFile(file:any) {
        this.isLoading = true;
        this.googleDrivePickerService.open(this.isShowUploadTab, 'single', this.fileType, this.replaceGoogleData);
    }

    checkMaxFiles(files: any) {
        if (files !== null) {
            if (files.length >= this.maxFiles) {
                this.isUploadLocked = true;
            } else {
                this.isUploadLocked = false;
            }

            if (files.length > 0) {
                this.isLoaded = true;
                // this.loadedFile.emit(this.isLoaded)
                this.sharedService.isLoadedUploadTrue();
            } else {
                this.isLoaded = false;
                // this.loadedFile.emit(this.isLoaded)
                this.sharedService.isLoadedUploadfalse();
            }
        }
    }

    decisionTypePlatModalService: any = null;
    removeFile(file:any) {
        // this.emitRemoveFiles();

        this.platModalService.toggle('decision', 'deletion_subject_generic', {
            forward: () => {
                if (this.files.length >= 1) {
                    const fileToRemove = this.files.indexOf(file);

                    if (fileToRemove > -1) {
                        this.files.splice(fileToRemove, 1);
                        //
                        this.sharedService.removeFiles$.next({ files: this.files, isUpdated: true });
                        // this.filesEvent.emit(this.files);
                    }

                    this.checkMaxFiles(this.files);
                } else {

                }
                // this.decisionTypePlatModalService = 'forward';
            },
            finally: () => {
                this.platModalService.close('decision');
            },
        });
    }

    emitRemoveFiles() {
        const subscription = this.sharedService.removeFiles$.subscribe((item: any) => {
            if (item?.isUpdated) {
                this.filesEvent.emit(item?.files);
            }
        });

        this.subscription.push(subscription);
    }

    isAfterEndDate():boolean {
        const currentDate = new Date();
        const endDate = new Date(this.content.end_date);
        if (currentDate > endDate) {
            return true;
        }
        return false;
    }

    checkStatusDelivery(): string {
        if (this.isStateSended) {
            if (
                this.statusDelivery === 'late_delivered'
            ) {
                return 'show_late_delivered_label';
            }

            if (
                (this.statusDelivery === 'on_time_delivered'
                || this.statusDelivery === 'open_to_deliveries')
            ) {
                return 'show_delivered_label';
            }
        }

        if (!this.isStateSended) {
            if (this.statusDelivery === 'late_delivered') {
                if (
                    this.remainingAttempts === 0
                ) {
                    return 'show_late_delivered_label';
                }
                return 'show_action_buttons';
            }

            if (
                this.statusDelivery === 'on_time_delivered'
                || this.statusDelivery === 'open_to_deliveries'
            ) {
                if (
                    this.remainingAttempts === 0
                ) {
                    return 'show_delivered_label';
                }
                return 'show_action_buttons';
            }
        }

        return 'show_action_buttons';
    }

    returnSubmissionAttempts(): string {
        const submissionsAttempts = this.remainingAttempts;

        if (submissionsAttempts > 1 || submissionsAttempts === 0) {
            return `${submissionsAttempts.toString()} ${this.i18n.upload_submission_attempts_remaining}`;
        }

        return `${submissionsAttempts.toString()} ${this.i18n.upload_submission_attempt_remaining}`;
    }

    uploadFile(googleData: any) {
        const countFiles = this.files.length;
        const countNewFiles = googleData.length;
        let Sharingpublic: any;

        if (countFiles + countNewFiles > this.maxFiles) {
            this.googleData = [];
            this.isLoading = false;
            this.sharedService.modalClose();
            this.platModalService.toggle('message', this.i18n.upload_selected_files_number_exceeds, 'close');
        } else {
            const googleFiles: any = [];
            for (let i = 0; i < googleData.length; i++) {
                const element = googleData[i];
                googleFiles.push(element.id);
            }
            let params: any = {};

            if (this.sharingStatus) {
                params = {
                    public: true,
                    provider: 'GOOGLE_DRIVE',
                    file: googleFiles,
                };
            } else {
                params = {
                    provider: 'GOOGLE_DRIVE',
                    file: googleFiles,
                };
            }

            if (!this.syncWithDrive) {
                params.syncWithDrive = this.syncWithDrive;
            }

            this.uploadService.uploadFile(params).subscribe({
                next: (response:any) => {
                    response.forEach((item:any) => {
                        this.files.push(item);
                    });
                    this.checkMaxFiles(this.files);
                    this.files.forEach((file:any) => {
                        file.submitted_at = file.hasOwnProperty('created_at') ? file.created_at : file.submitted_at;
                        // // substitui - por /
                        // if (file.hasOwnProperty('submitted_at')) {
                        // 	file.submitted_at = new Date(file.submitted_at.replace(/-/g,"/"));
                        // }

                        // if (file.hasOwnProperty('created_at')) {
                        // 	file.submitted_at = new Date(file.created_at.replace(/-/g,"/"));
                        // }
                    });
                    this.isLoading = false;
                    console.log('this.files ', this.files);

                    this.filesEvent.emit(this.files);
                    this.googleData = [];
                    this.countFilesInsertedPermission = 0;
                },
                error: (err) => {
                    console.log('err ', err);

                    this.googleData = [];
                    this.countFilesInsertedPermission = 0;
                    this.isLoading = false;
                    if (err.status == 403) {
                        this.platModalService.toggle('message', this.i18n.upload_no_permission, 'close');
                    } else {
                        this.platModalService.toggle('message', err.error.error, 'close');
                    }
                }
            });
        }
    }

    ngOnDestroy() {
        console.log('ENTROU no Destroy do UPLOAD INLINE');
        this.files = [];
        this.filesEvent.emit(this.files);
        this.onCloseEvent.emit(this.files);
        this.sharedService.removeFiles$.next({ files: this.files, isUpdated: false });

        if (this.subscription.length) {
            this.subscription.forEach((element) => {
                element.unsubscribe();
            });
        }

        if (this.interval) {
            clearInterval(this.interval);
        }
    }
}
