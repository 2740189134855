<div class="rounded-lg transition ease-out duration-180 animate-fade-in-down">
  <div class="content-wrapper">
    <!-- HEADER CREATION MODE -->
    <header class="header">
      <div class="flex items-center gap-1.5">
        {{ titleForm }}
      </div>
      <div class="header__section-title">{{ section_title }}</div>
    </header>
  
    <form class="form flex justify-between px-2 md:px-9 flex-col md:flex-row gap-2 md:gap-5" [formGroup]="assignmentForm">
      <div class="form__fields my-2 md:my-6" >

        <div class="input-container" *ngIf="availableFields.nomeTarefa">
            <label class="my-1.5 label-content w-full">
                {{ nameContentLabel}}
            </label>
            <input
              type="text"
              class="input-contents p-2"
              required
              formControlName="nomeTarefa"
            />
        </div>

        <div class="input-container" *ngIf="availableFields.tipoTarefa && provider === 'GOOGLE_DRIVE'">
            <div class="flex items-center gap-1.5">
                <label class="my-1.5 label-content">{{
                    i18n.disciplines_select_content_type
                }}</label>
  
                <button type="button" (click)="displayPanelType.toggle()">
                   <span class="iconify-inline text-19 text-customGray-default" data-icon="carbon:help"></span>
                </button>
                
                <!-- Tooltip -->
                <div class="relative">
                    <app-display-panel
                        #displayPanelType
                        [style]="{ position: 'absolute' }"
                        [custom_radius]="'10px'"
                        [hide_arrow]="true"
                        [panel_style]="{ background: '#FEF8E6', width: '20.5rem'}"
                        [newStyle]="{ left: '0', top: '-3.125rem' }"
                    >
                        <small class="text-customGray-dark font-normal">
                            {{ i18n.disciplines_select_content_select_type }}
                        </small>
                    </app-display-panel>
                </div>
            </div>

            <select
                (change)="fnChangeAssigmentType()" 
                class="select-criteria input-contents bg-transparent"
                formControlName="tipoTarefa"
                [ngClass]="{'cursor-not-allowed' : isGoogleButtonsLocked}"
            >
                <option class="selected-text" value="" disabled selected>
                    {{ i18n.disciplines_select_option }}
                </option>
                <option *ngFor="let assignment of assignmentType" [ngValue]="assignment.type" [disabled]="editMode || isGoogleButtonsLocked">
                    {{ assignment.nameSelect }}
                </option>
            </select>

            <ng-container *ngIf="assignment !== 'UploadAssignment' && assignment !== 'Assignment'">

                <div 
                    class="flex items-center gap-1.5 mt-2"
                >
                    <label class="my-1.5 label-content">
                        {{ assignment === 'UploadQuiz' ? i18n.disciplines_file_upload_quiz : i18n.disciplines_template_file }}
                    </label>
                </div>
    
                <ng-container *ngIf="files.length">
                    <span class="text-sm font-bold my-2 text-customBlue-default">
                        {{ i18n.disciplines_add_files }} {{ files.length }}
                    </span>
                </ng-container>
    
                <div *ngFor="let task of assignmentType">
                    <button  
                        *ngIf="task.type === assignment && assignment !== 'UploadAssignment'"
                        (click)="openUploadTemplateAssignment()" 
                        class="flex flex-col md:flex-row items-center w-full gap-2 p-2 px-4 rounded-md border border-customGray-default mb-2 cursor-pointer"
                        [ngClass]="{'opacity-20 cursor-not-allowed ': isUploadLoading || isGoogleButtonsLocked }"
                        [disabled]=" isUploadLoading || isGoogleButtonsLocked"
                    >
                        <div 
                            
                            [ngClass]="{'cursor-not-allowed ':  isUploadLoading || isGoogleButtonsLocked }"
                        >
                            <span
                                class="iconify cursor-pointer text-customGray-default text-18"
                                [attr.data-icon]="task.icon"
                                [style.color]="task.color"
                            ></span>
                        </div>
                        <div class="text-14 md:text-left font-normal text-customGray-dark">
                            {{ task.nameButton }}
                        </div>
                    </button>
                </div>
    
                <!-- Google Drive Loading -->
                <div *ngIf="isUploadLoading">
                    <div class="flex flex-col items-center justify-center gap-2" >
                        <div class="mx-auto">
                            <app-bullets-loader></app-bullets-loader>
                        </div>
                        <span class="text-center text-customGray-dark text-16">{{i18n.upload_wait_until_we_load_yor_file}}</span>
                    </div>
                </div>
    
                <!-- MAX files -->
                <div *ngIf="maxFiles > 0" class="text-center">
                    <p class="text-sm font-semibold text-customBlue-default my-4">{{i18n.upload_max_files_number}} {{maxFiles}}</p>
                </div>
            </ng-container>
        </div>


        <div class="input-container" *ngIf="availableFields.envioArquivo">
            <span 
                *ngIf="files.length"
                class="text-sm font-bold text-customBlue-default"
            >
                {{ i18n.disciplines_add_files }} {{ files.length }}
            </span
          >
            <app-upload-inline
                #uploadInlineOne
                [textUploadBtn]="i18n?.disciplines_files_management"
                [multiSelect]="true"
                [files]="files"
                [fileType]="fileType"
                [maxFiles]="maxFiles"
                [syncWithDrive]="syncWithDrive"
                [showDate]="false"
                [showOpenBtn]="false"
                [showReplaceBtn]="false"
                [showSendTitle]="false"
                [hasModalFileSharing]="hasModalFileSharing"
                [flowMethod]="flowMethod"
                (filesEvent)="receiveFiles($event)"
                (sentIsModalFileSharingOpen)="receiveFileSharingModalOpen($event)"
                (googlePostParams)="receiveGooglePostParams($event)"
            ></app-upload-inline>
        </div>

        <div class="input-container" *ngIf="availableFields.organizadorConferencia">
            <label class="w-full my-1.5 label-content">{{i18n.disciplines_conference_organizer}}</label>
            <select class="select-criteria bg-transparent" formControlName="organizadorConferencia">
                <option class="selected-text" [value]="undefined" disabled selected>
                    {{ i18n.disciplines_select_conference_type }}
                </option>
                <option *ngFor="let user of users" [ngValue]="user.external_id" [disabled]="editMode">
                    {{ user.name }}
                </option>
                <option class="selected-text" [value]="null" [disabled]="editMode">
                    {{ i18n.disciplines_platform }}
                </option>
            </select>
        </div>

        

        <div class="w-full" *ngIf="availableFields.maxFiles">
          <div class="flex items-center gap-1.5">
            <label class="my-1.5 label-content">{{
              i18n.disciplines_max_number_files
            }}</label>
            <button type="button" (click)="displayPanelMaxFiles.toggle()">
              <span class="iconify-inline text-19 text-customGray-default" data-icon="carbon:help"></span>
            </button>
            <!-- Tooltip -->
            <div class="relative">
              <app-display-panel
                #displayPanelMaxFiles
                [style]="{ position: 'absolute' }"
                [custom_radius]="'10px'"
                [hide_arrow]="true"
                [panel_style]="{ background: '#FEF8E6', width: '20.5rem'}"
                [newStyle]="{ left: '0', top: '-3.125rem' }">
                <small class="text-customGray-dark font-normal">
                  {{i18n.disicplines_max_number_description}}
                </small>
              </app-display-panel>
            </div>
          </div>
          <input
            type="text"
            class="input-contents"
            formControlName="maxFiles"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
            maxlength="6"
            size="10"
          />
        </div>

        <div 
            [ngClass]="{'hidden': assignment === 'UploadQuiz'}"
            class="flex w-full flex-col"
        >
          <div class="flex items-center gap-1.5">
            <label class="my-1.5 label-content">{{
              tranlateCriterionAndAdvance.adv_and_crit_label_criterion
            }}</label>
            <button type="button" (click)="displayPanelCriterion.toggle()">
              <span class="iconify-inline text-19 text-customGray-default" data-icon="carbon:help"></span>
            </button>
             <!-- Tooltip -->
             <div class="relative">
               <app-display-panel
                 #displayPanelCriterion
                 [style]="{ position: 'absolute' }"
                 [custom_radius]="'10px'"
                 [hide_arrow]="true"
                 [panel_style]="{ background: '#FEF8E6', width: '20.5rem'}"
                 [newStyle]="{ left: '0', top: '-3.125rem' }">
                 <small class="text-customGray-dark font-normal">
                  {{ i18n.disciplines_content_criterion_description }}
                 </small>
               </app-display-panel>
             </div>
          </div>
          <app-completion-criteria
            (selectedCriteria)="receivesSelectedCriteria($event)"
            [setTypeCriteria]="selectedCriteria"
            [contentType]="assignment"
            [i18n]="tranlateCriterionAndAdvance"
          >
          </app-completion-criteria>
        </div>

        <!-- Completion rule value -->
        <div class="input-container w-100" *ngIf="showAttempts">
          <div class="flex items-center gap-1.5">
            <label class="my-1.5 label-content">{{ i18n.disciplines_number_attempts }}</label>
            <button type="button" (click)="displayPanelAttemptSubmission.toggle()">
              <span class="iconify-inline text-19 text-customGray-default" data-icon="carbon:help"></span>
            </button>
            <!-- Tooltip -->
            <div class="relative">
              <app-display-panel
                #displayPanelAttemptSubmission
                [style]="{ position: 'absolute' }"
                [custom_radius]="'10px'"
                [hide_arrow]="true"
                [panel_style]="{ background: '#FEF8E6', width: '20.5rem'}"
                [newStyle]="{ left: '0', top: '-3.125rem' }">
                <small class="text-customGray-dark font-normal">
                  {{i18n.disicplines_attempt_submissions_description}}
                </small>
              </app-display-panel>
            </div>
          </div>
          <input
            formControlName="attempt_sub"
            class="input-contents"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
            type="text"
            maxlength="6"
            size="10"
          />
        </div>

        <!-- Completion rule value -->
        <div class="input-container-timer" *ngIf="showTimeSelect">
          <div class="flex items-center gap-1.5">
            <label class="my-1 label-content">{{tranlateCriterionAndAdvance.adv_and_crit_lenghtOfStay}}</label>
            <button type="button" (click)="displayPanelshowTimeSelect.toggle()">
              <span class="iconify-inline text-19 text-customGray-default" data-icon="carbon:help"></span>
            </button>
            <!-- Tooltip -->
            <div class="relative">
              <app-display-panel
                #displayPanelshowTimeSelect
                [style]="{ position: 'absolute' }"
                [custom_radius]="'10px'"
                [hide_arrow]="true"
                [panel_style]="{ background: '#FEF8E6', width: '20.5rem'}"
                [newStyle]="{ left: '0', top: '-3.125rem' }">
                <small class="text-customGray-dark font-normal">
                  {{i18n.disciplines_completion_rule_value_description}}
                </small>
              </app-display-panel>
            </div>
          </div>
          <div>
             <i class="clock-contents-icon timer-icon-icon"></i>
            <input
              placeholder="{{
                tranlateCriterionAndAdvance.adv_and_crit_enter_time
              }}"
              class="input-content-timer input-contents"
              formControlName="lengthOfstay"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
              type="text"
              maxlength="3"
              size="10"
              min="1"
              max="120"
            />
          </div>
          <div *ngIf="!lengthOfstayValid?.pristine && lengthOfstayValid?.errors">
            <p
              class="text-sm text-customOrange-default"
              *ngIf="lengthOfstayValid?.errors?.max"
            >
              {{i18n.disciplines_max_time}}
            </p>
          </div>

          <div *ngIf="!lengthOfstayValid?.pristine && lengthOfstayValid?.errors">
            <p
              class="text-sm text-customOrange-default"
              *ngIf="lengthOfstayValid?.errors?.min"
            >
              {{i18n.disciplines_min_time}}
            </p>
          </div>
        </div>

        <div class="input-container" *ngIf="availableFields.providerLTI">
          <label class="my-1.5 label-content w-full">{{
            i18n.disciplines_select_provider_lti
          }}</label>
          <select
            class="select-criteria input-contents bg-transparent"
            formControlName="providerLTI"
          >
            <option class="selected-text" value="" disabled selected>
              {{ i18n.disciplines_select_provider_lti }}
            </option>
            <option
              class="selected-text"
              *ngFor="let providerLTI of providersLTI"
              [ngValue]="providerLTI?.id"
            >
              {{ providerLTI?.name }}
            </option>
          </select>
        </div>

        <div class="input-container" *ngIf="availableFields.idResourceLTI">
          <label class="my-1.5 label-content w-full">{{
            i18n.disciplines_id_resource_LTI
          }}</label>
          <input
            type="text"
            class="input-contents"
            formControlName="id_LTI_resource"
          />
        </div>

        <div class="input-container" *ngIf="availableFields.url">
          <label class="my-1.5 label-content w-full">{{ urlLabel }}</label>
          <input
            (change)="checkUrl($event)"
            type="text"
            class="input-contents"
            required
            formControlName="url"
          />
          <div *ngIf="!urlValid?.pristine && urlValid?.errors">
            <p
              class="text-sm"
              style="color: #e8532c"
              *ngIf="urlValid?.errors?.required"
            >
              {{ i18n.disciplines_url_required }}
            </p>
          </div>
          <div
            class="flex items-center"
            *ngIf="
              !isIframable &&
              isIframable != null &&
              this.assignment !== 'Youtube'
            "
          >
            <i class="info-circle-fill-icon h-2 w-2 color-blue-icon"></i>
            <p class="text-sm ml-2">
              {{ i18n.disciplines_new_window_warning }}
            </p>
          </div>
          <div
            class="flex items-center"
            *ngIf="urlInvalid && urlInvalid != null"
          >
            <i class="ban-outfill-icon h-2 w-2 color-blue-icon"></i>
            <p class="text-sm ml-2" style="color: #e8532c">
              {{ i18n.disciplines_invalid_link }}
            </p>
          </div>
        </div>

        <div class="input-container mb-6" *ngIf="availableFields.endDate">
          <label class="label-content w-full my-1.5">{{ i18n.disciplines_delievery_assignment_date }}</label>
          <app-picker-calendar
          [i18n]="i18n"
          [currentLanguage]="currentLanguage"
          [editMode]="editMode"
          [initialDate]="initialDate"
          [Form]="assignmentForm"
          [inputNameControlPicker]="'endDate'"
          [inputControlNameMask]="'calendarEndDate'"
          [position]="'absolute'"
          [minDateValidation]="startDate?.value === '' || startDate?.value === null ? initialDate : startDate?.value"
          (emitEvent)="fnPickDateToInput(); validateMinDate($event.calendarDateValue)">
        </app-picker-calendar>

        <!-- Validation  -->
        <div *ngIf="assignmentForm.controls['calendarEndDate']?.errors">
          <p
          class="text-sm"
          style="color: #e8532c"
          *ngIf="assignmentForm.controls['calendarEndDate']?.errors?.dateError"
        >
          {{i18n.disciplines_date_error}} {{(currentLanguage === 'en' ? (startDate?.value === '' || startDate?.value === null ? (initialDate | date:'MM/dd/yyyy HH:mm') : (startDate?.value | date:'MM/dd/yyyy HH:mm')) : (startDate?.value === '' || startDate?.value == null ? (initialDate | date:'dd/MM/yyyy HH:mm') : (startDate?.value | date:'dd/MM/yyyy HH:mm')))}}
          </p>
        </div>
          
          <div [@collapse]="enableFieldAllowLateSubmisson" class="flex items-center gap-2 pt-4">
            <input
              (change)="fnChangeAllowLateSubmission(); "
              formControlName="allowLateSubmission"
              class="checkbox_assignment"
              type="checkbox"
            />
            <div class="label-content ">
              {{ i18n.adv_and_crit_allow_to_start }}
            </div>
          </div>
        </div>

        <!-- Datas conferência (Utilizado as mesmas variáveis de (data-inicio), pois as saídas são as mesmas) -->
        <div class="input-container" *ngIf="availableFields.startDateConference">
            <label class="label-content w-full my-1.5 pt-2">{{ i18n.discipline_conference_start_date }}</label>
            <app-picker-calendar
            [i18n]="i18n"
            [currentLanguage]="currentLanguage"
            [editMode]="editMode"
            [initialDate]="initialDate"
            [Form]="assignmentForm"
            [inputNameControlPicker]="'startDate'"
            [inputControlNameMask]="'calendarStartDate'"
            [position]="'absolute'"
            [minDateValidation]="initialDate"
            (emitEvent)="fnPickDateToInput(); validateMinDate($event.calendarDateValue)">
          </app-picker-calendar>

            <!-- Validation  -->
            <div *ngIf="!assignmentForm.controls['calendarStartDate']?.pristine && assignmentForm.controls['calendarStartDate']?.errors">
              <p
                class="text-sm text-customOrange-F96319"
                *ngIf="assignmentForm.controls['calendarStartDate']?.errors?.dateError">
                {{i18n.disciplines_date_error}} {{currentLanguage === 'en' ? (initialDate  | date:'MM/dd/yyyy HH:mm') : (initialDate | date:'dd/MM/yyyy HH:mm')}}
              </p>
            </div>
        </div>

        <!-- Datas conferência (Utilizado as mesmas variáveis de (data-final), pois as saídas são as mesmas) -->
        <div class="input-container mb-6" *ngIf="availableFields.endDateConference">
            <label class="label-content w-full my-1.5">{{ i18n.discipline_conference_end_date }}</label>
            <app-picker-calendar
            [i18n]="i18n"
            [currentLanguage]="currentLanguage"
            [editMode]="editMode"
            [initialDate]="initialDate"
            [Form]="assignmentForm"
            [inputNameControlPicker]="'endDate'"
            [inputControlNameMask]="'calendarEndDate'"
            [position]="'absolute'"
            [minDateValidation]="startDate?.value === '' || startDate?.value === null ? initialDate : startDate?.value"
            (emitEvent)="fnPickDateToInput(); validateMinDate($event.calendarDateValue)">
          </app-picker-calendar>
          <!-- Validation  -->
          <div *ngIf="assignmentForm.controls['calendarEndDate']?.errors">
            <p class="text-sm text-customOrange-F96319" *ngIf="assignmentForm.controls['calendarEndDate']?.errors?.dateError">
                {{i18n.disciplines_date_error}} {{(currentLanguage === 'en' ? (startDate?.value === '' || startDate?.value === null ? (initialDate | date:'MM/dd/yyyy HH:mm') : (startDate?.value | date:'MM/dd/yyyy HH:mm')) : (startDate?.value === '' || startDate?.value === null ? (initialDate | date:'dd/MM/yyyy HH:mm') : (startDate?.value | date:'dd/MM/yyyy HH:mm')))}}
            </p>
          </div>
        </div>

        <div class="w-full">
            <span 
                class="text-customGray-dark text-start font-bold "
            >
                {{ i18n.discipline_assignment_options }}
            </span>
        </div>

        <div class="w-full" *ngIf="availableFields.startDate">
          
          <div class="flex items-center gap-2">
            <input
              (change)="fnChangeHideUntilStart(); "
              type="checkbox"
              class="checkbox_assignment"
              formControlName="hideUntilStart"
            />
            <div class="label-content">
                {{ labelSchedulerContent }}
            </div>
          </div>

          <div [@collapse]="scheduleAssignment" class="input-container">
            <label class="label-content w-full my-1.5 pt-2">{{i18n.disciplines_date_time_post_assignment}}</label>
            <app-picker-calendar
            [i18n]="i18n"
            [currentLanguage]="currentLanguage"
            [editMode]="editMode"
            [initialDate]="initialDate"
            [Form]="assignmentForm"
            [inputNameControlPicker]="'startDate'"
            [inputControlNameMask]="'calendarStartDate'"
            [position]="'absolute'"
            [minDateValidation]="initialDate"
            (emitEvent)="fnPickDateToInput(); validateMinDate($event.calendarDateValue)">
          </app-picker-calendar>

            <!-- Validation  -->
            <div *ngIf="!assignmentForm.controls['calendarStartDate']?.pristine && assignmentForm.controls['calendarStartDate']?.errors">
              <p
                class="text-14 text-customOrange-default"
                *ngIf="assignmentForm.controls['calendarStartDate']?.errors?.dateError">
                {{i18n.disciplines_date_error}} {{currentLanguage === 'en' ? (initialDate  | date:'MM/dd/yyyy HH:mm') : (initialDate | date:'dd/MM/yyyy HH:mm')}}
              </p>
            </div>
          </div>
        </div>
        <!-- End Agendar  -->

        <app-conditional-options
          class="w-full"
          (selectedOptions)="receivesOptionsForm($event)"
          [contentFormData]="content"
          [contentLength]="contentLength"
          [editMode]="editMode"
          [contents]="contents"
          [i18n]="tranlateCriterionAndAdvance"
        ></app-conditional-options>

        <!-- Create attached Forum -->
        <div *ngIf="availableFields.createForum" class="flex items-center w-full gap-2">
            <input
                formControlName="createAttachedForum"
                class="checkbox_assignment w-26"
                type="checkbox"
                [ngClass]="{'cursor-not-allowed': isFieldAttachedForumDisabled}"
                (mouseenter)="isFieldAttachedForumDisabled ? displayPanel.toggle() : ''"
            />
            <p class="label-content">
                {{ i18n.disciplines_create_discussions_forum }}
                <button type="button" (click)="displayPanel.toggle()">
                    <span class="iconify-inline text-19 text-customGray-default" data-icon="carbon:help"></span>
                </button>
            </p>

            <div class="relative">
                <app-display-panel
                  #displayPanel
                  [style]="{ position: 'absolute' }"
                  [custom_radius]="'10px'"
                  [hide_arrow]="true"
                  [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
                  [newStyle]="{ top: '25px', left: '-230px' }"
                >
                    <small class="font-normal text-customGray-dark">
                        {{ i18n.disciplines_create_attached_forum_warning }}
                    </small>
                </app-display-panel>
              </div>
        </div>

        <div class="flex flex-col gap-3.5 w-full" *ngIf="availableFields.recursoAvaliativo">
            <ng-container *appCheckUrlToHide="'disciplines-models'">
                <app-evaluative-resource-options
                [editMode]="editMode"
                [assignment]="assignment"
                (emitRubric)="saveDataInObservable()"
                (evaluationResources)="receiveEvaluationResources($event)"
                (periodOutput)="receivePeriodOutput($event)"
                (weight)="receiveWeight($event)"
                ></app-evaluative-resource-options>
                
                <app-substitute-activity
                (substitute_activity)="receiveActivity($event)"
                [load_substitute_activity]="substitute_activity_send"
                ></app-substitute-activity>
            </ng-container>
        </div>

        <div
            *ngIf="assignment === 'UploadQuiz'"
        >
            <div class="border border-customBlue-royal rounded-md py-4">
                <div class="mx-auto mb-2 flex justify-center items-center rounded-full w-10 h-10 bg-customGray-iceGray">
                    <span class="iconify text-customBlue-royal w-5 h-5" data-icon="humbleicons:exclamation"></span>
                </div>

                <div class="text-center text-customBlue-royal">
                    {{ !editMode 
                        ? i18n.disciplines_message_collect_emails_form 
                        : i18n.disciplines_upload_task_edit_message
                    }}
                </div>
            </div>
        </div>

      </div>
      <div class="form__divisor"></div>
      <div class="form__description my-2 md:my-6">
        <ng-container *ngIf="files.length">
            <app-upload-file-list
                [files]="files"
                [i18n]="i18n"
                [currentLanguage]="currentLanguage"
                [showDate]="false"
                [showOpenBtn]="true"
                [showReplaceBtn]="showReplaceBtn"
                [showRemoveBtn]="showRemoveBtn"
                [showSendTitle]="true"
                [headerText]="i18n.disciplines_attachments"
                [isLoading]="isUploadLoading"
                (sendRemoveFile)="UploadInlineComponent.removeFile($event)"
                (sendReplaceFile)="UploadInlineComponent.replaceFile($event)"
            ></app-upload-file-list>
        </ng-container>

        <ng-container *ngIf="!editMode || isFormCompleted">
            <div class="label-content ">
                {{ descriptionContentLabel }}
            </div>
            <app-editor [form]="assignmentForm" [formControlName]="'descriptionEditor'" [height]="500"></app-editor>
        </ng-container>
        <div *ngIf="!descriptionEditor?.pristine && descriptionEditor?.errors">
          <p
            class="text-sm"
            style="color: #e8532c"
            *ngIf="descriptionEditor?.errors?.required"
          >
            {{i18n.grades_field_required}}
          </p>
        </div>

        <article *ngIf="sectionService?.criterions?.length" class="mt-6">
          <header class="flex items-center justify-between gap-2 mb-4">
            <p class="text-22 font-semibold text-customBlue-default">
              {{ i18n.disciplines_content_rubric_title }}
            </p>
            <div class="flex flex-col items-end gap-3">
              <small
                class="text-14 text-customOrange-default"
                *ngIf="messageErrorEditRubric"
                >{{
                  i18n.disciplines_content_rubric_evaluative_resource
                }}</small
              >
              <button
                type="button"
                class="text-customBlue-default font-medium px-5 py-2 border border-customBlue-default rounded w-max"
                (click)="goCreateRubrica()"
              >
                {{ i18n.disciplines_content_rubric_edit }}
              </button>
            </div>
          </header>

          <ul class="flex flex-col gap-7">
            <li *ngFor="let criterion of sectionService.criterions">
              <app-card-rubric [receiveCriterion]="criterion"></app-card-rubric>
            </li>
          </ul>
        </article>

        
      </div>
    </form>

    <div
      class="form__especific"
      
      *ngIf="!editMode || (editMode && !content.hasOwnProperty('length'))"
    >
        <app-create-content-quiz
            *ngIf="assignment === 'InternalQuiz'"
            [data]="content"
            [points_available]="periodParams"
            (payload)="receivePayload($event)"
        ></app-create-content-quiz>

        <div class="flex items-center justify-end w-full">
            <div
                class="flex gap-2"
                [ngSwitch]="assignment"
            >
                <button
                    *ngSwitchCase="'InternalQuiz'"
                    class="flex items-center justify-center rounded-md py-2 px-8 font-bold text-16 text-customWhite-default bg-customBlue-default"
                    [ngClass]="{
                        'opacity-20 cursor-not-allowed': assignmentForm.invalid || !ready
                    }"
                    [disabled]="assignmentForm.invalid || !ready"
                    (click)="postAssignment()"
                >
                    {{ returnButtonText(assignment) }}
                </button>
                <button
                    *ngIf="!enableButton"
                    class="flex items-center justify-center rounded-md py-2 px-8 font-bold text-16 text-customWhite-default bg-customBlue-default"
                    [ngClass]="{
                        'opacity-20 cursor-not-allowed': assignmentForm.invalid
                    }"
                    [disabled]="assignmentForm.invalid"
                    (click)="postAssignment()"
                >
                {{ returnButtonText(assignment) }}
                </button>
                <button 
                    class="flex items-center justify-center rounded-md py-2 px-12 font-bold text-customBlue-default text-16 background-transparent border border-customBlue-default font-semibold outline-none focus:outline-none ease-linear transition-all duration-150"
                    (click)="cancelAssignment()"
                >
                    {{ i18n.disciplines_cancel }}
                </button>
            </div>
        </div>
    </div>
  </div>
</div>

<div class="hidden" *ngIf="provider !== 'GOOGLE_CLOUD_STORAGE'" >

    <app-upload-inline
        [textUploadBtn]="i18n?.disciplines_files_management"
        [multiSelect]="false"
        [files]="files"
        [fileType]="fileType"
        [maxFiles]="maxFiles"
        [syncWithDrive]="syncWithDrive"
        [showDate]="false"
        [showOpenBtn]="false"
        [showReplaceBtn]="showReplaceBtn"
        [showSendTitle]="false"
        [isShowUploadTab]="false"
        [isShowExplanation]="true"
        [textExplanation]="i18n?.disciplines_task_tooltip_picker_assignment"
        [hasModalFileSharing]="hasModalFileSharing"
        [flowMethod]="flowMethod"
        [assignment]="assignment"
        (filesEvent)="receiveFiles($event)"
        (sentIsModalFileSharingOpen)="receiveFileSharingModalOpen($event)"
        (googlePostParams)="receiveGooglePostParams($event)"
        (sendIsUploadLoading)="receiveIsUploadLoading($event)"
        (sendIsUploadLocked)="receiveIsUploadLocked($event)"
    >
    </app-upload-inline>
</div>

<app-file-sharing-modal
    [isModalOpen]="isModalFileSharingOpen"
    [i18n]="i18n"
    (sentIsModalOpen)="receiveIsModalOpenFromFileSharing($event)"
    (callPostGoogleFiles)="callPostGoogleFiles($event)"
></app-file-sharing-modal>

<p-dialog
  header="Erro"
  [(visible)]="displayBasic"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
>
  <p>{{ textError }}</p>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayBasic = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
