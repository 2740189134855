import {
    ChangeDetectorRef,
    Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren
} from '@angular/core';
import {
    FormArray, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ListItem, PostDetailedPlanning, Resources } from 'src/app/pages/content-planner/interfaces/detailed-planning.interface';
import { ResponseModules } from 'src/app/pages/content-planner/interfaces/modules.interface';
import { ContentPlannerService } from 'src/app/pages/content-planner/services/content-planner.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-detailed-class-to-class',
    templateUrl: './detailed-class-to-class.component.html',
    styleUrls: ['./detailed-class-to-class.component.scss']
})
export class DetailedClassToClassComponent implements OnInit {
    @ViewChildren('multiSelectElement') multiSelectElements: QueryList<ElementRef> | undefined;
    resourcesList: Resources[] = [];
    activitiesList: Resources[] = [];
    modulesList: ResponseModules[] = [];
    materialsList: ListItem[] = [];
    proceduresList: ListItem[] = [];
    classList: [] = [];
    planningExternalId = '';
    planningDetail: any = {};
    type: 'class_to_class' | 'estimated' | '' = '';
    form: FormGroup;
    isVisibleSuccessPlanningModal = false;
    i18n: any = {};
    isExpanded: boolean[] = [];
    isExpandedClass: boolean[] = [];
    isVisibleResourceModal = false;
    moduleResources: any = [];
    classIdResource: any;
    classResources: any = [];
    contentTypes: any = {};
    isVisibleModalLoading = false;
    messageModalLoading = '';

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private contentPlannerService: ContentPlannerService,
        private platformModalService: PlatformModalsService,
        private sharedService: SharedService,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef
    ) {
        this.createFormModule();
    }

    ngOnInit(): void {
        this.getTraductions();
        this.getParamsFromRoute();
        this.getResources();
        this.getModules();
        this.getClassSupportItems();
        this.getPlanning();
        this.listeningBroadcast();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.updateDynamicMaxWidth();
    }

    listeningBroadcast(): void {
        const channel = new BroadcastChannel('resource');
        channel.onmessage = (event) => {
            if (event.data.action === 'resourceAdded') {
                const {
                    sectionExternalId,
                    classId,
                    type,
                    contentId,
                    contentExternalId
                } = event.data;

                const data = {
                    content_id: contentId,
                    content_external_id: contentExternalId,
                    section_external_id: sectionExternalId,
                    class_id: classId,
                    type
                };

                let resourceAdded = localStorage.getItem('resourceAdded') || {};

                if (typeof resourceAdded === 'string') {
                    resourceAdded = JSON.parse(resourceAdded);
                }

                if (!resourceAdded[classId]) {
                    resourceAdded[classId] = [];
                }

                const alreadyAdded = resourceAdded[classId].some(item => item.content_id === contentId);

                if (!alreadyAdded) {
                    resourceAdded[classId].push(data);
                    localStorage.setItem('resourceAdded', JSON.stringify(resourceAdded));
                }

                this.modulesList.map((module) => {
                    module.classes.map((classe: any) => {
                        if (classe.id == classId) {
                            classe.resources.push(data);
                        }
                    });
                });

                this.cdr.detectChanges();
            }
        };
    }

    getTraductions(): void {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('ContentPlanner'),
            ...this.sharedService.getTranslationsOf('Disciplines'),
        };

        this.loadContentTypes();
    }

    loadContentTypes() {
        this.contentTypes = {
            File: this.i18n.disciplines_page_file,
            Youtube: this.i18n.disciplines_youtube,
            TextPage: this.i18n.disciplines_text_page,
            Link: this.i18n.disciplines_link,
            Assignment: this.i18n.disciplines_docs_assignment,
            UploadAssignment: this.i18n.disciplines_docs_assignment,
            Forum: this.i18n.disciplines_forum,
            InternalQuiz: this.i18n.disciplines_upload_quiz,
            Scorm: this.i18n.disciplines_scorm,
            LTI: this.i18n.disciplines_page_lti,
            Conference: this.i18n.disciplines_conference,
            UploadQuiz: this.i18n.disciplines_upload_quiz_external,
        };
    }

    getParamsFromRoute(): void {
        this.planningExternalId = this.route.snapshot.params.planning_external_id;
        this.type = this.route.snapshot.params.type;
    }

    getPlanning(): void {
        if (this.planningExternalId) {
            this.contentPlannerService.getPlanningDetail(this.planningExternalId).subscribe({
                next: (response) => {
                    this.planningDetail = response;
                    this.planningDetail.degree_compoente = this.formatedDegreeComponente(response);
                },
                error: (error) => {
                    this.planningDetail = [];
                }
            });
        }
    }

    formatedDegreeComponente(response: any): string {
        let degreeComponente = '';
        if (response && response.degree_name && response.component_name) {
            const degree = response.degree_name[0] !== 'G' ? this.i18n.planner_degree.toLowerCase() : '';
            degreeComponente = `${response.degree_name} ${degree} - ${response.component_name}`;
        }
        return degreeComponente;
    }

    createFormModule(): void {
        this.form = this.fb.group({
            modules: ['', Validators.required],
            number_estimated: ['', Validators.required],
            classes: this.fb.array([this.createEmptyClass()]),
        });
    }

    createEmptyClass(): FormGroup {
        return this.fb.group({
            description: ['', Validators.required],
            resources: [[]],
            activities: [[], Validators.required],
            materials: [[], Validators.required],
            procedures: [[], Validators.required],
        });
    }

    getResources(): void {
        this.resourcesList = [
            {
                name: this.i18n.planner_content_file,
                type: 'File',
                value: 'rsc_file_enabled'
            },
            {
                name: this.i18n.planner_content_link,
                type: 'Link',
                value: 'rsc_link_enabled'
            },
            {
                name: this.i18n.planner_content_youtube,
                type: 'Youtube',
                value: 'rsc_youtube_enabled'
            },
            {
                name: this.i18n.planner_content_textpage,
                type: 'TextPage',
                value: 'rsc_textpage_enabled'
            },
            {
                name: this.i18n.planner_content_scorm,
                type: 'Scorm',
                value: 'rsc_scorm_enabled'
            },
            {
                name: this.i18n.planner_content_lti,
                type: 'LTI',
                value: 'rsc_lti_enabled'
            },
            {
                name: this.i18n.planner_content_conference,
                type: 'Conference',
                value: 'rsc_conference_enabled'
            },
            {
                name: this.i18n.planner_content_external_quiz,
                type: 'UploadQuiz',
                value: 'rsc_external_quiz_enabled'
            },
        ];

        this.activitiesList = [
            {
                name: this.i18n.planner_content_docs_assignment,
                type: 'DocsAssignment',
                value: 'rsc_docs_assignment_enabled'

            },
            {
                name: this.i18n.planner_content_sheets_assignment,
                type: 'SheetsAssignment',
                value: 'rsc_sheets_assignment_enabled'
            },
            {
                name: this.i18n.planner_content_upload_assignment,
                type: 'UploadAssignment',
                value: 'rsc_upload_assignment_enabled'
            },
            {
                name: this.i18n.planner_content_internal_quiz,
                type: 'InternalQuiz',
                value: 'rsc_internal_quiz_enabled'
            },
        ];
    }

    getModules(): void {
        if (this.planningExternalId) {
            this.contentPlannerService.getModules(this.planningExternalId).subscribe({
                next: (response) => {
                    this.modulesList = response.plannings_modules;
                    this.sanitizeModulesList();
                },
                error: () => {
                    this.modulesList = [];
                    this.form.controls.modules.setValue('error');
                }
            });
        }
    }

    sanitizeModulesList() {
        if (this.modulesList.length) {
            this.modulesList.map((module, index) => {
                if (index === 0) {
                    this.isExpanded[index] = true;
                }

                module?.classes.map((classe: any, index) => {
                    if (index === 0) {
                        this.isExpandedClass[index] = true;
                    }

                    classe.url_file = this.sanitizer.bypassSecurityTrustResourceUrl(classe.url_file);

                    if (!classe.resources) {
                        classe.resources = [];
                    }

                    const classId = classe.id.toString();
                    const storedResources = JSON.parse(localStorage.getItem('resourceAdded') || '{}');

                    if (storedResources[classId]) {
                        classe.resources = [...classe.resources, ...storedResources[classId]];
                    }

                    const control = this.fb.control(classe.skills || []);
                    this.form.addControl(`skills_${classId}`, control);
                });
            });
        }
    }

    getClassSupportItems(): void {
        this.contentPlannerService.getListItems().subscribe({
            next: (response) => {
                this.materialsList = response.material;
                this.proceduresList = response.procedure;
            },
            error: () => {
                this.materialsList = [];
                this.proceduresList = [];
            }
        });
    }

    controlModalSuccess(value: boolean): void {
        if (typeof value === 'boolean') {
            this.isVisibleSuccessPlanningModal = value;
        }
    }

    validateEstimatedClass(EstimatedInput: HTMLInputElement): void {
        if (EstimatedInput) {
            let numericValue = EstimatedInput.value.replace(/[^0-9]/g, '');
            numericValue = numericValue.replace(/^0*(\d+)/, '$1');

            if (numericValue === '0') {
                numericValue = '';
            }

            const numberOfClasses = parseInt(numericValue, 10);
            const classesArray = this.form.get('classes') as FormArray;

            while (classesArray.length > numberOfClasses) {
                classesArray.removeAt(classesArray.length - 1);
            }

            while (classesArray.length < numberOfClasses) {
                classesArray.push(this.createEmptyClass());
            }

            this.form.controls.number_estimated.setValue(numericValue);
            this.updateDynamicMaxWidthTimeout();
        }
    }

    setResourcesItem(value: string, classIndex: number): void {
        const classArray = this.form.get('classes') as FormArray;
        const classControl = classArray.at(classIndex);

        if (classControl) {
            const resourcesControl = classControl.get('resources');

            if (resourcesControl) {
                if (resourcesControl.value.includes(value)) {
                    const itemIndex = resourcesControl.value.indexOf(value);
                    resourcesControl.value.splice(itemIndex, 1);
                } else {
                    resourcesControl.value.push(value);
                }
            }
        }
    }

    resetForm(moduleExternalId: string): void {
        this.form.reset({
            modules: moduleExternalId,
            number_estimated: '',
        });

        const classesArray = this.form.get('classes') as FormArray;
        classesArray.clear();
        classesArray.push(this.createEmptyClass());
    }

    isSubmitButtonDisabled(): boolean {
        const classesArray = this.form.get('classes') as FormArray;

        for (let i = 0; i < classesArray.length; i += 1) {
            const classGroup = classesArray.at(i) as FormGroup;

            if (!classGroup.controls.resources.value.length) {
                return true;
            }
        }
        return false;
    }

    updateDynamicMaxWidthTimeout(): void {
        setTimeout(() => this.updateDynamicMaxWidth(), 500);
    }

    updateDynamicMaxWidth(): void {
        if (this.multiSelectElements) {
            this.multiSelectElements.forEach((e: ElementRef) => {
                const containerWidth = e.nativeElement.parentElement.parentElement.offsetWidth;
                if (window.innerWidth < 640) {
                    e.nativeElement.style.maxWidth = `${containerWidth}px`;
                } else if (window.innerWidth > 640 && window.innerWidth < 768) {
                    e.nativeElement.style.maxWidth = `${(containerWidth / 2) - 8}px`;
                } else {
                    e.nativeElement.style.maxWidth = `${(containerWidth / 3) - 8}px`;
                }
            });
        }
    }

    onSubmit(): void {
        if (this.form.valid && !this.isSubmitButtonDisabled()) {
            const moduleExternalId = this.form.controls.modules.value;
            const payload: PostDetailedPlanning = {
                type: this.type,
                estimated_classes: parseFloat(this.form.controls.number_estimated.value),
                planning_classes: []
            };

            const classesArray = this.form.get('classes') as FormArray;

            (classesArray.controls as FormGroup[]).forEach((classGroup: FormGroup) => {
                const planningClass = {
                    description: classGroup.controls.description.value,
                    rsc_file_enabled: classGroup.controls.resources.value.includes('rsc_file_enabled'),
                    rsc_link_enabled: classGroup.controls.resources.value.includes('rsc_link_enabled'),
                    rsc_youtube_enabled: classGroup.controls.resources.value.includes('rsc_youtube_enabled'),
                    rsc_textpage_enabled: classGroup.controls.resources.value.includes('rsc_textpage_enabled'),
                    rsc_internal_quiz_enabled: classGroup.controls.activities.value.includes('rsc_internal_quiz_enabled'),
                    rsc_scorm_enabled: classGroup.controls.resources.value.includes('rsc_scorm_enabled'),
                    rsc_lti_enabled: classGroup.controls.resources.value.includes('rsc_lti_enabled'),
                    rsc_conference_enabled: classGroup.controls.resources.value.includes('rsc_conference_enabled'),
                    rsc_external_quiz_enabled: classGroup.controls.resources.value.includes('rsc_external_quiz_enabled'),
                    rsc_docs_assignment_enabled: classGroup.controls.activities.value.includes('rsc_docs_assignment_enabled'),
                    rsc_sheets_assignment_enabled: classGroup.controls.activities.value.includes('rsc_sheets_assignment_enabled'),
                    rsc_upload_assignment_enabled: classGroup.controls.activities.value.includes('rsc_upload_assignment_enabled'),
                    material: classGroup.controls.materials.value,
                    procedure: classGroup.controls.procedures.value
                };

                payload.planning_classes.push(planningClass);
            });

            this.contentPlannerService.postDetailedPlanning(this.planningExternalId, moduleExternalId, payload).subscribe({
                next: () => {
                    this.resetForm(moduleExternalId);
                    this.getResources();
                    this.controlModalSuccess(true);
                },
                error: () => {
                    this.platformModalService.toggle('message', this.i18n.planner_error_create_planner, 'close');
                }
            });
        }
    }

    toggleExpand(index: number) {
        const tempState = this.isExpanded[index];
        this.isExpanded.fill(false);
        this.isExpanded[index] = !tempState;
    }

    toggleExpandClass(index: number) {
        const tempState = this.isExpandedClass[index];
        this.isExpandedClass.fill(false);
        this.isExpandedClass[index] = !tempState;
    }

    addNewClass(module_id: number) {
        this.isVisibleModalLoading = true;
        this.messageModalLoading = this.i18n.planner_decision_loading_create_class;

        this.contentPlannerService.postAddClass({ module_id }).subscribe({
            next: (response) => {
                this.getModules();
                this.isVisibleModalLoading = false;
            },
            error: () => {
                console.log('error');
                this.isVisibleModalLoading = false;
            }
        });
    }

    deleteClass(class_id: number) {
        this.isVisibleModalLoading = true;
        this.messageModalLoading = this.i18n.planner_decision_loading_delete_class;

        this.contentPlannerService.deleteClass(class_id)
            .subscribe(
                {
                    next: (response) => {
                        this.getModules();
                        this.isVisibleModalLoading = false;
                    },
                    error: (error) => {
                        this.isVisibleModalLoading = false;
                        console.log(error);
                    }
                }
            );
    }

    openModalResource(module: any, class_id: number) {
        this.moduleResources = module;
        this.isVisibleResourceModal = true;
        this.classIdResource = class_id;
    }

    closeModalResource() {
        this.moduleResources = [];
        this.isVisibleResourceModal = false;
    }

    decisionDeleteResource(resource: any): void {
        this.platformModalService.toggle('decision', this.i18n.planner_decision_delete_resource, {
            forward: () => {
                this.deleteResource(resource.class_id, resource.content_id);
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    decisionDeleteClass(class_id: number): void {
        this.platformModalService.toggle('decision', this.i18n.planner_decision_delete_class, {
            forward: () => {
                this.deleteClass(class_id);
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    decisionCreateClass(module: any): void {
        console.log('module_id', module.id);
        this.platformModalService.toggle('decision', this.i18n.planner_decision_create_class, {
            forward: () => {
                this.addNewClass(module.id);
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    decisionSavePlanning(type: string): void {
        this.platformModalService.toggle('decision', this.i18n.planner_decision_save_planning_class, {
            forward: () => {
                this.savePlanning();
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    deleteResource(classId: number, contentId: number) {
        this.isVisibleModalLoading = true;
        this.messageModalLoading = this.i18n.planner_decision_loading_delete_resource;

        this.contentPlannerService.deleteResource(classId, contentId).subscribe({
            next: (response) => {
                this.modulesList.map((module) => {
                    module.classes.map((classe: any) => {
                        if (classe.id === classId) {
                            const index = classe.resources.findIndex((resource: any) => resource.content_id === contentId);
                            classe.resources.splice(index, 1);
                        }
                    });
                });

                this.refactoryResourceStorage(classId, contentId);
                this.isVisibleModalLoading = false;
            },
            error: (error) => {
                console.log('error', error);
            }
        });
    }

    refactoryResourceStorage(classId: number, contentId: number) {
        const resourceAdded = localStorage.getItem('resourceAdded') || '';

        if (resourceAdded[classId]) {
            const resourceJson = JSON.parse(resourceAdded);
            resourceJson[classId] = resourceJson[classId].filter((resource: any) => resource.content_id !== contentId);
            localStorage.setItem('resourceAdded', JSON.stringify(resourceJson));
        } else {
            this.getModules();
        }
    }

    setSkillsItem(value: any, classId: number): void {
        this.modulesList.map((module) => {
            module.classes.map((classe: any) => {
                if (classe.id === classId) {
                    classe.skills = value;
                }
            });
        });
    }

    savePlanning() {
        this.isVisibleModalLoading = true;
        this.messageModalLoading = this.i18n.planner_decision_loading_create_planning;

        const classes = this.modulesList.flatMap(module => module.classes);
        this.contentPlannerService.patchModuleClass({ classes }).subscribe({
            next: (response) => {
                this.isVisibleModalLoading = false;
                localStorage.removeItem('resourceAdded');
                this.controlModalSuccess(true);
            },
            error: (error) => {
                this.isVisibleModalLoading = false;
                console.log('error', { error });
            }
        });
    }

    editComponent(module, resource) {
        const url = `/disciplines/${module.discipline_external_id}/content-assignment/assignment;content=${resource.content_external_id};section=${resource.section_external_id};section_title=${module.section.title};type=${resource.type};edit=1;class_id=${resource.class_id}`;
        // Abrir a rota em uma nova aba
        window.open(url, '_blank');
    }
}
