import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
@Component({
  selector: 'app-create-list',
  templateUrl: './create-list.component.html',
  styleUrls: ['./create-list.component.scss'],
})
export class CreateListComponent implements OnInit {
  @Output() change = new EventEmitter();
  @Input() data: any;

  i18n: any;

  alternatives: any[] = [
    { text: '', correct: false },
    { text: '', correct: false },
  ];

  constructor(private shared: SharedService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    const translationModuleName = 'QuestionBank';
    this.shared.setI18n(this, translationModuleName);

    if (this.data !== undefined) {
      this.alternatives = this.data.options;
      this.emitResult();
    }
  }

  emitResult() {
    const arrOfOptions = this.alternatives.map((alt, i) => ({
      text: this.alternatives[i].text,
      order: i + 1,
      correct: alt.correct ? 1 : 0,
    }));

    const ready =
      arrOfOptions.every((alt) => alt.text.length) &&
      arrOfOptions.some((alt) => alt.correct) &&
      arrOfOptions.length > 1;

    this.change.emit({
      ready,
      payload: {
        options: arrOfOptions,
      },
    });
  }

  add($randomElement) {
    const maxNumberOfAlternatives = 30;
    const $formBody = $randomElement.closest('.form-body');

    if (this.alternatives.length < maxNumberOfAlternatives)
      this.alternatives.push({ text: '', active: false });
    this.scroll($formBody);

    this.emitResult();
  }

  delete($event, index) {
    $event.stopPropagation();

    this.alternatives.splice(index, 1);

    this.emitResult();
  }

  setCorrectAlt(index: number) {
    this.alternatives.forEach((alt, i) => {
      if (i === index) alt.correct = !alt.correct;
      else alt.correct = false;
    });

    this.emitResult();
  }

  input(_, index) {
    _.stopPropagation();

    this.alternatives[index].text = _.target.value;

    this.emitResult();
  }

  getAlternativeName(index: number) {
    return `Alternativa ${index + 1}`;
  }

  scroll($formBody) {
    const insideDrodownClassheight = 51;

    $formBody.scrollTop += insideDrodownClassheight; //this gives the scroll, is them sum of the .inside-dropdown (class) height with the flex gap
  }
}
