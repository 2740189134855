import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ReportsService } from '../../../reports.service';
import { MessengerToastService } from 'src/app/components/messenger-toast/messenger-toast.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';

@Component({
  selector: 'app-large-grades',
  templateUrl: './large-grades.component.html',
  styleUrls: ['./large-grades.component.scss']
})


export class LargeGradesComponent implements OnInit {
  external_id: any = []
  reportsData: any = []
  periodsData: any = []
  loading: boolean = false
  selectedPeriodIDs: any = []

  resourceForm: UntypedFormGroup

  sortBySelect: any = [

  ]

  sortTypeSelect: any = [

  ]

  i18n: any = [];
  currentLanguage: any;

  q: string = '';
  finDate:  string = '';
  iniDate:  string = '';
  sortBy:   string = 'user_name';
  sortType: string = 'ASC';
  periods: any = '';
  isNotStandalone: boolean = false;

  @Output() screen = new EventEmitter();

  constructor(
    public platModalService: PlatformModalsService,
    private reportsService: ReportsService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    public toastMessenger: MessengerToastService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.isNotStandalone = this.localStorageService.isNotStandalone();
    this.getTranslations();
    this.loadTranslations();
    this.getUrlParams();
    this.getPeriods(this.external_id);
    this.getReportDetail(
      this.external_id,
      'GRADES',
      0,
      1,
      this.q,
      this.iniDate,
      this.finDate,
      this.sortBy,
      this.sortType,
      this.periods,
      )
    this.createResources();
  }

  loadingTrue() {
    this.platModalService.toggle('loading');
  }

  loadingFalse() {
    this.platModalService.close('loading');
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Reports'),...this.sharedService.getTranslationsOf('Modal')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  loadTranslations() {
    this.sortBySelect = [
      {'title': `${this.i18n.reports_name}`, 'value': 'user_name'},
      {'title': `${this.i18n.reports_average}`, 'value': 'average'},
    ]
    this.sortTypeSelect = [
      {'title': `${this.i18n.reports_ascending}`, 'value': 'ASC'},
      {'title': `${this.i18n.reports_descending}`, 'value': 'DESC'}
    ]
  }

  getReportDetail(disipline, type, format, detail, q, iniDate, finDate, sortBy, sortType, periods) {
    this.loading = true
    let form_group = ''
    this.reportsService
      .getReportDetail(disipline, type, format, detail, q, iniDate, finDate, sortBy, sortType, periods, form_group)
      .subscribe(
        data => {
          this.reportsData = data.periods
          this.loading = false
        }, //data
        (err) => console.log('err', err), //err
        ()=> { //complete
          console.log('ok')
          this.loading = false
        }
      )
  }

  getPeriods(disipline) {
    this.loading = true
    this.reportsService
      .getPeriods(disipline)
      .subscribe(
        data => { //data
          this.periodsData = data
          console.log('PERIoDOS', this.periodsData)
        },
        (err) => console.log('err', err), //err
        ()=> { //complete
          console.log('ok')
        }
      )
  }

  getFile(report_format, format_file){
    let  discipline_external_id = this.external_id
    let  report_type = 'GRADES'
    //report_format = report_format
    let  detail_level = 1
    let  q = this.resourceForm.controls.q.value;
    let  initial_date =  ''
    let  final_date = ''
    let  sort_by = this.resourceForm.controls.sortBy.value
    let  sort_type = this.resourceForm.controls.sortType.value
    let  periods = this.selectedPeriodIDs
    let  form_group = ''

    this.reportsService
    .getReportBlobGrade(
     /*  this.external_id, 'GRADES', report_format, 'average', 'ASC' */
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      initial_date,
      final_date,
      sort_by,
      sort_type,
      periods,
      form_group
      )
      .subscribe( (blob: Blob): void => {
        this.howToGetTheFile(format_file, blob)
      });
      (err) => console.log('ERROR', err);
      () => {
        console.log('FEITO!')
      }
  }

  getUrl(report_format, format_file){
    this.toastMessenger.messengerToast('load', this.i18n.reports_generating, true)
    let  discipline_external_id = this.external_id
    let  report_type = 'GRADES'
    //report_format = report_format
    let  detail_level = 1
    let  q = this.resourceForm.controls.q.value;
    let  initial_date =  ''
    let  final_date = ''
    let  sort_by = this.resourceForm.controls.sortBy.value
    let  sort_type = this.resourceForm.controls.sortType.value
    let  periods = this.selectedPeriodIDs
    let  form_group = ''

    this.reportsService
    .getReportUrlBlobGrade(
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      initial_date,
      final_date,
      sort_by,
      sort_type,
      periods,
      form_group
    )
      .subscribe( (data): void => {
        this.toastMessenger.messengerToast('success', this.i18n.reports_finished, true, this.i18n.reports_view_report, data.url)
      });
  }

  howToGetTheFile(format_file: string, blob: Blob) {
    const file = new Blob([blob], { type: `application/${format_file}` });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');

    switch (format_file) {
      case 'pdf':
       window.open(fileURL, '_blank', 'width=800, height=600');
      break;
      case 'xlsx':
        a.href = fileURL;
        a.download = `active_students.${format_file}`;
        a.click();
      break;
      case 'csv':
        a.href = fileURL;
        a.download = `active_students.${format_file}`;
        a.click();
      break;
      default:
        break;
    }
  }

  getUrlParams() {
    this.route?.parent?.parent?.params
      .subscribe((params) => this.external_id = params.id)
  }

  arrayFormatForTitleContent(array) {
    let arrayContentTitle: any = []
      array?.students[0]?.period_grades.forEach(element => {
        arrayContentTitle.push({
          title: element.content_title,
          substitutive_title: element.content_substitutive_title
        })
      });
      return arrayContentTitle
  }

  isLastFromArray(array, index) {
    let arrayLength = array.length -1
    let isIndexLast = arrayLength != index ? true : false
    return isIndexLast
  }

  textCut(limit: any, textToCut: any) {
    let text = textToCut;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }

  showMainScreen() {
    this.screen.emit('show')
    console.log('CHAMANDO RESOURCES');
  }

  createResources() {
    this.resourceForm = this.formBuilder.group({
      q:        ['',],
      sortBy:   ['user_name',],
      sortType: ['ASC',],
      periods:  ['', ],
    })
    this.resourceForm.get('periods')?.valueChanges.subscribe(
      periodsData => {
        this.selectedPeriodIDs = periodsData.toString()
        console.log('PERIODS SELECTED', this.selectedPeriodIDs);
      }
    )
  }

  doQuery() {
    let  discipline_external_id = this.external_id
    let  report_type = 'GRADES'
    let  report_format = 0
    let  detail_level = 1
    let  q = this.resourceForm.controls.q.value;
    let  initial_date =  ''
    let  final_date = ''
    let  sort_by = this.resourceForm.controls.sortBy.value
    let  sort_type = this.resourceForm.controls.sortType.value
    let  periods = this.selectedPeriodIDs

    this.getReportDetail(
      discipline_external_id,
      report_type,
      report_format,
      detail_level,
      q,
      initial_date,
      final_date,
      sort_by,
      sort_type,
      periods
    );
  }

}
