import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { HomeLayoutComponent } from '../home/home.component';
import { ProfileComponent } from './profile.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        component: ProfileComponent

      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
