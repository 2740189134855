<div
    class="flex mt-8 bg-customWhite-default w-full flex-col sm:flex-row rounded-xl"
>
    <div
        class="flex items-center justify-center bg-customWhite-bWhite h-20 rounded-l-xl-none sm:rounded-l-xl rounded-t-xl sm:rounded-t-none"
    >
        <div class="py-8 px-4">
            <span
                class="iconify-inline text-24 text-customBlue-default"
                data-icon="lucide:download"
            ></span>
        </div>
    </div>
    <div class="flex h-20 justify-between items-center pl-4 pr-4 w-full">
        <div>
            <div
                class="text-inicieColors-blue font-bold text-18 font-montserrat"
            >
                {{ title }}
            </div>
            <div
                class="text-customGray-dark text-14 sm:text-16 sm:text-left font-weight-normal font-roboto"
            >
                {{ subtitle }}
            </div>
        </div>
        <div class="relative">
            <button
                id="popoverSheet"
                class="arrow-button"
                (click)="togglePopover()"
                style="width: 20px"
            >
                <span
                    class="iconify-inline text-14 text-customBlue-default"
                    data-icon="bxs:down-arrow"
                ></span>
            </button>
            <div *ngIf="isPopoverVisible" class="popover">
                <div (click)="openModal()">
                    <a
                        class="text-15 font-normal font-roboto text-inicieColors-blue"
                    >
                        {{ labelGenerate }}
                    </a>
                </div>
                <div>
                    <a
                        [routerLink]="[link]"
                        class="text-15 font-normal font-roboto text-inicieColors-blue"
                    >
                        {{ labelImport }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal-help-connection
    [isVisible]="isModalVisible"
    [i18n]="i18n"
    (eventClose)="closeModal()"
    [modeGenerate] = "modeGenerate"
></app-modal-help-connection>
