import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, OnChanges {
  activities: any = []

  isEmpty: boolean = false

  i18n: any = [];
  currentLanguage: any;

  @Input() reportsData: any = []

  @Output() screen = new EventEmitter();

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ACTIVITIES', changes.reportsData.currentValue?.CONTENTS?.contents);
    this.activities = changes.reportsData.currentValue?.CONTENTS?.contents;
    this.test();
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Reports'),...this.sharedService.getTranslationsOf('Modal')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
    console.log('TRADUCAO', this.i18n);
  }

  showBiggerScreen() {
    this.screen.emit('actives')
    console.log('CHAMANDO ACTIVE');

  }

  test() {
    if (Array.isArray(this.reportsData?.CONTENTS) == false && this.reportsData?.CONTENTS?.contents == undefined) {
      this.isEmpty = true
      console.log('ENTROU NO TRUE');
    }
    else if (Array.isArray(this.reportsData?.CONTENTS) == false && this.reportsData?.CONTENTS?.contents.length == 0) {
      this.isEmpty = true
      console.log('ENTROU NO TRUE');
    }
    else if (this.reportsData?.CONTENTS.length == 0) {
      this.isEmpty = true
      console.log('ENTROU NO TRUE');
    }
    else {
      this.isEmpty = false
      console.log('ENTROU NO FALSE');
    }
  }

}
