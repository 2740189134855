import { Injectable } from '@angular/core';
import {
    ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class DisciplinesSettingsGuard implements CanActivate {
    constructor(
        private sharedService: SharedService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const type = 'list';
        const i18n = this.sharedService.getTranslationsOf('Errors');
        const message = i18n.permission_unauthorized;

        if (state.url.includes('discipline-settings/discipline-settings-data')) {
            if (!this.sharedService.checkPermission('disciplines_settings', type)) {
                // Se disciplines_settings_basic não tiver permissão, redireciona para a primeira página que tem permissão
                // dentro de configurações de turma
                const firstAvailable = this.sharedService.getFirstPermissionAvailable('disciplines_settings', 'list')[0].split('_').pop();
                const routeTo:any = route.routeConfig?.children?.filter((item:any) => item.path.includes(firstAvailable))[0];
                const currentRoute = state.url.replace('/discipline-settings-data', '');

                if (route.data?.hasOwnProperty('isRedirectedFrom')) {
                    this.router.navigate([`${currentRoute}/${routeTo?.path}`, ], { relativeTo: this.route });
                    return false;
                }
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('discipline-settings/discipline-settings-visibility')) {
            if (!this.sharedService.checkPermission('disciplines_settings', type)) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('discipline-settings/discipline-settings-tab')) {
            if (!this.sharedService.checkPermission('disciplines_settings', type)) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('discipline-settings/discipline-settings-general')) {
            if (!this.sharedService.checkPermission('disciplines_settings', type)) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('discipline-settings/discipline-settings-details')) {
            if (!this.sharedService.checkPermission('disciplines_settings', type)) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('discipline-settings/discipline-settings-certificates')) {
            if (!this.sharedService.checkPermission('disciplines_settings', type)) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('discipline-settings/discipline-settings-grades')) {
            if (!this.sharedService.checkPermission('disciplines_settings', type)) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('discipline-settings/discipline-settings-file-sharing')) {
            if (!this.sharedService.checkPermission('disciplines_settings', type)) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }
        return true;
    }
}
