import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TitlePageService } from 'src/app/services/titlePageService/title-page.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardTitleResolver implements Resolve<void> {
    constructor(
        private titleService: TitlePageService
    ) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {

        const paths = [
            { url: '', title: '' },
        ]

        this.titleService.resolveTitle(route,paths);
    }
}
