import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-header-forum',
  templateUrl: './header-forum.component.html',
  styleUrls: ['./header-forum.component.scss']
})
export class HeaderForumComponent implements OnInit {
  @Input() query: any;
  @Input() user: any;
  @Input() filterOpen: any;
  @Input() isModalOpen: any;
  @Input() showFilter: boolean = true;
  @Input() showSearch: boolean = true;
  @Input() showAddModal: boolean = true;
  @Input() titlePage: string;
  @Input() placeholderSearch: string = '';

  
  @Output() sendQuery = new EventEmitter();
  @Output() sendFilter = new EventEmitter();
  @Output() sendOrder = new EventEmitter();
  @Output() sendModalOpenStatus = new EventEmitter();

  filterChecked: boolean;
  orderChecked: boolean = true;
  i18n: any = [];

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
  }

  openModal(){
    this.sendModalOpenStatus.emit(true);
  }

  getTranslations() {
    this.i18n = this.sharedService.getTranslationsOf('Forum');
    this.placeholderSearch = this.i18n.forum_search_debates;    
  }    

  onToggle(event:any){
    this.filterOpen = event;
  }

  checkFilter(event:any, filter: any){
    this.filterChecked = !this.filterChecked;
    this.sendFilter.emit(filter);
    event?.preventDefault();
    event?.stopPropagation();
  }

  checkOrder(event:any, filter: any){
    this.orderChecked = !this.orderChecked;
    if(this.orderChecked){
      this.sendOrder.emit('DESC');
    }else{
      this.sendOrder.emit('ASC');
    }
    event?.preventDefault();
    event?.stopPropagation();
  }


  searchForum(){
    console.log('this.query ', this.query);
    
    this.sendQuery.emit(this.query);
  }  
}
