import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../../../grades.service';
import { minDateValidator } from 'src/app/utils/form-validators/form-validators'; 

@Component({
  selector: 'app-grades-periods-modal',
  templateUrl: './grades-periods-modal.component.html',
  styleUrls: ['./grades-periods-modal.component.scss'],
})
export class GradesPeriodsModalComponent implements OnInit {
  @Input() isModalOpen: boolean;
  @Input() controlButtonPeriodText: boolean;
  @Input() discipline_external_id: boolean;
  @Input() period_id: any;
  @Input() editMode: any;
  @Output() sendIsModalOpen = new EventEmitter();
  @Output() sendNewPeriod = new EventEmitter();
  isModalCategoriesOpen: boolean = false;

  periodsForm: UntypedFormGroup;
  grade_period_id: any;
  i18n: any = [];
  currentLanguage: string | null;

  initialDate: Date = new Date();

  constructor(
    private FormBuilder: UntypedFormBuilder,
    private gradesService: GradesService,
    public platModalService: PlatformModalsService,
    public sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.createPeriodsForm();
    this.gradesService.period$.subscribe((period) => {
      if (Object.keys(period).length) this.setPeriodValueToForm(period);
    });
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Grades'), ...this.sharedService.getTranslationsOf('Disciplines')};
    this.currentLanguage = this.sharedService.getSelectedLanguage();
  }

  createPeriodsForm() {
    this.periodsForm = this.FormBuilder.group({
      name: ['', [Validators.required]],
      calculation_type: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      calendarEndDate: ['', [this.minDateValidator.bind(this)]],
      startDate: [new Date()],
      calendarStartDate: ['', [this.minDateValidator.bind(this)]],
    });
  }

  get name() {
    return this.periodsForm.get('name');
  }

  get start_date() {
    return this.periodsForm.get('start_date');
  }

  get end_date() {
    return this.periodsForm.get('end_date');
  }

  get calculation_type() {
    return this.periodsForm.get('calculation_type');
  }

  setPeriodValueToForm(period: any) {
    this.grade_period_id = period?.id;
    this.periodsForm.controls.name.setValue(period?.name);
    this.periodsForm.controls.calculation_type.setValue(
      period?.calculation_type
    );
    this.periodsForm.controls.start_date.setValue(this.returnDate(period?.start_date));
    this.periodsForm.controls['calendarStartDate'].setValue(this.returnDate(period?.start_date));
    this.periodsForm.controls.end_date.setValue(this.returnDate(period?.end_date));
    this.periodsForm.controls['calendarEndDate'].setValue(this.returnDate(period?.end_date));
    this.fnPickDateToInput();
  }

  returnDate(date: string): string {
    return formatDate(date, this.currentLanguage === 'en' ? 'MM/dd/yyyy HH:mm:ss' : 'dd/MM/yyyy HH:mm:ss', this.currentLanguage === 'en' ? 'en-US' : 'pt-BR')
  }

  resetConceptValues() {
    this.periodsForm.reset();
  }

  returnFormatDate(dateValue: any): any {
    if (!dateValue) return;
    console.log('data ', dateValue);
    // Checa se é uma data do tipo Date ou uma string
    if (!(dateValue instanceof Date)) {
        dateValue = dateValue.replaceAll('/', '-').replace('00:00:00', '').trim();
        dateValue = dateValue.split('-');
      if (this.currentLanguage != 'en') {
        dateValue = dateValue[2] + '-' + dateValue[1] + '-' + dateValue[0];
      }else{
        dateValue = dateValue[2] + '-' + dateValue[0] + '-' + dateValue[1];
      }
      return dateValue;
    }else{

      return formatDate(dateValue, 'yyyy-MM-dd', 'en-US');
    }
  }

  saveGeneralSettings() {
    let name = this.periodsForm.controls.name.value;
    let calculation_type = this.periodsForm.controls.calculation_type.value;
    
    let start_date: any = this.returnFormatDate(this.periodsForm.controls.start_date.value);
    let end_date: any = this.returnFormatDate(this.periodsForm.controls.end_date.value);

    const params = {
      name: name,
      calculation_type: calculation_type,
      start_date: start_date,
      end_date: end_date,
    };

    let message: string;

    if (this.editMode) {
      this.gradesService
        .patchPeriod(this.discipline_external_id, this.grade_period_id, params)
        .subscribe({
          next: (response) => {
            this.gradesService.checkForUpdatesOnPeriod();
            this.closeModal();
            this.openModalCategories(response);
          },
          error: (err) => {
            message = this.errorMessage(err.status, err.error.error);
            this.platModalService.toggle('message', message, 'close');
          },
        });
    } else {
      this.gradesService
        .postPeriod(this.discipline_external_id, params)
        .subscribe({
          next: (response) => {
            response.calculation_type = params.calculation_type;
            this.sendNewPeriod.emit(response);
            this.closeModal();
            this.openModalCategories(response);
          },
          error: (err) => {
            message = this.errorMessage(err.status, err.error.error);
            this.platModalService.toggle('message', message, 'close');
          },
        });
    }
  }

  openModalCategories(period: any) {
    this.gradesService.categories$.next(period);
    this.isModalCategoriesOpen = true;
  }

  getStatusCategoriesModal(event: any) {
    this.isModalCategoriesOpen = event.value;

    if (event.value === false && event.name === 'cancel') {
      this.isModalOpen = true;
    }
  }


  errorMessage(statusCode: any, errorMessage: any) {
    
    if (this.i18n.hasOwnProperty(errorMessage)) {
      return this.i18n[errorMessage];
    }else {
      return this.i18n.error_system_error;
    }
  }

  closeModal() {
    this.isModalOpen = false;
    this.editMode = false;
    this.resetConceptValues();
    this.sendIsModalOpen.emit(this.isModalOpen);
  }

  minDateValidator(control: FormControl): { [key: string]: any } | null {
    const enteredDate = control;
    
    let dateMin : Date = new Date();
    let returnValue: any = null;
    
    if (this.periodsForm && true) {
      dateMin = this.initialDate;
      returnValue = minDateValidator(dateMin)(enteredDate);
    }
    return returnValue;
  }

  fnPickDateToInput(calendarDateValue?){
    // Coloca a data no input vindo do CalendarPicker
    if (calendarDateValue) {
      let format = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'MM/dd/yyyy HH:mm','en-US') : formatDate(calendarDateValue, 'dd/MM/yyyy HH:mm','pt-BR');
      this.periodsForm.controls['calendarStartDate'].setValue(format);
    }
  }

  get startDate() {
    return this.periodsForm.get('startDate');
  }
}
